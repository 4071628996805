import useUpdateTableData from 'components/hooks/useUpdateTableData';
import { Formik } from 'formik';
import { apiAuth } from 'logic/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import RestaurantForm from './RestaurantForm';
import {sayError} from "../../../logic/defaults";

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  ue_payment_commission: Yup.string().required('El campo comisión UE es obligatorio'),
  not_ue_payment_commission:  Yup.string().required('El campo comisión no europea es obligatorio'),
  latitude:  Yup.string().required('El campo latitud es obligatorio').nullable(),
  longitude:  Yup.string().required('El campo longitud es obligatorio').nullable(),
};
const validacion = Yup.object().shape(obligado);

/*const initialValues = {
  // name: '',
  // email: '',
};*/

export default function RestaurantsEdit() {
  const { t } = useTranslation();
  const [users, setUsers] = React.useState([]);
  //const [adyen_config, setAdyenConfigs] = React.useState([]);
  const [tpv, setTpvData] = React.useState([]);
  const { id } = useParams();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const [error, setError] = useState(false)

  /*const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants-admin-new/${id}/`,
  });  */

  const getData = async () => {
    setLoading(true)
    try {
      const res_obj = await apiAuth.get(`restaurants-admin-new/get_restaurants_for_bo/?id=${id}`)
      setLoading(false)
      const res_data = res_obj.data;
      setData(res_data);
    } catch (error) {
      setLoading(false)
      setError(true)
      toast.error(error?.response?.data);
    }
  } 

  const { updateData, error: updatedError } = useUpdateTableData({
    entidad: 'restaurants-admin-new',
    id,
  });

  React.useEffect(() => {
    getData();
    getDataUsers();
    //getAdyenConfigs();
    getTPVData();
  }, []);

  const getDataUsers = async () => {
    try {
      const res_obj = await apiAuth.get(`/users/admin_users`);
      const res_data = res_obj.data;
      setUsers(res_data?.map(u => ({id: u.id, name: u.email})));
    } catch (error) {
      toast.error(sayError(error?.response?.data));
    }
  }

  /*const getAdyenConfigs = async () => {
    try {
      const res_obj = await adyenAuth.post(`/adyen-config`);
      const res_data = res_obj.data;
      setAdyenConfigs(res_data.map(u => ({id: u.id, name: u.name})));
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }*/

  const getTPVData = async () => {
    try {
      const res_obj = await apiAuth.get('/tpvs/');
      const res_data = res_obj.data.results;
      setTpvData(res_data.map(u => ({id: u.id, name: u.name})));
    } catch (error) {
      toast.error(sayError(error?.response?.data));
    }
  }

  let newData = {...data}

  React.useEffect(() => {
    delete newData.manager;
    delete newData.adyen_config;
    delete newData.tpv;
    if (data){
      newData.manager = [{}];
      newData.manager[0].id = data.manager;
      for (let i= 0; i < users.length; i++){
        if (users[i].id === data.manager){
          newData.manager[0]["name"] = users[i].name;
        }
      }
      /*newData.adyen_config = [{}];
      newData.adyen_config[0].id = data.adyen_config; 
      for (let x= 0; x < adyen_config.length; x++){
        if (adyen_config[x].id === data.adyen_config){
          newData.adyen_config[0]["name"] = adyen_config[x].name;
        }
      }*/
      newData.tpv = [{}];
      newData.tpv[0].id = data.tpv; 
      for (let y= 0; y < tpv.length; y++){
        if (tpv[y].id === data.tpv){
          newData.tpv[0]["name"] = tpv[y].name;
        }
      }
    }
    

  }, [data, tpv, users])

  const onSubmit = async (values, { setSubmitting }) => {
    //
    const enviaremos = beforeSubmit(values);
  
    await updateData(enviaremos);
    toast.success(`${t('update_ok')}`);
    getData();
  };

  const beforeSubmit = (values) => {
    const after = { ...values };

    delete after.company;
    delete after.groups;
    delete after.opening_hours;
    delete after.owner;
    delete after.profile_photos;
    delete after.sectors;
    delete after.chef_photo;
    delete after.logo;
    delete after.menu;
    delete after.tags;
    delete after.types;
    delete after.home_image;
    delete after.home_image_order_and_pay_or_collect;
    delete after.adyen_account_holder_code;
    //delete after.adyen_account_code;
    delete after.adyen_account_tips_code;
    delete after.tpv;
    delete after.tpv_key;
    delete after.tpv_secret;
    delete after.tpv_wildcard_product_name;
    delete after.tpv_wildcard_product_code;
    delete after.tpv_local_id;
    delete after.manager;
    after.manager = values.manager?.id;
    //delete after.adyen_config;
    //after.adyen_config = values.adyen_config.id;
    //delete after.tpv; 
    //after.tpv = values.tpv.id; 

    return after;
  };

  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-col'}>
        <Formik
          enableReinitialize={true}
          initialValues={newData/* || initialValues*/}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <RestaurantForm
              {...{
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }}
            />
          )}
        </Formik>
      </div>
    );
  }
}
