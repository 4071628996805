import React, { useState, useEffect, useContext } from "react";
import Spinner from "common/Spinner";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import OrderNotification from "../../../sound/OrderNotification2.mp3"
import ErrorSound from "../../../sound/ErrorSound.mp3"
import styled from "styled-components";
import { orderAndPay } from "logic/api";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';
import { Howl } from "howler";
import OrderScanInput from "./Orders/OrderScanInput";
import { format } from "date-fns";
import OrderScanDetail from "./Orders/OrderScanDetail";
import { toast } from "react-toastify";
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import MenuHeader from "pages/home/components/overview/components/MenuHeader";


const OrdersScan = () => {
  const { t } = useTranslation();

  const { state: { selectedRestaurant } } = useContext(AuthContext);

  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants') || '[]');

  const hasActiveOrderAndPayTab = restaurants.some(item => item.show_order_and_pay_tab_on_backoffice);

  const [selectedOrder             , setSelectedOrder]              = useState(null);
  const [orders                    , setOrders]                     = useState([]);
  const [products                  , setProducts]                   = useState([]);
  const [productsModifiers         , setProductsModifiers]          = useState([]);
  const [modifiersModifiers        , setModifiersModifiers]         = useState([]);
  const [loading                   , setLoading]                    = useState(false);
  const [statusLoading             , setStatusLoading]              = useState(false);
  const [showWarning               , setShowWarning]                = useState(false);

  const playSound = (sound, volume) => {
    const Sound = new Howl({
      src: [sound],
      volume: volume
    });
    Sound.play();
  };

  const changeStatus = (id, status) => {
    setStatusLoading(true);

    const endpoint = `/update_order_status`;
    const body = {"id": id, "status": status};

    orderAndPay
      .post(endpoint, body)
      .then(() => {
        if(status === "closed") {
          setSelectedOrder(null)
          toast.success(`${t('Estado actualizado con éxito')}`);
        } else {
          handleSearch(selectedOrder.uuid, false)
        }
      })
      .catch(err => console.log(`Orders.jsx:changeStatus ~ catch: ${err.toString()}`))
  };

  const handleSearch = (order_uuid, notify=true) => {
    setLoading(true)
    const ids = selectedRestaurant.map(res => res.value);
    const from = format(new Date(`2000-01-01`), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    const to = format(new Date(`2024-01-01`), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    let endpoint = `/list/orders?date_from=${from}&date_to=${to}&restaurants=[${ids}]&uuid=${order_uuid}`;
    orderAndPay
      .get(endpoint)
      .then(res => {
        const data = res.data
        const orders = data.orders
        if (orders.length > 0) {
          if (notify) {
            playSound(OrderNotification, 0.5);
          }
          setOrders(orders);
          setSelectedOrder(orders[0])
          setProducts(data.products);
          setProductsModifiers(data.products_modifiers);
          setModifiersModifiers(data.modifiers_modifiers);
          setShowWarning(false);
        } else {
          if (notify) {
            playSound(ErrorSound, 1);
            setShowWarning(true);
          }
        }
      })
      .finally(() => {setLoading(false); setStatusLoading(false)})
    
  }

  if (!hasActiveOrderAndPayTab) return <UnavailableFunctionality title={'Orders'} />

  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
      <MenuHeader title={"Orders"} route_group={'payments_and_orders'}/>
        <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
          <div className={`flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
            <RestaurantSelect />
          </div>
        </div>
        <div className={`flex-auto px-[32px] w-full mt-4`}>
          <div style={{height: "100%", display: 'flex', gap: '1%', zIndex: 0}}>
            {selectedOrder ?
            <div style={{width: '100%', padding: '20px'}}>
              <div style={{textDecoration: "underline", fontSize: '20px', color: "#094553", fontWeight: 600, userSelect: 'none', cursor: 'pointer', width: 'fit-content'}} onClick={() => setSelectedOrder(null)}>{'< ' + t("Get out of the order")}</div>
              <OrderScanDetail
                order={orders?.find((od => od.id === selectedOrder.id))}
                products={products.filter(pr => pr.order_request === selectedOrder.id)}
                productsModifiers={productsModifiers}
                modifiersModifiers={modifiersModifiers}
                changeStatus={changeStatus}
                statusLoading={statusLoading}
                setSelectedOrder={setSelectedOrder}
              />
            </div> :
            <div style={{height: "100%", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{backgroundColor: 'white', borderRadius: '10px', padding: "20px", width: '90%', height: 'min-content'}}>
                <OrderScanInput
                handleClick={handleSearch}
                loading={loading}
                showWarning={showWarning}/>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersScan;
