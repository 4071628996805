import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "react-select";
import { select_styles } from 'logic/defaults';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import SmallSpinner from 'common/SmallSpinner';

const SelectSectors = ({sectors, sector, setSector, loadingSectors}) => {
  const {t} = useTranslation();
  
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const options = [ { id: '', name: t('All')}, ...sectors ]

  return (
    <div className={`font-bold ${isRowBased ? 'bg-white w-36' : ''} text-[14px]`}>
      {t('Search sector')}
      {loadingSectors ? <SmallSpinner /> : (
        <Select 
          menuPortalTarget={document.body}
          name='sector'
          placeholder={t('Sector')}
          options={options}
          styles={select_styles}
          style={{height: "30px"}}
          value={sector || options[0]}
          onChange={(e) => setSector(e)}
          autoComplete="one-time-code"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      )}
    </div>
  )
}

export default SelectSectors;
