import {
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  subDays,
  isAfter,
  isToday,
} from "date-fns";
import { useState, useEffect, useContext } from "react";
import "./DateRangeSelect.css";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import { useTranslation } from "react-i18next";
import { Context } from "data/authContext";

export const getTodayDates = () => {
  const todayStart = new Date();
  const todayEnd = new Date();
  // Don't use the return value of setHours(), instead use the date object itself
  // setHours() modifies the date object but then returns an integer
  return { todayStart, todayEnd };
}

const predefinedRanges = ( t ) => {
  const { todayStart, todayEnd } = getTodayDates();
  const response = [
    {
      label: t("today"),
      value: [todayStart, todayEnd],
      placement: "bottom",
    },
    {
      label: t("yesterday"),
      value: [addDays(todayStart, -1), addDays(todayEnd, -1)],
      placement: "bottom",
    },
    {
      label: t("this_week"),
      value: [startOfWeek(new Date()), new Date()],
      placement: "bottom",
    },
    {
      label: t("last_week"),
      value: [subDays(startOfWeek(new Date()), 7), subDays(startOfWeek(new Date()), 1)],
      placement: "bottom",
    },
    {
      label: t("last_7_days"),
      value: [subDays(new Date(), 6), new Date()],
      placement: "bottom",
    },
    {
      label: t("last_30_days"),
      value: [subDays(new Date(), 29), new Date()],
      placement: "bottom",
    },
    {
      label: t("this_month"),
      value: [startOfMonth(new Date()), new Date()],
      placement: "bottom",
    },
    {
      label: t("last_month"),
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "bottom",
    },
    {
      label: t("This year"),
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "bottom",
    },
    {
      label: t("Last year"),
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: "bottom",
    },
    {
      label: t("All time"),
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "bottom",
    }
  ];
  return response;
};

function isAfterDay(date1, date2) {
  if (typeof date1 != 'object'){
    date1 = new Date(date1)
  }
  return isAfter(new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()), new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()));
}

const afterToday = (afterDate) =>{
  if (afterDate === void 0) {
    afterDate = new Date();
  }

  return function (date) {
    if (isAfterDay(date, new Date(afterDate))) {
      return true;
    }

    return false;
  };
  
}

export default function CustomDateRangeSelect({selectedDate, selectedDateTo, changeSelectedDates, hourFormat=true, todayDefault=false, allowAfterToday=false, title="period"}) {
  const {t} = useTranslation()
  const predefinedRangesValue = predefinedRanges(t);
  const defaultRange = predefinedRangesValue[todayDefault ? 0 : predefinedRangesValue.length - 1].value;
  const [dateRange, setDateRange] = useState(selectedDate && selectedDateTo ? [selectedDate, selectedDateTo] : null);
  const changeDates = (dates) => {
    if(dates){
      setDates(dates)
    }
  }

  const setDates = (dates) => {
    const datesWithHours = [new Date(dates[0]), new Date(dates[1])];
    datesWithHours[0].setUTCHours(0, 0, 0, 0);

    if (isToday(dates[1])) {
      const currentUTCHours = new Date().getUTCHours();
      const previousUTCHours = currentUTCHours - 1;
      

      //since UTC is -2 hours it will remove a day (dont know why) this if avoids it
      if (previousUTCHours < 0) {
        dates[1].setUTCHours(24 + currentUTCHours);
        datesWithHours[1].setUTCHours(24 + currentUTCHours);
      } else {
        dates[1].setUTCHours(currentUTCHours);
        datesWithHours[1].setUTCHours(currentUTCHours);
      }
      
    } else {
      datesWithHours[1].setUTCHours(23, 59, 59, 59);
    }
    setDateRange(dates);
    changeSelectedDates(datesWithHours);
  };


  useEffect(() => {
    if (dateRange) {
      setDates(dateRange)
    }
  }, [dateRange]);

  return (
    <div className="flex flex-col" style={{ fontFamily: 'Poppins, sans-serif', fontSize:  "16px", fontWeight: 500}}>
      {t(title)}
      <DateRangePicker
        value={selectedDate ? dateRange : null}
        onChange={changeDates}
        ranges={predefinedRanges(t)}
        format={hourFormat ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
        defaultCalendarValue={dateRange}
        showWeekNumbers
        shouldDisableDate={allowAfterToday ? "" : afterToday()}
        onClean={() => changeSelectedDates([null, null])}
        preventOverflow
      />
    </div>
  );
}
