import React from 'react';
import { Icon, IconType } from 'common/Icon';
import { useTranslation } from 'react-i18next';
import { ValidatedTicketsContainer } from './TicketsContainers';

export const ValidatedTickets = ({ validated, total }) => {
  const { t } = useTranslation();

  return (
    <ValidatedTicketsContainer>
      <div>
        <Icon type={IconType.TICKET}/>
        <b>{validated}/{total}</b>
      </div>
      <span>
        {t('validated_tickets', { count: total })}
      </span>
    </ValidatedTicketsContainer>
  );
};
