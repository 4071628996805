import React from 'react';
import { useTranslation } from "react-i18next";

const WalletTableHeader = ({opacity}) => {

  const { t } = useTranslation();
  return (
    <div className={`${opacity ? 'opacity-20' : ''}`} style={{display: 'grid', gridTemplateColumns: ".5fr 1.2fr .8fr 1fr 1fr", textAlign: 'center', width: "100%", backgroundColor: "#FCFCFC", justifyContent: 'space-around', fontSize: "17px", fontWeight: 500, padding: "15px 0px", alignItems: 'center', borderBottom: "2px solid #D1D1D1"}}>
		<div>#{t('ID')}</div>
		<div>{t('client')}</div>
		<div>{t('Date and hour')}</div>
		<div>{t('Amount payed')}</div>
		<div>{t('Amount topped up')}</div>
	</div>
  );
};
export default WalletTableHeader;
