import { select_styles } from 'logic/defaults';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import "./DateSelect.css";
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { components } from "react-select";
import './RestaurantSelect.css'

export default function DataSelect({setColumn, column}) {
  const { t } = useTranslation();
  const [optionsList, setOptionsList] = React.useState(null);
  const [seleccionado, setSeleccionado] = React.useState(null);
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const isRowBased = useMediaQuery("(max-width: 760px)");

  const options = [
    { value: 'payments', label: t('Payments') },
    { value: 'transactions', label: t('Transactions') },
    { value: 'avg_ticket', label: t('Avg_Ticket') },
    { value: 'tips', label: t('Tips') },
    { value: 'reviews', label: t('Reviews') },
    { value: 'avg_rating', label: t('Avg_Rating') },
    { value: 'percentage_payments_with_reviews', label: '% ' + t('Payments with reviews') },
    { value: 'percentage_payments_with_tips', label: '% ' + t('Payments with tips') },
    { value: 'payments_with_client', label: '% ' + t('Payments with client') },

  ]

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null()}
          />{" "}
          <span></span>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  React.useEffect(() => {
    setOptionsList(options)
    setSeleccionado(options[0])
  }, []);

  const handleSelect = (opcion) => {
      setSeleccionado(opcion);
      setColumn(opcion.value)
  };
 
  return (
    <div className={`flex items-center space-x-2 ${!isRowBased ? " w-2/3 ": " w-full "}  ${isRowBased && 'justify-center'}`} style={{fontFamily: 'Poppins, sans-serif', fontSize: isRowBased ? "14px" : "16px", fontWeight: 700, display: user.is_investor ? "none" : ""}}>
      <div>{t('Reports')}</div>
      <Select
        menuPortalTarget={document.body}
        multiple={true}
        isClearable={false}
        components={{
          Option
        }}
        closeMenuOnSelect={true}
        styles={select_styles}
        hideSelectedOptions={false}
        className={isRowBased ? '' : 'w-full max-w-md text-[14px]'}
        name={'options'}
        placeholder={t(`Select one option`)}
        options={optionsList}
        value={seleccionado === null ? "" : seleccionado}
        onChange={(a) => {
          handleSelect(a);
        }}
      />
    </div>
  );
}
