import React from 'react';
import { HeaderMockup } from '../components';
import { BottomSheetMockup } from '../components/BottomSheetMockup';
import styled from 'styled-components';
import { Icon, IconType } from 'common/Icon';
import { Trans, useTranslation } from 'react-i18next';
import defaultImage from './../../../img/homeImage.jpg'
import { getImageUrl } from 'logic/functions';

export const WifiPreview = ({ wifi_ssid, wifi_pass, theme, home_image}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderMockup wifi themeName={theme}/>
      <img src={getImageUrl(home_image) || defaultImage} alt=''/>
      <BottomSheet themeName={theme}>
        <Icon type={IconType.WIFI} color='#FFFFFF'/>
        <h4>{t('connect_to_our_wifi')}</h4>
        <p className='ssid'>
          <Icon type={IconType.WIFI}/>
          {wifi_ssid || t('wifi_name')}
        </p>
        {wifi_pass &&
          <div className='pass'>
            <h5>{t('password')}</h5>
            <span>
              {wifi_pass}
              <Icon type={IconType.COPY}/>
              {t('copy')}
            </span>
          </div>
        }
        <p>
          <Trans i18nKey={wifi_pass ? 'wifi_pass_config_message' : 'wifi_config_message'}>
            Go to <b>⚙️ wifi settings</b> to connect to our network.
          </Trans>
        </p>
      </BottomSheet>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  img {
    height: 50%;
    width: 100%;
    object-fit: cover;
  }
`;

const BottomSheet = styled(BottomSheetMockup)`
  & > div {
    font-size: 8px;
    
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 8px 16px;

      & > svg {
        background-color: #1A91E7;
        height: 32px;
        width: 32px;
        padding: 6px;
        border: 1px solid currentColor;
        border-radius: 6px;
        box-shadow: 1.5px 1.5px 0 currentColor;
      }
    }
  }

  h4 {
    font-size: 1.125em;
    font-weight: 600;
  }

  b {
    font-weight: 600;
  }

  .ssid {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #1A91E7;
  }

  .pass {
    width: 100%;
    margin-bottom: 8px;

    h5 {
      font-weight: 500;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    span {
      display: flex;
      align-items: center;
      border: 1px solid #DDD;
      border-radius: 4px;
      width: 100%;
      padding: 4px;

      svg {
        border-left: 1px solid #DDD;
        padding: 0 4px;
        height: 10px;
        margin-left: auto;
      }
    }
  }

  p {
    text-align: center;
  }
`;