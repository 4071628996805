import React from "react";
import RoundButton from "../RoundButton";

export const ConfirmSynchronizeModal = ({ closeModal, synchronize, t }) => {
	return (
		<div className="z-9999 absolute top-0 left-0 right-0 bottom-0 flex">
      <div className='z-50 flex flex-col p-10 m-auto overflow-hidden bg-white rounded-3xl'>
        <p>{t('confirm_synchronize_text_1')}<br/>{t('confirm_synchronize_text_2')}</p>
        <p>{t('confirm_synchronize_text_3')}</p>
        <p>{t('Do you wish to continue?')}</p>
        <div className="flex flex-row justify-end gap-4 mt-auto">
          <button
            onClick={closeModal}
            className='text-gray-500 font-bold text-xs py-3 px-4 rounded-full capitalize'
          >
            {t('No, cancel')}
          </button>
          <RoundButton
            onClick={synchronize}
            bgColor={'#5FB894'}
            textColor={'#FFFFFF'}
            text={t('Yes, synchronize')}
            className='min-w-[12rem]'
          />
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'/>
    </div>
	);
};
