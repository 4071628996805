import React from 'react'
import { ProductCardMockup } from '../components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const DiscountedProductPreview = ({discount, product}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h4>{t('product_preview')}</h4>
      <ProductCardMockup
        product={product}
        biggest
        badge
        allergens
        description={"short"}
        discount={discount}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  h4 {
    font-size: 1rem;
    font-weight: 500;
    color: #212121;
  }

  & > div {
    border: 1px solid #DDDDDD66;
    box-shadow: 0px 8px 16px 0px #959DA51A;
  }
`