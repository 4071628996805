import React, { useState, useEffect, useContext } from "react"
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChevronUpIcon } from '@heroicons/react/outline'
import { bottomRoutes, routes } from "./AdminRoutes"
import { admin_routes } from "./SuperAdminRoutes"
import styled from "styled-components"
import { waiter_routes } from "./WaiterRoutes"
import { NavigatorContext } from 'data/navigatorContext';
import { Logo } from "common/Logo"
import { Context as AuthContext } from 'data/authContext';

const pathIsInChildren = (button) => {
	return button.children && button.children.some(element => window.location.pathname === element.path || (element.children && element.children.some(subelement => window.location.pathname === subelement.path)))
}

const StyledButton = styled.button`
	--text_color: #FFFFFF;
	--bg_color: #094553;
	--text_color_hover: #094553;
	--bg_color_hover: #9EFFD7;
	
	&.submenu {
		--text_color: #FFFFFF;
		--bg_color: #205763;
		--text_color_hover: #FFFFFF;
		--bg_color_hover: #547F87;
	}

	color: var(--text_color);
	background-color: var(--bg_color);
	
	&:hover, &.active {
		color: var(--text_color_hover);
		background-color: var(--bg_color_hover);
	}
`;

const NavButton = ({ button, sideBarOpen, isSubMenu, onNavButtonClick, openSubMenuIndex, index, setOpenSubMenuParent, openSubMenuParent, allowedRoutes }) => {
	const isActive = window.location.pathname === button.path || pathIsInChildren(button)
	const history = useHistory()
	const [openSubMenu, setOpenSubMenu] = useState(openSubMenuParent)
	const handleClick = () => {
		setOpenSubMenuParent && setOpenSubMenuParent(!openSubMenuParent)
		if (button.children) {
			onNavButtonClick && onNavButtonClick(index);
		} else {
			if (button.onClick) {
				button.onClick();
			} else {
				history.push(button.path);
			}
		}
	}

	const [isThisSubMenuOpen, setIsThisSubMenuOpen] = useState(openSubMenuIndex === index)

	useEffect(() => {
		setIsThisSubMenuOpen(openSubMenuIndex === index)
		if (openSubMenuIndex !== index) setOpenSubMenu(false)
	}, [openSubMenuIndex])

	useEffect(() => {
		setOpenSubMenu(openSubMenuParent)
	}, [openSubMenuParent])

	return (
		<div className={`${(!isSubMenu && index) ? 'mt-4' : ''} ${(isThisSubMenuOpen || isActive) && sideBarOpen && 'bg-[#205763] rounded-md w-full'}`}>
			<StyledButton
				onClick={handleClick}
				className={`${isSubMenu ? 'submenu' : ''} ${(isActive || (isThisSubMenuOpen && sideBarOpen)) ? 'active' : ''} ${isSubMenu && isActive ? 'rounded-md' : ''}
			  ${!isSubMenu ? ' rounded-md h-11 px-5 py-3' : 'p-2 h-9 '}
			  rounded-md group text-xs space-x-3 z-50 w-full text-white focus:outline-none
			  flex flex-row items-center justify-between cursor-pointer relative text-left font-semibold disabled:text-gray-200`}
			>
				<div className="h-6 w-6 flex justify-center items-center absolute">{button.icon}</div>
				<div className={`${!sideBarOpen ? 'hidden' : 'inherit'} flex flex-row items-center justify-between py-3 w-full`}>

					<span className={`min-w-[140px] whitespace-normal capitalize_first`} style={{ paddingLeft: button.level ? '50px' : isSubMenu ? '40px' : '30px', whiteSpace: 'wrap' }}>
						{button.title}
					</span>
					{button.children && <ChevronUpIcon height={20} className={`${(openSubMenu && sideBarOpen) || (isThisSubMenuOpen && sideBarOpen) ? 'rotate-0' : 'rotate-180'}  transform transition-transform`} />}
				</div>
			</StyledButton>

			{button.children &&
				<div className={` z-10 ${(openSubMenu && sideBarOpen) || (isThisSubMenuOpen && sideBarOpen) ? 'h-min' : 'h-0 opacity-0 hidden'} `}>
					{button.children.map((button, childIndex) => {
						return <NavButton key={childIndex + 'sub'}
							button={button}
							sideBarOpen={sideBarOpen}
							isSubMenu={true}
							openSubMenuIndex={openSubMenuIndex}
							setOpenSubMenuParent={setOpenSubMenu}
							openSubMenuParent={openSubMenu}
							index={childIndex + 'sub'}
							allowedRoutes={allowedRoutes}
						/>
					})
					}
				</div>
			}
		</div>
	)
}


const NavigationComponent = ({ open, setOpen }) => {
	const { t } = useTranslation()
	const { setOpen : setOpenFromContext } = useContext(NavigatorContext);
	const {
		state: {
		  allowedRoutes
		}
	  } = React.useContext(AuthContext); 

	const history = useHistory()
	const user = JSON.parse(localStorage.getItem("yumminn-user"))
	
	const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null); // State to track open submenu
	
	const handleNavButtonClick = (index) => {
		if (openSubMenuIndex === index) {
			setOpenSubMenuIndex(null)
		} else {
			setOpenSubMenuIndex(index); // Open the clicked submenu
		}
	};

	const handleLogout = () => {
		localStorage.removeItem('yumminn-user')
		localStorage.removeItem('yumminn-restaurants')
		localStorage.removeItem('yumminn-tokens')
		localStorage.removeItem('yumminn-path')
		localStorage.removeItem('yumminn-selectedRestaurant')
		localStorage.removeItem('yumminn-selectedCompany')
		sessionStorage.removeItem('yumminn-selectedYear')
		sessionStorage.removeItem('yumminn-selectedYearTo')
		sessionStorage.removeItem('yumminn-selectedMonth')
		sessionStorage.removeItem('yumminn-selectedMonthTo')
		sessionStorage.removeItem('yumminn-selectedDate')
		sessionStorage.removeItem('yumminn-selectedDateTo')
		sessionStorage.removeItem('yumminn-selectedStartTime')
		sessionStorage.removeItem('yumminn-selectedEndTime')
		localStorage.removeItem('token')
		localStorage.removeItem('tabs')
		sessionStorage.removeItem('yumminn-selectedReviewFilter')
		history.push('/')
	}


	const tabs = JSON.parse(localStorage.getItem('tabs'))

	const buttons = user.is_superuser ? admin_routes(t, tabs, allowedRoutes) : user.rol !== "waiter" ? routes(t, tabs, allowedRoutes) : waiter_routes(t, tabs, allowedRoutes)
	const lastButtons = bottomRoutes({ handleLogout: handleLogout, t: t, allowedRoutes: allowedRoutes })

	if(!user) return <></>

	return (
		<div className="flex sticky top-0 overflow-hidden">
			<div
				onMouseOver={() => {setOpen(true); setOpenFromContext(true)}}
				onMouseOut={() => {setOpen(false); setOpenFromContext(false)}}
				className={`${open ? 'w-60' : 'w-[80px]'} flex flex-col h-screen bg-[#094553] duration-300 items-center pt-6`}
			>
				<Logo onClick={() => history.push("/admin/home")} className="px-6"/>
				<div className="flex flex-col mt-4 w-full flex-1 overflow-hidden">
					<NoScrollBar className="no-scrollbar p-2 pt-0 flex flex-col justify-between overflow-scroll">
						{buttons.map((button, index) => {
							return <NavButton key={index}
								button={button}
								sideBarOpen={open}
								openSubMenuIndex={openSubMenuIndex}
								onNavButtonClick={handleNavButtonClick}
								isParentSubMenuOpen={true}
								index={index}
								allowedRoutes={allowedRoutes}
							/>
						})}
					</NoScrollBar>
					<div className="p-2 mt-auto flex flex-col justify-between bg-[#094553] w-full">
						<hr />
						{lastButtons.map((button, index) => {
							return <NavButton key={index + buttons.length}
								button={button}
								sideBarOpen={open}
								openSubMenuIndex={openSubMenuIndex}
								onNavButtonClick={handleNavButtonClick}
								isParentSubMenuOpen={true}
								index={index + buttons.length}
								allowedRoutes={allowedRoutes}
							/>
						})}
					</div>
				</div>
			</div>
		</div>
	)
}


const NoScrollBar = styled.div`
	::-webkit-scrollbar {
		width: 0;
		
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

export default NavigationComponent