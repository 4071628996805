import React from 'react';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { Icon, IconType } from 'common/Icon';
import './custom-toastify.css'; 
import { SECOND } from 'logic/defaults';

const CustomToast = ({ title, message }) => {
  const { t } = useTranslation();

  return (
    <div className="custom-toast flex items-center justify-center gap-4">
      <Icon type={IconType.VERIFIED} size={50}/>
      <div className='flex flex-col flex-1 w-0'>
        {title &&
          <strong className='whitespace-nowrap font-semibold'>
            {t(title)}
          </strong>
        }
        {message &&
          <div className='leading-snug'>
            {t(message)}
          </div>
        }
      </div>
      <div style={{outline: 'none'}}>
        <XIcon className={`w-[24px] text-gray-700 cursor-pointer`}/>
      </div>
    </div>
  );
};

export const notify = ({title, message}) => {
  toast(<CustomToast title={title} message={message}/>, {
    autoClose: 3 * SECOND,
    position: toast.POSITION.TOP_RIGHT,
    closeButton: false,
    hideProgressBar: true,
  });
};