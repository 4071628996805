import React, { useState, useEffect, useContext } from "react";
import Spinner from "common/Spinner";
import TopupsTable from "./TopupsTable";
import RoundPagination from "common/RoundPagination";
import TopupDetail from "./TopupDetail";
import WalletSearchInput from "./WalletSearchInput";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from 'data/authContext';
import { useRecharges } from "./useRecharges";

const WalletTableHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
const RecommendationFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-top: 15px;
`;
const RecommendationFiltersLabel = styled.p`
  align-self: center;
  margin-right: 5px;
  min-width: fit-content;
`;
const RecommendationWrapper = styled.div`
  display: flex;
  flex-direction: row;
	font-size: 12.5px;
	border-radius: 20px;
	padding: 6px;
  padding-left: 6px;
  margin-left: 5px;
  background-color: rgb(95, 184, 148);
  min-width: fit-content;
  span {
    color: white;
  }
`;
const RecommendationType = styled.span`
	font-size: 11.5px;
	background-color: rgb(115, 204, 168);
	color: hsl(0, 0%, 20%);
	border-radius: 20px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-right: 5px;
`;
const RecommendationValue = styled.span`
	color: hsl(0, 0%, 20%);
  align-self: center;
`;
const RecommendationCloseButton = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  span {
    font-weight: 600;
    color: #404040;
    align-self: center;
  }
  &:hover {
    cursor: pointer;
    background-color: rgb(115, 204, 168);
  }
  &:hover span {
    color: white;
  }
`;

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const WalletTopups = () => {
  const { state: { selectedRestaurant, selectedDate, selectedDateTo } } = useContext(AuthContext);

  const { t } = useTranslation();

  const elementsPerPage = 50;

  const [selectedTopup             , setSelectedTopup]              = useState(null);
  const [numberOfPages             , setNumberOfPages]              = useState(null);
  const [totalNumberOfTopups       , setTotalNumberOfTopups]        = useState(null);
  const [topups                    , setTopups]                     = useState([]);
  const [incomingTopupsIds         , setIncomingTopupsIds]          = useState([]);
  const [notOpenedIncomingTopupsIds, setNotOpenedIncomingTopupsIds] = useState([]);
  const [recommendationFilters     , setRecommendationFilters]      = useState([]);
  const [page                      , setPage]                       = useState(1);
  const [loading                   , setLoading]                    = useState(false);
  const [statusLoading             , setStatusLoading]              = useState(false);
  const [notifyNew                 , setNotifyNew]                  = useState(false);

  const { data, isLoading, mutate } = useRecharges({ page, recommendationFilters, setNotifyNew });
  
  const recommendationTypeToString = {
		'client_name':  t('Client_name'),
		'client_phone': t('Phone'),
		'client_email': t('Email_short'),
    'recharge_ids':  '#' + t('recharge_ids'),
	};

  const updateData = data => {
    try {
      const recharges = data.recharges.filter(payment => payment.split_type === 'recharge');
      setTopups(recharges);

      if (totalNumberOfTopups && totalNumberOfTopups > 0 && data?.total_number_of_recharges> 0 && notifyNew) {
        const newTopupsCount = data.total_number_of_recharges - totalNumberOfTopups;

        if (newTopupsCount > 0) {
          const incomingIds = data.recharges.slice(0, newTopupsCount).map(topup => topup.id);
          const updatedNotOpenedIds = incomingTopupsIds.concat(incomingIds);

          setIncomingTopupsIds(incomingIds);
          setNotOpenedIncomingTopupsIds(updatedNotOpenedIds);
        } else {
          setIncomingTopupsIds([]);
        }
      } else {
        setIncomingTopupsIds([]);
      }

      if (recharges.length > 0 && (!selectedTopup || !recharges.some(t => t.id === selectedTopup.id))) {
        setSelectedTopup(recharges[0]);
      }

      const incomingNumberOfTopups = data.total_number_of_recharges;
      setTotalNumberOfTopups(incomingNumberOfTopups);
  
      const nextNumberOfPages = Math.ceil(incomingNumberOfTopups / elementsPerPage);
      setNumberOfPages(nextNumberOfPages > 0 ? nextNumberOfPages : null);
    } catch (error) {
      console.log(`Recharges.jsx:getData ~ catch: ${error.toString()}`);
    } finally {
      setLoading(false);
      setStatusLoading(false);
      !notifyNew && setNotifyNew(true);
    }
  };

  const addRecommendationFilter = newFilter => {
    const newRecommendationFilters = recommendationFilters.concat([newFilter]);
    setRecommendationFilters(newRecommendationFilters);
  };

  const removeRecommendationFilter = indexToRemove => {
    const newRecommendationFilters = recommendationFilters.filter((_, index) => index !== indexToRemove);
    setRecommendationFilters(newRecommendationFilters);
  };

  useEffect(() => {
    setPage(1);
    setNumberOfPages(null);
    setNotifyNew(false);
  }, [selectedRestaurant, selectedDate, selectedDateTo, recommendationFilters]);

  useEffect(() => {
    if (!data) return;
    updateData(data);
  }, [data]);

  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {(isLoading || loading) && <LoadingOverlay/>}
        <div className={`flex-auto px-[32px] w-full mt-4`}>
          <WalletTableHeader>
            <div className={`gap-5`} style={{display: 'flex', flexDirection: 'row'}}>
              <WalletSearchInput addFilter={addRecommendationFilter} />
            </div>
            {recommendationFilters?.length > 0 && (
              <RecommendationFiltersWrapper>
                <RecommendationFiltersLabel>{t('filtering_by') + ":"}</RecommendationFiltersLabel>
                {recommendationFilters?.map((recommendation, index) => {
                  if (!recommendation) {
                    return <></>;
                  }
                  return (
                    <RecommendationWrapper key={'recommendation_' + index}>
                      <RecommendationType>
                        {recommendationTypeToString[recommendation.type]}
                      </RecommendationType>
                      <RecommendationValue>
                        {recommendation.value}
                      </RecommendationValue>
                      <RecommendationCloseButton onMouseDown={() => removeRecommendationFilter(index)}>
                        <span>X</span>
                      </RecommendationCloseButton>
                    </RecommendationWrapper>
                  );
                })}
              </RecommendationFiltersWrapper>
            )}
            {numberOfPages && (
              <RoundPagination
                page={page}
                numberOfPages={numberOfPages}
                setPage={setPage}
                total={totalNumberOfTopups}
                elementNamePlural={t("topups")}
                elementNameSingular={t("topup")}
              />
            )}
          </WalletTableHeader>
          <div style={{display: 'flex', gap: '1%', zIndex: 0}}>
            <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: '59%'}}>
              <TopupsTable
                topups={topups}
                selectedTopup={selectedTopup}
                setSelectedTopup={setSelectedTopup}
                incomingTopupsIds={incomingTopupsIds}
                notOpenedIncomingTopupsIds={notOpenedIncomingTopupsIds}
                setNotOpenedIncomingTopupsIds={setNotOpenedIncomingTopupsIds}
              />
            </div>
            {selectedTopup && topups?.find((od => od.id === selectedTopup.id)) &&
            <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: '40%', height: 'min-content'}}>
              <TopupDetail
                topup={topups?.find((od => od.id === selectedTopup.id))}
                statusLoading={statusLoading}
              />
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTopups;
