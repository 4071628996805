import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import TablesTabs from './TablesTabs';
import XMenuButton from '../../common/XMenuButton';
import profile from '../../img/profile.png';
import { Context as AuthContext } from '../../data/authContext';
import SectorSelect from './SectorSelect';
import {useMediaQuery} from '../../hooks/useMediaQuery';

const TablesContainerNoLogin = ({ children }) => {
  const { t } = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");


  return (
    <div className={'flex flex-col bg-gray-100 w-full h-full'}>
      <div className='bg-white flex justify-between align-center'>
        <h1 className='font-bold text-[24px] px-[32px] py-5 text-[#094553] bg-[#E2FEF1]'>
          {t('Tables')}
        </h1>
       
      </div>
      <div className={` ${isRowBased ? 'bg-[#EFF8F4]':'px-[32px]'}`} >
        {/* <RestaurantSelect /> */}
        {/* <SectorSelect/> */}
        {/* <TablesTabs /> */}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default TablesContainerNoLogin;
