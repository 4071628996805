import React from 'react';
import SideBar from 'layouts/MenuContainer';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import {useMediaQuery} from '../../../../hooks/useMediaQuery';

const StockControlContainer = ({ children, title }) => {

  const isRowBased = useMediaQuery("(max-width: 760px)");

  return (
    <div id="menuContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased ? 'flex-col' : 'flex-row'}`}>
      <SideBar style={{flex:' 0 1 auto', position:'fixed', overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        <MenuHeader title={title} route_group={"stock_control"}/>
        <div className={`${isRowBased && 'bg-white'}`} >
          <div className='bg-white py-4 px-[32px]'>
            <RestaurantSelect />
          </div>
          <div className='px-[32px]'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default StockControlContainer;
