import { HOUR, MINUTE, SECOND } from 'logic/defaults';
import React, { useEffect, useState } from 'react';

const millisecToTimer = (ms, interval, fixedHours) => {
  const sign = ms <= -interval ? '-' : '';

  const parseMillisec = (ms, divisor) => {
    const divideMillisec = ms => Math.abs(Math[divisor === interval ? 'ceil' : 'floor'](ms / divisor) % 60);

    return divisor >= interval ? divideMillisec(ms).toString().padStart(2, '0') : '00';
  };

  const seconds = parseMillisec(ms, SECOND);
  const minutes = parseMillisec(ms, MINUTE);

  const absMs = Math.abs(ms);
  const hour = Math[HOUR <= interval ? 'ceil' : 'floor'](absMs / HOUR).toString();
  
  const showHours = fixedHours || (absMs > HOUR);

  return `${sign}${showHours ? hour + ':' : ''}${minutes}:${seconds}`;
};

export const useCountDown = ({ endTime, interval = SECOND, allowNegativeValues = false, fixedHours = false }) => {
  const [millisecLeft, setMillisecLeft] = useState(0);

  useEffect(() => {
    const validateTime = ms => allowNegativeValues ? ms : Math.max(ms, 0);

    // endTime can be a Date object, a string representing a date or a number of milliseconds
    if (typeof endTime === 'string') {
      endTime = new Date(endTime)
    } else if (typeof endTime === 'number') {
      endTime = new Date(Date.now() + endTime)
    };

    setMillisecLeft(validateTime(endTime - Date.now()));
    
    const countDown = setInterval(() => {
      setMillisecLeft(prev => {
        const newTime = validateTime(prev - interval);

        if (!allowNegativeValues && newTime <= 0) {
          clearInterval(countDown);
        }

        return newTime;
      });
    }, interval);
    
    return () => clearInterval(countDown);
  }, [endTime]);

  return { millisecLeft, timer: millisecToTimer(millisecLeft, interval, fixedHours) };
};
