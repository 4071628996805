import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { getTextColor } from 'styles/colors';

export const PercentBar = ({data = [], unit = '', showPercent, style, className}) => {
  const barRef = useRef([]);
  
  const total = data.reduce((sum, item) => sum += item.value, 0);
  const percents = data.map(item => ({...item, percent: Math.round(item.value * 100 / (total || 1))}))

  const placeLabels = () => {
    barRef.current.forEach(({bar, label}, index) => {
      if (bar.clientWidth < (label.clientWidth + 4) && !index) {
        label.classList.add('tooltipRight');
        barRef.current[1].label.classList.add('moveRight');

        return;
      }
      
      if (bar.clientWidth < (label.clientWidth + 4) && index) {
        label.classList.add('tooltipLeft');

        return;
      }
      
      barRef.current[1].label.classList.remove('tooltipRight');
      label.classList.remove('tooltipLeft', 'tooltipRight');
    })
  };

  useLayoutEffect(placeLabels, [data, showPercent]);

  useLayoutEffect(() => {
    window.addEventListener('resize', placeLabels);

    return () => window.removeEventListener('resize', placeLabels);
  }, [])

  return (
    <BarContainer className={className} style={style}>
      {percents.map((item, index) => (
        <div
          key={'item-' + index}
          ref={bar => barRef.current[index] = {...barRef.current[index], bar}}
          style={{width: item.percent + '%', backgroundColor: item.color}}
        >
          <span
            ref={label => barRef.current[index] = {...barRef.current[index], label}}
            style={{backgroundColor: item.color, color: getTextColor(item.color)}}
          >
            {showPercent ? item.percent + '%' : item.value + unit}
          </span>
        </div>
      ))}
    </BarContainer>
  );
};

const BarContainer = styled.div`
  display: flex;
  height: 2rem;
  border-radius: 6px;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    flex-shrink: 0;
    border-radius: 4px;
    padding: 0 4px;
    margin: 4px 2px;
    position: relative;
    isolation: isolate;

    transition: 0.3s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: calc(50% - 4px);
      rotate: 45deg;
      transform: skew(-10deg, -10deg);
      height: 8px;
      width: 8px;
      border-radius: 0 2px;
      z-index: -1;
    }

    &.tooltipLeft {
      translate: calc(-100% - 12px);
      
      &::after {
        left: calc(100% - 4px);
      }
    }
    
    &.tooltipRight {
      left: calc(100% + 12px);
      z-index: 2;
      
      &::after {
        right: calc(100% - 4px);
      }
    }

    &.moveRight {
      margin-left: auto;
    }
  }
`;