import { BUTTON_COLORS, BUTTON_SIZES, Button } from 'common/Button';
import { SquareIcon } from 'common/Icon';
import { InfoTooltip } from 'common/InfoTooltip';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KitchenSaturationContainer } from './KitchenSaturationContainers';
import { KitchenSaturationModal } from './KitchenSaturationModal';
import { Context as AuthContext } from '../../../../data/authContext';
import { RestaurantSaturationCard } from './RestaurantSaturationCard';
import { ChevronDownIcon } from '@heroicons/react/outline';

export const KitchenSaturation = ({ saturationList, mutate }) => {
  const { state: { selectedRestaurant } } = useContext(AuthContext);

  const { t } = useTranslation();
  
  const [isListOpen, setIsListOpen] = useState(false);
  const [isManageOpen, setIsManageOpen] = useState(false);

  const hasMultipleRestaurants = selectedRestaurant?.length > 1;

  const restaurantSaturation = !hasMultipleRestaurants && saturationList?.length ? saturationList[0] : null;

  const toggleListOpen = () => setIsListOpen(prev => !prev);

  const openManage = event => {
    event.stopPropagation();
    setIsListOpen(false);
    setIsManageOpen(true);
  };

  if (restaurantSaturation) return (
    <>
      <RestaurantSaturationCard
        saturation={restaurantSaturation}
        openManage={() => setIsManageOpen(true)}
        mutate={mutate}
      />
      <KitchenSaturationModal isOpen={isManageOpen} close={() => setIsManageOpen(false)}/>
    </>
  );

  return (
    <>
      <KitchenSaturationContainer className={isListOpen ? 'open' : ''}>
        <div onClick={toggleListOpen}>
          <div className="row">
            <SquareIcon emoji={'🔥'}>👩🏻‍🍳</SquareIcon>
            {!!saturationList?.length &&
              <>
                <h4>
                  <Trans i18nKey='active_saturation_restaurants' count={saturationList?.length}>
                    <span>{{count: saturationList?.length}}</span> Restaurantes con <b>gestión de cocina saturada activa</b>
                  </Trans>
                </h4>
                <ChevronDownIcon height={32} className='chevron'/>
              </>
            }
            {!saturationList?.length &&
              <>
                <h4>
                  {t('kitchen_saturated?')}
                  <InfoTooltip position='top' interactive>
                    <p className='info'>
                      <Trans i18nKey='info_kitchen_saturated'>
                        Informa a tus comensales de la espera o desactiva los pedidos temporalmente desde aquí.
                        <a href='https://guidejar.com/guides/243dee7f-cac5-45a5-b4b3-0e1bcfd26b69' target='_blank' rel="noreferrer">Mas info en este tutorial.</a>
                      </Trans>
                    </p>
                  </InfoTooltip>
                </h4>
                <Button
                  label={'manage'}
                  size={BUTTON_SIZES.SM}
                  color={BUTTON_COLORS.DANGER}
                  onClick={openManage}
                />
              </>
            }
          </div>
          {!!saturationList?.length &&
            <ul>
              <p>
                <Trans i18nKey='clients_will_be_inform_in_webapp_short'>
                  <b>Le informaremos al comensal</b> a través de la webapp
                </Trans>
              </p>
              {saturationList.map(saturation =>
                <RestaurantSaturationCard
                  key={`saturation_${saturation.restaurant}`}
                  as='li'
                  saturation={saturation}
                  mutate={mutate}
                />
              )}
            </ul>
          }
        </div>
      </KitchenSaturationContainer>
      <KitchenSaturationModal isOpen={isManageOpen} close={() => setIsManageOpen(false)} mutate={mutate}/>
    </>
  );
};