import { Icon, IconType } from 'common/Icon';
import { TextInput } from 'common/Input';
import { formatDate } from 'pages/discounts/components/discountFunctions';
import React, { useContext, useState } from 'react'
import { AccessContext } from './AccesContext';
import { SECOND } from 'logic/defaults';

export const AccessRow = ({ type, entry, regexValidator, setEntryToDelete }) => {
  const {
    isValidating,
    changeEntry,
    saveEntry,
    deleteEntry,
    clearDraft,
  } = useContext(AccessContext);

  const rowId = entry.id ? `row_${entry.id}` : `row_new`;

  const [attr, setAttr] = useState(entry[type])
  const [inputError, setInputError] = useState(null);

  const rowRef = node => {
    if (!node) return;

    !entry.id && document.getElementById('new_entry_input')?.focus()
    
    if (!entry.id || (
      node.dataset?.notified !== entry.modified_at &&
      (Date.now() - Date.parse(entry.modified_at) <= 5 * SECOND)
    )) {
      const handleAnimationEnd = event => {
        event.target.classList.remove('modified');
        event.target.removeEventListener('animationend', handleAnimationEnd);
      };

      node.addEventListener('animationend', handleAnimationEnd);
      node.classList.add('modified');
      node.dataset.notified = entry.modified_at;
    };
  };

  const changeAndRevalidate = event => {
    const value = event.target.value;

    if (inputError && regexValidator.test(value)) {
      setInputError(null);
    }

    setAttr(value);
    !entry.id && changeEntry({...entry, [type]: value});
  };

  const validateAndSave = event => {
    const value = event.target.value;
    const hasChanges = !entry.id || entry[type] !== value;

    if (!value || !hasChanges) {
      entry.id && setAttr(entry[attr]);
      setInputError(null);
      return;
    };

    if (regexValidator.test(value)) {
      saveEntry({ ...entry, [type]: value });
    } else {
      setInputError(`invalid_${type}`);
    };
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.target.value && validateAndSave(event);
    } else if (event.key === 'Escape') {
      entry.id ? setAttr(entry[type]) : clearDraft();
      event.target.blur();
      setInputError(null);
    }
  };

  return (
    <tr id={rowId} ref={rowRef}>
      <td className='col_id'>
        <span>{entry.id || '-'}</span>
      </td>
      <td className='col_entry'>
        <TextInput
          id={entry.id ? `entry_input_${entry.id}` : 'new_entry_input'}
          value={attr}
          error={inputError}
          placeholder={`access_${type}_placeholder`}
          autocomplete='off'
          onChange={changeAndRevalidate}
          disabled={isValidating}
          onBlur={validateAndSave}
          onKeyDown={handleKeyDown}
        />
      </td>
      <td className='col_created'>
        {formatDate(entry.created_at)}
      </td>
      <td className='col_modified'>
        {formatDate(entry.modified_at)}
      </td>
      <td>
        <Icon
          type={IconType.TRASH}
          size={20}
          disabled={isValidating}
          onClick={() => entry.id ? setEntryToDelete(entry) : deleteEntry()}
        />
      </td>
    </tr>
  );
}
