import React from 'react';
import takeAwayIcon from '../../../../img/PickUpBag.svg';
import tableIcon from '../../../../img/TableIcon.svg';
import deliveryIcon from '../../../../img/DeliveryCycle.svg';


const OrderType = ({type}) => {

  if (type == "take_away") {
    return <img src={takeAwayIcon}/>
  }
  else if (type == "delivery") {
    return <img src={deliveryIcon}/>
  }
  else {
    return <img src={tableIcon}/>
  }
};

export default OrderType;