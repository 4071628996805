import React, { useState } from 'react';
import { ChooseDiscount } from './ChooseDiscount';
import { DiscountPopup } from './DiscountContainers';
import { TabSelector } from 'common/TabSelector';
import { ActiveSwitch } from 'pages/admin/pages/OrderAndPay/ActiveSwitch';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { useTranslation } from 'react-i18next';
import { VisibilitySchedules } from './VisibilitySchedules';
import { PromotionTexts } from './PromotionTexts';
import { PromotionSpecs } from './PromotionSpecs';
import { DISCOUNT_TABS, DISCOUNT_TYPOLOGIES, MANDATORY_DISCOUNT_FIELDS, NEW_DISCOUNT, PRODUCT_DISCOUNT_TABS } from './constants';
import { PromotionLimitsTab } from './PromotionLimitsTab';
import { baseAuth } from 'logic/api';
import { logToServer } from 'logic/functions';
import LoadPromotionAndVisualize from './LoadPromotionAndVisualize';

export const DiscountModal = ({
  discount,
  catalog,
  testTableUrl,
  selectedRestaurantId,
  activeGeneralDiscount,
  activeProductDiscount,
  updateDiscount,
  setOpenWarning,
  ...popupProps
}) => {
  const { t } = useTranslation();
  
  const [discountDraft, setDiscountDraft] = useState({
    ...NEW_DISCOUNT,
    ...discount,
    maxEurosRedeemable: discount?.max_cents_redeemable ? (discount.max_cents_redeemable / 100).toFixed(2) : '',
    minimumRedeemEuros: discount?.minimum_redeem_amount ? (discount.minimum_redeem_amount / 100).toFixed(2) : '',
  });
  
  const tabOptions = discountDraft.typology === DISCOUNT_TYPOLOGIES.PRODUCT ? PRODUCT_DISCOUNT_TABS : DISCOUNT_TABS;

  const [tab, setTab] = useState(tabOptions[0]);
  const [tabsToCheck, setTabsToCheck] = useState(discountDraft.id ? [] : [...tabOptions])
  const [isLoading, setIsLoading] = useState(false);

  const isSaveDiabled = isLoading;
  
  const editDiscount = partialDiscount => setDiscountDraft(prev => ({...prev, ...partialDiscount}));

  const changeTab = tab => {
    setTab(tab);
    setTabsToCheck(remainingTabs => {
      const index = remainingTabs.indexOf(tab);
      
      if (index === -1) return remainingTabs;

      const newRemainingTabs = [...remainingTabs];
      newRemainingTabs.splice(index, 1);

      return newRemainingTabs;
    });
  };

  const disableNext = tab => {
    return MANDATORY_DISCOUNT_FIELDS[discountDraft.typology][tab]?.some(field => {
      if (!discountDraft?.[field]) return true;
      if (Array.isArray(discountDraft[field])) return !discountDraft[field].length;
      if (field === 'value') return !Number(discountDraft[field]) || isNaN(Number(discountDraft[field]));
      return false;
    });
  };

  const disableTab = tab => {
    const index = tabOptions.indexOf(tab);

    if (index > 0) return tabOptions.slice(0, index).some(t => disableNext(t));
    return false;
  };

  const goNext = () => tabsToCheck.length && !disableNext(tab) && changeTab(tabsToCheck[0]);

  const selectTypology = partialDiscount => {
    const newTabOptions = partialDiscount.typology === DISCOUNT_TYPOLOGIES.PRODUCT ? PRODUCT_DISCOUNT_TABS : DISCOUNT_TABS;

    setTabsToCheck(newTabOptions);
    changeTab(newTabOptions[0]);
    editDiscount(partialDiscount);
  };

  const toggleActive = async (discount) => {
    if (discount.typology === DISCOUNT_TYPOLOGIES.PRODUCT && activeGeneralDiscount && !discount.active){
      setOpenWarning(true);
      return;
    };

    const endpoint = `/discount/toggle_active/${discount.typology}/${discount.id}/`;
    setIsLoading(true);
    await baseAuth
      .post(endpoint)
      .then((response) => {
        if (response.status === 200) {
          discount.active = !discount.active;
          updateDiscount(discount, {
            title: discount.active ? 'activated_discount' : 'disactivated_discount',
            message: discount.active ? 'visible_at_webapp' : 'not_visible_at_webapp',
          });
        }
      })
      .catch((e) => console.log(`🚀 ~ file: DiscountsTable.jsx:toggleActive ~ await baseAuth.put('${endpoint}') ~ e:`, e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const save = async () => {
    if (!discountDraft?.typology) return;

    const newDiscount = {...discountDraft};

    newDiscount.max_cents_redeemable = Number(newDiscount.maxEurosRedeemable) * 100;
    newDiscount.value = Number(newDiscount.value);
    newDiscount.visibility_schedules = newDiscount.schedules;
    newDiscount.minimum_redeem_amount = Number(newDiscount.minimumRedeemEuros) * 100;

    if (discountDraft.products) {
      const productIds = Object.values(discountDraft.products).map(obj => obj.product_id);
      newDiscount.products = productIds
    };
    
    const endpoint = newDiscount.id
      ? `/discount/edit/${newDiscount.typology}/${newDiscount.id}/`
      : `/discount/create/${newDiscount.typology}/${selectedRestaurantId}/`;

    await baseAuth
      [newDiscount.id ? 'put' : 'post'](endpoint, newDiscount)
      .then((response) => {
        updateDiscount({...discountDraft, id: discountDraft.id || response.data.id }, {
          title: discountDraft.id ? 'discount_updated' : 'discount_created',
        }).finally(() => {
          testTableUrl ? setTab('visualize') : popupProps.close();
        });
      })
      .catch((e) => {
        console.log(`🐉 > file: DiscountModal.jsx:113 > save > ${endpoint}:`, e);
        logToServer(`🐉 > file: DiscountModal.jsx:113 > save > ${endpoint}:`, e, selectedRestaurantId);
      })
      .finally(() => setIsLoading(false));
  };

  if (!discountDraft.typology) return (
    <DiscountPopup {...popupProps}>
      <ChooseDiscount
        editDiscount={selectTypology}
        discount={discountDraft}
        activeGeneralDiscount={activeGeneralDiscount}
        activeProductDiscount={activeProductDiscount}
        close={popupProps.close}
      />
    </DiscountPopup>
  );

  return (
    <DiscountPopup {...popupProps}>
      <header>
        <div>
          <h2>
            {t(`${discountDraft.id ? 'edit' : 'create'}_${discountDraft.typology}`)}
          </h2>
          <ActiveSwitch
            checked={discountDraft.active}
            loading={isLoading}
            onChange={toggleActive}
          />
        </div>
        <TabSelector
          name={'discount_tabs'}
          value={tab}
          options={tabOptions}
          onChange={changeTab}
          disableTab={disableTab}
        />
      </header>
      {tab === DISCOUNT_TABS[0] &&
        <PromotionTexts
          editDiscount={editDiscount}
          discountDraft={discountDraft}
        />
      }
      {tab === DISCOUNT_TABS[1] &&
        <PromotionSpecs
          discount={discountDraft}
          catalog={catalog}
          discountToEdit={discount}
          editPromo={editDiscount}
        />
      }
      {tab === DISCOUNT_TABS[2] &&
        <PromotionLimitsTab
          discountDraft={discountDraft}
          editDiscount={editDiscount}
        />
      }
      {tab === DISCOUNT_TABS[3] &&
        <VisibilitySchedules
          discountDraft={discountDraft}
          editDiscount={editDiscount}
        />
      }
      {tab === 'visualize' &&
        <LoadPromotionAndVisualize
          discount={discountDraft}
          testTableUrl={testTableUrl}
          close={popupProps.close}
        />
      }
      <footer>
        <Button
          label={'cancel'}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={popupProps.close}
        />
        <Button
          label={tabsToCheck.length ? 'next' : 'save'}
          loading={isLoading}
          disabled={tabsToCheck.length ? disableNext(tab) : isSaveDiabled}
          onClick={tabsToCheck.length ? goNext : save}
        />
      </footer>
    </DiscountPopup>
  );
};
