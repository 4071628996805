import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from '../../data/authContext';
import "./DateSelect.css";
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { components } from "react-select";
import './RestaurantSelect.css'
import { SelectInput } from 'common/Input';

const MULTI_SELECT_LOCATIONS = [
  '/admin/orders/scan',
  '/admin/dashboard',
  '/admin/invoices',
  '/admin/orders',
  '/admin/catalog-dashboard',
  '/payments',
];

const NOT_TODOS_OPTION = [
  '/admin/orders/scan',
  '/admin/orders',
  '/admin/catalog-dashboard',
  '/payments',
];

export default function RestaurantSelect() {
  const { t } = useTranslation();
  const {
    state: { restaurants, selectedRestaurant },
    setSelectedRestaurant
  } = React.useContext(AuthContext);

  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();

  const path = history.location.pathname;

  const isLockersPage = path === '/admin/lockers';
  const isEventsPage = path === '/admin/event-tickets' || path === '/admin/create-events';
  const isScanOrderPage = path === '/admin/orders/scan';
  const isWalletPage = path === '/wallet/settings' || path === '/wallet/topups' || path === '/wallet/metrics' || path === '/wallet/users';

  const title = isEventsPage ? 'shop' : 'Restaurant';

  const [isMultipleSelectable, setIsMultipleSelectable] = useState(MULTI_SELECT_LOCATIONS.includes(path));

  const [restaurantsList, setRestaurantsList] = useState(restaurants || []);

  const handleSelect = (opcion) => {
    //if "todos" already selected, and selected another restaurant, remove "todos"
    const indexOfTodosInSelectedRestaurant = selectedRestaurant.map(e => e.label).indexOf(t("Todos"))
    if(indexOfTodosInSelectedRestaurant > -1){
      opcion.splice(indexOfTodosInSelectedRestaurant, 1)
    }
    
    //if restaurant already selected, and selected "todos", remove selected restaurant and leave "todos"
    if(Array.isArray(opcion)){
      const indexOfTodosInOption = opcion.map(e => e.label).indexOf(t("Todos"))
      if(indexOfTodosInOption > -1){
        opcion = [opcion[indexOfTodosInOption]]
      }
    }

    if(isMultipleSelectable && (!opcion || !opcion.length) && restaurants?.length > 1 && !NOT_TODOS_OPTION.includes(path)) {
      opcion = [{ value: null, label: t("Todos") }];
    }

    if (isMultipleSelectable) {
      setSelectedRestaurant(opcion);
    } else {
      setSelectedRestaurant([opcion]);
    }
  };

  useEffect(() => {
    if (restaurants) {
      //remove "todos" from list if NOT multiple selectable
      if(!isMultipleSelectable) {
        if (selectedRestaurant && selectedRestaurant.length > 1) {
          setSelectedRestaurant([selectedRestaurant[0]]);
        }
      }

      //add "todos" to list if multiple selectable
      if (restaurants && isMultipleSelectable && restaurants.length > 1 && !NOT_TODOS_OPTION.includes(path)) {
        restaurants.unshift({ value: null, label: t("Todos") });
      }

      let newRestaurantList = restaurants;

      if (isLockersPage) {
        newRestaurantList = restaurants.filter(restaurant => restaurant.show_lockers_page);
      }

      if (isEventsPage) {
        newRestaurantList = restaurants.filter(restaurant => restaurant.show_events_page);
      }

      if (isWalletPage) {
        newRestaurantList = restaurants.filter(restaurant => restaurant.show_wallet_page);
      }

      setRestaurantsList(newRestaurantList);
      
      if(!selectedRestaurant || selectedRestaurant.length === 0){
        setSelectedRestaurant([newRestaurantList[0]]);
      } else if (isScanOrderPage){
        setSelectedRestaurant(newRestaurantList);
      } else if (isLockersPage) {
        if (!selectedRestaurant[0].show_lockers_page) {
          const selectedWithLocker = selectedRestaurant?.find(restaurant => restaurant.show_lockers_page) || newRestaurantList[0];
          setSelectedRestaurant(selectedWithLocker ? [selectedWithLocker] : null);
        }
      } else if (isEventsPage) {
        if (!selectedRestaurant[0].show_lockers_page) {
          const selectedWithEvent = selectedRestaurant?.find(restaurant => restaurant.show_events_page) || newRestaurantList[0];
          setSelectedRestaurant(selectedWithEvent ? [selectedWithEvent] : null);
        }
      } else if (isWalletPage) {
        if (!selectedRestaurant[0].show_wallet_page) {
          const selectedWithWallet = selectedRestaurant?.find(restaurant => restaurant.show_wallet_page) || newRestaurantList[0];
          setSelectedRestaurant(selectedWithWallet ? [selectedWithWallet] : null);
        }
      }
    }
  }, [restaurants, isMultipleSelectable, history]);

  useEffect(() => {
    setIsMultipleSelectable(MULTI_SELECT_LOCATIONS.includes(path));
  }, [path])

  return (
    <SelectInput
      label={title}
      isMulti={isMultipleSelectable}
      menuPortalTarget={document.body}
      multiple={true}
      isClearable={false}
      components={{Option}}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      name={'restaurante'}
      placeholder={t(`Empezar a escribir restaurante...`)}
      options={restaurantsList}
      value={selectedRestaurant}
      onChange={handleSelect}
      isDisabled={!restaurantsList.length}
      className={!isRowBased ? "w-72" : "w-full"}
    />
  );
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        {props.isMulti &&
          <>
            <input
              className="checkboxRestaurantSelect"
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null()}
            />{" "}
          </>
        }
        <span></span>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};