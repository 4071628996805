import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Popup } from 'common/Popup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ConfirmPopup = ({isOpen, title, message, cancelLabel, confirmLabel, confirm, cancel}) => {
  const { t } = useTranslation();

  return (
    <Popup isOpen={isOpen} close={cancel}>
      <Container>
        {typeof title === 'string' ? <h2>{t(title)}</h2> : title}
        {typeof message === 'string' ? <p>{t(message)}</p> : message}
        <div>
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            label={t(cancelLabel || 'cancel')}
            onClick={cancel}
          />
          <Button
            label={t(confirmLabel || 'confirm')}
            onClick={confirm}
          />
        </div>
      </Container>
    </Popup>
  );
};

const Container = styled.div`
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0.5em;
    max-width: 50ch;
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2em;
    max-width: 50ch;
  }

  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }
`;