import { PhotographIcon, SelectorIcon } from "@heroicons/react/outline";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { allergensList } from "data/allergens";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import bocata from '../../../img/bocata.png';
import { DiscountBadge } from "./DiscountBadge";
import { calculateEuros } from "services/payments";
import { getImageUrl } from "logic/functions";

export const ProductCardMockup = ({ amplified, description, allergens, discount, badge, bigger, biggest, chooseDiscount, product }) => {
  const { t } = useTranslation();

  const randomAllergens = useMemo(
    () =>
      new Array(Math.floor(Math.random() * 3 + 2))
        .fill(null)
        .map((_, index) => (
          <span
            className={`${biggest ? "h-[12px] w-[12px]" : bigger ? "h-[8px] w-[8px]" : "h-[6px] w-[6px]"}`}
            key={"mock_allergen-" + index}
            style={{
              backgroundColor: `${
                allergensList[Math.floor(Math.random() * 14)].color
              }90`,
              borderRadius: "63% 37% 48% 52% / 66% 60% 40% 34% ",
            }}
          />
        )),
    []
  );

  const image = getImageUrl(product?.image || product?.product_image);

  const originalPrice = product ? calculateEuros(product?.product__tpv_price_cents) : 4.2;

  function isNumber(value) {
    return typeof value === 'number';
  }

  function convertToFloatWithDecimal(stringNumber) {
    let cleanedString = stringNumber.replace(',', '.');
    let floatValue = parseFloat(cleanedString);
    return parseFloat(floatValue.toFixed(2));
  }

  function calculateDiscountedPrice(originalPrice) {
    if (discount && !chooseDiscount && (discount.coupon_type === "percentage" || discount.discount_type === "percentage")) {
      const priceWithDiscount = discount.value && originalPrice - originalPrice * (discount.value / 100) > 0
        ? originalPrice - originalPrice * (discount.value / 100)
        : 0;
      return priceWithDiscount;
    }

    if (discount && !chooseDiscount && (discount.coupon_type === "amount" || discount.discount_type === "amount")) {
      const priceWithDiscount = discount.value && originalPrice - discount.value > 0
        ? originalPrice - discount.value
        : 0;
      return priceWithDiscount;
    }

    if(discount && chooseDiscount){
      return 3.8;
    }

    return originalPrice;
  }

  const finalOriginalPrice = isNumber(originalPrice) ? originalPrice : convertToFloatWithDecimal(originalPrice)

  const discountedPrice = calculateDiscountedPrice(finalOriginalPrice);

  return (
    <div className={`${badge ? "bg-white" : ""} ${biggest ? "min-w-[300px] rounded-lg shadow-md" : "rounded-md shadow"} w-full flex gap-2 p-1 text-[#404040] relative ${amplified ? 'amplified-class' : ''}`}>
      {badge &&
        <div className='absolute' style={{top: '-10px', left: '-6px'}}>
          <DiscountBadge discount={discount && discount.value ? discount.value : 20} />
        </div>
      }
      <div className={`${biggest ? 'w-14 h-14 rounded-md' : 'w-8 h-8 rounded'} bg-gray-200  flex overflow-hidden`}>
        {product && image ?
          <img src={image} className="object-cover h-full w-full" alt='image_product' />
          :
          <PhotographIcon height={biggest ? 24 : 14} className="m-auto text-gray-400" />
        }
        {!product &&
          <img src={bocata} alt='bocata' />
        }
      </div>
      <div className="flex-1 flex flex-col gap-[3px]">
        <h6 className={`${biggest ? "text-[16px]" : bigger ? "text-[12px]" : "text-[7px]"} leading-[1em] font-semibold`}>
          {product ? product.label : t("Product")}
        </h6>
        {description === "long" && (
          <p className={`${biggest ? "text-[14px]" : bigger ? "text-[10px]" : "text-[5px]"}`}>
            {product ? product.description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."}
          </p>
        )}
        {description === "short" && (
          <p className={`${biggest ? "text-[14px]" : bigger ? "text-[10px]" : "text-[5px]"}`}>
          {product ? product.description : "Lorem ipsum dolor sit amet."}
          </p>
        )}
        {allergens && <div className="flex gap-1">{randomAllergens}</div>}
      </div>
      <div className="flex flex-col justify-between items-end">
        <div className="flex flex-col">
          {(
            <b className={`${biggest ? "text-[14px]" : bigger ? "text-[10px]" : "text-[5px]"}`}>
              {discountedPrice.toFixed(2).replace(".", ",")}€
            </b>
          )}
          <b
            className={`${
              discountedPrice && !bigger && !biggest ? "line-through text-[4px] text-gray-400" : !bigger && !biggest ? "text-[7px]" 
              : discountedPrice && bigger ? "line-through text-[8px] text-gray-400" : bigger ? "text-[10px]"
              : discountedPrice && biggest ? "line-through text-[12px] text-gray-400" : biggest ? "text-[14px]" 
              : "text-[5px]"
            }`}
          >
            {isNumber(originalPrice) ? originalPrice.toFixed(2).replace(".", ",") : originalPrice}€
          </b>
        </div>
        <PlusCircleIcon height={biggest ? 18 : bigger ? 16 : 14} />
      </div>
    </div>
  );
};
