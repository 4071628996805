import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import XTextButton from 'common/XTextButton';
import { Context as AuthContext } from 'data/authContext';
import logo from 'img/logo.png';
import { api, apiAuth, translation } from 'logic/api';
import { bottomRoutes, routes } from "../../components/AdminRoutes"
import { admin_routes } from "../../components/SuperAdminRoutes"
import { waiter_routes } from "../../components/WaiterRoutes"
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { XInput } from 'styles/StyledComponents';
import background from '../../img/bg-login.svg'

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    state: { error },
    loadUser,
    loadSuperUser,
    setIsLoggedIn,
    setAllowedRoutes
  } = React.useContext(AuthContext);

  const [submitting, setSubmitting] = React.useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [state, setState] = React.useState({
    email: '',
    password: '',
    origin: 'backoffice'
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const tabsAndLanding = async(restaurants, boSections, tuser, res, landingRoute) => {
    if (restaurants.filter((item) => item.show_order_and_pay_tab_on_backoffice).length > 0) {
      const tabRes = await translation.get(`/tabs`);
      localStorage.setItem('tabs', JSON.stringify(tabRes.data.tabs));
    }
    if(boSections.length > 0){
      const buttons = tuser.data.is_superuser ? admin_routes(t, res.data.tabs, boSections) : tuser.data.rol !== "waiter" ? routes(t, res.data.tabs, boSections) : waiter_routes(t, res.data.tabs, boSections)
      const lastButtons = bottomRoutes({handleLogout: null, t: t, allowedRoutes: boSections})
      const allButtons = [...buttons, ...lastButtons];
      landingRoute = findLandingRoute(allButtons) 
      return landingRoute;
    }
    return landingRoute;
  }

  const findLandingRoute = (buttons) => {
    const button = buttons.find((button) => button.path || button.children.some(element => element.path));
    
    return button ? (button.path || (button.children.find(element => element.path) || {}).path) : null;
  };

  const handleLogin = async () => {
    setSubmitting(true);
    try {
      const res = await api.post(`/users/login`, state);
      const boSections = res.data.bo_sections
      setAllowedRoutes(boSections);
      localStorage.setItem('token', res.data.token);

      const tuser = await apiAuth.get(`/users/retrieve?w=1`);
      let landingRoute = tuser.data.rol === "waiter" ? '/payments' : '/admin/home'
      setIsLoggedIn(true);

      if (tuser.data.is_admin) {
        await loadUser(res.data.token);
        let restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
        landingRoute = await tabsAndLanding(restaurants, boSections, tuser, res, landingRoute) 

        if (tuser.data.rol === "waiter" && restaurants.length > 0) {
          localStorage.setItem('yumminn-path', 'payments');
          history.push(landingRoute);
        } else if(restaurants.length > 0){
          localStorage.setItem('yumminn-path', 'home');
          history.push(landingRoute);
        } else {
          toast.error("Error")
        }
      } else if (tuser.data.is_superuser || tuser.data.is_investor) {
          await loadSuperUser(res.data.token);
          let restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
          landingRoute = await tabsAndLanding(restaurants, boSections, tuser, res, landingRoute);

          localStorage.setItem('yumminn-path', 'dashboard');
          if(restaurants.length > 0){
            history.push(landingRoute);
          }
          else{
            toast.error("Error")
          }
      }
    } catch (error) {
        if(error && error.response && error.response.data === "Invalid credentials"){
          toast.error(t('Invalid sent data'))
        } else{
          toast.error(error)
        }
      } finally {
        setSubmitting(false);
      }
    };

  return (
    <div className='flex h-screen w-full justify-center items-center bg-gray-100 bg-right-top bg-no-repeat'  style={{backgroundImage: `url(${background})`, backgroundSize: window.innerWidth > 768 ? '90%' : 'auto'}}>
      <form onSubmit={() => console.log('first')} className='flex flex-col w-3/4 md:w-1/2 lg:max-w-md md:bg-white h-50 p-3 flex-column justify-around items-center space-y-4 bg-white shadow-sm rounded-xl'>
        <div className={'w-3/4 md:w-1/2 flex justify-center'}>
          <img src={logo} alt='logo' />
        </div>
        <div className={'px-2 w-full'}>
          <div className={'font-medium text-sm text-capitalize py-2'}>
            {t('email')}*
          </div>
          <XInput
            icon={
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                />
              </svg>
            }
            type='text'
            name='email'
            placeholder={t('email')}
            value={state.email || ''}
            doChange={handleChange}
          />
        </div>
        <div className={'px-2 w-full relative'}>
          <div className={'font-medium text-sm text-capitalize py-2'}>
            {t('password')}*
          </div>
          <XInput
            icon={
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'
                />
              </svg>
            }
            type={isPassVisible ? 'text' : 'password'}
            name='password'
            placeholder={t('password')}
            value={state.password || ''}
            doChange={handleChange}
          />
          <div className='h-6 w-6 mx-4 my-2.5 text-trueGray-700 absolute right-0 bottom-0 cursor-pointer' onClick={() => setIsPassVisible(wasVisible => !wasVisible)}>
            {isPassVisible ? <EyeIcon/> : <EyeOffIcon/>
            }
          </div>
        </div>

        <XTextButton
          type='submit'
          disabled={submitting}
          submitting={submitting}
          doClick={handleLogin}
        >
          {t('login')}
        </XTextButton>

        <Link to='/forgot'>
          <div style={{ textAlign: 'center' }}>{t('forgot_password')}</div>
        </Link>
      </form>
    </div>
  );
};

export default Login;
