import React from 'react';
import { useTranslation } from "react-i18next";

const ShiftsHeader = ({disabled}) => {
  console.log(disabled)
  const { t } = useTranslation();

  return (
    <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr .7fr", textAlign: 'center', width: "100%", backgroundColor: "#FCFCFC", color: disabled ? "#CCC": "#404040", justifyContent: 'space-around', fontSize: "16px", fontWeight: 600, padding: "15px 0px", borderBottom: "2px solid #D1D1D1"}}>
		<div>{t('Shift')}</div>
		<div>{t('schedule')}</div>
		<div>{t('Slot duration')}</div>
		<div>{t('Slots quantity')}</div>
		<div>{t('Dishes quantity')}</div>
		<div>{t('Status')}</div>
		<div/>
	</div>
  );
};
export default ShiftsHeader;
