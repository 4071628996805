import React from "react";

export const icons = {
  delete: <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
  </svg>,
  duplicate: <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"/>
  </svg>,
  add: <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>,
  cancel: <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
  </svg>,
  select: <svg className="w-5 h-5" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l4-4 4 4m0 6l-4 4-4-4"/>
  </svg>,
  template: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
          d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"/>
  </svg>,
  download: <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
  </svg>,
  export: <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
  </svg>,
  up: <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
  </svg>,
  down: <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
  </svg>,
  updown: <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
  </svg>,

}

export const XButton = ({children, doClick}) => (
  <button
    type={"button"}
    className={"rounded-full cursor-pointer shadow-md p-2 m-1 text-principal bg-gray-50 hover:bg-principal hover:text-white"}
    onClick={doClick}
  >
    {children}
  </button>
)

export const XTextButton = ({children, submitting, doClick}) => (
  <div className={"px-2 w-full"}>
    <button
      type={"button"}
      disabled={submitting}
      className={"rounded-full w-full cursor-pointer shadow-md py-2 px-5 m-1 text-principal bg-gray-50 hover:bg-principal hover:text-white"}
      onClick={doClick}
    >
      <div className={"flex space-sm justify-center"}>
        {submitting &&
        <div>
          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black-50" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
        </div>
        }
        <div>{children}</div>
      </div>
    </button>
  </div>
)

export const XInput = ({icon, doChange, ...rest}) => (
  <div
    className={"flex justify-start items-center rounded-lg border-solid box-border border border-gray-200 p-1w-full"}>
    <div className={"m-2 text-principal"}>
      {icon}
    </div>
    <input
      {...rest}
      onChange={doChange}
      className={"w-full p-2 bg-white"}
    />
  </div>
)

export const XSwitch = ({title, state, onState, offState, doState}) => {
  return (
    <div>
      <div className={"font-medium text-sm text-capitalize py-2"}>{title}</div>
      <div className={"flex space-x-3"} onClick={() => {
        doState(state === onState ? offState : onState)
      }}>
        <div>{offState}</div>
        <div className={"w-11 bg-green-100 rounded-pill relative"}>
          <div
            className={`w-6 h-6 rounded-full shadow-md bg-principal absolute ${state === onState ? "transform translate-x-5 duration-500 ease-in-out" : "transform translate-x-0 duration-500 ease-in-out"} `}>&nbsp;</div>
        </div>
        <div>{onState}</div>
      </div>
    </div>
  )
}

