import { allergensList } from 'data/allergens';
import i18next from 'i18next';

export const sinolist = [
  { value: false, name: 'No' },
  { value: true, name: 'Si' },
];

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

export const DAYS = [
  { id: '1', day: i18next.t('Monday') },
  { id: '2', day: i18next.t('Tuesday') },
  { id: '3', day: i18next.t('Wednesday') },
  { id: '4', day: i18next.t('Thursday') },
  { id: '5', day: i18next.t('Friday') },
  { id: '6', day: i18next.t('Saturday') },
  { id: '7', day: i18next.t('Sunday') },
];

export const select_styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: (state.isSelected) ? '#5FB894' : '#D4D4D8',
    borderWidth: '2px',
    borderRadius: '8px',
    backgroundColor: state.isDisabled ? '#F6F6F6' : '#FFF',
    opacity: state.isDisabled ? '0.7' : '1',
    boxShadow: 'none',
    outline: state.isFocused ? '2px solid #5FB894' : 'none',
    outlineOffset: '2px',
    '&:hover': {
      color: '#5FB894',
      borderColor: state.isFocused ? '#5FB894' : '#D4D4D8',
    }
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 99999999,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = '#5FB894';
    const colorfocus = '#5FB89433';
    return {
      ...styles,
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color
        : isFocused
        ? colorfocus
        : null,
    };
  },
  placeholder: (styles) => ({
      ...styles,
      fontFamily: 'Poppins, sans-serif',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      '&:hover': {
        color: '#5FB894',
      }
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    paddingRight: state.isDisabled ? '3px' : '',
    flexShrink: 0,
  }),
  multiValueRemove: (baseStyles, state) => ({
  ...baseStyles,
    display: state.isDisabled ? 'none' : '',
    color: '#404040'
  }),
  valueContainer: (baseStyles) => ({
  ...baseStyles,
    flexWrap: 'nowrap',
    overflow: 'hidden',
  }),
  dropdownIndicator: (styles, {isFocused}) => ({
    ...styles,
    color: 'inherit',
    fill: 'currentColor',
    rotate: isFocused ? '180deg' : '0deg',
    transition: '0.15s ease-in-out',
    '&:hover': {
      fill: 'currentColor',
    }
  }),
};

export const select_styles_month = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: base => ({
    ...base,
    fontSize: '12px',
    fontFamily: 'Poppins, sans-serif',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = '#5FB894';
    const colorfocus = '#5FB89433';
    return {
      ...styles,
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color
        : isFocused
        ? colorfocus
        : null,
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400
    }
  }
};

export const sayError = (err) => {
  let str = [];
  for (const key in err) {
    if (Array.isArray(err[key])) {
      for (const error of err[key]) {
        str.push(<div key={error}>{error}</div>);
      }
    } else {
      str = err[key];
    }
  }
  return <div>{str}</div>;
};

export const select_allergens_styles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = '#5FB894';
    const colorfocus = '#5FB89433';
    return {
      ...styles,
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color
        : isFocused
        ? colorfocus
        : null,
    };
  },
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? '#5FB894' : '#D4D4D8',
    borderWidth: '2px',
    borderRadius: '8px',
    backgroundColor: state.isDisabled ? '#F6F6F6' : '#FFF',
    opacity: state.isDisabled ? '0.7' : '1',
    height: '40px',
    boxSizing: 'border-box',
  }),
  multiValue: (styles, { data }) => {
    const colors = allergensList.reduce((obj, {label, color}) => {
      obj[label] = color;
      return obj;
    }, {default: '#999'});
    return {
      ...styles,
      color: '#FFF',
      backgroundColor: colors[data.label] || colors.default,
      borderRadius: '8px',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#FFF',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    borderRadius: '2px 8px 8px 2px',
    ':hover': { backgroundColor: '#0002' },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
  }),
  input: (styles) => ({
    ...styles,
    height: '100%',
    margin: '0'
  }),
};

export const categories_select_styles = {
  ...select_styles,
  valueContainer: (baseStyles) => ({
    ...baseStyles,
      flexWrap: 'nowrap',
    }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: "14px",
    color: "#575757"
  })
};

export const product_select_styles = {
  ...select_styles,
  valueContainer: (baseStyles) => ({
    ...baseStyles,
      overflowX: 'auto', 
      overflowY: 'scroll',
      maxWidth: '100%',
      maxHeight: '90px'
    }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: "14px",
    color: "#575757"
  })
}

export const theme_select_styles = {
  ...select_styles,
  singleValue: (base) => ({
    ...base,
    overflow: 'visible',
  }),
  menu: (base) => ({
    ...base,
    minWidth: 'fit-content',
  }),
};

export const locker_select_styles = {
  ...select_styles,
  menu: (styles) => ({
    ...styles,
    minWidth: 'min-content',
    border: '1.5px solid #DDDDDD',
    borderRadius: '10px',
    boxShadow: '0 4px 8px #00000015',
  }),
  menuList: (styles) => ({
    ...styles,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '8px 16px',
    minWidth: 'min-content',
    zIndex: '999',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const colorDefault = '#FCFCFC';
    const colorSelected = '#5FB894';
    const colorfocus = '#5FB89433';
    return {
      ...styles,
      fontFamily: 'Poppins, sans-serif',
      borderRadius: '10px',
      opacity: isDisabled ? 0.5 : 1,
      backgroundColor:
        isSelected ? colorSelected
        : isFocused ? colorfocus
        : colorDefault,
    };
  },
  singleValue: (styles) => ({
    ...styles,
    width: 'min-content',
    opacity: 0.6,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
};

export const locker_manage_select_styles = {
  ...locker_select_styles,
  control: (styles, state) => ({
    ...locker_select_styles.control(styles, state),
    minHeight: '40px',
    borderRadius: '0.6em',
    backgroundColor: 'transparent',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0.325em 0.5em',
  }),
  singleValue: (styles) => ({
    ...locker_select_styles.singleValue(styles),
    opacity: 1,
  }),
};

export const locker_key_select_styles = {
  ...select_styles,
  control: (styles, state) => ({
    ...select_styles.control(styles, state),
    height: '44px',
    backgroundColor: state.isDisabled ? '#EEEEEE' : '',
    borderRadius: '10px',
    opacity: state.isDisabled ? 0.8 : 1,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
};

export const order_item_select_styles = {
  ...select_styles,
  control: (styles, state) => ({
    ...select_styles.control(styles, state),
    border: 'none',
    outline: 'none',
    color: '#404040',
    margin: '-6px -8px',
    '&:hover': {
      color: '#5FB894',
    }
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 'var(--font_sm)',
    color: 'inherit',
    '&:hover': {
      color: '#5FB894',
    }
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    right: "-16px",
    width: "max-content",
    minWidth: "200px",
  }),
  menuList: (styles) => ({
    ...styles,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '8px',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const colorDefault = '#FCFCFC';
    const colorSelected = '#5FB89433';
    const colorfocus = '#5FB89420';
    return {
      ...styles,
      fontFamily: 'Poppins, sans-serif',
      borderRadius: '10px',
      opacity: isDisabled ? 0.5 : 1,
      backgroundColor:
        isSelected ? colorSelected
        : isFocused ? colorfocus
        : colorDefault,
    };
  },
};

export const visibilityTranslation = [
  { label: i18next.t("not_visible"), value: 0 },
  { label: i18next.t("visible"), value: 1 },
  { label: i18next.t("visibility_schedule"), value: 2 },
];

export const simpleVisibilityTranslation = [{ label: i18next.t("not_visible"), value: 0 }, { label: i18next.t("visible"), value: 1 }]
