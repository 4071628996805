import { ChevronDownIcon, ChevronLeftIcon, FilterIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardMockup } from '../components';
import '../../../styles/animations.css';
import { STYLES } from './CategoryStyle';
import { SuggestionProductCardMockup } from '../components/SuggestionProductCardMockup';
import styled from "styled-components";

export const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 32px;
`;

export const SuggestionsContainer = styled.div`
  display:grid;
  grid-template-columns: auto auto;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  justify-content: center;
  margin: 5px 0px 0px 0px;
`;

export const SuggestionsPreview = ({groupType, name, products}) => {
  const { t } = useTranslation();

  const isSupergroup = groupType === 'SUPER_GROUP';
  const isGroup = groupType === 'CATEGORY_GROUP';
  const isCategory = groupType === 'CATEGORY';
  const cardsToShow = 4
  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full p-1 pb-[2px] flex items-center gap-1 relative bg-[#404040] '>
        <ChevronLeftIcon width={13} className='flex-shrink-0 mr-auto text-white'/>
        <span className={STYLES.SELECTED_SUPERGROUP + (isSupergroup ? 'intermitent-outline' : '')}>
          {isSupergroup && name ? name : t('group')}
        </span>
        <span className={STYLES.SUPERGROUP}>{t('group')}</span>
        <span className={STYLES.SUPERGROUP}>{t(t('group'))}</span>
        <FilterIcon width={13} className='flex-shrink-0 ml-auto opacity-0'/>
        {!isSupergroup &&
          <div className={STYLES.EXTRA_OVERLAY}/>
        }
      </div>
      <div className='w-full flex-1 flex flex-col relative'>
        <div className='w-full px-2 py-[2px] flex items-center gap-1 relative'>
          <span className={(isGroup || isCategory ? STYLES.EXPANDED_GROUP : STYLES.COLAPSED_GROUP) + (isGroup ? 'intermitent-outline' : '')}>
            {isGroup && name ? name : t('category')}
            <ChevronDownIcon width={10} className={`flex-shrink-0 ${isCategory ? 'transform rotate-180' : ''}`}/>
          </span>
          <span className={STYLES.GROUP}>
            {t('category')}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          <span className={STYLES.GROUP}>
            {t('category')}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          <span className={STYLES.GROUP}>
            {t('category')}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          {isCategory &&
            <div className={STYLES.EXTRA_OVERLAY}/>
          }
        </div>
        {isCategory &&
          <div className='w-full px-2 py-[2px] text-[#A0A0A0] flex items-center gap-1'>
            <span className={isCategory ? STYLES.SELECTED_CATEGORY : STYLES.CATEGORY + 'text-white intermitent-outline'}>
              {isCategory && name ? name : t('category')}
            </span>
            <span className={STYLES.CATEGORY}>{t('category')}</span>
            <span className={STYLES.CATEGORY}>{t('category')}</span>
            <span className={STYLES.CATEGORY}>{t('category')}</span>
          </div>
        }
        <div className='flex-1 flex flex-col gap-2 p-2 relative'>
          <ProductCardMockup allergens description={'long'}/>
          <ProductCardMockup allergens description={'short'}/>
          <ProductCardMockup allergens description={'long'}/>
          <ProductCardMockup allergens description={'short'}/>
          <ProductCardMockup allergens description={'short'}/>
        </div>
        <div className={STYLES.EXTRA_OVERLAY}/>
        <div style={{width: '100%', height: '95%', bottom: 0, borderRadius: '15px 15px 0px 0px', position: 'absolute', backgroundColor: 'white'}}>
          <div style={{fontSize: '8px', width: '100%', textAlign: 'center', fontWeight: 600, margin: '10px 0px'}}>¿Te gustaría añadir algo más?</div>
          <SuggestionsWrapper>
            <SuggestionsContainer id="scroll">
              {products.slice(0, cardsToShow).map((product, index) => (
              <SuggestionProductCardMockup product={product}/>
            ))}
            
            {(Array.from(Array(Math.abs(products.length < 4 ? products.length - 4 : 0)).keys())).map(() => (
              <SuggestionProductCardMockup product={null}/>
            ))}
            </SuggestionsContainer>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
              <button style={{backgroundColor: '#5FB894', borderRadius: '99999px', color: 'white', fontWeight: 500, width: "70%", padding: '2px 0px', fontSize: '8px'}}>
                Continuar
							</button>
            </div>
          </SuggestionsWrapper>
        </div>
      </div>
        
    </div>
  );
};