import moment from "moment";
import { centsToAmountString } from "logic/functions";

export const calcDelta = (current, previous) => {
  if(current === previous){
    return 0
  }
  if (current === 0) {
    return -100;
  } else if (previous === 0) {
    return 100;
  } else {
    return ((current - previous) / previous) * 100;
  }
};

const LINE_CHARTS = [
  'payments',
  'tips',
  'transactions',
  'users',
  'cashbackGranted',
  'cashbackRedeemed',
  'totalActiveCredits',
  'totalCreditUsers',
  'totalCreditsUsed',
  'totalInvestedInIncentives'
];

const dateLabels = {
  hours: (item) => `${item.hour.toString().padStart(2, '0')}:00`,
  days: (item) => `${item.day}/${item.month}`,
  weeks: (item) => `W${item.week.toString().padStart(2, '0')}`,
  months: (item) => `${item.month}/${item.year}`,
  years: (item) => `${item.year}`,
};

const lineChartValues = {
  payments: (actual, previous) => ({
    curr: actual.base_amount_cents + actual.tip_cents - actual.general_discount_cents - actual.product_discount_cents,
    prev: previous.base_amount_cents + previous.tip_cents - previous.general_discount_cents - previous.product_discount_cents,
  }),
  tips: (actual, previous) => ({
    curr: actual.tip_cents,
    prev: previous.tip_cents,
  }),
  transactions: (actual, previous) => ({
    curr: actual.transactions,
    prev: previous.transactions,
  }),
  users: (actual, previous) => ({
    curr: actual.registered_users,
    prev: previous.registered_users,
  }),
  cashbackGranted: (actual, previous) => ({
    curr: actual.generated_loyalty_cents,
    prev: previous.generated_loyalty_cents,
  }),
  cashbackRedeemed: (actual, previous) => ({
    curr: actual.spent_loyalty_cents,
    prev: previous.spent_loyalty_cents,
  }),
  totalActiveCredits: (actual, previous) => ({
    curr: actual.total_active_credits,
    prev: previous.total_active_credits,
  }),
  totalInvestedInIncentives: (actual, previous) => ({
    curr: actual.total_incentive_credits,
    prev: previous.total_incentive_credits,
  }),
  totalCreditsUsed: (actual, previous) => ({
    curr: actual.credit_cents,
    prev: previous.credit_cents,
  }),
  totalCreditUsers: (actual, previous) => ({
    curr: actual.clients_with_credits,
    prev: previous.clients_with_credits,
  }),
};

const addLineChartItem = (chart, data, actual, previous, interval) => {
  const newData = {...data};
  try {
    const {curr, prev} = lineChartValues[chart](actual, previous);
    const date = dateLabels[interval](actual);
    const prevDate = dateLabels[interval](previous)
  
    newData.current += curr;
    newData.previous += prev;
    newData.delta = newData.previous ? (newData.current - newData.previous) * 100 / newData.previous : 0;
    newData.chart.push({date, prevDate, curr, prev});
  } catch (error) {
    console.log("🚀 ~ file: dashboardFunctions.js:58 ~ actualValues.forEach ~ error:", error);
  }
  return newData;
};

const groupByDate = (array, item, interval) => {
  const DATES = ['year', 'month', 'week', 'day', 'hour'];
  try {
    if (interval === 'weeks') {
      const {year, month, day} = item;

      item.week = Number(moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('W'));
      delete item.month;
      delete item.day;
    }
    
    if (interval === 'months'){
      delete item.day;
    }

    const index = array.findIndex(element => DATES.every(date => element[date] === item[date]))

    index === -1 ? array.push(item) : Object.keys(item).forEach(key => !DATES.includes(key) && (array[index][key] += item[key]));
  } catch (error) {
    console.log("🚀 ~ file: dashboardFunctions.js:92 ~ groupByDate ~ error:", error);
    console.table(item);
  }

  return array;
};

export const formatDashboardData = (data, interval) => {
  if (!data) return;
  //transforms prev and actual lists from BE response to data the graphs can interpret
  
  const chartsData = LINE_CHARTS.reduce((charts, chart) => {
    charts[chart] = {
      current: 0,
      previous: 0,
      delta: 0,
      chart: [], // {curr: INTEGER, prev: INTEGER, date: STRING}[]
    };

    return charts;
  }, {});

  if (data.actual && data.prev) {
    const actualValues = data.actual?.reduce((array, item) => groupByDate(array, item, interval), []);
    const prevValues = data.prev?.reduce((array, item) => groupByDate(array, item, interval), []);

    //if (actualValues.length !== prevValues.length) 

    actualValues.forEach((actual, index) => {
      const previous = prevValues[index];
      
      for (const chart in chartsData) {
        const data = chartsData[chart];
        chartsData[chart] = addLineChartItem(chart, data, actual, previous, interval);
      };
    });
  };
  return chartsData;
};

export const formatUsersBarChartData = (data, interval) => {
  if (!data) return [];

  const actualValues = data.actual.reduce((array, item) => groupByDate(array, item, interval), []);
  const prevValues = data.prev.reduce((array, item) => groupByDate(array, item, interval), []);

  return actualValues.map((item, index) => ({
    label: dateLabels[interval](item),
    clients_who_payed_just_once: item.clients_who_payed_just_once,
    clients_who_repeated: item.clients_who_repeated,
    prev_clients_who_payed_just_once: prevValues[index].clients_who_payed_just_once,
    prev_clients_who_repeated: prevValues[index].clients_who_repeated,
  }));
};


export 	const formatOverviewDataOld = (data) => {
  console.log("🚀 ~ file: dashboardFunctions.js:125 ~ formatOverviewData ~ data:", data)
  const IGNORE_KEYS = ['year', 'month', 'day', 'hour', 'restaurant'];

  const sumData = (object, item) => {

    try {
      Object.keys(item).forEach(key => {
        if (IGNORE_KEYS.includes(key)) return;
  
        object[key] = object[key] ? object[key] + item[key] : item[key];
      })
    } catch (error) {
      console.log("🚀 ~ file: dashboardFunctions.js:137 ~ sumData ~ error:", error);
      console.table(item);
    }
    
    return object;
  };

  const calcPayments = (paymentData) => paymentData.base_amount_cents + paymentData.tip_cents - paymentData.general_discount_cents - paymentData.product_discount_cents;

  const calcUsers = (usersData) => usersData.clients_who_payed_just_once + usersData.clients_who_repeated;
  
  const calcStars = (starsData) => {
    const COUNT = ['one', 'two', 'three', 'four', 'five'];

    const totalStars = COUNT.reduce((total, num, index) => total += starsData[num + '_star_reviews'] * index + 1, 0);
    const totalQuantity = COUNT.reduce((total, num) => total += starsData[num + '_star_reviews'], 0);

    return {totalQuantity, avgStars: totalQuantity ? totalStars / totalQuantity : 0};
  };

  const current = data.period.actual.reduce(sumData, {});
  const previous = data.period.prev.reduce(sumData, {});

  const currentDay = data.today.actual.reduce(sumData, {});
  const previousDay = data.today.prev.reduce(sumData, {});

  const currentWeek = data.week.actual.reduce(sumData, {});
  const previousWeek = data.week.prev.reduce(sumData, {});

  const currentMonth = data.month.actual.reduce(sumData, {});
  const previousMonth = data.month.prev.reduce(sumData, {});
  
  const paymentsCurrent = calcPayments(current);
  const paymentsPrevious = calcPayments(previous);

  const transactionsCurrent = current.transactions;
  const transactionsPrevious = previous.transactions;

  const avgTicketCurrent = transactionsCurrent ? paymentsCurrent / transactionsCurrent : 0;
  const avgTicketPrevious = transactionsPrevious ? paymentsPrevious / transactionsPrevious : 0;

  const starsCurrent = calcStars(current);
  const starsPrevious = calcStars(previous);

  const payments = {
    total_period: {
      value: paymentsCurrent,
      delta: calcDelta(paymentsCurrent, paymentsPrevious),
    },
    transactions: {
      value: transactionsCurrent,
      delta: calcDelta(transactionsCurrent, transactionsPrevious),
    },
    average_ticket: {
      value: avgTicketCurrent,
      delta: calcDelta(avgTicketCurrent, avgTicketPrevious),
    },
    today: {
      value: calcPayments(currentDay),
      delta: calcDelta(calcPayments(currentDay), calcPayments(previousDay)),
    },
    last_week: {
      value: calcPayments(currentWeek),
      delta: calcDelta(calcPayments(currentWeek), calcPayments(previousWeek)),
    },
    last_month: {
      value: calcPayments(currentMonth),
      delta: calcDelta(calcPayments(currentMonth), calcPayments(previousMonth)),
    },
  };

  const tips = {
    total_period: {
      value: current.tip_cents,
      delta: calcDelta(current.tip_cents, previous.tip_cents),
    },
    payments_with_tip: {
      value: 0,
      delta: 0,
    },
    tip_on_payment: {
      value: 0,
      delta: 0,
    },
    today: {
      value: currentDay.tip_cents,
      delta: calcDelta(currentDay.tip_cents, previousDay.tip_cents),
    },
    last_week: {
      value: currentWeek.tip_cents,
      delta: calcDelta(currentWeek.tip_cents, previousWeek.tip_cents),
    },
    last_month: {
      value: currentMonth.tip_cents,
      delta: calcDelta(currentMonth.tip_cents, previousMonth.tip_cents),
    },
  };

  const restaurants = {
    total: {
      value: 1,
      delta: calcDelta(0, 0),
    },
    with_payments: {
      value: 1,
      delta: calcDelta(0, 0),
    },
    payments_per_restaurant: {
      value: 1,
      delta: calcDelta(0, 0),
    },
  };

  const users = {
    week: {
      new: currentWeek.clients_who_payed_just_once,
      recurrent: currentWeek.clients_who_repeated,
      delta: calcDelta(calcUsers(currentWeek), calcUsers(previousWeek)),
    },
    month: {
      new: currentMonth.clients_who_payed_just_once,
      recurrent: currentMonth.clients_who_repeated,
      delta: calcDelta(calcUsers(currentMonth), calcUsers(previousMonth)),
    },
    year: {
      new: 0,
      recurrent: 0,
      delta: 0,
    },
    historic: 0,
  };

  const loyalty = {
    cashback_granted: {
      value: current.generated_loyalty_cents,
      delta: calcDelta(current.generated_loyalty_cents, previous.generated_loyalty_cents),
    },
    cashback_redeemed: {
      value: current.spent_loyalty_cents,
      delta: calcDelta(current.spent_loyalty_cents, previous.spent_loyalty_cents),
    },
    avg_cashback_per_user: {
      value: 0,
      delta: 0,
    },
    points_granted: {
      value: current.generated_points,
      delta: calcDelta(current.generated__points, previous.generated__points),
    },
    avg_points_per_user: {
      value: 0,
      delta: 0,
    },
    restaurants: {
      with_cashback: 0,
      total: 0,
      delta: 0,
    },
  };

  const reviews = {
    total_reviews: {
      value: starsCurrent.totalQuantity,
      delta: calcDelta(starsCurrent.totalQuantity, starsPrevious.totalQuantity),
    },
    average_stars: {
      value: starsCurrent.avgStars,
      delta: calcDelta(starsCurrent.avgStars, starsPrevious.avgStars),
    },
    reviews: [
      {stars: 1, quantity: current.one_star_reviews},
      {stars: 2, quantity: current.two_star_reviews},
      {stars: 3, quantity: current.three_star_reviews},
      {stars: 4, quantity: current.four_star_reviews},
      {stars: 5, quantity: current.five_star_reviews},
    ],
    restaurants: {
      with_reviews: 0,
      total: 0,
      delta: 0,
    },
  };

  return {payments, tips, restaurants, users, loyalty, reviews};
};

export const formatOverviewData = (data) => {

  const periodData = data.period;
  const todayData = data.today;
  const weekData = data.week;
  const monthData = data.month;
  
  const totalRevenueActual = periodData.actual?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0);
  const totalRevenuePrev = periodData.prev?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0);

  const totalTipsActual = periodData.actual?.reduce((acc, obj) => acc + obj.tip_cents, 0);
  const totalTipsPrev = periodData.prev?.reduce((acc, obj) => acc + obj.tip_cents, 0);
  
  const restaurantsWithReviewsActual = periodData.actual?.reduce((maxValue, obj) => Math.max(maxValue, obj.restaurants_with_reviews), 0);
  const restaurantsWithReviewsPrev = periodData.prev?.reduce((maxValue, obj) => Math.max(maxValue, obj.restaurants_with_reviews), 0);

  const transactionsCurrent = periodData.actual?.reduce((acc, obj) => acc + obj.transactions, 0);
  const transactionsPrevious = periodData.prev?.reduce((acc, obj) => acc + obj.transactions, 0);

  const avgTicketCurrent = transactionsCurrent > 0 ? totalRevenueActual / transactionsCurrent : 0;
  const avgTicketPrevious = transactionsPrevious > 0 ? totalRevenuePrev / transactionsPrevious : 0;
  
  //const paymentWithTipsCurrent = periodData.actual.reduce((maxValue, obj) => Math.max(maxValue, obj.transactions_with_tips), 0);
  //const paymentWithTipsPrevious = periodData.prev.reduce((maxValue, obj) => Math.max(maxValue, obj.transactions_with_tips), 0);

  const paymentWithTipsCurrent = periodData.actual?.reduce((acc, obj) => acc + obj.transactions_with_tips, 0);
  const paymentWithTipsPrevious = periodData.prev?.reduce((acc, obj) => acc + obj.transactions_with_tips, 0);

  const percentPaymentsWithTipsCurrent = transactionsCurrent > 0 ? (paymentWithTipsCurrent / transactionsCurrent) * 100 : 0;
  
  const tipOnPaymentCurrent = totalRevenueActual > 0 ? (totalTipsActual * 100) / Math.round(totalRevenueActual) : 0;
  const tipOnPaymentPrevious = totalRevenuePrev > 0 ? (totalTipsPrev * 100) / Math.round(totalRevenuePrev) : 0;

  const totalLoyaltyGrantedActual = periodData.actual?.reduce((acc, obj) => acc + obj.generated_loyalty_cents, 0);
  const totalLoyaltyGrantedPrev = periodData.prev?.reduce((acc, obj) => acc + obj.generated_loyalty_cents, 0);

  //console.log(totalLoyaltyGrantedActual, 'totalLoyaltyGrantedActual');
  
  const totalLoyaltyUsedActual = periodData.actual?.reduce((acc, obj) => acc + obj.spent_loyalty_cents, 0);
  const totalLoyaltyUsedPrev = periodData.prev?.reduce((acc, obj) => acc + obj.spent_loyalty_cents, 0);

  const totalPointsGrantedActual = periodData.actual?.reduce((acc, obj) => acc + obj.generated_points, 0);
  const totalPointsGrantedPrev = periodData.prev?.reduce((acc, obj) => acc + obj.generated_points, 0);

  const totalClientsWithGeneratedLoyaltyCentsActual = periodData.actual?.reduce((acc, obj) => acc + obj.clients_with_generated_loyalty_cents, 0);
  const totalClientsWithGeneratedLoyaltyCentsPrev = periodData.prev?.reduce((acc, obj) => acc + obj.clients_with_generated_loyalty_cents, 0);

  const totalClientsWithGeneratedLoyaltyPointsActual = periodData.actual?.reduce((acc, obj) => acc + obj.clients_with_generated_loyalty_points, 0);
  const totalClientsWithGeneratedLoyaltyPointsPrev = periodData.prev?.reduce((acc, obj) => acc + obj.clients_with_generated_loyalty_points, 0);

  const totalPaymentsWithCashbackActual = periodData.actual?.reduce((acc, obj) => acc + obj.transactions_with_loyalty, 0);
  const totalPaymentsWithCashbackPrev = periodData.prev?.reduce((acc, obj) => acc + obj.transactions_with_loyalty, 0);

  const totalPointsUsedActual = periodData.actual?.reduce((acc, obj) => acc + obj.spent_points, 0);
  const totalPointsUsedPrev = periodData.prev?.reduce((acc, obj) => acc + obj.spent_points, 0);

  const clientsWhoRepeatedActual = periodData.actual?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0);
  const clientsWhoRepeatedPrev = periodData.prev?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0);

  const clientsWhoPaidOnceActual = periodData.actual?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0);
  const clientsWhoPaidOncePrev = periodData.prev?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0);

  const paymentsWithClientActual = periodData.actual?.reduce((acc, obj) => acc + obj.payments_with_client, 0);
  const paymentsWithClientPrev = periodData.prev?.reduce((acc, obj) => acc + obj.payments_with_client, 0);

  const totalClientsActual = clientsWhoRepeatedActual + clientsWhoPaidOnceActual;
  const totalClientsPrev = clientsWhoRepeatedPrev + clientsWhoPaidOncePrev;

  //TODAYS DATA

  const totalRevenueTodayActual = todayData ? todayData.actual?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0) : 0;
  const totalRevenueTodayPrev = todayData ? todayData.prev?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0) : 0;

  const totalTipsTodayActual = todayData ? todayData.actual?.reduce((acc, obj) => acc + obj.tip_cents, 0) : 0;
  const totalTipsTodayPrev = todayData ? todayData.prev?.reduce((acc, obj) => acc + obj.tip_cents, 0) : 0;

  const recurringUsersTodayActual = todayData ? todayData.actual?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0) : 0;
  const recurringUsersTodayPrev = todayData ? todayData.prev?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0) : 0;

  const clientsWhoPaidOnceTodayActual = todayData ? todayData.actual?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0): 0;
  const clientsWhoPaidOnceTodayPrev = todayData ? todayData.prev?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0): 0;

  //WEEKS DATA

  const totalRevenueWeekActual = weekData ? weekData.actual?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0) : 0;
  const totalRevenueWeekPrev = weekData ? weekData.prev?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0) : 0;

  const totalTipsWeekActual = weekData ? weekData.actual?.reduce((acc, obj) => acc + obj.tip_cents, 0) : 0;
  const totalTipsWeekPrev = weekData ? weekData.prev?.reduce((acc, obj) => acc + obj.tip_cents, 0) : 0;

  const recurringUsersWeekActual = weekData ? weekData.actual?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0) : 0;
  const recurringUsersWeekPrev = weekData ? weekData.prev?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0) : 0;

  const clientsWhoPaidOnceWeekActual = weekData ? weekData.actual?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0): 0;
  const clientsWhoPaidOnceWeekPrev = weekData ? weekData.prev?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0): 0;

  //MONTH DATA

  const totalRevenueMonthActual = monthData ? monthData.actual?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0) : 0;
  const totalRevenueMonthPrev = monthData ? monthData.prev?.reduce((acc, obj) => acc + obj.base_amount_cents + obj.tip_cents, 0) : 0;

  const totalTipsMonthActual = monthData ? monthData.actual?.reduce((acc, obj) => acc + obj.tip_cents, 0) : 0;
  const totalTipsMonthPrev = monthData ? monthData.prev?.reduce((acc, obj) => acc + obj.tip_cents, 0) : 0;

  const recurringUsersMonthActual = monthData ? monthData.actual?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0) : 0;
  const recurringUsersMonthPrev = monthData ? monthData.prev?.reduce((acc, obj) => acc + obj.clients_who_repeated, 0) : 0;

  const clientsWhoPaidOnceMonthActual = monthData ? monthData.actual?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0): 0;
  const clientsWhoPaidOnceMonthPrev = monthData ? monthData.prev?.reduce((acc, obj) => acc + obj.clients_who_payed_just_once, 0): 0;

  const payments = {
    total_period: {
      value: totalRevenueActual,
      delta: calcDelta(totalRevenueActual, totalRevenuePrev),
    },
    transactions: {
      value: transactionsCurrent,
      delta: calcDelta(transactionsCurrent, transactionsPrevious),
    },
    average_ticket: {
      value: avgTicketCurrent,
      delta: calcDelta(avgTicketCurrent, avgTicketPrevious),
    },
    today: {
      value: totalRevenueTodayActual,
      delta: calcDelta(totalRevenueTodayActual, totalRevenueTodayPrev),
    },
    last_week: {
      value: totalRevenueWeekActual,
      delta: calcDelta(totalRevenueWeekActual, totalRevenueWeekPrev),
    },
    last_month: {
      value: totalRevenueMonthActual,
      delta: calcDelta(totalRevenueMonthActual, totalRevenueMonthPrev),
    },
  };

  const tips = {
    total_period: {
      value: totalTipsActual,
      delta: calcDelta(totalTipsActual, totalTipsPrev),
    },
    payments_with_tip: {
      value: percentPaymentsWithTipsCurrent,
      delta: calcDelta(paymentWithTipsCurrent, paymentWithTipsPrevious),
    },
    tip_on_payment: {
      value: tipOnPaymentCurrent,
      delta: calcDelta(tipOnPaymentCurrent, tipOnPaymentPrevious),
    },
    today: {
      value: totalTipsTodayActual,
      delta: calcDelta(totalTipsTodayActual, totalTipsTodayPrev),
    },
    last_week: {
      value: totalTipsWeekActual,
      delta: calcDelta(totalTipsWeekActual, totalTipsWeekPrev)
    },
    last_month: {
      value: totalTipsMonthActual,
      delta: calcDelta(totalTipsMonthActual, totalTipsMonthPrev),
    },
  };

  const users = {
    total: totalClientsActual,
    new: clientsWhoPaidOnceActual,
    recurrent: clientsWhoRepeatedActual,
    payment_rates_per_user: paymentsWithClientActual,
    period: {
      total: data.total_clients,
      new: clientsWhoPaidOnceActual,
      recurrent: clientsWhoRepeatedActual,
      delta: calcDelta(clientsWhoPaidOnceActual + clientsWhoRepeatedActual, clientsWhoPaidOncePrev + clientsWhoRepeatedPrev),
      deltaNew: calcDelta(clientsWhoPaidOnceActual, clientsWhoPaidOncePrev),
      deltaRecurrent: calcDelta(clientsWhoRepeatedActual, clientsWhoRepeatedPrev)
    },
    today: {
      new: clientsWhoPaidOnceTodayActual,
      recurrent: recurringUsersTodayActual,
      delta: calcDelta(clientsWhoPaidOnceTodayActual + recurringUsersTodayActual,clientsWhoPaidOnceTodayPrev + recurringUsersTodayPrev),
    },
    week: {
      new: clientsWhoPaidOnceWeekActual,
      recurrent: recurringUsersWeekActual,
      delta: calcDelta(clientsWhoPaidOnceWeekActual + recurringUsersWeekActual, clientsWhoPaidOnceWeekPrev + recurringUsersWeekPrev),
    },
    month: {
      new: clientsWhoPaidOnceMonthActual,
      recurrent: recurringUsersMonthActual,
      delta: calcDelta(clientsWhoPaidOnceMonthActual + recurringUsersMonthActual, clientsWhoPaidOnceMonthPrev + recurringUsersMonthPrev),
    },
    // year: {
    // 	new: 2500,
    // 	recurrent: 350,
    // 	delta: 29.9551545,
    // },
    historic: data.total_clients
  };

  if (data.restaurants_total_clients) {
    users['restaurants_historic'] = data.restaurants_total_clients;
  }

  const restaurantsObj = {
    with_reviews: restaurantsWithReviewsActual,
    with_cashback: data.restaurants_with_cashback,
    total: data.total_restaurants,
    delta: calcDelta(restaurantsWithReviewsActual, restaurantsWithReviewsPrev)
  }

  const loyalty = {
    cashback_granted: {
      value: centsToAmountString(totalLoyaltyGrantedActual),
      delta: calcDelta(totalLoyaltyGrantedActual, totalLoyaltyGrantedPrev)
    },
    cashback_redeemed: { 
      value: centsToAmountString(totalLoyaltyUsedActual),
      delta: calcDelta(totalLoyaltyUsedActual, totalLoyaltyUsedPrev)
    },
    points_granted: {
      value: totalPointsGrantedActual,
      delta: calcDelta(totalPointsGrantedActual, totalPointsGrantedPrev)
    },
    restaurants: restaurantsObj,
    avg_cashback_per_user: {
      value: (totalClientsWithGeneratedLoyaltyCentsActual) > 0 ? centsToAmountString(totalLoyaltyGrantedActual / totalClientsWithGeneratedLoyaltyCentsActual) : 0,
      delta: calcDelta((totalClientsWithGeneratedLoyaltyCentsActual > 0 ? totalLoyaltyGrantedActual / (totalClientsWithGeneratedLoyaltyCentsActual): 0), (totalClientsWithGeneratedLoyaltyCentsPrev > 0 ? totalLoyaltyGrantedPrev / (totalClientsWithGeneratedLoyaltyCentsPrev) : 0))
    },
    avg_points_per_user: {
      value:(totalClientsWithGeneratedLoyaltyPointsActual) > 0 ? totalPointsGrantedActual / totalClientsWithGeneratedLoyaltyPointsActual : 0,
      delta: calcDelta((totalClientsWithGeneratedLoyaltyPointsActual > 0 ? totalPointsGrantedActual / (totalClientsWithGeneratedLoyaltyPointsActual):0), (totalClientsWithGeneratedLoyaltyPointsPrev > 0 ? totalPointsGrantedPrev / (totalClientsWithGeneratedLoyaltyPointsPrev)  : 0))
    },
    avg_payments_with_cashback: {
      value: (totalPaymentsWithCashbackActual) > 0 ? totalPaymentsWithCashbackActual / transactionsCurrent : 0,
      delta: calcDelta((totalPaymentsWithCashbackActual > 0 ? transactionsCurrent / (totalPaymentsWithCashbackActual):0), (totalPaymentsWithCashbackPrev > 0 ? transactionsPrevious / (totalPaymentsWithCashbackPrev)  : 0))
    }
  };

  const totalReviewsActual = periodData.actual?.reduce((acc, obj) => {
    return (
      acc +
      obj.one_star_reviews +
      obj.two_star_reviews +
      obj.three_star_reviews +
      obj.four_star_reviews +
      obj.five_star_reviews
    );
  }, 0);

  const totalReviewsPrev = periodData.prev?.reduce((acc, obj) => {
    return (
      acc +
      obj.one_star_reviews +
      obj.two_star_reviews +
      obj.three_star_reviews +
      obj.four_star_reviews +
      obj.five_star_reviews
    );
  }, 0);

  const totalStarsActual = periodData.actual?.reduce((acc, obj) => {
    return (
      acc +
      (obj.one_star_reviews +
        2 * obj.two_star_reviews +
        3 * obj.three_star_reviews +
        4 * obj.four_star_reviews +
        5 * obj.five_star_reviews)
      );
  }, 0);

  const totalStarsPrev = periodData.prev?.reduce((acc, obj) => {
    return (
      acc +
      (obj.one_star_reviews +
        2 * obj.two_star_reviews +
        3 * obj.three_star_reviews +
        4 * obj.four_star_reviews +
        5 * obj.five_star_reviews)
      );
  }, 0);
 
  const averageRatingActual = totalStarsActual !== 0 ? totalStarsActual / totalReviewsActual: 0;
  const averageRatingPrev = totalStarsPrev !== 0 ? totalStarsPrev / totalReviewsPrev: 0;
  const reviewsChartData = [
    {stars: 1, quantity: 0},
    {stars: 2, quantity: 0},
    {stars: 3, quantity: 0},
    {stars: 4, quantity: 0},
    {stars: 5, quantity: 0}
  ];

  periodData.actual.forEach((obj)=>{
    if (obj.one_star_reviews > 0) reviewsChartData[0] = {stars: 1, quantity: reviewsChartData[0].quantity + obj.one_star_reviews}
    if (obj.two_star_reviews > 0) reviewsChartData[1] = {stars: 2, quantity: reviewsChartData[1].quantity + obj.two_star_reviews}
    if (obj.three_star_reviews > 0) reviewsChartData[2] = {stars: 3, quantity: reviewsChartData[2].quantity + obj.three_star_reviews}
    if (obj.four_star_reviews > 0) reviewsChartData[3] = {stars: 4, quantity: reviewsChartData[3].quantity + obj.four_star_reviews}
    if (obj.five_star_reviews > 0) reviewsChartData[4] = {stars: 5, quantity: reviewsChartData[4].quantity + obj.five_star_reviews}
  });

  const reviews =	 {
    total_reviews: {
      value: totalReviewsActual,
      delta: calcDelta(totalReviewsActual, totalReviewsPrev)
    },
    restaurants: restaurantsObj,
    average_stars: {
      value: averageRatingActual,
      delta: calcDelta(averageRatingActual, averageRatingPrev)
    },
    reviews: reviewsChartData,
    payments_with_review: Math.round((totalReviewsActual / transactionsCurrent) * 100)
  };

  const totalRestaurants = data.total_restaurants;
  const averagePaymentsPerRestaurantActual = transactionsCurrent > 0 ? (transactionsCurrent / data.total_restaurants).toFixed(2) : 0;
  const averagePaymentsPerRestaurantPrevious = transactionsPrevious > 0 ? (transactionsPrevious / data.total_restaurants).toFixed(2) : 0;
  const restaurants =	 {
    with_payments: data.restaurants_with_payments,
    total: totalRestaurants,
    delta: calcDelta(data.restaurants_with_payments, data.restaurants_with_payments),
    payments_per_restaurant: {
      value: averagePaymentsPerRestaurantActual,
      delta: calcDelta(averagePaymentsPerRestaurantActual, averagePaymentsPerRestaurantPrevious)
    }
  };

  return {payments, tips, users, loyalty, reviews, restaurants};
};