export const PANELS = {
  METRICS: 'metrics',
  COUNTER: 'counter',
  LOCKER: 'locker',
  ORDERS: 'orders',
};

export const ORDER_TABS = ['orders_assignables', 'orders_in_locker'];

export const LOCKER_COLORS = {
  COUNTER: '#ECD9FF',
  EMPTY: '#D4F8D3',
  FULL: '#CCE8FB',
  EXPIRED: '#FFF3D3',
  DISABLED: '#F3F3F3',
};