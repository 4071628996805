import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableProductRow from './DraggableProductRow';

export const DroppableModifierList = ({
  parent,
  parentName,
  level,
  highlightedRow,
  isTpvYumminn,
  setSourceList,
  setModifierToEdit,
  setModifierCategoryToEdit,
  setModifierCategoryToDelete,
  setHighlightedRow,
}) => {
  return (
    <Droppable
      type={parent.id}
      droppableId={'MODIFIER-' + parent.id}
      direction="vertical"
    >
      {providedModList => (
        <React.Fragment key={`$tr-droppable ${parent.id}`}>
          <tr
            ref={providedModList.innerRef}
            {...providedModList.droppableProps}
          >
            <td key={parent.id} colSpan={100}>
              <table>
                {parent.modifiers.map((mod, index) =>
                  <DraggableProductRow
                    key={'MODIFIER-' + mod.id}
                    type={'MODIFIER'}
                    item={mod}
                    parentList={parent.modifiers}
                    parentName={parentName}
                    level={level}
                    index={index}
                    hasSiblings={parent.modifiers.length > 1}
                    isTpvYumminn={isTpvYumminn}
                    highlightedRow={highlightedRow}
                    setSourceList={setSourceList}
                    setModifierToEdit={setModifierToEdit}
                    setModifierCategoryToEdit={setModifierCategoryToEdit}
                    setModifierCategoryToDelete={setModifierCategoryToDelete}
                    setHighlightedRow={setHighlightedRow}
                  />
                )}
              </table>
            </td>
          </tr>
          {providedModList.placeholder}
        </React.Fragment>
      )}
    </Droppable>
  );
};
