import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import etiqueta from "../../../img/etiqueta.svg";

const Wrapper = styled.div`
  width: 260px;
  height: 270px;
  flex-shrink: 0;
  border-radius: 21.074px 21.074px 21.077px 21.077px;
  background-color: white;
  position: relative;
  margin-top:10px;
  padding: 5px;
  border: 1px solid #DDDDDD66;
  box-shadow: 0px 8px 16px 0px #959DA51A;
`;

const CouponPreview = ({discount}) => {
  const { t } = useTranslation();

  discount = {
    payment_details_text: t(`example_${discount?.typology}_payment_details_text`),
    discount_type: 'percentage',
    value: 5,
    ...discount,
  };

  const calculateDiscountImport = ({discount_type, value}) => {
    if(discount_type === 'amount'){
      return Number(value || 0).toFixed(2)
    }
    if(discount_type === 'percentage'){
      return (25 * (value || 0) / 100).toFixed(2)
    }
  };

  const calculateTotal = ({discount_type, value}) => {
    if(discount_type === 'percentage'){
      return ((25 - (25 * (value || 0) / 100)) + 1.25).toFixed(2)
    }
    if(discount_type === 'amount'){
      return ((25 - (value || 0)) + 1.25).toFixed(2)
    }
  };

  return (
    <Wrapper>
      <div className="flex flex-row w-full justify-center items-center p-5 text-[12px] font-semibold">
        <span className="absolute left-5 text-[10px]">
          <ChevronLeftIcon height={15} />
        </span>
        {t("payment_details")}
      </div>
      <hr className="border-dashed mx-4 mb-3"/>
      <div>
        <div className="text-[10px] px-5 p-1 flex flex-row w-full justify-between items-center">
          {t('subtotal')}
          <span>25.00€</span>
        </div>
        <div className="text-[10px] px-5 p-1 flex flex-row w-full justify-between">
          {t('tip')}(5%)
          <span>1.25€</span>
        </div>
        <div className="text-[10px] px-5 p-1 flex flex-row w-full justify-between">
          {discount.payment_details_text || t(`example_${discount?.typology}_payment_details_text`)}
          <span>{calculateDiscountImport(discount) + '€'}</span>
        </div>
        <div className="text-[12px] font-semibold px-4 p-1 flex flex-row w-full justify-between items-center">
          {t('total')}
          <span className="rounded-full bg-[#EFF8F4] p-1">
            {calculateTotal(discount) + '€'}
            </span>
        </div>
      </div>
      <div className="mx-4 mt-3 flex flex-col items-center justify-between">
        <div className="text-[12px] flex flex-row gap-2 w-full whitespace-nowrap">
          <img src={etiqueta} alt='tag'/>
          {t('do_you_have_a_code_coupon')}
        </div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px', width: '100%'}}>
          <div style={{flex: 1}} className="text-[10px] flex flex-row items-center justify-between px-2 border-[1px] border-gray-300 h-6 rounded-md"> 
            {discount?.code ? discount?.code : t('coupon_code')}
          </div>
          <div style={{width: '50px', marginLeft: '3px'}} className="bg-[#404040] flex items-center h-6 justify-center rounded-full text-white text-center text-[8px] font-semibold">
            {t('Apply')}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CouponPreview;
