import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { centsToAmountString, thousandsSeparator, toDecimalString} from "logic/functions";
import {ReactComponent as WaiterStar} from "../../../../img/waiterStar.svg";

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1fr 1fr .7fr .7fr 1fr 1fr .7fr 1fr 1fr .7fr 1fr',
	width: '100%',
  backgroundColor: '#FCFCFC',
  minHeight: "50px"
});

const WaiterRow = ({ waiterData, paymentsData, reviewsData, indent}) => {
  const { t } = useTranslation();
  indent++;
  const hasPaymentsData = paymentsData.length > 0
  const hasReviewsData = reviewsData.length > 0
  const paymentData = hasPaymentsData ? paymentsData[0] : []
  const reviewData = hasReviewsData ? reviewsData[0] : []

  return (
    <table className={'w-full'}>
      <tr
        className={`${bgShades[indent]} ${borderShades[indent]} px-1`}
        style={rowStyle()}
      >
          <td style={{fontWeight: 600}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            {waiterData.name}
          </td>
          <td style={{fontWeight: 400, paddingRight: '10px'}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-end `}>
            {hasPaymentsData ? centsToAmountString(paymentData.base_amount_total_cents, true, true) : "0"}€
          </td>
          <td style={{fontWeight: 400}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            {hasPaymentsData ? thousandsSeparator(paymentData.num_payments) : 0}
          </td>
          <td style={{fontWeight: 400}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            {hasReviewsData ? thousandsSeparator(reviewData.num_reviews) : 0}
          </td>
          <td style={{fontWeight: 400}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            {hasReviewsData && hasReviewsData && paymentData.num_payments ? toDecimalString(reviewData.num_reviews/paymentData.num_payments * 100, 0) : 0}%
          </td>
          <td style={{fontWeight: 700, gap: "5px"}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            <WaiterStar height={15}/>
            {hasReviewsData && reviewData.num_reviews ? (reviewData.total_score/reviewData.num_reviews).toFixed(1).replace('.', ',') : 0}
          </td>
          <td style={{fontWeight: 400, paddingRight: '10px'}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-end `}>
            {hasPaymentsData && paymentData.num_payments ? centsToAmountString(paymentData.base_amount_total_cents/paymentData.num_payments, true, true) : 0}€
          </td>
          <td style={{fontWeight: 400}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            {hasPaymentsData && paymentData.num_payments ? toDecimalString(paymentData.num_tip_payments/paymentData.num_payments * 100, 0)  : 0}%
          </td>
          <td style={{fontWeight: 400}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-center `}>
            {hasPaymentsData && paymentData.base_amount_total_cents ? toDecimalString((paymentData.tips_total_cents * 100)/paymentData.base_amount_total_cents, 1)  : 0}%
          </td>
          <td style={{fontWeight: 400, paddingRight: '10px'}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-end `}>
            {hasPaymentsData && paymentData.num_tip_payments ? centsToAmountString(paymentData.tips_total_cents/paymentData.num_tip_payments, true, true) : 0}€
          </td>
          <td style={{fontWeight: 400, paddingRight: '10px'}} className={`h-full text-center text-xm font-thin py-2 items-center flex justify-end `}>
            {hasPaymentsData ? centsToAmountString(paymentData.tips_total_cents, true, true) : 0}€
          </td>
      </tr>
    </table>
  )
};

export default WaiterRow