import {useMediaQuery} from '../hooks/useMediaQuery';


const XButton = ({ disabled = false, children, doClick, type }) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");
  return(
    <button
      disabled={disabled}
      type={type}
      className={`${isRowBased ?  'flex flex-row justify-center align-center rounded-full cursor-pointer shadow-md px-1 py-1 mx-2 my-1 border-4 border-principal text-sm text-gray-50 font-bold uppercase tracking-wide bg-principal hover:bg-gray-50 hover:text-principal disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200' : 'flex flex-row justify-center align-center rounded-full cursor-pointer shadow-md px-8 py-2 my-1 border-4 border-principal text-sm text-gray-50 font-bold uppercase tracking-wide bg-principal hover:bg-gray-50 hover:text-principal disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200'}`}
      onClick={doClick}
    >
      {children}
    </button>
  );
}

export default XButton;
