import React from 'react';
import { FilePond } from 'react-filepond';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';

export const ImagesSettings = ({ logo, logoWidth, homeImage, photoOverlay, changeImage, onLogoWidthChange, togglePhotoOverlay }) => {
  const { t } = useTranslation();

  return (
    <div className='flex gap-6'>
      <div className='flex justify-between gap-4 flex-1'>
        <div className='flex flex-col gap-4 flex-1'>
          <div>
            <h3 className='font-semibold text-md'>
              {t('logo')}
            </h3>
            <p className='font-normal text-sm text-gray-400'>
              {t('suggestion_logo')}
            </p>
          </div>
          <div className='flex justify-between gap-2 w-full'>
            <div>
              <h3 className='font-semibold text-md'>
                {t('size')}
              </h3>
              <p className='font-normal text-sm text-gray-400'>
                {'*' + t('optional')}
              </p>
            </div>
            <label className='flex items-center gap-2 w-[min-content]'>
              <input
                type='number'
                placeholder='auto'
                max={100}
                min={0}
                value={logoWidth}
                onChange={onLogoWidthChange}
                className='rounded border-2 border-gray-300 w-16 h-8 text-right placeholder-gray-300'
              />
              %
            </label>
          </div>
        </div>
        <FilePond
          credits={false}
          files={logo?.file ? [{ "source": logo.file }] : null}
          labelIdle={`
            <div>
              ${t('Suelta tu imagen aquí o haz click para ')}
              </br>
              <span class="filepond--label-action">
                ${t('Upload Business logo')}
              </span>
            </div>
          `}
          name={'logo'}
          allowMultiple={false}
          allowDrop={true}
          allowReplace={true}
          allowRemove={true}
          onaddfile={(error, file) => changeImage('logo', file.file)}
          onremovefile={() => changeImage('logo', null)}
          acceptedFileTypes={['image/*']}
          stylePanelLayout='compact'
        />
      </div>
      <div className='flex justify-between gap-2 flex-1'>
        <div className='flex flex-col gap-4'>
          <div>
            <h3 className='font-semibold text-md'>
              {t('home_image')}
            </h3>
            <p className='font-normal text-sm text-gray-400'>
              {'jpg/png/gif'}
            </p>
          </div>
          <div className='flex items-center justify-between gap-4'>
            <h3 className='font-semibold text-md'>
              {t('overlay')}
            </h3>
            <Switch
              height={16}
              width={36}
              handleDiameter={20}
              offColor={'#DDD'}
              offHandleColor={'#CCC'}
              onColor={'#C7E6DA'}
              onHandleColor={'#5FB894'}
              activeBoxShadow={'0 0 0 3px #CFEADF'}
              onChange={togglePhotoOverlay}
              name='loyalty_program'
              checked={photoOverlay}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <span className='font-semibold text-sm text-principal'>
              {t(photoOverlay ? 'visible' : 'hidden')}
            </span>
          </div>
        </div>
        <FilePond
          credits={false}
          files={homeImage?.file ? [{ "source": homeImage.file }] : null}
          labelIdle={`
            <div>
              ${t('Suelta tu imagen aquí o haz click para ')}
              </br>
              <span class="filepond--label-action">
                ${t('Upload_Main_Image')}
              </span>
            </div>
          `}
          name={'home_image'}
          allowMultiple={false}
          allowDrop={true}
          allowReplace={true}
          allowRemove={true}
          onaddfile={(error, file) => changeImage('home_image', file.file)}
          onremovefile={() => changeImage('home_image', null)}
          acceptedFileTypes={['image/*']}
          stylePanelLayout='compact'
        />
      </div>
    </div>
  );
};
