import { ButtonExample } from "./ButtonExample";
import { IconExample } from "./IconExample";
import { TooltipExample } from "./TooltipExample";
import { InputExample } from "./InputExample";
import { PaginationExample } from "./PaginationExample";
import { PopupExample } from "./PopupExample";
import { RadioButtonExample } from "./RadioButtonExample";
import { SwitchExample } from "./SwitchExample";
import { TabSelectorExample } from "./TabSelectorExample";

export const examples = {
  Button: <ButtonExample/>,
  Icons: <IconExample/>,
  Inputs: <InputExample/>,
  Popups: <PopupExample/>,
  Tooltips: <TooltipExample/>,
  Pagination: <PaginationExample/>,
  TabSelector: <TabSelectorExample/>,
  RadioButton: <RadioButtonExample/>,
  Switches: <SwitchExample/>
};