
import { Icon, IconType } from 'common/Icon';
import { RADIO_VARIANT, RadioButtonGroup } from 'common/RadioButtonGroup';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSWR from 'swr';
import { apiAuth } from 'logic/api';
import { Context } from 'data/authContext';
import { Tooltip } from 'common/Tooltip';
import { toast } from 'react-toastify';
import { logToServer } from 'logic/functions';

const AccessTypeTooltip = ({type}) => {
  return (
    <Tooltip position='bottom'>
      <p>
        <Trans
          i18nKey={`${type}_tooltip`}
          defaults='El registro <b>no será obligatorio</b> para acceder a la webapp.'
          components={{ b: <b/> }}
        />
      </p>
    </Tooltip>
  );
};

const ACCESS_TYPES = [
  {label: 'open_access', value: 0, leftIcon: <Icon type={IconType.EYE}/>, rightIcon: <AccessTypeTooltip type='open_access'/>},
  {label: 'login_required', value: 1, leftIcon: <Icon type={IconType.LOCK}/>, rightIcon: <AccessTypeTooltip type='login_required'/>},
  {label: 'restricted_access', value: 2, leftIcon: <Icon type={IconType.MAIL}/>, rightIcon: <AccessTypeTooltip type='restricted_access'/>},
];

export const AccessTypeSelector = () => {
  const { t } = useTranslation();
  const { state: { selectedRestaurantId }, setAccessType } = useContext(Context);
  
  const fetcher = async endpoint => {
    try {
      const newAccessType = await apiAuth.get(endpoint).then(res => res.data.access_type);
      setAccessType(newAccessType);
      return newAccessType;
    } catch (error) {
      logToServer(`get_access_type error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t('get_access_type_error'));
      setAccessType(null);
      throw new Error(error);
    }
  };
  
  const { data: accessType, isValidating, mutate } = useSWR(
    `get_access_type/${selectedRestaurantId}`,
    fetcher,
    {revalidateOnFocus: false},
  );

  const changeAccessType = async value => {
    if (value === accessType) return;

    try {
      const newAccessType = await apiAuth.post(
        'update_access_type',
        {restaurant: selectedRestaurantId, access_type: value}
      ).then(res => res.data.access_type);
  
      mutate(newAccessType);
    } catch (error) {
      logToServer(`update_access_type error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t('update_access_type_error'));
    }
  };

  return (
    <AccessTypeSelectorContainer>
      <h6>{t('access_type')}</h6>
      <RadioButtonGroup
        value={accessType}
        options={ACCESS_TYPES}
        variant={RADIO_VARIANT.ROUNDED_SQUARE}
        disabled={isValidating}
        onChange={changeAccessType}
      />
    </AccessTypeSelectorContainer>
  );
};

const AccessTypeSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;
  gap: 16px;
  background-color: #FFF;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  z-index: 4;

  h6 {
    font-size: 1rem;
    font-weight: 500;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .yumminn_tooltip p {
    font-weight: 400;
    text-align: center;
    text-wrap: balance;
    width: max-content;
    max-width: 30ch;
    overflow: hidden;
  }
`;