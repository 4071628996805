import React from 'react';
import SpeakerIcon from '../../../../img/SpeakerIcon.svg';
import MuteSpeakerIcon from '../../../../img/MuteSpeakerIcon.svg';
import {Slider} from '@mui/material';

const VolumeSlider = ({name, selectedVolume, setSelectedVolume}) => {
	const onChange = event => {
		setSelectedVolume(event.target.value);
		localStorage.setItem(name, event.target.value)
	}

  return (
	<div style={{display: 'flex', gap: '15px', alignItems: 'center', marginTop: "16px"}}>
		<img onClick={() => setSelectedVolume(selectedVolume > 0 ? 0 : 0.5)} style={{height: "20px", cursor: 'pointer'}} src={selectedVolume == 0 ? MuteSpeakerIcon : SpeakerIcon}/>
		<div style={{width: "100px", display: 'flex'}}>
			<Slider
				name={name}
				value={selectedVolume}
				style={{color: 'rgb(95,184,148)'}}
				defaultValue={selectedVolume}
				onChange={onChange}
				step={0.1}
				min={0}
				max={1}
			/>
		</div>
    </div>
  );
};
export default VolumeSlider;
