import React, { useContext } from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { Context as AuthContext } from '../../data/authContext';

export const DatesFilter = () => {
  const {t} = useTranslation();
  const [active, setActive] = React.useState('');
  const path = localStorage.getItem('yumminn-path') || null
  const isRowBased = useMediaQuery("(max-width: 760px)");

	const { state: selectedReviewFilter, setSelectedReviewFilter } = useContext(AuthContext);
  React.useEffect(() => {
    path !== 'ratings' ? setActive('days') : setActive('year');
    path !== 'ratings' ? setSelectedReviewFilter('days') : setSelectedReviewFilter('year');
  }, []);

  const handleActive = (filtro) => {
    setActive(filtro);
    setSelectedReviewFilter(filtro);
  };

  return (
    <div className={isRowBased ? 'flex justify-center' : 'flex justify-around p-1 border-primary border-secundario rounded-md'}>
      {path !== 'reports' && path !== 'ratings' &&  
      <div className='w-auto'>
        <button
          onClick={() => handleActive('hours')}
          className={`flex z-50 justify-center items-center w-max py-1 px-3 text-[16px] border border-gray rounded-md mx-1
                ${
            active === 'hours' &&
            'focus:ring-primary border border-primary active:border-primary '
          }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {t('Daily')}
        </button>
      </div>}
      {path === 'ratings' && 
      <div className='w-auto'>
        <button
          onClick={() => handleActive('year')}
          className={`flex z-50 justify-center items-center w-max py-1 px-3 text-[16px] border border-gray rounded-md mx-1
                ${
            active === 'year' &&
            'focus:ring-primary border border-primary active:border-primary '
          }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {t('All')}
        </button>
      </div>}
      <div className='w-auto'>
        <button
          onClick={() => handleActive('days')}
          className={`flex z-50 justify-center items-center w-max py-1 px-3 text-[16px] border border-gray rounded-md mx-1
                ${
            active === 'days' &&
            'focus:ring-primary border border-primary active:border-primary '
          }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {path !== 'ratings' ? t('Days') : t('Last_7_days')}
        </button>
      </div>
      <div className='w-auto'>
        <button
          onClick={() => handleActive('month')}
          className={`flex z-40 justify-center items-center w-max py-1 px-3 text-[16px]  border border-gray rounded-md mx-1
                ${
            active === 'month' &&
            'border border-primary focus:ring-primary active:border-primary '
          }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {path !== 'ratings' ? t('Months') : t('Last_30_days')}
        </button>
      </div>
      {/* <div className='w-auto'>
        <button
          onClick={() => handleActive('year')}
          className={`flex z-30 justify-center items-center w-max py-1 px-3 text-[16px] rounded-md mx-1 border border-gray
                ${
            active === 'year' &&
            'border border-primary focus:ring-primary active:border-primary '
          }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {t('Years')}
        </button>
      </div> */}
    </div>
  );
};
