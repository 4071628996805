import React,{useState, useRef} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import Select from 'react-select';
import { getPosition } from './ProductHighlightRow';
import { categories_select_styles } from 'logic/defaults';
import { Context as AuthContext } from '../../../../data/authContext';
import green_check from "../../../../img/green_check.svg";
import { TrashIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';

export const CommercialBannerDetailsSettings = ({catalog, customGroupDraft, changeTitle, changePosition, changeImage}) => {
  const {state: { selectedRestaurant }} = React.useContext(AuthContext);
  const [showInfo, setShowInfo] = useState(true);
  const [imgError, setImgError] = useState(false);
  const select_child = {"l1": catalog?.supergroups ? catalog.supergroups.map(supergroup => ({"value": supergroup.id, "label": supergroup.name ? supergroup.name : supergroup.tpv_name})) : [],
                        "l2": catalog?.category_groups ? catalog.category_groups.map(category_group => ({"value": category_group.id, "label": category_group.name ? category_group.name : category_group.tpv_name})) : [],
                        "l3": catalog?.product_categories ? catalog.product_categories.map(product_category => ({"value": product_category.id, "label": product_category.name ? product_category.name : product_category.tpv_name})) : []}
  const select_options = [{"value": "restaurant", "label": "Inicio de la carta", disabled: false},
                          {"value": "l1", "label": "Inicio de categoría principal", disabled: select_child["l1"]?.length === 0},
                          {"value": "l2", "label": "Inicio de categoría secundaria", disabled: select_child["l2"]?.length === 0},
                          {"value": "l3", "label": "Inicio de categoría de producto", disabled: select_child["l3"]?.length === 0}]
  const [childrenSelect, setChildrenSelect] = useState(select_child[getPosition(customGroupDraft)[0]] ? select_child[getPosition(customGroupDraft)[0]] : []);
  const selected_value = select_options.find(s_o => s_o.value === getPosition(customGroupDraft)[0])
  const selected_child = select_child[getPosition(customGroupDraft)[0]]?.find(s_c_c => s_c_c.value === customGroupDraft[getPosition(customGroupDraft)[0]])
  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const innerChangePosition = (value) => {
    setShowInfo(false)
    if (value?.value === "restaurant") {
      changePosition(selectedRestaurant[0].value, null, null, null)
      setChildrenSelect([])
    } else {
      const children_select = select_child[value?.value] ? select_child[value?.value] : []
      setChildrenSelect(children_select)
      switch (value?.value) {
        case "l1":
          changePosition(null, children_select[0].value, null, null)
          break;
        case "l2":
          changePosition(null, null, children_select[0].value, null)
          break;
        case "l3":
          changePosition(null, null, null, children_select[0].value)
          break;    
        default:
          return;
      }
    }
  }
  const innerChangeChildPosition = (value) => {
    setShowInfo(false)
    switch (selected_value?.value) {
      case "l1":
        changePosition(null, value.value, null, null)
        break;
      case "l2":
        changePosition(null, null, value.value, null)
        break;
      case "l3":
        changePosition(null, null, null, value.value)
        break;    
      default:
        return;
    }
  }
  
  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '30%', height: '100%'}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>      
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '7%', paddingRight: '7%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{fontSize: '14px', fontWeight: 500}}>{"Nombre (Interno)"}</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <div style={{fontSize: '14px', position: 'absolute', right: '15px', top: '25%', color: 'gray'}}>{customGroupDraft.name.length}/50</div>
            <input
                type='text'
                placeholder='Ej: Banner Coca-Cola'
                className='yumminn-input'
                value={customGroupDraft.name}
                name='custom_group_title'
                style={{width: '10ch'}}
                maxLength={50}
                onChange={(e) => changeTitle(e.target.value)}
              />
          </div>
          {customGroupDraft.name.length < 1 &&
          <div style={{fontSize: '13px', marginTop: '5px'}}>⚠️ Debes añadir un nombre</div>}

          <div style={{fontSize: '14px', fontWeight: 500, marginTop: '25px'}}>Posición del banner</div>
          {showInfo &&
          <>
          <div style={{fontSize: '12px', marginTop: '10px', fontStyle: 'italic'}}>
            <span style={{fontWeight: 600}}>Elige una posición estratégica dentro de tu carta.</span><br/>
          </div>
          </>}
            <div style={{marginTop: "15px", fontSize: "13px"}}>
              <Select
                  id={'children'}
                  value={!selected_value["disabled"]  ? selected_value : null}
                  options={select_options}
                  styles={categories_select_styles}
                  className={'w-80'}
                  hideSelectedOptions={false}
                  onChange={(e) => innerChangePosition(e)}
                  isClearable={false}
                  isOptionDisabled={(option) => option.disabled}
                />
            </div>
            {childrenSelect.length > 0 && <div style={{marginTop: "15px", fontSize: "13px"}}>
              <Select
                  id={'child_children'}
                  value={selected_child}
                  options={childrenSelect}
                  styles={categories_select_styles}
                  className={'w-80'}
                  hideSelectedOptions={false}
                  onChange={(e) => innerChangeChildPosition(e)}
                  isClearable={false}
                  maxMenuHeight={showInfo ? "175px" : "275px"}
                />
            </div>}
            <div>
            <div style={{fontSize: '14px', fontWeight: 500, marginTop: '35px'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
              <button
                  style={{width: '150px'}}
                  className={"rounded-full cursor-pointer py-2 px-4 bg-principal text-xs text-white font-bold"}
                  onClick={handleClick}>{customGroupDraft.image ? "Modificar Imagen" : "Añadir Imagen"}</button>
              {customGroupDraft.image && 
              <div style={{display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer'}}
                   onClick={() => changeImage(null)}>
                <TrashIcon className={`w-[20px] text-gray-600`}/>
                Eliminar
              </div>}
            </div>
            <input accept="image/png, image/jpg" ref={hiddenFileInput} type="file" style={{display:'none'}} name="myImage" onChange={(event) => {setImgError(false); changeImage(event.target.files[0]); event.target.value=""}}/>
            {customGroupDraft.image && !imgError &&
              <img src={typeof customGroupDraft.image == "string" ? customGroupDraft.image : URL.createObjectURL(customGroupDraft.image)} onError={() => {setImgError(true); changeImage(null)}} alt='' style={{width: '0px'}} />
            }
            {!customGroupDraft.image && !imgError && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', gap: '5px'}}>
              ⚠️ Debes añadir un banner
            </div>}
            {imgError && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', color: '#EF1E1E', gap: '5px'}}>
              <ExclamationCircleIcon style={{color: '#EF1E1E'}} className={`w-[20px]`}/>
              Error al subir la imagen
            </div>}
            {customGroupDraft.image && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', color: '#5FB894', gap: '5px'}}>
              <img src={green_check} style={{width: '20px'}} alt=''/>
              Imagen subida correctamente
            </div>}
            <div style={{display: 'flex', marginTop: '10px', width: '170px', gap: "5px", fontSize: "12px"}}>
              <div><InformationCircleIcon style={{color: '#A3A3A3', width: '20px'}}/></div>
              <div>JPG, PNG SVG<br/>Tamaño: 860x400 px<br/>Calidad máx: 2,5MB.</div>
            </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  )
}
