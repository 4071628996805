import React from 'react';
import { Trans } from 'react-i18next';
import VisibilitySchedulesList from "./VisibilitySchedulesList";
import illustration from '../../../../img/empty_schedules.svg'
import styled from 'styled-components';
import { SquareIcon } from 'common/Icon';

export const GroupShedulesPopup = ({group, newSchedule, editSchedule, editGroup, setSchedulesToDelete}) => {

  const schedules = group?.visibility_schedules || [];
  const isInactive = group.visibility === 0;

  return (
    <div className={`flex gap-8 h-full py-6 flex-1 relative ${schedules.length ? 'justify-between items-start' : 'flex-col'}`}>
      <VisibilitySchedulesList
        schedules={schedules}
        isInactive={isInactive}
        handleAddSchedule={newSchedule}
        editSchedule={editSchedule}
        editObject={editGroup}
        setSchedulesToDelete={setSchedulesToDelete}
      />
      {!!schedules.length &&
        <i className={`text-xs text-[#404040] rounded-md max-w-[38ch] p-2 transition-colors ${isInactive ? 'bg-[#FFF2D0]' : 'bg-gray-100'}`}>
          <Trans i18nKey={isInactive ? 'explanation_schedules_inactive' : 'explanation_schedules_active'}>
            All time ranges (day/time) of all active schedules will be <b>complemented</b>. If you want to hide a time range, make sure that it doesn't appear in any active schedule.
          </Trans>
        </i>
      }
      {!schedules.length &&
        <EmptyState>
          <SquareIcon>
            🕑
          </SquareIcon>
          <h4>
            <Trans i18nKey={isInactive ? 'title_empty_schedules_inactive' : 'title_empty_schedules_active'}>
              The category will be <u>visible</u> during your entire opening hours
            </Trans>
          </h4>
          <p>
            <Trans i18nKey={isInactive ? 'explanation_empty_schedules_inactive' : 'explanation_empty_schedules_active'}>
              If you want to customise the visibility, you can <b className='font-semibold'>define a schedule or disable the category</b> at the top right.
            </Trans>
          </p>
        </EmptyState>
      }
    </div>
  );
};

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40ch;
  text-align: center;
  margin: 0 auto auto;
  flex-grow: 99;

  h4 {
    font-size: 1rem;
    font-weight: 600;
    text-wrap: balance;
    line-height: 1.3em;
    margin: 1.5em;
  }
`;