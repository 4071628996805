import React from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import YumminnPaymentsTabs from './YumminnPaymentsTabs';
import XMenuButton from '../../common/XMenuButton';

const YumminnPaymentsContainer = ({ children }) => {
  const { t } = useTranslation();
  const [usuario, setUsuario] = React.useState(null);
  const user = localStorage.getItem("yumminn-user")

  React.useEffect(() => {
    const user_obj = user
      ? user
      : JSON.parse(localStorage.getItem('yumminn-user'));
    setUsuario(user_obj);
  }, [user]);

  return (
    <div className={'flex flex-col bg-gray-100 w-full h-full'}>
     <div className='bg-white flex justify-between align-center'>
        <h1 className='font-bold text-[24px] px-[32px] py-5 text-[#094553] bg-[#E2FEF1]'>
          {t('Yumminn_Payments')}
        </h1>
        <div style={{overflowWrap: 'everywhere', display: 'flex' }} className={'pt-4'}>
          <XMenuButton doClick={() => null}>
            {usuario && usuario.email}
          </XMenuButton>
        </div>
      </div>
      <div className='px-[32px]'>
        <RestaurantSelect />
        <YumminnPaymentsTabs />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default YumminnPaymentsContainer;
