import React, { createContext, useContext, useState } from 'react';
import useSWR from 'swr';
import { Context } from 'data/authContext';
import { apiAuth } from 'logic/api';
import { useDebounce } from 'hooks/useDebounce';
import { logToServer } from 'logic/functions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Icon, IconType } from 'common/Icon';

export const AccessContext = createContext();

export const AccessContextProvider = ({ type, children }) => {
  const { state: { selectedRestaurantId }} = useContext(Context);

  const { t } = useTranslation();
  
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('-id');
  const [search, setSearch] = useState('');
  const [importResults, setImportResults] = useState(null);

  const searchParams = new URLSearchParams ({ page, order });
  const debouncedSearch = useDebounce(search, 500);
  debouncedSearch && searchParams.append('search', debouncedSearch);

  const fetcher = async endpoint => {
    try {
      const res = await apiAuth.get(endpoint);
      return res.data;
    } catch (error) {
      logToServer(`${endpoint} error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`access_management_${type}_error`));
      throw new Error(error);
    }
  };

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    selectedRestaurantId && `list/whitelist_${type}s/${selectedRestaurantId}?${searchParams.toString()}`,
    fetcher,
    {revalidateOnFocus: false}
  );
  
  const [entryDraft, setEntryDraft] = useState(null);
  const [isImporting, setIsImporting] = useState(null);
  const [isExporting, setIsExporting] = useState(null);

  const addEntry = () => setEntryDraft({
    restaurant: selectedRestaurantId,
    [type]: '',
  });

  const changeEntry = partialEntry => setEntryDraft(
    prev => ({...prev, ...partialEntry})
  );

  const clearDraft = () => setEntryDraft(null);

  const saveEntry = async entry => {
    if (!entry || !entry[type]) return;

    try {
      await apiAuth.post(`update_whitelist_${type}`, entry);
      clearDraft();
      mutate();

      !entry.id && toast.success(
        <div style={{display: 'flex', alignItems: 'center', color: '#094553'}}>
          <Icon type={IconType.VERIFIED}/>
          <span style={{fontSize: '1.5rem', fontWeight: '600', textAlign: 'center', flex: '1'}}>
            {t(`${type}_added`)}
          </span>
        </div>,
        {position: 'bottom-center'}
      );
    } catch (error) {
      logToServer(`update_whitelist_${type} error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`update_whitelist_${type}_error`));
    }
  };

  const deleteEntry = async entryId => {
    if (!entryId) {
      clearDraft();
      return;
    };

    try {
      await apiAuth.delete(`delete_whitelist_${type}/${entryId}`).then(res => res.data);

      mutate(prev => {
        const entries = [...prev.entries];
        const index = entries.findIndex(entry => entry.id === entryId);

        entries.splice(index, 1);

        return { ...prev, entries };
      });
    } catch (error) {
      logToServer(`delete_whitelist_${type} error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`delete_whitelist_${type}_error`));
    }
  };

  const importFile = async event => {
    if (!event.target.files?.[0]) return;

    setIsImporting(true);

    const formData = new FormData();

    formData.append('restaurant', selectedRestaurantId);
    formData.append('file', event.target.files[0])

    try {
      const results = await apiAuth.post(
        `import_whitelist_${type}s`,
        formData,
        {"Content-Type": "multipart/form-data"}
      ).then(res => res.data);

      mutate();
      setImportResults(results);
    } catch (error) {
      logToServer(`import_whitelist_${type}s error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`import_whitelist_error`));
    } finally {
      event.target.value = ''; // reset input
      setIsImporting(false);
    }
  };

  const exportFile = async () => {
    setIsExporting(true);

    try {
      const file = await apiAuth.get(`export_whitelist_${type}s/${selectedRestaurantId}`).then(res => res.data);
      const blob = new Blob([file], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      
      a.href = url;
      a.download = `${type}_whitelist.csv`;
      
      a.click();
    } catch (error) {
      logToServer(`export_whitelist_${type}s error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`export_whitelist_error`));
    } finally {
      setIsExporting(false);
    }
  };

  const downloadEmptySheet = () => {
    const blob = new Blob([`id,${type},delete`], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    
    a.href = url;
    a.download = `new_${type}_whitelist.csv`;
    a.click();
  };

  const closeImportResults = () => setImportResults();

  const value = {
    entries: data?.entries || [],
    entryDraft,
    pages: data?.pages || {num_pages: 0, num_entries: 0},
    page,
    order,
    search,
    error,
    importResults,
    isLoading,
    isValidating,
    isImporting,
    isExporting,
    addEntry,
    changeEntry,
    saveEntry,
    deleteEntry,
    clearDraft,
    importFile,
    exportFile,
    downloadEmptySheet,
    setPage,
    setOrder,
    setSearch,
    closeImportResults,
  };

  return (
    <AccessContext.Provider value={value}>
      {children}
    </AccessContext.Provider>
  );
};
