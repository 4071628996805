import React, { useState, useEffect, useContext, useRef } from "react";
import Spinner from "common/Spinner";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';
import { baseAuth } from "logic/api";
import { toast } from "react-toastify";
import BookingShiftsTable from "./BookingShifts/BookingShiftsTable";
import { newShiftMock } from "./BookingShifts/newBookingShiftMock";
import BookingShiftModal from "./OrderAndPay/Modals/BookingShiftModal";
import DeleteBookingShiftModal from "./OrderAndPay/Modals/DeleteBookingShiftModal";

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const BookingShifts = () => {
  
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();
  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
	const hasActiveBookingsTab = restaurants.some(item => item.allow_booking)
  const [shifts                    , setShifts]                     = useState([]);
  const [shiftToRemove             , setShiftToRemove]              = useState(null);
  const [shiftToEdit               , setShiftToEdit]                = useState(null);
  const [shiftToCreate             , setShiftToCreate]              = useState(null);
  const [loading                   , setLoading]                    = useState(false);


  useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setShifts([])
		if (selectedRestaurant && selectedRestaurant.length > 0) {
			getData();
		}
	}, [selectedRestaurant])


  const getData = async () => {
		setLoading(true)
		await baseAuth.get(`/booking/list/booking_shift/${selectedRestaurant[0].value}/`)
			.then((response) => {
				const shifts = response.data
				setShifts(shifts)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

  const updateShift = async (shift) => {
		setLoading(true)
		const newShift = JSON.parse(JSON.stringify(shift))
		await baseAuth.post(`/booking/update/booking_shift/`, newShift)
		  .then((response) => {
        const shifts = response.data.shifts
        setShifts(shifts)
        setShiftToEdit(null)
        setShiftToCreate(null)
        setShiftToRemove(null)
        setLoading(false)
		  })
		  .catch((e) => {
        if (e.response.data.error == "E2") {
          toast.error("El horario seleccionado colapsa con otro turno de reserva existente.");
        } else if (e.response.data.error == "E1") {
          toast.error("Información del turno de reserva no enviada correctamente. Recargue la página y pruebe de nuevo.");
        }else {
          toast.error("Error al guardar el turno de reserva.");
        }
			setLoading(false)
		  })
	  }

  const createShift = async (shift) => {
    setLoading(true)
    const newShift = JSON.parse(JSON.stringify(shift))
    await baseAuth.post(`/booking/create/booking_shift/`, newShift)
      .then((response) => {
        const shifts = response.data.shifts
        setShifts(shifts)
        setShiftToEdit(null)
        setShiftToCreate(null)
        setShiftToRemove(null)
        setLoading(false)
      })
      .catch((e) => {
        if (e.response.data.error == "E2") {
          toast.error("El horario seleccionado colapsa con otro turno de reserva existente.");
        } else if (e.response.data.error == "E1") {
          toast.error("Información del turno de reserva no enviada correctamente. Recargue la página y pruebe de nuevo.");
        }else {
          toast.error("Error al guardar el turno de reserva.");
        }
        setLoading(false)
      })
    }

  const removeShift = async (shift) => {
    setLoading(true)
    await baseAuth.post(`/booking/remove/booking_shift/`, {"id": shift.id})
      .then((response) => {
        const shifts = response.data.shifts
        setShifts(shifts)
        setShiftToEdit(null)
        setShiftToCreate(null)
        setShiftToRemove(null)
        setLoading(false)
      })
      .catch((e) => {
        toast.error("Error al eliminar el turno de reserva.");
        setLoading(false)
      })
    }

  const handleCreate = () => {
    newShiftMock["restaurant"] = selectedRestaurant[0].value
    setShiftToCreate(newShiftMock)
  }

  if (!hasActiveBookingsTab) return <UnavailableFunctionality title={'Bookings'} />
  return (
    <> 
      <button
        style={{width: '225px'}}
        className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
        onClick={() => handleCreate()}>Crear turno de reserva</button>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {loading && <LoadingOverlay/>}
       
        <div className="flex-auto w-full">
            <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: '90%'}}>
              <BookingShiftsTable shifts={shifts} setShifts={setShifts} setLoading={setLoading} setShiftToRemove={setShiftToRemove} setShiftToEdit={setShiftToEdit}/>
            </div>
        </div>
      </div>
      {shiftToRemove &&
				<DeleteBookingShiftModal t={t} removeShift={removeShift} shift={shiftToRemove} setShiftToRemove={setShiftToRemove}/>
			}
      {shiftToEdit &&
				<BookingShiftModal t={t} shift={shiftToEdit} setShiftToEdit={setShiftToEdit} saveShift={updateShift} setShiftToRemove={setShiftToRemove} type={0}/>
			}
      {shiftToCreate &&
				<BookingShiftModal t={t} shift={shiftToCreate} setShiftToEdit={setShiftToCreate} saveShift={createShift} setShiftToRemove={setShiftToRemove} type={1}/>
			}
    </>
  );
};

export default BookingShifts;
