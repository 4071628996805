import React, { useEffect, useState } from 'react';
import DateRangeSelect from 'components/yumminn/DateRangeSelect';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { SelectInput, SearchInput } from 'common/Input';
import { TicketsContainer } from './TicketsContainers';
import { useTickets } from './useTickets';
import { PurchasePanel } from './PurchasePanel';
import { PurchaseTable } from './PurchaseTable';
import { ValidatedTickets } from './ValidatedTickets';
import { Pagination } from 'common/Pagination';
import { useTranslation } from 'react-i18next';
import { STATUS_COLORS, STATUS_KEYS } from './constants';
import { getPurchaseData } from './functions';
import { components } from "react-select";
import styled from 'styled-components';

export const Tickets = () => {
  const { t } = useTranslation();

  const statusOptions = Object.values(STATUS_KEYS).map(key => ({label: t(key), value: key, color: STATUS_COLORS[key]}));

  const [page, setPage] = useState(1);
  const [event, setEvent] = useState(null);
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState([]);

  const {
    purchaseList,
    eventOptions,
    ticketsData,
    pages,
    isLoading,
    isValidating,
    mutate,
    getSuggestions,
  } = useTickets({page, event, search});

  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);

  const selectedPurchase = selectedPurchaseId && purchaseList.find(purchase => purchase.id === selectedPurchaseId);

  const statusFilter = filter.map(status => status.value);

  const purchaseStatusFilter = purchase => {
    const { status } = getPurchaseData(purchase);
    return statusFilter.includes(status);
  };

  const filteredPurchaseList = filter.length
    ? purchaseList.filter(purchaseStatusFilter)
    : purchaseList;
  
  useEffect(() => {
    if (!purchaseList.some(purchase => purchase.id === selectedPurchaseId))
    setSelectedPurchaseId(purchaseList?.length ? purchaseList[0].id : null);
  }, [purchaseList]);

  return (
    <TicketsContainer>
      <MenuHeader title={"events"} route_group={'event_tickets'}/>
      <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
        <div className={`flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
          <RestaurantSelect />
          <DateRangeSelect hourFormat={false} defaultRange='this_month'/>
          <ValidatedTickets {...ticketsData}/>
        </div>
      </div>
      <div className="filters">
        <SelectInput
          id='ticket-event'
          label='event'
          placeholder='All'
          value={event}
          options={eventOptions}
          onChange={setEvent}
          isClearable
        />
        <SearchInput
          label='search_by'
          value={search}
          placeholder='placeholder_search_tickets'
          getSuggestions={getSuggestions}
          onSelect={setSearch}
          components={{Option, MultiValue}}
        />
        <SelectInput
          id='ticket-status'
          label='filter_by_status'
          placeholder='All'
          value={filter}
          options={statusOptions}
          onChange={setFilter}
          isMulti
          isClearable
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
      </div>
      <Pagination
        page={page}
        numOfPages={pages.num_pages}
        numOfEntries={pages.num_entries}
        entryName='purchase'
        className='pagination'
        setPage={setPage}
        disabled={isValidating}
      />
      <div className="panels">
        <PurchaseTable
          purchaseList={filteredPurchaseList}
          selectedPurchaseId={selectedPurchaseId}
          setSelectedPurchaseId={setSelectedPurchaseId}
          isLoading={isLoading}
        />
        <PurchasePanel
          purchase={selectedPurchase}
          isLoading={isLoading}
          mutate={mutate}
        />
      </div>
    </TicketsContainer>
  );
};

const Option = (props) => {
  const { t } = useTranslation();

  return (
    <components.Option {...props}>
      <OptionContainer>
        <span>{t(props.data.attr)}</span>
        {props.data.value}
      </OptionContainer>
    </components.Option>
  );
};

const MultiValue = (props) => {
  const { t } = useTranslation();

  return (
    <components.MultiValue {...props}>
      <MultiValueContainer>
        <span>{t(props.data.attr)}</span>
        {props.data.value}
      </MultiValueContainer>
    </components.MultiValue>
  );
};

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;

  span {
    background-color: #E2FEF1;
    border-radius: 4px;
    padding: 2px 4px;
  }
`;

const MultiValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1em;
  padding: 0;
  color: #FFF;

  span {
    background-color: #73CCA8;
    border-radius: 1em;
    padding: 4px 6px;
    margin: -2px;
  }
`;