import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React from 'react'
import styled from 'styled-components';

const getValue = order => {
  if (!order) return 0
  if (typeof order === 'number') return order;
  return order.startsWith('-') ? -1 : 1;
};

export const OrderIndicator = ({ order }) => {
  const value = getValue(order);

  return (
    <Container>
      <ChevronUpIcon style={{opacity: value >= 1 ? 1 : 0.25}}/>
      <ChevronDownIcon style={{opacity: value <= -1 ? 1 : 0.25}}/>
    </Container>
  );
};

const Container = styled.span`
  height: 1.5rem;
  width: 0.75rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 1rem;
    height: 1rem;
    margin: -0.125rem;

    path {
      stroke-width: 0.18rem;
    }
  }
`;