import styled, { css } from "styled-components";

const fonts = css`
  & > h2, & > h3 {
    font-weight: 600;
    color: #094553;
  }

  & > h2 {
    font-size: 1.5rem;
  }

  & > h3 {
    font-size: 1.125rem;
  }
  
  & > p {
    font-size: 1rem;

    a {
      color: #094553;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export const ComponentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${fonts}

  & > div {
    flex: 1;
    padding: 24px;
  }
  
  & > aside {
    padding: 24px;
    border-left: 1px solid #DDD;
    text-align: end;

    ${fonts}

    li {
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 4px;

      &:hover {
        color: #094553;
      }
    }
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  
  ${fonts}
`;

export const ColumnContainer = styled(RowContainer)`
  flex-direction: column;
`;