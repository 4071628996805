import { toDecimalString } from 'logic/functions';
import React from 'react';
import ArrowIndicator from './ArrowIndicator';

const getDeltaStyles = (n, small) => {
  const size = small ? 'gap-[2px] p-1 text-xs' : 'gap-1 p-2 text-sm'

  const color =
    n > 0 ? 'bg-[#9EFFD7] text-[#094553]' :
    n < 0 ? 'bg-[#E186843F] text-[#E18684]' :
    'bg-trueGray-200 text-trueGray-500';

    return `${size} ${color}`;
};

export const DeltaIndicator = ({delta, small = false, className}) => {
  return (
    <div className={`flex w-min justify-center items-center font-bold rounded-lg ${getDeltaStyles(delta, small)} ${className}`}>
      {toDecimalString(delta)}%
      <ArrowIndicator value={delta} small/>
    </div>
  );
};
