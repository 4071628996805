import { Button, BUTTON_VARIANTS } from 'common/Button';
import EmptyState from 'common/EmptyState';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import { TextInput } from 'common/Input';
import { OrderIndicator } from 'common/OrderIndicator';
import { Pagination } from 'common/Pagination';
import { DeletePopup } from 'pages/admin/pages/Modifiers/DeletePopup';
import { formatDate } from 'pages/discounts/components/discountFunctions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessContext } from './AccesContext';
import { AccessRow } from './AccessRow';

export const AccessTable = ({ type, regexValidator }) => {
  const {
    entries,
    page,
    pages,
    order,
    entryDraft,
    error,
    isLoading,
    isValidating,
    isExporting = false,
    addEntry,
    deleteEntry,
    exportFile,
    setPage,
    setOrder,
  } = useContext(AccessContext);

  const { t } = useTranslation();

  const [entryToDelete, setEntryToDelete] = useState(null);

  const orderBy = attr => setOrder(
    prev => {
      if (prev.replace('-', '') === attr) {
        return prev.startsWith('-') ? attr : `-${attr}`;
      }

      return attr;
    }
  );

  const deleteEntryAndClose = (entry) => {
    deleteEntry(entry.id);
    setEntryToDelete(null);
  };

  return (
    <div className='access_table'>
      <table>
        <thead>
          <tr>
            <th className='col_id'>
              <button onClick={() => orderBy('id')} disabled={!entries.length}>
                <span>{t('id')}</span>
                <OrderIndicator order={order.includes('id') ? order : ''}/>
              </button>
            </th>
            <th className='col_entry'>
              <button onClick={() => orderBy(type)} disabled={!entries.length}>
                <span>{t(type)}</span>
                <OrderIndicator order={order.includes(type) ? order : ''}/>
              </button>
            </th>
            <th className='col_created'>
              <button onClick={() => orderBy('created_at')} disabled={!entries.length}>
                <span>{t('created_at')}</span>
                <OrderIndicator order={order.includes('created_at') ? order : ''}/>
              </button>
            </th>
            <th className='col_modified'>
              <button onClick={() => orderBy('modified_at')} disabled={!entries.length}>
                <span>{t('modified_at')}</span>
                <OrderIndicator order={order.includes('modified_at') ? order : ''}/>
              </button>
            </th>
            <th className='col_delete'/>
          </tr>
        </thead>
        <tbody>
          {entryDraft &&
            <AccessRow
              type={type}
              entry={entryDraft}
              regexValidator={regexValidator}
              setEntryToDelete={setEntryToDelete}
            />
          }
          {entries.map(entry =>
            <AccessRow
              key={entry.id}
              type={type}
              entry={entry}
              regexValidator={regexValidator}
              setEntryToDelete={setEntryToDelete}
            />
          )}
        </tbody>
      </table>
      {!isLoading && !entries.length && !error &&
        <EmptyState
          icon={<SquareIcon type={IconType.CIRCLE_LIST}/>}
          text={t(`${type}_whitelist_empty_state`)}
        />
      }
      {error &&
        <EmptyState
          icon={<SquareIcon>❌</SquareIcon>}
          text={t(`access_management_${type}_error`)}
        />
      }
      <footer>
        <Button
          label='download_list'
          rightIcon={<Icon type={IconType.DOWNLOAD}/>}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={exportFile}
          loading={isExporting}
          disabled={!entries.length}
        />
        <Pagination
          page={page}
          numOfPages={pages.num_pages}
          numOfEntries={pages.num_entries}
          setPage={setPage}
          entryName={type}
          disabled={isValidating}
        />
        <Button
          leftIcon={<Icon type={IconType.ADD}/>}
          label={`add_${type}`}
          onClick={addEntry}
          loading={isValidating}
        />
      </footer>
      <DeletePopup
        isOpen={!!entryToDelete}
        title={t(`delete_${type}`, {[type]: entryToDelete?.[type]})}
        message={`delete_${type}_message`}
        onConfirm={() => deleteEntryAndClose(entryToDelete)}
        onCancel={() => setEntryToDelete(null)}
      />
    </div>
  );
};
