import React, { useState, useContext, useEffect } from 'react';
import DateRangeSelect from 'components/yumminn/DateRangeSelect';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { TicketsContainer } from './TicketsContainers';
import { useTickets } from './useTickets';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { EventsTable } from './EventsTable';
import { Popup } from 'common/Popup';
import { ColumnContainer } from 'pages/components/ExampleContainers';
import { EventSpecs } from './EventSpecs';
import { TicketSpecs } from './TicketSpecs';
import { Footer } from './EventsStyles';
import { saveEventOrTicket } from './EventApi';
import { Context as AuthContext } from '../../data/authContext';
import { CustomProgressBar } from "common/CustomProgressBar";
import { apiAuth } from 'logic/api';
import { SeeAtWebAppPopup } from './SeeAtWebAppPopup'

export const CreateEvents = () => {
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();

  const {
    eventTickets,
    eventsList,
    isLoading,
    mutate,
  } = useTickets({page: 1, event:null, search:[]});

  const NEW_EVENT = {name: "", location: "", description: "", image: null, imageFile: null, datetime: "", active: true};
  const NEW_TICKET = {name: "", price_cents: "", description: "", image: null, imageFile: null, order: 0, event: "", active: true};
  const [loading, setLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [popup, setPopup] = useState(null);
	const [eventToEdit, setEventToEdit] = useState(null);
  const [ticketToEdit, setTicketToEdit] = useState(null)
  const [eventDraft, setEventDraft] = useState(NEW_EVENT);
  const [ticketDraft, setTicketDraft] = useState(NEW_TICKET);
  const [QR, setQR] = useState(null)
  const restaurant = selectedRestaurant && selectedRestaurant[0].value

  const close = () => {
    setEventToEdit(null);
    setTicketToEdit(null);
    setEventDraft(NEW_EVENT);
    setTicketDraft(NEW_TICKET);
    setPopup(null);
  }

  const editEvent = (object) => {
    setEventDraft((prevObj) => ({ ...prevObj, ...object }));
  };

  const editTicket = (object) => {
    setTicketDraft((prevObj) => ({ ...prevObj, ...object }));
  };

  const getQr = async (restaurant) => {
    try {
      const res_obj = await apiAuth.get(
        `/tables/?restaurant=${restaurant}`
      );
      if (res_obj.data){
        const res_data = res_obj.data.results;
        
        if(res_data){
          let _array = [];
          res_data && res_data.map((item) => {
            item.isChecked = false;
            _array.push(item);
          });

          const tables = (_array);
          const table = tables.find((table) => table.qr !== null)
          if(table){
            const qr = table.qr
            setQR(qr)
          }
        }
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  useEffect(() => {
    const restaurant = selectedRestaurant && selectedRestaurant[0].value
    if(!QR){
      getQr(restaurant)
    }
  }, [QR, selectedRestaurant])

  useEffect(() => {
    if(eventToEdit) setPopup(1)
    if(ticketToEdit) setPopup(2)
  }, [eventToEdit, ticketToEdit])

  const saveEvent = async(eventOrTicket) => {
    if (!selectedRestaurant) return;

    setLoading(true);
  
    try {
      await saveEventOrTicket({
        restaurant: restaurant,
        eventOrTicket: eventOrTicket,
        eventToEdit: eventToEdit,
        ticketToEdit: ticketToEdit,
        object: eventOrTicket === 'EVENT' ? eventDraft : ticketDraft, 
        setSelectedEventId: setSelectedEventId,
        t: t
      })

      mutate();
      if(!ticketToEdit && !eventToEdit){
        setTimeout(() => {
          setLoading(false);
          close();
        }, 3000);
      } else {
        setLoading(false);
        close()
      }
      

    } catch (error) {
      setLoading(false);
      console.log("🚀 saveEvent error:", error);
    }
  }

  const handleSeeAtWebApp = () => {
    if(QR) setPopup(3)
  }

  return (
    <TicketsContainer>
      <MenuHeader title={"events"} route_group={'event_tickets'}/>
      <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
        <div style={{marginBottom: "20px"}} className={`flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
          <RestaurantSelect />
          <DateRangeSelect hourFormat={false} defaultRange='this_month'/>
        </div>
        <div style={{marginBottom: "20px"}} className='flex flex-col items-end justify-center w-full'>
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={() => handleSeeAtWebApp()}
            label='see_in_webapp'
            className = 'mt-6'
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
      </div>
      <div className='w-fit px-7'>
        <Button 
          size={BUTTON_SIZES.LG}
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => setPopup(1)}
          label='Create_event'
          className = 'mt-6'
          disabled={isLoading}
          loading={isLoading}
        />
      </div>
      <div className="panels">
        <EventsTable
          eventsList={eventsList}
          selectedEventId={selectedEventId}
          setSelectedEventId={setSelectedEventId}
          isLoading={isLoading}
          setEventToEdit={setEventToEdit}
          setTicketToEdit={setTicketToEdit}
          setEventDraft={setEventDraft}
          mutate={mutate}
          setPopup={setPopup}
          eventTickets={eventTickets}
          setTicketDraft={setTicketDraft}
          ticketDraft={ticketDraft}
        />
      </div>
      <Popup isOpen={popup === 1} close={close}>
        <ColumnContainer>
          <h3>{t(eventToEdit ? `edit_event` : `create_event`)}</h3>
          <hr></hr>
          <EventSpecs eventDraft={eventDraft} editEvent={editEvent} />
          <hr></hr>
        </ColumnContainer>
        <Footer>
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={close}
            label='cancel'
            className = 'mt-6'
            disabled={isLoading}
            loading={isLoading}
          />
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={()=> saveEvent('EVENT')}
            label='save'
            className = 'mt-6 p-2'
            disabled={isLoading}
            loading={isLoading}
          />
        </Footer>
        {loading && !eventToEdit &&
          <div className="z-9999 absolute top-0 left-0 right-0 bottom-0 flex">
            <div className="flex flex-col gap-6 p-10 m-auto overflow-hidden bg-white rounded-3xl text-center shadow-xl">
              <h4 className="text-xl font-semibold w-full text-center flex justify-center">
                {t(`creating_event`)}
              </h4>
              <CustomProgressBar estimatedSeconds={5}/>
              <i className="max-w-xs">
               {t(`do_not_forget_to_add_tickets`)}😉
              </i>
            </div>
          </div>
        }
      </Popup>
      <Popup isOpen={popup === 2} close={close}>
      <ColumnContainer>
          <h3>{t(ticketToEdit ? `edit_ticket` : `create_ticket`)}</h3>
          <hr></hr>
          <TicketSpecs ticketDraft={ticketDraft} editTicket={editTicket} />
          <hr></hr>
        </ColumnContainer>
        <Footer>
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={close}
            label='cancel'
            className = 'mt-6'
            disabled={isLoading}
            loading={isLoading}
          />
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={()=> saveEvent('TICKET')}
            label='save'
            className = 'mt-6 p-2'
            disabled={isLoading}
            loading={isLoading}
          />
          {loading && !ticketToEdit &&
          <div className="z-9999 absolute top-0 left-0 right-0 bottom-0 flex">
            <div className="flex flex-col gap-6 p-10 m-auto overflow-hidden bg-white rounded-3xl text-center shadow-xl">
              <h4 className="text-xl font-semibold w-full text-center flex justify-center">
                {t(`creating_ticket`)}
              </h4>
              <CustomProgressBar estimatedSeconds={5}/>
              <i className="max-w-xs">
               {t(`wait_some_seconds`)}...
              </i>
            </div>
          </div>
        }
        </Footer>
      </Popup>
      <SeeAtWebAppPopup isOpen={popup === 3} confirm={close} qr={QR} />
    </TicketsContainer>
  );
};

