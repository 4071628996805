import React from 'react';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { centsToAmountString } from 'logic/functions';
import StatusBadge from 'pages/admin/pages/Orders/StatusBadge';
import RefundsIcon from "../../../common/RefundIcon";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getLoyaltyCents, getPromoCents, getSubtotalCents, getTotalCents, getDate } from './utils';
import { Tooltip } from 'common/Tooltip';
import walletIcon from '../../../img/walletIcon.svg'

export const PaymentRow = ({ pay, CardType, ItemsBadge, openRefundModal }) => {
  const { t } = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const user = JSON.parse(localStorage.getItem('yumminn-user'));

  return (
    <tr>
      <Td centered>
        {pay.tableName}
        <Tooltip>
          <span>{`${t('Name')}: `}<b>{pay.tableName}</b></span>
          <span>{`${t('Table')}: `}<b>{pay.tableNumber}</b></span>
          <span>{`${t('Sector')}: `}<b>{pay.sectorName}</b></span>
        </Tooltip>
      </Td>
      {pay.split_type !== 'recharge' ? 
        <Td>{pay.orderRequestCode ? pay.orderRequestCode : ""}</Td>
        :
        <Td>{`YR-${pay.id}`}</Td>
      }
      {!isRowBased &&
        <Td centered>
          {pay.card_brand && (pay.card_last_4 ?
            <div className=' flex flex-row items-center justify-center'>
              <CardType cardType={pay.card_brand} size={10}/>
              <div className='ml-2 whitespace-nowrap'>* {pay.card_last_4}</div>
            </div>
            :
            <div className=' flex flex-row items-center justify-center'>
              <CardType cardType={pay.card_brand} size={10} paymentMethod={pay.payment_method} />
            </div>)
          }
          {pay.payment_method === 'cash' &&
            <div className=' flex flex-row items-center justify-center capitalize'>
              {t('cash')}
            </div>
          }
          {pay.payment_method === 'pos_terminal' &&
            <div className=' flex flex-row items-center justify-center capitalize'>
              {t('pos_terminal')}
            </div>
          }
          {pay.payment_method === 'wallet' &&
            <div className=' flex flex-row gap-2 items-center justify-center capitalize'>
              <img style={{height: "20px"}} src={walletIcon} alt=''/>
              {t('wallet')}
            </div>
          }
        </Td>
      }
      <Td centered>{getDate(pay)}</Td>
      <Td>{centsToAmountString(pay.base_amount_cents) + '€'}</Td>
      <Td>{centsToAmountString(getLoyaltyCents(pay)) + '€'}</Td>
      <Td>
        {centsToAmountString(getPromoCents(pay)) + '€'}
        {getPromoCents(pay) > 0 &&
          <Tooltip>
            {pay.product_discount_cents > 0 &&
              <span>{`${t('product_discount')}: `}<b>{centsToAmountString(pay.product_discount_cents)}€</b></span>
            }
            {pay.general_discount_cents > 0 &&
              <span>{`${t('General discount')}: `}<b>{centsToAmountString(pay.general_discount_cents)}€</b></span>
            }
            {pay.code_coupon_cents > 0 &&
              <span>{`${t('code_coupon_discount')}: `}<b>{centsToAmountString(pay.code_coupon_cents)}€</b></span>
            }
            {pay.credit_cents > 0 &&
              <span>{`${t('credit_cents')}: `}<b>{centsToAmountString(pay.credit_cents)}€</b></span>
            }
            {pay.bizum_promotion_cents > 0 &&
              <span>Bizum: <b>{centsToAmountString(pay.bizum_promotion_cents)}€</b></span>
            }
            {pay.master_c2p_promotion_cents > 0 &&
              <span>Mastercard C2P: <b>{centsToAmountString(pay.master_c2p_promotion_cents)}€</b></span>
            }
          </Tooltip>
        }
      </Td>
      <Td>{centsToAmountString(getSubtotalCents(pay)) + '€'}</Td>
      <Td>{centsToAmountString(pay.tip_cents) + '€'}</Td>
      <Td>{centsToAmountString(pay.refunded_amount) + '€'}</Td>
      <Td>{centsToAmountString(getTotalCents(pay)) + '€'}</Td>
      <Td centered>
        <StatusBadge status={pay.status} refunded={pay.refunded} payment={pay} />
      </Td>
      <Td centered>
      {pay.items && pay.items.length > 0 &&
        <ItemsBadge payment={pay} />
      }
      </Td>
      {!pay.refunded && user.rol !== 'waiter' && pay.split_type !== 'recharge' &&
        <Td centered onClick={() => openRefundModal(pay)}>
          <div className='m-auto w-min cursor-pointer'>
            <RefundsIcon className='h-5 w-5'/>
          </div>
        </Td>
      }
    </tr>
  );
};

const Td = styled.td`
  text-align: ${({centered}) => centered ? 'center' : 'right'};
  padding: 0.75rem;

  .yumminn_tooltip span {
    color: #FFF;
  }
`;
