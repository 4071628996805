import styled from 'styled-components';

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #404040;
  margin-top: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
`

export {CardTitle}