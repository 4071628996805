import styled, { css } from 'styled-components';

const baseButton = css`
  --btn_color: #FFFFFF;
  --btn_bg_color: #094553;
  --btn_border_color: #094553;

  font-size: var(--font_md);
  font-weight: 600;
  line-height: 1;
  min-height: 44px;
  padding: 8px 42px;
  padding: clamp(8px, 0.5em, 16px) clamp(42px, 2em, 84px);
  color: var(--btn_color);
  background-color: var(--btn_bg_color);
  border: 2px solid var(--btn_border_color);
  border-radius: 99px;

  &::first-letter {
    text-transform: capitalize;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const LockerPageContainer = styled.div`
  --font_xxl: clamp(1.5rem, 3.5vh, 3.5rem);
  --font_xl: clamp(1.25rem, 1.3vw, 2.5rem);
  --font_lg: clamp(1.125rem, 1.15vw, 2.3rem);
  --font_md: clamp(1rem, 1.1vw, 2rem);
  --font_sm: clamp(0.875rem, 0.9vw, 1.85rem);
  --font_xs: clamp(0.8rem, 0.6vw, 1.5rem);

  display: flex;
  flex: 1;
  height: 100%;
  width: 100;
  overflow-x: clip;
  margin: 0 -32px;
  
  .responsive_size {
    font-size: var(--font_md);
  }
`;

export const LockerList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  grid-auto-rows: min-content;
  gap: 24px;
  flex: 1;
  border-top: 1px solid #F4F4F5;
  padding: 32px;
  margin: 0;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 200px);
  overflow: auto;
  transition: 0.2 ease-in-out;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const LockerCardContainer = styled.li`
  --shadow_color: #DADADA;

  &.selected {
    --shadow_color: #094553;
  }

  color: #404040;
  background-color: #FFF;
  border: 1.5px solid var(--shadow_color);
  border-radius: 18px;
  overflow: hidden;
  transition: 0.15s ease-in-out;

  &.selected, &:hover, &:has(:focus) {
    box-shadow:
      0px 0px 0 1px var(--shadow_color),
      2px 2px 0 var(--shadow_color),
      4px 4px 0 var(--shadow_color),
      6px 6px 0 var(--shadow_color);
    translate: -6px -6px;
  }

  &:active {
    box-shadow: 
      0px 0px 0 1px var(--shadow_color),
      2px 2px 0 var(--shadow_color);
    translate: -2px -2px;
  }

  &.disabled {
    box-shadow: none;
    translate: 0;
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    transition: background-color 0.2s ease-in-out;

    button:disabled {
      opacity: 0.5;
    }
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.3em;
    opacity: 0.9;
    
    &::first-letter {
      text-transform: capitalize;
    }
  }
  
  button {
    opacity: 0.9;
  }

  & > div {
    padding: 16px;
  }
`;

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: flex-end;
  height: min-content;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  background-color: #094553;
  color: #FFFFFF;
  border-radius: 99px;
  padding: 8px 32px;

  svg {
    opacity: 0.5;
  }
`;

export const HiddenButton = styled.button`
  display: none;
`;

export const OrderItemContainer = styled.li`
  --shadow_color: #DADADA;

  &.selected, &:has(:focus) {
    --shadow_color: #094553;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-size: var(--font_md);
  border: 1.5px solid var(--shadow_color);
  border-radius: 10px;
  padding: 0.5em 1em;
  transition: 0.2s ease-in-out;
  position: relative;

  &.selected, &:hover, &:has(:focus) {
    box-shadow:
      0px 0px 0 1px var(--shadow_color),
      1px 1px 0 var(--shadow_color),
      2px 2px 0 var(--shadow_color),
      4px 4px 0 var(--shadow_color);
    translate: -4px -4px;
    z-index: 9;
  }

  &:active {
    box-shadow:
      0px 0px 0 1px var(--shadow_color),
      1px 1px 0 var(--shadow_color);
    translate: -1px -1px;
  }

  .code {
    background-color: #EEF9F5;
    border: 1px solid #AFDCCA;
    border-radius: 99px;
    padding: 0 8px;
    white-space: nowrap;
    
    &:focus {
      background-color: #B6EFD8;
      outline-color: #5FB894;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  b {
    font-weight: 600;
  }

  .time {
    display: flex;
    gap: 8px;
    width: 4.6em;
  }

  &.fixed_size {
    font-size: 1rem;
  }
`;

export const CounterButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  border: 2px solid #D4D4D8;
  border-radius: 10px;
  width: 100%;
  padding: 8px 16px;

  b {
    font-weight: 600;
  }

  svg {
    margin: 0 -4px 0 auto;
  }

  &:disabled {
    color: #40404090;
  }
`;

export const OrderOptionLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 1rem;

  .code {
    background-color: #EEF9F5;
    border-radius: 99px;
    padding: 0 8px;
    white-space: nowrap;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  b {
    font-weight: 600;
  }

  .time {
    display: flex;
    gap: 8px;
    width: 4.6em;
  }

  .expired {
    text-decoration: line-through;
  }
`;

export const PopupHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border-bottom: 1.5px solid #DDDDDD;
  padding-bottom: 24px;
  margin-bottom: 24px;

  h2 {
    font-size: var(--font_lg);
    font-weight: 600;
    line-height: 1.3;
  }

  div {
    font-size: var(--font_sm);
  }
`;

export const PopupForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 500px;
  font-size: var(--font_sm);

  .row {
    display: flex;
    align-items: center;
    gap: 16px;
    
    & > div {
      max-width: 50%;
      font-size: var(--font_sm);
    }
    
    &:first-child > div {
      min-width: 50%;
    }

    .key {
      width: 200px;
      max-width: 200px;
    }
  }
  
  .counter {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    margin-top: 26px;
  }

  h6 {
    font-size: var(--font_md);
    font-weight: 300;
    font-weight: 400;
    margin: 0 0 4px;
  }


`;

export const PopupFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  border-top: 1.5px solid #DDDDDD;
  padding-top: 24px;
  margin-top: 24px;

  button {
    ${baseButton}
  }

  .deleteButton {
    --btn_color: #F09B9B;
    --btn_bg_color: transparent;
    --btn_border_color: #F09B9B;
    
    margin-right: auto;
  }

  .cancelButton {
    --btn_color: #5FB894;
    --btn_bg_color: transparent;
    --btn_border_color: #5FB894;
  }
`;

export const Panel = styled.div`
  position: relative;
  top: 0;
  z-index: 2;
  transition: ease-in-out 0.2s;
  
  main {
    position: sticky;
    top: 0;
    margin-top: -95px;
    padding: 32px;
    background-color: #FFFFFF;
    border: 1.5px solid #DDDDDD;
    border-right: none;
    border-radius: 32px 0 0 0;
    min-width: 260px;
    height: calc(100vh - 105px);
    overflow: auto;
    z-index: 2;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.out {
    max-width: 260px;
    translate: 100% 0;
  }
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  
  button {
    font-size: var(--font_sm);
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    color: #FFFFFF;
    background-color: #094553;
    border-radius: 99px;
    padding: clamp(8px, 0.5em, 16px) clamp(16px, 1em, 24px);
  }

  ul {
    --gap: clamp(16px, 2vh, 24px);

    display: grid;
    grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr auto;
    gap: clamp(16px, 2vh, 24px);
    flex: 1;
    max-height: 1000px;
    padding: 0 8px;
    margin: 0 -8px auto;
    position: relative;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before, &::after {
      content: '';
      position: sticky;
      width: 100%;
      height: 24px;
      left: 0;
    }

    &::before {
      top: 0;
      margin-bottom: calc(var(--gap) * -1);
      background: linear-gradient(#FFF 30%, #FFF0 100%);
    }

    &::after {
      bottom: 0;
      margin-top: calc(var(--gap) * -1);
      background: linear-gradient(#FFF0 0%, #FFF 70%);
    }
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 15%;
    height: 100%;
    border: 1.5px solid #40404026;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0px 2px 8px #959DA526;

    &:nth-child(2n) > b {
      color: #5FB894;
    }
  }

  .timer {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      font-weight: 600;
    }
  }

  li, .timer {
    b {
      font-size: var(--font_xxl);
      font-weight: 600;
      color: #094553;
      line-height: 1;
    }
    
    span {
      font-size: var(--font_xs);
      line-height: 1.2;
      color: #767676;
    }
  }
  
  p {
    font-size: var(--font_sm);
    max-width: 195px;
    text-align: center;
    text-wrap: balance;
  }
`;

export const SideOrderPanel = styled.div`
  position: absolute;
  right: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border: 1.5px solid #DDDDDD;
  border-right: none;
  border-radius: 24px 0 0 24px;
  min-width: 450px;
  padding: 24px;
  margin-top: -50px;
  min-height: min(450px, calc(100% + 50px));
  max-height: calc(100% + 50px);
  z-index: 1;
  transition: translate ease-in-out 0.2s;

  & > button {
    margin: 0 0 8px auto;
  }

  &.out {
    translate: 100% 0;
  }
`;

export const PanelTemplate = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-width: 100%;
  color: #404040;

  header {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  h3 {
    font-size: var(--font_xl);
    font-weight: 600;
    line-height: 1.2;
  }

  hr {
    width: 100%;
    margin: 16px 0;
    border: none;
    border-bottom: 1.5px solid #DDDDDD;
  }

  & > p {
    font-size: var(--font_md);
    display: flex;
    gap: 4px;
  }

  & > p + ul {
    margin-top: 0;
  }

  .tabs {
    font-size: var(--font_md);
    width: fit-content;
  }

  .locker_actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
    font-size: var(--font_sm);
  }

  .clear, .clear_all {
    ${baseButton}
    padding: 4px 24px;
    height: 36px;
    align-self: flex-start;
  }

  .clear {
    --btn_color: #FFFFFF;
    --btn_bg_color: #094553;
    --btn_border_color: #094553;
  }

  .clear_all {
    --btn_color: #FFFFFF;
    --btn_bg_color: #E07A7A;
    --btn_border_color: #E07A7A;

    margin-top: 24px;
  }
`;

export const OrderFiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  margin: 24px 0;
  z-index: 10;

  label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    width: 170px;
    font-size: var(--font_sm);

    span {
      font-weight: 500;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  p {
    font-size: var(--font_sm);
    display: flex;
    gap: 4px;
    padding: 8px 0;
  }
`;

export const OrderList = styled.ul`
  font-size: var(--font_md);
  display: flex;
  flex-direction: column;
  gap: var(--font_md);
  min-width: 29.4em;
  box-sizing: content-box;
  flex: 1;
  padding: 0 6px;
  margin: -24px -6px;
  overflow: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar-thumb {
    border: 7px solid transparent;
  }

  &::before, &::after {
    content: '';
    position: sticky;
    width: 100%;
    height: 24px;
    left: 0;
    flex-shrink: 0;
    z-index: 2;
  }

  &::before {
    top: 0;
    margin-bottom: -16px;
    background: linear-gradient(#FFF 50%, #FFF0 100%);
  }

  &::after {
    bottom: 0;
    margin-top: -16px;
    background: linear-gradient(#FFF0 0%, #FFF 50%);
  }
`;

export const LockerOptionLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  color: #404040;
  border-radius: 4px;
  width: min-content;
  padding: 8px;
`;

export const ItemButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font_sm);

  span{
    white-space: nowrap;
    
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

export const OrderDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 28.75em;
  overflow: hidden;
  font-size: var(--font_md);

  .order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    background-color: #FCFCFC;
    border: 1.5px solid #EDEDED;
    border-radius: 10px;
    padding: 24px;

    p {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      margin: 0;
    }

    b {
      font-size: var(--font_xl);
      font-weight: 600;
      line-height: 1;
      
      &.negative {
        color: #E7443A;
      }
    }
    
    span {
      font-size: var(--font_lg);
      font-weight: 500;
      line-height: 1.15;
      text-align: center;
    }
  }

  .client {
    display: flex;
    justify-content: space-between;
    gap: 1.5em;
    padding: 16px;
    border-radius: 10px;
    background-color: #F8F8F8DA;
    margin: 1.5em 0px;

    p {
      display: flex;
      flex-direction: column;
      margin: 0;
      position: relative;
      flex: auto;
      width: 0;
      max-width: min-content;

      &:after {
        content: attr(data-after);
        position: absolute;
        top: 100%;
        white-space: nowrap;
        border-radius: 8px;
        box-shadow: 0 4px 6px -1px #0001, 0 2px 4px -1px #0001;
        background-color: #FFF;
        padding: 8px;
        pointer-events: none;
        opacity: 0;
        z-index: 9999;
        transition: opacity 0.2s ease-in-out;
      }

      &:first-child::after {
        left: 0;
      }

      &:nth-child(2)::after {
        left: 50%;
        translate: -50% 0;
      }

      &:last-child::after {
        right: 0;
      }

      &:hover::after {
        opacity: 1;
      }
    }

    span {
      font-size: var(--font_md);
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }

  .products, .comment {
    border: 1.5px solid #EDEDED;
    border-radius: 10px;
    padding: 16px;
  }

  .products {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: var(--font_lg);
    }
    
    ul {
      h5, b {
        font-size: var(--font_md);
      }
      gap: 0.5em;
    }
  }
  
  .comment {
    min-height: 64px;
    padding: 8px 16px;
    margin-top: 1em;
  }

  .total {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 1em;

    b {
      font-size: var(--font_lg);
    }
  }
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  flex:1;

  h4 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0.88em;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #FFFFFF;
      background-color: #404040;
      border-radius: 4em;
      height: 1.35em;
      min-width: 1.35em;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    overflow: hidden;
    flex-shrink: 0;
  }

  & > ul {
    flex: 1;
    overflow: auto;
    scrollbar-width: thin;

    ::-webkit-scrollbar-thumb {
      border: 7px solid transparent;
    }
  }

  li {
    display: flex;
    align-items: flex-start;
    gap: 0.5em;
    font-size: 1rem;
    
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.75rem;
      background-color: #F0F0F0;
      border-radius: 4em;
      height: 1.7em;
      min-width: 1.7em;
    }

    h5, b {
      line-height: 1.3em;
    }

    b {
      margin-left: auto;
      font-weight: 600;
    }
  }

  ul > ul {
    padding-left: 16px;

    li {
      font-size: 0.875rem;

      b {
        font-weight: 500;
      }

      &::before {
        content: '⤷';
        font-size: 1.5rem;
        line-height: 0.86;
        flex-shrink: 0;
        color: #C7C7C7;
        overflow: hidden;
      }
    }
  }
`;

export const LockerBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size:var(--font_sm);
  font-weight: 500;
  line-height: 1;
  background-color: ${({color}) => color};
  border-radius: 5px;
  padding: 8px;
  margin: -3px 0;
  max-height: 2.15em;
  min-width: min-content;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ManageOrderContainer = styled.div`
  main {
    display: flex;
    gap: 24px;
    min-height: min-content;
    max-height: 70vh;
  }
  
  .manage {
    max-width: min-content;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h3 {
      font-size: var(--font_lg);
      line-height: 1.3;
      max-width: 100%;
      white-space: nowrap;

      b {
        font-weight: 600;
      }
    }

    p {
      text-decoration: underline;
      white-space: nowrap;
      font-size: var(--font_sm);
    }
    
    & > div {
      font-size: var(--font_md);
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: min-content;
      margin-top: auto;
    
      label {
        padding: 0.5em;
        border: 2px solid #DDDDDD;
        border-radius: 0.6em;
        width: 100%;
        min-height: 40px;
        min-width: max-content;
        transition: 0.15s ease-in-out;

        &:has(:checked) {
          color: #FFFFFF;
          background-color: #5FB894;

          div {
            border-color: #FFFFFF;
            height: 1.2em;
            width: 1.2em;
            border-radius: 0.3em;

            svg {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .selector_active {
      background-color: #EFFFF8;
      border-radius: 0.6em;
      width: 100%;

      & > div {
        border-color: #5FB894;
      }
    }
  }

  .order_card {
    display: flex;
    flex-direction: column;
    background-color: #FCFCFC;
    border: 1.5px solid #DDDDDD;
    border-radius: 16px;
    padding: 16px;
    max-height: 100%;
    max-width: 50vw;

    h4 {
      font-size: var(--font_lg);
      font-weight: 600;
      line-height: 1;
      padding-bottom: 16px;
      border-bottom: 1.5px solid #DDDDDD;
      margin-bottom: 16px;
    }

    & > div {
      flex: 1;
      height: 0;
      opacity: 0.7;
      overflow: auto;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    border-top: 1.5px solid #DDDDDD;
    padding-top: 24px;
    margin-top: 24px;

    button {
      ${baseButton}
    }

    .cancel {
      --btn_color: #5FB894;
      --btn_bg_color: transparent;
      --btn_border_color: #5FB894;
    }
  }
`;

export const ClientCardContainter = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  background-color: #F8F8F8;
  opacity: 0.8;
  border-radius: 10px;
  padding: 0.5em 1em;

  p {
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: start;
    font-weight: 500;
  }

  span {
    font-weight: 400;

    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const ConfirmClearAllPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    font-size: 1.125rem;
    max-width: 440px;
    text-align: center;
    text-wrap: balance;
  }

  b {
    font-weight: 600;
  }

  footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  button {
    ${baseButton}
  }

  .cancel {
    --btn_color: #5FB894;
    --btn_bg_color: transparent;
    --btn_border_color: #5FB894;
  }
`;