import React from 'react';
import { STATUS_COLORS } from './constants';
import { useTranslation } from 'react-i18next';
import { getPurchaseData } from './functions';

export const PurchaseRow = ({purchase, selected, onClick}) => {
  const { t } = useTranslation();

  const { status, date, total } = getPurchaseData(purchase);

  return (
    <tr className={selected ? 'selected' : ''} onClick={() => onClick(purchase.id)}>
      <td>
        <span className='purchase'>
          {purchase.code}
        </span>
      </td>
      <td>
        <span className='status' style={{backgroundColor: STATUS_COLORS[status]}}>
          {t(status)}
        </span>
      </td>
      <td>
        <span className='date'>
          {date}
        </span>
        </td>
      <td>{total}€</td>
    </tr>
  );
};
