import React from 'react';
import { useTranslation } from 'react-i18next';

const YumminnPaymentsTabs = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col py-4'>
    </div>
  );
};

export default YumminnPaymentsTabs;
