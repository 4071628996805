import React, { useState } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {ReactComponent as MultiTypeCustomGroup} from "../../../../../img/MultiTypeCustomGroup.svg";
import {ReactComponent as IndividualTypeCustomGroup} from "../../../../../img/IndividualCustomGroupType.svg";
import { useTranslation } from "react-i18next";


const ChooseTypeCustomGroupModal = ({handleNewCustomGroup, setChooseType }) => {
	const [customGroupType, setType] = useState("individual");
  const { t } = useTranslation();

  return (
    <div style={{ zIndex: 1000, fontSize: "15px" }} className="absolute top-0 left-0 right-0 bottom-0 items-center">
      <div className='w-full max-w-min top-14 m-auto z-50 relative flex flex-col gap-4 items-center p-7 overflow-hidden bg-white rounded-xl'>
        <div className="font-bold max-w-min whitespace-nowrap">{t('what_type_of_highlight_you_wanna_create')}</div>
        <div style={{display: 'flex', gap: "30px"}}>
          <div  onClick={() => setType("individual")} style={{gap: "25px", cursor: "pointer", display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', width: "200px", height: "300px", backgroundColor: customGroupType === "individual" ? "#EFFFF8" : "white", borderRadius: "15px", borderWidth: "2px", borderColor: customGroupType === "individual" ? '#5FB894' : "#E4E4E4"}}>
            <IndividualTypeCustomGroup style={{transform: "scale(1.1)"}}/>
            <div style={{display: 'flex', fontWeight: 600, marginBottom: "10px", color: customGroupType === "individual" ? "black" : "#838383"}}>Un producto</div>
          </div>
          <div onClick={() => setType("multi")} style={{gap: "25px", cursor: "pointer", display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', width: "200px", height: "300px", backgroundColor: customGroupType === "multi" ? "#EFFFF8" : "white", borderRadius: "15px", borderWidth: "2px", borderColor: customGroupType === "multi" ? '#5FB894' : "#E4E4E4"}}>
              <MultiTypeCustomGroup style={{transform: "scale(1.1)"}}/>
            <div style={{display: 'flex', fontWeight: 600, marginBottom: "10px", color: customGroupType === "multi" ? "black" : "#838383"}}>Varios productos</div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <button
          style={{width: "160px"}}
          className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xs text-gray-500 font-bold"}
          onClick={() => setChooseType(false)}>{t('cancel')}</button>
          <button
          style={{width: "160px"}}
          className={"rounded-full cursor-pointer py-2 px-5 m-2 bg-principal text-xs text-white font-bold"}
          onClick={() => handleNewCustomGroup(customGroupType)}>{t('create')}</button>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default ChooseTypeCustomGroupModal