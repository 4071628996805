import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import NewFeaturesPillCard from "./NewFeaturesPillCard";

const NewFeaturesImageCard = ({image, pillTitle, pillColor}) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);
  const newImage = image.includes('media') ? image : (process.env.REACT_APP_AWS_S3_URL + '/' + image);

  return  (
    <div className='flex-1 bg-cover bg-center relative w-full h-full rounded-br-[12px] rounded-tr-[12px]' style={{border: "1px solid #D7D7D7", backgroundImage: `url(${ newImage })`}} onError={() => setImgError(true)} >
      <NewFeaturesPillCard pillTitle={pillTitle} pillColor={pillColor}/>
    </div>
  )
}

export default NewFeaturesImageCard;
