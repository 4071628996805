import * as Yup from 'yup';

import {apiAuth} from 'logic/api';
import {useAxios, useLazyAxios} from 'use-axios-client';
import { useParams} from 'react-router';

import FormError from 'common/FormError';
import {Formik} from 'formik';
import React from 'react';
import Select from 'react-select';
import XButton from 'common/XButton';
import {sayError, select_styles} from 'logic/defaults';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import useUpdateTpvData from 'components/hooks/useUpdateTpvData';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  // name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);


export default function RestaurantsEditTPV() {
  const {t} = useTranslation();
  const {id} = useParams();
  const [tpv, setTpv] = React.useState(null)
  const [fields, setFields] = React.useState(null)
  const [modifiedData, setModifiedData] = React.useState(null)


  const {data: list} = useAxios({
    axiosInstance: apiAuth,
    url: `/tpvs/`,
  });

  //   React.useEffect(() => {
  //     getData();
  //   }, [getData]);

  const [getData, {data, error, loading}] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants-admin-new/${id}/`,
  });

  // const [getFieldsData, { data: fields }] = useLazyAxios({
  //   axiosInstance: apiAuth,
  //   url: `/restaurants-admin/setup_fields/?tpv_id=${id}/`,
  // });

  const {updateData, error: updatedError} = useUpdateTpvData({
    entidad: 'restaurants-admin-new',
    id,
  });

  React.useEffect(() => {
    getData();
  }, [getData]);
  

  React.useEffect(() => {
    if (tpv) {
      getFieldsData(tpv);
    }
  }, [tpv]);

  React.useEffect(() => {
    if (data && data.tpv) {
      getFieldsData(data.tpv);
    }
  }, [data]);

  React.useEffect(() => {
     let newData = {...data}
     fields && newData.tpv_metadata.result_fields && fields.map((field) => {
        if (!newData[field.name]){
          newData[field.name] = newData.tpv_metadata.result_fields[field.name]
          newData.tpv_metadata.result_fields["tpv"] = newData.tpv;
        }

        newData[field.name] = newData.tpv_metadata.source_fields[field.name]
      })
      setModifiedData(newData)
  }, [fields, data]);
  

  const getFieldsData = async (id) => {
    try {
      const res = await apiAuth.get(`/restaurants-admin-new/setup_fields/?tpv_id=${id}`);
      setFields(res.data) 
    } catch (error) {
      toast.error(sayError(error));
    }
  }

  const onSubmit = async (values, {setSubmitting}) => {
    //console.log(values, "values");
    const enviaremos = beforeSubmit(values);
    //console.log(enviaremos, "enviaremos");
    await updateData(enviaremos);
    toast.success(`${t('update_ok')}`);
    getData();
  };

  const beforeSubmit = (values) => {
    let after = {...values};

    delete after.chef_photo
    delete after.logo
    delete after.menu
    delete after.home_image
    
    fields.map((field) => {
      if(after.tpv_metadata.result_fields){ //this means this is un update or create metadata BDP (working)
        after.tpv_metadata.result_fields[field.name] = after[field.name];
        after.tpv_metadata.result_fields["tpv"] = after.tpv; 
      } else{ //delete dummy metadata created when creating restaurant for the first time
        delete values.tpv_metadata["a"]
      }
      const tpv = after.tpv
      switch(tpv){
        case 6: //1 in local, 6 in prod
          //create new metatada Agora X
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.base_url = after["base_url"] 
          after.tpv_metadata["result_fields"]["api_token"] = after["api_token"] 
          after.tpv_metadata["result_fields"]["workplace_id"]= after["workplace_id"] 
          after.tpv_metadata["result_fields"]["payment_method_id"]= after["payment_method_id"] 
          after.tpv_metadata["result_fields"]["pos_id"]= after["pos_id"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.base_url = after["base_url"] 
          after.tpv_metadata["source_fields"]["api_token"] = after["api_token"] 
          after.tpv_metadata["source_fields"]["workplace_id"]= after["workplace_id"] 
          after.tpv_metadata["source_fields"]["payment_method_id"]= after["payment_method_id"] 
          after.tpv_metadata["source_fields"]["pos_id"]= after["pos_id"] 
          break;
        case 4: //4 in prod, 6 in local
          //create new metadata Revo working
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.tenant = after["tenant"] 
          after.tpv_metadata.result_fields.authorization = after["authorization"] 
          after.tpv_metadata.result_fields.payment_method = after["payment_method"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.tenant = after["tenant"] 
          after.tpv_metadata.source_fields.authorization = after["authorization"] 
          after.tpv_metadata.source_fields.payment_method = after["payment_method"]
          break;
        case 1: //1 in prod, 4 in local
          //create new metadata HT (not working) 
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.local_id = after["local_id"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.tpv_username = after["tpv_username"] 
            break;
        case 5: // 5 both in prod and local
          //create new metadata lastApp
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.authorization = after["authorization"] 
          after.tpv_metadata.result_fields.location_name = after["location_name"] 
          after.tpv_metadata.result_fields.location_name = after["brand_id"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.authorization = after["authorization"] 
          after.tpv_metadata.source_fields.location_name = after["location_name"] 
          after.tpv_metadata.source_fields.location_name = after["brand_id"] 
          break;
        case 9: //3 en local, 9 en prod
          //create new metadata Glop
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.client_id = after["client_id"] 
          after.tpv_metadata.result_fields.client_secret = after["client_secret"] 
          after.tpv_metadata.result_fields.group_id = after["group_id"] 
          after.tpv_metadata.result_fields.id_terminal = after["id_terminal"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.client_id = after["client_id"] 
          after.tpv_metadata.source_fields.client_secret = after["client_secret"] 
          after.tpv_metadata.source_fields.group_id = after["group_id"] 
          after.tpv_metadata.source_fields.id_terminal = after["id_terminal"] 
          break;
        case 7: //10 en local, 7 en prod
          //create new metadata Sofyman 
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.api_key = after["api_key"] 
          after.tpv_metadata.result_fields.punto_venta_id = after["punto_venta_id"] 
          after.tpv_metadata.result_fields.organization_id = after["organization_id"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.api_key = after["api_key"] 
          after.tpv_metadata.source_fields.punto_venta_id = after["punto_venta_id"] 
          after.tpv_metadata.source_fields.organization_id = after["organization_id"]
          break;
        case 10: //8 in local, 10 in prod 
          //create new metadata Neogrup 
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.base_url = after["base_url"] 
          after.tpv_metadata.result_fields["api-key"] = after["api-key"] 
          after.tpv_metadata.result_fields.shop_id = after["shop_id"] 
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.base_url = after["base_url"] 
          after.tpv_metadata.source_fields["api-key"] = after["api-key"] 
          after.tpv_metadata.source_fields.shop_id = after["shop_id"] 
          break;
        case 48:
          //create new metadata Sinqro
          after.tpv_metadata.result_fields = {}
          after.tpv_metadata.result_fields.authorization = after["authorization"]
          after.tpv_metadata.result_fields.authentication = after["authentication"]
          after.tpv_metadata.result_fields.selling_point_id = after["selling_point_id"]
          after.tpv_metadata.source_fields = {}
          after.tpv_metadata.source_fields.authorization = after["authorization"]
          after.tpv_metadata.source_fields.authentication = after["authentication"]
          after.tpv_metadata.source_fields.selling_point_id = after["selling_point_id"]
          break;
        default:
          break;
      }
    })

    /*after.tpv = values.tpv ? values.tpv.id : null;
    after.tpv_key = values.tpv_key;
    after.tpv_secret = values.tpv_secret;
    after.tpv_password = values.tpv_password;
    after.tpv_username = after.tpv_metadata.source_fields.tpv_username;
    after.tpv_wildcard_product_name = after.tpv_metadata.result_fields.tpv_wildcard_product_name;
    after.tpv_wildcard_product_code = values.tpv_wildcard_product_code;
    after.tpv_local_id = values.tpv_local_id;*/ 

    return after;
  };

  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error || updatedError) {
    return <pre>{JSON.stringify(error || updatedError, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-col'}>
        <Formik
          enableReinitialize={true}
          initialValues={modifiedData}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
            <form onSubmit={handleSubmit} autoComplete='off'>
              {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}

              <div className='flex flex-row space-x-5 w-full'>
                {/* col2 */}

                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_key'
                    >
                      {t('TPV_Platform')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <Select
                        menuPortalTarget={document.body}
                        styles={select_styles}
                        className='w-full max-w-md text-[14px]'
                        name={'TPV'}
                        placeholder={t(`Empezar a escribir TPV...`)}
                        options={list && list.results}
                        value={
                          typeof values.tpv === 'number'
                            ? list && list.results.filter((l) => l.id === values.tpv)
                            : values.tpv
                        }
                        onChange={(a) => {
                          setFieldValue('tpv', a);
                          setTpv(a.id)
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    </div>
                    <FormError message={errors.tpv_key}/>
                  </div>

                  {fields && fields.map((field) => (
                    <div className='flex items-center w-full space-x-2'>
                      <label
                        className='w-72 text-[14px] text-texto text-right'
                        htmlFor='tpv_key'
                      >
                        {t(field.name)}
                      </label>
                      <div className='w-full flex flex-col mb-1'>
                        <input
                          type={field.type}
                          placeholder={t(field.name)}
                          className='yumminn-input'
                          value={(values[field.name])}
                          name={field.name}
                          onChange={handleChange}
                          autoComplete='one-time-code'
                        />
                        <FormError message={errors.tpv_key}/>
                      </div>
                    </div>
                  ))}

                </div>
                {/* END col2 */}
              </div>

              <div className='self-end my-5 w-full'>
                <XButton
                  className='btn btn-primary rounded-full px-12'
                  type='button'
                  doClick={handleSubmit}
                >
                  {t('Send')}
                </XButton>
              </div>
            </form>
          )}
        </Formik>

        <div>   
          {data && data.tpv_metadata && data.tpv_metadata.result_fields &&
            <div className={"w-1/2"}>
              {Object.keys(data.tpv_metadata.result_fields).map(key => (
                <div className={"flex flex-row w-full"}>
                  <div className={"w-1/2 font-bold"}>{(key !== "tpv_wildcard_product_code" && key !== "tpv_password") && t(key)}</div>
                  <div className={"w-1/2"}>{(key !== "tpv_wildcard_product_code" && key !== "tpv_password") && (data.tpv_metadata.result_fields[key] || data.tpv_metadata.source_fields[key])}</div>
                </div>
              ))
              }
            </div>
          }
        </div>
      </div>
    );
  }
}