import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { TrashIcon } from '@heroicons/react/outline';
import { baseAuth } from "logic/api";

const DeleteCustomGroupModal = ({ t, custom_group, setCustomGroupToRemove, removeCustomGroup }) => {

  return (
    <div style={{ zIndex: 1000, fontSize: "15px" }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div className='w-full max-w-min top-14 m-auto z-50 relative flex flex-col gap-4 items-center p-7 overflow-hidden bg-white rounded-xl'>
      <TrashIcon 
          className={`w-[30px] text-gray-700`}
        />
        <div className="font-bold max-w-min whitespace-nowrap">Eliminar Destacado</div>
        <div className="text-xs text-center max-w-min whitespace-nowrap">¿Estás seguro que quieres eliminar este <br/>destacado? Como alternativa, <b>puedes <br/>desactivarlo y no perder la configuración.</b></div>
        <div className="flex flex-row">
        <button
          className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xs text-gray-500 font-bold"}
          onClick={() => setCustomGroupToRemove(null)}>No, cancelar</button>
          <button
          className={"rounded-full cursor-pointer py-2 px-5 m-2 bg-principal text-xs text-white font-bold"}
          onClick={() => removeCustomGroup(custom_group, 0)}>Sí, eliminar</button>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default DeleteCustomGroupModal