import React, { useState } from 'react';
import { ColumnContainer, RowContainer } from '../ExampleContainers';
import { Button } from 'common/Button';
import { Popup } from 'common/Popup';
import { ConfirmPopup } from 'pages/admin/pages/Lockers/ConfirmPopup';
import { SuccessPopup } from 'common/SuccessPopup';

export const PopupExample = () => {
  const [popup, setPopup] = useState(null);

  const close = () => setPopup(null);

  return (
    <ColumnContainer>
      <h2>Base popup</h2>
      <RowContainer>
        <Button
          label='Popup'
          onClick={() => setPopup(1)}
        />
      </RowContainer>
      <h2>Commonly used</h2>
      <RowContainer>
        <Button
          label='ConfirmPopup'
          onClick={() => setPopup(2)}
        />
        <Button
          label='SuccessPopup'
          onClick={() => setPopup(3)}
        />
      </RowContainer>
      <Popup isOpen={popup === 1} close={close}>
        <ColumnContainer>
          <h3>Popup</h3>
          <p>Basic popup to show content over the screen, it can be closed by clicking outside.</p>
          <p>It can be used as a wrapper, extended or <a href='https://styled-components.com/docs/basics#styling-any-component' target='_blank' rel='noreferrer'>styled</a>.</p>
        </ColumnContainer>
      </Popup>
      <ConfirmPopup
        isOpen={popup === 2}
        title={'ConfirmPopup'}
        message={
          <p>
            Simple popup with a title and a message to confirm o dismiss an action.
            <br/>
            Button labels can be modified using <code>cancelLabel</code> and <code>confirmLabel</code>.
          </p>
        }
        cancel={close}
        confirm={close}
      />
      <SuccessPopup
        isOpen={popup === 3}
        title={'SuccessPopup'}
        message={
          <p>
            An informative popup with a title and a message.
            <br/>
            Button label can be modified using <code>closeLabel</code>.
          </p>
        }
        close={close}
      />
    </ColumnContainer>
  );
};
