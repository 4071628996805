import { toDecimalString } from 'logic/functions';
import React from 'react';
import { PaymentsLayout } from './PaymentsLayout';

export const TipsOverview = ({data, dates}) => {
  
  return (
    <PaymentsLayout
      title='tips'
      infoOptions={dates}
      period={{total_period: data?.total_period}}
      metrics={{
        payments_with_tip: {...data?.payments_with_tip , value: (toDecimalString(data?.payments_with_tip.value, 1)) + '%'},
        tip_on_payment: {...data?.tip_on_payment , value: (toDecimalString(data?.tip_on_payment.value, 1)) + '%'},
      }}
      current={{
        today: data?.today,
        last_week: data?.last_week,
        last_month: data?.last_month,
      }}
    />
  );
};
