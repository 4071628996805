import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

export const ToggleSwitch = ({
  name,
  checked,
  disabled,
  onChange,
  offColor = '#AAAAAA',
  offHandleColor = '#5D5D5D',
  onColor = '#A5EEDD',
  onHandleColor = '#094553',
  className = '',
}) => {
  return (
    <StyledSwith
      name={name}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      height={14}
      width={28}
      handleDiameter={18}
      offColor={offColor}
      offHandleColor={offHandleColor}
      onColor={onColor}
      onHandleColor={onHandleColor}
      activeBoxShadow={`0 0 0 3px ${onHandleColor}40`}
      uncheckedIcon={false}
      checkedIcon={false}
      className={`${className} ${disabled ? 'disabled' : ''}`}
    />
  );
};

const StyledSwith = styled(Switch)`
  &.disabled * {
    cursor: not-allowed !important;
  }
`;