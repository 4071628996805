import React from 'react';
import logo from '../../img/logo.png';
import { Link, useHistory, useParams } from 'react-router-dom';
import { XInput, XTextButton } from '../../styles/StyledComponents';
import { useTranslation } from 'react-i18next';
import { api, apiAuth } from '../../logic/api';
import { toast } from 'react-toastify';
import { sayError } from 'logic/defaults';
import background from '../../img/bg-login.svg'

const Update = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useParams();

  React.useEffect(() => {
    if (!token || token === '') {
      history.push('/');
    }
    handleValidateToken();
  }, [history, token]);

  const [submitting, setSubmitting] = React.useState(false);
  const [state, setState] = React.useState({
    password: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidateToken = async () => {
    if (token) {
      try {
        await api.post('/password_reset/validate_token/', { token });
      } catch (error) {
        toast.error(sayError(error.response.data));
        setTimeout(() => history.push('/'), 2000);
      }
    } else {
      toast.error(`${t('no_password')}`);
      setTimeout(() => history.push('/'), 2000);
    }
  };

  const handleUpdate = async () => {
    if (state.password !== '') {
      setSubmitting(true);
      try {
        await api.post('/password_reset/confirm/', {
          password: state.password,
          token,
        });
        toast.success(`${t('update_password_ok')}`);
        setTimeout(() => history.push('/'), 2000);
      } catch (error) {
        toast.error(sayError(error.response.data));
      } finally {
        setSubmitting(false);
      }
    } else {
      toast.error(`${t('no_password')}`);
    }
  };

  return (
    <div className='flex h-screen w-full justify-center items-center bg-gray-100 bg-right-top bg-no-repeat'  style={{backgroundImage: `url(${background})`, backgroundSize: window.innerWidth > 768 ? '90%' : 'auto'}}>
      <div className='flex flex-col w-3/4 md:w-1/2 lg:max-w-md md:bg-white h-50 p-3 flex-column justify-around items-center space-y-4 bg-white shadow-sm rounded-xl'>
        <div className={'w-3/4 md:w-1/2'}>
          <img src={logo} alt='logo' />
        </div>
        <div className={'px-2 w-full'}>
          <h3 className={'font-bold mb-2 text-principal text-lg'}>
            {t('change_password_text')}
          </h3>

          {/*<div className={""}>*/}
          {/*    {t("new_password_explanation")}*/}
          {/*</div>*/}

          <div className={'font-medium text-sm text-capitalize py-2'}>
            {t('new_password')}*
          </div>
          <XInput
            icon={
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                />
              </svg>
            }
            type='password'
            name='password'
            placeholder={t('Password')}
            value={state.password || ''}
            doChange={handleChange}
          />
        </div>

        <XTextButton
          disabled={submitting || state.password === ''}
          submitting={submitting}
          doClick={handleUpdate}
        >
          {t('reset_password')}
        </XTextButton>

        {/* <div className={'text-sm'}>{t('password_conditions')}</div> */}

        <div>
          <Link to='/'>
            <div style={{ textAlign: 'center' }}>{t('return_home')}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Update;
