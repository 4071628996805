import React,{ useState } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import grey_arrow_up from "../../img/grey_arrow_up.svg";
import grey_arrow_down from "../../img/grey_arrow_down.svg";
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { deleteEventOrTicket, ObjectTypes } from './EventApi';
import { Icon, IconType } from 'common/Icon';
import { ActiveSwitch } from "../../pages/admin/pages/OrderAndPay/ActiveSwitch";
import { saveEventOrTicket } from './EventApi';
import { cloneDeep } from 'lodash';
import { DeleteEventPopup } from './DeleteEventPopup';
import { NotRemovablePopup } from './NotRemovablePopup';
import { getImageUrl } from 'logic/functions';

export const EventRow = ({event, editEvent, onToggleOpen, open, setEventDraft, mutate, restaurant }) => {

  const datePart = event?.datetime.split('T')[0];
  const timePart = event?.datetime.split('T')[1].split('Z')[0];
  const timeWithoutSeconds = timePart.split(':').slice(0, 2).join(':');
  const { t } = useTranslation();
  const imageUrl = event.image;
  const [isLoading, setIsLoading] = useState(false)
  const [active, setActive] = useState(event?.active)
  const [deletePopup, setDeletePopup] = useState(false)
  const [notRemovableEventPopup, setNotRemovableEventPopup] = useState(false)
  const notRemovable = event.not_removable;

  const toggleActiveEvent = async state => {
    const newEvent = cloneDeep(event)

    if (event) {
      setActive(state)
    };
    newEvent.active = state

    setIsLoading(true);

    try {
      await saveEventOrTicket({
        restaurant: restaurant,
        eventOrTicket: 'EVENT',
        eventToEdit: newEvent,
        ticketToEdit: null,
        object: newEvent, 
        t: t
      })

      mutate();
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);

    } catch (error) {
      setIsLoading(false);
      mutate();
      console.log("🚀 toggleActiveEvent error:", error);
    } 
  
  };

  const handleEdit = () => {
    editEvent(event)
    setEventDraft(event)
  }

  const openImage = () => {
    if(imageUrl){
      window.open(getImageUrl(imageUrl), '_blank');
    }
  };

  const handleDelete = () => {
    if(notRemovable) {
      setNotRemovableEventPopup(true)
  } else {
      setDeletePopup(true);
  }
  }

  const confirmDelete = () => {
    deleteEventOrTicket({objectType: ObjectTypes['EVENT'], object: event, mutate, t:t})
    setDeletePopup(false)
  }

  return (
    <tr className={`${open ? 'selected' : ''} ${!event?.active ? 'disabled' : ''}`} >
      <td onClick={onToggleOpen}>
        <span className='flex items-center gap-4'> 
          <img src={open ? grey_arrow_up : grey_arrow_down} alt='' />        
          <span 
            style={{overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} 
            className='px-2 py-0.5 min-w-20'
          >
            {event.name}
          </span>
        </span>
      </td>
      <td onClick={onToggleOpen}>
        <span>
        </span>
      </td>
      <td onClick={onToggleOpen}>
        <span className='datetime'>
          <span style={{fontWeight: 600}}>{t('Date and hour')}:</span> {`${datePart} ${timeWithoutSeconds}`}
        </span>
      </td>
      <td onClick={onToggleOpen}>
        <span className='flex justify-center items-center'>
          {imageUrl ? 
            <Button 
              size={BUTTON_SIZES.SM}
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={() => openImage()}
              label='see_photo'
              className='px-2'
            />
            :
            <Button 
              size={BUTTON_SIZES.SM}
              variant={BUTTON_VARIANTS.PRIMARY}
              onClick={() => handleEdit()}
              label='add_photo'
              leftIcon={<Icon type={IconType.ADD}/>}
            />
          }
        </span>
      </td>
      <td style={{ maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={onToggleOpen}>
        <span style={{whiteSpace: 'nowrap', maxWidth: '150px', minWidth: '213px', textOverflow: 'ellipsis', overflow: 'hidden'}} className='address'>
          <span style={{fontWeight: 600}}>{t('Address')}:</span> {event.location}
        </span>
      </td>
      <td style={{width: "20px"}}> 
        <ActiveSwitch
          noMessage={true}
          noTitle={true}
          disabled={isLoading}
          loading={isLoading}
          checked={active}
          onChange={toggleActiveEvent}
        />
      </td>
      <td style={{width: "20px"}}>
        <TrashIcon
          onClick={() => handleDelete()}
          className={`w-[20px] ${notRemovable ? 'text-gray-300' : 'text-gray-700'} cursor-pointer transition-all animate-fade-in-display delay-75 `}
        />
      </td>
      <td style={{width: "20px"}}>
        <PencilIcon
          onClick={() => handleEdit()}
          className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
        />  
      </td>
      {!notRemovable && <DeleteEventPopup isOpen={deletePopup} confirm={confirmDelete} cancel={() => setDeletePopup(false)} type='EVENT'/>}
      {notRemovable && <NotRemovablePopup isOpen={notRemovableEventPopup} confirm={()=> setNotRemovableEventPopup(false)} type='EVENT'/>}
    </tr>
  );
};
