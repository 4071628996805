import { BookOpenIcon, ChevronLeftIcon, ChevronRightIcon, DuplicateIcon, RefreshIcon, TranslateIcon, UploadIcon } from '@heroicons/react/outline'
import React from 'react'

export const HalfMobileMockup = ({children}) => {
  return (
    <div className='isolate h-[280px] w-[196px] rounded-t-[30px] bg-[#020203] pt-[10px] pl-[10px] pr-[10px] relative' style={{boxShadow: 'inset 0 0 2px 1px #020203, inset 0 0 3px 3px #777783'}}>
      <div className='h-[16px] w-[98px] rounded-b-[10px] bg-[#020203] absolute top-[10px] left-1/2 transform -translate-x-1/2 z-10'>
        <div className='h-[4px] w-[22px] rounded-full bg-[#262525] absolute top-[4px] left-1/2 transform -translate-x-1/2'/>
        <div className='h-[9px] w-[9px] rounded-full bg-[#262525] absolute top-[1px] right-[24px] flex'>
          <div className='h-[4px] w-[4px] rounded-full m-auto' style={{boxShadow: 'inset 0 1px 1px 1px #134c86'}}/>
        </div>
        <div/>
      </div>
      <div className='h-[16px] w-[3px] rounded-l-[4px] bg-[#020203] absolute top-[65px] left-[-2px]' style={{boxShadow: 'inset 0 0 1px 1px #020203, inset 0 0 3px 3px #777783'}}/>
      <div className='h-[32px] w-[3px] rounded-l-[4px] bg-[#020203] absolute top-[97px] left-[-2px]' style={{boxShadow: 'inset 0 0 1px 1px #020203, inset 0 0 3px 3px #777783'}}/>
      <div className='h-[32px] w-[3px] rounded-l-[4px] bg-[#020203] absolute top-[145px] left-[-2px]' style={{boxShadow: 'inset 0 0 1px 1px #020203, inset 0 0 3px 3px #777783'}}/>
      <div className='h-[48px] w-[3px] rounded-r-[4px] bg-[#020203] absolute top-[106px] right-[-2px]' style={{boxShadow: 'inset 0 0 1px 1px #020203, inset 0 0 3px 3px #777783'}}/>
      <div className='h-[5px] w-[180px] rounded-[50%] bg-[#00000070] filter blur-[2px] absolute bottom-[-2px] left-1/2 transform -translate-x-1/2 z-[-1]'/>
      <div className='h-full w-full rounded-t-[20px] bg-white flex flex-col overflow-hidden'>
        <div className='h-[44px] w-full bg-[#8F8F8F] p-[4px] flex items-end'>
          <div className='h-[17px] w-full rounded-[4px] bg-[#A5A5A5] text-[#E1E1E1] flex justify-between items-center p-[4px]'>
            <TranslateIcon height={10}/>
            <span className='text-[8px]'>yumminn.com</span>
            <RefreshIcon height={10}/>
          </div>
        </div>
        <div className='flex-1 overflow-hidden'>
          {children}
        </div>
      </div>
    </div>
  )
}
