import React, { useState } from 'react';
import {  ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, CartesianGrid, Bar, Cell, LabelList } from 'recharts';
import { useTranslation } from 'react-i18next';
import ArrowIndicator from 'components/yumminn/ArrowIndicator';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { calculatePercentageDifference  } from '../../pages/Dashboard';
import { abbreviateBigNumbers } from 'logic/functions';
import styled from 'styled-components';

const toAbbrebviatedStr = (num) => abbreviateBigNumbers(num || 0, {rounded: true, thousands: true})

const StripesPattern = ({color}) => {
  return (
    <pattern id={`stripes-${color}`} patternUnits='userSpaceOnUse' width='10' height='10' patternTransform='scale(0.6) rotate(20)'>
      <rect x='0' y='0' width='100%' height='100%' fill={`#${color}40`}/>
      <path d='M0 10h20z' strokeWidth='3.5' stroke={`#${color}90`} fill='none'/>
    </pattern>
  );
};

const CustomLabel = ({ x, y, width, height, value, color }) => {
  const fontSize = Math.min(width / 2.5, 14);

  return (
    <text
      x={x + width / 2}
      y={y + height - (fontSize / 2)}
      stroke={color}
      fontSize={fontSize}
      strokeWidth='2'
      paintOrder='stroke fill'
      strokeLinecap='butt'
      strokeLinejoin='miter'
      fill='#FFF'
      textAnchor='middle'
      fontWeight='600'
    >
      {value + '%'}
    </text>
  );
};

export const UsersWithPayments = ({info}) => {
  const { t } = useTranslation();

  const [showPrevious, setShowPrevious] = useState(false);
  
  const togglePrevious = () => setShowPrevious(prev => !prev);

  const getRecurrentPercent = ({clients_who_repeated, clients_who_payed_just_once}) => {
    return Math.round(clients_who_repeated / (clients_who_repeated + clients_who_payed_just_once) * 100) || 0;
  };

  const getNewPercent = ({clients_who_repeated, clients_who_payed_just_once}) => {
    return Math.round(clients_who_payed_just_once / (clients_who_repeated + clients_who_payed_just_once) * 100) || 0;
  };

  const getRecurrentPrevPercent = ({prev_clients_who_repeated, prev_clients_who_payed_just_once}) => {
    return Math.round(prev_clients_who_repeated / (prev_clients_who_repeated + prev_clients_who_payed_just_once) * 100) || 0;
  };

  const getNewPrevPercent = ({prev_clients_who_repeated, prev_clients_who_payed_just_once}) => {
    return Math.round(prev_clients_who_payed_just_once / (prev_clients_who_repeated + prev_clients_who_payed_just_once) * 100) || 0;
  };

  const actualValueArray = info.map((payment)=> payment.clients_who_repeated)
  const prevValueArray = info.map((payment)=> payment.prev_clients_who_repeated)

  const total_actual = actualValueArray.reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue)
  },0);
  const total_prev = prevValueArray.reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue)
  },0);

  const diff = calculatePercentageDifference(total_actual, total_prev)

  return (
    <div className='yumminn-card flex flex-col gap-3 col-span-1 self-stretch p-6'>
      <div className='flex justify-between items-center gap-2'>
        <h3 className='yumminn-card-title-large'>
          {t('users_with_payments')}
        </h3>
        <button
          onClick={togglePrevious}
          className={`flex flex-wrap w-min gap-1 justify-center items-center font-bold text-sm rounded-lg p-2 border-2 focus:outline-none ${diff === 0 ? 'bg-trueGray-200 text-trueGray-500 border-trueGray-500' : diff > 0 ? 'bg-[#5FB8943f] text-principal border-principal' : 'bg-[#E186843F] text-[#E18684] border-[#E18684]'}`}
        >
          {diff}%
          <ArrowIndicator value={diff}/>
          <span className='w-full whitespace-nowrap text-xs font-medium flex items-center'>
            {showPrevious ? t('hide_difference') : t('view_difference')}
            <ChevronRightIcon height='16' width='16' className='mr-[-4px]'/>
          </span>
        </button>
      </div>
      <ResponsiveContainer width='100%' height={220}>
        <BarChart
          width={500}
          height={300}
          data={info}
          margin={{right: 0, left: 0, top: 0, bottom: 0}}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <XAxis
            dataKey="label"
            tickSize={4}
            stroke='#A7A7A7'
            strokeWidth={1.5}
            height={25}
            tick={<XTick/>}
            padding={{left: 16, right: 16}}
            />
          <YAxis
            tickSize={4}
            stroke='#A7A7A7'
            strokeWidth={1.5}
            width={60}
            tick={<YTick/>}
            padding={{top: 16}}
          />
          <svg x={59} y={220 - 41}> {/* round corner */}
            <rect width='16' height='18' fill='#FFF'/>
            <path d='M 17 16 A 16 16 0 0 1 1 0' stroke='#A7A7A7' fill='none' strokeWidth='1.5'/>
          </svg>
          <Tooltip
            cursor={{fill: '#09455320'}}
            formatter={(value, label) => [value, t(label)]}
            contentStyle={{
              borderRadius: '6px',
              fontSize: '0.75rem',
              padding: '8px',
              border: 'none',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
              color: '#757575',
            }}
            labelStyle={{fontWeight: 600}}
          />
          {showPrevious &&
            <>
              <Bar
                maxBarSize={24}
                stroke="#09455390"
                dataKey="prev_clients_who_payed_just_once"
                stackId="previous"
                fill="url(#stripes-094553)"
                isAnimationActive={false}
              >
                <LabelList
                  valueAccessor={getNewPrevPercent}
                  content={<CustomLabel color='#09455390'/>}
                  position='bottom'
                  offset={20}
                />
                {info.map(entry => (
                  <Cell key={'cell1-' + entry.label} radius={entry.prev_clients_who_repeated ? [0, 0, 4, 4] : 4}/>
                ))}
              </Bar>
              <Bar
                maxBarSize={24}
                radius={[4, 4, 0, 0]}
                stroke="#5FB89490"
                dataKey="prev_clients_who_repeated"
                stackId="previous"
                fill="url(#stripes-5FB894)"
                isAnimationActive={false}
              >
                <LabelList
                  valueAccessor={getRecurrentPrevPercent}
                  content={<CustomLabel color='#5FB894E0'/>}
                  position='bottom'
                  offset={20}
                />
              </Bar>
            </>
          }
          <Bar
            maxBarSize={showPrevious ? 24 : 48}
            radius={[0, 0, 4, 4]}
            dataKey="clients_who_payed_just_once"
            stackId="current"
            fill="#094553"
            isAnimationActive={false}
          >
            <LabelList
              valueAccessor={getNewPercent}
              content={<CustomLabel color='#094553'/>}
              position='bottom'
              offset={20}
            />
            {info.map(entry => (
              <Cell key={'cell2-' + entry.label} radius={entry.clients_who_repeated ? [0, 0, 4, 4] : 4}/>
            ))}
          </Bar>
          <Bar
            maxBarSize={showPrevious ? 24 : 48}
            radius={[4, 4, 0, 0]}
            dataKey="clients_who_repeated"
            stackId="current"
            fill="#5FB894"
            isAnimationActive={false}
          >
            <LabelList
              valueAccessor={getRecurrentPercent}
              content={<CustomLabel color='#5FB894'/>}
              position='bottom'
              offset={20}
            />
          </Bar>
          <defs>
            <StripesPattern color='094553'/>
            <StripesPattern color='5FB894'/>
          </defs>
        </BarChart>
      </ResponsiveContainer>
      <Footer>
        <div>
          <p>
            <svg viewBox='0 0 32 32'>
              <circle cx='16' cy='16' r='16' fill='#094553'/>
            </svg>
            <span>{t('clients_who_payed_just_once')}</span>
          </p>
          <p>
            <svg viewBox='0 0 32 32'>
              <circle cx='16' cy='16' r='16' fill='#5FB894'/>
            </svg>
            <span>{t('clients_who_repeated')}</span>
          </p>
        </div>
        {showPrevious &&
          <div>
            <p>
              <svg viewBox='0 0 32 32'>
                <circle cx='16' cy='16' r='16' fill='url(#stripes-094553)'/>
              </svg>
              <span>{t('previous')}</span>
            </p>
            <p>
              <svg viewBox='0 0 32 32'>
                <circle cx='16' cy='16' r='16' fill='url(#stripes-5FB894)'/>
              </svg>
              <span>{t('previous')}</span>
            </p>
          </div>
        }
      </Footer>
    </div>
  );
};

const XTick = ({payload, x, y}) => (
  <text x={x} y={y + 14} fontSize='14' fontWeight='500' fill='#404040' textAnchor="middle">
    {payload.value}
  </text>
);

const YTick = ({payload, x, y}) => (
  <text x={x} y={y + 4} fontSize='14' fontWeight='500' fill='#404040' textAnchor="end">
    {toAbbrebviatedStr(payload.value)}
  </text>
);

const Footer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #F8F8F8;
  margin: 0 -24px -24px;
  padding: 8px;
  opacity: ${({isLoading}) => isLoading ? 0.5 : 1};

  p {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 4px;

    span:first-letter {
      text-transform: capitalize;
    }
  }
  
  svg {
    display: inline-block;
    height: 12px;
    width: 12px;
  }
`;