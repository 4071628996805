import React from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from "react-i18next";
import { categories_select_styles } from 'logic/defaults';

const ShiftSelect = ({shifts, selectedShift, setSelectedShift}) => {

	const { t } = useTranslation();

	const shiftsToOptions = (shifts) => {
		const shiftOptions = shifts.map(shift => {
			return {
				value: shift.id,
				label: shift.name
			};
		});
		//console.log('shiftOptions', shiftOptions);
		return shiftOptions;
	};

	const getShiftName = id => {
		for (let i = 0; i < shifts.length; i++) {
			if (shifts[i].id === id) {
				return shifts[i].name;
			}
		}
		return '';
	};

	const Option = (props) => {
		console.log('selectedShift.id', selectedShift);
		console.log('props.value', props.value);
		return (
			<div key={'shift_' + props.value}>
                <components.Option {...props}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <label>{props.label}</label>
                    <input
                    className="checkboxRestaurantSelect"
                    type="checkbox"
                    checked={props.value === selectedShift}
                    onChange={() => null()}
                    />{" "}
                    <span></span>
                    </div>
                </components.Option>
			</div>
		);
	};

	const handleChangeOption = (shiftToSelect) => {
		console.log('handleChangeOption shiftToSelect', shiftToSelect);
		setSelectedShift(shiftToSelect.value);
		/*const states_to_set = value.map(st => st.value)
		setSelectedStates(states_to_set)*/
	};

	return (
		shifts?.length > 0 && selectedShift && (<div>
			<div style={{fontSize: "16px", fontWeight: 500}}>{t('Shift')}</div>
			<Select
				isMulti={false}
				options={shiftsToOptions(shifts)}
				styles={categories_select_styles}
				className={'w-60'}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				placeholder={getShiftName(selectedShift)}
				onChange={e => handleChangeOption(e)}
				isClearable={false}
				components={{Option}}
			/>
		</div>)
	);
};
export default ShiftSelect;
