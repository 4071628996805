import { Button, BUTTON_VARIANTS } from 'common/Button';
import EmptyState from 'common/EmptyState';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import { OrderIndicator } from 'common/OrderIndicator';
import { Pagination } from 'common/Pagination';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeletePopup } from '../Modifiers/DeletePopup';
import { SubsidyRow } from './SubsidyRow';
import { Checkbox } from 'common/Input';
import { CouponPreviewPopover } from './SubsidiesPopovers';
import { SubsidiesContext } from './SubsidiesContext';

export const SubsidiesTable = () => {
  const {
    entries,
    allPreselected,
    page,
    pages,
    order,
    entryDraft,
    error,
    isLoading,
    isValidating,
    isExporting,
    addEntry,
    deleteEntry,
    toggleAllPreselected,
    exportFile,
    setPage,
    setOrder,
    closeImportResults,
  } = useContext(SubsidiesContext);

  const { t } = useTranslation();

  const [entryToDelete, setEntryToDelete] = useState(null);

  const orderBy = attr => setOrder(
    prev => {
      if (prev.replace('-', '') === attr) {
        return prev.startsWith('-') ? attr : `-${attr}`;
      }

      return attr;
    }
  );

  const deleteEntryAndClose = (entry) => {
    deleteEntry(entry.id);
    setEntryToDelete(null);
  };

  return (
    <div className='subsidies_table'>
      <table>
        <thead>
          <tr>
            <th className='col_id'>
              <button onClick={() => orderBy('id')}>
                <span>{t('id')}</span>
                <OrderIndicator order={order.includes('id') ? order : ''}/>
              </button>
            </th>
            <th className='col_email'>
              <button onClick={() => orderBy('email')}>
                <span>{t('email')}</span>
                <OrderIndicator order={order.includes('email') ? order : ''}/>
              </button>
            </th>
            <th className='col_coupon'>
              <button onClick={() => orderBy('code_coupon__code')}>
                <span>{t('coupon')}</span>
                <OrderIndicator order={order.includes('code_coupon__code') ? order : ''}/>
              </button>
            </th>
            <th className='col_preselected'>
              <div>
                <button onClick={() => orderBy('preselected')}>
                  <span>{t('preselected')}</span>
                </button>
                <Checkbox
                  checked={allPreselected}
                  onChange={e => toggleAllPreselected(e.target.checked)}
                />
                <CouponPreviewPopover/>
              </div>
            </th>
            <th className='col_created'>
              <button onClick={() => orderBy('created_at')}>
                <span>{t('created_at')}</span>
                <OrderIndicator order={order.includes('created_at') ? order : ''}/>
              </button>
            </th>
            <th className='col_modified'>
              <button onClick={() => orderBy('modified_at')}>
                <span>{t('modified_at')}</span>
                <OrderIndicator order={order.includes('modified_at') ? order : ''}/>
              </button>
            </th>
            <th className='col_delete'/>
          </tr>
        </thead>
        <tbody>
          {entryDraft &&
            <SubsidyRow
              entry={entryDraft}
              setEntryToDelete={setEntryToDelete}
            />
          }
          {entries.map(entry =>
            <SubsidyRow
              key={entry.id}
              entry={entry}
              setEntryToDelete={setEntryToDelete}
            />
          )}
        </tbody>
      </table>
      {!isLoading && !entries.length && !error &&
        <EmptyState
          icon={<SquareIcon type={IconType.SUBSIDY}/>}
          text={t(`subsidies_empty_state`)}
        />
      }
      {error &&
        <EmptyState
          icon={<SquareIcon>❌</SquareIcon>}
          text={t(`subsidies_error`)}
        />
      }
      <footer>
        <Button
          label='download_list'
          rightIcon={<Icon type={IconType.DOWNLOAD}/>}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={exportFile}
          loading={isExporting}
          disabled={!entries.length}
        />
        <Pagination
          page={page}
          numOfPages={pages.num_pages}
          numOfEntries={pages.num_entries}
          setPage={setPage}
          entryName={'email'}
          disabled={isValidating}
        />
        <Button
          leftIcon={<Icon type={IconType.ADD}/>}
          label={`add_email`}
          onClick={addEntry}
          loading={isValidating}
        />
      </footer>
      <DeletePopup
        isOpen={!!entryToDelete}
        title={t(`delete_email`, {email: entryToDelete?.email})}
        message={`delete_email_message`}
        onConfirm={() => deleteEntryAndClose(entryToDelete)}
        onCancel={() => setEntryToDelete(null)}
      />
    </div>
  );
};
