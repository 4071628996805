export const getLoyaltyCents = (payment) => payment.loyalty_discount_cents + payment.zerosix_discount_cents;
export const getPromoCents = (payment) => Math.min((payment.general_discount_cents + payment.product_discount_cents + payment.bizum_promotion_cents + payment.master_c2p_promotion_cents + payment.code_coupon_cents + payment.credit_cents), payment.base_amount_cents);
export const getSubtotalCents = (payment) => payment.base_amount_cents - getLoyaltyCents(payment) - getPromoCents(payment);
export const getTotalCents = (payment) => Math.max((getSubtotalCents(payment) + payment.tip_cents) - payment.refunded_amount, 0);

export const getDate = (payment) => {
  const date = new Date(payment.date)
  return <>
    {date.toLocaleDateString('es-ES')}
    <br/>
    {date.toLocaleTimeString('es-ES')}
  </>
};