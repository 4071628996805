import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { Icon, IconType } from './Icon';

export const TabSelector = ({
  name,
  value,
  options,
  disabled = false,
  breadcrumb = true,
  color = '#094553',
  onChange,
  className = '',
  disableTab,
}) => {
  const { t } = useTranslation();
  const tabsRef = useRef(new Array(options.length));

  const valueIndex = options.indexOf(value);

  const sliderPosition = breadcrumb ? 0 : tabsRef.current[valueIndex]?.offsetLeft || 0;
  const sliderWidth = breadcrumb
    ? tabsRef.current[valueIndex]?.offsetLeft + tabsRef.current[valueIndex]?.clientWidth || 0
    : tabsRef.current[valueIndex]?.clientWidth || 0;

  return (
    <TabContainer disabled={disabled} className={className}>
      {options.map((option, index) => {
        return (
          <div key={'option-' + option}>
            <Label
              key={'option-' + option}
              ref={tab => tabsRef.current[index] = tab}
              color={color}
              disabled={disabled || disableTab?.(option)}
              isPrevious={breadcrumb && index < valueIndex}
            >
              {t(option)}
              <input
                type="radio"
                name={name}
                value={option}
                checked={value === option}
                onChange={(event) => !(disabled || disableTab?.(option)) && onChange?.(event.target.value)}
                disabled={disabled}
              />
            </Label>
            {breadcrumb && index !== (options.length - 1) &&
              <Icon type={IconType.CHEVRON_RIGHT} size={12} color={index < valueIndex ? color : '#404040'}/>
          }
          </div>
        )
      }
      )}
      <Slider color={color} style={{width: sliderWidth + 'px', left: sliderPosition + 'px'}}/>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  box-sizing: border-box;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};

  div {
    display: flex;
    align-items: center;
  }
  
  svg {
    opacity: 0.65;
  }
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  color: ${({isPrevious, color}) => isPrevious ? color : '#404040'};
  opacity: ${({disabled}) => disabled ? 0.3 : 0.6};
  padding: 12px;
  transition: 0.2s cubic-bezier(.35,.07,.22,1.04);
  z-index: 2;

  &:first-letter {
    text-transform: capitalize;
  }

  &:has(:checked) {
    color: ${({color}) => color};
    opacity: 1;
  }

  &:hover {
    color: ${({disabled, color}) => disabled ? '' : color};
    opacity: ${({disabled}) => disabled ? 0.3 : 1};
  }

  &:not(:has(:checked)) {
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;
  }
`;

const Slider = styled.div`
  position: absolute;
  bottom: -1.5px;
  border-bottom: 2px solid ${({color}) => color};
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;