import Loading from "common/Loading";
import { paymentAuth } from "logic/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { apiAuth } from "logic/api";
import { toast } from 'react-toastify';

const useRefundRedsysModal = () => {
  const { t } = useTranslation();

  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const [merchantParams, setMerchantParams] = React.useState("");
  const [signatureVersion, setSignatureVersion] = React.useState("HMAC_SHA256_V1");
  const [signature, setSignature] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const handleRefund = async(payment) => {
    const amountToBeRefunded = payment.payment_method !== 'wallet' ? (payment.base_amount_cents - payment.general_discount_cents - payment.product_discount_cents - payment.loyalty_discount_cents - payment.credit_cents - payment.master_c2p_promotion_cents - payment.code_coupon_cents - payment.refunded_amount) + payment.tip_cents : payment.credit_cents
    try {
      await apiAuth.patch(
        `/payments/${payment.id}`,
        { refunded: true,
          refunded_amount: amountToBeRefunded },
      );
    } catch (error) {
      toast.error(error.response.data);
      console.log(`error`, error);
    }
  }

  const openModal = (payment) => {
    setIsLoading(true)
    paymentAuth.post(`/refund-payment`, {paymentId: payment.id,
        restaurantId: payment.restaurantId})
      .then((result) => {
        const encodedSignature = encodeURI(result.data.ds_signature)
        setMerchantParams(result.data.ds_merchant_parameters)
        setSignature(encodedSignature)
        setSignatureVersion(result.data.ds_signature_version)
        handleRefund(payment)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
    });
      setShowRefundModal(true);
  };

  const closeModal = () => {
    setShowRefundModal(false);
  }

  const Modal = () => {
    return (
      <div>
        <input defaultChecked={showRefundModal} type="checkbox" id="delete-modal-REDSYS" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box" style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: "20px"}}>
              <b style={{fontSize: "17px"}}>¿Confirmar devolución del pago?</b>
              <p style={{textAlign: 'center'}}>Al confirmar se ejecutará la devolución del importe total del pago, esta acción será irreversible.</p>
              <div style={{display: 'flex', gap: '30px'}}>            
                <button onClick={() => closeModal()} className="btn bg-secundario border-transparent">
                  {t("cerrar")}
                </button>
                {isLoading ?
                  <Loading />
                :
                    (!merchantParams || !signature || !signatureVersion) ? 
                        <div>Error</div>
                    :
                    <form name="from" action={window.location.hostname === 'localhost' ? "https://sis-t.redsys.es:25443/sis/realizarPago" : "https://sis.redsys.es/sis/realizarPago"} method="POST">
                      <input type="hidden" name="Ds_SignatureVersion" value={signatureVersion}/>
                      <input type="hidden" name="Ds_MerchantParameters" value={merchantParams}/>
                      <input type="hidden" name="Ds_Signature" value={signature}/>	
                      <button type="submit" className=" btn bg-red-500 border-transparent hover:bg-red-700 hover:border-transparent">Confirmar</button>
                    </form>
                }
              </div>
            </div>
          </div>
      </div>
    )
  }

  return {
    Modal,
    openModal
  };
};

export default useRefundRedsysModal;