import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "react-select";
import { select_styles } from 'logic/defaults';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import SmallSpinner from 'common/SmallSpinner';

const SelectTables = ({tables, table, setTable, loadingTables}) => {
  const {t} = useTranslation();
  
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const options = [ { id: '', name: t('All')}, ...tables ]

  return (
    <div className={`font-bold ${isRowBased ? 'bg-white w-36' : ''} text-[14px] ml-10`}>
      {t('Search table')}
      {loadingTables ? <SmallSpinner /> : (
        <Select 
          menuPortalTarget={document.body}
          name='table'
          styles={select_styles}
          placeholder={t('Table')}
          options={options}
          style={{height: "30px"}}
          value={table || options[0]}
          onChange={(e) => setTable(e)}
          autoComplete="one-time-code"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      )}
    </div>
  )
}

export default SelectTables;
