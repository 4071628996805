import React from 'react'; 
import { beautifyDecimalsAmount, calculateExtraCredits } from "../../components/previews/screens/WalletCardPreview";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const ExtraCredits = styled.div`
    font-size: ${({theme}) => theme.font_xxl};
    display: flex; 
    align-items: center;
    margin: 4px;
    gap: 3px;

    i {
        white-space: nowrap; 
        font-size: ${({theme}) => theme.font_md};
    }

    b {
        font-weight: 600;
        font-size: ${({theme}) => theme.font_md};
    }

`;

const BadgeBonus = styled.div`
    font-size: ${({theme}) => theme.font_md};
    display: flex; 
    align-items: center;

    i {
        font-weight: 600;
        white-space: nowrap; 
    }

    span {
        font-weight: 600;
        background-color: #E1FFF2;
        border-radius: 18px;
        padding: 1.97px 5.91px 1.97px 5.91px;
        color: #404040;
    }
`;


const CardDetailsWrapper = styled.div`
    font-size: ${({theme}) => theme.font_md};
    display: flex; 
    align-items: center;
    position: relative;
`;

const OuterWrapper = styled.div`
    display: flex; 
    justify-content: center;
    width: 100%;
`;

const CardDetails = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;

  h4 {
    line-height: 1.3;
    font-weight: 600;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    margin: 0 0 0 5px;
  }

  span {
        font-weight: 600;
        background-color: #E1FFF2;
        border-radius: 18px;
        padding: 1.97px 5.91px 1.97px 5.91px;
        color: #404040;
    }
`;

const RechargeCard = ({ recharge }) => {

    const { t } = useTranslation();
    return(
        <OuterWrapper>
            <div style={{backgroundColor: '#F9F9F9', marginTop: "10px", borderRadius: '10px', textAlign: 'center', width: 'fit-content', padding: "5px 12px"}}>
                <CardDetailsWrapper>
                    <CardDetails>
                        <h4>{beautifyDecimalsAmount(recharge.credit__amount)}€</h4>
                        <p className='semibold'>{t('of topup')}</p>
                    </CardDetails>
                </CardDetailsWrapper>
                {recharge.credit__bonus > 0 && 
                    <ExtraCredits>
                        <b>+{calculateExtraCredits(recharge.credit__bonus, recharge.credit__amount, recharge.credit__bonus_type)}€</b> 
                        <i>{t('of extra credits')}</i>
                        <BadgeBonus>
                            {recharge.credit__bonus_type === 'percentage' && <span>+{Number(recharge.credit__bonus)}%</span>}
                        </BadgeBonus>
                    </ExtraCredits>   
                }
            </div>
        </OuterWrapper>
    )
}

export default RechargeCard;