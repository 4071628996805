import React, {useEffect} from 'react';
import { PlusIcon, SaveIcon, XIcon } from '@heroicons/react/outline';
import FormError from 'common/FormError';
import useDeleteModal from 'components/hooks/useDeleteModal';
import { Formik } from 'formik';
import { motion } from 'framer-motion';
import { apiAuth } from 'logic/api';
import { DAYS, sayError, select_styles } from 'logic/defaults';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as _ from 'lodash';

const time_format = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

const initialValues = {
  id: null,
  day: null,
  hour_from: '',
  hour_to: '',
};

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  day: Yup.object().required('Required'),
  hour_from: Yup.string()
    .required('Required')
    .matches(time_format, 'Must have correct time format'),
  hour_to: Yup.string()
    .required('Required')
    .matches(time_format, 'Must have correct time format'),
};
const validacion = Yup.object().shape({ ...obligado });

/**
 *
 * Component
 *
 */

const OpeningHours = ({ restaurant }) => {
  const { t } = useTranslation();
  const [sectores, setSectores] = React.useState([]);

  const getData = React.useCallback(async () => {
    try {
      const res_obj = await apiAuth.get(`/list/opening_hours/${restaurant}`);
      const res_data = res_obj.data;
      //console.log('res_data', res_data);
      let ordenados = _.orderBy(res_data, ['day', 'hour_from']);
      ordenados = ordenados.map((o) => ({
        ...o,
        day: DAYS.find((d) => Number(d.id) === o.day),
      }));
      setSectores(ordenados);
    } catch (error) {
      toast.error(error.toString());
    }
  }, [restaurant]);

  useEffect(() => {
    //console.log('useEffect [restaurant, getData] -> restuarant: ', restaurant);
    if (restaurant) {
      getData();
    }
  }, [restaurant]);

  /*useEffect(() => {
    console.log('useEffect [restaurant, getData] -> restuarant: ', restaurant);
    if (restaurant) {
      getData();
    }
  }, [restaurant, getData]);*/

  const onSubmit = async (values, { setSubmitting }) => {
    //
    const opening = { ...values };
    opening.day = values.day.id;
    opening.restaurant = restaurant;

    try {
      if (opening.id) {
        opening.id = Number(values.id);
        await apiAuth.get(`/update/opening_hours/${opening.id}/${opening.day}/${opening.hour_from}/${opening.hour_to}`);
      } else {
        await apiAuth.get(`/create/opening_hours/${restaurant}/${opening.day}/${opening.hour_from}/${opening.hour_to}`);
      }
      toast.success(`${t('update_ok')}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleAddSector = (e) => {
    setSectores((prev) => [
      ...prev,
      {
        day: 1,
        hour_from: '',
        hour_to: '',
      },
    ]);
  };

  const handleDeleteSector = async (sector_id) => {
    if (!sector_id) {
      getData();
      return;
    }
    try {
      await apiAuth.get(`/delete/opening_hours/${sector_id}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const sectorModal = useDeleteModal(handleDeleteSector);

  if (!sectores) return null;

  if (sectores) {
    return (
      <div className={'flex flex-col w-full'}>
        {/* * */}
        {/* formularios de sectores */}
        {/* * */}
        {sectores.map((sector, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            key={index}
            className={`mb-1 mr-1 p-3 rounded-lg ${
              sector.id ? 'bg-white' : 'bg-gray-200'
            }`}
          >
            <Formik
              id={index}
              enableReinitialize={true}
              initialValues={sector || initialValues}
              validationSchema={validacion}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} autoComplete='off'>
                  {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(updated, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}

                  <div
                    key={sector.id}
                    className='flex space-x-2 items-center justify-center'
                  >
                    <Select
                      menuPortalTarget={document.body}
                      styles={select_styles}
                      className='w-full max-w-md text-[12px]'
                      // isClearable={true}
                      // isSearchable={true}
                      name={'day'}
                      placeholder={t(`Day`)}
                      getOptionLabel={(o) => o['day']}
                      getOptionValue={(o) => o['id']}
                      options={DAYS}
                      value={values.day}
                      onChange={(a) => {
                        setFieldValue('day', a);
                      }}
                    />

                    <div className='w-full flex flex-col'>
                      <input
                        type='text'
                        placeholder={t('From')}
                        className='yumminn-input-small'
                        value={values?.hour_from || ''}
                        name='hour_from'
                        onChange={handleChange}
                      />
                      <FormError message={errors.hour_from} />
                    </div>

                    <div className='w-full flex flex-col'>
                      <input
                        type='text'
                        placeholder={t('To')}
                        className='yumminn-input-small'
                        value={values?.hour_to || ''}
                        name='hour_to'
                        onChange={handleChange}
                      />
                      <FormError message={errors.hour_to} />
                    </div>

                    <button type='button' onClick={handleSubmit}>
                      <SaveIcon className='h-4 w-4 text-principal hover:text-medio' />
                    </button>

                    <button
                      type='button'
                      onClick={() => sectorModal.openModal(values.id)}
                    >
                      <XIcon className='h-4 w-4 text-principal hover:text-medio' />
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </motion.div>
        ))}

        {/* * */}
        {/* nuevo sector */}
        {/* * */}
        <div
          className='flex items-center space-x-4 input input-bordered bg-transparent border-dashed my-0 rounded-md pt-0 py-0 h-[44px] text-[12px] cursor-pointer transition transform hover:bg-primary hover:bg-opacity-40'
          onClick={handleAddSector}
        >
          <PlusIcon className='h-4 w-4 text-principal hover:text-medio' />
          {t('Add_new_opening_hour')}
        </div>

        <sectorModal.Modal mensaje={t('Delete opening hour')} />
      </div>
    );
  }
};

export default OpeningHours;
