import createDataContext from './createDataContext';

const INITIAL_STATE = {
  selectedSuperGroup: null,
  selectedGroup: null,
  selectedCategory: null,
  draggingItem: null,
  draggingName: '',
  sourceList: null,
  lastHover: {
    SUPER_GROUP: null,
    CATEGORY_GROUP: null,
    CATEGORY: null
  },
};

const ACTIONS = {
  SET_STATE: 'SET_STATE',
  SET_SUPER_GROUP: 'SET_SUPER_GROUP',
  SET_GROUP: 'SET_GROUP',
  SET_LAST_HOVER: 'SET_LAST_HOVER',
};

const actions = {
  setSelectedSuperGroup: (dispatch) => {
    return (selectedSuperGroup) => {
      if (selectedSuperGroup && !selectedSuperGroup.category_groups) selectedSuperGroup.category_groups = [];
      dispatch({ type: ACTIONS.SET_SUPER_GROUP, payload: { selectedSuperGroup } });
    };
  },
  setSelectedGroup: (dispatch) => {
    return (selectedGroup) => {
      if (selectedGroup && !selectedGroup.product_categories) selectedGroup.product_categories = [];
      dispatch({ type: ACTIONS.SET_GROUP, payload: { selectedGroup } });
    };
  },
  setSelectedCategory: (dispatch) => {
    return (selectedCategory) => {
      if (selectedCategory && !selectedCategory.products) selectedCategory.products = [];
      dispatch({ type: ACTIONS.SET_STATE, payload: { selectedCategory } });
    };
  },
  setDraggingItem: (dispatch) => {
    return (draggingItem) => {
      dispatch({ type: ACTIONS.SET_STATE, payload: { draggingItem } });
    };
  },
  setDraggingName: (dispatch) => {
    return (draggingName) => {
      dispatch({ type: ACTIONS.SET_STATE, payload: { draggingName } });
    };
  },
  setSourceList: (dispatch) => {
    return (sourceList) => {
      dispatch({ type: ACTIONS.SET_STATE, payload: { sourceList } });
    };
  },
  setLastHover: (dispatch) => {
    return (lastHover) => {
      dispatch({ type: ACTIONS.SET_LAST_HOVER, payload: { lastHover } });
    };
  },
};

const reducer = (state, action) => {
  const actionHandlers = {
    [ACTIONS.SET_STATE]: () => ({ ...state, ...action.payload }),
    [ACTIONS.SET_SUPER_GROUP]: () => {
      const { selectedSuperGroup } = action.payload;
      const selectedGroup = selectedSuperGroup?.category_groups ? selectedSuperGroup.category_groups[0] : null;
      const selectedCategory = selectedGroup?.product_categories ? selectedGroup.product_categories[0] : null;

      return { ...state, selectedSuperGroup, selectedGroup, selectedCategory };
    },
    [ACTIONS.SET_GROUP]: () => {
      const { selectedGroup } = action.payload;
      const selectedCategory = selectedGroup?.product_categories ? selectedGroup.product_categories[0] : null;

      return { ...state, selectedGroup, selectedCategory };
    },
    [ACTIONS.SET_LAST_HOVER]: () => {
      return {
        ...state,
        lastHover: {
          ...state.lastHover,
          ...action.payload.lastHover,
        }
      }
    },
  };

  return actionHandlers[action.type]?.() || state;
};

export const { Context: CatalogDNDContext, Provider: CatalogDNDProvider } = createDataContext(reducer, actions, INITIAL_STATE);