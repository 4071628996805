import React from 'react';
import { Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import ProtectedRoute from 'common/ProtectedRoute';
import HomeContainer from './YumminnPaymentsContainer';

import YumminnPaymentsScreen from './pages/YumminnPaymentsScreen';

const YumminnPaymentsRoutes = () => (
  <>
    <Router>
      <Switch>
        {/* home */}
        <ProtectedRoute
          exact
          path='/yumminn-payments'
          component={YumminnPaymentsScreen}
          layout={HomeContainer}
        />
        {/* comodin */}
      </Switch>
    </Router>
  </>
);

export default YumminnPaymentsRoutes;
