import NavigationComponent from 'components/NavigationComponent'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const TutorialLayout = ({ children }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <Container>
      <aside>
        <NavigationComponent open={open} setOpen={setOpen} />
      </aside>
      <main>
        <header>
          <h1>{t('tutorials')}</h1>
        </header>
        <div>
          {children}
        </div>
      </main>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  background-color: #F4F4F5;
  width: 100%;

  & > aside {
    z-index: 10;
    height: 100%;
  }

  & > main {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: #094553;
      background-color: #E2FEF1;
      width: 100%;
      padding: 1.25rem 2rem;

      h1 {
        font-size: 1.5rem;
        font-weight: 700;
        white-space: nowrap;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    & > div {
      width: 100%;
      flex: 1;
    }
  }
`;
