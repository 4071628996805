import { Button, BUTTON_SIZES } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { Popup } from 'common/Popup';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ImportResultsModal = ({importResults, numEntries, type, close}) => {
  const { t } = useTranslation();
  
  if (!importResults) return <></>;

  return (
    <ResultsModal isOpen={!!importResults} close={close}>
      <Icon type={IconType.VERIFIED}/>
      <h3>
        <Trans
          i18nKey='import_results_title'
          defaults='<b>¡Genial!</b> Se ha subido tu archivo, con los siguientes cambios:'
          components={{ b: <b/>}}
        />
      </h3>
      <ul>
        <li>
          <strong>{importResults.new}</strong>
          <span>{t(type, {count:importResults.new})} {t('added', {count:importResults.new})}</span>
        </li>
        <li>
          <strong>{importResults.update}</strong>
          <span>{t(type, {count:importResults.update})} {t('updated', {count:importResults.update})}</span>
        </li>
        <li>
          <strong>{importResults.delete}</strong>
          <span>{t(type, {count:importResults.delete})} {t('deleted', {count:importResults.delete})}</span>
        </li>
      </ul>
      <p>
        <Trans
          i18nKey='import_results_message'
          count={numEntries}
          values={{type: t(type, {count: numEntries})}}
          defaults='En total, tu lista ahora tiene <b>{{count}} {{type}}</b>.'
          components={{ b: <b/>}}
        />
      </p>
      <Button
        label={'understood'}
        size={BUTTON_SIZES.LG}
        onClick={close}
      />
    </ResultsModal>
  );
};

const ResultsModal = styled(Popup)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  svg {
    height: 4rem;
    width: 4rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    max-width: 26ch;
    text-align: center;
    text-wrap: balance;
  }

  ul {
    display: flex;
    gap: 16px;
  }
  
  li {
    display: flex;
    flex-direction: column;
    background-color: #F9F9F9;
    border-radius: 10px;
    padding: 16px;
  }

  strong {
    font-size: 1.5rem;
    font-weight: 600;
  }

  span {
    font-size: 1rem;
  }

  p {
    font-size: 1.125rem;
  }

  b {
    font-weight: 600;
  }
`;