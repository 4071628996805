/**
 *
 * Utils to return account holder payloads
 *
 */

const acountHolderSlug = (name, id) => {
  // convert name to slug for account code
  const regex = /\b \b/gi;
  const slug = name.toLowerCase().replace(regex, '-');
  return `${slug}-${id}`;
};

export const buildBussinessPayload = (restaurant) => {
  // check is valid
  if (restaurant.id === '') return null;
  if (restaurant.name === '') return null;
  if (restaurant.email === '') return null;
  if (restaurant.phone === '') return null;
  if (restaurant.street_number === '') return null;
  if (restaurant.zip_code === '') return null;
  if (restaurant.address === '') return null;
  if (restaurant.city === '') return null;
  if (restaurant.company_name === '') return null;
  if (restaurant.company_cif === '') return null;
  if (restaurant.company_iban === '') return null;
  if (restaurant.account_owner_name === '') return null;
  if (restaurant.link === '') return null;
  //
  return {
    accountHolderCode: acountHolderSlug(restaurant.company_name, restaurant.id),
    accountHolderDetails: {
      email: restaurant.email,
      fullPhoneNumber: `+34${restaurant.phone}`,
      address: {
        country: 'ES',
        houseNumberOrName: restaurant.street_number,
        postalCode: restaurant.zip_code,
        street: restaurant.address,
        city: restaurant.city,
      },
      businessDetails: {
        doingBusinessAs: restaurant.name,
        legalBusinessName: restaurant.company_name,
        registrationNumber: restaurant.company_cif,
        taxId: restaurant.company_cif
      },
      bankAccountDetails: [
        {
          iban: restaurant.company_iban,
          countryCode: 'ES',
          currencyCode: 'EUR',
          ownerName: restaurant.account_owner_name,
          ownerCountryCode: "ES",
          ownerHouseNumberOrName: restaurant.street_number,
          ownerPostalCode: restaurant.zip_code,
          ownerStreet: restaurant.address,
          ownerCity: restaurant.city
        },
      ],
      webAddress: restaurant.link,
    },
    legalEntity: 'Business',
  };
};

export const buildStorePayload = (restaurant) => {
  // check is valid
  if (restaurant.id === '') return null;
  if (restaurant.name === '') return null;
  if (restaurant.address === '') return null;
  if (restaurant.street_number === '') return null;
  if (restaurant.zip_code === '') return null;
  if (restaurant.city === '') return null;
  if (restaurant.phone === '') return null;
  if (restaurant.adyen_account_holder_code === '') return null;
  if (restaurant.link === '') return null;
  //
  return {
    id: restaurant.id,
    accountHolderCode: restaurant.adyen_account_holder_code,
    accountHolderDetails: {
      storeDetails: [
        {
          fullPhoneNumber:  `+34${restaurant.phone}`,
          merchantAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
          merchantCategoryCode: "7999",
          storeName:  restaurant.name.substring(0, 22),
          storeReference: restaurant.adyen_account_holder_code.substring(0, 22),
          webAddress: restaurant.link.split('//')[1],
          shopperInteraction: "Ecommerce",
          address: {
            city: restaurant.city,
            country: "ES",
            houseNumberOrName: restaurant.street_number,
            postalCode: restaurant.zip_code,
            street: restaurant.address
          }
        }
      ]
    }
  };
};

export const buildIndividualPayload = (restaurant) => {
  // check is valid
  if (restaurant.id === '') return null;
  if (restaurant.name === '') return null;
  if (restaurant.email === '') return null;
  if (restaurant.phone === '') return null;
  if (restaurant.street_number === '') return null;
  if (restaurant.zip_code === '') return null;
  if (restaurant.address === '') return null;
  if (restaurant.city === '') return null;
  if (restaurant.owner_first_name === '') return null;
  if (restaurant.owner_last_name === '') return null;
  if (restaurant.owner_birthdate === '') return null;
  if (restaurant.company_iban === '') return null;
  if (restaurant.account_owner_name === '') return null;
  //
  return {
    accountHolderCode: acountHolderSlug(restaurant.owner_first_name + " " + restaurant.owner_last_name, restaurant.id),
    accountHolderDetails: {
      email: restaurant.email,
      fullPhoneNumber: `+34${restaurant.phone}`,
      address: {
        country: 'ES',
        houseNumberOrName: restaurant.street_number,
        postalCode: restaurant.zip_code,
        street: restaurant.address,
        city: restaurant.city,
      },
      individualDetails: {
        name: {
          firstName: restaurant.owner_first_name,
          lastName: restaurant.owner_last_name,
        },
        personalData: {
          dateOfBirth: restaurant.owner_birthdate,
          documentData: {
            type: 'ID',
          },
        },
      },
      bankAccountDetails: [
        {
          iban: restaurant.company_iban,
          countryCode: 'ES',
          currencyCode: 'EUR',
          ownerName: restaurant.account_owner_name,
        },
      ],
    },
    legalEntity: 'Individual',
  };
};

export const getTranslationLanguage = () => {
  let lang = localStorage.getItem('i18nextLng') || navigator?.language || 'es-ES';
  
  switch (lang.split('-')[0].toLowerCase()) {
    case 'en':
      lang = 'english';
    case 'fr':
      lang = 'french';
    case 'ca':
      lang = 'catalan';
    case 'de':
      lang = 'german';
    case 'it':
      lang = 'italian';
    case 'pt':
      lang = 'portuguese';
    default:
      lang = 'spanish';
  };

  return lang;
}