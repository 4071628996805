import { Popup } from 'common/Popup';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveSwitch } from '../OrderAndPay/ActiveSwitch';
import { LockersContext } from 'data/LockersContext';
import { Checkbox, TextInput } from 'common/Input';
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';
import { TrashIcon } from '@heroicons/react/outline';
import { InfoTooltip } from 'common/InfoTooltip';
import { ConfirmPopup } from './ConfirmPopup';
import { Context } from 'data/authContext';
import Select from 'react-select';
import { locker_key_select_styles } from 'logic/defaults';
import { PopupForm, PopupFooter, PopupHeader } from './LockerContainers';

const INITIAL_DRAFT = {
  name: '',
  counter: false,
  active: true,
  key: '',
};

export const EditLockerPopup = ({locker, close}) => {
	const { state: { selectedRestaurantId } } = useContext(Context);
  const { state: { lockers }, editLocker, deleteLocker } = useContext(LockersContext);
  const { t } = useTranslation();

  const isNewLocker = !locker.id;
  const hasOrders = locker.conuter ? !!locker.order_list.length : !!locker.order;
  const hasCounter = lockers.some(locker => locker.counter);

  const keyOptions = [
    { label: t('with_key'), value: true },
    { label: t('without_key'), value: false },
  ];

  const [lockerDraft, setLockerDraft] = useState({...INITIAL_DRAFT, ...locker});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPopup, setConfirmePopup] = useState(null);
  const [hasKey, setHasKey] = useState(!lockerDraft.counter);

  const editDraft = partialDraft => setLockerDraft(prev => ({...prev, ...partialDraft}));

  const confirmCancel = () => hasChanges(lockerDraft) ? setConfirmePopup('cancel') : close();

  const confirmDelete = () => setConfirmePopup('delete');

  const closeConfirm = () => setConfirmePopup(null);

  const hasChanges = (draft) => {
    const previous = isNewLocker ? INITIAL_DRAFT : locker;

    return !['name', 'counter', 'key'].every(key => draft[key] === previous[key]);
  };

  const getDefaultName = (draft) => {
    if (draft.counter) return t('counter');

    const filteredLockers = lockers.filter(lock => !lock.counter);
    const lockerNumber = isNewLocker
      ? filteredLockers.length
      : filteredLockers.findIndex(lock => lock.id === locker.id);

    return `${t('locker')} ${lockerNumber + 1}`;
  };

  const selectKey = ({value}) => setHasKey(value);

  const toggleActive = async active => {
    if (isNewLocker) {
      editDraft({active});
      return;
    };

    setIsLoading(true);

    try {
      const res = await baseAuth.patch(`/locker/toggle_active_locker/${locker.id}`);

      editLocker(res.data);
      editDraft(res.data);
    } catch (error) {
      toast.error(t('edit_locker_error'));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveLocker = async () => {
    if (!isNewLocker && !hasChanges(lockerDraft) || hasOrders) return;

    setIsLoading(true);

    if (!lockerDraft.name) lockerDraft.name = getDefaultName(lockerDraft);
    if (!hasKey) lockerDraft.key = '';

    const endpoint = isNewLocker ? 'create_locker' : 'edit_locker';
    const method = isNewLocker ? 'post' : 'patch';

    try {
      const body = { ...lockerDraft, restaurant: selectedRestaurantId };
      const res = await baseAuth[method](`/locker/${endpoint}`, body);
      editLocker(res.data);
      close();
    } catch (error) {
      toast.error(t(`${endpoint}_error`));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeLocker = async () => {
    setIsLoading(true);

    try {
      await baseAuth.delete(`/locker/delete_locker/${locker.id}`);
      deleteLocker(locker.id);
      close();
    } catch (error) {
      toast.error(t(`delete_locker_error`));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = event => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'counter' && value) {
      setHasKey(false);
    }

    editDraft({[event.target.name]: value});
  };

  return (
    <Popup isOpen close={confirmCancel}>
      <PopupHeader>
        <h2>{isNewLocker ? t('create_locker') : t('edit_locker')}</h2>
        <ActiveSwitch
          checked={lockerDraft.active}
          loading={isLoading}
          disabled={hasOrders}
          disabledInfo={t('info_locker_active_disabled')}
          onChange={toggleActive}
        />
      </PopupHeader>
      <PopupForm>
        <div className='row'>
          <TextInput
            name='name'
            label={t('name')}
            value={lockerDraft.name}
            placeholder={getDefaultName(lockerDraft)}
            onChange={onChange}
          />
          {!hasCounter &&
            <div className='counter'>
              <Checkbox
                name='counter'
                label={t('counter')}
                checked={lockerDraft.counter}
                onChange={onChange}
              />
              <InfoTooltip info={t('info_locker_counter')}/>
            </div>
          }
        </div>
        <div>
          <h6>{t('key')}</h6>
          <div className='row'>
            <Select
              name={'key_select'}
              placeholder={t(`select`)}
              options={keyOptions}
              value={keyOptions.find(option => option.value === hasKey)}
              styles={locker_key_select_styles}
              onChange={selectKey}
              isDisabled={lockerDraft.counter}
              hideSelectedOptions
            />
            <TextInput
              name='key'
              //label={t('key')}
              value={lockerDraft.key}
              disabled={lockerDraft.counter || !hasKey}
              placeholder={t('key')}
              onChange={onChange}
              className='key'
            />
          </div>
        </div>
      </PopupForm>
      <PopupFooter>
        {!isNewLocker &&
          <button
            onClick={confirmDelete}
            disabled={hasOrders || isLoading}
            className='deleteButton'
          >
            <TrashIcon height={20}/>
          </button>
        }
        <button
          onClick={confirmCancel}
          disabled={isLoading}
          className='cancelButton'
        >
          {t('cancel')}
        </button>
        <button
          onClick={saveLocker}
          disabled={(!isNewLocker && !hasChanges(lockerDraft)) || (hasKey && !lockerDraft.key)}
        >
          {t('save')}
        </button>
      </PopupFooter>
      <ConfirmPopup
        isOpen={confirmPopup === 'cancel'}
        title='confirm_cancel_locker_title'
        message='changes_will_be_lost'
        confirm={close}
        cancel={closeConfirm}
      />
      <ConfirmPopup
        isOpen={confirmPopup === 'delete'}
        title='confirm_delete_locker_title'
        message='this_cannot_be_undone'
        confirm={removeLocker}
        cancel={closeConfirm}
      />
    </Popup>
  );
};