import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import React from 'react';
import { useModifiers } from './useModifiers';
import { ModifiersContainer } from './ModifiersContainers';
import { ModifiersTable } from './ModifiersTable';
import { ModifierCategories } from './ModifierCategories';
import { useTranslation } from 'react-i18next';
import { SquareIcon } from 'common/Icon';

const Modifiers = () => {
  const {
    modifierCategories,
    modifiers,
    tpv,
    selectedCategory,
    setSelectedCategory,
    mutateCat,
    mutateMod,
    selectedRestaurantId,
  } = useModifiers();

  const { t } = useTranslation();

  return (
    <ModifiersContainer>
      <MenuHeader title={'modifiers'} route_group={'menu'}/>
      <header>
        <RestaurantSelect filter={'show_order_and_pay_tab_on_backoffice'}/>
      </header>
      <div>
        <ModifierCategories
          modifierCategories={modifierCategories}
          selectedRestaurantId={selectedRestaurantId}
          selectedCategory={selectedCategory}
          modifiers={modifiers}
          tpv={tpv}
          setSelectedCategory={setSelectedCategory}
          mutateCat={mutateCat}
        />
        <div className='modifiers_wrapper'>
          {selectedCategory &&
            <h2>
              {t('group') + ': '}
              <b>“{selectedCategory.visible_name || selectedCategory.name || selectedCategory.tpv_name}”</b>
            </h2>
          }
          {modifierCategories?.length > 0 &&
            <ModifiersTable
              modifiers={modifiers}
              selectedCategory={selectedCategory}
              tpv={tpv}
              mutateMod={mutateMod}
            />
          }
          {modifierCategories?.length <= 0 &&
            <section className='empty_state'>
              <SquareIcon size={48}>🎛</SquareIcon>
              <p>
                {t('modifier_category_empty_state')}
              </p>
            </section>
          }
        </div>
      </div>
    </ModifiersContainer>
  );
};

export default Modifiers;