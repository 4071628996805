import { awareDatetimeString, centsToAmountString } from 'logic/functions';
import React from 'react';
import { useTranslation } from "react-i18next";
import modifierArrow from '../../../../../img/ModifierArrow.svg';

const OrderModifierRow = ({modifier, modifier_modifiers, is_modifier_modifier, font_size="14px", smaller_font_size="13px", show_prices=true, modifier_font_weight=400}) => {
  return (
    <div>
        <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', margin: is_modifier_modifier ? "5px 0px 0px 40px" : "10px 0px 0px 20px"}}>
        <div style={{display: "flex", gap: "10px", fontSize: is_modifier_modifier ? smaller_font_size : font_size, fontStyle: is_modifier_modifier ? "italic": "normal", height: "100%", fontWeight: 400,  alignItems: 'center'}}>
          <img src={modifierArrow}/>
          <div style={{fontWeight: modifier_font_weight}}> 
            {modifier.name}
          </div>
        </div>
        {show_prices &&
        <div style={{fontSize: is_modifier_modifier ? smaller_font_size : font_size, fontStyle: is_modifier_modifier ? "italic": "normal", fontWeight: 500}}>{(modifier.unit_price_cents >= 0 ? "+" : "-") + centsToAmountString(modifier.unit_price_cents * modifier.quantity) + "€"}</div>
        }
      </div>
      {modifier_modifiers?.map((modifier_modifier, index) =>
        <OrderModifierRow key={'modifier_modifier_' + index} modifier={modifier_modifier} modifier_modifiers={[]} is_modifier_modifier={true} />
      )} 
    </div>
  );
};
export default OrderModifierRow;
