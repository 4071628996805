import { Icon, IconType } from 'common/Icon';
import { ColumnContainer, RowContainer } from '../ExampleContainers';
import { TextInput, NumberInput, SelectInput, TextArea, DateInput, Checkbox, SearchInput } from 'common/Input';
import React from 'react';
import styled from 'styled-components';
import { InfoTooltip } from 'common/InfoTooltip';

const mockOptions = [
  {label: 'one', value: 1},
  {label: 'two', value: 2, color: '#8F0E19'},
  {label: 'three', value: 3, color: '#e2d564'}
];

const CustomLabel = () => (
  <h6 style={{display: 'flex', alignItems: 'center', gap: '0.25em', color: '#691BC2'}}>
    <Icon type={IconType.AI} size={20}/>
    <i>Custom Label</i>
    <Icon type={IconType.AI} size={20}/>
    <InfoTooltip info='example with info'/>
  </h6>
);

const mockAsyncSuggestions = async () => {
  await new Promise(resolve => setTimeout(resolve, 2000));

  return mockOptions;
};

export const InputExample = () => {
  return (
    <ColumnContainer>
      <InputRow>
        <InputColumn>
          <h2>TextInput</h2>
          <TextInput label='Label' value='value'/>
          <TextInput label={<CustomLabel/>} value='value'/>
          <TextInput value='' placeholder='placeholder'/>
          <TextInput value='error' error='error message'/>
          <TextInput value='disabled' disabled/>
          <TextInput value='password' password/>
          <TextInput value='www.webapp.yumminn.com' copy/>
        </InputColumn>
        <InputColumn>
          <h2>NumberInput</h2>
          <NumberInput label='Label' value='0.5'/>
          <NumberInput label={<CustomLabel/>} value='0.5'/>
          <NumberInput value='' placeholder='placeholder'/>
          <NumberInput value='0' error='error message'/>
          <NumberInput value='0' disabled/>
          <NumberInput value='10' unit='€'/>
        </InputColumn>
        <InputColumn>
          <h2>SelectInput</h2>
          <SelectInput options={mockOptions} value={mockOptions[0]} label='Label'/>
          <SelectInput options={mockOptions} value={mockOptions[0]} label={<CustomLabel/>}/>
          <SelectInput options={mockOptions} value={mockOptions} multiple isMulti/>
          <SelectInput options={mockOptions} placeholder='placeholder' value=''/>
          <SelectInput options={mockOptions} value={mockOptions[0]} error='error message'/>
        </InputColumn>
        <InputColumn>
          <h2>TextArea</h2>
          <TextArea label='Label' value='value'/>
          <TextArea label={<CustomLabel/>} value='value'/>
          <TextArea value='' placeholder='placeholder (2 rows)' rows={2}/>
          <TextArea value='error' error='error message'/>
        </InputColumn>
        <InputColumn>
          <h2>DateRangeInput</h2>
          <DateInput label='Label' placeholder='placeholder'/>
          <DateInput label={<CustomLabel/>} placeholder='placeholder'/>
        </InputColumn>
        <InputColumn>
          <h2>SearchInput</h2>
          <SearchInput label='label' placeholder='placeholder' getSuggestions={mockAsyncSuggestions}/>
        </InputColumn>
      </InputRow>
      <InputRow>
        <InputColumn>
          <h2>Checkbox</h2>
          <Checkbox label='Label' checked/>
          <Checkbox label={<CustomLabel/>}/>
          <Checkbox label='Label' checked disabled/>
          <Checkbox label='Label' disabled/>
          <Checkbox/>
        </InputColumn>
      </InputRow>
    </ColumnContainer>
  );
};

const InputRow = styled(RowContainer)`
  gap: 4rem;
  flex-wrap: wrap;
`;

const InputColumn = styled(ColumnContainer)`
  width: 20rem;
`;