import React from 'react';
import { useTranslation } from "react-i18next";
import { awareDatetimeString, centsToAmountString } from 'logic/functions';

const BookingRow = ({booking, selected}) => {
  const { t } = useTranslation();
  const booking_date = new Date(booking.date)
  booking_date.setHours(booking.hour);
  booking_date.setMinutes(booking.minute)
  const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
  return (
    <div style={{border: selected ? booking.canceled ? "2px solid #C44A4A" : "2px solid #5FB894" : "2px solid #FCFCFC", display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr", textAlign: 'center', width: "100%", backgroundColor: booking.canceled ? "#FFDDDD" : selected ? "#EEF9F5" : "#FCFCFC", justifyContent: 'space-around', fontSize: "16px", fontWeight: 500, padding: "10px 0px", marginTop: "10px", alignItems: 'center', borderRadius: '15px'}}>
		<div><span style={{textDecoration: booking.canceled ? "line-through" : "none", backgroundColor: selected ? "#094553" : "#EEF9F5", color: selected ? "#FFFFFF" : "#404040", border: selected ? "none" : "1px solid #AFDCCA", padding: '5px 10px', borderRadius: "99999px", }}>{booking.code}</span></div>
		<div style={{textDecoration: booking.canceled ? "line-through" : "none", fontWeight: 600}}>{booking_date.toLocaleDateString('es-ES', options)}</div>
    <div style={{textDecoration: booking.canceled ? "line-through" : "none"}}>{booking.name ? booking.name : '-'}</div>
    <div style={{textDecoration: booking.canceled ? "line-through" : "none"}}>{booking.diners}</div>
	</div>
  );
};
export default BookingRow;
