import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import WaiterRow from "./WaiterRow"
import {ReactComponent as SortArrow} from "../../../../img/sortArrow.svg";
import RoundPagination from "common/RoundPagination";

const rowStyle = {
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1fr 1fr .7fr .7fr 1fr 1fr .7fr 1fr 1fr .7fr 1fr',
	width: '100%',
	backgroundColor: "#F8F8F8",
	marginTop: "10px"
};
const thStyle = {
	fontSize: "15px", 
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: "10px 0px",
	cursor: 'pointer',
	gap: "10px"
};
const headers = [
	"Camarero",
	"Volumen de\npagos",
	"# pagos",
	"# reseñas",
	"% pagos\ncon reseñas",
	"Puntuación",
	"Pago\nmedio",
	"% pagos\ncon propina",
	"% medio de\npropina",
	"Propina\nmedia",
	"Total\npropinas"
];

export const WaitersTable = ({paymentsData, reviewsData, waitersData}) => {

	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [orderBy, setOrderBy] = useState(5);
	const [asc, setAsc] = useState(false);
	const pageSize = 15;
	const [allWaitersData, setAllWaitersData] = useState(waitersData);
	const [waitersToShow, setWaitersToShow] = useState(JSON.parse(JSON.stringify(allWaitersData))?.slice(pageSize * (page - 1), pageSize * page));
	let hasNextPage = JSON.parse(JSON.stringify(allWaitersData))?.slice(pageSize * (page), pageSize * (page + 1)).length > 0;
	let indent = 0;

	const changeSort = (index) => {
		const isAsc = index == orderBy ? !asc : asc;
		sortByIndex(index, isAsc);
	};
	const defaultSort = (objToSort, data, value, attr) => {
		return objToSort.sort((a,b) => (data.filter(p_d => p_d[attr] ==  a.id).length > 0 ? data.filter(p_d => p_d[attr] == a.id)[0][value] : 0) - (data.filter(p_d => p_d[attr] == b.id).length > 0 ? data.filter(p_d => p_d[attr] == b.id)[0][value] : 0));
	};

	useEffect(() => {
		setWaitersToShow(JSON.parse(JSON.stringify(allWaitersData))?.slice(pageSize * (page - 1), pageSize * page));
	}, [page]);

	useEffect(() => {
		setAllWaitersData(waitersData);
		setWaitersToShow(JSON.parse(JSON.stringify(waitersData))?.slice(pageSize * (page - 1), pageSize * page));
	}, [waitersData]);

	const sortByIndex = (index, isAsc) => {
		let objToSort = page > 1 ? waitersToShow : allWaitersData
		if (index == 1) {
			defaultSort(objToSort, paymentsData, "base_amount_total_cents", "waiter")
		} else if (index == 2) {
			defaultSort(objToSort, paymentsData, "num_payments", "waiter")
		} else if (index == 3) {
			defaultSort(objToSort, reviewsData, "num_reviews", "waiter_id")
		} else if (index == 4) {
			objToSort.sort((a,b) => ((paymentsData.filter(p_d => p_d["waiter"] ==  a.id).length > 0 && reviewsData.filter(r_d => r_d["waiter_id"] ==  a.id).length > 0 && paymentsData.filter(p_d => p_d["waiter"] ==  a.id)[0]["num_payments"]) ? (reviewsData.filter(r_d => r_d["waiter_id"] == a.id)[0]["num_reviews"] / paymentsData.filter(p_d => p_d["waiter"] ==  a.id)[0]["num_payments"]) : 0) - ((paymentsData.filter(p_d => p_d["waiter"] ==  b.id).length > 0 && reviewsData.filter(r_d => r_d["waiter_id"] ==  b.id).length > 0 && paymentsData.filter(p_d => p_d["waiter"] ==  b.id)[0]["num_payments"]) ? (reviewsData.filter(r_d => r_d["waiter_id"] ==  b.id)[0]["num_reviews"] / paymentsData.filter(p_d => p_d["waiter"] ==  b.id)[0]["num_payments"]) : 0));
		} else if (index == 5) {
			objToSort.sort((a,b) => ((reviewsData.filter(r_d => r_d["waiter_id"] == a.id).length > 0 && reviewsData.filter(r_d => r_d["waiter_id"] ==  a.id)[0]["num_reviews"]) ? (reviewsData.filter(r_d => r_d["waiter_id"] ==  a.id)[0]["total_score"]/reviewsData.filter(r_d => r_d["waiter_id"] ==  a.id)[0]["num_reviews"]) : 0) - ((reviewsData.filter(r_d => r_d["waiter_id"] ==  b.id).length > 0 && reviewsData.filter(r_d => r_d["waiter_id"] ==  b.id)[0]["num_reviews"]) ? (reviewsData.filter(r_d => r_d["waiter_id"] ==  b.id)[0]["total_score"]/reviewsData.filter(r_d => r_d["waiter_id"] ==  b.id)[0]["num_reviews"]) : 0))
		} else if (index == 6) {
			objToSort.sort((a,b) => ((paymentsData.filter(r_d => r_d["waiter"] ==  a.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_payments"]) ? (paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["base_amount_total_cents"]/paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_payments"]) : 0) - ((paymentsData.filter(r_d => r_d["waiter"] ==  b.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_payments"]) ? (paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["base_amount_total_cents"]/paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_payments"]) : 0))
		} else if (index == 7) {
			objToSort.sort((a,b) => ((paymentsData.filter(r_d => r_d["waiter"] ==  a.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_payments"]) ? (paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_tip_payments"]/paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_payments"]) : 0) - ((paymentsData.filter(r_d => r_d["waiter"] ==  b.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_payments"]) ? (paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_tip_payments"]/paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_payments"]) : 0))
		} else if (index == 8) {
			objToSort.sort((a,b) => ((paymentsData.filter(r_d => r_d["waiter"] ==  a.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["base_amount_total_cents"]) ? ((paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["tips_total_cents"] * 100)/paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["base_amount_total_cents"]) : 0) - ((paymentsData.filter(r_d => r_d["waiter"] ==  b.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["base_amount_total_cents"]) ? ((paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["tips_total_cents"]*100)/paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["base_amount_total_cents"]) : 0))
		} else if (index == 9) {
			objToSort.sort((a,b) => ((paymentsData.filter(r_d => r_d["waiter"] ==  a.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_tip_payments"]) ? (paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["tips_total_cents"]/paymentsData.filter(r_d => r_d["waiter"] ==  a.id)[0]["num_tip_payments"]) : 0) - ((paymentsData.filter(r_d => r_d["waiter"] ==  b.id).length > 0 && paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_tip_payments"]) ? (paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["tips_total_cents"]/paymentsData.filter(r_d => r_d["waiter"] ==  b.id)[0]["num_tip_payments"]) : 0))
		} else if (index == 10) {
			defaultSort(objToSort, paymentsData, "tips_total_cents", "waiter")
		} else if (index == 0) {
			objToSort.sort((a,b) => a.name.localeCompare(b.name))
		}
		if (page > 1) {
			setWaitersToShow(isAsc ? objToSort : objToSort.reverse())
		} else {
			const obj = isAsc ? objToSort : objToSort.reverse()
			setAllWaitersData(obj)
			setWaitersToShow(JSON.parse(JSON.stringify(obj))?.slice(pageSize * (page - 1), pageSize * page))
		}
		setOrderBy(index);
		setAsc(isAsc);
	};

	return (
		<>				
			{waitersToShow && waitersToShow.length > 0 &&
				<RoundPagination
					page={page}
					numberOfPages={waitersData ? Math.ceil(waitersData.length / pageSize) : 0}
					setPage={setPage}
					total={waitersData.length}
					elementNamePlural={t("waiters")}
					elementNameSingular={t("waiter")}
				/>
			}
			<div style={{marginBottom: '25px', width: '100%', alignSelf: 'flex-start', zIndex: 0}}>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'center', marginTop: '10px'}} className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200">
						<table className=" w-full">
							<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white">
							<tr
								style={rowStyle}>
								{headers.map((item, index) => (
									<th onClick={() => changeSort(index)} style={thStyle} className="font-bold border-b-2 border-gray-200">
										{index == orderBy &&
										<SortArrow transform={asc ? "rotate(180)" : ""} height={25}/>}
										{item.split('\n').map(function(t) {
											return (<>
												{t}
												<br/>
											</>)
										})}
									</th>
								))}
							</tr>
							</thead>
							<tbody style={{position: 'relative'}}>
								<div style={{position: 'relative', display: 'flex', gap: "10px", flexDirection: 'column', paddingTop: "10px"}}>
								{waitersToShow && waitersToShow.length > 0
									? waitersToShow.map((waiter_data, index) =>			
										<div>
											<WaiterRow								
												waiterData={waiter_data}
												paymentsData={paymentsData ? paymentsData.filter(p_d => p_d.waiter == waiter_data.id) : []}
												reviewsData={reviewsData ? reviewsData.filter(r_d => r_d.waiter_id == waiter_data.id) : []}
												indent={indent}
											/>
										</div>)
									: <div style={{width: "100%", textAlign: 'center', marginTop: "15px", fontSize: "16px", fontWeight: 700}}>No hay registros para mostrar</div>}
								</div>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

export default WaitersTable;
