import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CardWithHeader } from 'components/yumminn/CardWithHeader';
import styled from 'styled-components';
import { PeriodRow } from './PeriodRow';
import { DataCard } from '.';
import { PieChart, Pie, Cell } from 'recharts';
// import createHistory from 'history/createBrowserHistory';

// const history = createHistory({forceRefresh:true});  

export const SimplifiedUsersOverview = ({data, dates, type}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showPercent, setShowPercent] = useState(false);
  
  const togglePercent = () => setShowPercent(prev => !prev);

  const userData = [
    { name: 'New', value: data?.new },
    { name: 'Recurrent', value: data?.recurrent }
  ];

  //console.log(userData)

  /*useEffect(() => {
    console.log(data, 'SimplifiedUsersOverview:data');
  }, [data]);*/

  const noData  = [
    { name: 'New', value: 100 }
  ];

  const dataEmpty = data?.new === 0 && data?.recurrent === 0;
  const COLORS = ['#094553', '#5BB994'];
  
  return (
    <CardWithHeader
      title='registered_users'
      info='info_registered_users'
      infoOptions={dates}
      color='#B6EBD5'
      className=' '
      // handleClick={() => history.push('/admin/crm')}
    >
      <Container type={type}>
        <DataCard
          data={data?.new}
          label={'new_users'}
          delta={data?.deltaNew}
          bgColor='#094553'
          color='#E8E8E8'
          className='h-18 w-full'
        />
        <DataCard
          data={data?.recurrent}
          label={'recurrent_users'}
          delta={data?.deltaRecurrent}
          bgColor='#5BB994'
          color='#E8E8E8'
          className='h-18 w-full'
        />
        <div className='relative flex flex-col justify-center items-center'>
          <div className='absolute flex flex-col items-center text-3xl font-semibold w-full h-full text-center  justify-center'>
            {data?.new + data?.recurrent}
            <label className='font-normal text-sm text-gray-500' >{t('total')}</label>
          </div>
          <PieChart width={140} height={134} onMouseEnter={null}>

            {dataEmpty ?
              <Pie
              data={noData}
              innerRadius={50}
              outerRadius={65}
              fill="#8884d8"
              dataKey="value"
              >
                {noData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={'#E5E5E5'} />
                ))}
              </Pie>
              :
              <>
                <Pie
                  data={userData}
                  innerRadius={50}
                  outerRadius={65}
                  fill="#FFFFFF"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {userData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                {/* <Pie
                  data={userData}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={40}
                  outerRadius={48}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {userData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie> */}
              </>
            }
          </PieChart>
        </div>
      </Container>
    </CardWithHeader>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);
`;