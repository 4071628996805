import React from 'react';
import Select, { components } from 'react-select';
import { categories_select_styles } from 'logic/defaults';
import { MobileMockup, CategoriesPreview } from "components/previews";
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TextInput, SelectInput } from 'common/Input';

export const GroupSpecsPopup = ({
  group,
  groupType,
  groupToEdit,
  options,
  editGroup,
  groupChildren,
  handleSelectChildren
}) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  return (
    <div className="flex gap-4">
      <div className="flex flex-col items-center gap-4 w-80 mt-4">
        <MobileMockup>
          <CategoriesPreview groupType={groupType} name={group?.name || group?.visible_name}/>
        </MobileMockup>
        <span>{'*' + t('web_preview')}</span>
      </div>
      <div className="flex flex-col gap-8 my-10">
        <p className="max-w-[32ch]">{t(`Info_${groupType}`)}</p>
        <TextInput
          label={`name_${groupType}`}
          value={group?.visible_name || ''}
          placeholder={group?.tpv_name}
          onChange={event => editGroup({name: event.target.value})}
        />
        {options && !!options.length &&
          <div className="flex flex-col gap-3">
            <div className='relative' onMouseEnter={() => setHover(!!groupToEdit)} onMouseLeave={() => setHover(false)}>
              <SelectInput
                id='children'
                label={`select_children_${groupType}`}
                value={groupChildren}
                options={options}
                placeholder='Select one option'
                disabled={!!groupToEdit}
                onChange={handleSelectChildren}
                menuPortalTarget={document.body}
                components={{MultiValue}}
                isMulti
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                className='max-w-xl'
              />
              <i className={`top-[calc(100%+0.25rem)] left-0 max-w-[32ch] w-max text-xs shadow p-2 bg-[#FFF2D0] rounded-md z-10 transition-opacity absolute ${hover ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                <Trans i18nKey={'explanation_disabled_select_' + groupType}>
                  <b className='font-semibold'>To add or remove child categories</b> to this category, <b className='font-semibold'>delete them from the</b> <u className='font-semibold'>catalogue overview</u>.
                </Trans>
              </i>
            </div>
          </div>
        }
        {!!group?.dish_order &&
          <div className="flex flex-col gap-3">
            <label htmlFor='children'>{t('choose_dish_order')}</label>
            <div className='relative'>
              <Select
                id={'children'}
                value={group?.dish_order}
                options={[{label: t('yes'), value: 'Yes'},{label: t('no'), value: 'No'}]}
                styles={categories_select_styles}
                className={'w-80 max-w-full'}
                hideSelectedOptions={false}
                onChange={handleSelectChildren}
                components={{Option}}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          className="checkboxRestaurantSelect"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null()}
        />{" "}
        <span></span>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxVisible = 2;
  const hiddenValues = getValue().slice(maxVisible).length;

  if (index > maxVisible) return <></>;

  if (index === maxVisible) return (
    <div className='bg-[#DDD] text-[#222] font-medium rounded-full p-2 min-w-[2.25rem] min-h-[2.25rem] text-[85%] text-center ml-auto'>
      {'+' + hiddenValues}
    </div>
  )

  return (
    <components.MultiValue isClearable={false} {...props}/>
  );
};