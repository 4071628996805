import React from 'react';
import { Icon } from 'common/Icon';
import styled from 'styled-components';
import { InfoTooltip } from 'common/InfoTooltip';
import { useTranslation } from 'react-i18next';

export const TopProductCard = ({ value, iconType, name, info, isLow }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div>
        <b className={isLow ? 'low' : ''}>{value}</b>
        {iconType &&
          <span>
            <Icon type={iconType}/>
          </span>
        }
      </div>
      <div>
        <p>{t(name)}</p>
        {info &&
          <InfoTooltip>{info}</InfoTooltip>
        }
      </div>
    </Card>
  );
};

const Card = styled.div`
  background-color: #FFF;
  border: 1px solid #E2E2E2;
  border-radius: 16px;
  padding: 16px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:first-of-type {
      margin-bottom: 16px;
    }
  }

  b {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1;

    &.low {
      color: #E7443A;
    }
  }

  span {
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
    color: #094553;
    background-color: #F0F7F8;
    border-radius: 2rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .yumminn_tooltip {
    width: 12rem;
  }
`;