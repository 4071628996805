import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';


export default function TargetTraductionModelSelector() {
  const { t } = useTranslation();

  const history = useHistory();

  const path = history.location.pathname;
  
  return (
    <div className="flex gap-5 overflow-scroll-y">
      <Button
        label={t('supergroup_name')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/supergroup_name')}
        className={"self-end whitespace-nowrap"}
        variant={path === "/translations/supergroup_name" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
        />  
      <Button
        label={t('category_group_name')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/category_group_name')}
        className={"self-end"}
        variant={path === "/translations/category_group_name" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />  
      <Button
        label={t('product_category_name')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/product_category_name')}
        className={"self-end"}
        variant={path === "/translations/product_category_name" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />  
      <Button
        label={t('product_name')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/product_name')}
        className={"self-end"}
        variant={path === "/translations/product_name" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />  
      <Button
        label={t('product_description')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/product_description')}
        className={"self-end"}
        variant={path === "/translations/product_description" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />     
      <Button
        label={t('modifier_category_name')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/modifier_category_name')}
        className={"self-end"}
        variant={path === "/translations/modifier_category_name" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />     
      <Button
        label={t('modifier_name')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/modifier_name')}
        className={"self-end"}
        variant={path === "/translations/modifier_name" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />     
      <Button
        label={t('modifier_description')}
        size={BUTTON_SIZES.SM}
        onClick={() => history.push('/translations/modifier_description')}
        className={"self-end"}
        variant={path === "/translations/modifier_description" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
      />     
    </div>
  );
}