import React from 'react';
import { useTranslation } from 'react-i18next';


export const RoadmapMockup = ({theme}) => {
  const { t } = useTranslation();

  const Circle = ({children, label}) => (
    <div className='flex flex-col items-center gap-[2px] max-w-[1.75rem]'>
      <div
        className='h-7 w-7 rounded-full border-[3px] flex justify-center items-center transition-colors'
        style={{backgroundColor: theme?.tertiary_a20 || '#5FB894', borderColor: theme?.tertiary || '#5FB894'}}
      >
        {children}
      </div>
      <span className='text-[5px] text-center' style={{color: theme?.text || '#404040'}}>
        {t(label)}
      </span>
    </div>
  );

  const Dot = () => <div className='h-[3px] w-[3px] rounded-full transition-colors' style={{backgroundColor: theme?.tertiary || '#5FB894'}}/>;

  const Dots = () => (
    <div className='flex justify-evenly items-center flex-1 pb-[9px]'>
      <Dot/>
      <Dot/>
      <Dot/>
    </div>
  );

  return (
    <div className='flex justify-between items-center px-4'>
      <Circle label='menu'>
        <svg width="14" height="14" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.08176 28.8922C2.02403 28.8045 1.92282 28.8251 1.84371 28.7934C1.09464 28.497 0.659166 27.9492 0.575065 27.1174C0.561523 26.981 0.561523 26.8417 0.561523 26.7045C0.561523 20.2314 0.561523 13.7582 0.561523 7.28436C0.561523 6.09723 0.987731 5.48598 2.06964 5.1173C6.30891 3.67211 10.5482 2.22618 14.7867 0.779509C15.9335 0.388716 17.0083 0.953522 17.3012 2.10083C17.354 2.30729 17.3604 2.5189 17.3611 2.72978C17.3632 3.40077 17.3568 4.07249 17.3639 4.74347C17.3682 5.11657 17.3297 5.49114 17.3875 5.8635C17.7417 5.86939 18.0959 5.87529 18.4501 5.88193C18.654 5.88562 18.8571 5.87751 19.0602 5.92175C19.892 6.10461 20.4828 6.79181 20.5491 7.66851C20.5584 7.78723 20.5612 7.90741 20.5612 8.02686C20.5612 14.2707 20.5619 20.5138 20.5612 26.7576C20.5612 27.8046 20.0801 28.5398 19.2306 28.8119C19.15 28.8377 19.0495 28.8052 18.9875 28.8915L2.08176 28.8922Z" fill={theme.tertiary}/>
          <path d="M18.9612 17.4156C18.9612 20.5228 18.9612 23.6307 18.9612 26.7379C18.9612 27.1965 18.9213 27.2363 18.4716 27.2363C13.1932 27.2363 7.91547 27.2363 2.63705 27.2363C2.20657 27.2363 2.16309 27.1913 2.16309 26.7497C2.16309 20.5073 2.16309 14.2649 2.16309 8.02259C2.16309 7.5824 2.20799 7.53815 2.63919 7.53815C7.92616 7.53815 13.2131 7.53815 18.5001 7.53815C18.9106 7.53815 18.9612 7.58903 18.9612 8.01153C18.9612 11.1467 18.9612 14.2812 18.9612 17.4156Z" fill={theme.tertiary_lighter}/>
          <path d="M10.5483 17.1085C8.83418 17.1085 7.12008 17.1085 5.40599 17.1085C5.25489 17.1085 5.10379 17.1137 4.9584 17.0584C4.61344 16.9279 4.39178 16.5629 4.43241 16.1927C4.47446 15.8093 4.76667 15.4834 5.1544 15.4731C5.41882 15.4665 5.47298 15.3551 5.53214 15.1184C6.07024 12.9492 7.39591 11.5858 9.50485 11.0689C9.77996 11.0018 9.76428 10.8581 9.76499 10.6553C9.76642 10.0315 10.2732 9.62375 10.8113 9.80587C11.1356 9.915 11.3814 10.2284 11.3536 10.6C11.3287 10.937 11.452 11.0114 11.7521 11.094C13.8211 11.664 15.1097 13.0465 15.6157 15.1988C15.6585 15.3809 15.6955 15.4554 15.8923 15.462C16.3762 15.479 16.7069 15.844 16.6955 16.3041C16.6841 16.7657 16.3398 17.1048 15.8516 17.1063C14.0841 17.1115 12.3158 17.1085 10.5483 17.1085Z" fill={theme.tertiary}/>
          <path d="M5.01535 5.85827C7.12785 5.13641 9.17621 4.43667 11.2253 3.73693C12.5616 3.28052 13.8973 2.82336 15.2336 2.36695C15.6556 2.22316 15.7611 2.3028 15.7611 2.76954C15.7618 3.73398 15.7589 4.69917 15.7639 5.66361C15.7646 5.81403 15.7539 5.88777 15.5708 5.88703C12.0898 5.87965 8.6096 5.88113 5.12867 5.88039C5.11228 5.88039 5.09446 5.87449 5.01535 5.85827Z" fill={theme.tertiary_light}/>
          <path d="M10.5736 22.6566C12.2614 22.6566 13.9491 22.6551 15.6361 22.6573C16.1036 22.6573 16.4465 22.9604 16.4857 23.3932C16.5327 23.9101 16.177 24.309 15.6525 24.3105C14.2933 24.3141 12.9349 24.3119 11.5757 24.3119C9.55087 24.3119 7.52532 24.3134 5.50047 24.3112C4.84905 24.3105 4.44066 23.7036 4.70864 23.1329C4.869 22.7923 5.1491 22.6544 5.51117 22.6551C7.19889 22.6588 8.88661 22.6566 10.5736 22.6566Z" fill={theme.tertiary}/>
          <path d="M7.45189 21.0017C6.83966 21.0017 6.22672 21.0032 5.61449 21.0017C5.09777 20.9995 4.74354 20.6611 4.74354 20.1744C4.74354 19.6885 5.09777 19.3486 5.6152 19.3479C6.84892 19.3457 8.08336 19.3457 9.31708 19.3479C9.82026 19.3486 10.1916 19.7151 10.1816 20.1884C10.1716 20.6559 9.81028 20.9988 9.31708 21.001C8.69559 21.0039 8.07409 21.001 7.4526 21.001L7.45189 21.0017Z" fill={theme.tertiary}/>
          <path d="M7.11207 15.4252C7.40286 13.8569 8.85467 12.644 10.4868 12.6042C12.0641 12.5658 13.4859 13.6173 13.9613 15.1672C14.0226 15.367 14.0333 15.4606 13.7668 15.4592C11.6264 15.4474 9.48543 15.4525 7.34513 15.451C7.26744 15.451 7.18548 15.4739 7.11207 15.4252Z" fill={theme.tertiary_light}/>
        </svg>
      </Circle>
      <Dots/>
      <Circle label='payment'>
        <svg width="14" height="14" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.9022 33.6698C13.4682 34.119 10.0331 34.5754 6.59719 35.0152C4.76997 35.2494 3.24764 34.2844 2.5776 32.49C2.48893 32.2512 2.4392 31.9952 2.39294 31.746C1.62207 27.6057 0.853785 23.4674 0.0855051 19.3291C-0.328251 17.1003 0.785164 15.3971 2.87163 15.1147C5.90677 14.701 8.94925 14.3151 11.9874 13.9177C15.8176 13.4169 19.6469 12.9112 23.4783 12.4173C25.3186 12.1814 26.8318 13.1213 27.5122 14.912C27.6086 15.1689 27.664 15.4431 27.7137 15.711C28.4807 19.8304 29.2455 23.95 30.0082 28.07C30.4293 30.3383 29.3207 32.0314 27.2017 32.3181C23.7697 32.7789 20.3317 33.219 16.8975 33.6681L16.9022 33.6698ZM2.66761 23.1252C2.70826 23.3441 2.74025 23.5164 2.77181 23.6864C3.24955 26.2597 3.72687 28.8308 4.20461 31.4042C4.43418 32.6408 5.11441 33.1887 6.26994 33.0375C13.1218 32.1462 19.9728 31.2503 26.8229 30.3496C27.946 30.2027 28.3958 29.4974 28.1754 28.2978C27.6963 25.7056 27.213 23.1138 26.7317 20.5219C26.7006 20.3542 26.6521 20.1888 26.6029 19.9948L2.66761 23.1252ZM2.1341 20.2515L26.0975 17.1174C26.0007 16.5958 25.9367 16.108 25.8177 15.6346C25.5842 14.7109 24.8131 14.2584 23.742 14.3984C16.9623 15.2851 10.1806 16.1721 3.40099 17.0587C3.2928 17.0729 3.18373 17.0823 3.07727 17.1059C2.16537 17.3039 1.73203 18.0021 1.9041 19.0006C1.9724 19.4043 2.05412 19.8087 2.13669 20.2535L2.1341 20.2515Z" fill={theme.tertiary}/>
          <path d="M2.66761 23.1252L26.6054 19.9938C26.6525 20.188 26.7009 20.3535 26.7342 20.5209C27.2154 23.1128 27.6987 25.7046 28.1778 28.2968C28.4005 29.4962 27.9485 30.2016 26.8254 30.3486C19.9757 31.2516 13.1242 32.1452 6.27239 33.0365C5.11686 33.1877 4.43663 32.6397 4.20706 31.4031C3.72715 28.83 3.252 26.2587 2.77426 23.6854C2.74269 23.5154 2.71071 23.3431 2.67006 23.1242L2.66761 23.1252ZM8.90625 30.8196C9.53379 30.7375 10.1613 30.6555 10.7889 30.5733C11.8124 30.4395 11.7908 30.4424 11.5958 29.3325C11.5037 28.8125 11.2916 28.6972 10.8385 28.7636C9.5168 28.9627 8.18729 29.1198 6.8608 29.2933C5.8048 29.4315 5.82427 29.4289 6.03007 30.5612C6.12129 31.0526 6.32856 31.1781 6.75659 31.1079C7.4681 30.9886 8.18999 30.9133 8.90625 30.8196Z" fill={theme.tertiary_light}/>
          <path d="M2.13504 20.2535C2.05245 19.8087 1.9729 19.404 1.90243 19.0006C1.73036 18.0021 2.16414 17.3062 3.0756 17.1059C3.18207 17.0823 3.29113 17.0729 3.39932 17.0587C10.1789 16.1721 16.9607 15.2852 23.7403 14.3984C24.8114 14.2584 25.5826 14.7109 25.816 15.6346C25.9354 16.1103 25.999 16.5958 26.0958 17.1174L2.1341 20.2515L2.13504 20.2535Z" fill={theme.tertiary_light}/>
          <path d="M8.90662 30.821C8.19036 30.9146 7.46847 30.9899 6.75696 31.1092C6.32893 31.1795 6.11949 31.0543 6.03044 30.5625C5.82464 29.4302 5.80517 29.4327 6.86117 29.2947C8.18766 29.1212 9.51717 28.9639 10.8389 28.7649C11.2938 28.6958 11.5058 28.8113 11.5962 29.3337C11.7911 30.4436 11.8128 30.4408 10.7892 30.5747C10.1617 30.6568 9.53416 30.7388 8.90662 30.821Z" fill={theme.tertiary}/>
          <path d="M14.1208 22.7265C11.0442 20.7411 7.96237 18.7599 4.89035 16.7672C3.25645 15.7078 2.63332 14.0652 3.23057 12.4452C3.31049 12.2299 3.4329 12.0293 3.55385 11.8353C5.56183 8.61186 7.57062 5.39143 9.5794 2.17099C10.6612 0.436627 12.6498 0.0601166 14.5251 1.26011C17.2547 3.00387 19.9728 4.77063 22.6945 6.52707C26.1259 8.7414 29.5594 10.9521 32.9874 13.1718C34.6329 14.2389 35.2646 15.8589 34.6781 17.4834C34.593 17.7157 34.4635 17.932 34.3335 18.1405C32.3357 21.3477 30.336 24.5537 28.3343 27.7584C27.2332 29.5235 25.255 29.8965 23.3507 28.6777C20.2685 26.7014 17.1945 24.7076 14.118 22.7222L14.1208 22.7265ZM9.26234 6.3713C9.15607 6.54166 9.07243 6.67577 8.9899 6.80807C7.74078 8.81065 6.49277 10.8114 5.24364 12.814C4.64338 13.7763 4.84752 14.5868 5.88273 15.2548C12.018 19.2192 18.1556 23.1799 24.2954 27.137C25.3015 27.7864 26.1158 27.623 26.6999 26.6909C27.96 24.6751 29.2163 22.6567 30.4745 20.6397C30.5558 20.5092 30.6217 20.3687 30.705 20.2089L9.26234 6.3713ZM10.6573 4.13494L32.1252 17.9889C32.3784 17.5829 32.6368 17.2209 32.8413 16.8319C33.2393 16.072 32.9006 15.2637 31.941 14.6445C25.8674 10.725 19.7919 6.80424 13.7183 2.88476C13.6214 2.82221 13.5267 2.75604 13.4252 2.70073C12.5583 2.22509 11.7618 2.39484 11.2663 3.16299C11.0649 3.47276 10.874 3.79186 10.6581 4.13801L10.6573 4.13494Z" fill={theme.tertiary}/>
          <path d="M9.26234 6.3713L30.7073 20.2099C30.6221 20.3685 30.5561 20.509 30.4767 20.6407C29.2185 22.6577 27.9623 24.6761 26.7022 26.6919C26.12 27.6253 25.3038 27.7874 24.2976 27.138C18.1567 23.1828 12.0203 19.2202 5.885 15.2558C4.84979 14.5878 4.64564 13.7773 5.24591 12.815C6.49311 10.8112 7.74304 8.81166 8.99217 6.80908C9.07469 6.67678 9.15834 6.54267 9.26461 6.37233L9.26234 6.3713ZM19.5931 19.3718C18.686 20.7867 19.6382 22.7256 21.3971 22.9964C21.7492 23.0508 21.9432 23.1582 22.1409 23.4689C22.7474 24.4321 23.929 24.8565 24.9408 24.5334C25.9794 24.2022 26.5814 23.2283 26.417 22.1537C26.2506 21.078 25.3672 20.1927 24.2563 20.0427C23.9308 19.9978 23.748 19.89 23.5609 19.6014C22.6043 18.1098 20.4753 17.9966 19.5931 19.3718ZM9.43601 15.552C9.99819 15.9148 10.5604 16.2776 11.1226 16.6404C12.0395 17.2322 12.0202 17.2197 12.5679 16.3632C12.8239 15.9616 12.725 15.7453 12.3145 15.488C11.1137 14.7411 9.93327 13.9615 8.74492 13.1947C7.79888 12.5841 7.81633 12.5954 7.26307 13.4737C7.02455 13.8561 7.11309 14.0759 7.50565 14.3139C8.15976 14.7081 8.79433 15.138 9.43601 15.552Z" fill={theme.tertiary_lighter}/>
          <path d="M10.6565 4.13692C10.8724 3.79078 11.0653 3.47293 11.2647 3.16191C11.7602 2.39375 12.5556 2.22582 13.4237 2.69965C13.5251 2.75494 13.6198 2.82113 13.7167 2.88368C19.7903 6.80316 25.8659 10.7239 31.9394 14.6433C32.8991 15.2627 33.2377 16.0709 32.8399 16.8308C32.6341 17.2217 32.3768 17.5818 32.1236 17.9877L10.6573 4.13494L10.6565 4.13692Z" fill={theme.tertiary_lighter}/>
          <path d="M19.5931 19.3718C20.4753 17.9966 22.6035 18.1068 23.5609 19.6014C23.7449 19.8906 23.9308 19.9978 24.2563 20.0427C25.366 20.1945 26.2506 21.078 26.417 22.1537C26.5834 23.2296 25.9794 24.2022 24.9408 24.5334C23.929 24.8565 22.7454 24.4308 22.1409 23.4689C21.9444 23.1563 21.7503 23.049 21.3971 22.9964C19.6382 22.7256 18.686 20.7867 19.5931 19.3718Z" fill={theme.tertiary}/>
          <path d="M9.43601 15.552C8.79433 15.138 8.15976 14.7081 7.50565 14.3139C7.11314 14.0759 7.02252 13.8549 7.26307 13.4737C7.81633 12.5954 7.79888 12.5841 8.74492 13.1947C9.93327 13.9615 11.1137 14.7411 12.3145 15.488C12.728 15.7447 12.827 15.9609 12.5679 16.3632C12.0202 17.2197 12.0395 17.2322 11.1226 16.6404C10.5604 16.2776 9.99819 15.9148 9.43601 15.552Z" fill={theme.tertiary}/>
        </svg>
      </Circle>
      <Dots/>
      <Circle label='reward'>
        <svg width="12" height="12" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.9997 13.2903C24.9997 6.73067 19.6826 1.41306 13.1235 1.41306L13.1235 25.1675C19.6826 25.1675 24.9997 19.8499 24.9997 13.2903Z" fill="#B7EAD3"/>
          <path d="M1.24736 13.2903C1.24736 6.73066 6.56451 1.41305 13.1235 1.41305L13.1235 25.1675C6.56451 25.1675 1.24736 19.8499 1.24736 13.2903Z" fill="#EFF8F4"/>
          <ellipse cx="12.8762" cy="13.0444" rx="11.8762" ry="11.8772" stroke="#5FB894" strokeWidth="1.78115"/>
          <path d="M22.0307 13.292C22.0307 8.37234 18.0428 4.38413 13.1235 4.38413L13.1235 22.2C18.0428 22.2 22.0307 18.2117 22.0307 13.292Z" fill="#9ADCBF"/>
          <path d="M4.21543 13.291C4.21543 18.2107 8.20329 22.1989 13.1226 22.1989L13.1226 4.38306C8.20329 4.38306 4.21543 8.37126 4.21543 13.291Z" fill="#9ADCBF" fillOpacity="0.4"/>
          <path d="M12.9479 8.57646C13.5906 8.57646 14.1116 8.08146 14.1116 7.47084C14.1116 6.86023 13.5906 6.36523 12.9479 6.36523C12.3052 6.36523 11.7842 6.86023 11.7842 7.47084C11.7842 8.08146 12.3052 8.57646 12.9479 8.57646Z" fill="#5FB894"/>
          <path d="M11.9594 20.1566C11.1448 20.3894 10.2721 19.9821 9.98114 19.2256L7.94467 13.3484C7.65375 12.5919 8.11923 11.7773 8.992 11.4863C9.80659 11.2536 10.6794 11.6609 10.9703 12.4174L13.0068 18.2946C13.2395 19.051 12.774 19.8657 11.9594 20.1566Z" fill="#5FB894"/>
          <path d="M14.1122 20.1566C14.9267 20.3894 15.7995 19.9821 16.0323 19.2256L18.0687 13.3484C18.3015 12.5919 17.8942 11.7773 17.0796 11.4863C16.265 11.2536 15.3922 11.6609 15.1595 12.4174L13.123 18.2946C12.8321 19.051 13.2976 19.8657 14.1122 20.1566Z" fill="#5FB894"/>
          <path d="M13.0628 20.2149C11.841 20.2149 10.8518 19.2256 10.8518 18.0036V11.7191C10.8518 10.4971 11.841 9.5079 13.0628 9.5079C14.2847 9.5079 15.2739 10.4971 15.2739 11.7191V18.0036C15.2739 19.2256 14.2265 20.2149 13.0628 20.2149Z" fill="#5FB894"/>
          <path d="M17.486 10.2058C17.9359 10.2058 18.3006 9.86712 18.3006 9.44934C18.3006 9.03155 17.9359 8.69287 17.486 8.69287C17.0361 8.69287 16.6714 9.03155 16.6714 9.44934C16.6714 9.86712 17.0361 10.2058 17.486 10.2058Z" fill="#5FB894"/>
          <path d="M8.58363 10.2058C9.03352 10.2058 9.39822 9.86712 9.39822 9.44934C9.39822 9.03155 9.03352 8.69287 8.58363 8.69287C8.13375 8.69287 7.76904 9.03155 7.76904 9.44934C7.76904 9.86712 8.13375 10.2058 8.58363 10.2058Z" fill="#5FB894"/>
        </svg>
      </Circle>
    </div>
  );
};
