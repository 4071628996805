import {Formik} from 'formik';
import {apiAuth} from 'logic/api';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import RestaurantForm from './RestaurantForm';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {sayError} from "../../../logic/defaults";
import CompanyForm from './CompanyForm';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  // name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
  // name: '',
  // email: '',
};


export default function CompaniesNew({
                                         values,
                                         errors,
                                         touched,
                                         setFieldValue,
                                         handleChange,
                                         handleBlur,
                                         handleSubmit,
                                         isSubmitting,
                                       }) {
  const {t} = useTranslation();
  const history = useHistory();


  const onSubmit = async (values, {setSubmitting}) => {
    //
    const enviaremos = beforeSubmit(values);

    try {
      await apiAuth.post(`/companies/`, enviaremos);
      toast.success(`${t('update_ok')}`);
      history.push("/admin/companies");
    } catch (error) {
      toast.error(sayError(error.response.data));
    }

  };

  const beforeSubmit = (values) => {
    const after = {...values};

    delete after.groups;
    delete after.opening_hours;
    delete after.owner;
    delete after.profile_photos;
    delete after.sectors;
    delete after.chef_photo;
    delete after.logo;
    delete after.menu;
    delete after.home_image;
    delete after.home_image_order_and_pay_or_collect;

    after.tpv = values?.tpv?.id ?? null;
    after.owner = values?.owner?.id ?? null;
    after.business_type = values?.business_type.name ?? 'Business';

    return after;
  };

  return (
    <div className={'flex flex-col'}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validacion}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
          <CompanyForm
            {...{
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }}
          />
        )}
      </Formik>
    </div>
  );
}
