import React from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from "react-i18next";
import { categories_select_styles } from 'logic/defaults';

const StockTypeSelect = ({selectedStockType, setSelectedStockType}) => {

	const { t } = useTranslation();
	const stock_type_options = [
		{ value: 'all', label: t('All items') },
		{ value: 'with_limit', label: t('With limit') },
		{ value: 'without_limit', label: t('Without limit') },
	];

	const Option = (props) => {
		//console.log('props.value', props.value);
		return (
			<div key={'shift_' + props.value}>
                <components.Option {...props}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <label>{props.label}</label>
                    <input
                    className="checkboxRestaurantSelect"
                    type="checkbox"
                    checked={props.value === selectedStockType}
                    onChange={() => null()}
                    />{" "}
                    <span></span>
                    </div>
                </components.Option>
			</div>
		);
	};

	const handleChangeOption = (stockTypeToSelect) => {
		console.log('handleChangeOption stockTypeToSelect', stockTypeToSelect);
		setSelectedStockType(stockTypeToSelect.value);
	};

	return (
		<div>
			<div style={{fontSize: "16px", fontWeight: 500}}>{t('Filter by stock')}</div>
			<Select
				isMulti={false}
				options={stock_type_options}
				styles={categories_select_styles}
				className={'w-60'}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				placeholder={t('All items')}
				onChange={e => handleChangeOption(e)}
				isClearable={false}
				components={{Option}}
			/>
		</div>
	);
};
export default StockTypeSelect;
