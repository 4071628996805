import { BUTTON_SIZES } from 'common/Button';
import { RadioButtonGroup } from 'common/RadioButtonGroup';
import { WEEKDAYS } from 'pages/discounts/components/constants';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const DAY_OPTIONS = WEEKDAYS.SHORT.map(((label, value) => ({ label, value })));

export const DaySelector = ({days, editDays}) => {
  const {t} = useTranslation();
  
  const handleChange = (value) => {
    const index = days.findIndex(day => day === value)
    
    if (index !== -1) {
      const newDays = [...days];
      newDays.splice(index, 1);
      editDays(newDays);
    } else {
      editDays([...days, value].sort((a, b) => a - b));
    }
  };

  return (
    <DaysContainer>
      <div>
        <h4>
          {t('select_days')}
        </h4>
        <span>{t('selected')}</span>
        <span>{t('unselected')}</span>
      </div>
      <RadioButtonGroup
        multiValue={days}
        options={DAY_OPTIONS}
        onChange={handleChange}
        size={BUTTON_SIZES.MD}
      />
    </DaysContainer>
  );
};

const DaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 16px;

    & > h4 {
      font-size: 1rem;
      font-weight: 400;
      margin-right: auto;
    }
  
    & > span {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 8px;
      
      &::before {
        content: '';
        display: inline-block;
        height: 1rem;
        width: 1rem;
        border-radius: 0.25rem;
        border: 1px solid #094553;
      }
  
      &:first-of-type::before {
        background-color: #094553;
      }
  
      &:last-of-type::before {
        background-color: #FFFFFF;
      }
    }
  }
`;