import React from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Select from 'react-select';
import XButton from 'common/XButton';
import useUpdateTableData from 'components/hooks/useUpdateTableData';

import useRestaurant from 'components/yumminn/useRestaurant';
import FormError from 'common/FormError';
import {apiAuth} from 'logic/api';
import {useLazyAxios} from 'use-axios-client';
import {select_styles} from 'logic/defaults';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  // name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
  // name: '',
  // email: '',
};

const SettingsBankPage = ({children}) => {
  const {t} = useTranslation();
  const {seleccionado} = useRestaurant();
  // const [tagsList, setTagsList] = React.useState([]);
  // const [restaurantTypesList, setRestaurantTypesList] = React.useState([]);

  const [getData, {data, error, loading}] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants/${seleccionado[0]?.value}`,
  });

  const [{data: list}] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/tpvs/`,
  });

  const {updateData, error: updatedError} = useUpdateTableData({
    entidad: 'restaurants',
    id: seleccionado[0]?.value,
  });

  React.useEffect(() => {
    //
    if (seleccionado) {
      getData();
    }
  }, [seleccionado, getData]);

  const onSubmit = async (values, {setSubmitting}) => {
    //
    const enviaremos = beforeSubmit(values);

    console.group('Enviamos');
    console.log('values :>> ', values);
    console.log(enviaremos);
    console.groupEnd();

    await updateData(enviaremos);
    toast.success(`${t('update_ok')}`);
    getData();
  };

  const beforeSubmit = (values) => {

    const after = {...values};

    delete after.groups;
    delete after.opening_hours;
    delete after.owner;
    delete after.profile_photos;
    delete after.sectors;
    delete after.chef_photo;
    delete after.logo;
    delete after.menu;
    delete after.home_image;
    delete after.home_image_order_and_pay_or_collect;

    after.tags = values.tags.map((t) => t.id);
    // delete after.types;
    after.types = values.types.map((t) => t.id);
    after.tpv = values.tpv?.id;

    return after;
  };

  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error || updatedError) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-col'}>
        <Formik
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              setFieldValue,
              handleChange,
              handleSubmit,
              /* and other goodies */
            }) => (
            <form onSubmit={handleSubmit} autoComplete='off'>
              {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
              {/* <pre>{JSON.stringify(updated, null, 4)}</pre> */}
              <div className='flex flex-row space-x-5 w-full'>
                {/* col1 */}
                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='company_name'
                    >
                      {t('Company_Name')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Company_Name')}
                        className='yumminn-input'
                        value={values.company_name || ''}
                        name='company_name'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.company_name}/>
                    </div>
                  </div>
                  {/* // company nif */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='company_cif'
                    >
                      {t('Company_CIF')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Company_CIF')}
                        className='yumminn-input'
                        value={values.company_cif || ''}
                        name='company_cif'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.company_cif}/>
                    </div>
                  </div>
                  {/* // compnay bank */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='company_bank'
                    >
                      {t('Company_Bank')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Company_Bank')}
                        className='yumminn-input'
                        value={values.company_bank || ''}
                        name='company_bank'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.company_bank}/>
                    </div>
                  </div>
                  {/* // company iban */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='company_iban'
                    >
                      {t('Company_IBAN')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Company_IBAN')}
                        className='yumminn-input'
                        value={values.company_iban || ''}
                        name='company_iban'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.company_iban}/>
                    </div>
                  </div>
                </div>
                {/* END col1 */}

                {/* col2 */}
                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='owner_first_name'
                    >
                      {t('Owner_Name')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Owner_First_Name')}
                        className='yumminn-input'
                        value={values.owner_first_name || ''}
                        name='owner_first_name'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.owner_first_name}/>
                    </div>
                  </div>
                  {/* col2 */}
                  <div className='w-full flex flex-col'>
                    {/* // name */}
                    <div className='flex items-center w-full space-x-2'>
                      <label
                        className='w-72 text-[14px] text-texto text-right'
                        htmlFor='owner_last_name'
                      >
                        {t('Owner_Name')}*
                      </label>
                      <div className='w-full flex flex-col mb-1'>
                        <input
                          type='text'
                          placeholder={t('Owner_Last_Name')}
                          className='yumminn-input'
                          value={values.owner_last_name || ''}
                          name='owner_last_name'
                          onChange={handleChange}
                          autoComplete='one-time-code'
                        />
                        <FormError message={errors.owner_last_name}/>
                      </div>
                    </div>
                  </div>
                  {/* // owner nif */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='owner_cif'
                    >
                      {t('Owner_CIF')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Owner_CIF')}
                        className='yumminn-input'
                        value={values.owner_cif || ''}
                        name='owner_cif'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.owner_cif}/>
                    </div>
                  </div>
                  {/* // compnay bank */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='owner_phone'
                    >
                      {t('Owner_Phone')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Owner_Phone')}
                        className='yumminn-input'
                        value={values.owner_phone || ''}
                        name='owner_phone'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.owner_phone}/>
                    </div>
                  </div>
                  {/* // owner email */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='owner_email'
                    >
                      {t('Owner_email')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='email'
                        placeholder={t('Owner_email')}
                        className='yumminn-input'
                        value={values.owner_email || ''}
                        name='owner_email'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.owner_email}/>
                    </div>
                  </div>
                  {' '}
                </div>
                {/* END col2 */}
              </div>

              {/* ********************************************** */}
              {/* only superusers can edit pos and payments data */}
              {/* ********************************************** */}

              <div className='flex flex-row space-x-5 w-full mt-5'>
                {/* col1 */}
                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_holder_id'
                    >
                      {t('Account_holder')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Account_holder')}
                        className='yumminn-input'
                        value={values.adyen_account_holder_code || ''}
                        name='adyen_account_holder_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_account_holder_code}/>
                    </div>
                  </div>
                  {/* // company nif */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_code'
                    >
                      {t('Account')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Account')}
                        className='yumminn-input'
                        value={values.adyen_account_code || ''}
                        name='adyen_account_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_account_code}/>
                    </div>
                  </div>
                  {/* // compnay bank */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_tips_code'
                    >
                      {t('Tips_account')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Tips_account')}
                        className='yumminn-input'
                        value={values.adyen_account_tips_code || ''}
                        name='adyen_account_tips_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_account_tips_code}/>
                    </div>
                  </div>
                </div>
                {/* END col1 */}

                {/* col2 */}

                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_key'
                    >
                      {t('TPV_Key')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <Select
                        menuPortalTarget={document.body}
                        styles={select_styles}
                        className='w-full max-w-md text-[14px]'
                        name={'TPV'}
                        placeholder={t(`Empezar a escribir TPV...`)}
                        options={list && list.results}
                        value={values.tpv}
                        onChange={(a) => {
                          setFieldValue('tpv', a);
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isDisabled={true}
                      />
                    </div>
                    <FormError message={errors.tpv_key}/>
                  </div>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_key'
                    >
                      {t('TPV_Key')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('TPV_Key')}
                        className='yumminn-input'
                        value={values.tpv_key || ''}
                        name='tpv_key'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.tpv_key}/>
                    </div>
                  </div>
                  {/* // owner nif */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_secret'
                    >
                      {t('TPV_Secret')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('TPV_Secret')}
                        className='yumminn-input'
                        value={values.tpv_secret || ''}
                        name='tpv_secret'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.tpv_secret}/>
                    </div>
                  </div>
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_local_id'
                    >
                      {t('TPV_Local_Id')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('TPV_Local_Id')}
                        className='yumminn-input'
                        value={values.tpv_local_id || ''}
                        name='tpv_local_id'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.tpv_local_id}/>
                    </div>
                  </div>
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_wildcard_product_name'
                    >
                      {t('TPV_Wildcard_Product_Name')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('TPV_Wildcard_Product_Name')}
                        className='yumminn-input'
                        value={values.tpv_wildcard_product_name || ''}
                        name='tpv_wildcard_product_name'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.tpv_wildcard_product_name}/>
                    </div>
                  </div>
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-72 text-[14px] text-texto text-right'
                      htmlFor='tpv_wildcard_product_code'
                    >
                      {t('TPV_Wildcard_Product_Code')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('TPV_Wildcard_Product_Code')}
                        className='yumminn-input'
                        value={values.tpv_wildcard_product_code || ''}
                        name='tpv_wildcard_product_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.tpv_wildcard_product_code}/>
                    </div>
                  </div>
                </div>
                {/* END col2 */}
              </div>

              <div className='self-end my-5 w-full'>
                <XButton
                  className='btn btn-primary rounded-full px-12'
                  type='button'
                  doClick={handleSubmit}
                >
                  {t('save')}
                </XButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default SettingsBankPage;
