import { PlusCircleIcon } from '@heroicons/react/solid'
import React from 'react';
import styled from "styled-components";
import { centsToAmountString } from "logic/functions";
import { ImagePlaceholder } from './ImagePlaceholder';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 70px;
  height: 95px;
  padding: 3px;
  position: relative;
  color: #404040;
  border: 2px solid #EEE;
  border-radius: 10px;
  box-shadow: 0px 1px 2px #00000010, 0px 0px 4px #00000010;
  box-sizing: border-box;

  img {
    height: 100%;
    width: 60px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  gap: 5px;
  margin-top: 2px;

  & > span {
    font-size: 0.4rem;
    position: absolute;
    bottom: 5px;
    left: 5px;
  }

  h5 {
    font-size: 0.45rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 10px;
    margin: 0;
  }
`;

const AddButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 1px;
  right: 1px;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;


export const CounterButton = styled.button`
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  border: none;
  border-radius: 9999px;
  height: .7rem;
  width: .7rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
  padding: 0.3rem;
  transition: opacity 0.15s ease-in-out;
  
  &:disabled {
    opacity: 0.6;
  }

  svg {
    height: 100%;
    width: 100%;
    margin: auto;
  }
`;

export const SuggestionProductCardMockup = ({product}) => {
  return (
    <CardContainer>
      <div style={{display: 'flex', height: "50%", justifyContent: 'center', alignItems: 'center'}}>
      {product && product.product_image ?
        <img src={`${product.product_image}`} alt='product_photo'/>
      :
        <ImagePlaceholder/>
      }
      </div>
      <InfoWrapper>
        <h5>{product ? product.product_tpv_name : "Producto Ejemplo"}</h5>
        {product ? (product.product_tpv_price_cents > 0 &&
        <span>{centsToAmountString(product.product_tpv_price_cents)}€</span>) : <span>1,20€</span>}
        <AddButtonsWrapper>
          <PlusCircleIcon height={14}/>
        </AddButtonsWrapper>
      </InfoWrapper>
    </CardContainer>
  )
}
