import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import takeAwayIcon from '../../../../img/PickUpBag.svg';
import { TextInput } from 'common/Input';
import { Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';


const OrderScanInput = ({handleClick, loading, showWarning}) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState("");

  const handleKeyPress = (e) => {
    if ((e.keyCode === 13 || e.which === 13) && inputText) {
        handleClick(inputText)
    }
  };

  return (
	<div style={{display: 'flex', flexDirection: 'column', gap: "15px"}}>
    <div style={{fontWeight: 500, fontSize: '22px', color: '#222222', display: 'flex', gap: '10px', borderBottom: "1px solid #D1D1D1"}}>
      <img src={takeAwayIcon}/>
      {t('See orders')}
    </div>
    <p>{t('Scan the order')}</p>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{width: '60%'}}>
      <TextInput
        autoFocus={true}
        onKeyDown={handleKeyPress}
        name='order_uuid'
        value={inputText}
        placeholder={'Ej: ecd594f63cf94597b7644e5e804bn7'}
        onChange={(event) => setInputText(event.target.value)}
      />
      </div>
      <Button
        label={'See order'}
        style={{width: '30%'}}
        onClick={() => handleClick(inputText)}
        loading={loading}
        disabled={!inputText}
      />
    </div>
    {showWarning && 
    <div style={{display: 'flex', backgroundColor: "#FFFBEC", padding: "10px", gap: '10px', alignItems: 'center'}}>
      <Icon type={IconType.WARNING} size={50}/>
      <p><b>El ID ingresado no pertenece a un pedido o no es correcto.</b> Ingresa otro o inténtalo de nuevo.</p>
    </div>
    }
	</div>
  );
};
export default OrderScanInput;
