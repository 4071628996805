import React from 'react';
import { ColumnContainer, RowContainer } from '../ExampleContainers';
import { Icon, IconType, SquareIcon } from 'common/Icon';
import styled from 'styled-components';

const types = Object.values(IconType);

export const IconExample = () => {
  return (
    <ColumnContainer>
      <h2>Icon</h2>
      <p>Hover to see the names</p>
      <RowContainer>
        {types.map(type =>
          <IconWithLabel key={type}>
            <Icon type={type} size={24}/>
            <span>{type}</span>
          </IconWithLabel>
        )}
      </RowContainer>
      <p>When <code>onClick</code> is present, it becomes a button and some styles change</p>
      <RowContainer>
        {['ADD', 'PENCIL', 'TRASH', 'VIDEO'].map(type =>
          <Icon type={IconType[type]} size={24} onClick={() => alert(type)}/>
        )}
      </RowContainer>
      <h2>SquareIcon</h2>
      <RowContainer>
        <SquareIcon>🐉</SquareIcon>
        <SquareIcon type={IconType.WIFI}/>
        <SquareIcon>A</SquareIcon>
      </RowContainer>
      <RowContainer>
        <SquareIcon emoji='🔥'>🐉</SquareIcon>
        <SquareIcon type={IconType.WIFI} color={'#094553'}/>
        <SquareIcon color={'#094553'}>A</SquareIcon>
      </RowContainer>
      <RowContainer>
        <SquareIcon type={IconType.EDIT} emoji='❌'/>
        <SquareIcon type={IconType.TRASH} size={60} emoji='🕑'/>
        <SquareIcon size={80} emoji='🐱‍👤'>A</SquareIcon>
        <SquareIcon size={90} emoji='👾'>🐱‍🐉</SquareIcon>
        <SquareIcon size={90} extraIcon={IconType.PENCIL}>
          <img src='https://picsum.photos/200'/>
        </SquareIcon>
      </RowContainer>
      <RowContainer>
        <SquareIcon size={80}>
          <SquareIcon size={60}>
            <SquareIcon size={40}/>
          </SquareIcon>
        </SquareIcon>
        <SquareIcon size={90} src='https://picsum.photos/200'/>
        <SquareIcon size={200} emoji='📸' src='https://picsum.photos/200/300'/>
      </RowContainer>
    </ColumnContainer>
  );
};

const IconWithLabel = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 100%;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px #0001, 0 2px 4px -1px #0001;
    padding: 8px;
    margin: 0 8px;
    pointer-events: none;
    opacity: 0;
    z-index: 9999;
    transition: opacity 0.2s ease-in-out;
  }
  
  &:hover {
    color: #094553;

    span {
      opacity: 1;
    }
  }
`;