import React,{useState, useRef, useEffect} from 'react'
import { MobileMockup } from "components/previews";
import Select, { components } from 'react-select';
import { categories_select_styles } from 'logic/defaults';
import L3DishCountImg from "../../../../img/L3DishCount.svg";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';


export const L3SlotDishSetting = ({l3DishCountDraft, setL3DishCountDraft}) => {  
  const [openedL1Ids, setOpenedL1Ids] = useState([]);
  const [openedL2Ids, setOpenedL2Ids] = useState([]);

  const handleChange = (l3) => {
      const newL3DishCountDraft = JSON.parse(JSON.stringify(l3DishCountDraft))
      console.log(l3)
      console.log(newL3DishCountDraft)
      const l3_obj = newL3DishCountDraft.l3.find(l3_f => l3_f.id == l3.id)
      l3_obj.slot_dish_count = l3_obj.slot_dish_count > 0 ? 0 : 1

      setL3DishCountDraft(newL3DishCountDraft)
  }

  return (
    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>              
        <img src={L3DishCountImg} style={{width: "200px"}}/>
        <div style={{padding: "0px 30px", textAlign: 'center', marginTop: "10px", fontSize: "16px"}}>
          <span style={{fontWeight: 700}}>Selecciona</span> las categorías cuyos productos consideras que cuentan como <span style={{fontWeight: 700}}><span style={{textDecoration: "underline"}}>un plato</span> en la capacidad del slot.</span> Ejemplo: Tal vez no quieras considerar las <span style={{fontWeight: 700}}>bebidas</span> como un plato.
        </div>
      </div>
      <div style={{padding: "10px 20px", overflowY: 'scroll', userSelect: 'none', border: "2px solid #DDDDDD", borderRadius: "15px"}}>              
        {l3DishCountDraft.l1.map((l1) => (
          <>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{fontSize: "16px", fontWeight: 600}}>{l1.visible_name}</div>
              <div>
                {openedL1Ids.includes(l1.id) ? 
                  <ChevronUpIcon
                    style={{height: "35px"}}
                    onClick={() => setOpenedL1Ids(ids => ids.filter(id =>id != l1.id))}
                    className='rounded-full cursor-pointer p-[3px]'
                  /> : 
                  <ChevronDownIcon
                    style={{height: "35px"}}
                    onClick={() => setOpenedL1Ids(prevOpenedL1Ids => [...prevOpenedL1Ids, l1.id])}
                    className='rounded-full cursor-pointer p-[3px]'
                />}
              </div>
          </div>
            {openedL1Ids.includes(l1.id) && 
              l3DishCountDraft.l2.filter(l2_f => l2_f.category_supergroup == l1.id).map((l2) => (
                <div style={{marginLeft: "10px"}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div style={{fontSize: "15px", fontWeight: 500}}>{l2.visible_name}</div>
                  <div>
                    {openedL2Ids.includes(l2.id) ? 
                      <ChevronUpIcon
                        style={{height: "35px", color: "#A0A0A0"}}
                        onClick={() => setOpenedL2Ids(ids => ids.filter(id =>id != l2.id))}
                        className='rounded-full cursor-pointer p-[3px]'
                      /> : 
                      <ChevronDownIcon
                        style={{height: "35px", color: "#A0A0A0"}}
                        onClick={() => setOpenedL2Ids(prevOpenedL2Ids => [...prevOpenedL2Ids, l2.id])}
                        className='rounded-full cursor-pointer p-[3px]'
                    />}
                  </div>
                </div>
                  {openedL2Ids.includes(l2.id) && 
                  l3DishCountDraft.l3.filter(l3_f => l3_f.category_group == l2.id).map((l3) => (
                    <div style={{marginBottom: "10px", display: 'flex', flexDirection: 'column', gap: "10px"}}>

                      <div style={{display: 'flex', justifyContent: 'space-between', border: "2px solid #DDDDDD", alignItems: 'center', padding: "5px 10px", borderRadius: '5px'}}>
                        <div>{l3.visible_name}</div>
                        <input
                          style={{color: "#A0A0A0", boxShadow: 'none', cursor: 'pointer'}}
                          type="checkbox"
                          checked={l3.slot_dish_count > 0}
                          onChange={() => handleChange(l3)}
                        />
                      </div>
                    </div>
                  ))
                  }
                </div>
              ))
            }
        </>
        ))}
      </div>
    </div>
  )
}
