import React, {useRef} from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import { PlusIcon } from '@heroicons/react/outline';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import DatePicker from "react-datepicker";
import trash from '../../../img/trash.png';
import useDeleteModal from 'components/hooks/useDeleteModal';
import MenuSavedSchedules from './MenuSavedSchedules';
import useModal from 'components/hooks/useModal';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

const MenuVisibility = ({
    selectedDays,
    setSelectedDays,
    menuId,
    grupos,
    setGrupos,
    timeRange,
    setTimeRange,
    to,
    setTo,
    from,
    setFrom,
    allTimeRanges,
    setAllTimeRanges,
    allSchedules,
    setAllSchedules
}) => {
    const { t } = useTranslation();
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const isRowBased = useMediaQuery("(max-width: 760px)");
    const myRef = useRef(null);
    const [scroll, setScroll] = React.useState(false)
    const [visibilityNewSchedules, setVisibilityNewSchedules] = React.useState(false)
    const [showButton, setShowButton] = React.useState(true)

    const handleDays = (day) => {
        let dayList = [...selectedDays]
        const dayAlreadySelected = dayList.some((d) => d === day)
        if(!dayAlreadySelected){
            dayList.push(day)
            setSelectedDays(dayList)
        } else {
            const dayToDelete = dayList.indexOf(day)
            dayList.splice(dayToDelete, 1)
            setSelectedDays(dayList)
        }
    }

    const setTimeRanges = (e) => {
        setTo(e)
        let timeRangeList = [...allTimeRanges]
        timeRangeList.push({from: from, to: e})
        setAllTimeRanges(timeRangeList)
        const today = new Date(new Date().getTime());
        const startDate = today.setHours(12, 0, 0, 0);
        const endDate = today.setHours(0, 0, 0, 0);
        setFrom(new Date(startDate))
        setTo(new Date(endDate))
        setTimeRange(false)
    }

    const handleDeleteTimeRange = (index) => {
        let timeRangeList = [...allTimeRanges]
        timeRangeList.splice(index, 1)
        setAllTimeRanges(timeRangeList)
    };

    const menuModal = useDeleteModal(handleDeleteTimeRange);
    const infoModal = useModal();

    const setSchedules = () => {
        if(!visibilityNewSchedules){
          setVisibilityNewSchedules(true)
        } else {
          let schedules = [...allSchedules]
          if(selectedDays.length > 0){
              schedules.push({
                  selectedDays: selectedDays,
                  timeRanges: allTimeRanges
              })
              setAllSchedules(schedules)
              setSelectedDays([])
              setAllTimeRanges([])
          } else {
              infoModal.openModal()
          }
        }  
    }

    React.useEffect(() => {
        if(scroll){
            myRef?.current?.scrollIntoView();
            setScroll(false)
        }
    }, [scroll])

 
    return(
        <>
        <div className='flex flex-col w-full pt-8'>
            <MenuSavedSchedules setScroll={setScroll} allSchedules={allSchedules} setAllSchedules={setAllSchedules} menuId={menuId} setGrupos={setGrupos} setAllTimeRanges={setAllTimeRanges} allTimeRanges={allTimeRanges} selectedDays={selectedDays} setSelectedDays={setSelectedDays} setTimeRange={setTimeRange}/>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='mb-5 mr-3 bg-white p-3 rounded-lg'
                ref={myRef}
                style={{visibility: grupos && grupos[0] && grupos[0].visibility && grupos[0].visibility.length > 0 && !visibilityNewSchedules ? 'hidden' : 'visible', position: grupos && grupos[0] && grupos[0].visibility && grupos[0].visibility.length > 0 && !visibilityNewSchedules ? 'absolute' : 'relative'}}
              >
                <div
                  className='flex flex-col space-y-3 divide-y'
                >
                  <div className={`flex ${isRowBased && 'flex-col'} items-center w-full space-x-2`}>
                    <label
                      className={`w-36 text-[16px] text-texto ${isRowBased && 'pl-8 pb-4'}`}
                      htmlFor='name_link'
                    >
                      {t('Select days')}
                    </label>
                    {weekdays.map((day, index) => 
                    <div key={`button${index}`} className='flex'>
                      <button
                          style={{color: selectedDays.some((d) => d === day) ? '#FFF' : '#EA9F89', background: selectedDays.some((d) => d === day) ? '#EA9F89' : '#FFF'}}
                          className='focus:outline-none flex text-nuevo_secundario w-24 h-9 justify-center items-center capitalize rounded-full cursor-pointer shadow-md mx-1 border-2 border-nuevo_secundario text-sm tracking-wide'
                          type='button'
                          onClick={() => handleDays(day)}
                          >
                          {t(day)}
                      </button>
                    </div>
                    )}
                  </div>
                  <div className='flex flex-col w-full space-x-2 pt-4 pb-4'>
                    <label
                      className='w-36 text-[16px] text-texto pb-4'
                      htmlFor='name_link'
                    >
                      {t('Select time range')}
                    </label> {/*saved timeranges*/}
                    {allTimeRanges.map((range, index) => 
                      <div key={index} className='flex pt-1 justify-between items-center pb-4'>
                          <div key={`alltimediv${index}`} className='flex'>
                              <div key={`alltimediv2${index}`} className='mr-48'>
                                  {t('From')}
                                  <DatePicker
                                      key={`from${index}`}
                                      selected={range['from']}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeCaption="Time"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      disabled
                                      style={{border: '2px solid hsl(0, 0%, 80%'}}
                                  />
                              </div>
                              <div key={`alltimediv3${index}`} className='ml-8'>
                                  {t('To')}
                                  <DatePicker
                                      key={`to${index}`}
                                      selected={range['to']}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeCaption="Time"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      disabled
                                      style={{border: '2px solid hsl(0, 0%, 80%'}}
                                  />
                              </div>
                          </div>
                          <img 
                              key={`imgalltimeranges${index}`}
                              className='mr-8'
                              src={trash} 
                              onClick={() => menuModal.openModal(index)}
                              style={{width: '24px', height: "24px", marginLeft: '15px', cursor: "pointer"}} 
                              alt='delete'
                          />  
                      </div>
                    )}
                  
                    {!timeRange && showButton &&
                    <div 
                      className='text-[16px] text-principal font-semibold flex items-center cursor-pointer'
                      style={{border: '2px dashed #d0d0d0', padding: "20px 80px", borderRadius: '4px', marginRight: '30px'}}
                      onClick={() => {setTimeRange(true); setShowButton(false)}}
                    >
                      <PlusIcon className={`mr-2 h-4 w-4 text-principal hover:text-medio`} />
                      {t('Add time range')}
                    </div>
                    } {/*edit timeranges*/}
                    {timeRange &&
                    <div className='flex pt-1 justify-between items-center mt-4' style={{borderTopWidth: '1px'}}>
                      <div className='flex pt-8'>
                          <div className='mr-48'>
                              {t('From')}
                              <DatePicker
                                  selected={from}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Time"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  style={{border: '2px solid hsl(0, 0%, 80%'}}
                                  onChange={(e) => setFrom(e)} />
                          </div>
                          <div className='ml-8'>
                              {t('To')}
                              <DatePicker
                                  selected={to}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Time"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  minTime={from}
                                  injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
                                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                  style={{border: '2px solid hsl(0, 0%, 80%'}}
                                  onChange={(e) => setTimeRanges(e)} />
                          </div>
                      </div>
                    </div>
                    }
                    {allTimeRanges.length > 0 && 
                    <div 
                      className='text-[16px] text-principal font-semibold flex items-center cursor-pointer pt-8'
                      onClick={() => setTimeRange(true)}
                    >
                      <PlusIcon className={`mr-2 h-4 w-4 text-principal hover:text-medio`} />
                      {t('Add time range')}
                    </div>}
                  </div>

                </div>
                
              </motion.div>
              
            <div 
                className='text-[16px] text-principal font-semibold flex items-center justify-center cursor-pointer'
                style={{/*visibility: allSchedules.length > 0 && !visibilityNewSchedules ? 'hidden' : 'visible', */border: '2px dashed #d0d0d0', padding: "20px", borderRadius: '4px', marginRight: '30px', width: '300px'}}
                onClick={() =>setSchedules()}
                
            >
                <PlusIcon className={`mr-2 h-4 w-4 text-principal hover:text-medio whitespace-nowrap`} />
                {t('Add new schedule')}
            </div>
        </div>

        <menuModal.Modal mensaje={t('Delete_time_range')}/>
        <infoModal.Modal>
            {t('You cannot add a new menu schedule')}
        </infoModal.Modal> 
        </>
    )
}

export default MenuVisibility;

