import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '../../common/Popup';
import styled from 'styled-components';
import { Icon, IconType } from '../../common/Icon';
import { UseHistoryOfRecharges } from './WalletLogic';
import { Context as AuthContext } from 'data/authContext';
import { centsToAmountString } from "logic/functions";
import Spinner from "../../common/Spinner";

export const HistoryRechargesPopup = ({selectedTopup, clientEmail, className = '', ...popupProps}) => {
  const { t } = useTranslation();
  const { state: { selectedDate, selectedDateTo } } = useContext(AuthContext);
  const { content: topupHistory, loading: isLoading } = UseHistoryOfRecharges(selectedDate, selectedDateTo, clientEmail, popupProps.isOpen);

  const formatDate = (isoDate) =>{
    const date = new Date(isoDate)
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = String(date.getUTCFullYear()).slice(-2); // Last two digits of the year

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'

    const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }


  if(isLoading) {
    return(
        <Spinner>{t("Loading")}</Spinner>
      ) 
  }

  if(topupHistory && topupHistory.length > 0){
    return (
        <Popup {...popupProps}>
          <Container className={className}>
            <div className='flex flex-row font-semibold gap-2'>
                <Icon type={IconType.WALLET} size={25}/>
                <span className='text-[18px]'>{t('history_of_recharges')}</span>
            </div>
            <p className='text-[24px]'>{topupHistory[0]?.payment__client_name}</p>
            <p>{selectedTopup?.email}</p>
            <div className='border-[#DADADA] border-[1px] w-full'></div>
            <div className='max-h-[376px] overflow-y-scroll'>
                {topupHistory.map((topup, index) =>
                    <div key={`topupHistory${index}`} className= 'flex flex-col p-4'>
                        <div className='flex items-center gap-2'>
                            <Icon type={IconType.RECEIPT} size={32}/>
                            <div className='uppercase font-semibold text-[18px]'>{`ID: YR-${topup.payment__id}`}</div>
                            <div className='text-[14px] text-[#8C8C8C] mr-8'>{formatDate(topup.created_at)}</div>
                            <div className='font-bold text-[18px] text-[#212121]'>{centsToAmountString(topup.change_amount) + '€'}</div>
                        </div> 
                    </div>
                )}
            </div>
            <button className='mt-4' onClick={popupProps.close}>
              {t('get_out')}
            </button>
          </Container>
        </Popup>
      );
  } 
  
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: fit-content;
  font-size: 1rem;

  & > svg {
    color: #FFFFFF;
    background-color: #5FB894;
    border-radius: 5em;
    padding: 0.125em;
    box-shadow: 0 0 0 0.25em #D3F4E6;
    height: 1.6em;
    width: 1.6em;
  }

  & > h3 {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.3;
    max-width: 450px;
    text-align: center;
    text-wrap: balance;
  }
  
  & > p {
    font-weight: 1em;
    text-align: center;
    line-height: 1.3;
    max-width: 450px;
    text-wrap: balance;
  }

  & > button {
    --btn_color: #FFFFFF;
    --btn_bg_color: #094553;
    --btn_border_color: #094553;

    min-height: 44px;
    padding: 0.5em 2.5em;
    font-size: 1em;
    font-weight: 600;
    color: var(--btn_color);
    background-color: var(--btn_bg_color);
    border: 2px solid var(--btn_border_color);
    border-radius: 99px;

    &::first-letter {
      text-transform: capitalize;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;