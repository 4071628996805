import React from 'react';

import FormError from 'common/FormError';
import Select from 'react-select';
import XButton from 'common/XButton';
import {apiAuth} from 'logic/api';
import {select_styles} from 'logic/defaults';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {sayError} from "../../../logic/defaults";

export default function CompanyForm({
                                         values,
                                         errors,
                                         touched,
                                         setFieldValue,
                                         handleChange,
                                         handleBlur,
                                         handleSubmit,
                                         isSubmitting,
                                       }) {
  const {t} = useTranslation();
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, [])
  

  const getData = async () => {
    try {
      const res_obj = await apiAuth.get(`/users/admin_users`);
      const res_data = res_obj.data;
      setUsers(res_data?.map(u => ({id: u.id, name: u.email})));
    } catch (error) {
      toast.error(sayError(error?.response?.data));

    }

  }


  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
      <div className='flex flex-row space-x-5 w-full'>
        {/* col1 */}
        <div className='w-full flex flex-col'>
          {/* // type */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='tpv_key'
            >
              {t('Business_Type')}*
            </label>
            <div className='w-full flex flex-col mb-1'>
              <Select
                menuPortalTarget={document.body}
                styles={select_styles}
                className='w-full max-w-md text-[14px]'
                name={'TPV'}
                placeholder={t(`Empezar a escribir Tipo de Negocio...`)}
                options={[
                  {id: 'Business', name: 'Business'},
                  {id: 'Individual', name: 'Individual'},
                ]}
                value={
                  typeof values.business_type === 'string'
                    ? {
                      id: values.business_type,
                      name: values.business_type,
                    }
                    : values.business_type
                }
                onChange={(a) => {
                  setFieldValue('business_type', a);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </div>
            <FormError message={errors.business_type}/>
          </div>

          {/* // owner */}
          {!values.id &&
            <div className='flex items-center w-full space-x-2'>
              <label
                className='w-48 text-[14px] text-texto text-right'
                htmlFor='tpv_key'
              >
                {t('Owner')}*
              </label>
              <div className='w-full flex flex-col mb-1'>
                <Select
                  menuPortalTarget={document.body}
                  styles={select_styles}
                  className='w-full max-w-md text-[14px]'
                  name={'TPV'}
                  placeholder={t(`Empezar a escribir Propetario...`)}
                  options={users}
                  value={
                    typeof values.owner === 'string'
                      ? {
                        id: values.owner,
                        name: values.owner,
                      }
                      : values.owner
                  }
                  onChange={(a) => {
                    setFieldValue('owner', a);
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </div>
              <FormError message={errors.owner}/>
            </div>
          }

          {/* // name */}
          <div className='flex items-center w-full space-x-2 mt-5'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='name'
            >
              {t('Company_Name')}*
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Company_Name')}
                className='yumminn-input'
                value={values.name || ''}
                name='name'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.name}/>
            </div>
          </div>

          {/* // email */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='email'
            >
              {t('Email')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='email'
                placeholder={t('Email')}
                className='yumminn-input'
                value={values.email || ''}
                name='email'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.email}/>
            </div>
          </div>
           {/* // reports email */}
           <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='email'
            >
              {t('Reports email')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='email'
                placeholder={t('Reports email')}
                className='yumminn-input'
                value={values.reports_email || ''}
                name='reports_email'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.email}/>
            </div>
          </div>
          {/* // phone */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='phone'
            >
              {t('Phone')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Phone')}
                className='yumminn-input'
                value={values.phone || ''}
                name='phone'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.phone}/>
            </div>
          </div>
          {/* // address */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='address'
            >
              {t('Address')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Address')}
                className='yumminn-input'
                value={values.address || ''}
                name='address'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.address}/>
            </div>
          </div>
          {/* // address */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='street_number'
            >
              {t('Street_number')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Street_number')}
                className='yumminn-input'
                value={values.street_number || ''}
                name='street_number'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.street_number}/>
            </div>
          </div>
          {/* // zip_code */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='zip_code'
            >
              {t('Zip_code')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Zip_code')}
                className='yumminn-input'
                value={values.zip_code || ''}
                name='zip_code'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.zip_code}/>
            </div>
          </div>
          {/* // city */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='city'
            >
              {t('City')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('City')}
                className='yumminn-input'
                value={values.city || ''}
                name='city'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.city}/>
            </div>
          </div>

          {/* // country */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='country_code'
            >
              {t('Country_code')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Country_code')}
                className='yumminn-input'
                value={values.country_code || ''}
                name='country_code'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.country_code} />
            </div>
          </div> 

           {/* // currency code */}
           <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='currency_code'
            >
              {t('Currency_code')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Currency_code')}
                className='yumminn-input'
                value={values.currency_code || ''}
                name='currency_code'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.currency_code} />
            </div>
          </div> 

          {/* custom type fields */}

          {(values.business_type === 'Business' ||
            values?.business_type?.id === 'Business') && (
            <div>
              {/* // company nif */}
              <div className='flex items-center w-full space-x-2'>
                <label
                  className='w-48 text-[14px] text-texto text-right'
                  htmlFor='cif'
                >
                  {t('Company_NIF')}
                </label>
                <div className='w-full flex flex-col mb-1'>
                  <input
                    type='text'
                    placeholder={t('Company_NIF')}
                    className='yumminn-input'
                    value={values.cif || ''}
                    name='cif'
                    onChange={handleChange}
                    autoComplete='one-time-code'
                  />
                  <FormError message={errors.cif}/>
                </div>
              </div>
            </div>
          )}
          {/* END col1 */}

          {/* col2 */}
          {(values.business_type === 'Individual' ||
            values?.business_type?.id === 'Individual') && (
            <div>
              {/* // name */}
              <div className='flex items-center w-full space-x-2 mt-5'>
                <label
                  className='w-48 text-[14px] text-texto text-right'
                  htmlFor='owner_first_name'
                >
                  {t('Owner_First_Name')}*
                </label>
                <div className='w-full flex flex-col mb-1'>
                  <input
                    type='text'
                    placeholder={t('Owner_First_Name')}
                    className='yumminn-input'
                    value={values.owner_first_name || ''}
                    name='owner_first_name'
                    onChange={handleChange}
                    autoComplete='one-time-code'
                  />
                  <FormError message={errors.owner_first_name}/>
                </div>
              </div>
              {/* col2 */}
              <div className='w-full flex flex-col'>
                {/* // name */}
                <div className='flex items-center w-full space-x-2'>
                  <label
                    className='w-48 text-[14px] text-texto text-right'
                    htmlFor='owner_last_name'
                  >
                    {t('Owner_Last_Name')}*
                  </label>
                  <div className='w-full flex flex-col mb-1'>
                    <input
                      type='text'
                      placeholder={t('Owner_Last_Name')}
                      className='yumminn-input'
                      value={values.owner_last_name || ''}
                      name='owner_last_name'
                      onChange={handleChange}
                      autoComplete='one-time-code'
                    />
                    <FormError message={errors.owner_last_name}/>
                  </div>
                </div>
              </div>
              {/* // owner nif */}
              <div className='flex items-center w-full space-x-2'>
                <label
                  className='w-48 text-[14px] text-texto text-right'
                  htmlFor='owner_cif'
                >
                  {t('Owner_CIF')}
                </label>
                <div className='w-full flex flex-col mb-1'>
                  <input
                    type='text'
                    placeholder={t('Owner_CIF')}
                    className='yumminn-input'
                    value={values.owner_cif || ''}
                    name='owner_cif'
                    onChange={handleChange}
                    autoComplete='one-time-code'
                  />
                  <FormError message={errors.owner_cif}/>
                </div>
              </div>
              <div className='flex items-center w-full space-x-2'>
                <label
                  className='w-48 text-[14px] text-texto text-right'
                  htmlFor='email'
                >
                  {t('Owner_email')}
                </label>
                <div className='w-full flex flex-col mb-1'>
                  <input
                    type='email'
                    placeholder={t('Owner_email')}
                    className='yumminn-input'
                    value={values.email || ''}
                    name='email'
                    onChange={handleChange}
                    autoComplete='one-time-code'
                  />
                  <FormError message={errors.owner_email}/>
                </div>
              </div>
              {/* // owner birthdate */}
              <div className='flex items-center w-full space-x-2'>
                <label
                  className='w-48 text-[14px] text-texto text-right'
                  htmlFor='owner_birthdate'
                >
                  {t('Owner_birthdate')}
                </label>
                <div className='w-full flex flex-col mb-1'>
                  <input
                    type='date'
                    placeholder={t('Owner_birthdate')}
                    className='yumminn-input'
                    value={values.owner_birthdate || ''}
                    name='owner_birthdate'
                    onChange={handleChange}
                    autoComplete='one-time-code'
                  />
                  <FormError message={errors.owner_birthdate}/>
                </div>
              </div>
            </div>
          )}
          {/* END col2 */}

          {/* bank related fields common to all types */}
          {/* // company iban */}
          <div className='flex items-center w-full space-x-2 mt-5'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='iban'
            >
              {t('Bank_account_IBAN')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Bank_account_IBAN')}
                className='yumminn-input'
                value={values.iban || ''}
                name='iban'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.iban}/>
            </div>
          </div>
          {/* // company iban */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='owner_name'
            >
              {t('Owner_Name')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Owner_Name')}
                className='yumminn-input'
                value={values.owner_name || ''}
                name='owner_name'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.owner_name}/>
            </div>
          </div>
          {/* // adyen_account_holder_code */}
          {/* <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='adyen_account_holder_code'
            >
              {t('Adyen_account_holder_code')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Adyen_account_holder_code')}
                className='yumminn-input'
                value={values.adyen_account_holder_code || ''}
                name='adyen_account_holder_code'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.adyen_account_holder_code}/>
            </div>
          </div> */}
        </div>
      </div>
      <div className='self-end my-5 w-full'>
        <XButton
          className='btn btn-primary rounded-full px-12'
          type='button'
          doClick={handleSubmit}
        >
          {t('save')}
        </XButton>
      </div>
    </form>
  );
}
