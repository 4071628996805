import { snakeToCamel } from 'logic/functions';
import createDataContext from './createDataContext';
import { lockerSorter } from 'pages/admin/pages/Lockers/functions';

const INITIAL_STATE = {
  lockers: null,
  orders: null,
  shift: null,
};

const ACTIONS = {
  SET_STATE: 'SET_STATE',
  EDIT_LOCKER: 'EDIT_LOCKER',
  DELETE_LOCKER: 'DELETE_LOCKER',
  MOVE_ORDER: 'MOVE_ORDER',
  DELETE_ORDER: 'DELETE_ORDER',
  CLEAR_LOCKERS: 'CLEAR_LOCKERS'
};

const actions = Object.values(ACTIONS).reduce((actions, type) => {
  actions[snakeToCamel(type)] = (dispatch) => {
    return(payload) => {
      dispatch({ type, payload });
    };
  };

  return actions;
}, {});

const removeOrderFromList = (orderId, list) => {
  const newOrders = [...list];
  let order;

  const orderIndex = newOrders.findIndex(order => order.id === orderId);

  if (orderIndex !== -1) {
    order = newOrders.splice(orderIndex, 1)[0];
  };

  return { order, newOrders };
};

const removeOrderFromLocker = (orderId, lockers) => {
  const newLockers = [...lockers];
  let order;

  const fromLockerIndex = newLockers.findIndex(
    locker => locker.counter
      ? locker.order_list.some(order => order.id === orderId)
      : locker.order?.id === orderId
  );

  if (fromLockerIndex !== -1) {
    if (newLockers[fromLockerIndex].counter) {
      const orderIndex = newLockers[fromLockerIndex].order_list.findIndex(order => order.id === order);
      order = newLockers[fromLockerIndex].order_list.splice(orderIndex, 1)[0];
    } else {
      order = newLockers[fromLockerIndex].order;
      newLockers[fromLockerIndex].order = null;
    };
  };
  
  return { order, newLockers };
};

const reducer = (state, action) => {
  const actionHandlers = {
    [ACTIONS.SET_STATE]: () => ({ ...state, ...action.payload }),
    [ACTIONS.EDIT_LOCKER]: () => {
      const locker = action.payload;
      if (!action.payload.id) {
        console.error(new Error('editLocker error: No id provided'));
        return;
      };

      const newLockers = [...state.lockers];
      const lockerIndex = newLockers.findIndex(locker => locker.id === action.payload.id);

      if (lockerIndex === -1) {
        if (locker.counter) locker.order_list = [];
        newLockers.push(locker);
      } else {
        for (const key in locker) {
          newLockers[lockerIndex][key] = locker[key]
        };
      };

      newLockers.sort(lockerSorter);

      return {
        ...state,
        lockers: newLockers,
      };
    },
    [ACTIONS.DELETE_LOCKER]: () => {
      const newLockers = [...state.lockers];
      const lockerIndex = newLockers.findIndex(locker => locker.id === action.payload);

      lockerIndex !== 1 && newLockers.splice(lockerIndex, 1);

      return {
        ...state,
        lockers: newLockers,
      }
    },
    [ACTIONS.MOVE_ORDER]: () => {
      const { order: orderFromList, newOrders } = removeOrderFromList(action.payload.order, state.orders);
      const { order: orderFromLocker, newLockers } = removeOrderFromLocker(action.payload.order, state.lockers);
      const toLockerIndex = newLockers.findIndex(locker => locker.id === action.payload.locker);

      const order = orderFromList || orderFromLocker;

      order.modified_at = action.payload.modified_at;

      if (newLockers[toLockerIndex].counter) {
        newLockers[toLockerIndex].order_list.push(order);
      } else {
        newLockers[toLockerIndex].order = order;
      };

      return {
        ...state,
        lockers: newLockers,
        orders: newOrders,
      };
    },
    [ACTIONS.DELETE_ORDER]: () => {
      const { order, newLockers } = removeOrderFromLocker(action.payload.order, state.lockers);

      let newOrders;

      if (action.payload.closed) {
        newOrders = removeOrderFromList(action.payload.order, state.orders).newOrders;
      } else {
        newOrders = [...state.orders, order];
      }

      return {
        ...state,
        lockers: newLockers,
        orders: newOrders,
      };
    },
    [ACTIONS.CLEAR_LOCKERS]: () => {
      const newLockers = [...state.lockers];

      newLockers.forEach(locker => {
        if (locker.counter) {
          locker.order_list = [];
        } else {
          locker.order = null;
        }
      });

      return {
        ...state,
        lockers: newLockers,
      };
    },
  };
  
  return actionHandlers[action.type]?.() || state;
};

export const { Context: LockersContext, Provider: LockersProvider } = createDataContext(reducer, actions, INITIAL_STATE);