import React, { useState }from 'react'
import DiscountsTable from '../components/DiscountsTable'
import { Context as AuthContext } from "../../../data/authContext";
import { DiscountModal } from '../components/DiscountModal';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { CustomWarning } from '../components/CustomWarning';
import { useDiscounts } from './useDiscounts';
import { notify } from '../components/notify';

const DiscountsPage = () => {
  const {
    state: { selectedRestaurantId },
  } = React.useContext(AuthContext);

  const {
    discounts,
    catalog,
    activeGeneralDiscount,
    activeProductDiscount,
    testTableUrl,
    isValidating,
    loadingCatalog,
    mutate,
  } = useDiscounts({selectedRestaurantId});

  const [discountToEdit, setDiscountToEdit] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);

  const updateDiscount = async (discount, toastContent) => {
    const updatedDiscounts = [...discounts];
    const discountIndex = updatedDiscounts.findIndex(d => d.id === discount.id && d.typology === discount.typology);

    if (discountIndex !== -1) {
      updatedDiscounts[discountIndex] = discount;
    } else {
      updatedDiscounts.push(discount);
    }

    await mutate(prev => ({
      ...prev,
      discounts: updatedDiscounts,
    }));
    toastContent && notify(toastContent);
  };

  return (
    <div className='p-8'>
      <div className=' flex flex-row gap-3 mb-9'>
        <Button
          size={BUTTON_SIZES.LG}
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => setDiscountToEdit({restaurant: selectedRestaurantId})}
          label='add_discount'
          leftIcon={<Icon type={IconType.ADD}/>}
          disabled={isValidating}
        />
      </div>
      <DiscountsTable
        setDiscountToEdit={setDiscountToEdit}
        loading={isValidating || loadingCatalog}
        discounts={discounts}
        mutate={mutate}
        catalog={catalog}
        activeGeneralDiscount={activeGeneralDiscount}
        activeProductDiscount={activeProductDiscount}
      />
      {discountToEdit &&
        <DiscountModal
          isOpen={!!discountToEdit}
          discount={discountToEdit}
          catalog={catalog}
          testTableUrl={testTableUrl}
          selectedRestaurantId={selectedRestaurantId}
          activeGeneralDiscount={activeGeneralDiscount}
          activeProductDiscount={activeProductDiscount}
          updateDiscount={updateDiscount}
          close={() => setDiscountToEdit(null)}
          setOpenWarning={setOpenWarning}
        />
      }
      <CustomWarning isOpen={openWarning} close={() => setOpenWarning(false)}/>
    </div>
  )
}

export default DiscountsPage