import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Loading from "common/Loading";
import EmptyState from "../../../common/EmptyState.jsx";
import { DiscountEmptyStateIcon } from "common/EmptyStateIcons";
import { DeleteDiscountPopup } from "../components/DeleteDiscountPopup";
import { toast } from "react-toastify";
import { DiscountTableContainer } from "./DiscountContainers";
import { DiscountRow } from "./DiscountRow";

const DiscountsTable = ({
  setDiscountToEdit,
  loading,
  discounts,
  mutate,
  catalog,
  activeGeneralDiscount,
  activeProductDiscount,
}) => {
  const { t } = useTranslation();

  const [discountToDelete, setDiscountToDelete] = useState(null);
  const [productNames, setProductNames] = useState({});

  useEffect(() => {
    if(discounts){
      discounts.forEach(discount => {
        if (discount.typology === 'product_discount' && !productNames[discount.products__id]) {
          getDiscountNames(discount.products__id);
        }
      });
    }
  }, [discounts, catalog]);

  const updateDiscount = discount => {
    const newDiscounts = [...discounts];

    const discountIndex = newDiscounts.findIndex(
      d => d.id === discount.id && d.typology === discount.typology
    );

    if (discountIndex !== -1) {
      newDiscounts[discountIndex] = discount;
    } else {
      newDiscounts.push(discount)
    }
    
    mutate(prev => ({
      ...prev,
      discounts: newDiscounts,
    }));
  };

  const getDiscountNames = async (productIds) => {
    const products = [];

    const productList = catalog?.product_categories?.map(cat =>
      cat?.products?.map(prod => ({id: prod.id, name: prod.visible_name || prod.name || prod.tpv_name}))
    ).flat();

    if (!Array.isArray(productIds)) {
      const product = productList?.find(prod => prod?.id === productIds);

      if (product) {
        products.push(product);
      }
    } else {
      productIds.forEach(productId => {
        const product = productList?.find(prod => prod?.id === productId);
        if (product) {
          products.push(product);
        }
      });
    }

    const visibleNames = products.map(prod => prod.name);
    const visibleNamesString = visibleNames.join(', ');

    setProductNames((prev) => ({ ...prev, [productIds]: visibleNamesString }));
    return visibleNamesString;
  };

  const deleteDiscount = async (discount) =>{
    const endpoint = `/discount/delete/${discount.typology}/${discount.id}/`;
    await baseAuth
    .delete(endpoint)
    .then(_ => {
      const newDiscounts = [...discounts];
      const index = newDiscounts.findIndex(d => d.id === discount.id && d.typology === discount.typology);

      index !== -1 && newDiscounts.splice(index, 1);

      setDiscountToDelete(false);
      mutate(prev => ({
        ...prev,
        discounts: newDiscounts,
      }));
      toast.success(t('discount_deleted'));
  })
    .catch(e => console.log(`🚀 ~ file: DiscountsTable.jsx:deleteDiscount ~ await baseAuth.get('${endpoint}') ~ e:`, e));
  }

  console.log(`🐉 > file: DiscountsTable.jsx:93 > DiscountsTable > discounts:`, discounts)
  if (loading && !discounts.length) {
    return (<Loading />);
  }

  return (
    <DiscountTableContainer>
      <table>
        <thead>
          <tr>
            <th className='col_discount'>
              {t("discount")}
            </th>
            <th className='col_typology'>
              {t("typology")}
            </th>
            <th className='col_value'>
              {t("discount_value")}
            </th>
            <th className='col_applied'>
              {t("Applied in")}
            </th>
            <th className='col_visibility'>
              {t("visibility_and_schedules")}
            </th>
            <th className='col_limits'>
              {t("use_limits")}
            </th>
            <th colSpan={3}/>
          </tr>
        </thead>
          {discounts && discounts.length > 0 && Array.isArray(discounts) && discounts.map((discount) => {
            return (
              <DiscountRow
                key={discount.typology + "_" + discount.id}
                discount={discount}
                productNames={productNames}
                loading={loading}
                activeGeneralDiscount={activeGeneralDiscount}
                activeProductDiscount={activeProductDiscount}
                setDiscountToEdit={setDiscountToEdit}
                updateDiscount={updateDiscount}
                setDiscountToDelete={setDiscountToDelete}
              />
            );
          })}
          {!discounts?.length &&
            <tbody>
              <tr className="h-12 w-full bg-[#FCFCFC]">
                <td colSpan='6'>
                  <EmptyState text={t('create_discounts_in_your_menu')} icon={<DiscountEmptyStateIcon/>} />
                </td>
              </tr>
            </tbody>
          }
      </table>
      <DeleteDiscountPopup isOpen={!!discountToDelete} confirm={()=> deleteDiscount(discountToDelete)} cancel={() => setDiscountToDelete(false)} />
    </DiscountTableContainer>
  );
};

export default DiscountsTable;