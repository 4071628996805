import * as React from 'react';

function NewFeaturesIcon({icon, color}) {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle width="31" height="31" cx="15.4937" cy="15.4937" r="15.4937" fill={color} />
          <path d="M7.74683 21.6339H23.2406V6.14014H7.74683V21.6339Z" fill="url(#pattern0)" />
          <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="16" fill="#000">
            {icon}
          </text>
        </svg>
      );
}

export default NewFeaturesIcon;
