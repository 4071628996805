import { UsersOverview } from 'pages/home/components/overview/components';
import React from 'react';
import { CashbackOverview, PointsOverview, RestaurantsOverview } from './components';

export const AdminLoyaltyOverview = ({isLoading}) => {
  return <></> // Still in progress

  return (
    <div className={`flex flex-wrap justify-between gap-5 mx-12 my-6 items-start ${isLoading ? 'opacity-25' : 'opacity-100'}`}>
      <RestaurantsOverview/>
      <CashbackOverview/>
      <UsersOverview/>
      <PointsOverview/>
    </div>
  );
};
