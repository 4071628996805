const compareFn = (a, b) => {
    if (a.order < b.order) {
        return -1;
    }
    if (a.order > b.order) {
        return 1;
    }
    return 0;
}


const addSchedules = (catalog_data_target, schedules) => {
    for (const modelId in schedules) {
        if (schedules.hasOwnProperty(modelId)) {
            const catalogDataTargetSchedule = schedules[modelId];

            // Find the matching catalog target by ID
            const matchingCatalogDataTarget = catalog_data_target?.find(catalog_target => catalog_target.id === parseInt(modelId));

            if (matchingCatalogDataTarget) {
                // Check if "visibility_schedules" property exists and initialize it as an empty list if not
                if (!matchingCatalogDataTarget.visibility_schedules) {
                    matchingCatalogDataTarget.visibility_schedules = [];
                }

                // Iterate over each schedule in catalogDataTargetSchedule
                for (const scheduleId in catalogDataTargetSchedule) {
                    if (catalogDataTargetSchedule.hasOwnProperty(scheduleId)) {
                        const scheduleData = catalogDataTargetSchedule[scheduleId];
                        scheduleData.id = parseInt(scheduleId);

                        // Push the scheduleData to the list
                        matchingCatalogDataTarget.visibility_schedules.push(scheduleData);
                    }
                }
            }
        }
    }
    return catalog_data_target;
}
export { compareFn, addSchedules}
