import React from 'react';
import { useTranslation } from "react-i18next";
import { PencilIcon } from '@heroicons/react/outline';
import styled from 'styled-components';

const BookingActionButton = ({text, ...buttonProps}) => {

  return (
    <ButtonContainer {...buttonProps}>
      {text}
    </ButtonContainer>
  );
};
export default BookingActionButton;

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  width: 100%;
  color: #FFFFFF;
  border-radius: 99px;
`;