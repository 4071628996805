import React from 'react';
import MenuContainer from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from "react-i18next";

const AdminContainer = ({ children }) => {

  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();
  const path = history.location.pathname;
  const {t} = useTranslation();

  const title = path.includes('restaurants') ? 'restaurants' : path.includes('companies') ? 'Companies' : '';

  return (
    <div id="adminContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex: ' 0 1 auto', position: 'fixed', overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        {title &&
          <div className={`text-[#094553] bg-[#E2FEF1] py-5 w-full px-8 flex flex-row items-center gap-3`}>
            <h1 className="text-[24px] font-bold mr-5 whitespace-nowrap">{t(title)}</h1>
          </div>
        }
        <div className='flex flex-col h-full max-w-full'>{children}</div>
      </div>
    </div>
  );
};

export default AdminContainer;
