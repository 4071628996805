import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Counter } from 'common/Counter';
import { Checkbox } from 'common/Input';
import { InfoTooltip } from 'common/InfoTooltip';

export const PauseCard = ({ saturationDraft, editSaturationDraft }) => {
  const { t } = useTranslation();

  const addDuration = () => editSaturationDraft({duration_minutes: saturationDraft.duration_minutes + 5});
  const subtractDuration = () => editSaturationDraft({duration_minutes: Math.max(saturationDraft.duration_minutes - 5, 5)});
  const toggleUndefined = () => editSaturationDraft({is_duration_undefined: !saturationDraft.is_duration_undefined});

  return (
    <div
      className={saturationDraft.type === 'pause' ? 'selected' : ''}
      onClick={() => editSaturationDraft({type: 'pause'})}
    >
      <i>⏸</i>
      <h5>
        <Trans i18nKey='pause_orders'>
          <b>Pausar</b> pedidos
        </Trans>
      </h5>
      <p>
        <Trans i18nKey='how_long_pause'>
          ¿<b>Durante cuánto tiempo</b> quieres pausar los pedidos?
        </Trans>
      </p>
      <Counter
        count={saturationDraft.duration_minutes}
        onAdd={addDuration}
        onSubtract={subtractDuration}
        disabled={saturationDraft.is_duration_undefined}
        min={5}
      />
      <span>{t('minutes')}</span>
      <div className='check'>
        <Checkbox checked={saturationDraft.is_duration_undefined} onChange={toggleUndefined} label='undefined'/>
        <InfoTooltip info='info_pause_undefined'/>
      </div>
      <p>{t('orders_paused')}</p>
    </div>
  );
};
