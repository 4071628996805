import React,{useState, useRef, useEffect} from 'react'
import { MobileMockup } from "components/previews";
import Select, { components } from 'react-select';
import { categories_select_styles } from 'logic/defaults';
import StepperInput from 'common/StepperInput';
import { OrderSchedulePreview } from 'components/previews/screens/OrderSchedulePreview';

const getBeautyNumber = (number) => {
  return number.toLocaleString('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false
  })
}

export const ShiftSettings = ({shiftDraft, changeName, changeStartMinute, changeSlotDuration, changeSlotsQuantity, changeDishesInParallel, setStep}) => {  
  const minutesInDay = 1440
  const minutesStep = 30
  const minutesIter = minutesInDay / minutesStep
  const selectorOptions = []
  for (let i= 0; i <= minutesIter; i++){
    const optionMinute = minutesStep * i
    const optionLabel = `${getBeautyNumber(Math.trunc(optionMinute / 60))}:${getBeautyNumber(optionMinute - (Math.trunc(optionMinute / 60) * 60))}`
    selectorOptions.push({"label": optionLabel, "value": optionMinute})
  }

  const getEndTime = () => {
    const endMinute = shiftDraft.start_minute + (shiftDraft.slot_duration_minutes * shiftDraft.slots_quantity)
    return `${getBeautyNumber(Math.trunc(endMinute / 60))}:${getBeautyNumber(endMinute - (Math.trunc(endMinute / 60) * 60))}`
  }

  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%', userSelect: 'none'}}>
      <div style={{width: '30%', height: '100%', marginLeft: "5%"}}>
        <MobileMockup>
          <OrderSchedulePreview shift={shiftDraft} />
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{backgroundColor: "#FFF2D0", fontWeight: 600, borderRadius: "10px", padding: "5px 10px"}}>Los cambios realizados en este turno se aplicarán a partir de la próxima jornada.</div>
          <div style={{fontSize: '14px', fontWeight: 500, marginTop: "15px"}}>{"Nombre (Interno)"}</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <input
                type='text'
                placeholder='Ej: Turno de mañana'
                className='yumminn-input'
                value={shiftDraft.name}
                name='shift_name'
                style={{width: '10ch'}}
                maxLength={50}
                onChange={(e) => changeName(e.target.value)}
              />
          </div>
          {shiftDraft.name.length < 1 &&
          <div style={{fontSize: '13px', marginTop: '5px'}}>⚠️ Debes añadir un nombre</div>}
          <div style={{display: 'grid', width: '100%', gridTemplateColumns: "1fr 1fr", marginTop: '10px'}}>
            <div>
              <div style={{fontSize: '14px', fontWeight: 500}}>Hora de apertura</div>
                <div style={{width: "70%", marginTop: "5px"}}>
                  <Select
                    isMulti={false}
                    placeholder={"Inicio"}
                    id={'children'}
                    value={selectorOptions.find(op => op.value == shiftDraft.start_minute)}
                    options={selectorOptions}
                    styles={categories_select_styles}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      changeStartMinute(e.value);
                    }}
                    isClearable={false}
                  />
                </div>
              </div>
            <div>
              <div style={{fontSize: '14px', fontWeight: 500}}>Hora de cierre</div>
              <div style={{width: "70%", marginTop: "5px"}}>      
                <div style={{display: "flex", justifyContent: "space-between", border: "2px solid #D4D4D8", borderRadius: "8px", height: "40px", alignItems: 'center', padding: "2px 8px", backgroundColor: "#F7F7F7"}}>
                  <div style={{marginLeft: "5px"}}>
                      {getEndTime()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{display: 'grid', width: '100%', gridTemplateColumns: "1fr 1fr", marginTop: '10px'}}>
            <div>
              <div style={{fontSize: '14px', fontWeight: 500}}>Duración de los slots</div>
                <div style={{width: "70%", marginTop: "5px"}}>
                  <StepperInput value={shiftDraft.slot_duration_minutes} changeValueFunc={changeSlotDuration} disabledDown={shiftDraft.slot_duration_minutes <= 10} step={5}/>
                </div>
              </div>
            <div>
              <div style={{fontSize: '14px', fontWeight: 500}}>Cantidad de slots</div>
              <div style={{width: "70%", marginTop: "5px"}}>
                <StepperInput value={shiftDraft.slots_quantity} changeValueFunc={changeSlotsQuantity} disabledDown={shiftDraft.slots_quantity < 2}/>
              </div>
            </div>
          </div>
          <div style={{marginTop: '10px'}}>
            <div style={{fontSize: '14px', fontWeight: 500}}>Cantidad de platos por slot</div>
            <div style={{width: "100%", marginTop: "5px", display: 'flex', gap: "10px", alignItems: 'center'}}>
              <div style={{width: "40%"}}>
                <StepperInput value={shiftDraft.dishes_in_parallel} changeValueFunc={changeDishesInParallel} disabledDown={shiftDraft.dishes_in_parallel < 2}/>
              </div>
              <div>
                <button
                  style={{width: '150px', backgroundColor: '#094553'}}
                  className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
                  onClick={() => setStep(2)}>Configurar platos</button>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  )
}
