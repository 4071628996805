import "./DateSelect.css";
import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Context as AuthContext } from '../../data/authContext';
import i18n from "../../i18n"
import {useMediaQuery} from '../../hooks/useMediaQuery';
import calendar from "../../img/calendar.png"; 
import * as dayjs from 'dayjs';
import { useHistory } from "react-router-dom";

export default function DateSelect() {
  const { t } = useTranslation();
  const {
    state: { selectedDate, selectedDateTo },
    setSelectedDate,
    setSelectedDateTo,
  } = React.useContext(AuthContext);
  const [seleccionado, setSeleccionado] = React.useState(null);
  const [seleccionadoTo, setSeleccionadoTo] = React.useState(null);
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const history = useHistory();
  const location = history.location.pathname;

  React.useEffect(() => {
    let seleccionado = JSON.parse(sessionStorage.getItem('yumminn-selectedDate'));
    let seleccionadoTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'));
    let day = seleccionado ? parseInt(seleccionado.substring(8,10)) : null
    let dayTo = seleccionadoTo ? parseInt(seleccionadoTo.substring(8,10)) : new Date().getDate() - 1
    let month =  seleccionado ? parseInt(seleccionado.substring(5,7)) : new Date().getMonth() + 1
    let monthTo = seleccionadoTo ? parseInt(seleccionadoTo.substring(5,7)) : new Date().getMonth() + 1

    if (period === 'days' && day > dayTo && month >= monthTo){
      sessionStorage.removeItem('yumminn-selectedDate')
      sessionStorage.removeItem('yumminn-selectedDateTo')
    } else {
      seleccionado && setSeleccionado(seleccionado)
    } 
  }, [selectedDate, seleccionado, seleccionadoTo, period]);

  React.useEffect(() => {
    let seleccionadoTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'));
    setSeleccionadoTo(seleccionadoTo);
  }, [selectedDateTo, seleccionadoTo]);

  React.useEffect(() => {
    const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
    if (period === 'days'){
      setSeleccionado(dayjs().subtract(8, 'day').format('YYYY-MM-DD'))
      setSeleccionadoTo(yesterday)
    } else {
      setSeleccionado(dayjs().format('YYYY-MM-DD'))
    }
  }, [period])


  const handleSelect = (opcion) => {
    const offset = opcion.getTimezoneOffset();
    const date = new Date(opcion.getTime() - (offset*60*1000))
    setSelectedDate(date);
    setSeleccionado(date);
  };

  const handleSelectTo = (opcion) => {
    const offset = opcion.getTimezoneOffset();
    const dateTo = new Date(opcion.getTime() - (offset*60*1000))
    setSelectedDateTo(dateTo);
    setSeleccionadoTo(dateTo)
  }


  const setLanguage = () => {
    const language = i18n.language; 

    registerLocale("es", es);
    registerLocale("en", en);
    registerLocale("de", de);
    registerLocale("fr", fr);
  
    switch (language) {
      case "es":
        setDefaultLocale("es");
        break;
  
      case "en":
        setDefaultLocale("en");
        break;
  
      case "de":
        setDefaultLocale("de");
  
        break;
  
      case "fr":
        setDefaultLocale("fr");
        break;
  
      default:
        return;
    }
    return language;
  };

  const language = setLanguage();
  const yesterday = new Date(new Date().getTime() - 24*60*60*1000);


  return (
    <div className={`flex items-center my-3 z-0 ${isRowBased && 'justify-center ml-14'}`} style={{position: 'relative', fontSize: isRowBased ? "14px" : "16px", fontFamily: 'Poppins, sans-serif', fontWeight: 500}}>
    <div></div>
      {period === 'days' && <div className={location === '/loyalty' || location === '/loyalty/programs' || location === '/loyalty/mails' ? 'hidden' : ''}>{t('From')}</div>}
      <DatePicker
        portalId='root-portal'
        locale={language}
        selected={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : new Date()}
        onChange={(a) => {
          handleSelect(a);
        }}
        dateFormat="dd/MM/yyyy"
        style={{border: '2px solid hsl(0, 0%, 80%', zIndex: 9999}}
        placeholderText={t("from")}
        maxDate={new Date()}
      />
        {!isRowBased && <label style={{position: 'absolute', left: period === 'days' ? location === '/loyalty' || location === '/loyalty/mails' || location === '/loyalty/programs' ? '126px' : "145px" : "126px", pointerEvents: 'none'}}>
          <img src={calendar} alt='' style={{width: "24px", height: '24px'}}/>
        </label>}
      {period === 'days' &&
      <>
        {period === 'days' && <div className={location === '/loyalty' || location === '/loyalty/programs' || location === '/loyalty/mails' ? 'hidden' : ''}>{t('to')}</div>}
        <DatePicker
          portalId='root-portal'
          locale={language}
          selected={typeof seleccionadoTo === 'string' ? new Date(seleccionadoTo) : seleccionadoTo ? seleccionadoTo : yesterday}
          onChange={(a) => {
            handleSelectTo(a);
          }}
          dateFormat="dd/MM/yyyy"
          style={{border: '2px solid hsl(0, 0%, 80%', zIndex: 9999}}
          placeholderText={t("to")}
          minDate={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : yesterday}
          maxDate={new Date()}
        />
        {!isRowBased && <label style={{position: 'absolute', left: ['/loyalty', '/loyalty/mails', '/loyalty/programs', '/admin/dashboard'].includes(location) ? '310px' : "340px", pointerEvents: 'none'}}>
          <img src={calendar} alt='' style={{width: "24px", height: '24px'}}/>
        </label>}
      </>
      }
    </div>
  );
}