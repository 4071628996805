import { Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { Popup } from 'common/Popup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const AiTranslationsDonePopup = ({confirm}) => {
  const { t } = useTranslation();

  return (
    <Popup isOpen={true} close={confirm}>
      <Container>
        <Icon
            type={IconType.AI} 
            color='#094553'
            size={30}
        />
        <h2>{t('translations done!')}</h2>
        <p>{t('translations_done_message')}</p>
        <Button
          label={t('Ok')}
          onClick={confirm}
        />
      </Container>
    </Popup>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    max-width: 50ch;
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    max-width: 50ch;
  }

  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }
`;