import React,{useState, useRef, useEffect} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import green_check from "../../../../img/green_check.svg";
import { TrashIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { InfoLabel } from 'components/yumminn/InfoLabel';
import { getContrastRatio } from 'styles/colors';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { InfoTooltip } from 'common/InfoTooltip';


const MIN_CONTRAST = 4;
const RECOMMENDED_CONTRAST = 4.5;

const getContrastInfo = (color, color2) => {
  if (!color) return;
  
  const contrast = getContrastRatio(color, color2);

  if (contrast >= RECOMMENDED_CONTRAST) {
    return {
      type: 'info',
      message: 'contrast_good',
    };
  }

  if (contrast >= MIN_CONTRAST) {
    return {
      type: 'warning',
      message: 'contrast_minimum',
    };
  }

  return {
    type: 'error',
    message: 'contrast_bad',
  };
};

export const IndividualCustomGroupColorSettings = ({catalog, customGroupDraft, changeImage, changeColor, changeDefaultProductImage}) => {
  const [imgError, setImgError] = useState(false);
  
  const hiddenFileInput = useRef(null);
  const hiddenDefaultProductImageInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  
  const handleDefaultProductImageClick = () => {
    hiddenDefaultProductImageInput.current.click();
  };

  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '30%', height: '100%'}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>      
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '7%', paddingRight: '7%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{fontSize: '14px', fontWeight: 500, marginTop: "15px"}}>Color del título y descripción</div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '10px'}}>
            <label style={{fontSize: '14px', userSelect: 'none'}} className='flex justify-between items-center gap-2 p-2 w-32 bg-white border-2 border-gray-300 rounded-md cursor-pointer'>
              {customGroupDraft.title_color?.toUpperCase()}
              <span className='w-5 h-5 shadow-md' style={{backgroundColor: customGroupDraft.title_color}}/>
              <input
              type='color'
              default_value={customGroupDraft.title_color}
              onInput={(e) => changeColor(e.target.value, null, null)}
              className='absolute opacity-0 w-0 pointer-events-none'
              />
            </label>        
            <div style={{marginLeft: '10px'}}>
              <InfoLabel {...getContrastInfo(customGroupDraft.title_color, customGroupDraft.primary_color)} isVisible={true}/>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
            <div style={{ width: '45%'}}>
              <div style={{fontSize: '14px', fontWeight: 500, marginBottom: '5px'}}>Color de fondo</div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <label style={{fontSize: '14px', userSelect: 'none'}} className='flex justify-between items-center gap-2 p-2 w-32 bg-white border-2 border-gray-300 rounded-md cursor-pointer'>
                  {customGroupDraft.primary_color?.toUpperCase()}
                  <span className='w-5 h-5 shadow-md' style={{backgroundColor: customGroupDraft.primary_color}}/>
                  <input
                  type='color'
                  default_value={customGroupDraft.primary_color}
                  onInput={(e) => changeColor(null, e.target.value, e.target.value)}
                  className='absolute opacity-0 w-0 pointer-events-none'
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <h4 style={{fontWeight: 500,  marginBottom: '5px', lineHeight: 1.5, display: 'flex', alignItems: 'center', gap: '8px'}}>
              ¿Producto sin foto?
              <InfoTooltip info='La imagen por defecto se aplica solo en aquellos productos que no tienen cargada una foto, para darles mayor visibilidad. Formato png, jpg o gif. Calidad 2,5MB máx.'/>
            </h4>
            <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px'}}>
              <button
                onClick={handleDefaultProductImageClick}
                className={`rounded-full cursor-pointer py-2 px-4 text-xs text-white font-bold flex gap-1 items-center h-[32px] ${customGroupDraft.default_product_image ? 'bg-[#094553]' : 'bg-principal'}`}
              >
                {!customGroupDraft.default_product_image &&
                  <PlusCircleIcon color='#FFFFFF80' height={18}/>
                }
                {customGroupDraft.default_product_image ? 'Modificar imagen' : 'Añadir imagen por defecto'}
              </button>
              {customGroupDraft.default_product_image &&
                <div
                  style={{display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer'}}
                  onClick={() => changeDefaultProductImage(null)}
                >
                  <TrashIcon className={`w-[20px] text-gray-600`}/>
                  Eliminar
                </div>
              }
            </div>
            <input accept="image/png, image/jpg" ref={hiddenDefaultProductImageInput} type="file" style={{display:'none'}} name="default_product_image" onChange={(event) => {changeDefaultProductImage(event.target.files[0]); event.target.value=""}}/>
          </div>
          <div style={{fontSize: '14px', fontWeight: 500, marginTop: '10px'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
              <button
                className={"rounded-full cursor-pointer py-2 px-4 bg-principal text-xs text-white font-bold flex gap-1 items-center h-[32px]"}
                onClick={handleClick}
              >
                {!customGroupDraft.image &&
                  <PlusCircleIcon color='#FFFFFF80' height={20}/>
                }
                {customGroupDraft.image ? "Modificar logo" : "Añadir logo"}
              </button>
              <InfoTooltip info='No es obligatorio subir un logo. Utiliza png sin fondo para un mejor acabado.'/>
              {customGroupDraft.image && 
                <div
                  style={{display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer'}}
                  onClick={() => changeImage(null)}
                >
                  <TrashIcon className={`w-[20px] text-gray-600`}/>
                  Eliminar
                </div>
              }
            </div>
            <input accept="image/png, image/jpg" ref={hiddenFileInput} type="file" style={{display:'none'}} name="myImage" onChange={(event) => {setImgError(false); changeImage(event.target.files[0]); event.target.value=""}}/>
            {customGroupDraft.image && !imgError &&
              <img src={typeof customGroupDraft.image == "string" ? customGroupDraft.image : URL.createObjectURL(customGroupDraft.image)} onError={() => {setImgError(true); changeImage(null)}} alt='' style={{width: '0px'}} />
            }
            {imgError && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', color: '#EF1E1E', gap: '5px'}}>
              <ExclamationCircleIcon style={{color: '#EF1E1E'}} className={`w-[20px]`}/>
              Error al subir la imagen
            </div>}
            {customGroupDraft.image && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', color: '#5FB894', gap: '5px'}}>
              <img src={green_check} style={{width: '20px'}} />
              Imagen subida correctamente
            </div>}
          </div>
        </div>
      </div>  
    </div>
  )
}
