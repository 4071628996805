import React from 'react';
import styled from 'styled-components';

const BACKDROP_ID = 'popup_backdrop';

export const Popup = ({isOpen, close, children, className}) => {
  const onBackdropClick = (event) => {
    event.stopPropagation();
    event.target.id === BACKDROP_ID && close?.();
  };

  if (!isOpen) return <></>

  return (
    <PopupContainer id={BACKDROP_ID} onClick={onBackdropClick} className={className}>
      <div>
        {children}
      </div>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  z-index: 9999;
  background-color: #76767666;
  padding: 24px;

  & > div {
    background-color: #FFFFFF;
    border-radius: 32px;
    padding: 32px 40px;
    margin: auto;
    width: fit-content;
    box-shadow: 0 4px 8px #76767640;
    max-width: 100%;
    max-height: 100%;
  }
`;