import { ProductPopupMockup } from 'components/previews/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AllergensAndTagsContainer } from './Modals/AllergensAndTagsContainers';
import { TAG_LIST, tagsIcons } from 'data/tags';
import { allergensIcons, allergensList } from 'data/allergens';
import { SelectInput } from 'common/Input';
import styled from 'styled-components';
import { components }  from 'react-select';

const VEGGIE = [1, 2];
const SPICY = [6, 7, 8];

const toggleGroupTags = (tags, tag, group) => {
  group.forEach(gTag => {
    const index = tags.indexOf(gTag);

    if (index !== -1) {
      tags.splice(index, 1)
    }
  });

  tags.push(tag);
};

export const AllergensAndTags = ({ draft, editDraft }) => {
  const { t } = useTranslation();

  const handleAllergensSelect = options => {
    editDraft({ allergens: options.map(option => option.value) });
  };

  const handleTagsSelect = tag => {
    const tags = [...draft.tags];
    const index = tags.indexOf(tag);

    if (index !== -1) {
      tags.splice(index, 1);
    } else if (VEGGIE.includes(tag)) {
      toggleGroupTags(tags, tag, VEGGIE);
    } else if (SPICY.includes(tag)) {
      toggleGroupTags(tags, tag, SPICY);
    } else {
      tags.push(tag);
    };

    editDraft({ tags });
  };

  return (
    <AllergensAndTagsContainer>
      <ProductPopupMockup product={draft} />
      <div>
        <SelectInput
          label='Allergens'
          placeholder='Select one option'
          options={allergensList}
          value={draft?.allergens?.map(id => allergensList[id - 1])}
          multiple
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onChange={handleAllergensSelect}
          components={{ MultiValue, Option }}
          styles={allergen_selector_styles}
        />
        <div>
          <h6>{t('tags')}</h6>
          <p>{t('tags_explanation')}</p>
          <ul className='tags'>
            {TAG_LIST.map(tag =>
              <li
                key={tag.value}
                style={{'--color': tag.color, '--bgColor': tag.bgColor}}
                className={draft?.tags?.includes(tag.value) ? 'selected' : ''}
                onClick={() => handleTagsSelect(tag.value)}
              >
                {tagsIcons(tag.label)}
                {t(tag.label)}
              </li>
            )}
          </ul>
        </div>
      </div>
    </AllergensAndTagsContainer>
  );
};

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <AllergenContainer color={props.data.color}>
        {allergensIcons(props.data.label)}
      </AllergenContainer>
    </components.MultiValue>
  );
};

const Option = (props) => {
  const { t } = useTranslation();

  return (
    <components.Option {...props}>
      <OptionContainer color={props.data.color}>
        <AllergenContainer color={props.data.color}>
          {allergensIcons(props.data.label)}
        </AllergenContainer>
        {t(props.data.label)}
      </OptionContainer>
    </components.Option>
  );
};

export const AllergenContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  color: ${({color}) => color};
  background: #FFF;
  border-radius: 2rem;
  overflow: hidden;

  svg {
    height: 100%;
    width: 100%;
    padding: 0.375rem;
    background-color: ${({color}) => color + 40};
  }
`;

export const OptionContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  white-space: nowrap;
`;

export const allergen_selector_styles = {
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      margin: 0,
    };
  },
  multiValueRemove: styles => ({
    ...styles,
    display: 'none',
  }),
};
