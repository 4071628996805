import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSVDownload } from "react-csv";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { thousandsSeparator, centsToAmountString } from "logic/functions";
import { Context as AuthContext } from "../../../data/authContext";
import { apiAuth } from "logic/api";
import Spinner from "common/Spinner";
import * as dayjs from "dayjs";
import DateSelect from "components/yumminn/DateSelect";
import MonthSelect from "components/yumminn/MonthSelect";
import YearSelect from "components/yumminn/YearSelect";
import { DatesFilter } from "components/yumminn/DatesFilter";
import useReviewFilter from "components/yumminn/useReviewFilter";
import useRestaurant from "components/yumminn/useRestaurant";
import { order } from "tailwindcss/defaultTheme";
import useModal from 'components/hooks/useModal';
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import AccountManagerSelect from "components/yumminn/AccountManagerSelect";

export function subtractDays(date) {
  date.setDate(date.getDate() - 7);
  return date;
}

export const TopRestaurants = () => {
  const { seleccionado, start, end } = useRestaurant();
  const { activo } = useReviewFilter();
  const { t } = useTranslation();
  const infoModal = useModal();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [selectedColumn, setSelectedColumn] = useState("payments");
  const [sort, setSort] = useState("ASC");
  const [topRestaurantsData, setTopRestaurantsData] = useState();
  const [page, setPage] = useState(0);
  const [loadingTopRestaurants, setLoadingTopRestaurants] = useState(false);
  const [csvRestaurants, setCsvRestaurants] = useState([]);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [showAllRestaurants, setShowAllRestaurants] = useState(true);
  const [accountManagerSelected, setAccountManagerSelected] = useState({id: null, name: t("Todos")})
  let [counter, setCounter] = useState({});
  const { state: selectedReviewFilter, setSelectedReviewFilter } = React.useContext(AuthContext);
  const selectedDate = JSON.parse(sessionStorage.getItem("yumminn-selectedDate"));
  const date = new Date(selectedDate);
  subtractDays(date);
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const selectedDateTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))
  const endDate = selectedDateTo ? new Date(selectedDateTo) : yesterday;
  const offset = date.getTimezoneOffset();
  const dateEnd = new Date(endDate.getTime() - (offset * 60 * 1000)).toISOString().split('T')[0];
  const dateStart = new Date(date.getTime() - (offset * 60 * 1000)).toISOString().split('T')[0];
  const csvInstance = useRef(null);
  const numRestaurants = 25;
  const selectedYear = JSON.parse(sessionStorage.getItem("yumminn-selectedYear"))
  const selectedYearTo = JSON.parse(sessionStorage.getItem("yumminn-selectedYearTo"))
  const selectedMonth = JSON.parse(sessionStorage.getItem("yumminn-selectedMonth"))
  const selectedMonthTo = JSON.parse(sessionStorage.getItem("yumminn-selectedMonthTo"))
  const selectedStartTime = sessionStorage.getItem('yumminn-selectedStartTime')
  const selectedEndTime = sessionStorage.getItem('yumminn-selectedEndTime')
  

  let headers = [
    { label: "Restaurant", key: "restaurant" },
    { label: "Payments", key: "payments" },
    { label: "Payments Percentage", key: "payments_percentage" },
    { label: "Average Ticket", key: "ticket_avg" },
    { label: "Transactions", key: "transactions" },
    { label: "Transactions Percentage", key: "transactions_percentage" },
    { label: "Tips", key: "tips" },
    { label: "Tips Percentage", key: "tips_percentage" },
    { label: "Reviews", key: "reviews" },
    { label: "Reviews Percentage", key: "reviews_percentage" },
  ];

  const getTopRestaurants = async ({ allRestaurants, to_date, from_date }) => {
    let obj = {};

    //if downloading CSV
    if (allRestaurants && seleccionado) {
      obj = {
        from: from_date,
        to: to_date,
        period: activo,
        column: selectedColumn,
        order: sort,
        page: page,
        restaurant: seleccionado,
        showAllRestaurants: showAllRestaurants,
        // account_manager: accountManagerSelected
      };
    } else {
      obj = {
        from: from_date,
        to: to_date,
        period: activo,
        column: selectedColumn,
        order: sort,
        page: page,
        restaurants: numRestaurants,
        restaurant: seleccionado,
        showAllRestaurants: showAllRestaurants,
        account_manager: accountManagerSelected
      };
    }
    if (seleccionado){
      try {
        const results = await apiAuth.post(`/payments/top_restaurants`, obj);
        setLoadingTopRestaurants(false);

        if (!allRestaurants && results.data.result) {
          setTopRestaurantsData(results.data.result);
          if (sort === 'ASC'){
            let counterAsc = (numRestaurants * (page + 1) - numRestaurants)
            setCounter(counterAsc)
          }else{
            let counterLet = (results.data.result.total_restaurants - numRestaurants * (page)) + 1
            setCounter(counterLet)
          }
        } else {
          return new Promise((resolve) => {
            let data = [];

            let restaurants = results.data.result.restaurants;

            // This reformats the response data, for CSV...Example:
            // {
            //   "restaurant": "La mafia se sienta",
            //   "payments":0123,
            //   "reviews": 12,
            //   "tips":23,
            //   "transactions":123
            // }

            if (restaurants) {
              restaurants.forEach((item) => {
                let payments = [];

                payments["payments"] =
                  parseInt(centsToAmountString(item[1]["payments"])) + "€";
                payments["payments_percentage"] =
                  handleToFixed(item[1]["payments_percentage"]) + "%";
                payments["ticket_avg"] =
                  handleToFixed(
                    parseFloat(centsToAmountString(item[1]["ticket_avg"]))
                  ) + "€";
                payments["transactions"] = handleToFixed(item[1]["transactions"]);
                payments["transactions_percentage"] =
                  handleToFixed(item[1]["transactions_percentage"]) + "%";
                payments["tips"] =
                  handleToFixed(
                    parseFloat(centsToAmountString(item[1]["tips"]))
                  ) + "€";
                payments["tips_percentage"] =
                  handleToFixed(item[1]["tips_percentage"]) + "%";
                payments["reviews"] = handleToFixed(item[1]["reviews"]);
                payments["reviews_percentage"] =
                  handleToFixed(item[1]["reviews_percentage"]) + "%";
                let restaurant = {
                  restaurant: item[0],
                  ...payments,
                };
                data.push(restaurant);
              });
              
              //TOTALS for CSV
              data.push(
                {
                  'restaurant': 'TOTAL',
                  'payments': parseInt(centsToAmountString(results.data.result.all_restaurants.payments)) + '€',
                  'ticket_avg': handleToFixed(parseFloat(centsToAmountString(results.data.result.all_restaurants.avg_ticket))) + '€',
                  'reviews': handleToFixed(results.data.result.all_restaurants.reviews),
                  'tips': handleToFixed(parseFloat(centsToAmountString(results.data.result.all_restaurants.tips))) + '€',
                  'transactions': handleToFixed(results.data.result.all_restaurants.transactions),

                }
              )
            }
            setLoadingCsv(false);
            setCsvRestaurants(data);
            resolve();
          });
        }
      } catch (error) {
        setLoadingTopRestaurants(false);
        setLoadingCsv(false);
        console.log(`error`, error);
      }
    }
  };

  const getAllRestaurants = async (event, done) => {
    if (csvRestaurants.length > 0) setCsvRestaurants([]);
    setLoadingCsv(true);

    await getTopRestaurants({ allRestaurants: true, from_date: getDates().from_date, to_date: getDates().to_date });
  };

  const headerStyle = (column) => {
    if (selectedColumn === column) {
      return `h-5 w-5 transition ease-in-out delay-150 transform ${sort === "DESC" && "rotate-180"
        } text-[#00A106]`;
    }
    return "h-5 w-5 transition ease-in-out delay-150 transform";
  };

  const sortTable = (column) => {
    if (selectedColumn === column) {
      if (sort === "ASC") {
        setSort("DESC");
      } else {
        setSort("ASC");
      }
    } else {
      setSort("ASC");
    }
    setSelectedColumn(column);
  };

  const handleToFixed = (number) => {
    if (!number) return "0";
    if (number >= 1000) {
      return thousandsSeparator(number);
    }
    if (number > 0) {
      return number.toFixed(2).replace(/\./g, ",");
    }
  };

  const getCell = ({ number, percentage, type }) => {
    if (number > 0) {
      if (type === "euros") {
        if (!percentage) {
          return (
            <div>
              {handleToFixed(parseFloat(centsToAmountString(number)))} €
            </div>
          );
        }

        let amountString = centsToAmountString(number);
        let amountInt = parseInt(amountString);
        let amountFixed = handleToFixed(amountInt);
        let amountWithoutDecimals = amountFixed.replace(/,00/g, "");
        let amountFinal = amountWithoutDecimals + " €";
        return (
          <div>
            {amountFinal}
          </div>
        );
      }
      return (
        <div>
          {handleToFixed(number).replace(/,00/g, "")}
        </div>
      );
    } else {
      if (type === "euros") {
        return <div>0€</div>;
      }
      return <div>0</div>;
    }
  };

  const openInfoModal = (type) => {
    infoModal.openModal('payments')
  }

  const getDates = () => {
    let from_date;
    let to_date;

    const offsetMins = new Date().getTimezoneOffset();
    const date = new Date(new Date().getTime() - offsetMins * 60 * 1000);
    const stringDate = sessionStorage.getItem("yumminn-selectedDate")
      ? sessionStorage.getItem("yumminn-selectedDate").substring(1, 11)
      : date.toISOString().substring(0, 10);
    switch (activo) {
      case 'month':
        const startMonth = selectedMonth ? new Date(selectedMonth).getMonth() : new Date().getMonth() - 12;
        const startYearMonth = selectedMonth ? new Date(selectedMonth).getFullYear() : new Date().getFullYear() - 1;
        const fromDateMonth = dayjs().set('month', startMonth).set('year', startYearMonth).startOf('month').format('YYYY-MM-DD');
        const endMonth = selectedMonthTo ? new Date(selectedMonthTo).getMonth() : new Date().getMonth();
        const endYearMonth = selectedMonthTo ? new Date(selectedMonthTo).getFullYear() : new Date().getFullYear();
        const toDateMonth = dayjs().set('month', endMonth).set('year', endYearMonth).endOf('month').format('YYYY-MM-DD')
        from_date = fromDateMonth;
        to_date = toDateMonth;
        break;

      case 'year':
        const startYear = selectedYear ? new Date(selectedYear).getFullYear() : new Date().getFullYear();
        const fromDateYear = dayjs().startOf('year').set('year', startYear).format('YYYY-MM-DD');
        const endYear = selectedYearTo ? new Date(selectedYearTo).getFullYear() : new Date().getFullYear();
        const toDateYear = dayjs().endOf('year').set('year', endYear).format('YYYY-MM-DD')
        from_date = fromDateYear;
        to_date = toDateYear;
        break;

      case 'hours':
        //let selectedDate = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().format('YYYY-MM-DD');
        //from_date = new Date(new Date(selectedDate).setHours(1,0,0))
        //to_date = new Date(new Date(from_date).setHours(24,59,59))
        const today = new Date(new Date(stringDate));
        const modifiedDateStart = new Date(today.setHours(2, "00", "00"));
        const modifiedDateEnd = new Date(today.setHours(24, 59, "59"));
        from_date = selectedStartTime ? modifiedDateStart : modifiedDateStart;
        to_date = selectedEndTime ? modifiedDateEnd : modifiedDateEnd;
		    break;

      default:
        from_date = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().subtract(8, 'day').format('YYYY-MM-DD');
        to_date = dateEnd;
        break;
    }
    return { from_date, to_date };
  }

  useEffect(() => {
    setLoadingTopRestaurants(true);
    getTopRestaurants({ allRestaurants: false, from_date: getDates().from_date, to_date: getDates().to_date });
  }, [showAllRestaurants, accountManagerSelected, seleccionado, activo, end, start, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, dateStart, dateEnd, selectedDate, selectedDate, selectedStartTime, selectedEndTime, page, order, selectedColumn, sort]);

  useEffect(() => {
    setPage(0);
  }, [activo, end, start, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, dateStart, dateEnd, selectedDate, selectedDate, selectedStartTime, selectedEndTime])


  //shadow to indicate scroll
  const table = useRef(null);
  // const scrollArea = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);

  // const updateScroll = () =>{
  //   console.log(scrollArea.current.scrollH);
  //   setScrollPos(table.current.offsetTop)
  // }

  // useEffect(()=>{
  //   console.log(scrollPos);
  // },[scrollPos])
  
  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {!isRowBased &&
          <h1 className={`font-bold font-[#094553] text-[24px] px-[0px] right-0 py-5 w-full bg-[#EFF8F4] pl-8`} >
            {t('Top_restaurants')}
          </h1>}
        <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-12 lg:px-6 rounded-lg gap-5 bg-gray-100 pt-3`}>
          <div className={`flex ${isRowBased ? 'flex-col' : 'flex-row'} items-center gap-4 ${!isRowBased ? "w-2/3" : "w-3/3"}`}>
            <RestaurantSelect accountManagerSelected={accountManagerSelected}/>
            {seleccionado && seleccionado[0] && seleccionado[0].label && seleccionado[0].label==='Todos' &&
              <div className={`${isRowBased ? 'flex' : 'flex items-center'}`}>
                <div className={`text-xs ${!isRowBased ? "w-28 pr-4" : 'w-full whitespace-nowrap pr-2'}`}>
                  {t('Show all restaurants')}
                </div>
                {" "}
                <input
                  type="checkbox"
                  checked={showAllRestaurants}
                  onChange={() => setShowAllRestaurants(!showAllRestaurants)}
                />
              </div>
            }
          </div>
          <AccountManagerSelect accountManagerSelected={accountManagerSelected} setAccountManagerSelected={setAccountManagerSelected}/>
        </div>
        <div className={`flex flex-col lg:flex-row items-center justify-center w-full lg:pl-6 rounded-lg pb-8 gap-5 bg-gray-100  pt-3`}>
          <div className="z-9999">
            {period === 'month' ? <MonthSelect /> : period === 'year' ? <YearSelect /> : <DateSelect />}
          </div>
          <DatesFilter doFilter={setSelectedReviewFilter} />
        </div>
      </div>
      {loadingTopRestaurants && (
        <div className="h-full w-full absolute z-9999 flex flex-col justify-center items-center">
          <Spinner>{t("Loading")}</Spinner>
        </div>
      )}
      <div className="flex-auto overflow-scroll w-full h-full">
        <table
          className={
            loadingTopRestaurants
              ? "bg-white table-auto w-full overflow-y-scroll overflow-x-scroll opacity-25"
              : "bg-white table-auto w-full overflow-y-scroll overflow-x-scroll"
          }
        >
          <thead style={{ boxShadow: scrollPos > 0 && ' 20px 20px 50px grey' }} className=" text-xs sticky top-0 m-0 bg-white">
            <tr className="h-16 m-0 bg-white">
              <th style={{ position: 'relative' }} className="py-6 pb-3 w-14 relative"></th>
              <th className="px-3 max-w-min text-center items-center">
                <div className="w-full flex flex-row items-center ">
                  {t("Restaurants")}
                </div>
              </th>
              <th
                className="px-3 w-1/7 text-center items-center border-l-2 border-gray-200"
                onClick={() => sortTable("payments")}
              >
                <div className="flex flex-row items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={headerStyle("payments")}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t("Payments")}
                </div>
              </th>
              <th className="text-center items-center">%</th>
              <th className="text-center items-center pr-2">%{t('Payments with tips')}</th>
              <th
                className="px-3 w-1/7 text-center border-l-2 border-r-2 border-gray-200"
                onClick={() => sortTable("ticket_avg")}
              >
                <div className="flex flex-row items-center cursor-pointer ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={headerStyle("ticket_avg")}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t("Avg_Ticket")}
                </div>
              </th>
              <th
                className="px-3 text-center items-center"
                onClick={() => sortTable("transactions")}
              >
                <div className="flex flex-row items-center cursor-pointer ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={headerStyle("transactions")}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t("Transactions")}
                </div>
              </th>
              <th className="text-center items-center">%</th>
              <th
                className="px-6 w-1/8 text-center items-center border-l-2 border-gray-200"
                onClick={() => sortTable("tips")}
              >
                <div className="flex flex-row items-center cursor-pointer ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={headerStyle("tips")}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t("Tips")}
                </div>
              </th>
              <th className="text-center items-center">%</th>
              <th className="text-center items-center text-xs pr-2">%{t('tips vs payment')}</th>
              <th
                className="px-3 w-1/7 text-center items-center  border-l-2 border-gray-200"
                onClick={() => sortTable("reviews")}
              >
                <div className="flex flex-row items-center cursor-pointer ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={headerStyle("reviews")}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill="evenodd"
                      d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t("Reviews")}
                </div>
              </th>
              <th className="text-center items-center px-3">%</th>
              <th className="text-center items-center px-3 ">
                %{t('payments with review')}
              </th>
              <th className="text-center items-center px-3  border-l-2 border-gray-200">
                %{t('payments with user')}
              </th>
            </tr>
            {topRestaurantsData &&
              <tr className=" w-full text-gray-600 bg-gray-100">
                <th style={{ position: 'relative' }} className="py-6 pb-3 w-14 relative"></th>
                <th className="px-3 max-w-min text-left">{t('Total')}</th>
                <th className="w-1/7 text-center items-center  border-l-2 border-gray-200">
                  {getCell({
                    number: topRestaurantsData.all_restaurants.payments,
                    type: "euros",
                  })}
                </th>
                <th className="w-1/7 text-center"></th>
                <th className="w-1/7 text-center">
                  { handleToFixed(topRestaurantsData.all_restaurants.payments_with_tip_average)+'%'}
                </th>
                <th className="w-1/7 items-center text-center border-l-2 border-r-2 border-gray-200">
                  {getCell({
                    number: topRestaurantsData.all_restaurants.avg_ticket,
                    type: "euros",
                  })}
                </th>
                <th className="w-1/7 text-center items-center">
                  {topRestaurantsData.all_restaurants.transactions}
                </th>
                <th className="w-1/7 text-center items-center"></th>
                <th className="w-1/7 text-center items-center  border-l-2 border-gray-200">
                  {getCell({
                    number: topRestaurantsData.all_restaurants.tips,
                    type: "euros",
                  })}
                </th>
                <th className="w-1/7 text-center items-center"></th>
                <th className="w-1/7 text-center">
                  {handleToFixed(topRestaurantsData.all_restaurants.avg_tip)+'%'}
                </th>
                <th className="w-1/7 text-center items-center  border-l-2 border-gray-200">
                  {topRestaurantsData.all_restaurants.reviews}
                </th>
                <th className="w-1/7 text-center items-center"></th>
                <th className='w-1/7 text-center'>
                  {handleToFixed(topRestaurantsData.all_restaurants.avg_payment_with_review)+'%'}
                </th>
                <th className='w-1/7 text-center border-l-2 border-gray-200'>
                  {handleToFixed(topRestaurantsData.all_restaurants.payments_with_user)+'%'}
                </th>
              </tr>
            }
          </thead>

          <tbody className="text-sm align-top overflow-y-scroll overflow-x-scroll">
            {topRestaurantsData && !loadingTopRestaurants && topRestaurantsData.restaurants ?
              topRestaurantsData.restaurants.map((item, index) => {
                  const Comp = ({counter}) =>{
                    return (<tr key={index}>
                      <td className="px-4 py-3 text-center w-14">
                        {/* {item[1]["index"]}  */}
                        {counter}
                      </td>
                      <td className=" max-w-min px-4 py-3 text-start break-words overflow-hidden">
                        {item[0]}
                      </td>
                      <td className="w-1/7 px-4 py-3 text-center whitespace-nowrap border-l-2 border-gray-200">
                        {getCell({
                          number: item[1]["payments"],
                          type: "euros",
                        })}
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["payments_percentage"])}%
                        <span className="text-[12px] pl-1">
                          {"(" + handleToFixed(item[1]["payments_percentage_accumulated"]) + "%)"}
                        </span>
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["payments_with_tips"])}%
                      </td>
                      <td className="w-1/7 px-4 py-3 text-center items-center whitespace-nowrap border-l-2 border-r-2 border-gray-200">
                        {getCell({
                          number: item[1]["ticket_avg"],
                          type: "euros",
                        })}
                      </td>
                      <td className="px-4 py-3 text-center">
                        {getCell({
                          number: item[1]["transactions"],
                          type: "",
                        })}
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["transactions_percentage"])}%
                        <span className="text-[12px] pl-1">
                          {"(" + handleToFixed(item[1]["transactions_percentage_accumulated"]) + "%)"}
                        </span>
                      </td>
                      <td className="w-1/7 px-4 py-3 text-center  border-l-2 border-gray-200">
                        {getCell({
                          number: item[1]["tips"],
                          type: "euros",
                        })}
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["tips_percentage"])}%
                        <span className="text-[12px] pl-1">
                          {"(" + handleToFixed(item[1]["tips_percentage_accumulated"]) + "%)"}
                        </span>
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["avg_tip"])}%
                      </td>
                      <td className="w-1/7 px-4 py-3 text-center  border-l-2 border-gray-200">
                        {getCell({
                          number: item[1]["reviews"],
                          type: "",
                        })}
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["reviews_percentage"])}%
                        <span className="text-[12px] pl-1">
                          {"(" + handleToFixed(item[1]["reviews_percentage_accumulated"]) + "%)"}
                        </span>
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap'>
                        {handleToFixed(item[1]["reviews_percentage_with_payments"])}%
                      </td>
                      <td className='w-1/7 px-4 py-3 whitespace-nowrap border-l-2 border-gray-200'>
                        {handleToFixed(item[1]["percentage_payments_with_users"])}%
                      </td>
                    </tr>)
                  }
                  if (sort === 'ASC'){
                    counter++;
                  }else{
                    counter--;
                  }
                  
                  return <Comp counter={counter}/>
                })
                :
                !loadingTopRestaurants?
                <tr>
                  <td colSpan={15} className="p-10 text-center items-center w-full">{t('no restaurants with payments')}</td>
                </tr>
                :
                <></>               
              }
          </tbody>
        </table>
      </div>
      {topRestaurantsData && (
        <div className="flex-auto px-[32px] pb-1 bg-gray-100 flex sm:flex-row flex-col lg:text-base text-xs items-center justify-center w-full text-right">
          <div className="flex justify-center col-span-4 w-full space-x-4 my-5 border-top border-grey-500">
            <button
              className="disabled:text-gray-200"
              disabled={page === 0}
              onClick={() => {
                if (page !== 0) {
                  setPage(page - 1);
                }
              }}
            >
              {t("Previous")}
            </button>
            <div>
              [Total registros: {topRestaurantsData.total_restaurants}
            </div>
            <div> /</div>
            <div>
              Página {page + 1} de {topRestaurantsData.total_pages}]
            </div>
            <button
              className="disabled:text-gray-200"
              disabled={page === topRestaurantsData.total_pages}
              onClick={() => {
                if (page + 1 !== topRestaurantsData.total_pages) {
                  setPage(page + 1);
                }
              }}
            >
              {t("Next")}
            </button>
          </div>
          <div
            onClick={getAllRestaurants}
            className="rounded-full bg-[#5FB894] text-white text-base font-semibold sm:w-64 w-full p-5 h-10 flex flex-row items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            {loadingCsv ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 animate-spin"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            ) : (
              t("Export_Csv")
            )}
          </div>
          {csvRestaurants.length > 0 && !loadingCsv && (
            <CSVDownload
              data={csvRestaurants}
              headers={headers}
              separator={";"}
              ref={csvInstance}
            />
          )}
        </div>
      )}
    </>
  );
};

