import React, { useState, useEffect } from "react";
import {Redirect, Route} from "react-router-dom";
import axios from 'axios';
import { apiAuth, baseAuth } from "logic/api";
import { toast } from 'react-toastify';
import { Context as AuthContext } from 'data/authContext';

const ProtectedRoute = ({component: Component, layout: Layout, title, route_group, hasDateFilter,type, hasWaiterSelect, ...rest}) => {

  const token = localStorage.getItem('token')||null
  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
  const [isLogged, setIsLogged] = useState(false);
  const [loginChecked, setLoginChecked] = useState(false);
  const {
    setIsLoggedIn,
    setAllowedRoutes,
    state: {
      isLoggedIn,
      allowedRoutes
    }
  } = React.useContext(AuthContext); 

  const loggedIn = async () => {
    try {
      const response = await apiAuth.post(`/users/validate_token`, token);
      setIsLogged(response.data.valid);
      setIsLoggedIn(response.data.valid)
      setAllowedRoutes(response.data.bo_sections)

    } catch (error) {
      toast.error(error?.response?.data);
    } finally {
      setLoginChecked(true); // Mark login check as completed
    }
  }

  useEffect(() => {
    if(token){
      loggedIn();
    } 
  }, [isLoggedIn, token])


  const registerActivity = async () => {
    //console.log(window.location.pathname)
    const res = await axios.get("https://api.ipify.org/?format=json")
    //console.log(res)
    const ip = res.data.ip
    const body = {"ip": ip,
            "route": window.location.pathname}
    await baseAuth.post(`/bo/register-superuser-activity`, body)
  };

  useEffect(() => {
    try {
      registerActivity()
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (!loginChecked) {
    // This prevents rendering the route until login status is checked
    return null;
  }

  const currentPath = window.location.pathname;

  if (!(isLogged && restaurants && restaurants.length > 0) || (isLogged && allowedRoutes.length > 0 && !allowedRoutes.includes(currentPath))) return <Redirect to="/"/>

  return (
    <Route
      exact
      {...rest}
    >
      <Layout title={title} route_group={route_group} hasDateFilter={hasDateFilter} hasWaiterSelect={hasWaiterSelect} type={type} >
        <Component />
      </Layout>
    </Route>
  );
};

export default ProtectedRoute;
