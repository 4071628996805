import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from './Popup';
import styled from 'styled-components';

export const SuccessPopup = ({title, message, children, closeLabel, className = '', ...popupProps}) => {
  const { t } = useTranslation();

  return (
    <Popup {...popupProps}>
      <Container className={className}>
        <CheckIcon/>
        {title &&
          (typeof title === 'string' ? <h3>{t(title)}</h3> : title)
        }
        {message &&
          (typeof message === 'string' ? <p>{t(message)}</p> : message)
        }
        {children}
        <button onClick={popupProps.close}>
          {t(closeLabel || 'ok_close')}
        </button>
      </Container>
    </Popup>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: fit-content;
  font-size: 1rem;

  & > svg {
    color: #FFFFFF;
    background-color: #5FB894;
    border-radius: 5em;
    padding: 0.125em;
    box-shadow: 0 0 0 0.25em #D3F4E6;
    height: 1.6em;
    width: 1.6em;
  }

  & > h3 {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.3;
    max-width: 450px;
    text-align: center;
    text-wrap: balance;
  }
  
  & > p {
    font-weight: 1em;
    text-align: center;
    line-height: 1.3;
    max-width: 450px;
    text-wrap: balance;
  }

  & > button {
    --btn_color: #FFFFFF;
    --btn_bg_color: #094553;
    --btn_border_color: #094553;

    min-height: 44px;
    padding: 0.5em 2.5em;
    font-size: 1em;
    font-weight: 600;
    color: var(--btn_color);
    background-color: var(--btn_bg_color);
    border: 2px solid var(--btn_border_color);
    border-radius: 99px;

    &::first-letter {
      text-transform: capitalize;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;