import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoTooltip } from 'common/InfoTooltip';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { PlusCircleIcon } from '@heroicons/react/solid';

export const TabListHeader = ({ listName, numberOfLevels, listLength, createNew }) => {
	const { t } = useTranslation();
	
	const allowNewSuperGroup = listName === 'SUPER_GROUP' && listLength < 10 && numberOfLevels < 3;
	const allowNewCategoryGroup = listName === 'CATEGORY_GROUP' && numberOfLevels < 2;
	const allowNewCategory = listName === 'CATEGORY';
	const showAddNew = allowNewSuperGroup || allowNewCategoryGroup || allowNewCategory;
	
  return (
    <header>
      <h4>
        {t(listName)}
        <InfoTooltip info={`Info_${listName}`}/>
      </h4>
      {showAddNew &&
        <Button
          label={t(`add_new_f`)}
          leftIcon={<PlusCircleIcon/>}
          onClick={createNew}
          variant={BUTTON_VARIANTS.SECONDARY}
        />
      }
    </header>
  );
};
