import { PlusCircleIcon } from '@heroicons/react/solid';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const VisibilitySchedulesList = ({schedules, isInactive, handleAddSchedule, editSchedule, editObject, setSchedulesToDelete}) => {
  const { t } = useTranslation();

  const getWeekdays = (days) => {
    if (days.length === 7) return t('every_day');
    if (days.length === 2 && days.includes(5) && days.includes(6)) return t('weekend');

    days.sort((a, b) => a - b);
    const areConsecutive = days.length > 2 && days.map((day, index) => (
      days[index + 1] - day === 1 || day === 6 && days[0] === 0
    ));

    const isRange = areConsecutive && areConsecutive.filter(isConsecutive => !isConsecutive).length === 1;

    if (isRange) {
      const lastDayIndex = areConsecutive.findIndex(isConsecutive => !isConsecutive);
      const firstDay = WEEKDAYS[days[lastDayIndex === days.length - 1 ? 0 : lastDayIndex + 1]];
      const lastDay = WEEKDAYS[days[lastDayIndex]];

      return `${t(firstDay)} ${t('to')} ${t(lastDay)}`;
    }

    const weekdays = days.map(day => t(WEEKDAYS[day]));
    return weekdays.join(", ");
  };

  const deleteSchedule = async (index) => {
    const newSchedules = [...schedules];
    const deleted = newSchedules.splice(index, 1)[0];
    editObject({visibility_schedules: newSchedules});
    deleted.id && setSchedulesToDelete(prevSchedules => [...prevSchedules, deleted.id]);
  };

  return (
    <SchedulesContainer>
      {schedules?.length > 0 &&
        <ul>
          <h4>{t('custom_shchedules')}</h4>
          {schedules.map((schedule, index) =>
            <li key={'schedule-' + (schedule.id || schedule.tempId)}>
              <div className={isInactive ? 'inactive' : ''}>
                <b>{schedule?.name || `${t('schedule')} ${index + 1}`}</b>
                <i>{' - ' + getWeekdays(schedule.visibility_days)}</i>
              </div>
              <Icon type={IconType.PENCIL} size={20} onClick={() => editSchedule(schedule)}/>
              <Icon type={IconType.TRASH} size={20} onClick={() => deleteSchedule(index)}/>
            </li>
          )}
        </ul>
      }
      <Button
        leftIcon={<PlusCircleIcon/>}
        label='define_schedule'
        onClick={handleAddSchedule}
        variant={BUTTON_VARIANTS.SECONDARY}
      />
    </SchedulesContainer>
  );
};

const SchedulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  min-width: 50%;
  flex: 1;
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  
  li {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 1rem;

    & > div {
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      padding: 16px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
  }

  b {
    font-weight: 600;
  }
`;

export default VisibilitySchedulesList;