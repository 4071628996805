import { PhotographIcon } from '@heroicons/react/outline'
import { PlusCircleIcon } from '@heroicons/react/solid'
import React from 'react';
import styled from "styled-components";
import { centsToAmountString } from "logic/functions";

export const SwimLineCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: min-content;
  border-color: gray;
  background-color: white;
  padding: 6px;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0px 1px 2px #00000010, 0px 0px 4px #00000010;
  box-sizing: border-box;

  img {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    object-fit: cover;
  }

  & > div {
    display: flex;
    flex-direction: column;
    width: 50px;

  }

  h5 {
    font-size: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  span {
    font-size: 8px;
    line-height: 1.3;
  }

  button {
    align-self: end;
    margin-top: auto;
  }
`;


export const CounterButton = styled.button`
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  border: none;
  border-radius: 9999px;
  height: .7rem;
  width: .7rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
  padding: 0.3rem;
  transition: opacity 0.15s ease-in-out;
  
  &:disabled {
    opacity: 0.6;
  }

  svg {
    height: 100%;
    width: 100%;
    margin: auto;
  }
`;

export const CustomGroupProductCardMockup = ({card_primary_color, product, default_image}) => {
  return (
    <SwimLineCardContainer style={{background_color: card_primary_color}}>
      {(!!product?.product_image || !!default_image) ?
        <img src={product?.product_image || default_image} alt='product_photo'/>
      :
        <PhotographIcon height={40} className='m-auto text-gray-400'/>
      }
      <div>
        <h5>{product ? product.product__tpv_name : "Producto"}</h5>
        <span>
        {centsToAmountString(product ? product.product__tpv_price_cents : "1,20") + "€"}
        </span>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <PlusCircleIcon height={14}/>
        </div>
      </div>
    </SwimLineCardContainer>
  )
}
