import React from 'react';
import { ResponsiveContainer } from 'recharts';
import {useTranslation} from 'react-i18next';
import ArrowIndicator from './ArrowIndicator';

const GraphCard = ({title, indicators, children, totals}) => {
  const { t } = useTranslation()

  return (
    <div className='max-w-full h-full text-trueGray-700'>
        <div className='h-full flex flex-col justify-between p-4 bg-white rounded-2xl' style={{boxShadow: '0 10px 24px 0 #959da533'}}>
        <div className="flex justify-between">
          <div className='flex flex-wrap items-start font-bold'>
            <span className='text-md'>{t(title)}</span>
          </div>
          <div className='flex flex-row items-start gap-2 text-sm mb-2'>
            {indicators && indicators.map(({label, value}) => (
              <div key={'indicator-' + label} className='flex gap-2'>
                {t(label)}
                <div className={`flex gap-1 items-center font-bold text-sm rounded-lg p-2 ${value === 0 ? 'bg-trueGray-200 text-trueGray-500' : value > 0 ? 'bg-[#5FB8943f] text-principal' : 'bg-[#E186843F] text-[#E18684]'}`}>
                  {value}%
                  <ArrowIndicator value={value}/>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ResponsiveContainer width={"100%"} className={'flex-grow'}>
          {children}
        </ResponsiveContainer>
        <div className='flex justify-evenly gap-1'>
          {totals && totals.map(({label, value, color}) => (
            <div key={'total-' + label} className='flex flex-col'>
              <span className='text-base font-semibold'>{value}</span>
              <span className='text-xs'>
                <svg width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px", marginBottom: "2px"}}><path stroke="none" fill={color} d="M0,4h32v24h-32z"></path></svg>                
                {t(label)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GraphCard;