import { Popup } from "common/Popup";
import styled from "styled-components";

export const KitchenSaturationContainer = styled.div`
  --max_height: 84px;
  --border_color: #DDD;
  --rotation: 0deg;
  --z_index: 0;

  &.open {
    --max_height: calc(100vh - 232px);
    --border_color: #094553;
    --rotation: 180deg;
    --z_index: 99999;
  }

  margin-left: auto;
  max-height: 84px;
  max-width: 500px;
  z-index: var(--z_index);

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FFF;
    border: 1px solid var(--border_color);
    border-radius: 10px;
    padding: 16px;
    max-height: var(--max_height);
    transition: max-height 0.3s ease-in-out;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 16px;
    
    & > div:first-child {
      font-size: 1.5rem;
      flex-shrink: 0;
    }

    a {
      font-weight: 600;
      text-decoration: underline;
      display: block;

      &:visited {
        color: inherit;
      }

      &:hover {
        color: #9DEECB;
      }
    }

    .info {
      width: max-content;
      max-width: 13rem;
      white-space: wrap;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 500;
    text-wrap: balance;
    line-height: 1.3;
    flex: 1;
    margin: auto 0;

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      color: #FFF;
      background-color: #DE3030;
      border-radius: 1em;
      height: 1.4em;
      width: 1.4em;
    }

    & > div {
      margin-left: 8px;
    }
  }
  
  b {
    font-weight: 600;
  }

  .chevron {
    transition: rotate 0.3s ease-in-out;
    rotate: var(--rotation);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    overflow-y: auto;
    margin: 0;
    padding: 0;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & > p {
      font-size: 1.125rem;
      background-color: #F9F9F9;
      border-radius: 10px;
      padding: 8px;
      text-align: center;
    }

    & > li {
      margin: 0;
      height: min-content;
    }
  }
`;

export const KitchenSaturationCard = styled.div`
  --bg_color: #FFF;
  --border_color: #DDD;
  
  &.delay {
    --bg_color: #FFF9EA;
    --border_color: #D3CEC3;
  }

  &.pause {
    --bg_color: #FFF2F2;
    --border_color: #E57070;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #404040;
  background-color: var(--bg_color);
  border: 1px solid var(--border_color);
  border-radius: 10px;
  max-width: fit-content;
  padding: 16px;
  margin-left: auto;
  
  .row {
    display: flex;
    align-items: center;
    gap: 8px;
    gap: 16px;

    & > div:first-child {
      font-size: 1.5rem;
      flex-shrink: 0;
    }
  
    & > p {
      font-size: 1.25rem;
      font-weight: 500;
      text-wrap: balance;
      line-height: 1.3;
      max-width: 20ch;

      div {
        margin-left: 8px;
      }
    }
  
    button {
      margin-left: 16px;
    }

    a {
      font-weight: 600;
      text-decoration: underline;
      displablocky

      &:hover {
        color: #9DEECB;
      }

      &:visited {
        color: inherit;
      }
    }
  }

  .timer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: auto;

    span {
      font-size: 1rem;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 1;
    }

    strong {
      font-family: "Noto Sans Mono", monospace;
      font-weight: 800;
      font-size: 2.5rem;
      line-height: 1;
      font-variant-numeric: tabular-nums;
      letter-spacing: -2px;
    }
  }

  .no_timer {
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1;

    strong {
      font-size: 1.5rem;
      line-height: 1;
    }
  }

  b {
    font-weight: 600;

    &.blue {
      color: #094553;
    }
  }
`;

export const KitchenSaturationModalPopup = styled(Popup)`
  header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border-bottom: 1px solid #DDD;
    padding-bottom: 1em;

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1;
    }
  }

  .restaurant_select {
    margin: 0 auto;

    label {
      margin-top: 16px;

      & > div {
        min-width: 45%;
        margin: 0 auto
      }
    }
  }

  .saturation_options {
    display: flex;
    gap: 40px;
    padding: 0 40px;
    
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 2px solid #094553;
      border-radius: 1.25rem;
      padding: 24px;
      max-width: max(20vw, 285px);
      transition: 0.2s ease-in-out;
      filter: grayscale(1);
      
      & > * {
        opacity: 0.5;
        pointer-events: none;
      }
      
      &:hover, &.selected {
        background-color: #FAFFFD;
        filter: none;
        translate: -0.5rem -0.5rem;
        box-shadow: 
        0.1rem 0.1rem 0 #094553,
        0.3rem 0.3rem 0 #094553,
        0.5rem 0.5rem 0 #094553;
        
        & > * {
          opacity: 0.75;
        }
      }
      
      &.selected > * {
        pointer-events: all;
        opacity: 1;
      }
      
      i, h5 {
        opacity: 1 !important;
      }
    }

    &:has(> div:hover) .selected:not(:hover) {
      filter: grayscale(0.8);
      translate: -0.3rem -0.3rem;
      box-shadow: 
      0.1rem 0.1rem 0 #094553,
      0.2rem 0.2rem 0 #094553,
      0.3rem 0.3rem 0 #094553;
      
      & > * {
        opacity: 0.75;
      }
    }
    
    &.disabled {
      pointer-events: none;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    margin: 1em 0;
  }
  
  i {
    font-size: 2.25rem;
    font-style: normal;
    position: relative;

    &:first-of-type::after {
      content: attr(data-corner);
      position: absolute;
      top: -0.4em;
      right: -0.2em;
      font-size: 0.6em;
    }
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;

    b {
      font-weight: 700;
    }
  }

  p {
    font-size: 0.875rem;
    text-align: center;
    margin: 1em 0;
  }
  
  span {
    font-size: 1rem;
  }
  
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    margin: 8px 0;
  }

  b {
    font-weight: 600;
  }

  .check {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: auto 0;
  }

  .info {
    font-size: 1.125rem;
    text-align: center;
    text-wrap: balance;
    max-width: 50%;
    margin: 16px auto;

    &::after {
      content: '☝🏻';
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-top: 16px;
    border-top: 1px solid #DDD;
  }
`;

export const KitchenSaturationToast = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 1.25rem;
  color: #222222;
  font-family: 'Poppins', sans-serif;

  & > div:first-child {
    font-size: 1.5rem;
    flex-shrink: 0;
  }

  h5 {
    font-weight: 700;
  }

  p {
    text-wrap: balance;
  }

  b {
    font-weight: 600;
    color: #094553;
  }
  
  strong {
    font-weight: 700;
    color: #094553;
  }
`;