import React from 'react';
import { useTranslation } from "react-i18next";
import QRCode from 'react-qr-code';

const OrderBumerangQR = ({qr_string}) => {
  const { t } = useTranslation();
  return (
    <div className='mt-8 flex flex-row justify-between'>
				<div style={{fontSize:"18px", fontWeight:400}}>
					{"QR Bumerang"}
					<p style={{fontSize:"18px", fontWeight:700}}>
						{qr_string}
					</p>
				</div>
				<div className="flex flex-row justify-center">
					<QRCode
					size={180}
					value={qr_string}
					/>
				</div>
			</div>
  );
};
export default OrderBumerangQR;
