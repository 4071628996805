import {
    ChevronDownIcon,
    ChevronLeftIcon,
    FilterIcon,
  } from "@heroicons/react/outline";
  import React from "react";
  import { useTranslation } from "react-i18next";
  import { ProductCardMockup } from "../components";
  import "../../../styles/animations.css";
  import { STYLES } from "./CategoryStyle";
  import styled from "styled-components";
  
  const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  `;
  
  export const ProductDiscountPreview = ({ discount }) => {
    const { t } = useTranslation();
  
    return (
      <div className="w-full h-full flex flex-col">
        <div className="w-full p-1 pb-[2px] flex items-center gap-1 relative bg-[#404040]">
          <ChevronLeftIcon
            width={13}
            className="flex-shrink-0 mr-auto text-white"
          />
          <span className={STYLES.SELECTED_SUPERGROUP}>{t("group")}</span>
          <span className={STYLES.SUPERGROUP}>{t("group")}</span>
          <span className={STYLES.SUPERGROUP}>{t("group")}</span>
          <FilterIcon width={13} className="flex-shrink-0 ml-auto opacity-0" />
        </div>
        <div className="w-full flex-1 flex flex-col relative">
          <div className="w-full px-2 py-[2px] flex items-center gap-1 relative">
            <span className={STYLES.COLAPSED_GROUP}>
              {t("category")}
              <ChevronDownIcon width={10} className={`flex-shrink-0`} />
            </span>
            <span className={STYLES.GROUP}>
              {t("category")}
              <ChevronDownIcon width={10} className="flex-shrink-0" />
            </span>
            <span className={STYLES.GROUP}>
              {t("category")}
              <ChevronDownIcon width={10} className="flex-shrink-0" />
            </span>
            <span className={STYLES.GROUP}>
              {t("category")}
              <ChevronDownIcon width={10} className="flex-shrink-0" />
            </span>
          </div>
          <ProductList>
            <ProductCardMockup
              amplified
              allergens
              description={"long"}
              discount={discount}
            />
            <ProductCardMockup
              allergens
              description={"short"}
              discount={discount}
            />
            <ProductCardMockup
              allergens
              description={"long"}
              discount={discount}
            />
            <ProductCardMockup
              allergens
              description={"short"}
              discount={discount}
            />
            <ProductCardMockup
              allergens
              description={"short"}
              discount={discount}
            />
          </ProductList>
        </div>
      </div>
    );
  };
  