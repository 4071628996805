import React from 'react'
import styled from 'styled-components';

export const Logo = ({size = 32, className = '', onClick}) => {
  return (
    <LogoContainer as={!!onClick ? 'button' : 'div'} onClick={onClick} size={size} className={className}>
      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.3732 19.8853L25.3734 19.8772V19.8691V7.05682C25.3734 3.87255 22.797 1.31274 19.6051 1.31274H6.73211C3.54022 1.31274 0.96386 3.87255 0.96386 7.05682V19.8691C0.96386 22.9902 3.54597 25.5443 6.73211 25.5443H19.6051C22.731 25.5443 25.2953 22.9847 25.3732 19.8853Z" fill="#094553" stroke="white" strokeWidth="1.29347"/>
        <path d="M13.2377 8.02112C14.0022 8.02112 14.6219 7.43516 14.6219 6.71234C14.6219 5.98952 14.0022 5.40356 13.2377 5.40356C12.4732 5.40356 11.8535 5.98952 11.8535 6.71234C11.8535 7.43516 12.4732 8.02112 13.2377 8.02112Z" fill="white"/>
        <path d="M12.0613 21.7286C11.0924 22.0042 10.0542 21.522 9.70819 20.6265L7.28585 13.6693C6.9398 12.7738 7.49348 11.8095 8.53163 11.4651C9.50056 11.1895 10.5387 11.6717 10.8848 12.5672L13.3071 19.5244C13.5839 20.4199 13.0303 21.3842 12.0613 21.7286Z" fill="url(#paint0_radial_1992_96)" stroke="url(#paint1_linear_1992_96)" strokeWidth="0.323368" strokeMiterlimit="10"/>
        <path d="M14.6219 21.7286C15.5909 22.0042 16.629 21.522 16.9058 20.6265L19.3282 13.6693C19.605 12.7738 19.1206 11.8095 18.1516 11.4651C17.1827 11.1895 16.1445 11.6717 15.8677 12.5672L13.4454 19.5244C13.0993 20.4199 13.653 21.3842 14.6219 21.7286Z" fill="url(#paint2_radial_1992_96)" stroke="url(#paint3_linear_1992_96)" strokeWidth="0.323368" strokeMiterlimit="10"/>
        <path d="M13.3773 21.7975C11.9239 21.7975 10.7473 20.6265 10.7473 19.18V11.7406C10.7473 10.2941 11.9239 9.12305 13.3773 9.12305C14.8307 9.12305 16.0073 10.2941 16.0073 11.7406V19.18C16.0073 20.6265 14.7615 21.7975 13.3773 21.7975Z" fill="white" stroke="url(#paint4_linear_1992_96)" strokeWidth="0.323368" strokeMiterlimit="10"/>
        <path d="M18.6364 9.94941C19.1715 9.94941 19.6054 9.54849 19.6054 9.05393C19.6054 8.55937 19.1715 8.15845 18.6364 8.15845C18.1013 8.15845 17.6675 8.55937 17.6675 9.05393C17.6675 9.54849 18.1013 9.94941 18.6364 9.94941Z" fill="white"/>
        <path d="M8.04779 9.94941C8.58292 9.94941 9.01673 9.54849 9.01673 9.05393C9.01673 8.55937 8.58292 8.15845 8.04779 8.15845C7.51266 8.15845 7.07886 8.55937 7.07886 9.05393C7.07886 9.54849 7.51266 9.94941 8.04779 9.94941Z" fill="white"/>
        <defs>
          <radialGradient id="paint0_radial_1992_96" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.7345 19.0761) rotate(179.509) scale(4.73904 4.6053)">
            <stop offset="0.2807" stopColor="white" stopOpacity="0"/>
            <stop offset="0.9239" stopColor="white" stopOpacity="0.9"/>
          </radialGradient>
          <linearGradient id="paint1_linear_1992_96" x1="13.5382" y1="16.5674" x2="6.97591" y2="16.5093" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white"/>
          </linearGradient>
          <radialGradient id="paint2_radial_1992_96" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.9673 19.1032) scale(4.61428 4.59251)">
            <stop offset="0.2807" stopColor="white" stopOpacity="0"/>
            <stop offset="0.9239" stopColor="white" stopOpacity="0.9"/>
          </radialGradient>
          <linearGradient id="paint3_linear_1992_96" x1="13.18" y1="16.5999" x2="19.5708" y2="16.5999" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white"/>
          </linearGradient>
          <linearGradient id="paint4_linear_1992_96" x1="10.5385" y1="15.4679" x2="16.1487" y2="15.4679" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white"/>
          </linearGradient>
        </defs>
      </svg>
      <svg viewBox="0 0 87 25" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.35986 22.775V21.0172C4.35986 20.4547 4.63606 17.4314 3.66938 17.1502C3.39318 17.0799 2.77174 16.7283 2.49555 16.4471C1.94316 16.0252 1.52887 15.5331 1.25267 14.9003C0.976475 14.2675 0.838379 13.6347 0.838379 12.9316V8.36152C0.838379 7.65842 1.39077 7.09595 2.08126 7.09595H2.2884C2.97889 7.09595 3.53128 7.65842 3.53128 8.36152V12.9316C3.53128 13.3535 3.60033 13.705 3.80747 14.0566C4.01462 14.4081 4.29082 14.6894 4.56701 14.83C4.91225 15.0409 5.2575 15.1112 5.67179 15.1112C6.08608 15.1112 6.43133 15.0409 6.77657 14.83C7.12181 14.6191 7.39801 14.3378 7.53611 14.0566C7.74326 13.705 7.8123 13.3535 7.8123 12.9316V8.36152C7.8123 7.65842 8.36469 7.09595 9.05518 7.09595H9.26233C9.95282 7.09595 10.5052 7.65842 10.5052 8.36152V12.9316C10.5052 13.6347 10.3671 14.2675 10.0909 14.9003C9.81472 15.5331 9.40042 16.0252 8.91708 16.4471C8.57184 16.7283 8.15755 16.9393 7.74325 17.1502C6.70752 17.5721 6.98372 20.5251 6.98372 21.0172V22.775C6.98372 23.478 6.43133 24.0405 5.74084 24.0405H5.53369C4.91225 24.0405 4.35986 23.478 4.35986 22.775Z" fill="white"/>
        <path d="M17.2028 17.5017C16.1671 17.5017 15.3385 17.2908 14.5789 16.869C13.8194 16.4471 13.267 15.8846 12.9218 15.1815C12.5075 14.4784 12.3694 13.705 12.3694 12.8613V8.36151C12.3694 7.58811 12.9908 7.02563 13.6813 7.02563C14.4408 7.02563 14.9932 7.65842 14.9932 8.36151V12.9316C14.9932 13.3535 15.0623 13.705 15.2694 14.0566C15.4766 14.4081 15.7528 14.6894 16.029 14.83C16.3742 15.0409 16.7195 15.1112 17.1337 15.1112C17.548 15.1112 17.8933 15.0409 18.2385 14.83C18.5838 14.6191 18.86 14.3378 18.9981 14.0566C19.2052 13.705 19.2743 13.3535 19.2743 12.9316V8.43182C19.2743 7.65842 19.8957 7.09594 20.5862 7.09594C21.3457 7.09594 21.8981 7.72873 21.8981 8.43182V12.9316C21.8981 13.7753 21.691 14.5487 21.3457 15.2518C20.9314 15.9549 20.379 16.5174 19.6885 16.9393C19.0671 17.2908 18.2385 17.5017 17.2028 17.5017Z" fill="white"/>
        <path d="M25.1422 17.2207C24.3827 17.2207 23.8303 16.588 23.8303 15.8849V11.1741C23.8303 10.4007 24.0375 9.62732 24.3827 8.99453C24.728 8.36175 25.2803 7.79927 26.0399 7.37742C26.7994 6.95556 27.628 6.81494 28.5947 6.81494C29.078 6.81494 29.4923 6.88525 29.9066 6.95556C30.3209 7.09618 30.6661 7.2368 31.0804 7.44773C31.4257 7.65865 31.7709 7.93989 31.9781 8.22113C32.2543 7.93989 32.5995 7.65865 32.9448 7.44773C33.29 7.2368 33.7043 7.09618 34.1186 6.95556C34.5329 6.81494 35.0162 6.81494 35.4996 6.81494C36.5353 6.81494 37.3639 7.02587 38.0544 7.37742C38.7449 7.79927 39.2972 8.29144 39.7115 8.99453C40.0568 9.62732 40.2639 10.4007 40.2639 11.1741V15.8849C40.2639 16.6583 39.6425 17.2207 38.952 17.2207C38.1925 17.2207 37.6401 16.588 37.6401 15.8849V11.1741C37.6401 10.8226 37.571 10.471 37.3639 10.1898C37.1567 9.90856 36.9496 9.62732 36.6043 9.41639C36.2591 9.20546 35.9139 9.13515 35.5686 9.13515C35.2234 9.13515 34.8091 9.20546 34.5329 9.41639C34.1876 9.62732 33.9805 9.83825 33.7733 10.1898C33.5662 10.471 33.4971 10.8226 33.4971 11.1741V15.8849C33.4971 16.6583 32.8757 17.2207 32.1852 17.2207C31.4257 17.2207 30.8733 16.588 30.8733 15.8849V11.1741C30.8733 10.8226 30.8042 10.471 30.5971 10.1898C30.39 9.90856 30.1828 9.62732 29.8376 9.41639C29.4923 9.20546 29.1471 9.13515 28.8018 9.13515C28.4566 9.13515 28.0423 9.20546 27.7661 9.41639C27.4209 9.62732 27.2137 9.83825 27.0066 10.1898C26.7994 10.471 26.7304 10.8226 26.7304 11.1741V15.8849C26.4542 16.6583 25.8327 17.2207 25.1422 17.2207Z" fill="white"/>
        <path d="M43.3034 17.2207C42.5438 17.2207 41.9915 16.588 41.9915 15.8849V11.1741C41.9915 10.4007 42.1986 9.62732 42.5438 8.99453C42.8891 8.36175 43.4415 7.79927 44.201 7.37742C44.9605 6.95556 45.7891 6.81494 46.7558 6.81494C47.2392 6.81494 47.6534 6.88525 48.0677 6.95556C48.482 7.09618 48.8273 7.2368 49.2416 7.44773C49.5868 7.65865 49.9321 7.93989 50.1392 8.22113C50.4154 7.93989 50.7606 7.65865 51.1059 7.44773C51.4511 7.2368 51.8654 7.09618 52.2797 6.95556C52.694 6.81494 53.1773 6.81494 53.6607 6.81494C54.6964 6.81494 55.525 7.02587 56.2155 7.37742C56.906 7.79927 57.4584 8.29144 57.8727 8.99453C58.2179 9.62732 58.4251 10.4007 58.4251 11.1741V15.8849C58.4251 16.6583 57.8036 17.2207 57.1131 17.2207C56.3536 17.2207 55.8012 16.588 55.8012 15.8849V11.1741C55.8012 10.8226 55.7322 10.471 55.525 10.1898C55.3179 9.90856 55.1107 9.62732 54.7655 9.41639C54.4202 9.20546 54.075 9.13515 53.7297 9.13515C53.3845 9.13515 52.9702 9.20546 52.694 9.41639C52.3488 9.62732 52.1416 9.83825 51.9345 10.1898C51.7273 10.471 51.6583 10.8226 51.6583 11.1741V15.8849C51.6583 16.6583 51.0368 17.2207 50.3464 17.2207C49.5868 17.2207 49.0344 16.588 49.0344 15.8849V11.1741C49.0344 10.8226 48.9654 10.471 48.7582 10.1898C48.5511 9.90856 48.3439 9.62732 47.9987 9.41639C47.6534 9.20546 47.3082 9.13515 46.963 9.13515C46.6177 9.13515 46.2034 9.20546 45.9272 9.41639C45.582 9.62732 45.3748 9.83825 45.1677 10.1898C44.9605 10.471 44.8915 10.8226 44.8915 11.1741V15.8849C44.6153 16.6583 43.9939 17.2207 43.3034 17.2207Z" fill="white"/>
        <path d="M60.4272 15.8846V7.86935C60.4272 7.44749 60.7725 7.09595 61.1868 7.09595H62.3606C62.7749 7.09595 63.1201 7.44749 63.1201 7.86935V15.8846C63.1201 16.658 62.4987 17.2205 61.8082 17.2205C61.0487 17.2205 60.4272 16.658 60.4272 15.8846Z" fill="white"/>
        <path d="M66.5036 17.221C65.744 17.221 65.1917 16.5882 65.1917 15.8851V11.3853C65.1917 10.5416 65.3988 9.76818 65.744 9.06508C66.1583 8.36199 66.7107 7.79952 67.4012 7.37766C68.1608 6.9558 68.9893 6.74487 70.0251 6.74487C71.0608 6.74487 71.9584 6.9558 72.6489 7.37766C73.4085 7.79952 73.9609 8.36199 74.3061 9.06508C74.7204 9.76818 74.8585 10.5416 74.8585 11.3853V15.8851C74.8585 16.6585 74.237 17.221 73.5466 17.221C72.787 17.221 72.2346 16.5882 72.2346 15.8851V11.3853C72.2346 10.9634 72.1656 10.6119 71.9584 10.2603C71.7513 9.9088 71.4751 9.62756 71.1989 9.48694C70.8537 9.27601 70.5084 9.2057 70.0941 9.2057C69.6798 9.2057 69.3346 9.27601 68.9893 9.48694C68.6441 9.69787 68.3679 9.97911 68.2298 10.2603C68.0227 10.6119 67.9536 10.9634 67.9536 11.3853V15.8851C67.8846 16.6585 67.2631 17.221 66.5036 17.221Z" fill="white"/>
        <path d="M78.0346 17.3614C77.275 17.3614 76.7227 16.7286 76.7227 16.0255V11.5257C76.7227 10.682 76.9298 9.90856 77.275 9.20547C77.6893 8.50237 78.2417 7.9399 78.9322 7.51804C79.6918 7.09618 80.5203 6.88525 81.5561 6.88525C82.5918 6.88525 83.4894 7.09618 84.1799 7.51804C84.9395 7.9399 85.4918 8.50237 85.8371 9.20547C86.2514 9.90856 86.3895 10.682 86.3895 11.5257V16.0255C86.3895 16.7989 85.768 17.3614 85.0776 17.3614C84.318 17.3614 83.7656 16.7286 83.7656 16.0255V11.5257C83.7656 11.1038 83.6966 10.7523 83.4894 10.4007C83.2823 10.0492 83.0061 9.76794 82.7299 9.62732C82.3847 9.41639 82.0394 9.34608 81.6251 9.34608C81.2108 9.34608 80.8656 9.41639 80.5203 9.62732C80.1751 9.83825 79.8989 10.1195 79.7608 10.4007C79.5537 10.7523 79.4846 11.1038 79.4846 11.5257V16.0255C79.3465 16.7286 78.7251 17.3614 78.0346 17.3614Z" fill="white"/>
        <path d="M61.8076 3.7916C61.3242 3.7916 60.979 3.65098 60.6337 3.29944C60.2885 2.94789 60.1504 2.52603 60.1504 2.10417C60.1504 1.61201 60.2885 1.26046 60.6337 0.908914C60.979 0.557367 61.3933 0.416748 61.8076 0.416748C62.2909 0.416748 62.6361 0.557367 62.9814 0.908914C63.3266 1.26046 63.4647 1.68232 63.4647 2.10417C63.4647 2.59634 63.3266 2.94789 62.9814 3.29944C62.6361 3.65098 62.2218 3.7916 61.8076 3.7916Z" fill="white"/>
      </svg>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  --size: ${({size}) => size}px;

  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 1;
  height: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  max-width: 100%;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #FFFFFF;
    outline-offset: 2px;
  }

  svg:first-child {
    height: var(--size);
    width: var(--size);
  }

  svg:last-child {
    height: var(--size);
    width: calc(var(--size) * 87/25);
    margin-left: calc(var(--size) / 8);
  }
`;