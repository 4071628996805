import * as React from 'react';

export function UploadImageIcon(props) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5574_5237)">
        <path d="M19.0722 19.1548L14.4013 14.4839L9.73047 19.1548" stroke="#999999" strokeWidth="1.96951" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.4014 14.4839V24.9933" stroke="#999999" strokeWidth="1.96951" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.1985 21.9458C25.3375 21.3249 26.2372 20.3424 26.7557 19.1534C27.2742 17.9643 27.382 16.6365 27.062 15.3794C26.7421 14.1223 26.0126 13.0076 24.9887 12.2111C23.9648 11.4146 22.7049 10.9818 21.4077 10.981H19.9364C19.5829 9.61385 18.9241 8.34466 18.0096 7.26879C17.095 6.19292 15.9484 5.33839 14.6561 4.76943C13.3637 4.20046 11.9592 3.93188 10.5481 3.98387C9.13696 4.03586 7.756 4.40708 6.50901 5.0696C5.26202 5.73212 4.18144 6.66871 3.34852 7.80896C2.5156 8.94921 1.95201 10.2634 1.70012 11.6529C1.44824 13.0423 1.5146 14.4707 1.89424 15.8308C2.27387 17.1909 2.95689 18.4472 3.89195 19.5053" stroke="#999999" strokeWidth="1.96951" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.0722 19.1548L14.4013 14.4839L9.73047 19.1548" stroke="#999999" strokeWidth="1.96951" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_5574_5237">
        <rect width="28.0252" height="28.0252" fill="white" transform="translate(0.388672 0.471924)"/>
        </clipPath> 
        </defs>
    </svg>

  );
}

