import { ChevronRightIcon, PencilIcon } from '@heroicons/react/outline';
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { OrderSelector } from './OrderSelector';
import { getLockerColor, getPickUpTime } from './functions';
import { LockerCardContainer, CounterButton } from './LockerContainers';
import { useCountDown } from 'hooks/useCountDown';

export const LockerCard = ({locker, selected, selectLocker, setLockerToEdit, manageOrder, openOrdersPanel}) => {
  const { t } = useTranslation();

  const endTime = useMemo(() => getPickUpTime(locker.order), [locker.order]);

  const { millisecLeft } = useCountDown({endTime});
  
  const isCardDisabled = !locker.active || (locker.counter && !locker.order_list.length) || (!locker.counter && !locker.order);

  const openLockerPopup = () => setLockerToEdit(locker || {});

  const toggleSelect = () => {
    if (isCardDisabled) {
      openOrdersPanel();
    } else if (locker) {
      selectLocker(locker);
    };
  };

  return (
    <LockerCardContainer
      onClick={toggleSelect}
      className={`${selected ? 'selected' : ''} ${isCardDisabled ? 'disabled' : ''}`}
    >
      <header style={{background: getLockerColor(locker, millisecLeft)}}>
        <h3>
          {locker?.name || t('locker')}
        </h3>
        {locker &&
          <button disabled={locker.order || locker.order_list?.length}>
            <PencilIcon height={20} onClick={openLockerPopup}/>
          </button>
        }
      </header>
      <div>
        {locker && !locker.counter &&
          <OrderSelector locker={locker} manageOrder={manageOrder}/>
        }
        {locker && locker.counter &&
          <CounterButton disabled={isCardDisabled}>
            <b>{locker.order_list.length}</b>
            {t(locker.order_list.length === 1 ? 'order' : 'orders' )}
            <ChevronRightIcon height={20}/>
          </CounterButton>
        }
      </div>
    </LockerCardContainer>
  );
};