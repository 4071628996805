import React from "react";
import ProtectedRoute from "common/ProtectedRoute";
import HomeContainer from './HomeContainer';
import Dashboard from './pages/Dashboard';
import HomeGeneralPage from './pages/HomeGeneral';
import {HomeTipsTotal} from "./pages/HomeTipsTotal";
import {HomeRevenueCount} from "./pages/HomeRevenueCount";
import {Crm} from "./pages/Crm";


const HomeRoutes = () => (
  <>
    {/* home */}
    <ProtectedRoute
      exact
      path='/home'
      component={Dashboard}
      layout={HomeContainer}
    />
    <ProtectedRoute
      exact
      path='/home/users'
      component={HomeRevenueCount}
      layout={HomeContainer}
    />
    <ProtectedRoute
      exact
      path='/home/rating'
      component={HomeGeneralPage}
      layout={HomeContainer}
    />
    <ProtectedRoute
      exact
      path='/home/tips_total'
      component={HomeTipsTotal}
      layout={HomeContainer}
    />
    <ProtectedRoute
      exact
      path='/home/crm'
      component={Crm}
      layout={HomeContainer}
    />
    {/* comodin */}
  </>
);

export default HomeRoutes;
