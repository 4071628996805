import React from 'react';
import Select, { components } from "react-select";
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from '../../data/authContext';
import { select_styles } from 'logic/defaults';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { baseAuth } from '../../logic/api';

export default function AgentsRestaurantsFilter() {

  const { t } = useTranslation();
  const {
    state: { selectedAgentsRestaurants },
    setSelectedAgentsRestaurants,
  } = React.useContext(AuthContext);
  const [restaurants, setRestaurants] = React.useState([]);
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const isRowBased = useMediaQuery("(max-width: 760px)");

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null()}
          />{" "}
          <span></span>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const initializeSelectedTunnelRestaurants = () => {
    let selectedRestaurants;
    try {
      const selectedRestaurantsJSON = localStorage.getItem('yumminn-selectedAgentsRestaurants');
      if (selectedRestaurantsJSON) {
        selectedRestaurants = JSON.parse(selectedRestaurantsJSON);
      }
      selectedRestaurants = selectedRestaurants?.length > 0 ? selectedRestaurants : [];
    } catch (e) {
      console.log(e.toString(), "<- initializeSelectedTunnelRestaurants exception (AgentsRestaurantsFilter)");
      selectedRestaurants = [];
    }
    setSelectedAgentsRestaurants(selectedRestaurants);
  };

  const initializeTunnelRestaurantsList = async () => {
    try {
      const response = await baseAuth.get("/tunnel/restaurants");
      if (response?.status == 200 && response?.data) {
        setRestaurants(response.data);
      }
    } catch(e) {
      console.log(e.toString(), "<- initializeTunnelRestaurantsList exception (AgentsRestaurantsFilter)");
    }
  };

  React.useEffect(() => {
    initializeTunnelRestaurantsList();
    initializeSelectedTunnelRestaurants();
  }, []);

  return (
    <div className={`flex flex-col items-start my-3 ${!isRowBased && "w-2/5"} ${isRowBased && 'justify-center'}`} >
     <div className='text-base font-medium text-[#575757]'>{t('Restaurants')}</div>
      <Select
        isMulti={true}
        menuPortalTarget={document.body}
        multiple={true}
        isClearable={false}
        components={{
          Option
        }}
        closeMenuOnSelect={false}
        styles={select_styles}
        hideSelectedOptions={false}
        className={isRowBased ? '' : 'w-full max-w-md text-[14px]'}
        name={'restaurante'}
        placeholder={t('Restaurants')}
        options={restaurants}
        value={selectedAgentsRestaurants?.length > 0 ? selectedAgentsRestaurants : ""}
        onChange={(a) => {
          setSelectedAgentsRestaurants(a);
        }}
        style={{fontFamily: 'Poppins, sans-serif', fontSize: isRowBased ? "14px" : "16px", fontWeight: 700, display: user.is_investor ? "none" : ""}}
      />
    </div>
  );
}
