import React, { useState, useEffect, useContext } from 'react';
import styled from "styled-components";
import { apiAuth } from 'logic/api';
import { sayError } from 'logic/defaults';
import { toast } from 'react-toastify';
import { HeaderMockup } from '../components';
import { useTranslation, Trans } from 'react-i18next';
import  WalletCardPreview from "./WalletCardPreview";
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import Spinner from 'common/Spinner';
import { Context as AuthContext } from '../../../data/authContext';

export const TopupsPreview = ({setRestaurantCreditId, restaurantCreditId, topUpValues, loadingTopups}) => {
    const {state: { selectedRestaurantId, selectedRestaurant }} = useContext(AuthContext);
    const { t } = useTranslation();
    const [restaurantName, setRestaurantName] = useState("")
    const [data, setData] = useState(null);
    const [theme, setTheme] = useState('light');
    const [colors, setColors] = useState({primary: '#5FB894', secondary: '#FFFFFF', tertiary: null});
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (id) => {
        let isMounted = true
        if(isMounted){
            setIsLoading(true);
            try {
              const { data: newData } = await apiAuth(`/restaurants/${id}/`, {method: 'GET'});
              setData(newData);
              setRestaurantName(newData?.name)
              setTheme(newData?.theme || 'light')
              setColors({
                primary: newData?.primary_color,
                secondary: newData?.secondary_color,
                tertiary: newData?.tertiary_color || null,
              });
            } catch (error) {
              console.error(error);
              toast.error(sayError(error.response.data));
            } finally {
              setIsLoading(false);
            }
        }

        return(() => {
            isMounted = false
        })
       
      };

    useEffect(() => {
        let isMounted = true
        if(isMounted){
            selectedRestaurantId && getData(selectedRestaurantId);

        }
        return(() => {
            isMounted = false
        })
    }, [selectedRestaurantId]);

    const findExtraCredits = () => {
        const firstTopUpWithBonus  = topUpValues.find((topUp) => topUp.credit__bonus > 0)
        if(firstTopUpWithBonus){
            const percentageBonus = Number(firstTopUpWithBonus.credit__bonus)
            return percentageBonus;
        }
    }
    
    if(loadingTopups){
        return(
            <Spinner />
        )
    }

    return (
        <>
            <HeaderMockup themeName={theme}/>
            
            <WalletOuterContainer theme={theme}>
                <WalletCover>
                    <h1 style={{whiteSpace: 'nowrap'}}>
                        {t('topup_your_wallet')}
                    </h1>
                    <h2>
                        <Trans
                            i18nKey="top up your wallet in order to pay for products in this restaurant" 
                            values={{ restaurantName: restaurantName }}
                        >
                            Recarga tu monedero para poder pagar los productos de 
                        </Trans>
                        {findExtraCredits() > 0 &&
                            <Trans
                                i18nKey="and you will obtain" 
                                values={{ extraCredits: `${findExtraCredits()}%` }}
                            >
                                y obtendrás extra
                            </Trans>
                        }
                    </h2> 
                </WalletCover>
            
                <CardsContainer>
                    {topUpValues?.sort((a, b) => a.credit__id - b.credit__id).map((item, index) =>
                        <WalletCardPreview key={'topUp' + index} selected={item.id === restaurantCreditId} item={item} theme={theme} />
                    )}
                </CardsContainer>
                <StickyFooter>
                    <Button
                        size={BUTTON_SIZES.XS}
                        label = {t('continue')}
                        variant={BUTTON_VARIANTS.PRIMARY}
                        style={{width: "100%"}}
                    />
                </StickyFooter>


            </WalletOuterContainer>
        </>

 
    )
}


const WalletOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background: ${({background, theme}) => `${theme.background} url(${background})`};
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: var(--dvh);
  min-height: 100dvh;
  padding: 44px 10px 24px;
  margin-top: -44px;
  box-sizing: border-box;
`;

const WalletCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: calc(100% + 48px);
  min-height: ${({background}) => background ? '30vh' : 'auto'};
  background-image:
    linear-gradient(${({background, theme}) => background && theme.cover_overlay}),
    url(${({background}) => background});
  background-size: cover;
  padding: 20px 30px 35px;
  margin: 0 -24px -24px;
  box-sizing: border-box;

  img {
    object-fit: contain;
    width: 80%;
  }

  hr {
    border: 1px solid ${({theme}) => theme.text};
    width: 100%;
    margin-top: -5px;
  }

  h1, h2, p {
    text-align: center;
    line-height: 1.3;
    margin: 0;
    color: ${({theme}) => theme.text};
  }

  p {
    font-size: 8px;
  }

  h1 {
    font-size: 12px;
    font-weight: 600;
  }

  h2 {
    font-size: 8px;
    font-weight: 500;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  gap: 5px;
`;

const StickyFooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  align-items: flex-end;
  height: 100px;
`;

const StickyFooter = ({
  children
}) => {
  return (
    <StickyFooterContainer>
      {children}
    </StickyFooterContainer>
  )
};
