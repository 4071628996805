import React from 'react';
import { useTranslation } from "react-i18next";
import { UserIcon, CalendarIcon, ClockIcon } from '@heroicons/react/outline'

const fillWithLeftZero = (value) => {
  const valueString = value.toString();
  return valueString.length > 1 ? valueString : "0" + valueString;
};

const BookingGeneralInfo = ({booking}) => {

  const { t } = useTranslation();
  const booking_date = new Date(booking.date)

  const formatted_booking_date = booking_date.toLocaleString("es-ES", {
    weekday: 'long',
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
  return (
    <div style={{marginTop: "15px", display: 'flex', flexDirection: 'column', gap: "10px"}}>
		<div style={{display: 'flex'}}>
      <div style={{ backgroundColor: "#E7FBF3", padding: "5px 10px", display: 'flex', alignItems: 'center', gap: "5px", borderRadius: "5px"}}>
        <UserIcon height={23}/>
        <p style={{fontSize: "14px"}}><b>{booking.diners}</b> {booking.diners > 1 ? t('diners') : t('diner')}</p>
      </div>
    </div>	
    <div style={{display: 'flex', padding: "5px 10px", alignItems: 'center', gap: "5px", borderRadius: "5px"}}>
      <CalendarIcon height={23}/>
      <b style={{fontSize: "14px", textTransform: 'capitalize'}}>{formatted_booking_date}</b>
    </div>
    <div style={{display: 'flex', padding: "5px 10px", alignItems: 'center', gap: "5px", borderRadius: "5px"}}>
      <ClockIcon height={23}/>
      <b style={{fontSize: "14px"}}>{fillWithLeftZero(booking.hour)}:{fillWithLeftZero(booking.minute)}h</b>
    </div>
	</div>
  );
};
export default BookingGeneralInfo;
