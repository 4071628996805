import React, { useState } from 'react';
import { LockerCard } from './LockerCard';
import { useLockers } from './useLockers';
import { LockersProvider } from 'data/LockersContext';
import Loading from 'common/Loading';
import { EditLockerPopup } from './EditLockerPopup';
import { SidePanel } from './SidePanel';
import { CounterPanel } from './CounterPanel';
import { LockerPanel } from './LockerPanel';
import { PANELS } from './constants';
import { OrdersPanel } from './OrdersPanel';
import { HiddenButton, LockerList, LockerPageContainer } from './LockerContainers';
import { ManageOrderPopup } from './ManageOrderPopup';
import { SuccessPopup } from 'common/SuccessPopup';
import { MetricsPanel } from './MetricsPanel';

const LockersPage = () => {
  const { lockers } = useLockers();

  const [lockerToEdit, setLockerToEdit] = useState(null);
  const [orderAndPromiseToManage, setOrderAndPromiseToManage] = useState(null);
  const [selectedLockerId, setSelectedLockerId] = useState(null);
  const [sidePanel, setSidePanel] = useState(PANELS.ORDERS);
  const [sideOrder, setSideOrder] = useState(null);
  const [successPopup, setSuccessPopup] = useState(null)

  const closeLockerPopup = () => setLockerToEdit(null);
  const closeOrderPopup = () => setOrderAndPromiseToManage(null);
  const closeSideOrder = () => setSideOrder(null);
  const closeSuccessPopup = () => setSuccessPopup(null);
  
  const addLocker = () => setLockerToEdit({});

  const manageOrder = (order, promise) => {
    setOrderAndPromiseToManage({ order, promise });
  };

  const selectLocker = locker => {
    setSelectedLockerId(locker.id);
    setSidePanel(locker.counter ? PANELS.COUNTER : `${PANELS.LOCKER}-${locker.id}`);
  };
  
  const openOrdersPanel = () => {
    setSelectedLockerId(null);
    setSidePanel(PANELS.ORDERS);
  };
  
  const closePanel = () => {
    setSelectedLockerId(null);
    setSidePanel(prev => prev !== PANELS.ORDERS ? PANELS.ORDERS : PANELS.METRICS);
  };

  const onClickOutside = event => {
    if (event.target.tagName !== 'UL' || !selectedLockerId) return;
    closePanel();
  };

  const openSuccessPopup = props => setSuccessPopup({close: closeSuccessPopup, ...props});

  if (!lockers) return (
    <Loading/>
  );

  return (
    <LockerPageContainer>
      <LockerList onClick={onClickOutside} className={!!sideOrder ? 'disabled' : ''}>
        {lockers.map(locker =>
          <LockerCard
            key={locker.id}
            locker={locker}
            selected={selectedLockerId === locker.id}
            selectLocker={selectLocker}
            setLockerToEdit={setLockerToEdit}
            manageOrder={manageOrder}
            setSideOrder={setSideOrder}
            openOrdersPanel={openOrdersPanel}
          />
        )}
      </LockerList>
      <SidePanel panel={sidePanel} sideOrder={sideOrder} openOrdersPanel={openOrdersPanel} closeSideOrder={closeSideOrder}>
        {sidePanel === PANELS.METRICS &&
          <MetricsPanel openOrdersPanel={openOrdersPanel}/>
        }
        {sidePanel === PANELS.COUNTER &&
          <CounterPanel counterId={selectedLockerId} manageOrder={manageOrder} setSideOrder={setSideOrder} close={closePanel}/>
        }
        {sidePanel.includes(PANELS.LOCKER) &&
          <LockerPanel lockerId={selectedLockerId} manageOrder={manageOrder} close={closePanel}/>
        }
        {sidePanel === PANELS.ORDERS &&
          <OrdersPanel sideOrder={sideOrder} manageOrder={manageOrder} openLocker={selectLocker} setSideOrder={setSideOrder} close={closePanel}/>
        }
      </SidePanel>
      {lockerToEdit &&
        <EditLockerPopup locker={lockerToEdit} close={closeLockerPopup}/>
      }
      {orderAndPromiseToManage &&
        <ManageOrderPopup {...orderAndPromiseToManage} close={closeOrderPopup} openSuccessPopup={openSuccessPopup}/>
      }
      <SuccessPopup isOpen={!!successPopup} close={closeSuccessPopup} {...successPopup} className='responsive_size'/>
      <HiddenButton id="add_locker" onClick={addLocker}/>
    </LockerPageContainer>
  );
};

export const Lockers = () => <LockersProvider><LockersPage/></LockersProvider>;