import React from 'react';
import WalletTableHeader from './WalletTableHeader';
import WalletRow from './WalletRow';
import styled from "styled-components";
import { TopupsEmptyStateIcon } from 'common/EmptyStateIcons';
import EmptyState from 'common/EmptyState';
import { useTranslation } from 'react-i18next';

const RowContainer = styled.div`
	cursor: pointer;
	user-select: none;
`;

const AnimatedRowContainer = styled.div`
  cursor: pointer;
  user-select: none;
  animation: blink 1s linear 5;
  -webkit-animation: blink 1s linear 5;

  @keyframes blink {
		25% {
			opacity: 0.5;
		} 
		50% {
			opacity: 0;
		} 
		75% {
			opacity: 0.5;
	}
  }
`;

const TableWrapper = styled.div`
	max-height: 1040px;
	overflow-y: auto;
`;

const TopupsTable = ({topups, selectedTopup, setSelectedTopup, incomingTopupsIds, notOpenedIncomingTopupsIds, setNotOpenedIncomingTopupsIds}) => {
	const {t} = useTranslation();

	const handleSelect = (topup) => {
		const updatedNotOpenedIds = notOpenedIncomingTopupsIds.filter(n_i => n_i !== topup.id)
		setSelectedTopup(topup)
		setNotOpenedIncomingTopupsIds(updatedNotOpenedIds)
	};

	return (
		<div>
			<WalletTableHeader opacity={Boolean(topups?.length === 0)} />
			{topups?.length > 0 ?
				<TableWrapper>
					{topups?.map(topup =>
					incomingTopupsIds.includes(topup.id) ?
						<AnimatedRowContainer key={'order_' + topup.id} onClick={() => handleSelect(topup)}>
							<WalletRow topup={topup} selected={selectedTopup?.id === topup.id} isNotOpened={notOpenedIncomingTopupsIds.includes(topup.id)}/>
						</AnimatedRowContainer>
					:
						<RowContainer key={'order_' + topup.id} onClick={() => handleSelect(topup)}>
							<WalletRow topup={topup} selected={selectedTopup?.id === topup.id} isNotOpened={notOpenedIncomingTopupsIds.includes(topup.id)}/>
						</RowContainer>
					)}
				</TableWrapper>
			:
				<TableWrapper>
					<EmptyState text={t('no_topups_yet')} icon={<TopupsEmptyStateIcon />} />
				</TableWrapper>
			}
		</div>
  );
};
export default TopupsTable;
