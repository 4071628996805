import { ExclamationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tooltip } from './Tooltip';

export const InfoTooltip = ({
  info,
  children,
  className = '',
  ...tooltipProps
}) => {
	const { t } = useTranslation();

  return (
    <InfoContainer
      className={className}
    >
      <ExclamationCircleIcon/>
      <Tooltip {...tooltipProps}>
        {info &&
          <p>
            {t(info)}
          </p>
        }
        {children}
      </Tooltip>
    </InfoContainer>
  );
};

export const InfoContainer = styled.div`
  position: relative;
  display: inline-flex;
  
  & > svg {
    height: 16px;
    margin-bottom: -2px;
    color: #A3A3A3;
    cursor: help;
    rotate: 180deg;
  }
`;