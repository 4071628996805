import { Checkbox, TextInput } from 'common/Input';
import React, { useContext, useState } from 'react';
import { CouponSelector } from './CouponSelector';
import { Icon, IconType } from 'common/Icon';
import { formatDate } from 'pages/discounts/components/discountFunctions';
import { SECOND } from 'logic/defaults';
import { SubsidiesContext } from './SubsidiesContext';

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const SubsidyRow = ({ entry, setEntryToDelete }) => {
  const {
    changeEntry,
    saveEntry,
    deleteEntry,
    clearDraft,
    isValidating,
    couponOptions,
  } = useContext(SubsidiesContext);

  const [email, setEmail] = useState(entry?.email || '');
  const [inputError, setInputError] = useState(null);
  
  const rowId = entry.id ? `row_${entry.id}` : `row_new`;

  const rowRef = node => {
    if (!node) return;

    !entry.id && document.getElementById('new_entry_input')?.focus()
    
    if (!entry.id || (
      node.dataset?.notified !== entry.modified_at &&
      (Date.now() - Date.parse(entry.modified_at) <= 5 * SECOND)
    )) {
      const handleAnimationEnd = event => {
        event.target.classList.remove('modified');
        event.target.removeEventListener('animationend', handleAnimationEnd);
      };

      node.addEventListener('animationend', handleAnimationEnd);
      node.classList.add('modified');
      node.dataset.notified = entry.modified_at;
    };
  };

  const changeAndRevalidateEmail = event => {
    const value = event.target.value;

    if (inputError && EMAIL_REGEX.test(value)) {
      setInputError(null);
    }

    setEmail(value);
    !entry.id && changeEntry({email});
  };

  const validateEmailAndSave = event => {
    const value = event.target.value;
    const hasChanges = !entry.id || entry.email !== value;

    if (!value || !hasChanges) {
      entry.id && setEmail(entry.email);
      setInputError(null);
      return;
    };

    if (EMAIL_REGEX.test(value)) {
      entry.coupon && saveEntry({...entry, email: value});
    } else {
      setInputError(`invalid_email`);
    };
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.target.value && validateEmailAndSave(event);
    } else if (event.key === 'Escape') {
      entry.id ? setEmail(entry.email) : clearDraft();
      event.target.blur();
      setInputError(null);
    }
  };

  const selectCoupon = ({coupon}) => {
    !entry.id && changeEntry({coupon});
    EMAIL_REGEX.test(email) && saveEntry({...entry, coupon, email});
  };
  
  const togglePreselected = event => {
    const preselected = event.target.checked;
    
    !entry.id && changeEntry({preselected});
    saveEntry({...entry, preselected});
  };

  const deleteSubsidy = () => {
    entry.id ? setEntryToDelete(entry) : deleteEntry();
  };
  
  return (
    <tr id={rowId} ref={rowRef}>
      <td className='col_id'>
        <span>{entry.id || '-'}</span>
      </td>
      <td className='col_email'>
        <TextInput
          id={entry.id ? `entry_input_${entry.id}` : 'new_entry_input'}
          value={email}
          error={inputError}
          placeholder={`subsidy_email_placeholder`}
          autocomplete='off'
          onChange={changeAndRevalidateEmail}
          disabled={isValidating}
          onBlur={validateEmailAndSave}
          onKeyDown={handleKeyDown}
        />
      </td>
      <td className='col_coupon'>
        <CouponSelector
          coupon={entry.coupon}
          options={couponOptions}
          onChange={selectCoupon}
        />
      </td>
      <td className='col_preselected'>
        <Checkbox
          label={'selected'}
          checked={entry.preselected}
          onChange={togglePreselected}
        />
      </td>
      <td className='col_created'>
        {entry.created_at ? formatDate(entry.created_at) : '-'}
      </td>
      <td className='col_modified'>
        {entry.modified_at ? formatDate(entry.modified_at) : '-'}
      </td>
      <td className='col_delete'>
        <Icon
          type={IconType.TRASH}
          size={20}
          disabled={isValidating}
          onClick={deleteSubsidy}
        />
      </td>
    </tr>
  );
};
