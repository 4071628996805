import moment from "moment";

const { calculateEuros } = require("services/payments");

export const formatDate = dateStr => moment(dateStr).format('DD/MM/YYYY  HH:MM');

export const getDiscountTitle = (discount, t) => {
  if (discount.code) {
    return `${t('code')}: ${discount.code}`;
  }

  if (discount.payment_details_text) {
    return discount.payment_details_text;
  };

  let stringTitle = '';
  let valueString = '';

  if (discount.discount_type === 'percentage') {
    valueString = `${discount.value}`;
  } else if (discount.discount_type === 'amount') {
    const euros = calculateEuros(discount.value);
    valueString = `${euros}€`;
  };

  if(discount.products__id){
    const count = discount.products__id.length;
    const productCountKey = count === 1 ? 'product' : 'products';

    stringTitle = t(`discountTitle${discount.discount_type.charAt(0).toUpperCase() + discount.discount_type.slice(1)}`, {
      value: parseFloat(valueString),
      count,
      productCount: t(productCountKey),
    });
  };

  return stringTitle;
};

export const getDiscountStatus = discount => {
  if (
    discount.valid_since && discount.valid_until && (
      Date.parse(discount.valid_since) > Date.now() ||
      Date.parse(discount.valid_until) < Date.now()
    )
  ) {
    return 'period_limit_reached';
  }

  if (discount?.max_total_uses && (discount.uses >= discount.max_total_uses)) {
    return 'uses_limit_reached';
  }

  if (discount?.max_cents_redeemable && (discount.redeemed_cents >= discount.max_cents_redeemable)) {
    return 'cents_limit_reached';
  }

  return discount.active ? 'operative' : 'disabled';
};
