import { Popup } from 'common/Popup';
import React, { useContext, useMemo, useState } from 'react';
import { OrderData } from './OrderData';
import { ClientCardContainter, ManageOrderContainer } from './LockerContainers';
import { OrdersEmptyStateIcon } from 'common/EmptyStateIcons';
import { LockersContext } from 'data/LockersContext';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'common/Input';
import Select from 'react-select';
import { getEmptyLockersOptions, getPickUpTime, moveOrdertoLocker } from './functions';
import { locker_manage_select_styles } from 'logic/defaults';
import { LockerOption, LockerSingleValue } from './SelectComponents';
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';
import { Context } from 'data/authContext';

const ACTIONS = {
  NONE: 0,
  CONFIRM_PICKUP: 1,
  MOVE_TO_LOCKER: 2,
  REMOVE_FROM_LOCKER: 3,
  SEND_REMINDER: 4,
};

const COUNTER_LABELS = ['', 'remove_from_counter', 'to_locker', 'to_kitchen', 'send_reminder'];
const LOCKER_LABELS = ['', 'withdrawn', 'to_counter', 'to_kitchen', 'send_reminder'];
const CONFIRM_LABELS = ['', 'yes_withdrawn', 'no_to_counter', 'no_to_kithcen', 'send_reminder'];

export const ManageOrderPopup = ({ order, promise, openSuccessPopup, close }) => {
	const { state: { selectedRestaurantId } } = useContext(Context);
  const { state: { lockers }, moveOrder, deleteOrder } = useContext(LockersContext);

  const { t } = useTranslation();
  
  const locker = useMemo(() => lockers.find(locker => {
    return locker.counter ? locker.order_list.some(item => item.id === order.id) : locker.order?.id === order.id;
  }), [order.id]);

  const counter = useMemo(() => {
    return locker.counter ? locker : lockers.find(locker => locker.counter)
  }, [order.id]);

  const lockerOptions = useMemo(() => {
    return getEmptyLockersOptions({lockers, excludeCounter: locker.counter});
  }, [lockers]);

  const [action, setAction] = useState(ACTIONS.NONE);
  const [selectedLocker, setSelectedLocker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recursiveOrderPromise, setRecursiveOrderPromise] = useState(null);

  const toggleAction = action => {
    setAction(prev => prev === action ? ACTIONS.NONE : action);

    if (action === ACTIONS.MOVE_TO_LOCKER && !locker.counter) {
      setSelectedLocker(lockerOptions.find(locker => locker.value === counter.id));
    } else {
      setSelectedLocker(null);
    };
  };

  const selectLocker = locker => {
    setAction(ACTIONS.MOVE_TO_LOCKER);
    setSelectedLocker(locker);
  };
  
  const recursiveClose = () => setRecursiveOrderPromise(null);

  const manageOrder = (order, promise) => {
    setRecursiveOrderPromise({ order, promise });
  };

  const getLabel = action => {
    const label = (promise ? CONFIRM_LABELS : locker.counter ? COUNTER_LABELS : LOCKER_LABELS)[action];

    const options = !locker.counter && counter
      ? { counter_name: counter.name }
      : locker.counter
      ? { counter_name: locker.name}
      : null;

    return t(label, options);
  };

  const renderCheckbox = thisAction => (
    <Checkbox
      checked={action === thisAction}
      onChange={() => toggleAction(thisAction)}
      label={getLabel(thisAction)}
    />
  );

  const submit = async () => {
    if (!action || (action === ACTIONS.MOVE_TO_LOCKER && !selectedLocker)) return;

    setIsLoading(true);

    try {
      if (action === ACTIONS.CONFIRM_PICKUP) {
        await baseAuth.get(`locker/confirm_pickup/${order.id}`);
        deleteOrder({ order: order.id, closed: true });
        promise && await promise();
        openSuccessPopup({title: t(`pickup_success_${locker.counter ? 'counter' : 'locker'}`)});
        close();
      }
      
      if (action === ACTIONS.MOVE_TO_LOCKER) {
        const newPromise = async () => {
          await moveOrdertoLocker({order: order.id, locker: selectedLocker.value, moveOrder, t});
          promise && await promise();

          const title = locker.counter ? t('move_to_locker_success', {locker: selectedLocker.label}) : t('move_order_success');
          
          openSuccessPopup({
            title,
            message: t('email_reminder_message'),
            children: <ClientCard name={order.client_name} email={order.client_email}/>}
          );

          close();
        };
    
        const newLocker = lockers.find(locker => locker.id === selectedLocker.value);
        newLocker.order ? manageOrder(newLocker.order, newPromise) : await newPromise();
      }
      
      if (action === ACTIONS.REMOVE_FROM_LOCKER) {
        await baseAuth.delete(`locker/delete_order/${order.id}`);
        deleteOrder({ order: order.id });
        promise && await promise();
        close();
      }

      if (action === ACTIONS.SEND_REMINDER) {
        if (!locker) return;
        
        await baseAuth.post('locker/send_mail', {
          restaurant_id: selectedRestaurantId,
          mail_type: 'locker_reminder',
          client_email: order.client_email,
          client_name: order.client_name,
          order_code: order.code,
          pickup_date: getPickUpTime(order)?.toISOString(),
          locker_name: locker.name,
          locker_key: locker.key || null,
          is_counter: locker.counter
        });

        openSuccessPopup({
          title: t('email_reminder_success'),
          children: <ClientCard name={order.client_name} email={order.client_email}/>
        });
        
        close();
      }
    } catch (error) {
      console.error(error);
      toast.error('error_manage_order');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popup isOpen close={close}>
      <ManageOrderContainer>
        <main>
          <div className='manage'>
            <OrdersEmptyStateIcon />
            <h3>
              <Trans i18nKey={promise ? 'confirm_pickup_title' : 'manage_order_title'}>
                What do you want to do<br/><b>with the order {{code: order.code}}</b>?
              </Trans>
            </h3>
            {locker.counter &&
              <div>
                <p>{t('select_an_option') + ':'}</p>
                {renderCheckbox(ACTIONS.CONFIRM_PICKUP)}
                <Select
                  name='locker'
                  className={(action === ACTIONS.MOVE_TO_LOCKER && selectLocker) ? 'selector_active' : ''}
                  placeholder={getLabel(ACTIONS.MOVE_TO_LOCKER)}
                  value={selectedLocker}
                  options={lockerOptions}
                  styles={locker_manage_select_styles}
                  onChange={selectLocker}
                  components={{SingleValue: LockerSingleValue, Option: LockerOption}}
                  isLoading={isLoading}
                  hideSelectedOptions
                  menuPlacement='auto'
                  maxMenuHeight={300}
                />
                {locker &&
                  renderCheckbox(ACTIONS.SEND_REMINDER)
                }
              </div>
            }
            {!locker.counter &&
              <div>
                <p>{t('select_an_option') + ':'}</p>
                {renderCheckbox(ACTIONS.CONFIRM_PICKUP)}
                {counter &&
                  renderCheckbox(ACTIONS.MOVE_TO_LOCKER)
                }
                {renderCheckbox(ACTIONS.REMOVE_FROM_LOCKER)}
              </div>
            }
          </div>
          <div className='order_card'>
            <h4>
              {locker.name}
            </h4>
            <OrderData order={order}/>
          </div>
        </main>
        <footer>
          <button className='cancel' onClick={close}>
            {t('cancel')}
          </button>
          <button onClick={submit} disabled={!action || (action === ACTIONS.MOVE_TO_LOCKER && !selectedLocker)}>
            {t('confirm')}
          </button>
        </footer>
      </ManageOrderContainer>
      {recursiveOrderPromise &&
        <ManageOrderPopup {...recursiveOrderPromise} close={recursiveClose}/>
      }
    </Popup>
  );
};

const ClientCard = ({name, email}) => {
  const { t } = useTranslation();

  return (
    <ClientCardContainter>
      <p>
        <span>{t('client')}</span>
        {name}
      </p>
      <p>
        <span>{t('email')}</span>
        {email}
      </p>
    </ClientCardContainter>
  );
};