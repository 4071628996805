import { useEffect, useState } from 'react';
import useRestaurant from '../../components/yumminn/useRestaurant';
import { apiAuth } from "../../logic/api";

const useShippingInfo = () => {
    const { seleccionado } = useRestaurant();
    const restaurantId = seleccionado && seleccionado[0]?.value;
    const [shippingInfo, setShippingInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchShippingCost = async () => {
            setLoading(true);
            try {
                const res_obj = await apiAuth.get(`/get_shipping_info/${restaurantId}`);
                setShippingInfo(res_obj.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (restaurantId) {
            fetchShippingCost();
        }
    }, [restaurantId]);

    return { shippingInfo, loading, error };

}

const updateShippingInfo = async (params) => {

    let loading = true;
    let errorMsg = null;
    let updated = false;
    const obj = {
        ...(params.restaurant_id && { restaurant_id: params.restaurant_id }),
        ...(params.delivery_radius_meters && { delivery_radius_meters: params.delivery_radius_meters }),
        ...(params.longitude && { longitude: params.longitude }),
        ...(params.latitude && { latitude: params.latitude })
    }
    try {
        loading = true;
        const response = await apiAuth.post(`/update_shipping_info/`, obj);
        updated = response.data;
    } catch (error) {
        errorMsg = error
    } finally {
        loading = false
    }

    return { updated, loading, errorMsg };

}

const updateShippingCost = async (params) => {

    let loading = true;
    let errorMsg = null;
    let updated = false;
    const obj = {
        ...(params.minimum_shipping_cost && { minimum_shipping_cost: params.minimum_shipping_cost }),
        ...(params.cost_per_km && { cost_per_km: params.cost_per_km }),
        ...(params.restaurant_id && { restaurant_id: params.restaurant_id }),
    }
    try {
        loading = true;
        const response = await apiAuth.post(`/update_shipping_cost/`, obj);
        updated = response.data;
    } catch (error) {
        errorMsg = error
    } finally {
        loading = false
    }

    return { updated, loading, errorMsg };

}

const updatePreparationMinutes = async (preparationMinutes, restaurantId) => {
    const obj = {
        preparation_minutes: preparationMinutes,
        restaurant_id: restaurantId
    }
    try {
        await apiAuth.post(`/update_preparation_minutes/`, obj);
        return "updated";
    } catch (error) {
        return "error";
    } 
}

const readFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsText(file);
    });
};

const uploadGeojsonFile = async (geojsonFile, restaurantId) => {
    if(!geojsonFile){
        return "error";
    }

    const geojsonContent = await readFile(geojsonFile);
    const obj = {
        geojson: geojsonContent,
        restaurant_id: restaurantId
    }

    try {
        await apiAuth.post(`/upload_geojson/`, obj);
        return "uploaded";
    } catch (error) {
        return `error: ${error}`;
    } 
}

const removeGeojsonFile = async (restaurantId) => {

    if(!restaurantId){
        return "error";
    }

    const obj = {
        restaurant_id: restaurantId
    }

    try {
        await apiAuth.post(`/remove_geojson/`, obj);
        return "removed";
    } catch (error) {
        return `error: ${error}`;
    } 
}



export { useShippingInfo, updateShippingInfo, updatePreparationMinutes, uploadGeojsonFile, removeGeojsonFile, updateShippingCost };

