import React, { useState, useContext, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NumberInput } from 'common/Input';
import { InputContainer } from './WalletStyles';
import { Button, BUTTON_SIZES } from 'common/Button';
import Spinner from "common/Spinner";
import { TrashIcon } from '@heroicons/react/outline';
import useRestaurant from '../../components/yumminn/useRestaurant';
import { toast } from 'react-toastify';
import { Icon, IconType } from "common/Icon";
import { MobileMockup } from "../../components/previews/MobileMockup";
import { TopupsPreview } from 'components/previews/screens/TopupsPreview';
import { UseRestaurantTopUpInfo, createCreditInfo, updateCreditInfo } from './WalletLogic';
import { Context as AuthContext } from "../../data/authContext"; 
import { isEqual, cloneDeep } from "lodash";



const WalletSettings = () => {
  const {state: { selectedRestaurantId }} = useContext(AuthContext);
  const { content: topUpValues, loading: loadingTopups } = UseRestaurantTopUpInfo(selectedRestaurantId);
  const { seleccionado } = useRestaurant();
  const { t } = useTranslation();
  const [firstRecharge, setFirstRecharge] = useState("");
  const [secondRecharge, setSecondRecharge] = useState("");
  const [thirdRecharge, setThirdRecharge] = useState("");
  const [forthRecharge, setForthRecharge] = useState("");
  const [fifthRecharge, setFifthRecharge] = useState("");
  const [sixthRecharge, setSixthRecharge] = useState("");
  const [incentive, setIncentive] = useState("");
  const [restaurantCreditId, setRestaurantCreditId] = useState(0)
  const [loadingUpdateCreditInfo, setLoadingUpdateCreditInfo] = useState(false)
  const [errorUpdate, setErrorUpdate] = useState(null)
  const restaurantId = seleccionado && seleccionado[0]?.value;
  const [newTopupValues, setNewTopupValues] = useState([]);
  const [errorFirstRecharge, setErrorFirstRecharge] = useState(null);
  const [errorSecondRecharge, setErrorSecondRecharge] = useState(null);
  const [errorThirdRecharge, setErrorThirdRecharge] = useState(null);
  const [errorForthRecharge, setErrorForthRecharge] = useState(null);
  const [errorFifthRecharge, setErrorFifthRecharge] = useState(null);
  const [errorSixthRecharge, setErrorSixthRecharge] = useState(null);
  const [error, setError] = useState(null);

  const initialTopupValues = [
    {
        "credit__name": "",
        "credit__amount": 0,
        "credit__bonus": 0,
        "credit__bonus_type": ""
    },
    {
        "credit__name": "",
        "credit__amount": 0,
        "credit__bonus": 0,
        "credit__bonus_type": ""
    },
    {
        "credit__name": "",
        "credit__amount": 0,
        "credit__bonus": 0,
        "credit__bonus_type": ""
    },
    {
        "credit__name": "",
        "credit__amount": 0,
        "credit__bonus": 0,
        "credit__bonus_type": ""
    },
    {
        "credit__name": "",
        "credit__amount": 0,
        "credit__bonus": 0,
        "credit__bonus_type": ""
    },
    {
        "credit__name": "",
        "credit__amount": 0,
        "credit__bonus": 0,
        "credit__bonus_type": ""
    }
  ];

  useEffect(() => {
    let isMounted = true;

    if(isMounted){
      setErrorFirstRecharge(null)
      setErrorSecondRecharge(null)
      setErrorThirdRecharge(null)
      setErrorForthRecharge(null)
      setErrorFifthRecharge(null)
      setErrorSixthRecharge(null)
      setError(null)

      if(topUpValues && topUpValues.length > 0){
        const topUpValuesCopy = cloneDeep(topUpValues);

        setNewTopupValues(topUpValuesCopy)
        const [first, second, third, forth, fifth, sixth] = topUpValues;
  
        setFirstRecharge(first?.credit__amount ?? 0);
        setSecondRecharge(second?.credit__amount ?? 0);
        setThirdRecharge(third?.credit__amount ?? 0);
        setForthRecharge(forth?.credit__amount ?? 0);
        setFifthRecharge(fifth?.credit__amount ?? 0);
        setSixthRecharge(sixth?.credit__amount ?? 0);
        setIncentive(first?.credit__bonus ?? 0);
      } else {
        setNewTopupValues(initialTopupValues)
        setFirstRecharge("");
        setSecondRecharge("");
        setThirdRecharge("");
        setForthRecharge("");
        setFifthRecharge("");
        setSixthRecharge("");
      }
    }
   
    return () => {
			isMounted = false;
		};
  }, [topUpValues])


  const handleDelete = (rechargeNumber) => {
    setNewTopupValues(prevState => {
      const updatedValues = [...prevState];
      if (updatedValues[rechargeNumber - 1]) {
        updatedValues[rechargeNumber - 1].credit__amount = 0;
      }
      return updatedValues;
    });

    switch(rechargeNumber){
      case 1:
        setFirstRecharge("");
        break;
      case 2:
        setSecondRecharge("");
        break;
      case 3: 
        setThirdRecharge("");
        break;
      case 4: 
        setForthRecharge("");
        break;
      case 5:
        setFifthRecharge("");
        break;
      case 6:
        setSixthRecharge("");
        break;
      default:
        return;
    }

  }

  const handleRecharge = (e, rechargeNumber) => {
    let newValue = e.target.value
    const maxDecimals = 2;
    const decimalIndex = newValue.indexOf(".");
    if (decimalIndex !== -1) {
      newValue = newValue.slice(0, decimalIndex + maxDecimals + 1);
    }
    const rechargeValue = Number(newValue)

    setNewTopupValues(prevState => {
      const updatedValues = [...prevState];
      if (updatedValues[rechargeNumber - 1]) {
        updatedValues[rechargeNumber - 1].credit__amount = rechargeValue;
      }
      const invalidIndexes = findInvalidRechargeIndexes(updatedValues) 
    
      if(invalidIndexes.length > 0){
        setInvalidIndexes(invalidIndexes)
      } else {
        setError(null)
        setErrorFirstRecharge(null)
        setErrorSecondRecharge(null)
        setErrorThirdRecharge(null)
        setErrorForthRecharge(null)
        setErrorFifthRecharge(null)
        setErrorSixthRecharge(null)
      }

      return updatedValues;
    });

    switch(rechargeNumber){
      case 1:
        setFirstRecharge(rechargeValue);
        break;
      case 2:
        setSecondRecharge(rechargeValue);
        break;
      case 3: 
        setThirdRecharge(rechargeValue);
        break;
      case 4: 
        setForthRecharge(rechargeValue);
        break;
      case 5:
        setFifthRecharge(rechargeValue);
        break;
      case 6:
        setSixthRecharge(rechargeValue);
        break;
      default:
        return;
    }

  }

  const handleIncentive = (e) => {
    let newValue = e.target.value
    const maxDecimals = 2;
    const decimalIndex = newValue.indexOf(".");
    if (decimalIndex !== -1) {
      newValue = newValue.slice(0, decimalIndex + maxDecimals + 1);
    }
    const incentiveValue = Number(newValue)

    setIncentive(incentiveValue)

    setNewTopupValues(prevState => {
      const updatedValues = [...prevState];
      updatedValues.forEach((updatedValue) => {
        updatedValue.credit__bonus = incentiveValue;
        updatedValue.credit__bonus_type = incentiveValue === 0  ? '' :  'percentage';
      })
      return updatedValues;
    });
   
  }



  const getDifferences = (arr1, arr2) => {
    let differences = [];
  
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];
  
      if (!isEqual(obj1, obj2)) {
        differences.push(obj2);
      }
    }
  
    return differences;
  }

  const findInvalidRechargeIndexes = () => {
    const invalidIndexes = [];
  
    newTopupValues.forEach((topup, index) => {
      if (topup.credit__amount <= 0) {
        invalidIndexes.push(index);
      }
    });
  
    return invalidIndexes;
  };

  const setInvalidIndexes = (invalidIndexes) => {
    if (invalidIndexes.length > 0) {
      invalidIndexes.forEach((invalidIndex) => {
        setError("you cannot save amounts with a value of zero")
        switch (invalidIndex) {
        case 0:
          setErrorFirstRecharge("you cannot save amounts with a value of zero");
          break;
        case 1:
          setErrorSecondRecharge("you cannot save amounts with a value of zero");
          break;
        case 2:
          setErrorThirdRecharge("you cannot save amounts with a value of zero");
          break;
        case 3:
          setErrorForthRecharge("you cannot save amounts with a value of zero");
          break;
        case 4:
          setErrorFifthRecharge("you cannot save amounts with a value of zero");
          break;
        case 5:
          setErrorSixthRecharge("you cannot save amounts with a value of zero");
          break;
        default:
          break;
        }
      });
    } 
    
    setLoadingUpdateCreditInfo(false);
    return;
  }

  const saveCreditInfo = async () => {
    setLoadingUpdateCreditInfo(true);
    setErrorFirstRecharge(null)
    setErrorSecondRecharge(null)
    setErrorThirdRecharge(null)
    setErrorForthRecharge(null)
    setErrorFifthRecharge(null)
    setErrorSixthRecharge(null)
    setError(null)
    const invalidIndexes = findInvalidRechargeIndexes(newTopupValues) 
    setInvalidIndexes(invalidIndexes)
    if(invalidIndexes.length > 0) return

    if (isEqual(newTopupValues, topUpValues)) {
      setLoadingUpdateCreditInfo(false);
      return;
    }

    if (topUpValues.length === 0) {
      try {
        const { updated, loading, errorMsg } = await createCreditInfo({
          restaurant_id: restaurantId,
          top_up_values: newTopupValues,
          incentive: incentive
        });
        if(updated){
          showSuccessfulToast();
        }
        if (errorMsg) {
          console.log({errorMsg})
          setErrorUpdate(errorMsg);
        } else {
          setLoadingUpdateCreditInfo(loading);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingUpdateCreditInfo(false);
      }
    } else {
      const differences = getDifferences(topUpValues, newTopupValues);
      try {
        const { updated, loading, errorMsg } = await updateCreditInfo({
          top_up_values: differences,
          incentive: incentive
        });
        if(updated){
          showSuccessfulToast();
        }
        if (errorMsg) {
          console.log({errorMsg})
          setErrorUpdate(errorMsg);
        } else {
          setLoadingUpdateCreditInfo(loading);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingUpdateCreditInfo(false);
      }
    }
  }

  const CustomToast = () => (
    <div className="custom-toast flex items-center justify-center gap-4 w-full">
      <Icon type={IconType.VERIFIED} size={50}/>
      <div className='flex flex-col '>
        <div className=' semibold text-[#094553] text-[20px] whitespace-nowrap'>{t('topups_updated_successfully')}</div>
      </div>
    </div>
  );
  
  const showSuccessfulToast = () => {
    toast((closeToast) => <CustomToast closeToast ={closeToast}/>, {
      autoClose: 5000,
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      hideProgressBar: true,
      style: { minWidth: '400px', left: "-60px" }
    });
  };

    

  if(errorUpdate ) {
    toast.error(t('error_updating_credit_info'))
  }

  if(loadingTopups){
    return(
      <Spinner/>
    )
  }

  return (
    <div className='flex flex-wrap w-full p-4 gap-6 justify-start'>

      <div className="flex bg-[#FFF] rounded-3xl p-9 gap-12 items-center">
        <div className="flex flex-col gap-4">
          <div id='card_title' className='flex flex-row gap-2'>
            <span className="text-[24px] font-semibold text-[#404040]">{t('wallet_settings')}</span>
          </div>
        
          <div id='separator' className='border-solid border border-[#BFBFBF]'></div>

          <div className='text-base'>{t('edit_amounts_clicking_on_inputs')}</div>

          <InputContainer>

            <div className="column">
              <div className='row'>
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0.01}
                  placeholder={t('first_recharge_example')}
                  name='first_recharge'
                  value={firstRecharge}
                  className='recharge_input'
                  unit={'€'}
                  error={errorFirstRecharge}
                  showErrorMessage={false}
                  onChange={(e) => handleRecharge(e, 1)}
                />
                <TrashIcon height={30} className="hover:cursor-pointer" onClick={()=> handleDelete(1)}/>
              </div>


              <div className='row'>
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0.01}
                  name='third_recharge'
                  placeholder={t('third_recharge_example')}
                  value={thirdRecharge}
                  className='recharge_input'
                  error={errorThirdRecharge}
                  showErrorMessage={false}
                  unit={'€'}
                  onChange={(e) => handleRecharge(e, 3)}
                />
                <TrashIcon height={30} className="hover:cursor-pointer" onClick={()=> handleDelete(3)}/>
              </div>

              <div className='row'>
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0.01}
                  name='fifth_recharge'
                  placeholder={t('fifth_recharge_example')}
                  value={fifthRecharge}
                  error={errorFifthRecharge}
                  showErrorMessage={false}
                  className='recharge_input'
                  unit={'€'}
                  onChange={(e) => handleRecharge(e, 5)}
                />
                <TrashIcon height={30} className="hover:cursor-pointer" onClick={()=> handleDelete(5)}/>
              </div>
            
            </div>
            
            <div className="column">

              <div className='row'>
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0.01}
                  name='second_recharge'
                  placeholder={t('second_recharge_example')}
                  value={secondRecharge}
                  className='recharge_input'
                  unit={'€'}
                  error={errorSecondRecharge}
                  showErrorMessage={false}
                  onChange={(e) => handleRecharge(e, 2)}
                />
                <TrashIcon height={30} className="hover:cursor-pointer" onClick={()=> handleDelete(2)}/>
              </div>

              <div className='row'>
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0.01}
                  placeholder={t('forth_recharge_example')}
                  name='forth_recharge'
                  value={forthRecharge}
                  className='recharge_input'
                  unit={'€'}
                  error={errorForthRecharge}
                  showErrorMessage={false}
                  onChange={(e) => handleRecharge(e, 4)}
                />
                <TrashIcon height={30} className="hover:cursor-pointer" onClick={()=> handleDelete(4)}/>
              </div>
            
              <div className='row'>
                <NumberInput
                  type="number"
                  pattern="[0-9]*[.,]?[0-9]+"
                  min={0.01}
                  name='sixth_recharge'
                  placeholder={t('sixth_recharge_example')}
                  value={sixthRecharge}
                  error={errorSixthRecharge}
                  showErrorMessage={false}
                  className='recharge_input'
                  unit={'€'}
                  onChange={(e) => handleRecharge(e, 6)}
                />
                <TrashIcon height={30} className="hover:cursor-pointer" onClick={()=> handleDelete(6)}/>
              </div>

            </div>

          </InputContainer>

          <div className='text-base font-medium'>{t('general_incentive')} <span className='text-sm font-normal ml-2'>({t('optional')})</span></div> 
          <div className='text-sm font-normal mt-2 max-w-[380px]'>
            <Trans i18nKey={'incentive_explanation'}>
              El porcentaje del incentivo se aplicará como <b>incremento sobre el importe de la recarga.</b>
            </Trans>
          </div> 

          <div className='text-sm font-normal mt-[5px] max-w-[380px]'>
            <Trans i18nKey={'incentive_example'}>
              Ejemplo: <i>Si el incentivo es de un 10%, una recarga de 10€ generará 1€ extra de créditos en el monedero.</i>
            </Trans>
          </div> 

          <NumberInput
            type="number"
            pattern="[0-9]*[.,]?[0-9]+"
            min={0.01}
            name='incentive'
            value={incentive}
            placeholder={''}
            className='recharge_input'
            unit={'%'}
            onChange={(e) => handleIncentive(e)}
          />

          <Button 
            className={"mt-2"}
            size={BUTTON_SIZES.LG}
            onClick={saveCreditInfo}
            disabled={loadingUpdateCreditInfo || error}
            loading={loadingUpdateCreditInfo}
            label = {t('save')}
          />
          {error && 
            <div style={{position: "relative"}}>
              <i style={{width: "300px", fontWeight: 600, fontSize: "14px", borderRadius: "5px", padding: "10px 10px 10px 15px", textAlign: 'center', position: "absolute", backgroundColor: "#FFDCDA", bottom: "75px"}}>
                {t(error)}
              </i>
            </div>
          }

        </div>

        <MobileMockup>
          <TopupsPreview 
            setRestaurantCreditId={setRestaurantCreditId} 
            restaurantCreditId={restaurantCreditId}
            topUpValues={newTopupValues}
            loadingTopups={loadingTopups}
          />
        </MobileMockup>
      </div>

       
    </div>
  );
};


export default WalletSettings;