import XButton from 'common/XButton';
import { apiAuth, baseAuth } from 'logic/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLazyAxios } from 'use-axios-client';
import {toast} from 'react-toastify';
import {sayError} from "../../../logic/defaults";
import Spinner from 'common/Spinner';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import { PencilIcon, TrashIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import i18next from "i18next";

export default function RestaurantsList() {
  const { t } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false)
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const [restaurantList, setRestaurantList] = React.useState([])
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState([])
  

  const handleDelete = async (r) => {
    try {
      await apiAuth.delete(`/restaurants/${r.id}`);
      toast.success(`${t('restaurant_deleted')}`);
      history.push('/admin/restaurants')
      setRefresh(true)
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }

  const handleVerifyApplePay = async (r) => {
    try {
      await baseAuth.get(`/payment/verify-apple-pay-domain?restaurant=${r.id}`);
      toast.success(`${t('Verified')}`);
      setRefresh(true)
      getRestaurantList()
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }

  const changePaymentMethodVisibility = async (r_id, p) => {
    try {
      const body = {
        restaurantId: r_id,
        paymentMethod: p.name
    }
      await baseAuth.post(`/payment/change-payment-method-visibility`, body);
      toast.success(`${t('Changed')}`);
      setRefresh(true)
      getRestaurantList()
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }

  const [getData, { data, error }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants-admin-new/get_restaurants_admin`,
  });


  React.useEffect(() => {
    getDataCompanies();
    getData();
  }, [getData, restaurantList]);


  const getNewData = useCallback(() => {
    let newData = {...data}

    if (data) {
      let restaurantsInfo = newData.results;
      restaurantsInfo.sort(function(a,b){ return a.id - b.id})
      
      for (let i= 0; i < restaurantsInfo.length; i++){
        for (let x= 0; x < companies.length; x++){
          let idCompanyInRestaurantsInfo = restaurantsInfo[i].company 
          if (idCompanyInRestaurantsInfo === companies[x].id){
            restaurantsInfo[i]["owner_name"] = companies[x].owner_name
            restaurantsInfo[i]["company_name"] = companies[x].name
          }
        }
        if (restaurantsInfo[i]["logo"]?.length > 0) {
          restaurantsInfo[i]["logo_url"] = process.env.REACT_APP_AWS_S3_URL + restaurantsInfo[i]["logo"].replace("/media", "");
        }
      }
      setRefresh(false)
      setFields(restaurantsInfo)
      setAvailablePaymentMethods(newData.available_payment_methods)
    }
    
  }, [companies, data, setFields, setAvailablePaymentMethods]) 
  
  React.useEffect(() => {
    getNewData();
  }, [getNewData, setFields, setAvailablePaymentMethods, refresh]);

  const getDataCompanies = async () => {
    try {
      const res_obj = await apiAuth.get(`companies/get_companies_bo_data/`);
      const res_data = res_obj.data.results;
      setCompanies(res_data?.map(u => ({id: u.id, owner_name: u.owner_name, name: u.name})));
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }

  const makeManager = async(restaurant,account_manager) => {
    if(user && user.id){
      if(account_manager){
        if (window.confirm(t('confirm change manager'))){
          restaurant.account_manager = user.id
          apiAuth.patch(`/restaurants-admin-new/${restaurant.id}`, {'account_manager':restaurant.account_manager}).then((res)=>{
            toast.success(`${t('update_ok')}`);
            setRefresh(true)
            getRestaurantList()
          },(err)=>{
          console.log(err)
          })
        }
      }else{
        if (window.confirm(t('confirm manager'))){
          restaurant.account_manager = user.id
          apiAuth.patch(`/restaurants-admin-new/${restaurant.id}`, {'account_manager':restaurant.account_manager}).then((res)=>{
            toast.success(`${t('update_ok')}`);  
            setRefresh(true)
            getRestaurantList()
          },(err)=>{
          console.log(err)
          })
        }
      }
    }
  }

  const getRestaurantList = async() => {
    const trestaurants = await apiAuth.get(`/restaurants-admin-new/get_restaurants_admin`);
    setAvailablePaymentMethods(trestaurants.data.available_payment_methods)

    // arrange restaurants list for select
    let restaurant_list = trestaurants.data.results.map((r) => ({
      value: r.id,
      label: r.name,
      demo: r.demo,
      company: r.company,
      manager: r.manager,
      account_manager: r.account_manager,
      logo: r.logo,
      owner_name: r.owner
    })).filter((r => r.demo === false));
    restaurant_list.unshift({value: null, label: i18next.t("Todos")})
    setRestaurantList(restaurant_list)
    localStorage.setItem(
      'yumminn-restaurants',
      JSON.stringify(restaurant_list)
    );
  }
  

  if (fields.length === 0) {
    return <div style={{marginTop: "143px", background: isRowBased ? 'white' : ''}}><Spinner>{t('Loading')}</Spinner></div>;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;

  if (fields.length !== undefined) {
    return (
      <div>
        <div className={`${isRowBased ? 'flex bg-white justify-between w-full items-center' : 'self-end my-5 w-full'}`}>
          {isRowBased && <div style={{fontSize: '14px', padding: "0 15px"}}>{t('Create new restaurant')}:</div>}
          <XButton
            className='btn btn-primary rounded-full px-12'
            type='button'
            doClick={() => history.push('/admin/restaurants/new')}
          >
            {t('New_restaurant')}
          </XButton>
        </div>

        <div className={`${isRowBased ? 'pl-4 pb-4 pt-8 bg-white rounded-lg' : 'mb-5 mr-3 bg-white p-3 rounded-lg min-w-[420px]'}`}>
          <table width='100%' className='grid-cols-4'>
            <thead className="sticky top-0">
              <tr className='text-left bg-white border-b border-b-primary'>
                <th>{t('Name')}</th>
                {!isRowBased && <th>{t('Owner')}</th>}

                {!isRowBased && <th>{t('Company')}</th>}
  
                <th className='text-center'>{t('Options')}</th>
                <div>
                <th>
                <div
                style={{
                  height: '44px',
                }}
                className={`box-border rounded-full  flex flex-row`}
              >
                {availablePaymentMethods.map((payment_method) => {
                  return (
                    <div
                      //onClick={()=> changePaymentMethodVisibility(0, payment_method)}
                      className={`w-20 flex flex-row items-center justify-center`}
                    >
                    <img src={payment_method.url} style={{width: "25px"}} alt=''/>
                    </div>  
                      
                  )
                })}
                </div>
                </th>
                </div>
                <th className='text-center'>{t('Apple Pay Verified')}</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((r) => {
                return(
                  <tr key={r.id}>
                      <td style={{fontSize: isRowBased ? '14px' : '', width: isRowBased ? "150px" : ""}} >{r.name}</td>
                      {!isRowBased && <td>{r.owner_name}</td>}
                      {!isRowBased && <td>{r.company_name}</td>}
                      <td>
                        <div className='flex flex-row items-center'>
                          
                          <div
                            onClick={()=>!r.demo ? makeManager(r,r.account_manager) : ''}
                            style={{
                              height: '44px',
                            }}
                            className={`w-20 box-border ${r.account_manager ?'text-gray-400': 'text-gray-600'} ${!r.demo?'border-2':''} ${r.account_manager?'border-gray-400':'border-gray-600'}  ${!r.demo ? 'cursor-pointer' : ''} rounded-full  p-4 text-xs font-semibold  flex flex-row items-center justify-center`}
                          >
                            {r.account_manager && !r.demo ?t("assigned"): !r.demo ? t('assign') : ''}
                          </div>   

                          <XButton
                            className='btn btn-primary rounded-full px-2'
                            type='button'
                            doClick={() =>
                              history.push(`/admin/restaurants/${r.id}`)
                            }
                          >
                            {isRowBased ? 
                            <PencilIcon 
                              className="h-5 w-5 text-white-500"
                            />
                            :
                            <div>{t('edit')}</div>} 
                          </XButton>

                          <XButton
                            className='btn btn-primary rounded-full px-2'
                            type='button'
                            doClick={() => handleDelete(r)}
                          >
                          {isRowBased ? 
                            <TrashIcon 
                              className="h-5 w-5 text-white-500"
                            />
                            :
                            <div>{t('delete')}</div>} 
                          </XButton>

                          <XButton
                            className='btn btn-primary rounded-full px-2'
                            type='button'
                            doClick={() =>
                              history.push(`/admin/restaurants/tpv/${r.id}`)
                            }
                          >
                            <div style={{whiteSpace: 'nowrap'}}>{t('tpv_info')}</div> 
                          </XButton>

                          {!isRowBased && r.logo && (
                            <a href={r.logo}
                               alt="" style={{width: "214px"}} target="_blank" download={`${r.name.replaceAll(" ", "_")}`} rel="noreferrer">
                              <XButton
                                className='btn btn-primary rounded-full px-2'
                                type='button'
                              >
                                <div style={{fontSize: "12px"}}>{t('Download logo')}</div>
                              </XButton>
                            </a>
                          )}
                        </div>
                      </td>
                      <td>
                      <div className='flex flex-row items-center'>
                      {availablePaymentMethods.map((payment_method) => {
                        return (
                          <div
                          onClick={()=> changePaymentMethodVisibility(r.id, payment_method)}
                          style = {{height: "44px", cursor: "pointer", borderWidth: "1px", borderColor: "black", backgroundColor: r.payment_methods.includes(payment_method.name) ? "rgba(0, 255, 140, 0.5)" : "rgba(255, 0, 0, 0.5)"}}               
                          className={`w-20 box-border rounded-full  flex flex-row items-center justify-center`}
                        >
                            {r.payment_methods.includes(payment_method.name) ? 
                            <CheckCircleIcon 
                            className="h-7 w-7 text-black"
                            />
                            :
                            <ExclamationCircleIcon
                            className="h-7 w-7 text-black"
                            />}  
                          </div>  
                          
                        )
                      })}
                      </div>
                      </td>
                      {r.monei_pay && r.monei_account_id &&
                      <td>
                        <div
                          onClick={() => handleVerifyApplePay(r)}
                          style = {{height: "44px", cursor: "pointer", borderWidth: "1px", borderColor: "black", backgroundColor: r.monei_apple_pay_verified ? "rgba(0, 255, 140, 0.5)" : "rgba(255, 0, 0, 0.5)", marginLeft: "25px"}}               
                          className={`w-20 box-border rounded-full  flex flex-row items-center justify-center`}
                        >
                            {r.monei_apple_pay_verified ? 
                            <CheckCircleIcon 
                            className="h-7 w-7 text-black"
                            />
                            :
                            <ExclamationCircleIcon
                            className="h-7 w-7 text-black"
                            />}  
                          </div>  
                          </td>}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  else{
    return null;
  }
}
