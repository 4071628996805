import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from 'common/ToggleSwitch';
import styled from 'styled-components';

export const ActiveSwitch = ({checked, loading, disabled, disabledInfo, onChange, noTitle, noMessage}) => {
  const { t } = useTranslation();

  return (
    <SwitchContainer style={{width: noTitle && noMessage ? '60px' : ''}} className={disabled ? 'disabled' : ''}>
      <span style={{display: noTitle ? 'none' : 'inherit'}}>{t('Status')}</span>
      <ToggleSwitch
        name='active_switch'
        checked={checked}
        disabled={loading || disabled}
        onChange={onChange}
      />
      {!noMessage &&
        <b>
          {t(checked ? 'enabled' : 'disabled')}
        </b>
      }
      {disabledInfo &&
        <p>
          {disabledInfo}
        </p>
      }
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  font-size: 0.875rem;

  b {
    font-weight: 600;
    text-transform: capitalize;
    color: #094553;
    width: 7ch;
  }

  p {
    position: absolute;
    top: 100%;
    left: 0%;
    width: max-content;
    max-width: 12rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px #0001, 0 2px 4px -1px #0001;
    background-color: #FFF;
    padding: 8px;
    margin: 0 8px;
    pointer-events: none;
    opacity: 0;
    z-index: 9999;
    transition: opacity 0.2s ease-in-out;
  }

  &.disabled {
    
    & > div {
      cursor: not-allowed;

      * {
        pointer-events: none;
      }
    }

    &:hover p {
      opacity: 1;
    }
  }
`;
