import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const OrderRePrintHandler = ({order, reSendOrder, reSendLoading}) => {

  const { t } = useTranslation();
  
  const handleReSend = (order) => {
	reSendOrder(order.id)
  }

  return (
    <div style={{display: 'flex', marginTop: "50px", alignItems: 'flex-end', flexDirection: "column", gap: "10px"}}>
		<p  style={{fontSize: "15px", fontWeight: 500}}>{t("reprint_yumminn")}</p>
		<div style={{textAlign: 'center', justifyContent: 'center', display: 'flex', backgroundColor: "#094553", color: "#FFFFFF", padding: '10px 0px', width: "200px", borderRadius: "99999px", fontSize: '14px', fontWeight: 700, userSelect: 'none', cursor: 'pointer'}} onClick={() => handleReSend(order)}>
			{reSendLoading ? 
			    <div className="loader-wheel" style={{height: '21px', width: '21px', margin: 0}}></div>
			: 
			t("Print")
			}
		</div>  		
	</div>
  );
};
export default OrderRePrintHandler;
