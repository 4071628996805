import React from 'react';
import styled from 'styled-components';

export const ProgressBar = ({ progress, total = 100 }) => {
  return (
    <Bar>
      <div style={{ width: `${progress * 100 / total}%`}}/>
    </Bar>
  );
};

const Bar = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 99px;
  background-color: #E2E8F0;
  
  div {
    width: 0;
    height: 100%;
    border-radius: 99px;
    background: linear-gradient(90deg, #094553, #5FB894);
    transition: width 0.2s linear;
  }
`;
