import React, { useState, useEffect, useContext } from "react";
import Spinner from "common/Spinner";
import RoundPagination from "common/RoundPagination";
import WalletUsersSearchInput from "./WalletUsersSearchInput";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from 'data/authContext';
import WalletUsersTable from "./WalletUsersTable";
import { UseUsersWalletInfo } from "./WalletLogic";
import { CSVLink } from "react-csv";
import { SaveIcon } from '@heroicons/react/outline';
import { centsToAmountString } from "logic/functions";
import { HistoryRechargesPopup } from './HistoryRechargesPopup';

const WalletTableHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecommendationFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-top: 15px;
`;
const RecommendationFiltersLabel = styled.p`
  align-self: center;
  margin-right: 5px;
  min-width: fit-content;
`;
const RecommendationWrapper = styled.div`
  display: flex;
  flex-direction: row;
	font-size: 12.5px;
	border-radius: 20px;
	padding: 6px;
  padding-left: 6px;
  margin-left: 5px;
  background-color: rgb(95, 184, 148);
  min-width: fit-content;
  span {
    color: white;
  }
`;
const RecommendationType = styled.span`
	font-size: 11.5px;
	background-color: rgb(115, 204, 168);
	color: hsl(0, 0%, 20%);
	border-radius: 20px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-right: 5px;
`;
const RecommendationValue = styled.span`
	color: hsl(0, 0%, 20%);
  align-self: center;
`;
const RecommendationCloseButton = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  span {
    font-weight: 600;
    color: #404040;
    align-self: center;
  }
  &:hover {
    cursor: pointer;
    background-color: rgb(115, 204, 168);
  }
  &:hover span {
    color: white;
  }
`;

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const WalletUsers = () => {
  const { state: { selectedRestaurant, selectedDate, selectedDateTo, selectedRestaurantId } } = useContext(AuthContext);

  const { t } = useTranslation();
  const elementsPerPage = 50;

  const [selectedTopup             , setSelectedTopup]              = useState(null);
  const [numberOfPages             , setNumberOfPages]              = useState(null);
  const [totalNumberOfTopups       , setTotalNumberOfTopups]        = useState(null);
  const [topups                    , setTopups]                     = useState([]);
  const [incomingTopupsIds         , setIncomingTopupsIds]          = useState([]);
  const [notOpenedIncomingTopupsIds, setNotOpenedIncomingTopupsIds] = useState([]);
  const [recommendationFilters     , setRecommendationFilters]      = useState([]);
  const [page                      , setPage]                       = useState(1);
  const [loading                   , setLoading]                    = useState(false);
  const [statusLoading             , setStatusLoading]              = useState(false);
  const [notifyNew                 , setNotifyNew]                  = useState(false);
  const [csvData                   , setCsvData]                    = useState(false);

  const { content: data, loading: isLoading } = UseUsersWalletInfo(selectedRestaurantId, selectedDate, selectedDateTo, page, recommendationFilters);

  let headers = [
    { label: "user", key: "user" },
    { label: "total_credits_available", key: "total_credits_available" },
    { label: "incentive_obtained", key: "incentive_obtained" },
    { label: "#recharges", key: "#recharges"},
    { label: "last_recharge_id", key: "last_recharge_id"},
    { label: "#orders_with_credit", key: "#orders_with_credit"},
    
  ];

  const recommendationTypeToString = {
		'client_email': t('Email_short'),
    'recharge_ids': '#' + t('recharge_ids')
	};

  const addRecommendationFilter = newFilter => {
    const newRecommendationFilters = recommendationFilters.concat([newFilter]);
    setRecommendationFilters(newRecommendationFilters);
  };

  const removeRecommendationFilter = indexToRemove => {
    const newRecommendationFilters = recommendationFilters.filter((_, index) => index !== indexToRemove);
    setRecommendationFilters(newRecommendationFilters);
  };

  const [isOpenHistory, setIsOpenHistory] = useState(false)

	const closeHistoryRechargesPopup = () => setIsOpenHistory(false);


  const updateData = data => {
    try {
      const recharges = data.recharges;
      setTopups(recharges);

      if (totalNumberOfTopups && totalNumberOfTopups > 0 && data?.total_number_of_recharges> 0 && notifyNew) {
        const newTopupsCount = data.total_number_of_recharges - totalNumberOfTopups;

        if (newTopupsCount > 0) {
          const incomingIds = data.recharges.slice(0, newTopupsCount).map(topup => topup.last_recharge_id);
          const updatedNotOpenedIds = incomingTopupsIds.concat(incomingIds);

          setIncomingTopupsIds(incomingIds);
          setNotOpenedIncomingTopupsIds(updatedNotOpenedIds);
        } else {
          setIncomingTopupsIds([]);
        }
      } else {
        setIncomingTopupsIds([]);
      }

      /*if (recharges && recharges.length > 0 && (!selectedTopup || !recharges.some(t => t.last_recharge_id === selectedTopup.last_recharge_id))) {
        setSelectedTopup(recharges[0]);
      }*/

      const incomingNumberOfTopups = data.total_number_of_recharges;
      setTotalNumberOfTopups(incomingNumberOfTopups);
  
      const nextNumberOfPages = Math.ceil(incomingNumberOfTopups / elementsPerPage);
      setNumberOfPages(nextNumberOfPages > 0 ? nextNumberOfPages : null);
    } catch (error) {
      console.log(`Recharges.jsx:getData ~ catch: ${error.toString()}`);
    } finally {
      setLoading(false);
      setStatusLoading(false);
      !notifyNew && setNotifyNew(true);
    }
  };

  useEffect(() => {
    setPage(1);
    setNumberOfPages(null);
    setNotifyNew(false);
  }, [selectedRestaurant, selectedDate, selectedDateTo]);
  

  useEffect(() => {
    if (!data) return;
    updateData(data);
  }, [data]);

  
  useEffect(() => {
    if(topups && topups.length > 0 && selectedRestaurantId){
      handleDownloadCsv();
    }
  }, [topups])

  const handleDownloadCsv = () => {
    const data = topups.map((topup)=>{
      return {
        'user': topup.email,
        'total_credits_available':centsToAmountString(topup.client_credit__balance),
        'incentive_obtained':centsToAmountString(topup.total_incentive),
        '#recharges':topup.number_of_recharges,
        'last_recharge_id':`ID: YR-${topup.last_recharge_id}`,
        '#orders_with_credit':topup.orders_with_credit
      };
    });
    
    setCsvData(data);
  }
  
  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {(isLoading || loading) && <LoadingOverlay/>}
        <div className={`flex-auto px-[32px] w-full mt-4`}>
          <WalletTableHeader>
            <div className={`gap-5`} style={{display: 'flex', flexDirection: 'row'}}>
              <WalletUsersSearchInput addFilter={addRecommendationFilter} />
            </div>
            {recommendationFilters?.length > 0 && (
              <RecommendationFiltersWrapper>
                <RecommendationFiltersLabel>{t('filtering_by') + ":"}</RecommendationFiltersLabel>
                {recommendationFilters?.map((recommendation, index) => {
                  if (!recommendation) {
                    return <></>;
                  }
                  return (
                    <RecommendationWrapper key={'recommendation_' + index}>
                      <RecommendationType>
                        {recommendationTypeToString[recommendation.type]}
                      </RecommendationType>
                      <RecommendationValue>
                        {recommendation.value}
                      </RecommendationValue>
                      <RecommendationCloseButton onMouseDown={() => removeRecommendationFilter(index)}>
                        <span>X</span>
                      </RecommendationCloseButton>
                    </RecommendationWrapper>
                  );
                })}
              </RecommendationFiltersWrapper>
            )}
          </WalletTableHeader>
          <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: "fit-content"}}>
            <WalletUsersTable
              topups={topups}
              selectedTopup={selectedTopup}
              setSelectedTopup={setSelectedTopup}
              incomingTopupsIds={incomingTopupsIds}
              notOpenedIncomingTopupsIds={notOpenedIncomingTopupsIds}
              setNotOpenedIncomingTopupsIds={setNotOpenedIncomingTopupsIds}
              setIsOpenHistory={setIsOpenHistory}
            />
            <div style={{marginTop: "10px", width: "100%", height: "1px", backgroundColor: "#E6E6E6"}}></div>
            <div className='flex items-center justify-between pt-8'>
              {numberOfPages && (
                <RoundPagination
                  page={page}
                  numberOfPages={numberOfPages}
                  setPage={setPage}
                  total={totalNumberOfTopups}
                  elementNamePlural={t("users")}
                  elementNameSingular={t("user")}
                />
              )}
              {csvData.length > 0 &&
                <CSVLink data={csvData} headers={headers} separator={";"} filename={(selectedRestaurant[0].label+"_"+(new Date().toJSON().slice(0,10))+"_"+new Date().toLocaleTimeString()).replace(/ /g,"_")+'.csv'}>
                  <button
                    className='flex flex-row justify-center align-center rounded-full cursor-pointer shadow-md px-12 py-2 m-1 border-4 border-[#094553] text-sm text-gray-50 font-semibold tracking-wide bg-[#094553] disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200'
                    type='button'
                    onClick={() =>handleDownloadCsv()}
                  >
                    <SaveIcon className='h-5 w-5 text-white mr-4' />
                    {t('Export_Csv')}
                  </button>
                </CSVLink>
              }
            </div>
          </div>
        </div>
        {isOpenHistory && <HistoryRechargesPopup isOpen={!!isOpenHistory} close={closeHistoryRechargesPopup} clientEmail={selectedTopup?.email} selectedTopup={selectedTopup} className='responsive_size'/>}

      </div>
    </>
  );
};

export default WalletUsers;
