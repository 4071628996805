import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from '@heroicons/react/outline';
import EventModal from "./Events/EventModal";
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';
import useRestaurant from 'components/yumminn/useRestaurant';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { centsToAmountString } from "logic/functions";
import Spinner from "common/Spinner";

const Events = () => {
  const { t } = useTranslation();
  const { seleccionado } = useRestaurant();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([])
  const [openCreateEvent, setOpenCreateEvent] = useState(false)
  const [editEvent, setEditEvent] = useState(null)


  const getData = async () => {
    try {
      setLoading(true)

      const res_obj = await baseAuth.get(`/restaurant/events/?restaurant=${seleccionado[0]?.value}`);

      const res_data = res_obj.data;
      setEvents(res_data.eventos);
      setLoading(false)
    } catch (error) {
      console.log(error);
      toast.error('error');
      setLoading(false)
    }
  }

  const saveForm = async ({ eventId, name, phone, email, company, occasion, concept, hour, cents, event_date, created_event_date, details }) => {
    try {

      if (seleccionado) {
        const obj = {
          eventId: eventId ? eventId : null,
          restaurant: seleccionado[0]?.value,
          name: name,
          phone: phone,
          email: email,
          company: company,
          occasion: occasion,
          concept: concept,
          hour: hour,
          cents: cents,
          event_date: event_date,
          created_event_date: created_event_date,
          details: details
        }

        let res_obj = null
        if (!eventId){
          res_obj = baseAuth.post(`/restaurant/events`, obj);
        }else{
          res_obj = baseAuth.patch(`/restaurant/events`, obj);
        }
        res_obj.then((res)=>{
          setOpenCreateEvent(false)
          setEditEvent(null)
          toast.success(`${t('update_ok')}`);
          getData()
        },(err)=>{
        console.log(err)
        })
      }
    } catch (error) {
      console.log(error);
      toast.error('test');
    }
  }

  const edit = (event) => {
    setEditEvent(event)
  }

  const remove = (event) => {
    if (window.confirm(t('Confirm delete'))){
      baseAuth.delete(`/restaurant/delete/event/${event.uuid}/`).then((res)=>{
        toast.success(`${t('delete_ok')}`);
        getData()
      },(err)=>{
      console.log(err)
      })
    }
  }

  useEffect(() => {
    if (seleccionado) {
      getData()
    }
  }, [seleccionado])
  return (
    <>
      <div className="flex flex-col p-5">
        <div
          onClick={() => setOpenCreateEvent(true)}
          className='text-[#5FB894] h-[53px] relative bottom-[-4px] w-min ml-auto'
        >
          <div className={`pt-[4px]`}>
            <div className={`cursor-pointer  hover:text-medio  whitespace-nowrap flex flex-row items-center text-xs p-2 font-semibold gap-1 text-[#5FB894] border-b-2 border-[#5FB894] `}>
              <PlusIcon className='h-4 w-4 text-principal' />
              {t('Create_event')}
            </div>
          </div>
        </div>
        {loading && (
          <div className="h-full w-full absolute z-9999 flex flex-col justify-center items-center">
            <Spinner>{t("Loading")}</Spinner>
          </div>
        )}
        <div className="overflow-y-scroll bg-white rounded-lg">
          <div className="pt-5">
            <table className=" w-full rounded-lg">
              <thead className="sticky top-0 m-0 bg-gray-100 z-50 rounded-lg">
                <tr className="rounded-lg">
                  <th className="text-xs text-start font-semibold border-t-2 border-gray-200 py-2">{t('name')}</th>
                  <th className="text-xs font-semibold border-t-2 border-gray-200 py-2">{t('Email')}</th>
                  <th className="text-xs font-semibold border-t-2 border-gray-200 py-2">{t('event_date')}</th>
                  <th className="text-xs font-semibold border-t-2 border-gray-200 py-2">{t('Price')}</th>
                  <th className="text-xs font-semibold border-t-2 border-gray-200 py-2">{t('Company')}</th>
                  <th className="text-xs font-semibold border-t-2 border-gray-200 py-2">{t('Status')}</th>
                  <th className="text-xs font-semibold border-t-2 border-gray-200 py-2">{ }</th>
                </tr>
              </thead>

              
              <tbody className="p-2">
                {events.length === 0 &&
                  <tr className="h-36">
                    <td colSpan={7} className="text-center">{t('No_events')}</td>
                  </tr>
                }
                {events.length > 0 && events.map((item) => {
                  return (
                    <tr className="text-sm" key={item.uuid}>
                      <td className="text-center px-3 py-2">{item.name}</td>
                      <td className="text-center px-3 py-2">{item.email}</td>
                      <td className="text-center px-3 py-2">{item.date}</td>
                      <td className="text-center px-3 py-2">{centsToAmountString(item.cents) + "€"}</td>
                      <td className="text-center px-3 py-2">{item.company}</td>
                      {item.status === 1 ?
                        <td className="">
                          <div className="text-xs text-center py-2 bg-red-200 rounded-lg text-orange-600 font-medium">
                            {t('Pending')}
                          </div>
                        </td>
                        :
                        <td className="text-xs text-center px-3 py-2 bg-green-200 rounded-lg text-green-600 font-medium">
                          {t('paid')}
                        </td>
                      }
                      <td>
                        <div className="font-medium flex flex-row justify-evenly items-center">
                          <a target="_blank" className="pr-5 whitespace-nowrap cursor-pointer underline text-xs text-blue-600" href={process.env.REACT_APP_URL+"/event/"+item.uuid} rel="noreferrer">{t("check_link")}</a>
                          <PencilIcon
                            onClick={() => { edit(item) }}
                            className="cursor-pointer h-4 w-4 text-gray-400"
                          />
                          <TrashIcon
                            onClick={() => { remove(item) }}
                            className="cursor-pointer h-4 w-4 text-gray-400"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>
        </div>
      </div>

      {
        openCreateEvent && <EventModal closeModal={() => setOpenCreateEvent(false)} save={saveForm} />
      }
      {
        editEvent && <EventModal event={editEvent} closeModal={() => setEditEvent(null)} save={saveForm} />
      }
    </>
  )
}

export default Events
