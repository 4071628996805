import { bool } from 'prop-types';
import React from 'react'
import styled from 'styled-components';


export const ImagePlaceholder = ({}) => {
  return (
    <PlaceholderContainer>
      <b>⭐</b>
    </PlaceholderContainer>
  );
};

const PlaceholderContainer = styled.picture`
  display: flex;
  height: 100%;
  position: relative;
  isolation: isolate;
  
  & > b {
    font-size: 1.125rem;
    line-height: 1;
    margin: auto;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    height: 36px;
    width: 36px;
    border-radius: 9999px;
    opacity: 0.5;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    height: 60px;
    width: 60px;
    border-radius: ${({theme}) => theme.radius_round};
    background-color: ${({selected, theme}) => selected ? theme.img_placeholder_bg_selected : theme.img_placeholder_bg};
    opacity: 0.5;
  }
`;