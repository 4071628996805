import React from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import SideBar from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { AddButton } from 'pages/admin/pages/Lockers/LockerContainers';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

const ServiceContainer = ({ children, title }) => {
  const { t } = useTranslation();

  const isRowBased = useMediaQuery("(max-width: 760px)");
  const path = window.location.pathname;

  return (
    <div id="menuContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <SideBar style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col flex-1'}`}>
        
        <MenuHeader title={title} route_group={"service"}/>
        <div className={`${isRowBased && 'bg-white'} flex flex-col flex-1`} >
          <div className='bg-white py-4 px-[32px] flex gap-4'>
            <RestaurantSelect />
            {path === '/admin/lockers' &&
              <AddButton onClick={() => document.getElementById('add_locker').click()}>
                <PlusCircleIcon height={24}/>
                {t('add_locker')}
              </AddButton>
            }
          </div>
          <div className={`px-[32px] flex-1 ${path !== '/admin/lockers' ? 'overflow-hidden' : ''}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContainer;
