const { default: styled } = require("styled-components");

export const AllergensAndTagsContainer = styled.section`
  display: flex;
  gap: 16px;
  padding: 24px 0;
  overflow: auto;

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
    max-width: 25rem;

    h6 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.3;
    }
  
    p {
      font-size: 0.875rem;
      font-weight: 400;
      margin: 16px 0;
    }
    
    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      
      li {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #ACACAC;
        background-color: #FBFBFB;
        border-radius: 2rem;
        padding: 6px 16px;
        border: 1px solid #DBDBDB;
        cursor: pointer;
        user-select: none;
        transition: 0.2s ease-in-out;
  
        svg {
          color: #ACACAC;
          height: 1.25rem;
          width: auto;
          max-width: 1.75rem;
        }
  
        &:hover:not(.disabled), &.selected {
          color: #404040;
          border-color: var(--bgColor);
  
          svg {
            color: var(--color);
          }
        }
  
        &.selected {
          background-color: var(--bgColor);
        }
      }
    }
  }
`;