import React from 'react';
import { useTranslation } from 'react-i18next';


export const TableRevisionLegend = () => {
  const {t} = useTranslation();
  const boxClassName = "h-4 w-4"
  const containerClassName = 'flex gap-3 items-center'
  return (
    <div className='flex gap-10 text-base font-semibold italic'>
      <div className={containerClassName}>
        <span className={boxClassName + ' bg-[#00748F]'}/>
        <p>{t("auto_translation")}</p>
      </div>
      <div className={containerClassName}>
        <span className={boxClassName + ' bg-[#643FCC]'}/>
        <p>{t("manual_translation")}</p>
      </div>
    </div>
  );
};
