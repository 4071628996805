import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { DaySelector } from './DaySelector';
import { TimeRangeSelector } from './TimeRangeSelector';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { TextInput } from 'common/Input';

const VisibilitySchedulePopup = ({scheduleToEdit, define, cancel, setTimeRangesToDelete, schedulesLength, groupType}) => {
  const newSchedule = {
    tempId: `new ${schedulesLength + 1}`,
    visibility_days: [],
    time_ranges: [{start_hour: 0, end_hour: 24, start_minutes: 0, end_minutes: 0}],
  };

  const [schedule, setSchedule] = useState(scheduleToEdit || newSchedule)
  const [disabled, setDisabled] = useState(scheduleToEdit)

  const editSchedule = (object) => {
    setSchedule(prevSchedule => ({...prevSchedule, ...object}));
  };

  useEffect(() => {
    setDisabled(!schedule?.visibility_days || !schedule?.visibility_days.length);
  }, [schedule]);

  return (
    <>
      <div className='flex flex-col gap-6 px-4 py-12 overflow-y-auto'>
        <div className='flex justify-between items-start gap-4'>
          <TextInput
            label='name_schedule'
            value={schedule?.name || ''}
            onChange={event => editSchedule({name: event.target.value})}
            placeholder='schedule'
            className='w-80'
          />
          <i className='text-xs text-[#404040] bg-gray-100 rounded-md max-w-[38ch] p-2'>
            <Trans i18nKey={'explanation_schedule_' + groupType}>
              <b className='font-semibold'>By default</b>, this schedule <b className='font-semibold'>will apply to all categories</b> within this category. You can modify the <b className='font-semibold'>exceptions</b> by editing the corresponding category.
            </Trans>
          </i>
        </div>
        <DaySelector
          days={schedule?.visibility_days || []}
          editDays={days => editSchedule({visibility_days: days})}
        />
        <TimeRangeSelector
          ranges={schedule?.time_ranges || []}
          editRanges={ranges => editSchedule({time_ranges: ranges})}
          setTimeRangesToDelete={setTimeRangesToDelete}
        />
      </div>
      <hr className='border-t-2 border-b-0 border-gray-200 mt-auto mb-4'/>
      <div className="flex flex-row justify-end gap-4">
        <Button
          label='cancel'
          onClick={cancel}
          variant={BUTTON_VARIANTS.SECONDARY}
        />
        <Button
          label='define'
          onClick={() => !disabled && define(schedule)}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default VisibilitySchedulePopup;