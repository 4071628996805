import React from "react";
import { useTranslation } from "react-i18next";
import useRestaurant from "components/yumminn/useRestaurant";
import {toast} from 'react-toastify';
import {apiAuth} from 'logic/api';
import { PlusIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import Select from 'react-select';
import { select_styles } from 'logic/defaults';
import cloud from '../../../img/cloud.png'
import fileIcon from '../../../img/file.png'
import {useLazyAxios} from 'use-axios-client';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import {FilePond, registerPlugin} from 'react-filepond';
import './MenuCreation.css';
import MenuList from './MenuList';
import MenuVisibility from './MenuVisibility';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import Switch from "react-switch";
import useUpdateTableData from 'components/hooks/useUpdateTableData';
import Loading from "../../../common/Loading";
import { Context } from "data/authContext";

registerPlugin(FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const MenuCreationPage = () => {
  
  const { t } = useTranslation();
  
	const {
		state: { selectedRestaurantId },
	} = React.useContext(Context);

  const [grupos, setGrupos] = React.useState([]);
  
  const [typeOfMenu, setTypeOfMenu] = React.useState("");
  const [menuName, setMenuName] = React.useState("");
  const [menuLink, setMenuLink] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [visibility, setVisibility] = React.useState(true);
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [newtab, setNewtab] = React.useState(false)
  /*Menuvisibilitystates*/
  const [selectedDays, setSelectedDays] = React.useState([]) 
  const [timeRange, setTimeRange] = React.useState(false) 
  const today = new Date(new Date().getTime());
  const startDate = today.setHours(12, 0, 0, 0);
  const endDate = today.setHours(0, 0, 0, 0);
  const [from, setFrom] = React.useState(new Date(startDate));
  const [to, setTo] = React.useState(new Date(endDate));
  const [allTimeRanges, setAllTimeRanges] = React.useState([]);
  const [allSchedules, setAllSchedules] = React.useState([]);
  const [menuId, setMenuId] = React.useState(0)
  const [loadingPDFUpload, setLoadingPDFUpload] = React.useState(false)

  const [getData, {data}] = useLazyAxios({
    axiosInstance: apiAuth, url: `/restaurants/${selectedRestaurantId}/`,
  });

  const { updateData, error: updatedError } = useUpdateTableData({
    entidad: 'restaurants-admin-new',
    id: selectedRestaurantId,
  });

  React.useEffect(() => {
    //
    if(selectedRestaurantId){
      getData();
    }

    if(grupos && grupos[0]){
      if("file" in grupos[0] && grupos[0].file){
        setTypeOfMenu({ value: 'pdf', label: 'PDF' }) 
        if(grupos[0]){
          setFile(grupos[0].file)
          setMenuName(grupos[0].name)
         }
     } else if ("link" in grupos[0] && grupos[0].link !== '') {
       setTypeOfMenu({ value: 'link', label: 'Link' })
       if(grupos[0]){
        setMenuName(grupos[0].name)
        setMenuLink(grupos[0].link)
       }
     } else {
      setMenuLink("")
      setMenuName(grupos[0].name)
     }
     if(grupos[0].visibility?.length > 0){
      setVisibility(false)
      setAllSchedules(grupos[0].visibility)
     }
    }
  }, [selectedRestaurantId, getData, grupos, newtab]);


  React.useEffect(() => {
    if (data) {
      setNewtab(data.external_menu);
    }
  }, [data]);


  const changeType = (a) => {
    setFile(null)
    setMenuLink('')
    setTypeOfMenu(a)
  }
  const handleAddGroup = () => {
    setGrupos((prev) => [
      ...prev,
      {
        name: '',
        restaurant: selectedRestaurantId,
        items: [],
      },
    ]);
  };

    
  const goBack = () => {
    setFile(null)
    setMenuLink('')
    setMenuName('')
    setTypeOfMenu('')
    setVisibility(true)
    setFrom(new Date(startDate))
    setTimeRange(false)
    setAllTimeRanges([])
    setSelectedDays([])
    setAllSchedules([])
    setTo(new Date(endDate))
    setGrupos([])
    if(newtab && data.menu_link.length === 0){
      data.external_menu = false;
      setNewtab(false);
      updateData({external_menu: false});
    }
  }

  const uploadPDF = async (type, file) => {
    console.log("🚀 ~ file: MenuCreation.jsx:143 ~ uploadPDF ~ file:", file)
    let fd = new FormData();
    fd.append(type, file);
    setFile(fd)
  };

  const handleSave = async (id) => {
    let schedules = [...allSchedules]
    if(selectedDays.length > 0){
      schedules.push({
        selectedDays: selectedDays,
        timeRanges: allTimeRanges
      })
      setAllSchedules(schedules)
    }
    //updating existing menu general
    if(id !== undefined){
      try {
        await apiAuth(`/restaurants/${selectedRestaurantId}/modify_menu/?id=${id}`, {
          method: 'PATCH', 'Content-Type': 'multipart/form-data', data: 
          {
            name: menuName,
            link: menuLink,
            visibility: schedules
          }
        });
        getData();
        setMenuName("")
        setTypeOfMenu("")
        setMenuLink("")
        setFile(null)
        setGrupos([])
        setVisibility(true)
        setFrom(new Date(startDate))
        setTo(new Date(endDate))
        setAllSchedules([])
        setAllTimeRanges([])
        setSelectedDays([])
        setTimeRange(false)
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
      //updating existing menu pdf part
      try {
        await apiAuth(`/restaurants/${selectedRestaurantId}/modify_menu/?id=${id}`, {
          method: 'PATCH', 'Content-Type': 'multipart/form-data', data: file, 
        });
        getData();
        setMenuName("")
        setTypeOfMenu("")
        setMenuLink("")
        setFile(null)
        setGrupos([])
        setVisibility(true)
        setFrom(new Date(startDate))
        setTo(new Date(endDate))
        setAllSchedules([])
        setAllTimeRanges([])
        setSelectedDays([])
        setTimeRange(false)
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
      
    } else{
      //creating new menu type link
      if(typeOfMenu.value === 'link'){
        try {
          await apiAuth.post(`/restaurants/${selectedRestaurantId}/add_menus/`, 
          {
            name: menuName, 
            restaurant: selectedRestaurantId,
            link: menuLink,
            visibility: schedules
          });
          getData();
          setMenuName("")
          setTypeOfMenu("")
          setMenuLink("")
          setFile(null)
          setGrupos([])
          setVisibility(true)
          setFrom(new Date(startDate))
          setTo(new Date(endDate))
          setAllSchedules([])
          setAllTimeRanges([])
          setSelectedDays([])
          setTimeRange(false)
        } catch (error) {
          console.log(`error`, error);
        } 
      } else {      //creating new menu type pdf
        try {
          setLoadingPDFUpload(true)
          await apiAuth.post(`/restaurants/${selectedRestaurantId}/add_menus/`, 
          {
            name: menuName, 
            restaurant: selectedRestaurantId,
            visibility: schedules
          })
        } catch (error) {
          console.log(`error`, error);
        } 
        try {
          await apiAuth(`/restaurants/${selectedRestaurantId}/modify_menu/?id=${undefined}`, {
            method: 'PATCH', 'Content-Type': 'multipart/form-data', data: file,
          }).then((res) => setLoadingPDFUpload(false))
          getData();
          setMenuName("")
          setTypeOfMenu("")
          setMenuLink("")
          setFile(null)
          setGrupos([])
          setVisibility(true)
          setFrom(new Date(startDate))
          setTo(new Date(endDate))
          setAllSchedules([])
          setAllTimeRanges([])
          setSelectedDays([])
          setTimeRange(false)
        } catch (error) {
          console.error(error);
          toast.error(error.message);
        }
      }
    }
  }

  const handleSaveMenuLink = async () => {
    delete data.adyen_config
    delete data.manager
    delete data.tpv
    setMenuLink('')
    setMenuName('')
    data.menu_link = menuLink ? menuLink : data.menu_link
    data.menu_link_name = menuName ? menuName : data.menu_link_name
    updateData({
      menu_link: data.menu_link,
      menu_link_name: data.menu_link_name
    })
    setGrupos([])
  }

  const handleNewTab = () => {
    data.external_menu = !data.external_menu;
    setNewtab(data.external_menu);
    updateData({external_menu: data.external_menu});
    if(data.external_menu){
      if(data.menu_link.length === 0){
        setTypeOfMenu({ value: 'link', label: 'Link' })
        setGrupos((prev) => [
          ...prev,
          {
            name: '',
            restaurant: selectedRestaurantId,
            items: [],
          },
        ]);
      }
    } 
  }

  const onChangeName = (e, group) => {
   setMenuName(e.target.value)
  }

  if (!data) return <Loading />;

  const options = [
    { value: 'pdf', label: 'PDF' },
    { value: 'link', label: 'Link' }
  ]
  
  if (grupos && data) {
    return (
      <>
        {grupos.length > 0 && 
        <>
          <div onClick={() => goBack()} className='text-[16px] w-20 pb-6 pt-6 flex items-center cursor-pointer'>
            <ChevronLeftIcon className='h-4 w-4 hover:text-medio' />
            <span className="pl-2">{t('Back')}</span>
          </div>
          <div className='text-[18px] font-bold py-4' style={{color: '#404040'}}>{t('Menu details')}</div>
        </>
        }
        <div className='flex flex-col w-full'>
          {grupos.map((group, index) => (
            <div key={`initialDiv${index}`}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                key={index}
                className='mb-5 mr-3 bg-white p-3 rounded-lg'
              >
                <div
                  key={group.id}
                  className='flex flex-col space-y-3 divide-y'
                >
                  <div 
                    className='flex items-center w-full space-x-2'
                    key={`div${index}`}
                  >
                    <label
                      key={`label${index}`}
                      className='w-36 text-[14px] text-texto pl-1'
                      htmlFor='name_link'
                    >
                      {t('Name')}
                    </label>
                    <div 
                      className='w-full flex flex-col mb-1'
                      key={`div2${index}`}
                    >
                      <input
                        key={`input${index}`}
                        type='text'
                        placeholder={t('Add a menu name')}
                        className='yumminn-input-small-modified'
                        style={{borderRadius: '4px'}}
                        value={menuName}
                        name='menu_name'
                        onChange={(e) => onChangeName(e, group)}
                      />
                    </div>
                  </div>

                  <div 
                    className={`${!isRowBased ? 'flex items-start justify-between space-x-2 pt-4 pl-1' : 'flex flex-col'}`}
                    key={`div3${index}`}
                  >
                    {!data.external_menu && <div className={`flex items-center ${isRowBased && 'pb-4 pt-4'}`}>
                        <label
                          key={`label2${index}`}
                          className='w-36 text-[14px] text-texto '
                          htmlFor='type'
                        >
                          {t('Type')}
                        </label>
                        <div 
                          className={`w-full flex flex-col mb-1 ${!isRowBased ? 'pl-10' : 'pl-6'}`}
                          key={`div4${index}`}
                        >
                        <Select
                            key={`select${index}`}
                            menuPortalTarget={document.body}
                            className="w-full max-w-xs text-[14px]"
                            isClearable={false}
                            styles={select_styles}
                            isSearchable={true}
                            value={typeOfMenu}
                            options={options}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            onChange={(a) => changeType(a)}
                          />
                        </div>
                    </div>}
                    {typeOfMenu.label === 'PDF' ?
                    <div 
                      className='flex'
                      key={`div5${index}`}
                    >
                      <div 
                        key={`div6${index}`}
                        className={`text-[16px] text-principal font-semibold flex items-center cursor-pointer`}
                        style={{border: '2px dashed #d0d0d0', padding: "20px 100px", borderRadius: '4px', marginRight: '30px'}}
                      >
                        <FilePond
                          key={`filepond${index}`}
                          credits={false}
                          labelIdle={`<div style='margin-bottom: 50px; margin-left: 20px; white-space: nowrap'>${grupos[index].name}</div>`}
                          name={'menu'}
                          allowMultiple={false}
                          allowDrop={true}
                          allowReplace={true}
                          allowRemove={false}
                          onaddfile={(error, file) => {
                            uploadPDF('file', file.file);
                          }}
                          acceptedFileTypes={['application/pdf']}
                        />
                        {!file && <img key={`img${index}`} src={cloud} alt='upload' style={{marginRight: "10px"}}/>}  
                        {!file && <p key={`p${index}`}>{t('Upload PDF file with menu')}</p>}
                        {file && 
                          <div 
                            className='flex justify-start w-full'
                            key={`div7${index}`}
                          >
                          <img key={`img2${index}`} src={fileIcon} alt='file' style={{marginTop:"30px", marginRight: "10px", width: "24px", height: "24px"}}/>  
                          </div>}
                      </div>
                    </div> :
                    <div 
                      className='flex'
                      key={`div8${index}`}
                    >
                      <div 
                        className='flex items-center pr-7'
                        key={`div9${index}`}
                      >
                        <label
                          key={`label3${index}`}
                          className='w-52 text-[14px] text-texto text-rigth'
                          htmlFor='name'
                          >
                            {t('Menu_link')}*
                        </label>
                        <div 
                          className='w-full flex flex-col mb-1'
                          key={`div9${index}`}
                        >
                          <input
                            key={`input2${index}`}
                            type='text'
                            placeholder={t('Menu_link')}
                            className='yumminn-input'
                            value={menuLink}
                            name='menu_link'
                            onChange={(m) => setMenuLink(m.target.value)}
                            autoComplete='one-time-code'
                          />
                        </div>
                      </div>
                    </div>}
                  </div>
                </div> 
                </motion.div>
                {grupos.length > 0 &&
                  <>
                    <div 
                      className='text-[18px] font-bold pb-4 pt-8' 
                      style={{color: '#404040'}}
                      key={`div10${index}`}
                    >
                      {t('Visibility schedule')}
                    </div>
                    <div 
                      className="flex pl-8"
                      key={`div11${index}`}
                    >
                      <input 
                        key={`input3${index}`}
                        className="rounded bg-gray-200 border-transparent focus:border-transparent text-primary focus:ring-1 focus:ring-offset-2 focus:ring-gray-500 mt-1 mr-6" 
                        type='checkbox' 
                        value={visibility} 
                        checked={visibility}
                        onChange={() => setVisibility(!visibility)}
                      />
                      <div 
                        className='text-[16px]' style={{color: '#404040'}}
                        key={`div12${index}`}
                      >
                        {t('Always visible')}
                      </div>
                    </div>
                    {!visibility && 
                    <MenuVisibility 
                      key={`visibility${index}`}
                      menuId={menuId}
                      setGrupos={setGrupos}
                      grupos={grupos}
                      selectedDays={selectedDays}
                      setSelectedDays={setSelectedDays}
                      timeRange={timeRange}
                      setTimeRange={setTimeRange}
                      to={to}
                      setTo={setTo}
                      from={from}
                      setFrom={setFrom}
                      allTimeRanges={allTimeRanges}
                      setAllTimeRanges={setAllTimeRanges}
                      allSchedules={allSchedules}
                      setAllSchedules={setAllSchedules}
                    />
                    }

                  </>
                  } 
                <div 
                  className='flex w-full justify-end pt-4 pr-2'
                  key={`div13${index}`}
                >
                {loadingPDFUpload ?
                  <Loading/>
                  : 
                  <button
                    key={`button${index}`}
                    className='cursor-pointer flex flex-row w-32 justify-center align-center rounded-full shadow-md px-12 py-2 m-1 border-4 border-principal text-sm text-gray-50 font-bold uppercase tracking-wide bg-principal disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200 mb-8'
                    type='button'
                    disabled={!menuName|| !typeOfMenu || (!file && !menuLink)}
                    onClick={() => {!data.external_menu ? handleSave(group.id) : handleSaveMenuLink()}}
                  >
                    {t('save')}
                  </button>
                }
                </div>
              </div>
          ))}

         

          {/* * */}
          {/* nuevo group */}
          {/* * */}
          {grupos.length < 1 && <div
            className={`flex items-center justify-end space-x-4 input bg-transparent my-0 pt-8 py-0 pl-0 ${isRowBased && 'pb-20'}`}
          > 
          <div className='flex justify-between w-full items-center'>
          <div className={`flex items-center ${isRowBased ? 'text-[14px]' : 'text-[16px]'}`}>
              <div style={{color: !data.external_menu ? '#5FB894' : '#767676', fontWeight: 600}}>{t('Internal_menus')}</div>
              <Switch className='px-4' onChange={() => handleNewTab()} checked={newtab} onColor='#888' uncheckedIcon={false} checkedIcon={false}/>
              <div style={{color: data.external_menu ? '#5FB894' : '#767676', fontWeight: 600}}>{t('External_menu')}</div>
            </div>
            {!data.external_menu && 
            <div className={`flex items-center hover:bg-primary text-primary hover:bg-opacity-40 rounded-md h-[44px] ${isRowBased ? 'text-[14px] pt-28 whitespace-nowrap' : 'text-[16px]'} cursor-pointer transition transform px-4'`} style={{fontWeight: 600}} onClick={() => handleAddGroup()}>
              <PlusIcon className={`mr-2 h-4 w-4 text-principal hover:text-medio`} />
              {isRowBased ? t('New_menu') : t('Add_new_menu')}
            </div>}
          </div>

            

          </div>}
        </div>

        {grupos.length < 1 && <MenuList setGrupos={setGrupos} data={data} setNewtab={setNewtab} setMenuId={setMenuId}/>}


      </>
    );
  }
};

export default MenuCreationPage;
