import { LockersContext } from 'data/LockersContext';
import { locker_select_styles } from 'logic/defaults';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { OrderItem } from './OrderItem';
import { getOptionFromOrder, getPickUpTime, isOrderExpired, moveOrdertoLocker } from './functions';
import { OrderOption, OrderSingleValue } from './SelectComponents';
import { useCountDown } from 'hooks/useCountDown';

export const OrderSelector = ({ locker, manageOrder, setSideOrder }) => {
  const { t } = useTranslation();
  const { state: { orders }, moveOrder } = useContext(LockersContext);

  const endTime = useMemo(() => getPickUpTime(locker.order), [locker.order]);

  const { millisecLeft } = useCountDown({endTime});
  
  const options = useMemo(() => orders.map(order => getOptionFromOrder(order)), [orders]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = async ({value}) => {
    const promise = async () => {
      await moveOrdertoLocker({order: value, locker: locker.id, moveOrder, t});
      setIsLoading(false);
    };
    
    setIsLoading(true);

    try {
      locker.order ? manageOrder(locker.order, promise) : promise();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  if (locker.order && !isOrderExpired(locker.order, millisecLeft)) return (
    <OrderItem className='fixed_size' order={locker.order} onOrderClick={setSideOrder}/>
  );

  return (
    <div onClick={event => event.stopPropagation()}>
      <Select
        name={'order'}
        placeholder={t(`select_order`)}
        options={options}
        styles={locker_select_styles}
        onChange={handleSelect}
        components={{SingleValue: OrderSingleValue, Option: OrderOption}}
        value={getOptionFromOrder(locker.order)}
        hideSelectedOptions
        maxMenuHeight={300}
        isLoading={isLoading}
        menuPortalTarget={document.body}
        menuPlacement='auto'
      />
    </div>
  );
};