import useUpdateTableData from 'components/hooks/useUpdateTableData';
import { Formik } from 'formik';
import { apiAuth } from 'logic/api';
import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useLazyAxios } from 'use-axios-client';
import * as Yup from 'yup';
import CompanyForm from './CompanyForm';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  // name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);


/*const initialValues = {
  // name: '',
  // email: '',
};^*/

export default function CompaniesEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const [error, setError] = useState(false)

  /*const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `companies/get_companies_bo_data/${id}/`,
  });*/
  
  const getDataCompanies = async () => {
    setLoading(true)
    try {
      const res_obj = await apiAuth.get(`companies/get_companies_bo_data/?id=${id}`)
      setLoading(false)
      const res_data = res_obj.data;
      setData(res_data);
    } catch (error) {
      setLoading(false)
      setError(true)
      toast.error(error?.response?.data);
    }
  }

  const { updateData, error: updatedError } = useUpdateTableData({
    entidad: 'companies',
    id,
  });

  React.useEffect(() => {
    getDataCompanies();
  }, []);
  

  const onSubmit = async (values, { setSubmitting }) => {
    const enviaremos = beforeSubmit(values);
    await updateData(enviaremos);
    toast.success(`${t('update_ok')}`);
    getDataCompanies();
  };

  const beforeSubmit = (values) => {
    const after = { ...values };

    delete after.groups;
    delete after.opening_hours;
    delete after.owner;
    delete after.profile_photos;
    delete after.sectors;
    delete after.chef_photo;
    delete after.logo;
    delete after.menu;
    delete after.tags;
    delete after.types;
    delete after.home_image;
    delete after.home_image_order_and_pay_or_collect;
    //delete after.adyen_account_holder_code;
    delete after.adyen_account_code;
    delete after.adyen_account_tips_code;
    delete after.tpv;
    delete after.tpv_key;
    delete after.tpv_secret;
    delete after.tpv_wildcard_product_name;
    delete after.tpv_wildcard_product_code;
    delete after.tpv_local_id;

    after.business_type =
      typeof values.business_type === 'string' ? values.business_type : values.business_type?.id || null;

    return after;
  };

  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;


  if (data) {
    return (
      <div className={'flex flex-col pt-6 pl-2'}>
        <Formik
          enableReinitialize={true}
          initialValues={data/* || initialValues*/}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <CompanyForm
              {...{
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }}
            />
          )}
        </Formik>
      </div>
    );
  }
}
