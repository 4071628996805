import styled, { css } from "styled-components";

export const EventsTableContainer = styled.div`
  max-width: 100rem;
  overflow-y: auto;
  padding: 8px;
  
  table {
    border-collapse: separate;
    font-weight: 500;
    table-layout: auto;
    width: 100%;
    color: #212121;

    .disabled{
      background-color: #F3F3F3 !important;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .selected {
      border: 1px solid #094553;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: none;
  }

  th {
    font-size: 1.125rem;
    font-weight: 500;
    padding: 10px 16px;
    background-color: #FFF;
    border-bottom: 1px solid #DDD;
    position: sticky;
    top: 0;
    box-shadow: 0 -8px 0 #FFF;


    span {
      position: absolute;
      left: calc(100% + 0.5rem);
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      opacity: 0.25;
      transition: opacity 0.2s ease-in-out;

      svg {
        flex: 1;
        margin: -4px;
      }
    }

    &:has(div) {
      cursor: pointer;
    }
    
    &:hover span {
      opacity: 0.75;
    }
  }

  .custom-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-left: -150px;
    }

  tr {
    --bg_color: transparent;
    --border_color: transparent;

    .purchase {
      background-color: #EEF9F5;
      border: 1px solid #AFDCCA;
      border-radius: 1rem;
      padding: 4px 24px;
    }
    .ticket {
      --bg_color: #FFF!important;
    }
  

    &.selected {
      --bg_color: #F7FFFC;
      --border_color: #094553;
      
      .purchase {
        color: #FFF;
        background-color: #094553;
        border-color: #094553;
      }

     
    }

    &.disabled{
      td {
        background-color: #F3F3F3 !important;
      }
    }

    &.disabled:not(.selected) {
      background-color: #F3F3F3 !important;

      td {
        background-color: #F3F3F3 !important;

        &:first-child {
          border-width: 1px 0 1px 1px;
          border-radius: 8px 0 0 8px!important;
        }
        
        &:last-child {
          text-align: right;
          border-width: 1px 1px 1px 0;
          border-radius: 0 8px 8px 0!important;
        }
      }

 
    }

   
  }


  .selected-no-tickets{
    border: 1px solid #094553;
    background-color: #DDD; 
  }

  td {
    text-align: center;
    background-color: var(--bg_color);
    border-color: var(--border_color);
    border-width: 1px 0;
    border-style: solid;
    padding: 10px 16px;
  
    &:first-child {
      border-width: 1px 0 1px 1px;
      border-radius: 8px 0 0 0px;
    }
    
    &:last-child {
      text-align: right;
      padding-right: 32px;
      border-width: 1px 1px 1px 0;
      border-radius: 0 8px 0px 0;
    }
 

    .date {
      display: inline-block;
      white-space: break-spaces;
      max-width: min-content;
      line-height: 1.3rem;
    }

    &:has(.spinner) {
      padding: 0;
      border: none;
    }
  }


`;

export const DeleteEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    font-size: 1.125rem;
    max-width: 440px;
    text-align: center;
    text-wrap: balance;
  }

  b {
    font-weight: 600;
  }

  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .cancel {
    --btn_color: #5FB894;
    --btn_bg_color: transparent;
    --btn_border_color: #5FB894;
  }
`;

const fonts = css`
  & > h2, & > h3 {
    font-weight: 700;
  }

  & > h2 {
    font-size: 1.5rem;
  }

  & > h3 {
    font-size: 1.125rem;
  }
  
  & > p {
    font-size: 1rem;

    a {
      color: #094553;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;


export const RowContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  
  ${fonts}
`;

export const SeeAtWebAppContainer = styled(RowContainer)`
  flex-direction: column;
  gap: 20px;
`;

export const BodyQrContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 0 70px;
  margin-bottom: 10px;

${fonts}
`;

export const QRExplanation = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 5px;

  h2{
    text-transform: uppercase;
  }

  p{
    width: 350px;
    margin-bottom: 20px;
  }

${fonts}
`;

