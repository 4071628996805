import React from 'react';
import { NumberInput } from './Input';
import styled from 'styled-components';
import { Icon, IconType } from './Icon';

export const Counter = ({
  count,
  min = 0,
  max = Infinity,
  disabled,
  disableAdd,
  disableSubtract,
  onAdd,
  onSubtract,
  onNumberChange
}) => {
  const onChange = event => onNumberChange(Number(event.target.value));

  const onBlur = event => onNumberChange(
    Math.min(Math.max(Number(event.target.value), min), max)
  );

  return (
    <Container>
      <button disabled={disabled || disableSubtract || min >= count} onClick={onSubtract}>
        <Icon type={IconType.MINUS}/>
      </button>
      <NumberInput
        value={count}
        disabled={disabled}
        onChange={onNumberChange && onChange}
        onBlur={onBlur}
      />
      <button disabled={disabled || disableAdd || max <= count} onClick={onAdd}>
        <Icon type={IconType.PLUS}/>
      </button>
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #404040;
    border-radius: 1rem;
    height: 2rem;
    width: 2rem;
    transition: background-color 0.2s ease-in-out;

    &:disabled {
      background-color: #AAAAAA; 
    }
  }

  .input_wrapper {
    min-width: 5.5rem;

    input {
      text-align: center;
    }
  }
`;