import React, { useState } from "react";
import { FolderDownloadIcon, EyeIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import Switch from 'react-switch';
import {  baseAuth } from "logic/api";
import { centsToAmountString } from "logic/functions";

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1fr 3fr 1fr',
	width: '100%'

});

const ReportRow = ({ billing, setBillingToSee, indent, downloadPdf}) => {
  const { t } = useTranslation();
  const start_date = new Date(billing.period_start).toLocaleString('es-ES',{day: 'numeric', month:'long', year:'numeric'}).replaceAll("de", "")
  const end_date = new Date(billing.period_end).toLocaleString('es-ES',{day: 'numeric', month:'long', year:'numeric'}).replaceAll("de", "")

  indent++;

  return (
    <table className={'w-full'}>
      <tr
        className={`${bgShades[indent]} ${borderShades[indent]} border-b-2 border-gray-200 px-1`}
        style={rowStyle()}
      >
          <td style={{fontWeight: 600}} className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {centsToAmountString(billing.final_total).replace('.', ',')}€
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {start_date} - {end_date}
          </td>
          <td className={`h-full text-start text-xm font-normal py-2 items-center justify-center flex`}>
            <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <EyeIcon 
              onClick={() => setBillingToSee(billing)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
              />
            <FolderDownloadIcon
              onClick={() => downloadPdf(billing, 1)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />  
              </div>
          </td>        
      </tr>
    </table>
  )
};

export default ReportRow