import React, { useEffect, useContext, useRef } from 'react';
import { Context } from "data/authContext";
import { baseAuth } from 'logic/api';
import { LockersContext } from 'data/LockersContext';
import { lockerSorter } from './functions';
import { SECOND } from 'logic/defaults';
import { cloneDeep, isEqual } from 'lodash';

const nestInParent = (children, parents, refId, attrName) => {
  children.forEach(child => {
    const parent = parents.find(parent => parent.id === child[refId]);

    if (!parent) {
      console.error(new Error(`nestInParent error: ${refId} ${child[refId]} not found while nesting ${attrName}`));
      return;
    };

    if (parent[attrName]) {
      parent[attrName].push(child);
    } else {
      parent[attrName] = [child];
    };
  });
};

const formatLockersData = data => {
  try {
    const { lockers, locker_orders, modifiers, orders, products, shift } = cloneDeep(data);

    lockers.sort(lockerSorter);

    const counter = lockers.find(locker => locker.counter);
    
    if (counter) {
      counter.order_list = [];
    };

    nestInParent(modifiers, products, 'order_product__id', 'modifiers');
    nestInParent(products, orders, 'order_request__id', 'products');
  
    locker_orders.forEach(lockerOrder => {
      const locker = lockers.find(locker => locker.id === lockerOrder.locker__id);
      const orderIndex = orders.findIndex(order => order.id === lockerOrder.order_request__id);

      if (!locker) {
        console.error(new Error(`formatLockersData error: Locker ${lockerOrder.locker__id} not found`));
        return;
      };
      
      if (orderIndex === -1) {
        console.error(new Error(`formatLockersData error: Order ${lockerOrder.order_request__id} not found`));
        return;
      };

      const order = orders.splice(orderIndex, 1)[0];
      
      order.modified_at = lockerOrder.modified_at
  
      if (locker.counter) {
        locker.order_list.push(order);
      } else {
        locker.order = order;
      };
    });
  
    return { lockers, orders, shift };
  } catch (error) {
    console.error(error);
    return { lockers: null, orders: null };
  };
};

export const useLockers = () => {
	const { state: { selectedRestaurantId } } = useContext(Context);
  const { state, setState } = useContext(LockersContext);

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => isMountedRef.current = false;
  }, []);

  useEffect(() => {
    let timeout;

    const recursivelyUpdateLockers = prevData => {
      if (!isMountedRef.current) return;
    
      baseAuth.get(`/locker/get_lockers/${selectedRestaurantId}`)
        .then(({data}) => {
          !isEqual(data, prevData) && setState(formatLockersData(data));
    
          timeout = setTimeout(() => recursivelyUpdateLockers(data), SECOND * 30);
        }).catch(err => console.log(err));
    };

    selectedRestaurantId && recursivelyUpdateLockers();

    return () => clearTimeout(timeout);
  }, [selectedRestaurantId]);

  return state;
};
