import React from 'react';
import styled from "styled-components";

const DEFAULT_THEME = {
  background_contrast: '#E1E1E1',
  primary: '#094553',
};

export const ProgressBarMockup = ({current, total, icon, animated, className = '', theme = DEFAULT_THEME}) => {
  const progress = Math.min(100 / total * current, 100);
  
  const animate = (element) => {
    if (animated && element) {
      const observer = new IntersectionObserver(([entry]) => {
        entry.isIntersecting && element.style.setProperty('width', `${progress}%`);
      });
  
      element.style.setProperty('width', `0%`);
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
  };

  return (
    <ProgressBarContainer className={className} theme={theme}>
      <div style={{width: progress + '%'}} ref={animate}>
        {icon &&
          <span>{icon}</span>
        }
      </div>
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  height: 4px;
  width: 100%;
  margin: 4px 0;
  background-color: ${({theme}) => theme.background_contrast};
  border-radius: 999px;

  & > div {
    border-radius: 999px;
    height: 4px;
    min-width: min-content;
    background: ${({theme}) => theme.primary};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: width 0.2s ease;
    
    & > span {
      display: flex;
      width: 12px;
      height: 12px;
      padding: 0.5px;
      margin: -0.5px;
      background-color: #FFF;
      border-radius: 999px;
      box-sizing: border-box;

      &:not(:has(*)) {
        display: none;
      }
    }
  }
`;