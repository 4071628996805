import React from 'react';
import { useTranslation } from 'react-i18next';
import { calculateEuros } from 'services/payments';
import { ProductListContainer } from './LockerContainers';

export const ProductList = ({products}) => {
  const { t } = useTranslation();

  return (
    <ProductListContainer className='products'>
      <h4>
        <span>{products?.length || 0}</span>
        {t(products?.length === 1 ? 'product' : 'products')}
      </h4>
      <RecursiveList list={products}/>
    </ProductListContainer>
  );
};

export const RecursiveList = ({list, isModifier}) => {
  if (!list || !list.length) return <></>;

  return (
    <ul>
      {list?.map(item => (
        <React.Fragment key={item.id}>
          {Array(isModifier ? item.quantity : 1).fill(null).map((_, index) =>
            <li key={`${item.id}-${index}`}>
              {!isModifier &&
                <span>{item.quantity}</span>
              }
              <h5>{item.name}</h5>
              <b>{item.price_cents ? calculateEuros(item.price_cents) + '€' : ''}</b>
            </li>
          )}
          <RecursiveList list={item.modifiers} isModifier/>
        </React.Fragment>
      ))}
    </ul>
  );
};
