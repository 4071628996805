import * as Yup from 'yup';

import {adyenAuth, apiAuth} from 'logic/api';
import {buildBussinessPayload, buildIndividualPayload, buildStorePayload} from './utils';
import {useAxios, useLazyAxios} from 'use-axios-client';
import {useParams} from 'react-router';

import FormError from 'common/FormError';
import {Formik} from 'formik';
import OBUrlButton from './OBUrlButton';
import React from 'react';
import XButton from 'common/XButton';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  // name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
  // name: '',
  // email: '',
};

export default function RestaurantsEditExternal() {
  const {t} = useTranslation();
  const {id} = useParams();

  const {data: list} = useAxios({
    axiosInstance: apiAuth, url: `/tpvs/`,
  });


  const [getData, {data, error, loading}] = useLazyAxios({
    axiosInstance: apiAuth, url: `/restaurants-admin-new/${id}/`,
  });

  const [createData, {error: createError}] = useLazyAxios({
    axiosInstance: adyenAuth, method: 'POST', url: `/create-account-holder`,
  });

  const [createStore, {error: createStoreError}] = useLazyAxios({
    axiosInstance: adyenAuth, method: 'POST', url: `/create-account-holder-store`,
  });

  React.useEffect(() => {
    //
    getData();
  }, [getData]);


  const onSubmit = async (values, {setSubmitting}) => {
    //
    const enviaremos = beforeSubmit(values);

    if (enviaremos) {
      if (data.adyen_kyc_passed) {
        try {
          await createStore(enviaremos);
          toast.success(`${t('update_ok')}`);
        } catch (error) {
          toast.error(t('server error'));
        } finally {
          getData();
        }
      } else {
        try {
          await createData(enviaremos);
          toast.success(`${t('update_ok')}`);
        } catch (error) {
          toast.error(t('server error'));
        } finally {
          getData();
        }
      }
    } else {
      toast.error(t('missing_fields'));
    }
  };

  const beforeSubmit = (values) => {
    let after;

    if (data.adyen_kyc_passed) {
      after = buildStorePayload(values)
    } else {
      const restaurant_type = typeof values.business_type === 'string' ? values.business_type : values.business_type.id;
      if (restaurant_type === 'Business') {
        after = buildBussinessPayload(values);
      } else if (restaurant_type === 'Individual') {
        after = buildIndividualPayload(values);
      }
    }

    after.id = values.id;

    return after;
  };

  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error || createError || createStoreError) {
    return <pre>{JSON.stringify(error || createError, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-col'}>
        <Formik
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} autoComplete='off'>
              {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}

              <div className='flex flex-row space-x-5 w-full'>
                {/* col1 */}
                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='name'
                    >
                      {t('Business_Name')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Business_Name')}
                        className='yumminn-input'
                        value={values.name || ''}
                        name='name'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.name} />
                    </div>
                  </div>
                </div>
              </div>

              {/* ********************************************** */}
              {/* only superusers can edit pos and payments data */}
              {/* ********************************************** */}

              <div className='flex flex-row space-x-5 w-full mt-5'>
                {/* col1 */}
                <div className='w-full flex flex-col'>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_holder_code'
                    >
                      {t('Account_holder')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Account_holder')}
                        className='yumminn-input'
                        value={values.adyen_account_holder_code || ''}
                        name='adyen_account_holder_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_account_holder_code} />
                    </div>
                  </div>
                  {/* // company nif */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_code'
                    >
                      {t('Account')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Account')}
                        className='yumminn-input'
                        value={values.adyen_account_code || ''}
                        name='adyen_account_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_account_code} />
                    </div>
                  </div>
                  {/* // compnay bank */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_tips_code'
                    >
                      {t('Tips_account')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Tips_account')}
                        className='yumminn-input'
                        value={values.adyen_account_tips_code || ''}
                        name='adyen_account_tips_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_account_tips_code} />
                    </div>
                  </div>
                  {/* // compnay bank */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='adyen_account_tips_code'
                    >
                      {t('Store')}
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Store')}
                        className='yumminn-input'
                        value={values.adyen_store_code || ''}
                        name='adyen_store_code'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                        disabled={true}
                      />
                      <FormError message={errors.adyen_store_code} />
                    </div>
                  </div>
                </div>
                {/* END col1 */}

                {/* col2 */}

                <div className='w-full flex flex-col'>
                </div>
                {/* END col2 */}
              </div>

              <div className='self-end my-5 w-full'>
                {!data.adyen_kyc_passed &&
                  (!values.adyen_account_holder_code ||
                    values.adyen_account_holder_code === '') && (
                    <XButton
                      className='btn btn-primary rounded-full px-12'
                      type='button'
                      doClick={handleSubmit}
                      disabled={
                        values.adyen_account_holder_code &&
                        values.adyen_account_holder_code !== ''
                      }
                    >
                      {t('Create_accounts')}
                    </XButton>
                  )}
                {data.adyen_store_code === '' && data.adyen_kyc_passed && (
                  <XButton
                    className='btn btn-primary rounded-full px-12'
                    type='button'
                    doClick={handleSubmit}
                    disabled={!data.adyen_kyc_passed}
                  >
                    {t('Create_store')}
                  </XButton>
                )}
                {!data.adyen_kyc_passed &&
                  values.adyen_account_holder_code &&
                  values.adyen_account_holder_code !== '' && (
                    <OBUrlButton account={values.adyen_account_holder_code} />
                  )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
