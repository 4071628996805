import React from 'react'
import { mockThemes } from '../constants';
import styled from "styled-components";

const BadgeContainer = styled.div`
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  color: ${({theme, textColor}) => textColor ? textColor : theme.text_light};
  background-color: #fef282;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  border-radius: 999px;
  padding: 2px 4px;
  box-shadow: ${({theme, shadow}) => !shadow ? 'none' : theme.shadow_sm};
  position: relative;

  p {
    font-size: ${({theme}) => theme.font_xs};
    margin: 0;
  }
`;

const BlackText = styled.span`
  color: ${({theme}) => theme.text_dark};
`;


export const BadgeComponent = ({color, textColor, children, shadow, className = ''}) => {
  const theme = mockThemes['light'];

  return (
    <BadgeContainer textColor={textColor} color={color || theme.primary} shadow={shadow} className={className}>
      {children}
    </BadgeContainer>
  );
};

export const DiscountBadge = ({discount = 0, className = ''}) => {
  const theme = mockThemes['light'];
  
  if (!discount) return <></>;

  return (
    <BadgeComponent color={theme.discount} className={className}>
      <BlackText>{`-${discount}%`}</BlackText>
    </BadgeComponent>
  );
};