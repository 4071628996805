import React from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import SideBar from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';

const DiscountsContainer = ({ children, title }) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");

  return (
    <div id="menuContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <SideBar style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
        <MenuHeader title={title} route_group={'menu'}/>
          
        <div className={`px-[32px] bg-white`} >
          <div className=' flex flex-row items-end gap-5 py-4'>
            <RestaurantSelect />
          </div>
        </div>
          <div>{children}</div>
      </div>
    </div>
  );
};

export default DiscountsContainer;
