import React, { useState, useEffect, useContext, useRef } from "react";
import Spinner from "common/Spinner";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import RoundPagination from "common/RoundPagination";
import styled from "styled-components";
import { baseAuth } from "logic/api";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';
import BookingsTable from "./Bookings/BookingsTable";
import BookingDetail from "./Bookings/BookingDetail";
import { AddButton } from "./OrderAndPay/AddButton";
import tableIcon from '../../../img/tableIconWhite.svg';
import BookingSummary from "./Bookings/BookingSummary";
import UserIcon from '../../../img/userIconWhite.svg';
import BookingModal from "./OrderAndPay/Modals/BookingModal";
import { toast } from "react-toastify";
import { newBookingMock } from "./Bookings/newBookingMock";
import CancelBookingModal from "./OrderAndPay/Modals/CancelBookingModal";
import CustomDateRangeSelect from "components/yumminn/CustomDateRangeSelect";

const BookingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const Bookings = () => {
  
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();
  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'));
  const hasActiveOrderAndPayTab = restaurants.some(item => item.allow_booking);
  const elementsPerPage = 50;
  const [selectedBooking           , setSelectedBooking]            = useState(null);
  const [numberOfPages             , setNumberOfPages]              = useState(null);
  const [totalNumberOfBookings     , setTotalNumberOfBookings]      = useState(null);
  const [bookings                  , setBookings]                   = useState([]);
  const [daysDiners                , setDaysDiners]                 = useState([]);
  const [reservationDate           , setReservationDate]            = useState(null);
  const [reservationDateTo         , setReservationDateTo]          = useState(null);
  const [weekdaysMaxDiners         , setWeekdaysMaxDiners]          = useState([0, 0, 0, 0, 0]);
  const [page                      , setPage]                       = useState(1);
  const [loading                   , setLoading]                    = useState(false);
  const [getDataLoading            , setGetDataLoading]             = useState(false);
  const [componentStarted          , setComponentStarted]           = useState(false);
  const [bookingToCancel           , setBookingToCancel]            = useState(null);
  const [bookingToEdit             , setBookingToEdit]              = useState(null);
  const [bookingToCreate           , setBookingToCreate]            = useState(null);
  const selectedRestaurantRef         = useRef(selectedRestaurant);
  const pageRef                       = useRef(page);
  const getDataLoadingRef             = useRef(getDataLoading);
  const loadingRef                    = useRef(loading);
  const selectedBookingRef            = useRef(selectedBooking);
  const totalNumberOfBookingsRef      = useRef(totalNumberOfBookings);
  const reservationDateRef            = useRef(reservationDate);
  const reservationDateToRef          = useRef(reservationDateTo);

  selectedRestaurantRef.current = selectedRestaurant;
  pageRef.current = page;
  getDataLoadingRef.current = getDataLoading;
  loadingRef.current = loading;
  selectedBookingRef.current = selectedBooking;
  totalNumberOfBookingsRef.current = totalNumberOfBookings;
  reservationDateRef.current = reservationDate;
  reservationDateToRef.current = reservationDateTo;

  const getData = ({restaurants, showLoading, finallyCallback = () => {}}) => {
    const path = window.location.pathname
    const id = restaurants[0].value
    let endpoint = `booking/list/bookings?&page=${pageRef.current}&restaurant=${id}`;
    if (reservationDateRef.current) {
      endpoint += `&date_from=${reservationDateRef.current.toISOString()}&date_to=${reservationDateToRef.current.toISOString()}`
    }
    setGetDataLoading(true);
    if (showLoading) {
      setLoading(true);
    }
    if(path !== '/admin/bookings') return;
    baseAuth
      .get(endpoint)
      .then(res => {
        const response_bookings = res.data.bookings
        const days_diners = res.data.days_diners
        const weekdays_max_diners = res.data.weekdays_max_diners
        setBookings(response_bookings);
        setDaysDiners(days_diners)
        setWeekdaysMaxDiners(weekdays_max_diners)
        if (!selectedBookingRef.current || response_bookings.filter(o => o.id === selectedBookingRef.current.id).length === 0) {
          if (response_bookings.length > 0) {
            setSelectedBooking(response_bookings[0]);
          }
        }    
        const totalBookings = res.data.total_number_of_bookings;
        setTotalNumberOfBookings(totalBookings);

        const nextNumberOfPages = Math.ceil(totalBookings / elementsPerPage);
        setNumberOfPages(nextNumberOfPages > 0 ? nextNumberOfPages : null);
      })
      .catch(err => console.log(`Bookings.jsx:getData ~ endpoint=${endpoint} catch: ${err.toString()}`))
      .finally(_ => {
        setGetDataLoading(false);
        setLoading(false);
        finallyCallback();
      });
  };

  const updateData = (showLoading=true, notifyNew=false) => {
    if (!selectedRestaurantRef.current) {
      return;
    }
    if (getDataLoadingRef.current) {
      // In case there is a request currently being performed, we check again in 100ms.
      setTimeout(() => {
        updateData(showLoading, notifyNew);
      }, 100);
    } else {
      getData({restaurants: selectedRestaurantRef.current, showLoading:showLoading});
    }
  };


  const updateAfterTimeout = () => {
    setTimeout(() => {
      recursivelyUpdateBookings();
    }, 8000);
  };

  const recursivelyUpdateBookings = () => {
    if (selectedRestaurantRef.current && !getDataLoadingRef.current) {
      getData({restaurants:selectedRestaurantRef.current, showLoading:false, finallyCallback: updateAfterTimeout});
    } else {
      updateAfterTimeout();
    }
  };

  useEffect(() => {
    recursivelyUpdateBookings();
    setComponentStarted(true);
   
  }, []);

  useEffect(() => {
    setPage(1);
    setNumberOfPages(null);
    if (componentStarted) {
      updateData();
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (componentStarted) {
      updateData();
    }
  }, [page, reservationDate]);

  const updateBooking = async (booking) => {
		setLoading(true)
		const newBooking = JSON.parse(JSON.stringify(booking))
		await baseAuth.post(`/booking/update/booking/`, newBooking)
		  .then(() => {
        getData({restaurants: selectedRestaurantRef.current, showLoading:true});
        setBookingToEdit(null)
        setBookingToCreate(null)
        setBookingToCancel(null)
		  })
		  .catch((e) => {
        toast.error("Error al modificar la reserva.");   
			  setLoading(false)
		  })
	  }

  const createBooking = async (booking) => {
    setLoading(true)
    const newBooking = JSON.parse(JSON.stringify(booking))
    await baseAuth.post(`/booking/create/0/?restaurant=${selectedRestaurantRef.current[0].value}`, newBooking)
      .then(() => {
        setSelectedBooking(null)
        getData({restaurants: selectedRestaurantRef.current, showLoading:true});
        setBookingToEdit(null)
        setBookingToCreate(null)
        setBookingToCancel(null)
      })
      .catch((e) => {
        toast.error("Error al crear reserva.");
        setLoading(false)
      })
    }

  const cancelBooking = async (booking, reason) => {
    setLoading(true)
    await baseAuth.post(`/booking/cancel/booking/`, {"id": booking.id, "reason": reason})
      .then(() => {
        getData({restaurants: selectedRestaurantRef.current, showLoading:true});
        setBookingToEdit(null)
        setBookingToCreate(null)
        setBookingToCancel(null)
      })
      .catch((e) => {
        toast.error("Error al eliminar la reserva.");
        setLoading(false)
      })
    }

  const handleCreate = () => {
    newBookingMock["restaurant"] = selectedRestaurant[0].value
    setBookingToCreate(newBookingMock)
  }

  const changeReservationDates = (dates) => {
    setReservationDate(dates[0])
    setReservationDateTo(dates[1])
  }
  
  if (!hasActiveOrderAndPayTab) return <UnavailableFunctionality title={'Bookings'} />
  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {loading && <LoadingOverlay/>}
        <div style={{marginBottom: "10px"}} className={`flex-auto w-full mt-4`}>
          <BookingsHeader>
            <div style={{display: 'flex', gap: "15px"}}>
              <CustomDateRangeSelect selectedDate={reservationDate} selectedDateTo={reservationDateTo} changeSelectedDates={changeReservationDates} hourFormat={false} allowAfterToday={true} title="booking_period"/>
            </div>
            {bookings?.length > 0 &&
              <div style={{display: 'flex', gap: "15px"}}>
                <BookingSummary imgSrc={tableIcon}  imgHg={"12px"} value={totalNumberOfBookings} text={t("bookings")}/>
                <BookingSummary imgSrc={UserIcon} imgHg={"17px"} value={bookings.reduce((sum, item) => sum += item.diners, 0)} text={t("diners")}/>
              </div>
            }
          </BookingsHeader>
          <BookingsHeader>
            {numberOfPages && (
              <RoundPagination
                page={page}
                numberOfPages={numberOfPages}
                setPage={setPage}
                total={totalNumberOfBookings}
                elementNamePlural={t("bookings")}
                elementNameSingular={t("booking")}
              />
            )}
            <AddButton onClick={() => handleCreate()} text={t(`Crear nueva reserva`)} style={{margin: "14px 0 0 0", backgroundColor: "#5FB894", color: "#FFFFFF", border: 'none'}} plusIconStyle={{backgroundColor: "#FFFFFF", borderColor: "#FFFFFF", color: '#5FB894', opacity: 0.6}}/>
          </BookingsHeader>
          <div style={{display: 'flex', gap: '1%', zIndex: 0}}>
            <div style={{backgroundColor: 'white', borderRadius: '10px', margin: "20px 0px", padding: "10px", width: '59%'}}>
              <BookingsTable bookings={bookings} selectedBooking={selectedBooking} setSelectedBooking={setSelectedBooking}/>
            </div>
            {selectedBooking && bookings.find((od => od.id === selectedBooking.id)) && 
            <div style={{backgroundColor: 'white', borderRadius: '10px', margin: "20px 0px", padding: "10px", width: '40%'}}>
              <BookingDetail booking={bookings.find((od => od.id === selectedBooking.id))} setBookingToCancel={setBookingToCancel} setBookingToEdit={setBookingToEdit}/>
            </div>}
          </div>
        </div>
      </div>
      {bookingToCancel &&
				<CancelBookingModal t={t} cancelBooking={cancelBooking} booking={bookingToCancel} setBookingToCancel={setBookingToCancel}/>
			}
      {bookingToEdit &&
				<BookingModal t={t} booking={bookingToEdit} setBookingToEdit={setBookingToEdit} saveBooking={updateBooking} setBookingToCancel={setBookingToCancel} type={0} weekdaysMaxDiners={weekdaysMaxDiners} daysDiners={daysDiners}/>
			}
      {bookingToCreate &&
				<BookingModal t={t} booking={bookingToCreate} setBookingToEdit={setBookingToCreate} saveBooking={createBooking} setBookingToCancel={setBookingToCancel} type={1} weekdaysMaxDiners={weekdaysMaxDiners} daysDiners={daysDiners}/>
			}
    </>
  );
};

export default Bookings;
