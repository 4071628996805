import React, { useContext, useMemo, useState } from 'react';
import { OrderFiltersContainer, OrderList, PanelTemplate, ItemButton, LockerBadge, ConfirmClearAllPopup } from './LockerContainers';
import { Trans, useTranslation } from 'react-i18next';
import { ChevronRightIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import { TabSelector } from 'common/TabSelector';
import { ORDER_TABS } from './constants';
import { LockersContext } from 'data/LockersContext';
import { OrderItem } from './OrderItem';
import Select from 'react-select';
import { order_item_select_styles, select_styles } from 'logic/defaults';
import { getEmptyLockersOptions, getLockerColor, isOrderExpired, moveOrdertoLocker } from './functions';
import { LockerOption } from './SelectComponents';
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';
import { Context } from 'data/authContext';
import { Popup } from 'common/Popup';

const lang = sessionStorage.getItem('i18nextLng') || 'es-ES';

export const OrdersPanel = ({ manageOrder, openLocker, setSideOrder, close }) => {
  const { state: { selectedRestaurantId } } = useContext(Context);
  const { state: { lockers, orders, shift }, moveOrder, clearLockers } = useContext(LockersContext);
  const { t } = useTranslation();
  
  const sortOptions = [{label: t('closer'), value: -1}, {label: t('farthest'), value: 1}];

  const [tab, setTab] = useState(ORDER_TABS[0]);
  const [sortBy, setSortBy] = useState(sortOptions[0]);
  const [filterSlots, setFilterSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showClearAllPopup, setShowClearAllPopup] = useState(false);

  const ordersInLockers = useMemo(() => {
    return lockers.filter(locker => locker.order || locker.order_list)
      .map(locker => {
        if (locker.counter) {
          const orders = locker.order_list.map(order => ({...order, locker}))
          return orders;
        };

        return {...locker.order, locker};
      }).flat();
  }, [lockers]);

  const orderList = useMemo(() => {
    return (tab === ORDER_TABS[0] ? orders : ordersInLockers)
    .filter(order => !filterSlots.length || filterSlots.some(slot => slot.value === order.ready_at))
    .sort((a, b) => (Date.parse(b.ready_at) - Date.parse(a.ready_at)) * sortBy.value);
  }, [tab, orders, ordersInLockers, sortBy, filterSlots]);

  const slotOptions = useMemo(() => {
    if (!shift) return [];
    
    const options = [];
    const today = new Date();
    
    for (let i = 0; i < shift.slots_quantity + 1; i++) {
      const slotMinutes = shift.start_minute + shift.slot_duration_minutes * i;
      const hour = Math.floor(slotMinutes / 60);
      const minute = Math.floor(slotMinutes % 60);
      
      today.setHours(hour, minute, 0, 0);
      options.push({
        label: today.toLocaleTimeString(lang, {timeStyle: 'short'}),
        value: today.toISOString().replace(/\.\d+/, '')
      })
    };

    return options;
  }, [shift?.id]);

  const emptyLockerOptions = useMemo(() => {
    return getEmptyLockersOptions({lockers});
  }, [lockers]);

  const handleSelect = async ({order, lockerId}) => {
    const promise = async () => {
      await moveOrdertoLocker({order: order.id, locker: lockerId, moveOrder, t});
      setIsLoading(false);
    };
    
    setIsLoading(true);

    try {
      const newLocker = lockers.find(locker => locker.id === lockerId);
      newLocker.order ? manageOrder(newLocker.order, promise) : promise();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const showOrderDetails = (order) => (order.locker.counter || isOrderExpired(order)) ? manageOrder(order) : openLocker(order.locker);

  const clearAllLockers = async () => {
    setIsLoading(true);
    try {
      await baseAuth.get(`locker/clear_all_lockers/${selectedRestaurantId}`);
      clearLockers();
    } catch (error) {
      console.error(error);
      toast.error('clear_all_lockers_error');
    } finally {
      setShowClearAllPopup(false);
      setIsLoading(false);
    }
  };

  const onOrderClick = order => (order?.locker && !order.locker.counter) ? openLocker(order.locker) : setSideOrder(order);

  return (
    <PanelTemplate>
      <header>
        <h3>{t('order_management')}</h3>
        <button onClick={close}>
          <XIcon height={24}/>
        </button>
      </header>
      <hr/>
      <TabSelector
        name={'order_management'}
        value={tab}
        options={ORDER_TABS}
        onChange={setTab}
        color={'#094553'}
        className='tabs'
      />
      {tab === ORDER_TABS[1] &&
        <button className='clear_all' onClick={() => setShowClearAllPopup(true)}>
          {t('clear_all_lockers')}
        </button>
      }
      <OrderFiltersContainer>
        <label>
          <span>{t('pickup_time')}</span>
          <Select
            name='pickup_time'
            value={sortBy}
            options={sortOptions}
            onChange={setSortBy}
            styles={select_styles}
          />
        </label>
        <label>
          <span>{t('slot')}</span>
          <Select
            name='slot'
            value={filterSlots}
            options={slotOptions}
            onChange={setFilterSlots}
            styles={select_styles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder={t('all_slots')}
            maxMenuHeight={200}
            isMulti
          />
        </label>
        <p>
          <b>{orderList.length}</b>
          {t(orderList.length === 1 ? 'order' : 'orders')}
        </p>
      </OrderFiltersContainer>
      <OrderList>
        {orderList.map(order =>
          <OrderItem key={order.id} order={order} crossExpired={tab === ORDER_TABS[1]} onOrderClick={onOrderClick}>
            {tab === ORDER_TABS[0] &&
              <Select
                placeholder={t('assign_to_locker')}
                options={emptyLockerOptions}
                styles={order_item_select_styles}
                components={{Option: LockerOption}}
                onChange={({value}) => handleSelect({order: order, lockerId: value})}
                isLoading={isLoading}
                menuPortalTarget={document.body}
                maxMenuHeight={300}
                menuPlacement='auto'
              />
            }
            {tab === ORDER_TABS[1] &&
              <>
                <LockerBadge color={getLockerColor(order.locker)}>
                  {order.locker.name}
                </LockerBadge>
                <ItemButton onClick={() => showOrderDetails(order)}>
                  <span>{isOrderExpired(order) ? t('clear') : t('see_order')}</span>
                  <ChevronRightIcon height={18}/>
                </ItemButton>
              </>
            }
          </OrderItem>
        )}
      </OrderList>
        <ConfirmClearAll
          isOpen={showClearAllPopup}
          confirm={clearAllLockers}
          cancel={() => setShowClearAllPopup(false)}
        />
    </PanelTemplate>
  );
};

const ConfirmClearAll = ({isOpen, confirm, cancel}) => {
  const { t } = useTranslation();

  return (
    <Popup isOpen={isOpen}>
      <ConfirmClearAllPopup>
        <TrashIcon height={32}/>
        <h3>{t('clear_all_lockers_title')}</h3>
        <p>
          <Trans i18nKey={'clear_all_lockers_message'}>
            Continuing will <b>empty all lockers</b> and mark all orders as delivered. Are you sure you want to continue?
          </Trans>
        </p>
        <footer>
          <button className='cancel' onClick={cancel}>
            {t('no_cancel')}
          </button>
          <button onClick={confirm}>
            {t('yes_continue')}
          </button>
        </footer>
      </ConfirmClearAllPopup>
    </Popup>
  );
};