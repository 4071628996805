import styled from "styled-components";

const BaseTabButton = styled.div`
  --text_color: #767676;
  --bg_color: transparent;
  --border_color: transparent;
  --outline_color: #094553;

  --bg_color_hover: #D4F9EE;
  --outline_color_hover: transparent;

  ${({isLocked}) => isLocked && `
    --outline_color_hover: var(--outline_color);
    `}

  ${({disabled}) => disabled && `
    --outline_color_hover: none;
    --pointer_events: none;
  `}
  
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  white-space: nowrap;
  color: var(--text_color);
  background-color: var(--bg_color);
  border: 1px solid var(--border_color);
  border-radius: 99px;
  width: min-content;
  transition-property: color, background-color, border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 24px;
  }

  &:hover {
    background-color: var(--bg_color_hover);
    outline: 2px dashed var(--outline_color_hover);
    outline-offset: -2px;
  }
`;

const SuperGroupTabButton = styled(BaseTabButton)`
  ${({isSelected}) => isSelected && `
    --text_color: #FFF;
    --bg_color: #094553;
    --border_color: var(--bg_color);
    --outline_color: #FFF;
    --bg_color_hover: var(--bg_color);
  `}
`;

const CategoryGroupTabButton = styled(BaseTabButton)`
  ${({isSelected}) => isSelected && `
    --text_color: #094553;
    --bg_color: #CBFAEE;
    --border_color: #094553;
    --outline_color: #FFF;
    --bg_color_hover: var(--bg_color);
  `}
`;

const CategoryTabButton = styled(BaseTabButton)`
  ${({isSelected}) => isSelected && `
    --text_color: #094553;
    --bg_color: #FFF;
    --border_color: #094553;
    --outline_color: #FFF;
    --bg_color_hover: var(--bg_color);
  `}
`;

export const TabButtonContainer = ({ level, selected, isLocked, disabled, ...props }) => {
  if (level === 'SUPER_GROUP') return <SuperGroupTabButton isSelected={selected} isLocked={isLocked} disabled={disabled} {...props}/>;
  if (level === 'CATEGORY_GROUP') return <CategoryGroupTabButton isSelected={selected} isLocked={isLocked} disabled={disabled} {...props}/>;
  if (level === 'CATEGORY') return <CategoryTabButton isSelected={selected} isLocked={isLocked} disabled={disabled} {...props}/>;
  return <BaseTabButton isSelected={selected} isLocked={isLocked} disabled={disabled} {...props}/>;
};

export const DroppableContainer = styled.div`
  width: 100%;
  display: flex;
	overflow-x: auto;
  overflow-y: visible;
	opacity: ${({disabled}) => disabled ? '0.5' : ''};
  position: relative;
  padding: 32px 0 12px;
  margin-top: -32px;
  min-height: 52px;
	pointer-events: ${({disabled}) => disabled ? 'none' : 'all'};

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F1F1F1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    border-radius: 4px;
    height: 100%;
    border: 0px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #898989;
  }

  & > span {
    position: sticky;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background: linear-gradient(90deg, transparent 0%, #FFF 50%);
    padding-left: 1rem;
    z-index: 99;
  }
`;