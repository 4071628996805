import React,{ useState } from 'react';
import { Table } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import {useMediaQuery} from '../../hooks/useMediaQuery';
import infoIcon from '../../img/info.png';
import useModal from 'components/hooks/useModal';
import ArrowIndicator from './ArrowIndicator';

const TableCard = ({rows}) => {
  const { t } = useTranslation();
  const infoModal = useModal();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [explanation, setExplanation] = useState("");
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');

  const openInfoModal = label => {
    const title = document.querySelector('#title')
    const topBar = document.querySelector('#topBar')
    title && title.setAttribute('style', 'z-index: 0')
    topBar && topBar.setAttribute('style', 'z-index: 0')
    setExplanation(label + '_explanation')
    infoModal.openModal()
  };

  return (
    <div className='text-trueGray-700 max-w-prose bg-white text-sm rounded-2xl overflow-hidden' style={{boxShadow: '0 10px 24px 0 #959da533'}}>
      <Table responsive='sm' size="sm" className='w-fit overflow-auto'>
        <thead>
          <tr>
          <th>{/* Empty cell */}</th>
          <th className='text-sm text-right p-2'>{t('Current')}</th>
          <th className='text-sm text-right p-2'>{t('Previous')}</th>
          {!isRowBased && 
            <th className='text-sm text-right p-2'>{t('Variation')}</th>
          }
          </tr>
        </thead>
        <tbody>
          {rows && rows.map (({label, actualValue, previousValue, variation, isGray = false}) => (
            <tr key={label} className={isGray ? ' table-cell-gray' : ''}>
              <td className={`p-4 flex items-center gap-1 ${isGray ? ' table-cell-gray' : ''}`} style={{paddingRight: ''}}>
                <span className='whitespace-normal'>{t(label)}</span>
                <img
                  src={infoIcon}
                  alt={"info-icon"}
                  className='w-5 h-5 cursor-pointer'
                  onClick={() => openInfoModal(label)}
                />
              </td>
              <td className={`text-right ${isGray ? ' table-cell-gray' : ''}`}>{actualValue}</td>
              <td className={`text-right ${isGray ? ' table-cell-gray' : ''}`}>{previousValue}</td>
              {!isRowBased && 
                <td className={`font-bold text-sm text-right ${isGray ? 'table-cell-gray' : ''} ${!variation ? 'text-trueGray-400' : variation > 0 ? 'text-principal' : 'text-[#E18684]'}`}>
                  {variation}%
                  <ArrowIndicator value={variation}/>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </Table>
      <infoModal.Modal>
        {t(explanation)}
      </infoModal.Modal> 
    </div>
  );
};

export default TableCard;