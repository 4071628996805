import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { select_styles, select_styles_month } from 'logic/defaults';
import "../../../../components/yumminn/DateSelect.css";
import "react-datepicker/dist/react-datepicker.css";

const EventModal = ({ event, closeModal, save }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [eventToEdit, setEventToEdit] = useState(event)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleSelectDate= (opcion) => {
    setSelectedDate(opcion)
    if(eventToEdit){
      eventToEdit.date = opcion
      setEventToEdit({...eventToEdit})
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    let name = e.target.name?.value;
    let phone = e.target.phone?.value;
    let email = e.target.email?.value;
    let company = e.target.company?.value;
    let occasion = e.target.occasion?.value;
    let concept = e.target.concept?.value;
    let hour = e.target.hour?.value;
    let cents = e.target.cents?.value * 100;
    let event_date = e.target.event_date?.value;
    let created_event_date = e.target.created_event_date?.value;
    let details = e.target.details?.value;
    
    save({ eventId: eventToEdit ? eventToEdit.uuid : null, name: name, phone: phone, email: email, company: company, occasion: occasion, concept: concept, hour: hour, cents: cents, event_date: event_date, created_event_date: created_event_date, details: details });
  }

  const handleChange = (value, newValue) => {
    if(eventToEdit){
      eventToEdit[value] = newValue
      setEventToEdit({...eventToEdit})
    }
  }

  useEffect(() => {
    document.getElementById("created_event_date").disabled = true;
    document.getElementById("created_event_date").min = new Date().toISOString().slice(0, 10);
  }, [])

  return (
    <div style={{ zIndex: 1000 }} className="absolute top-0 left-0 right-0 bottom-0 items-center">
      <div className="lg:w-1/2 md:w-full top-20 m-auto z-50 relative flex flex-col gap-6 justify-between p-5 overflow-hidden bg-white rounded-xl">
        <button
          onClick={() => closeModal()}
          className="self-end fill-current h-5 w-5 absolute font-6xl text-4xl"
        >
          &times;
        </button>
        {eventToEdit?
          <div className="font-bold max-w-min whitespace-nowrap">{t("Edit_event")}</div>
          :
          <div className="font-bold max-w-min whitespace-nowrap">{t("Create_event")}</div>
        }
        <form
          onSubmit={handleSubmit}
          style={{
            gridTemplateAreas: `
              "main main"
              "main main"
              "main main"
              "main main"
              "main main"
              "footer footer"
              "footer footer"
            `}}
          className="flex flex-col lg:grid  grid-cols-2 gap-3"
        >
          <label>
            {t("name")}
            <input required value={eventToEdit?.name} onChange={(e) => handleChange("name", e.target.value)} id="name" name="name" type="text" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("phone")}
            <input value={eventToEdit?.phone} onChange={(e) => handleChange("phone", e.target.value)} id="phone" name="phone" type="tel" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("Email")}
            <input value={eventToEdit?.email} disabled={eventToEdit} onChange={(e) => handleChange("email", e.target.value)} required id="email" name="email" type="email" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("Company")}
            <input value={eventToEdit?.company} onChange={(e) => handleChange("company", e.target.value)} id="company" name="company" type="text" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("Occasion")}
            <input value={eventToEdit?.occasion} onChange={(e) => handleChange("occasion", e.target.value)} id="occasion" name="occasion" type="text" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("Concept")}
            <input value={eventToEdit?.concept} onChange={(e) => handleChange("concept", e.target.value)} id="concept" name="concept" type="text" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("Hour")}
            <input required value={eventToEdit?.hour} onChange={(e) => handleChange("hour", e.target.value)} id="hour" name="hour" type="time" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("Price")}
            <input value={eventToEdit?.cents} disabled={eventToEdit} step="any" onChange={(e) => handleChange("cents", e.target.value)} id="cents" name="cents" type="number" className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("event_date")}
            <input required value={eventToEdit?.date ? eventToEdit?.date : selectedDate} onChange={(e) => handleSelectDate(e.target.value)} min={new Date().toISOString().split('T')[0]} type="date" id="event_date" name="event_date" style={select_styles} className="w-full input input-bordered font-medium h-8" />
          </label>

          <label>
            {t("created_event_date")}
            <input id="created_event_date" name="created_event_date" type="date" onChange={() => null} value={eventToEdit?.created_at ?new Date(eventToEdit?.created_at).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]} disable={true.toString()} className="w-full input input-bordered font-medium h-8" />
          </label>

          <label style={{
            gridArea: "footer"
          }}>
            {t("detail")}
            <textarea value={eventToEdit?.details} onChange={(e) => handleChange("details", e.target.value)} id="details" name="details" type="text" className="w-full input input-bordered font-medium" />
          </label>

          <button
            type="submit"
            style={{
              color: 'white',
              boxSizing: 'border-box',
              height: '40px',
              backgroundColor: '#5FB894',
              gridColumn: 2
            }}
            className={`ml-auto max-w-min  cursor-pointer rounded-full text-xs font-semibold px-5 flex flex-row items-center justify-center`}
          >
            {eventToEdit ?
              t('confirm').toUpperCase()
              :
              t('save').toUpperCase()
            }
          </button>
        </form>
      </div>
      <div onClick={() => closeModal()} className="z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50"></div>
    </div>
  )
}

export default EventModal