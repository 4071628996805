import { ChevronDownIcon, ChevronLeftIcon, FilterIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardMockup } from '../components';
import '../../../styles/animations.css';
import { STYLES } from './CategoryStyle';


export const BookingPreview = (shift) => {
  const { t } = useTranslation();

  const getSlotHour = (minutes) => {
    const hour = `${getBeautyNumber(Math.trunc(minutes / 60))}:${getBeautyNumber(minutes - (Math.trunc(minutes / 60) * 60))}`
    return hour
  };

  const getBeautyNumber = (number) => {
    return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}
  const slots = []
  for (let i = 0; i <= shift["shift"].slots_quantity; i += 1) {
    slots.push({start_minute: (shift["shift"].start_minute + (shift["shift"].start_hour * 60)) + (shift["shift"].slot_duration_minutes * i)})
  }

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full p-1 pb-[2px] flex items-center gap-1 relative bg-[#404040] '>
        <ChevronLeftIcon width={13} className='flex-shrink-0 mr-auto text-white'/>
        <span className={STYLES.SELECTED_SUPERGROUP}>
          Comida
        </span>
        <span className={STYLES.SUPERGROUP}>Bebida</span>
        <span className={STYLES.SUPERGROUP}>Postre</span>
        <FilterIcon width={13} className='flex-shrink-0 ml-auto opacity-0'/>
      </div>
      <div className='w-full flex-1 flex flex-col relative'>
        <div className='w-full px-2 py-[2px] flex items-center gap-1 relative'>
          <span className={STYLES.EXPANDED_GROUP}>
          Comida rápida
            <ChevronDownIcon width={10} className={`flex-shrink-0 transform rotate-180`}/>
          </span>
          <span className={STYLES.GROUP}>
            Carne y Pescado
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          <span className={STYLES.GROUP}>
            Entrantes y Ensaladas
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          <span className={STYLES.GROUP}>
            Arroces y paellas
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
        </div>
          <div className='w-full px-2 py-[2px] text-[#A0A0A0] flex items-center gap-1'>
          <span className={STYLES.SELECTED_CATEGORY}>
            Hamburguesas
            </span>
            <span className={STYLES.CATEGORY}>Burritos</span>
            <span className={STYLES.CATEGORY}>Perritos calientes</span>
            <span className={STYLES.CATEGORY}>Tacos</span>
          </div>
        <div className='flex-1 flex flex-col gap-2 p-2 relative'>
          <ProductCardMockup allergens description={'long'}/>
          <ProductCardMockup allergens description={'short'}/>
          <ProductCardMockup allergens description={'long'}/>
          <ProductCardMockup allergens description={'short'}/>
          <ProductCardMockup allergens description={'short'}/>
        </div>
        <div className={STYLES.EXTRA_OVERLAY}/>
          <div style={{width: '100%', maxHeight: '95%', bottom: 0, borderRadius: '15px 15px 0px 0px', position: 'absolute', backgroundColor: 'white', paddingBottom: "30px"}}>
            <div style={{fontSize: '8px', width: '100%', textAlign: 'center', fontWeight: 600, margin: '10px 0px'}}>Selecciona un horario</div>
            <div style={{fontSize: '8px', width: '100%', alignSelf: 'center', fontWeight: 600, margin: '10px 0px', display: 'flex', justifyContent: 'center'}}>
              <div style={{border: "1px solid black", width: "70%", height: "15px", borderRadius: "2px", display: 'flex', justifyContent: 'space-between', padding: "0px 5px", lineHeight: "15px", overflow: 'hidden'}}>
                {shift["shift"].name ? shift["shift"].name : " "}
                <ChevronDownIcon width={10} className='flex-shrink-0'/>
              </div>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr", fontSize: '8px', gap: "2px", maxHeight: "300px", justifyContent: 'center', padding: "0px 2px"}}>
                    {slots.map((slot) =>
                    <div key={slot.start_minute} style={{width: "100%", height: "25px", lineHeight: "25px", textAlign: 'center', border: "1px solid #D9D9D9", borderRadius: "5px", backgroundColor: "#FFFFFF", color: "#404040", userSelect: 'none'}}>
                        {getSlotHour(slot.start_minute)}
                    </div>
                    )}
            </div>
          </div>
      </div>
    </div>
  );
};