import { CardWithHeader } from 'components/yumminn/CardWithHeader';
import { DataCard } from 'pages/home/components/overview/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import styled from 'styled-components';

export const RestaurantsOverview = ({data, dates}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const formatPieData = data => ([
    {
      name: 'with_payments',
      value: data?.with_payments || 0,
      fill:'#8050AF',
    },
    {
      name: 'without_payments',
      value: (data?.total - data?.with_payments) || 0,
      fill:'#C7AFDF',
    },
  ]);

  const calcRestaurantsPercent = ({with_payments, total} = {}) => Math.round((with_payments || 0) * 100 / (total || 1));
  
  return (
    <CardWithHeader
      title='restaurants'
      info={'info_period_restaurants'}
      infoOptions={dates}
      color='#D2B0F4'
      className={'flex-1'}
      handleClick={() => history.push('/admin/restaurants')}
    >
      <Container>
        <DataCard
          data={`${data?.with_payments || 0}/${data?.total || 0}`}
          delta={data?.with_payments?.delta}
          label='with_payments'
          size='large'
        />
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart width='100%' height='100%' margin={{top: 0, bottom: 0, right: 0, left: 0}}>
            <Pie
              data={formatPieData(data)}
              startAngle={-270}
              dataKey='value'
              nameKey='name'
              cx='50%'
              cy='50%'
              innerRadius='80%'
              outerRadius='100%'
              paddingAngle={3}
            >
              <Label value={calcRestaurantsPercent(data)} content={CustomLabel}/>
            </Pie>
            <Tooltip
              formatter={(value, name) => [value, t(name)]}
              contentStyle={{
                borderRadius: '6px',
                fontSize: '0.75rem',
                padding: '8px',
                border: 'none',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
              }}
            />
          </PieChart>
        </ResponsiveContainer>
        <DataCard
          data={(data?.payments_per_restaurant?.value)}
          delta={data?.payments_per_restaurant?.delta}
          label='payments_per_restaurant'
          size='large'
        />
      </Container>
    </CardWithHeader>
  );
};

const CustomLabel = ({viewBox: {cx, cy}, value}) => {
  const { t } = useTranslation();

  return (
    <g>
      <text
        x={cx}
        y={cy + 6}
        fontSize='32'
        fontWeight='600'
        textAnchor='middle'
        fill='#404040'
      >
        {value + '%'}
      </text>
      <text
        x={cx}
        y={cy + 24}
        fontSize='12'
        fontWeight='600'
        textAnchor='middle'
        fill='#969696'
      >
        {t('with_payments')}
      </text>
    </g>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(116px, 1fr));
  grid-auto-rows: 116px;
  width: 100%;
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);
  
  div:nth-child(2) {
    z-index: 2;
  }
  
  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(116px, 1fr));
    
    div:nth-child(2) {
      grid-column: span 2;
      order: -1;
    }
  }
`;