import React, { useEffect, useRef, useState } from "react";
import { dashboardData } from "logic/api";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { LineChartCard, UsersWithPayments } from "../components";
import { AdminOverview } from "../components";
import {
  formatDashboardData,
  formatOverviewData,
  formatUsersBarChartData,
} from "../constants/dashboardFunctions";
import BigSpinner from "common/BigSpinner";
import moment from "moment";
import { cloneDeep } from "lodash";
import { Context } from "data/authContext";
import { useIsMounted } from "hooks/useIsMounted";
import { format } from 'date-fns';

export const calculatePercentageDifference = (value1, value2) => {
  let percentageDifference = parseFloat(
    ((value1 - value2) / ((value1 + value2) / 2)) * 100
  ).toFixed(1);
  if (value1 === 0) percentageDifference = -100;
  if (value2 === 0) percentageDifference = 100;
  return percentageDifference;
};

const Dashboard = () => {
	const {
		state: { selectedRestaurant, selectedDate, selectedDateTo },
	} = React.useContext(Context);
  
  const isMountedRef = useIsMounted();
  const timeoutRef = useRef(null);

  const [data, setData] = useState(null);
  const [chartsData, setChartsData] = useState(null);
  const [overviewData, setOverviewData] = useState({});
  const [usersBarChartData, setUsersBarChartData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [numberOfComputedElements, setNumberOfComputedElements] = useState(0);
  const [numberOfElementsToCompute, setNumberOfElementsToCompute] = useState(0);
  const [retryCount, setRetryCount] = useState(0);

  const isRowBased = useMediaQuery("(min-width: 1014px)");
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const dates = {from_date: selectedDate, to_date: selectedDateTo};

  const styles = {
    graphs: () => ({
      display: isRowBased ? "grid" : "flex",
      flexDirection: "column",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(auto-fit, auto)",
      alignItems: "start",
      gap: "1.25rem",
      width: "100%",
      maxWidth: "80rem",
      paddingBottom: "32px",
      margin: "auto",
    }),
  };

  const getInterval = () => {
    const fromDate = moment(selectedDate);
    const toDate = moment(selectedDateTo);
    const timeDiff = toDate.diff(fromDate, "days") + 1; // first day included
    return timeDiff <= 1 ? "hours" : "days";
  };

  const centsToEuros = (x) => Math.round(Number(x) / 100); // must be INTEGER

  const getDataThen = (response, recursive, resolve, reject) => {

    if (response.status === 200 && response.data) {
      if (recursive && (
        ("retry" in response.data && response.data["retry"]) ||
        ("period" in response.data && "retry" in response.data.period && response.data.period["retry"])
      )) {
        const responseData = response.data;
        if ("number_of_computed_elements" in responseData) {
          setNumberOfComputedElements(responseData["number_of_computed_elements"]);
        }
        if ("number_of_elements_to_compute" in responseData) {
          setNumberOfElementsToCompute(responseData["number_of_elements_to_compute"]);
        }

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          setRetryCount(prevCount => prevCount + 1);
          recursivelyRetryGetData(resolve, reject);
        }, 1000);
      } else {
        setSpinner(false);
        setData(response.data);
        setOverviewData(formatOverviewData(response.data));
        setChartsData(formatDashboardData(response.data.period, getInterval()));
        setUsersBarChartData(formatUsersBarChartData(response.data.period, getInterval()));
        if (recursive) {
          resolve(response);
        }
      }
    } else {
      setSpinner(false);
    }
  };

  const recursivelyRetryGetData = (resolve, reject) => {

    if (!isMountedRef.current) return;

    const promise = getDashBoardData('dashboard');
    if (promise) {
      promise
        .then(response => getDataThen(response, true, resolve, reject))
        .catch(err => console.log(err));
    }
  };

  const getData = () => {
    clearTimeout(timeoutRef.current);
    setSpinner(true);
    return new Promise((resolve, reject) => {
      recursivelyRetryGetData(resolve, reject);
    });
  };

  const getDashBoardData = (endpoint) => {
    try {
      const restaurants = selectedRestaurant?.length ? selectedRestaurant.map((item) => item.value) : [0];

      const from = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      const to = format(selectedDateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");   
      const adminType = user.is_superuser ? 'superadmin' : 'admin';
      const type = getInterval();
      const fullEndpoint = `/${endpoint}?date_from=${from}&date_to=${to}&restaurants=${restaurants}&type=${type}&admin_type=${adminType}&number_of_retries=${retryCount}`;
      return dashboardData(fullEndpoint);
    } catch (error) {
      console.log(error);
    }
  };

  //console.log('Computed:', numberOfComputedElements, 'To Compute:', numberOfElementsToCompute);

  const updateChartData = async (chart, interval) => {
    const formattedData = {
      ...formatDashboardData(cloneDeep(data.period), interval),
    };

    // setUsersBarChartData([
    //   ...formatUsersBarChartData(cloneDeep(data.period), interval),
    // ]);

    if (chart === "cashback") {
      setChartsData((prev) => ({
        ...prev,
        cashbackGranted: formattedData.cashbackGranted,
        cashbackRedeemed: formattedData.cashbackRedeemed,
      }));

      return;
    }

    setChartsData((prev) => ({ ...prev, [chart]: formattedData[chart] }));
  };

  const isHourlyChart = () =>{
    const interval = getInterval()
    if (interval === 'hours'){
      return true
    }
    return false
  }


  useEffect(() => {
    if (selectedRestaurant && selectedRestaurant.length) {
      setNumberOfComputedElements(0);
      setNumberOfElementsToCompute(0);
      setRetryCount(0)
      getData();
    }

    return () => clearTimeout(timeoutRef.current);
  }, [selectedRestaurant, selectedDate, selectedDateTo]);

  return (
    <>
      {spinner && (
        <div
          style={{
            position: 'absolute',
            top: 'calc(-3.5rem - 97px)',
            left: '0',
            width: '100%',
            height: 'calc(100% + 3.5rem + 97px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            background: '#FFF8',
            zIndex: 999
          }}
        >
          <BigSpinner
            text={numberOfElementsToCompute > 0 ? `${((numberOfComputedElements / numberOfElementsToCompute) * 100).toFixed(2)}%` : ''}
            style={{
              position: 'fixed',
              top: '50%',
            }}
          />
        </div>
      )}
      <AdminOverview
        type={user.is_superuser ? "superadmin" : "admin"}
        data={overviewData}
        dates={dates}
        isLoading={!data || spinner}
      />
      <div
        className={`${(!data || spinner) && "pointer-events-none"}`}
        style={styles.graphs()}
      >
        <LineChartCard
          title={"payments"}
          data={chartsData?.payments}
          period={dates}
          initialInterval={getInterval()}
          updateData={updateChartData}
          unit="€"
          formatter={centsToEuros}
          style={{ height: "100%", width: "100%" }}
          hourly={isHourlyChart()}
        />
        <LineChartCard
          title={"transactions"}
          data={chartsData?.transactions}
          period={dates}
          initialInterval={getInterval()}
          updateData={updateChartData}
          style={{ height: "100%", width: "100%" }}
          hourly={isHourlyChart()}
        />
        <LineChartCard
          title={"tips"}
          data={chartsData?.tips}
          period={dates}
          initialInterval={getInterval()}
          updateData={updateChartData}
          unit="€"
          formatter={centsToEuros}
          style={{ height: "100%", width: "100%" }}
          hourly={isHourlyChart()}
        />
        {(user.is_superuser || user.is_admin) && data && (
          <>
            <LineChartCard
              title={"users"}
              data={chartsData?.users}
              period={dates}
              initialInterval={getInterval()}
              updateData={updateChartData}
              style={{ height: "100%", width: "100%" }}
              hourly={isHourlyChart()}
            />
            <UsersWithPayments info={usersBarChartData} />
            <LineChartCard
              title={"cashback"}
              chartTitles={["granted", "redeemed"]}
              data={[chartsData?.cashbackGranted, chartsData?.cashbackRedeemed]}
              period={dates}
              initialInterval={getInterval()}
              updateData={updateChartData}
              unit="€"
              formatter={centsToEuros}
              style={{ height: "100%", width: "100%", gridColumn: "span 2" }}
              hourly={isHourlyChart()}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
