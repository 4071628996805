import React from 'react';
import { ColumnContainer, RowContainer } from '../ExampleContainers';
import { ToggleSwitch } from 'common/ToggleSwitch';
import { ActiveSwitch } from 'pages/admin/pages/OrderAndPay/ActiveSwitch';
import { ChipWithToggle } from 'common/ChipWithToggle';

const CUSTOM_LABELS = ['custom ON label', 'custom OFF label'];

export const SwitchExample = () => {
  return (
    <ColumnContainer>
      <h2>ToggleSwitch</h2>
      <RowContainer>
        <ToggleSwitch checked/>
        <ToggleSwitch/>
      </RowContainer>
      <RowContainer>
        <ToggleSwitch checked disabled/>
        <ToggleSwitch disabled/>
      </RowContainer>
      <h2>ActiveSwitch</h2>
      <RowContainer>
        <ActiveSwitch checked/>
        <ActiveSwitch/>
      </RowContainer>
      <RowContainer>
        <ActiveSwitch checked disabled disabledInfo='disabledInfo'/>
        <ActiveSwitch disabled disabledInfo='disabledInfo'/>
      </RowContainer>
      <h2>ChipWithToggle</h2>
      <RowContainer>
        <ChipWithToggle label='label' checked/>
        <ChipWithToggle label='label'/>
      </RowContainer>
      <RowContainer>
        <ChipWithToggle label='label' checked onOffLabel={CUSTOM_LABELS}/>
        <ChipWithToggle label='label' onOffLabel={CUSTOM_LABELS}/>
      </RowContainer>
      <RowContainer>
        <ChipWithToggle label='label' checked loading/>
        <ChipWithToggle label='label' loading/>
      </RowContainer>
      <RowContainer>
        <ChipWithToggle label='label' checked disabled/>
        <ChipWithToggle label='label' disabled/>
      </RowContainer>
    </ColumnContainer>
  );
};
