import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSVDownload } from "react-csv";
import { centsToAmountString } from "logic/functions";
import { apiAuth } from "logic/api";
import Spinner from "common/Spinner";
import * as dayjs from "dayjs";
import useReviewFilter from "components/yumminn/useReviewFilter";
import { order } from "tailwindcss/defaultTheme";
import { Context } from "data/authContext";



const CrmTable = () => {
  
  
	const {
		state: { selectedRestaurant },
	} = React.useContext(Context);
  
  const { activo } = useReviewFilter();
  const { t } = useTranslation();
  const [column, setColumn] = useState("payments_count");
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [users,setUsers]=useState([])
  const [page, setPage] = useState(0);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [csvUsers, setCsvUsers] = useState([]);
  const csvInstance = useRef(null);
  const selectedDate = JSON.parse(sessionStorage.getItem("yumminn-selectedDate"));
  const selectedYear = JSON.parse(sessionStorage.getItem("yumminn-selectedYear"))
  const selectedYearTo = JSON.parse(sessionStorage.getItem("yumminn-selectedYearTo"))
  const selectedMonth = JSON.parse(sessionStorage.getItem("yumminn-selectedMonth"))
  const selectedMonthTo = JSON.parse(sessionStorage.getItem("yumminn-selectedMonthTo"))
  const selectedStartTime = sessionStorage.getItem('yumminn-selectedStartTime')
  const selectedEndTime = sessionStorage.getItem('yumminn-selectedEndTime')   
  const date = new Date(selectedDate);
  const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
  const selectedDateTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))
  const endDate = selectedDateTo ? new Date(selectedDateTo) : yesterday;
  const offset = date.getTimezoneOffset();
  const dateEnd = new Date(endDate.getTime() - (offset*60*1000)).toISOString().split('T')[0];
  const dateStart = new Date(date.getTime() - (offset*60*1000)).toISOString().split('T')[0];
  const numUsers = 25;

  let headers = [
    { label: "Name", key: "name" },
    { label: "Surname", key: "surname" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Number of Payments", key: "payments_count" },
    { label: "Registered date", key: "registered_date" },
    { label: "Total Tips", key: "total_tips" },
    { label: "Loyalty generated", key: "loyalty_generated" },
    { label: "Loyalty left", key: "loyalty_left" },
    { label: "Loyalty used", key: "loyalty_used" },
    { label: "Total payments", key: "payments_total" },
  ];


  const getDates=() =>{
    let from_date;
    let to_date;

    const offsetMins = new Date().getTimezoneOffset();
    const date = new Date(new Date().getTime() - offsetMins * 60 * 1000);
    const stringDate = sessionStorage.getItem("yumminn-selectedDate")
      ? sessionStorage.getItem("yumminn-selectedDate").substring(1, 11)
      : date.toISOString().substring(0, 10);

      switch (activo) {
        case 'month':
            const startMonth = selectedMonth ? new Date(selectedMonth).getMonth() : new Date().getMonth()-12;
            const startYearMonth = selectedMonth ? new Date(selectedMonth).getFullYear() : new Date().getFullYear()-1;
            const fromDateMonth = dayjs().set('month', startMonth).set('year', startYearMonth).startOf('month').format('YYYY-MM-DD');
            const endMonth =  selectedMonthTo ? new Date(selectedMonthTo).getMonth() : new Date().getMonth();
            const endYearMonth = selectedMonthTo ? new Date(selectedMonthTo).getFullYear() : new Date().getFullYear();
            const toDateMonth = dayjs().set('month', endMonth).set('year', endYearMonth).endOf('month').format('YYYY-MM-DD')
            from_date = fromDateMonth;
            to_date =  toDateMonth;
            break;

        case 'year':
            const startYear = selectedYear ? new Date(selectedYear).getFullYear() : new Date().getFullYear();
            const fromDateYear= dayjs().startOf('year').set('year', startYear).format('YYYY-MM-DD');
            const endYear =  selectedYearTo ? new Date(selectedYearTo).getFullYear() : new Date().getFullYear();
            const toDateYear = dayjs().endOf('year').set('year', endYear).format('YYYY-MM-DD')
            from_date = fromDateYear;
            to_date = toDateYear;
            break;

        case 'hours':
            const today = new Date(new Date(stringDate));
            let tomorrow = new Date(today);
            tomorrow = new Date(tomorrow.setDate(today.getDate()+1));
            const modifiedDateStart = new Date(today.setHours(2, "00", "00"));
            const modifiedDateEnd = new Date(tomorrow.setHours(1, 59, "00"));
            from_date = selectedStartTime ? modifiedDateStart : modifiedDateStart;
            to_date = selectedEndTime ? modifiedDateEnd : modifiedDateEnd;
            break;     

        default:
            from_date = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().subtract(8, 'day').format('YYYY-MM-DD');
            to_date = dateEnd;
            break;
    }
    return {from_date,to_date};
  }

  const headerStyle = (new_column) => {
    if (column === new_column) {
      return `h-5 w-5 transition ease-in-out delay-150 transform ${
        sort === "ASC" && "rotate-180"
      } text-[#00A106]`;
    }
    return "h-5 w-5 transition ease-in-out delay-150 transform";
  };

  const sortTable = (new_column) => {
    if (column === new_column) {
      if (sort === "ASC") {
        setSort("DESC");
      } else {
        setSort("ASC");
      }
    } else {
      setSort("ASC");
    }
    setColumn(new_column);
  };

  useEffect(()=>{
    if(selectedRestaurant && selectedRestaurant.length > 0){
      getData({allUsers:false})
    }
  },[selectedRestaurant, activo, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, dateStart, dateEnd, selectedDate, selectedDate, selectedStartTime, selectedEndTime, page, order, sort, column])


  const getAllUsers = () => {
    getData({allUsers:true})
  }


  const getData= async({allUsers})=>{
    let obj = {}
    if (allUsers){
      obj = {
        from: getDates().from_date, 
        to: getDates().to_date,
        period: activo,
        column: column,
        order: sort,
        page: page,
        restaurant: selectedRestaurant[0].value,
      };
    }else{
      obj = {
        from: getDates().from_date, 
        to: getDates().to_date,
        period: activo,
        column: column,
        order: sort,
        page: page,
        restaurant: selectedRestaurant[0].value,
        users: numUsers
      }; 
    }
    if(!loadingUsers){
      setLoadingUsers(true)
      try{
        const results = await apiAuth.post(`/payments/crm`, obj);
        

        if (!allUsers) {
          setUsers(results.data)
        } else {
          return new Promise((resolve) => {
            let data = [];

            let users = results.data.users;

            if (users) {
              users.forEach((item) => {
                let payments = [];
                payments["name"] =item.name?item.name:""
                payments["surname"] = item.surname?item.surname:''
                payments["email"] = item.email?item.email:''
                payments["payments_count"] = item.payments_count
                payments["payments_total"] = parseInt(centsToAmountString(item.payments_total)) + "€";
                payments["total_tips"] = item.total_tips
                payments["phone"] = item.phone
                payments["registered_date"] =item.registered_date
                payments["loyalty_granted"] =item.loyalty_granted?item.loyalty_granted:''
                payments["loyalty_left"] =item.loyalty_left?item.loyalty_left:''
                payments["loyalty_used"] =item.loyalty_used?item.loyalty_used:''
                
                let restaurant = {
                  ...payments,
                };
                data.push(restaurant);
              });
            }
            setLoadingCsv(false);
            setLoadingUsers(false); 
            setCsvUsers(data);
            resolve();
          });
        }
      } catch (error) {
        setLoadingUsers(false)
        setLoadingCsv(false);
        console.log(`error`, error);
      }
    }
    setLoadingUsers(false)
  }

  useEffect(()=>{},[])

  return (
    <>
      {loadingUsers && (
        <div className="h-full w-full absolute z-9999 flex flex-col justify-center items-center">
          <Spinner>{t("Loading")}</Spinner>
        </div>
      )}
      <div className="flex flex-col w-full h-full">
        <div className="p-5 flex-auto overflow-auto w-full flex-grow">
          <table
            className={
              loadingUsers
                ? "bg-white rounded-lg p-2 w-full h-full border-separate  overflow-hidden opacity-25"
                : "bg-white rounded-lg p-2 w-full h-full border-separate  overflow-hidden"
            }
          >
            <thead className="text-xs sticky top-0 m-0 bg-white border-b-2 border-gray-200">
              <tr className="h-16 m-0 bg-white ">           
                
                <th
                  className="px-3 w-1/7 text-center items-center "
                >
                  <div  className="flex flex-row items-center justify-center whitespace-nowrap">
                    {t('name')}
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                >
                  <div  className="flex flex-row items-center justify-center whitespace-nowrap">
                    {t('surname')}
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                >
                  <div  className="flex flex-row items-center justify-center whitespace-nowrap">
                    {t('Email')}
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                >
                  <div  className="flex flex-row items-center justify-center whitespace-nowrap">
                    {t('phone')}
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable('payments_count')}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                    <div className="flex flex-row items-center cursor-pointer ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={headerStyle("payments_count")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill="evenodd"
                          d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      #{t('Payments')}
                    </div>
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                  // onClick={() => sortTable('registered_date')}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                  {/* <div className="flex flex-row items-center cursor-pointer ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={headerStyle("registered_date")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill="evenodd"
                          d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg> */}
                    {t('date register')}
                    {/* </div> */}
                  </div>
                </th> 
                {/* <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable(date)}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                    {t('last visited')}
                  </div>
                </th> */}
                <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable('total_tips')}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                    <div className="flex flex-row items-center cursor-pointer ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={headerStyle("total_tips")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill="evenodd"
                          d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {t('accumulated tips')}
                    </div>
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable('loyalty_granted')}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                    <div className="flex flex-row items-center cursor-pointer ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={headerStyle("loyalty_granted")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill="evenodd"
                          d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {t('generated loyalty')}
                    </div>
                  </div>
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable('loyalty_used')}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                    <div className="flex flex-row items-center cursor-pointer ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={headerStyle("loyalty_used")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill="evenodd"
                          d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {t('used loyalty')}
                    </div>
                  </div>                 
                </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable('loyalty_left')}
                  >
                    <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                      <div className="flex flex-row items-center cursor-pointer ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={headerStyle("loyalty_left")}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill="evenodd"
                            d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {t('loyalty left')}
                      </div>
                    </div>                 
                  </th>
                <th
                  className="px-3 w-1/7 text-center items-center"
                  onClick={() => sortTable('payments_total')}
                >
                  <div  className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap">
                    <div className="flex flex-row items-center cursor-pointer ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={headerStyle("payments_total")}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill="evenodd"
                          d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {t('total').toUpperCase()}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            {/*restaurants*/}
            <tbody className="text-sm align-top">
              {users.users &&
                users.users.map((item, index) => {
                  return (                        
                    <tr key={index} className=" overflow-hidden bg-white rounded-md w-full ">
                      <td className="py-3 whitespace-nowrap text-start">
                        {item['name']}
                      </td>
                      <td className="py-3 text-start items-center">
                        {item['surname']}
                      </td>
                      <td className="py-3 text-start">
                        {item['email']}
                      </td>
                      <td className="py-3 text-center">
                        {item['phone']}
                      </td>
                      <td className="py-3 text-center">
                        {item['payments_count']}
                      </td>
                      <td className="py-3 text-center">
                        {new Date(item['registered_date']).toLocaleDateString()}
                      </td>
                      {/* <td className="bg-white z-0 w-1/3 px-4 py-3 py-3 text-center break-words overflow-hidden">
                        {new Date(item['last_visit_date']).toLocaleDateString()}
                      </td> */}
                      <td className="py-3 text-center">
                        {centsToAmountString(item['total_tips']) + "€"}
                      </td>
                      <td className="py-3 text-center">
                        {centsToAmountString(item['loyalty_granted']) + "€"}
                      </td>
                      <td className="py-3 text-center">
                        {centsToAmountString(item['loyalty_used']) + "€"}
                      </td>
                      <td className="py-3 text-center">
                        {centsToAmountString(item['loyalty_left']) + "€"}
                      </td>
                      <td className="py-3 text-center">
                        {centsToAmountString(item['payments_total']) + "€"}
                      </td>
                    </tr>
                  );
                })}
                {(users.length===0 || users.users.length===0) &&
                  <tr className="w-full bg-white items-center relative">
                    <td colSpan="11" className="text-center left-1/2 top-1/2 absolute">No hay usuarios en este periodo</td>
                  </tr>
                }
            </tbody>
          </table>
        </div>
        {users && (
          <div className="flex-auto px-[32px] flex sm:flex-row flex-col lg:text-lg text-xs items-center justify-center w-full text-right flex-grow-0">
            <div className="flex justify-center col-span-4 w-full space-x-4 mt-8 border-top border-grey-500 pb-7">
              <button
                className="disabled:text-gray-200"
                disabled={page === 0}
                onClick={() => {
                  if (page !== 0) {
                    setPage(page - 1);
                  }
                }}
              >
                {t("Previous")}
              </button>
              <div>
                [Total registros: {users.total_users}
              </div>
              <div> /</div>
              <div>
                Página {page+1} de {users.total_pages?users.total_pages+1:1}]
              </div>
              <button
                className="disabled:text-gray-200"
                disabled={page === users.total_pages}
                onClick={() => {
                  if (page !== users.total_pages) {
                    setPage(page + 1);
                  }
                }}
              >
                {t("Next")}
              </button>
            </div>
            <div
              onClick={getAllUsers}
              className="rounded-full bg-[#5FB894] text-white text-base font-semibold sm:w-64 w-full p-5 h-10 flex flex-row items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              {loadingCsv ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              ) : (
                t("Export_Csv")
              )}
            </div>
            {csvUsers.length > 0 && !loadingCsv && (
              <CSVDownload
                data={csvUsers}
                headers={headers}
                separator={";"}
                ref={csvInstance}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CrmTable