import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer } from './InputContainers';

export const TextArea = ({
  label,
  error,
  className = '',
  ...inputProps
}) => {
  const { t } = useTranslation();

  return (
    <InputContainer className={className}>
      <label>
        {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
        <textarea
          {...inputProps}
          placeholder={t(inputProps.placeholder || '')}
          rows={inputProps.rows || 3}
        />
      </label>
      {error &&
        <i className='error'>{t(error)}</i>
      }
    </InputContainer>
  );
};