import React from 'react';
import { ColumnContainer, RowContainer } from '../ExampleContainers';
import { InfoTooltip } from 'common/InfoTooltip';
import styled from 'styled-components';
import { Tooltip } from 'common/Tooltip';
import { Button, BUTTON_SIZES } from 'common/Button';

export const TooltipExample = () => {
  return (
    <ColumnContainer>
      <h2>Tooltip</h2>
      <p>Hover 👇🏻</p>
      <RowContainer>
        <span>
          Right
          <Tooltip>Right</Tooltip>
        </span>
        <span>
          Top
          <Tooltip position='top'>Top</Tooltip>
        </span>
        <span>
          Left
          <Tooltip position='left'>Left</Tooltip>
        </span>
        <span>
          Bottom
          <Tooltip position='bottom'>Bottom</Tooltip>
        </span>
        <span>
          Interactive
          <Tooltip position='bottom' bgColor='#FFFFFF' interactive>
            <Button label='button' size={BUTTON_SIZES.SM}/>
          </Tooltip>
        </span>
      </RowContainer>
      <h2>InfoTooltip</h2>
      <p>Hover 👇🏻</p>
      <RowContainer>
        <InfoTooltip info='Right'/>
        <InfoTooltip info='Top' position='top'/>
        <InfoTooltip info='Left' position='left'/>
        <InfoTooltip info='Bottom' position='bottom'/>
        <InfoTooltip position='bottom' bgColor='#FFFFFF' interactive>
          <TooltipContent>
            <h4>Title</h4>
            <p>description</p>
            <img src='https://picsum.photos/100' alt=''/>
          </TooltipContent>
        </InfoTooltip>
      </RowContainer>
    </ColumnContainer>
  );
};

const TooltipContent = styled.div`
  h4 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    font-size: 0.875rem;
    margin: 0.5em 0;
  }
`;