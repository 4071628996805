import React from 'react';
import { CardWithHeader } from 'components/yumminn/CardWithHeader';
import { DataCard } from './DataCard';
import { useHistory } from 'react-router-dom';
import { abbreviateBigNumbers } from 'logic/functions';
import styled from 'styled-components';

export const LoyaltyOverview = ({data, dates, type}) => {
  const history = useHistory();

  const abbreviateAmount = (num = 0) => abbreviateBigNumbers(num);
  const abbreviate = (num = 0) => abbreviateBigNumbers(num, {rounded: true});
  
  return (
    <CardWithHeader
      title='loyalty'
      info='info_period_loyalty'
      infoOptions={dates}
      color='#FFEDBA'
      className={`flex-auto ${type != 'admin' ? 'max-w-md' : ''}`}
      handleClick={() => history.push('/loyalty')}
    >
      <Container type={type}>
        {type === 'superadmin' && 
          <DataCard
            data={`${data?.restaurants.with_cashback || 0}/${data?.restaurants.total || 0}`}
            delta={data?.restaurants.delta}
            label='restaurants_with_cashback'
            bgColor='#F2EBFA'
            className='h-24 w-48 '
          />
        }
        <DataCard
          data={`${abbreviateAmount(data?.cashback_granted.value)}€`}
          delta={data?.cashback_granted.delta}
          label='cashback_granted'
          bgColor='#FFF3D4'
          className='h-24 w-48 '
        />
        <DataCard
          data={`${abbreviateAmount(data?.avg_cashback_per_user.value)}€`}
          delta={data?.avg_cashback_per_user.delta}
          label='avg_cashback_per_user'
          className='h-24 w-48 '
        />
        <DataCard
          data={`${abbreviateAmount(data?.cashback_redeemed.value)}€`}
          delta={data?.cashback_redeemed.delta}
          label='cashback_redeemed'
          bgColor='#FFE4DE'
          className='h-24 w-48 '
        />

        {type === 'superadmin' &&
          <>
            <DataCard
              data={abbreviate(data?.points_granted.value, true)}
              delta={data?.points_granted.delta}
              label='points_granted'
              bgColor='#EFF8F4'
              className='h-24 w-48 '
            />
            <DataCard
              data={abbreviate(data?.avg_points_per_user.value, true)}
              delta={data?.avg_points_per_user.delta}
              label='avg_points_per_user'
              className='h-24 w-48 '
            /> 
          </>
          }
          {type === 'admin' &&
            <DataCard
              data={abbreviate(data?.avg_payments_with_cashback.value, true)}
              delta={data?.avg_payments_with_cashback.delta}
              label='avg_payments_with_cashback'
              bgColor='#EFF8F4'
              className='h-24 w-48 '
            />
          }
      </Container>
    </CardWithHeader>
  );
};


const Container = styled.div`
  display: ${({type}) => type === 'admin' ? 'flex' : 'grid'};
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);
`;
