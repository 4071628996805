import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { getTextColor } from 'styles/colors';
import { InformationCircleIcon } from '@heroicons/react/outline';


export const CardWithHeader = ({ title, handleClick, color = '#094553', children, className, style, info, infoOptions }) => {
  const { t } = useTranslation();
  
  const [showTooltip, setShowTooltip] = useState(false);
  
  const textColor = getTextColor(color);

  return (
    <div className={`flex flex-col ${className} relative w-min`} style={style}>
      <header className='min-w-min flex justify-between items-center h-11 border-2 border-[#40404018] rounded-t-2xl overflow-hidden border-b-0 gap-2 py-2 px-4 relative' style={{backgroundColor: color}}>
        <h3 className='flex items-center gap-2 text-lg font-semibold capitalize leading-[1em] whitespace-nowrap' style={{color: textColor}}>
          {t(title)}
          {info &&
            <InformationCircleIcon
              height={'1em'}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />}
        </h3>
        {handleClick &&
          <ArrowRightIcon
            height={20}
            width={20}
            color={color}
            onClick={handleClick}
            className='rounded-full cursor-pointer p-[3px]'
            style={{backgroundColor: textColor}}
          />
        }
      </header>
      {info &&
        <div className={`absolute top-12 left-1 max-w-[13rem] w-max text-xs shadow right-0 p-2 bg-white rounded-md z-10 transition-opacity ${showTooltip ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          {t(info, {...infoOptions})}
        </div>
      }
      <div className='flex-1 flex-row bg-white border-2 border-gray-200 border-t-0 rounded-b-2xl'>
        {children}
      </div>
    </div>
  );
};
