import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'common/Popup';
import { TrashIcon } from '@heroicons/react/outline';
import { DeleteEventContainer } from './EventsContainers';
import { Button, BUTTON_VARIANTS } from 'common/Button';

export const DeleteEventPopup = ({isOpen, confirm, cancel, type}) => {
    const { t } = useTranslation();
  
    return (
      <Popup isOpen={isOpen}>
       <DeleteEventContainer>
            <TrashIcon 
                className={`w-[40px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />
            <h3>{type === 'EVENT' ? t('delete_event') : t('delete_ticket')}</h3>
            <p>
                {type === 'EVENT' ? t('delete_event_question') : t('delete_ticket_question')}
            </p>
            <footer>
                <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    label={t('no, cancel')}
                    onClick={cancel}
                />
                <Button
                    label={t('yes_delete')}
                    onClick={confirm}
                />
            </footer>
        </DeleteEventContainer>
      </Popup>
    );
  };