import React from 'react';
import { categories_select_styles } from 'logic/defaults';
import Select, { components } from 'react-select';
import { useTranslation } from "react-i18next";

const StatusSelect = ({selectedStates, setSelectedStates}) => {

	const { t } = useTranslation();
	const state_options = [
		{ value: 'received', 				  label: t('received') },
		{ value: 'ready_to_pick_up', 		  label: t('ready_to_pick_up') },
		{ value: 'error_sending_info_to_pos', label: t('POS error') },
		{ value: 'closed', 				      label: t('Finalized') }
	];

	const Option = (props) => {
		return (
			<div>
			<components.Option {...props}>
			<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
			<label>{props.label}</label>
				<input
				className="checkboxRestaurantSelect"
				type="checkbox"
				checked={selectedStates?.find((s_s) => s_s === props.value)}
				onChange={() => null()}
				/>{" "}
				<span></span>
				</div>
			</components.Option>
			</div>
		);
	};

	const handleChangeOption = (value) => {
		const states_to_set = value.map(st => st.value)
		setSelectedStates(states_to_set)
	}
	return (
		<div>
			<div style={{fontSize: "16px", fontWeight: 500}}>Filtrar por estado</div>
			<Select
				isMulti={true}
				options={state_options}
				styles={categories_select_styles}
				className={'w-60'}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				placeholder={t("Todos")}
				onChange={(e) => {
					handleChangeOption(e)
				}}
				isClearable={false}
				components={{
					Option
				}}
				/>
		</div>
	);
};
export default StatusSelect;
