import React, { useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { Context as AuthContext } from '../../data/authContext';
import AgentsRestaurantsFilter from './AgentsRestaurantsFilter';

const AgentsFilters = () => {

  const {t} = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const {
    state: {
      selectedAgentsPeriod,
      selectedAgentsInterval
    },
    setSelectedAgentsPeriod,
    setSelectedAgentsInterval
  } = React.useContext(AuthContext);
  const defaultPeriod = 'minutes';
  const defaultInterval = 2;
  const maxInterval = 9;

  useEffect(() => {
    let agentsPeriod = defaultPeriod;
    let agentsInterval = defaultInterval;
    try {
      agentsPeriod = localStorage.getItem('yumminn-selectedAgentsPeriod');
      agentsPeriod = agentsPeriod ? agentsPeriod : defaultPeriod;
      agentsInterval = localStorage.getItem('yumminn-selectedAgentsInterval');
      agentsInterval = agentsInterval ? agentsInterval : defaultInterval;
      console.log(agentsPeriod, "agentsPeriod");
      console.log(agentsInterval, "agentsInterval");
    } catch(e) {
      console.log(e.toString(), "<- localStorage.getItem exception (AgentsFilters.js)")
    }
    setSelectedAgentsPeriod(agentsPeriod);
    setSelectedAgentsInterval(agentsInterval);
  }, []);

  const handleIntervalChange = event => {
    const newInterval = event.target.value;
    setSelectedAgentsInterval(newInterval);
  };

  if (selectedAgentsPeriod == null || selectedAgentsInterval == null) {
    return <div />
  }

  return (
    <div className={isRowBased ? 'flex justify-center' : 'flex bg-white justify-around p-1 border-primary border-secundario rounded-md'} style={{flex: "1"}}>
      <AgentsRestaurantsFilter />
      <div style={{flex: "1"}} />
      {/*<p style={{alignSelf: 'center', marginRight: 8}}>{t('Candlestick interval') + ' (' + t('maximum') + ' ' + maxInterval.toString() + '):'}</p>
      <input className='yumminn-input'
             type="number"
             min="2"
             max={maxInterval.toString()}
             style={{width: 100, marginRight: 16, fontSize: "14px", height: 34, alignSelf: "center"}}
             value={selectedAgentsInterval}
             onChange={handleIntervalChange}
      />
      <div className='w-auto' style={{alignSelf: "center"}}>
        <button
          onClick={() => setSelectedAgentsPeriod('minutes')}
          className={`flex z-50 justify-center items-center w-max py-1 px-3 text-[16px] border border-gray rounded-md mx-1
                ${
                  selectedAgentsPeriod === 'minutes' &&
                  'focus:ring-primary border border-primary active:border-primary '
                }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {t('Minutes')}
        </button>
      </div>*/}
      {/*<div className='w-auto' style={{alignSelf: "center"}}>
        <button
          onClick={() => setSelectedAgentsPeriod('hours')}
          className={`flex z-50 justify-center items-center w-max py-1 px-3 text-[16px] border border-gray rounded-md mx-1
                ${
                  selectedAgentsPeriod === 'hours' &&
                  'focus:ring-primary border border-primary active:border-primary '
                }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {t('Hours')}
        </button>
      </div>
      <div className='w-auto' style={{alignSelf: "center"}}>
        <button
          onClick={() => setSelectedAgentsPeriod('days')}
          className={`flex z-40 justify-center items-center w-max py-1 px-3 text-[16px]  border border-gray rounded-md mx-1
                ${
                  selectedAgentsPeriod === 'days' &&
                  'border border-primary focus:ring-primary active:border-primary '
                }
              `}
          style={{outline: 'none', fontSize: isRowBased ? '14px' : '16px'}}
        >
          {t('Days')}
        </button>
      </div>*/}
    </div>
  );
};

export default AgentsFilters;
