// sets the initial configuration for i18n

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { englishTranslationObject } from "./locales/en";
import { spanishTranslatorObject } from "./locales/es";
import dayjs from "dayjs";
import {frenchTranslationObject} from "./locales/fr";

export const language = i18n.language;


export default i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          ...englishTranslationObject, // Translation object below
        }
      },
      fr: {
        translation: {
          ...frenchTranslationObject, // Translation object below
        }
      },
      es: {
        translation: {
          ...spanishTranslatorObject,
        }
      }
    },
    fallbackLng: "es",
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      format: function(value, format, lng) {
        if (value && format === "uppercase") {
          return value.toUpperCase();
        }
        if (value && format === "lowercase") {
          return value.toLowerCase();
        }
        if (value && format === "capitalize") {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }
        // moment corresponds to a function that moment.js gives us. consult if it is necessary
        if (value && value instanceof Date) {
          return dayjs(value).format(format);
        }
        return value;
      }
    }
  });

// triggers when the language is changed.
i18n.on("languageChanged", () => {
});
