import { calculateEuros } from "services/payments";
import { STATUS_KEYS } from "./constants";

const lang = sessionStorage.getItem('i18nextLng') || 'es-ES';

const dateFromat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const getPurchaseData = purchase => ({
  status: STATUS_KEYS[purchase.tickets.every(ticket => ticket.is_validated) ? 'validated' : purchase.status],
  date: new Date(purchase.date).toLocaleString(lang, dateFromat),
  total: calculateEuros(purchase.total_cents),
});

export const groupTicketsByEvent = tickets => tickets.reduce((obj, ticket) => {
  const ticketData = {code: ticket.code, uuid: ticket.uuid};

  const eventAndTicket = `${ticket.event_name}: ${ticket.name}`;

  if (eventAndTicket in obj) {
    obj[eventAndTicket].push(ticketData);
  } else {
    obj[eventAndTicket] = [ticketData];
  }

  return obj;
}, {});