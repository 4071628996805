import React from 'react'
import { MailIcon, PhoneIcon, UserIcon, CalendarIcon, ClockIcon } from '@heroicons/react/outline';

const fillWithLeftZero = (value) => {
  const valueString = value.toString();
  return valueString.length > 1 ? valueString : "0" + valueString;
};

export const BookingRevision = ({bookingDraft}) => {  
  const booking_date = new Date(bookingDraft.date)

  const formatted_booking_date = booking_date.toLocaleString("es-ES", {
    weekday: 'long',
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
  return (
    <div style={{display: 'flex', userSelect: 'none', flexDirection: 'column', width: "100%", alignItems: 'center', justifyContent: 'center', padding: "30px"}}>          
    <div style={{width: '100%'}}>
      <p style={{fontWeight: 600, fontSize: "16px"}}>Verifica los detalles de la reserva</p>
    </div>
    <div style={{width: '100%', border: "1px solid #D0D0D0", marginTop: "15px", padding: "20px", borderRadius: "10px", display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <p><span style={{textDecoration: 'underline'}}>A nombre de:</span> <span style={{fontWeight: 600}}>{bookingDraft.name}</span></p>
      <div style={{display: 'grid', gap: "25px", marginTop: "15px", gridTemplateColumns: "1fr 1fr 1fr"}}>
        <div style={{display: 'flex', gap: "5px", alignItems: 'center'}}>
          <MailIcon height={25}/>
          <p>{bookingDraft.email}</p>
        </div>
        <div style={{display: 'flex', gap: "5px", alignItems: 'center'}}>
          <PhoneIcon height={25}/>
          <p>+{bookingDraft.phone}</p>
        </div>
        <div style={{display: 'flex', gap: "5px", alignItems: 'center'}}>
          <UserIcon height={25}/>
          <p>{bookingDraft.name}</p>
        </div>
        <div style={{display: 'flex', gap: "5px", alignItems: 'center'}}>
          <CalendarIcon height={25}/>
          <p style={{textTransform: 'capitalize'}}>{formatted_booking_date}</p>
        </div>
        <div style={{display: 'flex', gap: "5px", alignItems: 'center'}}>
          <ClockIcon height={25}/>
          <p>{fillWithLeftZero(bookingDraft.hour)}:{fillWithLeftZero(bookingDraft.minute)}h</p>
        </div>
      </div>
    </div>
    <div style={{width: '100%', marginTop: "15px"}}>
      <p style={{fontSize: "14px"}}><span style={{backgroundColor: "#E7FBF3", padding: "2px 10px"}}>✉️  Al confirmar, <b>enviaremos un correo con los detalles al cliente.</b></span></p>
    </div>
  </div>
  )
}
