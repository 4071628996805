import React from 'react'

const EmptyState = ({text = "", icon}) => {
    const location = window.location.pathname
    
    return (
        <div className={`w-full flex flex-col items-center justify-center mt-14 ${location === "/admin/order-and-pay" ? '' : 'pb-6'}`}>
            {icon}
            <p
                style={{textAlign: "center"}}
                className={`${location === "/admin/orders" || location === '/discounts' ? 'font-semibold' : 'font-normal'} text-[18px] p-3 ${location === "/admin/product-highlight" || location === "/admin/orders" ? 'w-50' : location === "/admin/order-and-pay" ? 'w-44' : ''} text-center leading-5`}>
                {text}
            </p>
        </div>
    )
}

export default EmptyState;
