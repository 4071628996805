import { allergensIcons, allergensList } from 'data/allergens';
import { TAG_LIST, tagsIcons } from 'data/tags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const MockAllergensAndTags = ({product, showLabel = false}) => {
  const { t } = useTranslation();

  return (
    <List className={showLabel ? '' : 'truncate'}>
      {product?.allergens?.map(id => {
        const allergen = allergensList.find(a => a.value === id);

        if (!allergen) return <React.Fragment key={'allergen_' + id}/>;

        return (
          <li key={'allergen_' + id}>
            <span style={{backgroundColor: allergen.color + '40'}}>
              {allergensIcons(allergen.label)}
            </span>
            {showLabel &&
              t(allergen.label)
            }
          </li>
        );
      })}
      {product?.tags?.map(id => {
        const tag = TAG_LIST.find(t => t.value === id);

        if (!tag) return <React.Fragment key={'tag_' + id}/>;
        
        return (
          <li key={'tag_' + id}>
            {tagsIcons(tag.label)}
            {showLabel &&
              t(tag.label)
            }
          </li>
        )
      })}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  max-width: 100%;
  
  &.truncate {
    overflow: hidden;
    flex-wrap: nowrap;
  }

  li {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 8px;
    white-space: nowrap;
  }

  span {
    display: flex;
    border-radius: 12px;
    padding: 3px;
    height: fit-content;
    width: fit-content;

    svg {
      width: 12px;
    }
  }

  svg {
    height: 12px;
    width: auto;
  }
`;