import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDataContainer } from './LockerContainers';
import { ProductList } from './ProductList';
import { calculateEuros } from 'services/payments';
import { useCountDown } from 'hooks/useCountDown';
import { MINUTE } from 'logic/defaults';
import { getPickUpTime } from './functions';
import QRCode from 'react-qr-code';

const lang = sessionStorage.getItem('i18nextLng') || 'es-ES';

const recursiveSum = (items) => {
  if (!items || !items.length) return 0;

  return items.reduce((total, item) => {
    total += (item.price_cents * item.quantity);
    
    if (item.modifiers) {
      total += recursiveSum(item.modifiers);
    };

    return total;
  }, 0);
};

export const OrderData = ({order}) => {
  const { t } = useTranslation();
  
  const total_price_cents = useMemo(() => recursiveSum(order.products), [order.id]);

  const endTime = useMemo(() => getPickUpTime(order), [order]);
  
  const { millisecLeft, timer } = useCountDown({ endTime, interval: MINUTE, allowNegativeValues: true });

  return (
    <OrderDataContainer>
      <div className='order'>
        <p>
          {`#${t('order')}:`}
          <b>{order.code}</b>
        </p>
        <p>
          {t('promised_delivery_time')}
          <span>🕑 {(new Date(order.ready_at)).toLocaleTimeString(lang, {timeStyle: 'short'})}</span>
        </p>
        <b className={millisecLeft < 0 ? 'negative' : ''}>
          {timer}
        </b>
      </div>
      <div className='client'>
        {order.client_name &&
          <p data-after={order.client_name}>
            {t('client')}
            <span>{order.client_name}</span>
          </p>
        }
        {order.client_phone &&
          <p data-after={order.client_phone}>
            {t('phone')}
            <span>{order.client_phone}</span>
          </p>
        }
        {order.client_email &&
          <p data-after={order.client_email}>
            {t('mail')}
            <span>{order.client_email}</span>
          </p>
        }
      </div>
      <ProductList products={order.products}/>
      <div className='comment'>
        {`${t('comment')}: `}
        <i>{order.comment || t('no_comments')}</i>
      </div>
      <div className='total'>
        {`${t('total_account')}`}
        <b>{calculateEuros(total_price_cents) + '€'}</b>
      </div>
      {order.bumerang_qr && 
			<div className='total mt-8 flex flex-row justify-between'>
				<div style={{fontSize:"18px", fontWeight:400}}>
					{"QR Bumerang"}
					<p style={{fontSize:"18px", fontWeight:700}}>
						{order.bumerang_qr}
					</p>
				</div>
				<div className="flex flex-row justify-center mt-2">
					<QRCode
					size={180}
					value={order.bumerang_qr}
					/>
				</div>
			</div>
		}
    </OrderDataContainer>
  );
};
