import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { LoyaltyOverview, PaymentsOverview, RestaurantsOverview, ReviewsOverview, TipsOverview, UsersOverview, SimplifiedUsersOverview } from './components';

export const AdminOverview = ({type, data, dates, isLoading}) => {
  const formatDate = (date) => moment(date).format('DD/MM/YYYY');
  const formattedDates = {from: formatDate(dates.from_date), to: formatDate(dates.to_date)};
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const restaurantStatusButtonClicked = () => {
    window.open('https://docs.google.com/spreadsheets/d/1LCz7v6o-r5sRwX6zUzX1XHahsqFkZ1blYsTGveNmJAw/edit#gid=0', '_blank');
  };
  if (type === 'admin') {
    return (
      <div className={`flex flex-col items-start gap-5 self-center px-[2%] my-6 w-full max-w-7xl`}>
        <div className='flex flex-row items-start gap-5 w-full'>
          <PaymentsOverview data={data?.payments} dates={formattedDates}/>
          <TipsOverview data={data?.tips} dates={formattedDates}/>
        </div>
        <div className='flex flex-row items-start gap-5 flex-1 justify-between w-full h-full'>
          <ReviewsOverview data={data?.reviews} dates={formattedDates} type={type}/>
          <SimplifiedUsersOverview data={data?.users?.period} dates={formattedDates} type={type}/>
        </div>
        <LoyaltyOverview data={data?.loyalty} dates={formattedDates} type={type}/>
      </div>
    );
  } else {
    return (
      <div className={`flex flex-wrap items-start gap-5 self-center mx-auto my-6 w-full max-w-7xl`}>
        <div className='flex flex-wrap items-start gap-5 flex-auto w-[58%]'>
          {user.is_superuser && (
            <div style={{display: 'flex', width: '100%', marginBottom: '12px', flexDirection: 'row', justifyContent: 'center'}}>
              <RestaurantsStatusButton disabled={isLoading} onClick={restaurantStatusButtonClicked}>
                Ver status de restaurantes
              </RestaurantsStatusButton>
            </div>
          )}
          <div className='flex flex-row justify-between gap-5  w-full'>
            <PaymentsOverview data={data?.payments} dates={formattedDates}/>
            <TipsOverview data={data?.tips} dates={formattedDates}/>
          </div>
          <ReviewsOverview data={data?.reviews} dates={formattedDates} type={type}/>
        </div>
        <div className='flex flex-wrap items-start gap-5 flex-1'>
          <RestaurantsOverview data={data?.restaurants} dates={formattedDates}/>
          <UsersOverview data={data?.users} dates={formattedDates}/>
          <LoyaltyOverview data={data?.loyalty} dates={formattedDates} type={type}/>
        </div>
      </div>
    );
  }
};

const RestaurantsStatusButton = styled.button`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  background-color: #5BB994;
  border: 2px solid #5BB994;
  border-radius: 999px;
  padding: 4px 12px;

  &:focus {
    outline: none;
  }
`;