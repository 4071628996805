import { BUTTON_COLORS, BUTTON_VARIANTS, Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { Popup } from 'common/Popup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const DeletePopup = ({ title, message, onConfirm, onCancel, ...popupProps}) => {
  const { t } = useTranslation();

  return (
    <StyledPopup {...popupProps} close={onCancel || popupProps.close}>
      <Icon type={IconType.TRASH}/>
      <h3>{t(title)}</h3>
      <p>{t(message)}</p>
      <div>
        {onCancel &&
          <Button
            label='no_cancel'
            onClick={onCancel}
            variant={BUTTON_VARIANTS.SECONDARY}
          />
        }
        <Button
          label={onCancel ? 'yes_delete' : 'understood'}
          onClick={onConfirm}
          color={BUTTON_COLORS[onCancel ? 'DANGER' : 'DEFAULT']}
        />
      </div>
    </StyledPopup>
  );
};

const StyledPopup = styled(Popup)`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    text-align: center;
    max-width: 60ch;

    svg {
      height: 1.8rem;
      width: 1.8rem;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
    }

    p {
      font-size: 1.125rem;
      font-size: 400;
    }

    & > div {
      display: flex;
      gap: 24px;
      width: 100%;

      button {
        &:first-child {
          margin-right: auto;
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
`;