import React, { useState } from 'react'
import { ColumnContainer } from '../ExampleContainers';
import { TabSelector } from 'common/TabSelector';

const OPTIONS = ['one', 'two', 'three', '🐉'];

export const TabSelectorExample = () => {
  const [tab, setTab] = useState('one');

  return (
    <ColumnContainer>
      <h2>TabSelector</h2>
      <h3>With breadcrumbs and progress</h3>
      <TabSelector
        value={tab}
        options={OPTIONS}
        onChange={setTab}
      />
      <TabSelector
        value={tab}
        options={OPTIONS}
        onChange={setTab}
        disabled
      />
      <TabSelector
        value={tab}
        options={OPTIONS}
        onChange={setTab}
        color={'#8F0E19'}
      />
      <h3>Unrelated tabs</h3>
      <TabSelector
        value={tab}
        options={OPTIONS}
        onChange={setTab}
        breadcrumb={false}
      />
    </ColumnContainer>
  )
}
