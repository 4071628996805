import React, { useEffect, useState } from 'react';
import { ObjectTypes, patchObject, postObject, saveProductImage } from '../api-OrderAndPay';
import { TPVS } from '../../DragAndDrop/constants';
import { Popup } from 'common/Popup';
import styled from 'styled-components';
import { ActiveSwitch } from '../ActiveSwitch';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { ModifierSpecs } from './ModifierSpecs';
import { TabSelector } from 'common/TabSelector';
import { AllergensAndTags } from '../AllergensAndTags';
import { useTranslation } from 'react-i18next';
import { CustomProgressBar } from 'common/CustomProgressBar';
import { NUTRITIONAL_FIELDS, NutritionalInfo } from './NutritionalInfo';

const TABS = ["specs", "allergens_and_tags", "nutritional_table"];

const NEW_MOD_DRAFT = { name: "", visibility: 1, allergens: [], tags: [], tpv_price_cents: 0 };

export const EditModifierModal = ({ modifier, tpv, isTspoonlab, closeModal, onSave }) => {
  const { t } = useTranslation();
  
  const [tab, setTab] = useState(TABS[0]);
  const [shouldShowAllergens, setShouldShowAllergens] = useState(!modifier?.id);
  
  const [modifierDraft, setModifierDraft] = useState({
    ...NEW_MOD_DRAFT,
    ...modifier,
    proteins: modifier?.proteins_string, 
    fats: modifier?.fats_string,
    saturated_fats: modifier?.saturated_fats_string,
    carbohydrates: modifier?.carbohydrates_string,
    sugars: modifier?.sugars_string,
    sodium: modifier?.sodium_string,
    serving_size: modifier?.serving_size_string,
    fiber: modifier?.fiber_string,
    allergens: modifier.allergens_ids || [],
    tags: modifier.tags_ids || [],
  });
  
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [loading, setLoading] = useState(false);
  
  const disabledPriceInput = !tpv.includes(TPVS.YUMMINN);
  
  const editModifier = (partialMod) => {
    if (partialMod?.name !== undefined) partialMod.visible_name = partialMod.name;
    setModifierDraft((prev) => ({ ...prev, ...partialMod }));
  };

  const next = () => {
    setTab('allergens_and_tags');
    setShouldShowAllergens(false);
  };

  const save = async () => {
    if (disabledSaveButton) return;

    setLoading(true);

    try {
      const newModifier = {...modifierDraft};

      if ('tpv_price_cents' in newModifier && disabledPriceInput) {
        delete newModifier.tpv_price_cents;
      }

      if (!newModifier.serving_measurement_unit) {
        newModifier.serving_measurement_unit = 'g';
      }
  
      NUTRITIONAL_FIELDS.forEach(field => {
        if (newModifier[field] || newModifier[field] === 0) {
          newModifier[field] = Number(newModifier[field]) || 0;
        } else {
          newModifier[field] = null;
        }
      });

      const method = newModifier.id ? patchObject : postObject;

      await method({ objectType: ObjectTypes.MODIFIER, object: newModifier, t })
        .then(res => {
          if (res?.status === 200) {
            for (const key in newModifier) {
              modifier[key] = newModifier[key];
            }
            modifier.allergens_ids = newModifier.allergens;
            modifier.tags_ids = newModifier.tags;

            if (!newModifier.id) {
              newModifier.id = res.data.id;
              modifier.id = res.data.id;
            }
          }
        });
      
      if ((newModifier.image && newModifier.imageFile) || (!newModifier.image && !newModifier.imageFile)) {
        await saveProductImage({id: modifier.id, file: newModifier.imageFile ? newModifier.imageFile : "", type: 'modifier', t})
          .then(res => {
            if (res?.status === 200) {
              modifier.image = newModifier.image;
            }
          });
      }

      onSave && onSave(newModifier);

      setLoading(false);
      closeModal();
    } catch (error) {
      console.log(`EditModifierModal save error:`, error);
      setLoading(false);
    }
  };
  
  const toggleVisibility = async () => {
    if (loading) return;

    setLoading(true);
    
    try {
      const newVisibility = modifierDraft.visibility ? 0 : 1;

      const object = {
        id: modifier.id,
        category: modifier.category,
        visibility: newVisibility
      };
      
      const res = await patchObject({ objectType: ObjectTypes.MODIFIER, object, t })
      
      if (res.status === 200) {
        modifierDraft.visibility = newVisibility;
        modifier.visibility = newVisibility;
      }
    } catch (error) {
      console.log("🚀 ~ file: EditModifierModal.jsx:65 ~ toggleVisibility ~ error:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setDisabledSaveButton(
      modifierDraft.name === modifier.name &&
      modifierDraft.description === modifier.description &&
      modifierDraft.tpv_price_cents === modifier.tpv_price_cents &&
      modifierDraft.allergens.length === modifier.allergens_ids.length &&
      modifierDraft.allergens.every((allergen, index) => modifier.allergens_ids?.[index] === allergen?.value) &&
      modifierDraft.tags.length === modifier.tags_ids.length &&
      modifierDraft.tags.every((allergen, index) => modifier.tags_ids?.[index] === allergen?.value) &&
      modifierDraft.image === modifier.image &&
      modifierDraft.serving_measurement_unit === modifier.serving_measurement_unit &&
      modifierDraft.ingredients === modifier.ingredients &&
      NUTRITIONAL_FIELDS.every(field => modifierDraft[field] === modifier[field])
    );
  }, [modifierDraft]);

  return (
    <ModifierPopup close={closeModal} isOpen={true}>
      <header>
        <h2>{t('edit') + ' ' + t('Modifier')}</h2>
        <ActiveSwitch
          checked={modifierDraft.visibility !== 0}
          loading={loading}
          onChange={toggleVisibility}
        />
      </header>
      <TabSelector
        name={"edit_group"}
        value={tab}
        options={TABS}
        onChange={setTab}
      />
      {tab === 'specs' &&
        <ModifierSpecs
          modifierDraft={modifierDraft}
          disabledPriceInput={disabledPriceInput}
          loading={loading}
          editModifier={editModifier}
        />
      }
      {tab === "allergens_and_tags" &&
        <AllergensAndTags
          draft={modifierDraft}
          loading={loading}
          editDraft={editModifier}
        />
      }
      {tab === 'nutritional_table' &&
        <NutritionalInfo
          item={modifierDraft}
          isTspoonlab={isTspoonlab}
          editItem={editModifier}
        />
      }
      <footer>
        <Button
          label='cancel'
          disabled={loading}
          onClick={closeModal}
          variant={BUTTON_VARIANTS.SECONDARY}
        />
        <Button
          label={shouldShowAllergens ? 'next' : 'save'}
          disabled={disabledSaveButton || loading}
          onClick={shouldShowAllergens ? next : save}
        />
      </footer>
      <Popup isOpen={!modifier.id && loading} className='saving'>
        <h4>
          {t('creating_modifier_title') + '✨'}
        </h4>
        <CustomProgressBar estimatedSeconds={5}/>
        <i>
          {t('creating_modifier_message') + '🎉'}
        </i>
      </Popup>
    </ModifierPopup>
  );
};

const ModifierPopup = styled(Popup)`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    max-height: 100%;
    
    & > div {
      display: flex;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px; 

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.3;
      margin: 0;
    }
    
    div {
      font-size: var(--font_sm);
    }
  }
  
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 25rem;
  }

  .modifier_config_item {
    width: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
      max-width: 50%;
      font-size: var(--font_sm);
    }
  }

  .modifier_image {
    height: 64px;
    width: 64px;
    object-fit: cover;
    border-radius: 10px;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    border-top: 1.5px solid #DDDDDD;
    padding-top: 24px;
  }

  .saving {
    text-align: center;

    h4 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1em;
    }

    i {
      display: inline-block;
      font-size: 1.125rem;
      margin-top: 1.5rem;
      max-width: 40ch;
      text-wrap: balance;
    }
  }
`;