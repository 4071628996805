import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from "../../../data/authContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { api } from 'logic/api';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MonthSelect from 'components/yumminn/MonthSelect';
import YearSelect from 'components/yumminn/YearSelect';
import { DatesFilter } from "components/yumminn/DatesFilter";
import DateSelect from 'components/yumminn/DateSelect';
import useReviewFilter from 'components/yumminn/useReviewFilter';
import * as dayjs from 'dayjs';
import useRestaurant from "components/yumminn/useRestaurant";

const PerformanceGraphs = () => {

  const { seleccionado } = useRestaurant();
  const { activo } = useReviewFilter();
  const { t } = useTranslation();
  const [data, setData] = React.useState(null);
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const selectedYear = JSON.parse(sessionStorage.getItem("yumminn-selectedYear"))
  const selectedYearTo = JSON.parse(sessionStorage.getItem("yumminn-selectedYearTo"))
  const selectedMonth = JSON.parse(sessionStorage.getItem("yumminn-selectedMonth"))
  const selectedMonthTo = JSON.parse(sessionStorage.getItem("yumminn-selectedMonthTo"))
  const selectedDate = JSON.parse(sessionStorage.getItem('yumminn-selectedDate'))
  const selectedDateTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))
  const selectedStartTime = sessionStorage.getItem('yumminn-selectedStartTime')
  const selectedEndTime = sessionStorage.getItem('yumminn-selectedEndTime')
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const endDate = selectedDateTo ? new Date(selectedDateTo) : yesterday;
  const date = new Date(selectedDate);
  const offset = date.getTimezoneOffset();
  const dateEnd = new Date(endDate.getTime() - (offset * 60 * 1000)).toISOString().split('T')[0];
  const { state: selectedReviewFilter, setSelectedReviewFilter } = React.useContext(AuthContext);

  useEffect(() => {
    if (activo) {
      getData()
    }
  }, [activo,seleccionado, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, selectedDate, selectedDateTo, selectedStartTime, selectedEndTime])

  const getDates = () => {
    let from_date;
    let to_date;

    const offsetMins = new Date().getTimezoneOffset();
    const date = new Date(new Date().getTime() - offsetMins * 60 * 1000);
    const stringDate = sessionStorage.getItem("yumminn-selectedDate")
      ? sessionStorage.getItem("yumminn-selectedDate").substring(1, 11)
      : date.toISOString().substring(0, 10);
    switch (activo) {
      case 'month':
        const startMonth = selectedMonth ? new Date(selectedMonth).getMonth() : new Date().getMonth() - 12;
        const startYearMonth = selectedMonth ? new Date(selectedMonth).getFullYear() : new Date().getFullYear() - 1;
        const fromDateMonth = dayjs().set('month', startMonth).set('year', startYearMonth).startOf('month').format('YYYY-MM-DD');
        const endMonth = selectedMonthTo ? new Date(selectedMonthTo).getMonth() : new Date().getMonth();
        const endYearMonth = selectedMonthTo ? new Date(selectedMonthTo).getFullYear() : new Date().getFullYear();
        const toDateMonth = dayjs().set('month', endMonth).set('year', endYearMonth).endOf('month').format('YYYY-MM-DD')
        from_date = fromDateMonth;
        to_date = toDateMonth;
        break;

      case 'year':
        const startYear = selectedYear ? new Date(selectedYear).getFullYear() : new Date().getFullYear();
        const fromDateYear = dayjs().startOf('year').set('year', startYear).format('YYYY-MM-DD');
        const endYear = selectedYearTo ? new Date(selectedYearTo).getFullYear() : new Date().getFullYear();
        const toDateYear = dayjs().endOf('year').set('year', endYear).format('YYYY-MM-DD')
        from_date = fromDateYear;
        to_date = toDateYear;
        break;

      case 'hours':
        let selectedDate = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().format('YYYY-MM-DD');
        from_date = selectedDate
        to_date = selectedDate
        break;

      default:
        from_date = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().subtract(8, 'day').format('YYYY-MM-DD');
        to_date = dateEnd;
        break;
    }
    return { from_date, to_date };
  }

  const getData = async () => {
    const body = {
      "start":getDates().from_date,
      "end":getDates().to_date,
      "restaurants": seleccionado,
    }
    const res = await api.post(`/performance`,body);
    setData(res.data)
  }

  const CustomTooltip = ({ active, payload, label, restaurant }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${restaurant} : ${payload[0].value}`}</p>
          <p className="intro">{label}</p>
        </div>
      );
    }

    return null;
  };

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  const renderLegend = (props) => {
    const { payload } = props;
    
    return (
      <ul className='pt-5 h-28 overflow-scroll'>
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>{entry.value}</li>
          ))
        }
      </ul>
    );
  }

  const colors = ["#f46d43", "#abdda4", "#e4085b", "#3288bd", "#d53e4f", "#8B0000", "#66c2a5", "#5e4fa2", "#fee08b", "#fdae61"];;

  if (!data) return <div className={`flex-auto flex flex-col items-center w-full bg-gray-100 `} >
    {!isRowBased &&
      <h1 className={`font-bold text-[24px] text-[#094553] px-[0px] right-0 py-5 w-full bg-[#E2FEF1] pl-8`} style={{ zIndex: !isRowBased ? 9999 : '' }}>
        {t('Performance')}
      </h1>}
    <div className={`flex flex-col lg:flex-row justify-center items-center w-full px-12 lg:px-6 rounded-lg pb-3 gap-12 bg-gray-100`}>
      <RestaurantSelect/>
      {period === 'month' ? <MonthSelect /> : period === 'year' ? <YearSelect /> : <DateSelect />}
      <DatesFilter doFilter={setSelectedReviewFilter} />
    </div>
  </div>

  return (
    <div className={`flex-auto flex flex-col items-center w-full bg-gray-100 `} >
      {!isRowBased &&
        <h1 className={`font-bold text-[#094553] text-[24px] px-[0px] right-0 py-5 w-full bg-[#E2FEF1] pl-8`} style={{ zIndex: !isRowBased ? 9999 : '' }}>
          {t('Performance')}
        </h1>}
      <div className={`flex flex-col lg:flex-row justify-center items-center w-full px-12 lg:px-6 rounded-lg pb-3 gap-12 bg-gray-100`}>
        <RestaurantSelect/>
        {period === 'month' ? <MonthSelect /> : period === 'year' ? <YearSelect /> : <DateSelect />}
        <DatesFilter doFilter={setSelectedReviewFilter} />
      </div>
      <div className='p-5 w-full flex flex-col justify-between space-y-5'>
        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }} className={isRowBased ? 'bg-white rounded-md col-span-2 mb-12 w-full' : 'pt-4 w-full pl-4 pr-4 pb-4 bg-white rounded-md col-span-2'}>
          <h1 className='text-lg font-bold p-3'>
            Velocidad promedio de conexión
          </h1>
          <ResponsiveContainer width={"100%"}
            height={600}>
            <LineChart
              width={200}
              height={'400'}
              data={data.latency_graph}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" allowDuplicatedCategory={false} />
              
              <YAxis />
              {/* <YAxis domain={data.y_axis_min_max_values.length > 0 ? [data.y_axis_min_max_values[0], data.y_axis_min_max_values[1]] : [0, 10]} /> */}
              <Tooltip />
              {
                data.restaurants.slice(0,10).map((restaurant, index) => {
                  return <Line key={index} strokeDasharray={"5 " + index} type="monotone" activeDot={{ r: 8 }} strokeWidth={3} stroke={colors[index]} dataKey={restaurant} />
                })
              }
              <Legend content={renderLegend} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }} className={isRowBased ? 'overflow-scroll bg-white rounded-md col-span-2 mb-12 w-full' : 'overflow-scroll pt-4 w-full pl-4 pr-4 pb-4 bg-white rounded-md col-span-2'}>
          <h1 className='text-lg font-bold p-3'>Endpoints más lentos</h1>
          <table className='table w-full '>
            <thead>
              <tr>
                <th>Endpoint</th>
                {/* <th>Os</th> */}
                {/* <th>Network Speed* (Mbsp)</th> */}
                <th>Response time</th>
              </tr>
            </thead>
            {
              data.slowest_endpoints.map((item, index) => {
                return (<tbody key={index} >
                  <tr>
                    <td className="text-sm">{item.endpoint}</td>
                    {/* <td>{item.os_name}</td> */}
                    {/* <td>{item.device_network_speed}</td> */}
                    <td>{item.speed}</td>
                  </tr>
                </tbody>)
            })
          }
          </table>

        </div>
        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }} className={isRowBased ? 'overflow-scroll bg-white rounded-md col-span-2 mb-12 w-full' : 'overflow-scroll pt-4 w-full pl-4 pr-4 pb-4 bg-white rounded-md col-span-2'}>
          <h1 className='text-lg font-bold p-3'>Error logs</h1>
          <table className='table w-full '>
            <thead>
              <tr>
                <th>Url</th>
                <th>File name</th>
                <th>Error</th>
                <th>OS</th>
                <th>Browser</th>
              </tr>
            </thead>
            {
              data.logs.map((item, index) => {
                return (
                  <tbody key={index} >
                    <tr>
                      <th className='text-blue-400 underline'><a href={`https://yumminn-be.herokuapp.com/admin/api/frontendlog/${item.id}/change/`} target="_blank" rel="noreferrer"> {item.url}</a></th>
                      <th>{item.file_name.substring(item.file_name.lastIndexOf('/'))}</th>
                      <th>{item.message}</th>
                      <th>{item.os_name}</th>
                      <th>{item.browser_name}</th>
                    </tr>
                  </tbody>)
              })
            }
          </table>

        </div>
      </div>
    </div>
  )
}

export default PerformanceGraphs