import React from 'react'

export const PaymentCard = ({title, subtitle = "", quantity }) => (
  <div className={"bg-white rounded-lg p-4"}>
    <div className={'border-b flex flex-row justify-start items-baseline space-x-2'}>
      <div className={'py-2 border-b font-bold font-[18px]'}>{title}</div>
      <div className={'py-2 text-[10px]'}>{subtitle}</div>
    </div>
    <div className={'py-4 text-[36px]'}>{quantity} €</div>
  </div>
)
