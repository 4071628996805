import React, { useCallback, useContext } from 'react';
import useSWR from 'swr';
import { baseAuth } from 'logic/api';
import { Context as AuthContext } from 'data/authContext';
import { MINUTE } from 'logic/defaults';

const fetcher = endpoint => baseAuth.get(endpoint).then(res => res.data);

export const useTickets = ({page, event, search}) => {
  const { state: { selectedRestaurantId, selectedDate, selectedDateTo } } = useContext(AuthContext);

  const searchParams = new URLSearchParams ({
    date_from: selectedDate?.toISOString(),
    date_to: selectedDateTo?.toISOString(),
    page,
  });

  if (event) {
    searchParams.append('event', event.value);
  };

  if (search.length) {
    search.forEach(param => searchParams.append(param.attr.replace('#', ''), param.value))
  };

  const { data, isLoading, isValidating, mutate } = useSWR(
    `event/get_purchase_list/${selectedRestaurantId}?${searchParams.toString()}`,
    fetcher,
    {
      refreshInterval: 5 * MINUTE,
      refreshWhenHidden: false,
      revalidateOnFocus: true,
      revalidateOnMount: true,
    },
  );

  const getSuggestions = useCallback(async value => {
    if (!value) return [];

    try {
      const res = await baseAuth.get(
        `event/get_search_suggestions/${selectedRestaurantId}?value=${value}`
      );
  
      return Object.entries(res.data).map(([attr, values]) =>
        values.map(value => ({
          label: `${attr}: ${value}`,
          value,
          attr,
        }))
      ).flat();
    } catch (error) {
      console.log(`🐉 > file: functions.js:41 > getSuggestions > error:`, error);
      return []
    }
  }, [selectedRestaurantId]);

  return {
    eventTickets: data?.event_tickets || [],
    purchaseList: data?.purchase_list || [],
    eventOptions: data?.event_list?.map(event => ({label: event.name, value: event.id})) || [],
    eventsList: data?.event_list,
    ticketsData: data?.tickets_data || {validated: 0, total: 0},
    pages: data?.pages || {num_pages: 0, num_entries: 0},
    isLoading,
    isValidating,
    mutate,
    getSuggestions,
  };
};
