import React, { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../../data/authContext';
import { baseAuth } from "logic/api";
import { useTranslation } from 'react-i18next';
import { ChipWithToggle } from 'common/ChipWithToggle'
import { InfoTooltip } from 'common/InfoTooltip';
import { toast } from 'react-toastify';

export const ToggleRestauratnGroupVisibility = ({ isCatalogLoading, className = ''}) => {
	const { state: { selectedRestaurantId } } = useContext(AuthContext);
  const { t } = useTranslation();
  
	const [restaurantGroupInfo, setRestaurantGroupInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
  
	const getRestaurantGroupRestaurantInfo = async (restaurantId) => {
		if (!selectedRestaurantId) return;

    setIsLoading(true);

    try {
      const response = await baseAuth.get(`/restaurant/restaurant_group?restaurant=${restaurantId}`);
      setRestaurantGroupInfo(response.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false)
	};

	const changeVisibility = async () => {
		if (!selectedRestaurantId || isLoading) return;

		setIsLoading(true);
    
    try {
      await baseAuth.get(`/restaurant/change_restaurant_group_restaurant_visibility?restaurant=${selectedRestaurantId}`);
      await getRestaurantGroupRestaurantInfo(selectedRestaurantId);
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      toast.error(`${t('Error changing visibility')}`);
    }

		setIsLoading(false);
	};

  useEffect(() => {
		getRestaurantGroupRestaurantInfo(selectedRestaurantId);
  }, [selectedRestaurantId]);

  if (!restaurantGroupInfo || !restaurantGroupInfo.is_restaurant_group_restaurant) return <></>;

  return (
    <ChipWithToggle
      name='toggle_restaurant_visibility'
      checked={restaurantGroupInfo.active}
      loading={isLoading}
      disabled={isCatalogLoading}
      onOffLabel={[t('visible'), t('not_visible')]}
      onChange={changeVisibility}
      className={className}
      label={
        <span className='flex items-center gap-1'>
          {t('group')}
          <b className='font-semibold whitespace-nowrap'>
            {restaurantGroupInfo.restaurant_group_name}
          </b>
          <InfoTooltip info='info_restaurant_group_visibility'/>
        </span>
      }
    />
  );
};
