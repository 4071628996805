import React from 'react';
import { useTranslation } from "react-i18next";

const BookingsHeader = ({opacity}) => {

  const { t } = useTranslation();
  return (
    <div className={`${opacity ? 'opacity-20' : ''}`} style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr", textAlign: 'center', width: "100%", backgroundColor: "#FCFCFC", justifyContent: 'space-around', fontSize: "17px", fontWeight: 500, padding: "15px 20px 15px 0px", borderBottom: "2px solid #D1D1D1"}}>
		<div>#{t('Booking')}</div>
		<div>{t('booking_datetime')}</div>
		<div>{t('Client_name')}</div>
		<div style={{textTransform: "capitalize"}}>{t('diners')}</div>
	</div>
  );
};
export default BookingsHeader;
