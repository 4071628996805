import React, { useEffect, useState } from 'react';
import LoyaltyProgramCard from '../LoyaltyProgramCard';
import { apiAuth } from 'logic/api';
import Spinner from 'common/Spinner';
import { Context } from 'data/authContext';

const LoyaltyPrograms = () => {
  
	const {
		state: { selectedRestaurantId },
	} = React.useContext(Context);
  const [restaurantData, setRestaurantData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getRestaurantData = async (id) => {
    setIsLoading(true);

    try {
      const res = await apiAuth.get(`/restaurants/${id}`);
      setRestaurantData(res.data);
    } catch (error) {
      console.log("🚀 ~ file: LoyaltyPrograms.jsx:28 ~ getRestaurantData ~ error:", error)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    selectedRestaurantId && getRestaurantData(selectedRestaurantId);
  }, [selectedRestaurantId]);

  return (
    <div className='py-8'>
      {isLoading &&
        <div className="h-full w-full fixed top-0 left-0 z-[99999999] flex flex-col justify-center items-center bg-[#0004]">
          <Spinner/>
        </div>
      }
      {selectedRestaurantId &&
        <LoyaltyProgramCard
          restaurantData={restaurantData}
          getRestaurantData={getRestaurantData}
        />
      }
    </div>
  );
};

export default LoyaltyPrograms;
