import React from 'react';
import { useTranslation } from "react-i18next";

const OrderClientInfo = ({order}) => {

  const { t } = useTranslation();

  return (
	<div style={{marginTop: "25px", fontSize: "13px", fontWeight: 500, backgroundColor: "#F8F8F8DA", padding: "15px 20px", borderRadius: "10px"}}>
		<div style={{display: 'flex', gap: "20px", justifyContent: 'space-between'}}>
			<div>
				<div>{t("client")}</div>
				<div style={{fontWeight: 600}}>{order.client_name ? order.client_name : '-'}</div>
			</div>
			<div>
				<div>{t("Phone")}</div>
				<div style={{fontWeight: 600}}>{order.client_phone ? order.client_phone : '-'}</div>
			</div>	
		</div>
		<div style={{marginTop: "10px"}}>
			<div>{t("Email")}</div>
			<div style={{fontWeight: 600}}>{order.client_email ? order.client_email : '-'}</div>
		</div>
	</div>
  );
};
export default OrderClientInfo;
