import { useContext, useMemo } from 'react';
import useSWR from 'swr';
import { Context as AuthContext } from 'data/authContext';
import { orderAndPay } from "logic/api";
import { SECOND } from 'logic/defaults';
import { format } from 'date-fns';

export const useRecharges = ({ page, recommendationFilters, setNotifyNew }) => {
  const { state: { selectedRestaurant, selectedDate, selectedDateTo } } = useContext(AuthContext);

  const fetcher = endpoint => orderAndPay.get(endpoint).then(res => res.data);

  const endpoint = useMemo(() => {
    if (!selectedRestaurant || !selectedDate || !selectedDateTo) return null;

    const ids = selectedRestaurant.map(res => res.value);
    const from = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    const to = format(selectedDateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");    
  
    let endpoint = `/list/recharges?date_from=${from}&date_to=${to}&page=${page}&restaurants=[${ids}]`;
  
    const client_names = [];
    const client_phones = [];
    const client_emails = [];
    const recharge_ids = [];
  
    recommendationFilters.forEach(recommendation => {
      if (!recommendation || !recommendation.type || !recommendation.value) return;
  
      const type = recommendation.type;
      const value = recommendation.value;

      if (type === 'client_name') {
        client_names.push(value);
      } else if (type === 'client_phone') {
        client_phones.push(value);
      } else if (type === 'client_email') {
        client_emails.push(value);
      } else if (type === 'recharge_ids') {
        recharge_ids.push(value);
      }
    });

  
    if (client_names.length > 0) {
      endpoint += `&client_names=${JSON.stringify(client_names)}`;
    }
    if (client_phones.length > 0) {
      endpoint += `&client_phones=${JSON.stringify(client_phones)}`;
    }
    if (client_emails.length > 0) {
      endpoint += `&client_emails=${JSON.stringify(client_emails)}`;
    }
    if (recharge_ids.length > 0) {
      endpoint += `&recharge_ids=${JSON.stringify(recharge_ids)}`;
    }

    setNotifyNew(false);
    return endpoint;
  }, [page, recommendationFilters, selectedRestaurant, selectedDate, selectedDateTo]);
  
  const { data, error, mutate } = useSWR(endpoint, fetcher, {
    refreshInterval: 8 * SECOND,
    refreshWhenHidden: true,
  });
  
  const isLoading = !error && !data;

  return { data, isLoading, mutate };
}
