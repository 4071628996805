import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import RestaurantSelect from "../../../components/yumminn/RestaurantSelect";
import Reports from "./BillingComponents/Reports";
import DateRangeSelect from "components/yumminn/DateRangeSelect";
import BillingDetailModal from "./BillingComponents/BillingDetailModal";
import { centsToAmountString } from "logic/functions";
import { FolderDownloadIcon } from '@heroicons/react/outline';
import Payouts from "./BillingComponents/Payouts";
import PayoutDetailModal from "./BillingComponents/PayoutDetailModal";

export const Billing = () => {
	const {
		state: { selectedRestaurantId, selectedDate, selectedDateTo }
	} = React.useContext(AuthContext);
	const { t } = useTranslation();
	const [showPayouts, setShowPayouts] = useState(true);	
	const [loading, setLoading] = useState(false);
	const [billingToSee, setBillingToSee] = useState(null);	
	const [payoutToSee, setPayoutToSee] = useState(null);	
	const [billings, setBillings] = useState([]);	
	const [billingsTotal, setBillingsTotal] = useState(0);	
	const [payoutTotal, setPayoutTotal] = useState(0);	
	const [billingItems, setBillingItems] = useState([]);	
	const [payouts, setPayouts] = useState([]);	
	let startDate = new Date(selectedDate);
	startDate.setHours( 0,0,0,0 );
	startDate = startDate.getTime();
	let endDate = new Date(selectedDateTo);
	endDate.setHours( 23,59,59,0 );
	endDate = endDate.getTime();

	useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setBillings(null)
		setPayouts(null)
		setBillingItems(null)
		setBillingsTotal(0)
		setPayoutTotal(0)
		if (selectedRestaurantId) {
			getData();
		}
	}, [selectedRestaurantId, selectedDate, selectedDateTo])

	const getData = async () => {
		setLoading(true)
		await baseAuth.get(`/restaurant/billing_info?restaurant=${selectedRestaurantId}&from=${startDate}&to=${endDate}`)
			.then((response) => {
				const billings = response.data.billings
				const payouts = response.data.payouts
				const billing_items = response.data.billing_items
				const billings_total = response.data.billings_total_sum
				const payout_total = response.data.payouts_total_sum
				setBillings(billings)
				setPayouts(payouts)
				setBillingItems(billing_items)
				setBillingsTotal(billings_total)
				setPayoutTotal(payout_total)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

	const downloadPdf = async (report, type) => {
		setLoading(true)
		await baseAuth.get(`/restaurant/report_pdf?restaurant=${selectedRestaurantId}&report=${report["id"]}&type=${type}`)
		.then((response) => {
			const url = response.data.url
			window.open(url,"_self")
			setLoading(false)
		})
		.catch((e) => {
			setLoading(false)
		})
	}

	const downloadPeriodPdf = async (type) => {
		setLoading(true)
		await baseAuth.get(`/restaurant/report_period_pdf?restaurant=${selectedRestaurantId}&from=${startDate}&to=${endDate}&type=${type}`)
		.then((response) => {
			const url = response.data.url
			window.open(url,"_self")
			setLoading(false)
		})
		.catch((e) => {
			setLoading(false)
		})
	}

	return (
		<>
        <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
			<div style={{display: 'flex', width: "90%", alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-between'}}>
				<RestaurantSelect />
				<DateRangeSelect hourFormat={false} defaultRange="last_month"/>
				<div style={{display: 'flex', gap: "10px"}}>
					<div style={{userSelect: 'none', width: "190px", height: "38px", marginTop: "24px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: showPayouts ? "#5FB894" : "#FFFFFF", padding: "10px 0", fontWeight: 600, color: showPayouts ? "#FFFFFF" : "#094553", borderWidth: "1px", borderColor: showPayouts ? "#5FB894" : "#094553", borderRadius: "9999px"}}
					 onClick={() => setShowPayouts(true)}>Transferencias
					</div>
					<div style={{userSelect: 'none', width: "190px", height: "38px", marginTop: "24px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: !showPayouts ? "#5FB894" : "#FFFFFF", padding: "10px 0", fontWeight: 600, color: !showPayouts ? "#FFFFFF" : "#094553", borderWidth: "1px", borderColor: !showPayouts ? "#5FB894" : "#094553",  borderRadius: "9999px"}}
					 onClick={() => setShowPayouts(false)}>Informes
					</div>
				</div>
			</div>
			{loading ?
				<>
					<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
						<Spinner>{t("Loading")}</Spinner>
					</div>
					<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
				</> : showPayouts ?
				<>
				<div style={{display: 'flex', alignSelf: 'flex-start', gap: "30px"}}>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", padding: "20px 40px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{centsToAmountString(payoutTotal, true, true)}€</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676'}}>Total tranferido</div>
					</div>
					<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", padding: "20px 40px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
						<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{payouts?.length}</div>
						<div style={{fontSize: "12px", fontWeight: 500, color: '#767676'}}>Transferencias</div>
					</div>
				</div>
				{payouts?.length > 0 && 
				<div style={{display: 'flex', alignSelf: 'flex-start', width: '90%', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', alignSelf: 'flex-start', fontSize: "18px", fontWeight: 700, marginTop: "15px",}}>Historial de transferencias</div> 			
					<div onClick={() => downloadPeriodPdf(0)} style={{display: "flex", flexDirection: "row", fontSize: "13px", fontWeight: 600, gap: "10px", alignItems: "center", backgroundColor: "#5FB894", padding: "0px 20px", color: "#FFFFFF", cursor: 'pointer', borderRadius: "9999px"}}>
            			<FolderDownloadIcon className={`w-[20px]`}/>
            			<div>Descargar pdf periodo</div>
          			</div>
				</div>}
				<Payouts payouts={payouts ? payouts : []} setPayoutToSee={setPayoutToSee} downloadPdf={downloadPdf}/></>
				: 
				<>
				<div style={{display: 'flex', flexDirection: "column", alignSelf: 'flex-start', marginTop: "15px", padding: "20px 40px", alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "10px"}}>
					<div style={{fontSize: "23px", fontWeight: 700, color: '#094553'}}>{centsToAmountString(billingsTotal, true, true)}€</div>
					<div style={{fontSize: "12px", fontWeight: 500, color: '#767676'}}>Total facturado</div>
				</div>
				{billings.length > 0 &&
				<div style={{display: 'flex', alignSelf: 'flex-start', width: '90%', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', alignSelf: 'flex-start', fontSize: "18px", fontWeight: 700, marginTop: "15px",}}>Historial</div> 			
					<div onClick={() => downloadPeriodPdf(1)} style={{display: "flex", flexDirection: "row", fontSize: "13px", fontWeight: 600, gap: "10px", alignItems: "center", backgroundColor: "#5FB894", padding: "0px 20px", color: "#FFFFFF", cursor: 'pointer', borderRadius: "9999px"}}>
            			<FolderDownloadIcon className={`w-[20px]`}/>
            			<div>Descargar pdf periodo</div>
          			</div>
				</div>}
				<Reports billings={billings} setBillingToSee={setBillingToSee} downloadPdf={downloadPdf}/></>
			}
			{/* Modals */}
			{billingToSee &&
				<BillingDetailModal t={t} setBillingToSee={setBillingToSee} billing={billingToSee} billingItems={billingItems} downloadPdf={downloadPdf}/>
			}
			{payoutToSee &&
				<PayoutDetailModal t={t} setPayoutToSee={setPayoutToSee} payout={payoutToSee} downloadPdf={downloadPdf}/>
			}
        </div>        
    </>
	)
}

export default Billing