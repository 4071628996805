import React from 'react';
import { useTranslation } from 'react-i18next';

const FormError = ({ message }) => {
  const { t } = useTranslation();

  if (message) {
    return (
      <div className='text-red-800 text-[10px] px-1 mt-1'>
        {t(message)}
      </div>
    );
  }
  return null;
};

// const FormErrorMsg = styled.div((props) => ({
//   display: props.message ? "block" : "none",
//   height: "18px",
//   fontSize: "10px",
//   backgroundColor: "red",
//   color: "white",
//   padding: "2px",
//   marginBottom: "2px",
//   borderRadius: "4px",
// }));

export default FormError;
