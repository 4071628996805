import React, { useState } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { XIcon } from '@heroicons/react/outline';
import { BookingSettings } from "../../Bookings/BookingSettings";
import { BookingRevision } from "../../Bookings/BookingRevision";

const BookingModal  = ({ t, booking, setBookingToEdit, saveBooking, setBookingToCancel, type, weekdaysMaxDiners, daysDiners}) => {
  const [bookingDraft, setBookingDraft] = useState(booking ? { ...booking } : null);
  const [step, setStep] = useState(1);
  const [errorName, setErrorName] = useState(null)
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorDiners, setErrorDiners] = useState(null)
  const [errorHour, setErrorHour] = useState(null)
  const [errorDate, setErrorDate] = useState(null)

  const changeName = (name) => {
    setBookingDraft((prevBookingDraft) => {
      return {
      ...prevBookingDraft,
      name: name,
    }})
  }
  const changeEmail = (email) => {
    setBookingDraft((prevBookingDraft) => {
      return {
      ...prevBookingDraft,
      email: email,
    }})
  }
  const changePhone = (phone) => {
    setBookingDraft((prevBookingDraft) => {
      return {
      ...prevBookingDraft,
      phone: phone,
    }})
  }

  const changeDiners = (diners) => {
    setBookingDraft((prevBookingDraft) => {
      return {
      ...prevBookingDraft,
      diners: diners,
      date: null,
      hour: null,
      minute: null
    }})
  }

  const changeDate = (date) => {
    setBookingDraft((prevBookingDraft) => {
      return {
      ...prevBookingDraft,
      date: date,
      hour: null,
      minute: null,
    }})
  }

  const changeHour = (hour, minute) => {
    setBookingDraft((prevBookingDraft) => {
      return {
      ...prevBookingDraft,
      hour: hour,
      minute: minute,
    }})
  }

  const checkDisableNextStep = () => {
    if (!bookingDraft.name || !bookingDraft.email || !bookingDraft.diners || !bookingDraft.date || bookingDraft.hour == null || bookingDraft.minute == null) {
      return true
    }
    return false
  }

  const checkCompulsoryFields = () => {
    if (checkDisableNextStep()) {
      if(!bookingDraft.name){
        setErrorName('compulsory_name_error')
      }
      if(!bookingDraft.email){
        setErrorEmail('compulsory_email_error')
      }
      if(!bookingDraft.diners){
        setErrorDiners('compulsory_diners_error')
      }
      if(!bookingDraft.hour || !bookingDraft.minute){
        setErrorHour('compulsory_hour_error')
      }
      if(!bookingDraft.date){
        setErrorDate('compulsory_date_error')
      }
      return
    }
    saveBooking(bookingDraft)
  }

  const cleanErrors = () => {
    setErrorName(null)
    setErrorEmail(null)
    setErrorDiners(null)
    setErrorHour(null)
    setErrorDate(null)  
  }
  
  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "50%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>{type === 0 ? "Modificar reserva" : "Crear reserva"}</div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "220px", justifyContent: 'flex-end'}}>
              <button style={{margin: "0 15px"}} onClick={() => {setBookingToEdit(null)}}><XIcon className={`w-[25px] text-gray-700 cursor-pointer`}/></button>
            </div>
        </div>
        <div style={{marginTop: "10px", width: "100%", height: "2px", backgroundColor: "#5FB894"}}></div>
        <div style={{marginTop: "20px", display: "flex", height: "250px", width: '100%', justifyContent: 'center'}}>
          {step === 1 && 
            <BookingSettings
              bookingDraft={bookingDraft} 
              changeName={changeName} 
              changePhone={changePhone} 
              changeEmail={changeEmail} 
              changeDiners={changeDiners} 
              changeDate={changeDate} 
              changeHour={changeHour} 
              weekdaysMaxDiners={weekdaysMaxDiners} 
              daysDiners={daysDiners}
              errorName={errorName}
              errorEmail={errorEmail}
              errorDiners={errorDiners}
              errorHour={errorHour}
              errorDate={errorDate}
            />
          }
          {step === 2 && <BookingRevision bookingDraft={bookingDraft}/>}
        </div>
        <div className='mt-12 w-full text-xs italic'><span className='text-red-600'>*</span>{t('compulsory_fields')}</div>
        <div style={{marginTop: "5px", width: "100%", height: "2px", background: '#CCC'}}></div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '25px', width: "100%", justifyContent: type === 0 ? 'space-between' : 'flex-end'}}>
        {type === 0 && 
         <div>
         <button
           style={{width: '175px', border: "1px solid #838383", color: "#838383", fontWeight: 500}}
           className={"rounded-full cursor-pointer py-2 px-4 m-2 text-xs"}
           onClick={() => setBookingToCancel(booking)}>{"Cancelar reserva"}</button>
       </div>}
       {step === 0 && 
         <div>
         <button
           style={{width: '175px', border: "1px solid #838383", backGroundColor: "#094553", color: "white", fontWeight: 500}}
           className={"rounded-full cursor-pointer py-2 px-4 m-2 text-xs"}
           onClick={() => setStep(1)}>{"Modificar reserva"}</button>
       </div>}
        <div>
          <button
            //disabled={checkDisableNextStep()}
            onBlur={() => cleanErrors()}
            style={{width: '175px', backgroundColor: checkDisableNextStep() ? 'gray' : '#5FB894', fontWeight: 500}}
            className={`rounded-full py-2 px-4 m-2 bg-principal text-xs text-white ${checkDisableNextStep() ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => checkCompulsoryFields() && step === 1 ? setStep(2) : ''}>{step === 1 ? "Continuar" : type === 1 ? "Crear reserva" : "Modificar reserva"}</button>
        </div>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default BookingModal 