import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { parseIntegerString, toDecimalString } from 'logic/functions';
import useUpdateTableData from 'components/hooks/useUpdateTableData';
import { toast } from 'react-toastify';
import { Context } from 'data/authContext';
import { NumberInput } from 'common/Input';
import { Button } from 'common/Button';
import { ActiveSwitch } from 'pages/admin/pages/OrderAndPay/ActiveSwitch';
import styled from 'styled-components';

const LoyaltyProgramCard = ({restaurantData, getRestaurantData}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [initialValues, setInitialValues] = useState({});

	const {
		state: { selectedRestaurant },
	} = React.useContext(Context);

  const { updateData } = useUpdateTableData({
    entidad: 'restaurants',
    id: selectedRestaurant[0]?.value,
  });

  const handleSubmit = async (values) => {
    try {
      const data = {
        loyalty_earning_percentage: Number(values.loyalty_earning_percentage || 0),
        loyalty_min_redeem_amount_cents: Number(values.loyalty_min_redeem_amount_cents || 0) * 100,
        loyalty_earning_min_payment_cents: Number(values.loyalty_earning_min_payment_cents || 0) * 100,
        loyalty_hours_until_cashback_granted: Number(values.loyalty_hours_until_cashback_granted || 0),
      };
      
      await updateData(data);
      await getRestaurantData(selectedRestaurant[0].value);
      toast.success(`${t('update_ok')}`);
    } catch(error) {
      toast.error(t('an_error_has_occurred'));
    };
  };
  
  const toggleStatus = async () => {
    try {
      await updateData({loyalty_program: !isActive})
      await getRestaurantData(selectedRestaurant[0].value);
    } catch {
      toast.error(t('an_error_has_occurred'));
    };
  };

  useEffect(() => {
    setIsActive(restaurantData.loyalty_program);
    setInitialValues({
      loyalty_earning_percentage: restaurantData.loyalty_earning_percentage,
      loyalty_min_redeem_amount_cents: toDecimalString((restaurantData.loyalty_min_redeem_amount_cents || 0) / 100),
      loyalty_earning_min_payment_cents: toDecimalString((restaurantData.loyalty_earning_min_payment_cents || 0) / 100),
      loyalty_hours_until_cashback_granted: restaurantData.loyalty_hours_until_cashback_granted,
    });
  }, [restaurantData]);

  const validationSchema = yup.object().shape({
    loyalty_earning_percentage: yup.number().min(0).max(100),
    loyalty_min_redeem_amount_cents: yup.number().min(0),
    loyalty_earning_min_payment_cents: yup.number().min(0),
    loyalty_hours_until_cashback_granted: yup.number().min(0),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <FormCard>
          <header className='flex gap-14 w-full justify-between'>
            <h3 className='text-2xl font-semibold'>
              {t('your_loyalty_program')}
            </h3>
            <ActiveSwitch checked={isActive} onChange={toggleStatus}/>
          </header>
          <div>
            <NumberInput
              label='% redeem'
              value={values.loyalty_earning_percentage}
              name='loyalty_earning_percentage'
              placeholder='0'
              onChange={handleChange}
              error={errors.loyalty_earning_percentage}
              min={0}
              max={100}
              parseValue={parseIntegerString}
              showErrorMessage={false}
              unit='%'
            />
            <NumberInput
              label='min to earn'
              value={values.loyalty_earning_min_payment_cents}
              name='loyalty_earning_min_payment_cents'
              placeholder='0'
              onChange={handleChange}
              error={errors.loyalty_earning_min_payment_cents}
              showErrorMessage={false}
              unit='€'
            />
          </div>
          <div>
            <NumberInput
              label='min_time_to_redeem'
              value={values.loyalty_hours_until_cashback_granted}
              name='loyalty_hours_until_cashback_granted'
              placeholder='0'
              onChange={handleChange}
              parseValue={parseIntegerString}
              error={errors.loyalty_hours_until_cashback_granted}
              showErrorMessage={false}
              unit='h'
            />
            <NumberInput
              label='min to redeem'
              value={values.loyalty_min_redeem_amount_cents}
              name='loyalty_min_redeem_amount_cents'
              placeholder='0'
              onChange={handleChange}
              error={errors.loyalty_min_redeem_amount_cents}
              showErrorMessage={false}
              unit='€'
            />
          </div>
          <footer>
            <Button
              label='save'
              type='submit'
              onClick={handleSubmit}
              disabled={Object.values(errors).some(error => !!error)}
            />
          </footer>
        </FormCard>
      )}
    </Formik>
  );
};

const FormCard = styled.form`
  background-color: #FFF;
  border-radius: 20px;
  padding: 32px;
  width: fit-content;

  & > header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BFBFBF;
    padding-bottom: 24px;
    margin-bottom: 32px;
  }

  & > div {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin: 16px 0;

    & > * {
      flex: 1;
    }
  }

  & > footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #BFBFBF;
    padding-top: 24px;
    margin-top: 32px;
  }
`;

export default LoyaltyProgramCard;