import {useMediaQuery} from '../hooks/useMediaQuery';

const XMenuButton = ({
  active = false,
  disabled = false,
  children,
  doClick,
  onMouseOver,
  onMouseOut
}) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");
  return (
    <button
      disabled={disabled}
      type={'button'}
      className={`${isRowBased ? '' : 'w-full mb-5 pl-6'} relative flex flex-row items-center space-x-2 rounded-md cursor-pointer px-4 py-3 text-sm text-left font-semibold disabled:text-gray-200'
      ${
        active
          ? 'bg-principal hover:bg-gray-200 text-current hover:text-secundario focus:outline-none'
          : 'bg-transparent hover:bg-gray-200 hover:bg-opacity-20 text-current focus:outline-none'
      }
      `}
      onClick={doClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </button>
  );
}

export default XMenuButton;
