import React, { useState } from "react";
import { PencilIcon, TrashIcon, ExclamationIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import Switch from 'react-switch';
import {  baseAuth } from "logic/api";
import { Icon, IconType } from "common/Icon";

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '.2fr 1.5fr 1fr 1.5fr 1fr .5fr .2fr',
	width: '100%'

});

export const getPosition = (custom_group, catalog=null) => {
  if (custom_group.restaurant) {
    return ["restaurant", "Inicio de la carta"]
  } else if (custom_group.l1) {
    let obj = catalog?.supergroups.find(s_g => s_g.id == custom_group.l1)
    let name = (catalog && obj) ? `Inicio de categoría principal (${obj.name ? obj.name : obj.tpv_name})` : "Inicio de categoría principal"
    return ["l1", name]
  } else if (custom_group.l2) {
    let obj = catalog?.category_groups.find(c_g => c_g.id == custom_group.l2)
    let name = (catalog && obj) ? `Inicio de categoría secundaria (${obj.name ? obj.name : obj.tpv_name})` : "Inicio de categoría secundaria"
    return ["l2", name]
  } else {
    let obj = catalog?.product_categories.find(p_c => p_c.id == custom_group.l3)
    let name = (catalog && obj) ? `Inicio de categoría de producto (${obj.name ? obj.name : obj.tpv_name})` : "Inicio de categoría de producto" 
    return ["l3", name]
  }

};


const getVisibility = (custom_group) => {
  const number_to_weekday = {0: "L", 1: "M", 2: "X", 3: "J", 4: "V", 5: "S", 6: "D"}
  if (custom_group.visibility == 0) {
    return ["Nunca visible"]
  } else if (custom_group.visibility == 1) {
    return ["Siempre visible"]
  } else {
    let return_visibility = []
    for (let i= 0; i < custom_group.visibility_schedules?.length; i++){
      let days = custom_group.visibility_schedules[i].schedule?.days?.map((day) => number_to_weekday[day])
      for (let e= 0; e < custom_group.visibility_schedules[i].time_range.length; e++){
        let clean_from = `${custom_group.visibility_schedules[i].time_range[e].from[0].toString().padStart(2,0)}:${custom_group.visibility_schedules[i].time_range[e].from[1].toString().padStart(2,0)}`
        let clean_to = `${custom_group.visibility_schedules[i].time_range[e].to[0].toString().padStart(2,0)}:${custom_group.visibility_schedules[i].time_range[e].to[1].toString().padStart(2,0)}`;
        return_visibility.push(days.join('-') + ";  " + clean_from + " - " + clean_to)
      }
    }
    return return_visibility
  }
};



const ProductHighlightRow = ({ custom_group, indent, setLoading, setCustomGroups, setCustomGroupToRemove, setCustomGroupToEdit, catalog}) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const changeActiveState = async (new_state) => {
    setLoading(true)
    custom_group.active = new_state
    custom_group.type = 1
    let custom_group_f_d = new FormData();
		for ( var key in custom_group ) {
			if (!["products_info", "visibility_schedules"].includes(key)) {
				custom_group_f_d.append(key, custom_group[key] != null ? custom_group[key] : "");
			}
		}
		custom_group_f_d.append("products_info", JSON.stringify(custom_group["products_info"]));
		custom_group_f_d.append("visibility_schedules", JSON.stringify(custom_group["visibility_schedules"]));
    await baseAuth(`/custom_group/update_custom_group`, {
			method: "POST",
			"Content-Type": "multipart/form-data",
			data: custom_group_f_d,
		  })
      .then((response) => {
        const custom_groups = response.data.product_highlights
        setCustomGroups(custom_groups)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }
  
  indent++;

  return (
    <>
    <table className={'w-full'}>
      <tr
        className={`${bgShades[indent]} ${borderShades[indent]} border-b-2 border-gray-200 px-1`}
        style={rowStyle()}
      >
          <td className={`h-full text-center text-xs font-normal py-2 items-center flex justify-center `}>
            <Icon type={IconType.DRAG}/>
          </td>
          <td className={`h-full text-center text-xs font-normal py-2 items-center flex justify-center `}>
            {custom_group.name}
          </td>
          <td className={`h-full text-center text-xs font-normal py-2 items-center flex justify-center `}>
            <div style={{backgroundColor: custom_group.custom_group_type == "multi" ? '#EDD0FF' : custom_group.custom_group_type == "individual" ?  "#FFEAAD" : "#CAEAFF", padding: "5px", borderRadius: "5px"}}>
              <span style={{fontWeight: 600, marginRight: "1px"}}>{custom_group.products_info.length > 0 ? custom_group.products_info.length : ""}</span> {custom_group.products_info.length > 0 ? custom_group.products_info.length > 1 ? "Productos" : "Producto" : " ✔️ Imagen"}
            </div>
          </td>
          <td style={{display: getVisibility(custom_group).length > 1 ? "inline" : "flex", justifyContent: "center", alignItems: 'center'}} className={`h-full text-center text-xs font-normal py-2`}>
            {getVisibility(custom_group).map((visibility) =>
            <div>
              {visibility}
            </div>
            )}
          </td>
          <td className={`h-full text-center text-xs font-normal py-2 items-center flex justify-center `}>
          <Switch
            height={10}
            width={25}
            handleDiameter={15}
            offColor={'#DDD'}
            offHandleColor={'#CCC'}
            onColor={'#C7E6DA'}
            onHandleColor={'#5FB894'}
            activeBoxShadow={'0 0 0 3px #CFEADF'}
            onChange={() => changeActiveState(!custom_group.active)}
            name='active_custom_group'
            checked={custom_group.active}
            uncheckedIcon={false}
            checkedIcon={false}
          />
          </td>
          <td className={`h-full text-start text-xs font-normal py-2 items-center justify-center flex`}>
            <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <TrashIcon 
              onClick={() => setCustomGroupToRemove(custom_group)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
              />
            <PencilIcon
              onClick={() => setCustomGroupToEdit(custom_group)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />  
              </div>
          </td>     
          <td className={`h-full text-start text-xs font-normal py-2 items-center justify-center flex`}
          onMouseEnter={() => custom_group.products_warning ? setIsHovering(true) : ""}
          onMouseLeave={() => custom_group.products_warning ? setIsHovering(false) : ""}>
          {custom_group.products_warning && 
            <ExclamationIcon
            style={{fill: '#FFEAAD'}}
            className={`w-[20px] transition-all animate-fade-in-display delay-75 `}
          />}          
          </td>   
      </tr>
      {isHovering && <div style={{right: 0, zIndex: 999999, position: 'absolute', fontWeight: 500, padding: "10px", borderWidth: "2px", backgroundColor: "white", width: "225px", textAlign: 'center'}}>
      Los productos seleccionados <b>no</b> son visibles.<br/>El destacado no se mostrará a los clientes.
      </div>}
    </table>
    </>
  )
};

export default ProductHighlightRow