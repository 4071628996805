import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import DiscountCouponPreview from "components/previews/screens/DiscountCouponPreview";
import { ProductDiscountPreview } from "components/previews/screens/ProductDiscountPreview";
import { PromotionPreview } from "components/previews/screens/PromotionPreview";
import { HalfMobileMockup } from "components/previews/HalfMobileMockup";
import { HeaderMockup } from "components/previews/components";
import { apiAuth } from 'logic/api';
import { Context as AuthContext } from '../../../data/authContext';
import etiqueta from "../../../img/etiqueta.svg";
import { CardTitle } from '../styles/DiscountsContainers';
import { ProductCardMockup } from "components/previews/components";
import { Icon, IconType } from 'common/Icon';
import { Button, BUTTON_VARIANTS } from "common/Button";

export const ChooseDiscount = ({ discount, editDiscount, activeGeneralDiscount, activeProductDiscount, close }) => {
  const [theme, setTheme] = useState('light');
  const { t } = useTranslation();
  const {state: { selectedRestaurantId }} = useContext(AuthContext);
  const [openWarningGeneralDiscount, setOpenWarningGeneralDiscount] = useState(false)
  const [openWarningProductDiscount, setOpenWarningProductDiscount] = useState(false)

  const getData = async (id) => {
    try {
      const { data: newData } = await apiAuth(`/restaurants/${id}/`, {method: 'GET'});
      setTheme(newData?.theme || 'light')
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    selectedRestaurantId && getData(selectedRestaurantId);
  }, [selectedRestaurantId]);

  const promoTypes = [
    {
      value: "product_discount",
      label: t("product_discount"),
      disabled: true,
    },
    {
      value: "general_discount",
      label: t("whole_catalog_discount"),
      disabled: false,
    },
    {
      value: "code_coupon",
      label: t("coupons_discounts"),
      disabled: false,
    },
  ];
  
  const promoElement = promoTypes.find(element => discount?.typology && element.value === discount?.typology);

  const changeType = (promoType) => {
    if (promoType) {
      setDiscountType(promoType);
    }
  };

  const selectType = () => editDiscount({typology: discountType.value});

  const [discountType, setDiscountType] = useState(promoElement ? promoElement : null);

  return (
    <>
      <h2>{t('what_type_of_discount_you_want_to_create')}</h2>
      <div className="flex w-full gap-10 flex-row justify-around px-6 py-8 relative">
        <div
          className={`${discountType?.value === 'product_discount' ? "bg-[#E2FEF1]" : ""} ${activeGeneralDiscount ? 'opacity-70' : ''} flex flex-col items-center w-72 mt-4 border border-[#E4E4E4] rounded-[20px] p-10 relative cursor-pointer`}
          onClick={() => activeGeneralDiscount ? setOpenWarningGeneralDiscount(true) : changeType(promoTypes[0])}
        >
          {discountType?.value === 'product_discount' &&
            <div className='absolute top-2 left-2'>
              <Icon type={IconType.VERIFIED}/>
            </div>
          }
          <HalfMobileMockup>
            <ProductDiscountPreview discount={discount} />
            <div className='absolute p-5' style={{width: "270px", left: "-35px", top: "150px"}}>
              <ProductCardMockup
                bigger
                badge
                allergens
                description={"short"}
                discount={discount}
                chooseDiscount
              />
            </div>
          </HalfMobileMockup>
          <CardTitle>{t('discount in products')}</CardTitle>
          {openWarningGeneralDiscount && 
            <div
              style={{textAlign: 'center', lineHeight: "16px", zIndex:99999}}
              class="bg-[#FFF5DC] font-[12px] italic absolute inset-0 -m-3 w-[310px] h-[min-content] p-4 rounded-lg text-gray-800 font-semibold"
            >
              {t('general_discount_already_active_message')}
            </div>
          }
        </div>
        <div
          className={`${discountType?.value === 'general_discount' ? "bg-[#E2FEF1]" : ""} flex flex-col ${activeProductDiscount ? 'opacity-70' : ''} items-center w-72 mt-4 border border-[#E4E4E4] rounded-[20px] p-10 relative cursor-pointer`}
          onClick={() => activeProductDiscount ? setOpenWarningProductDiscount(true) : changeType(promoTypes[1])}
        >
          {discountType?.value === 'general_discount' &&
            <div className='absolute top-2 left-2'>
              <Icon type={IconType.VERIFIED}/>
            </div>
          }
          <HalfMobileMockup>
            <PromotionPreview discount={discount} />
          </HalfMobileMockup>
          <CardTitle>{t('discount in all menu (promotion)')}</CardTitle>
        {openWarningProductDiscount &&
          <div
            style={{textAlign: 'center', lineHeight: "16px", zIndex:99999}}
            class="bg-[#FFF5DC] font-[12px] italic absolute inset-0 -m-3 w-[310px] h-[min-content] p-4 rounded-lg text-gray-800 font-semibold"
          >
            {t('product_discount_already_active_message')}
          </div>
        }
        </div>
        <div 
          className={`${discountType?.value === 'code_coupon' ? "bg-[#E2FEF1]" : ""} flex flex-col items-center w-72 mt-4 border border-[#E4E4E4] rounded-[20px] p-10 relative cursor-pointer`}
          onClick={() => changeType(promoTypes[2])}
        >  
          {discountType?.value === 'code_coupon' && 
            <div className='absolute top-2 left-2'>
              <Icon type={IconType.VERIFIED}/>
            </div>
          }         
          <HalfMobileMockup>
            <div className="bg-black bg-opacity-70 pt-[20px] backdrop-blur-sm">
              <HeaderMockup themeName={theme}/>
              <DiscountCouponPreview discount={discount} />
              <div style={{boxShadow: "0px 3px 10px 0px #0000001A", left: "-38px", top: "160px"}} className="mx-4 bg-white p-[10px] mt-3 flex flex-col items-center justify-between absolute rounded-[10px]">
                <div className="text-[10px] flex flex-row gap-2 w-full whitespace-nowrap">
                  <img src={etiqueta} alt='tag'/>
                  {t('do_you_have_a_code_coupon')}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px', width: '100%'}}>
                  <div style={{flex: 1}} className="text-[9px] flex flex-row items-center justify-between px-2 border-[1px] border-gray-300 h-8 rounded-md whitespace-nowrap pr-6"> 
                    {discount?.code ? discount?.code : t('write_here_your_code')} 
                  </div>
                  <div style={{width: '80px', marginLeft: '3px'}} className="bg-[#404040] flex items-center h-8 justify-center rounded-full text-white text-center text-[9px] font-semibold px-4">{t('Apply')}</div>
                </div>
              </div>
            </div>
          </HalfMobileMockup>
          <CardTitle>{t('coupon_discount')}</CardTitle>
        </div>
      </div>
      <footer>
        <Button
          label={'cancel'}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={close}
        />
        <Button
          label={'select'}
          disabled={!discountType}
          onClick={selectType}
        />
      </footer>
    </>
  );
};
