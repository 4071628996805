import { PhotographIcon } from "@heroicons/react/outline";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/solid";
import { centsToAmountString, getImageUrl } from "logic/functions";
import React from "react";
import { useTranslation } from "react-i18next";
import { MockAllergensAndTags } from "./MockAllergensAndTags";
import styled from "styled-components";

export const ProductPopupMockup = ({ product }) => {
  const { t } = useTranslation();

  const image = getImageUrl(product?.image);

  return (
    <MockupContainer>
      <div className="w-72 flex gap-2 rounded-md p-[8px] text-[#787878] bg-white shadow-lg">
        <div className="w-16 h-16 bg-gray-200 rounded-md overflow-hidden flex relative flex-shrink-0">
          {image ? (
            <img
              src={image}
              height={64}
              width={64}
              style={{ objectFit: "cover" }}
              alt=''
            />
          ) : (
            <>
              <PhotographIcon height={25} className="m-auto text-[#C0C0C0]" />
            </>
          )}
        </div>
        <div className="flex-1 flex flex-col gap-[3px] w-0">
          <h6 className="text-[15px] font-semibold">
            {product.visible_name || t("Product")}
          </h6>
          <p
            className="flex-1 text-[12px]"
            style={{
              display: '-webkit-box',
              maxWidth: '100%',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {product.description}
          </p>
          <MockAllergensAndTags product={product}/>
        </div>
        <div className="flex flex-col justify-between items-end">
          <b className="text-[15px] font-bold">
            {centsToAmountString(product.tpv_price_cents).replace(".", ",")}€
          </b>
          <PlusCircleIcon height={20} />
        </div>
      </div>
      <div className="bg-white rounded-t-3xl w-72 mt-4 mx-5 flex flex-col shadow-lg">
        {image ? (
          <img
            src={image}
            className="rounded-t-3xl h-auto w-full max-h-72"
            style={{objectFit: "cover"}}
            alt=''
          />
        ) : (
          <div className="w-full h-72 bg-gray-200 rounded-t-3xl flex relative">
            <PhotographIcon height={55} className="m-auto text-[#C0C0C0]" />
          </div>
        )}
        <div className="flex-1 flex flex-col gap-[8px] p-4">
          <h6 className="text-[13px] leading-[1em] font-semibold">
            {product.name || product.visible_name || t("Product")}
          </h6>
          <p className="text-[9px]" style={{
            display: '-webkit-box',
            maxWidth: '200px',
            WebkitLineClamp: '4',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}>
            {product.description}
          </p>

          <MockAllergensAndTags  product={product} showLabel/>

          <div className="flex flex-row justify-between items-end px-2">
            <b className="text-[14px] font-bold">
              {centsToAmountString(product.tpv_price_cents).replace(".", ",")}€
            </b>
            <div className="flex flex-row gap-2">
              <MinusCircleIcon height={23} />
              1
              <PlusCircleIcon height={23} />
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
    </MockupContainer>
  );
};

const MockupContainer = styled.div`    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: min-content;
  overflow: hidden;
  padding: 16px 16px 0;
  border-bottom: 1px solid #DDDDDD;
  flex-shrink: 0;
  
  & > * {
    border: 1px solid #DDDDDD66;
    box-shadow: 0 0 8px #959DA51A;
  }
`;