import React, { useState } from 'react'
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useGetDataInTimeRange } from 'hooks/useGetDataInTimeRange';
import Spinner from 'common/Spinner';
import { LoyaltyGraph } from '../LoyaltyGraph';
import LoyaltyTable from '../LoyaltyTable';
import UsersGraph from '../UsersGraph';
import { useCallback } from 'react';
import { AdminLoyaltyOverview } from '../components/overview/AdminLoyaltyOverview';

function LoyaltyDashboard() {
  const isRowBased = useMediaQuery("(max-width: 760px)");
	const { t } = useTranslation()
	const [revenueCount, setRevenueCount] = useState([]);
	const [data, setData] = useState([])
	const [spinner, setSpinner] = useState(true)
	const [revenue, setRevenue] = useState([])
	const [loyalty, setLoyalty] = useState([])
	const [loyaltyChart, setLoyaltyChart] = useState([])
	const [totalClients, setTotalClients] = useState([])
	const [totalClientsChart, setTotalClientsChart] = useState([])
	const user = JSON.parse(localStorage.getItem('yumminn-user'));

  const setters = useCallback(res => {
    setData(res.data)
    setRevenue(res.data.revenue.revenue);
    setRevenueCount(res.data.revenue.revenue_count)
    setLoyalty(res.data.revenue.loyalty.table_data)
    setLoyaltyChart(res.data.revenue.loyalty.chart_data)
    setTotalClients(res.data.total_clients)
    setTotalClientsChart(res.data.total_clients.chart_data)
  }, [setData, setRevenueCount, setTotalClients, setTotalClientsChart, setLoyalty, setLoyaltyChart]);

  useGetDataInTimeRange('/payments/loyalty_dashboard_data/',setSpinner, setters);

  return (
    <div className='relative pb-8'>
      {(data.length === 0 || spinner) &&
        <div className="h-full w-full absolute top-0 left-0 z-9999 flex flex-col justify-center items-center">
          <Spinner>{t('Loading')}</Spinner>
        </div>
			}
      {(user?.is_superuser || user?.is_admin) &&
        <AdminLoyaltyOverview isLoading={data.length === 0 || spinner}/>
      }
      <div className={`w-full max-w-screen-xl grid items-stretch gap-8 ${data.length === 0 || spinner ? 'opacity-25' : ''}`} style={{gridTemplateColumns: isRowBased ? '1fr' : '0.7fr 1fr'}}>
        <div className='col-span-1 row-span-1 h-full min-h-[28rem]'>
					<LoyaltyGraph info={loyaltyChart} data={loyalty} />
				</div>
        <div className='col-span-1 row-span-1 row-start-2 h-full min-h-[28rem]'>
					<UsersGraph info={totalClientsChart} data={totalClients} />
        </div>
        <div className='col-span-1 row-span-2'>
          <LoyaltyTable
            revenue={revenue}
            revenueCount={revenueCount}
            loyalty={loyalty}
          />
        </div>
      </div>
    </div>
  )
}

export default LoyaltyDashboard