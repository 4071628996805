import { centsToAmountString } from 'logic/functions';
import React from 'react';
import { useTranslation } from "react-i18next";

const OrderTotals = ({text, amount, negative=false, light=false, font_size=null}) => {
  if (!font_size) {
    font_size = light ?  "15px" : "18px"
  }
  const { t } = useTranslation();
  return (
    <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', height: "25px", fontStyle: light ? "italic" : "normal"}}>
      <div style={{fontSize: font_size, fontWeight: 400}}> 
        {t(text)}
      </div>
      <div style={{fontSize: font_size, fontWeight: light ? 400 : 700}}>
        {negative ? "-" : ""}{centsToAmountString(amount ? amount : 0)}€
      </div>
    </div>
  );
};
export default OrderTotals;
