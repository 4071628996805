import React from 'react';
import { useTranslation } from "react-i18next";
import OrderProductRow from './OrderProductRow';

const OrderProductsInfo = ({products, products_modifiers, modifiers_modifiers, show_products_length=true, product_font_size="16px", product_smaller_font_size="14px", modifier_smaller_font_size="13px", show_prices=true, modifier_font_weight=400}) => {
  const { t } = useTranslation();
  products.sort(p => p.is_free_product ? 1 : -1);

  return (
    <div style={{marginTop: "15px", fontWeight: 600, border: "1px solid #D4D4D4", padding: "15px", borderRadius: "10px", maxHeight: "400px", overflowY: "scroll"}}>
		{show_products_length &&
    <div  style={{display: "flex", gap: "5px", fontSize: "16px", fontWeight: 600, height: "32px", alignItems: 'center'}}>
      <div style={{display: "flex", width: "32px", height: "100%", justifyContent: 'center', alignItems: 'center', backgroundColor: "#404040", color: "#FFFFFF", borderRadius: "999999px"}}>
      {products.length}
      </div>
      <div style={{fontSize: "18px"}}>
        {products.length > 1 ? t("Products") : t("Product")}
      </div>
    </div>}
    <div style={{marginTop: "30px", display: 'flex', gap: "20px", flexDirection: "column"}}>
    {products?.map((product, index) =>
      <OrderProductRow
        key={'product_' + index}
        product={product}
        product_modifiers={products_modifiers.filter(p_m => p_m.order_product == product.id)}
        modifiers_modifiers={modifiers_modifiers}
        font_size={product_font_size}
        smaller_font_size={product_smaller_font_size}
        modifier_smaller_font_size={modifier_smaller_font_size}
        show_prices={show_prices}
        modifier_font_weight={modifier_font_weight}
      />
		)}
    </div>
	</div>
  );
};
export default OrderProductsInfo;
