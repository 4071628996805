import { CatalogDNDContext } from "data/catalogDnDContext";
import { useContext, useEffect } from "react";
import { TabButton } from "./TabButton";

export const useDraggableClone = (listName, listId, draggableList) => {
  const DraggableClone = (providedDrop, _, rubric) => {
    const {
      state: {
        draggingItem,
      },
      setDraggingItem,
    } = useContext(CatalogDNDContext);
    
    useEffect(() => {
      !draggingItem && setDraggingItem({
        listName,
        listId,
        ...draggableList[rubric.source.index],
      });
  
      if (!draggingItem) return;
  
      const clone = document.getElementById(`dragging_clone_${draggingItem?.id}`);
      !draggingItem?.width && setDraggingItem({...draggingItem, width: clone?.clientWidth + -8, index: rubric.source.index});
    }, [draggingItem]);
  
    if (!draggingItem) return <></>;
  
    return (
      <div
        id={`dragging_clone_${draggingItem.id}`}
        {...providedDrop.draggableProps}
        {...providedDrop.dragHandleProps}
        ref={providedDrop.innerRef}
      >
        <TabButton
          listId={listId}
          provided={providedDrop}
          item={draggingItem}
          selected
        />
      </div>
    );
  };

  return DraggableClone;
};