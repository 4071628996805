import React from 'react';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import {centsToAmountString} from "../../../logic/functions";
import { InformationCircleIcon} from '@heroicons/react/outline';
import useModal from 'components/hooks/useModal';

const DetailPaymentMobile = ({detailsPayment, children}) => {

    const {t} = useTranslation();
    const infoModal = useModal();

    return (
        <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 0.5}}
        className='mb-5 bg-white'
      >
      <div style={{border: '1px solid rgba(64, 64, 64, 0.15)', boxShadow: '0px 8px 20px rgba(149, 157, 165, 0.2)', borderRadius: '5px'}} className={`flex flex-row justify-between p-4 text-[14px]`}>
        <div className='flex flex-col text-[14px] font-bold'>
          <div className='p-1 border-b-1'>{t('Table')}</div>
          <div className='p-1 border-b-1'>{t('Sector')}</div>
          <div className='p-1 border-b-1'>Método de pago</div>
          <div className='p-1 border-b-1'>{t('Date and hour')}</div>
          <div className='p-1 border-b-1'>{t('Tip')}</div>
          <div className='p-1 border-b-1'>{t('Total')}</div>
          <div className='p-1 border-b-1'>{t('Status')}</div>
        </div>
        <div className='flex flex-col text-[14px] text-right'>
          <div className='p-1 border-b-1'>{detailsPayment?.table?.name}</div>
          <div className='p-1 border-b-1'>{detailsPayment?.table?.sector?.name}</div>
          <div className='p-1 border-b-1 min-h-6'>{children}</div>
          <div className='p-1 border-b-1'>{new Date(detailsPayment?.date).toLocaleString('es-ES')}</div>
          <div className='p-1 border-b-1'>{centsToAmountString(detailsPayment?.tip_cents) + '€'}</div>
          <div className='p-1 border-b-1'>{centsToAmountString(detailsPayment.base_amount_cents + detailsPayment.tip_cents) + '€'}</div>
          <div className={`p-1 border-b-1 text-right ml-24 flex flex-row ${detailsPayment.refunded ? 'text-red-500 bg-red-100' : detailsPayment.status === 'error_sending_info_to_pos' ? 'text-yellow-500 bg-yellow-100' : 'text-green-500 bg-green-100'}`}>
               {detailsPayment.refunded ? t('refunded') : detailsPayment.status === 'error_sending_info_to_pos' ? t("paid") + " ("+t("pos error")+")" : t('paid')}                 
               {detailsPayment.status === 'error_sending_info_to_pos' && 
                    <InformationCircleIcon onClick={() =>infoModal.openModal()} className='h-4 w-4 text-yellow-500' />
                }
          </div>
        </div>


      </div>
      </motion.div>
    )

}

export default DetailPaymentMobile;
