import React from 'react';
import { awareDatetimeString, centsToAmountString } from 'logic/functions';

const WalletRow = ({topup, selected, isNotOpened}) => {
  
  return (
    <div style={{border: selected ? "2px solid #5FB894" : "2px solid #FCFCFC", display: 'grid', gridTemplateColumns: ".5fr 1.2fr .8fr 1fr 1fr", textAlign: 'center', width: "100%", backgroundColor: selected ? "#EEF9F5" : isNotOpened ? "#FFF7E2" : "#FCFCFC", justifyContent: 'space-around', fontSize: "16px", fontWeight: 500, padding: "10px 0px", marginTop: "10px", alignItems: 'center', borderRadius: '15px'}}>
		<div><span style={{whiteSpace: 'nowrap', backgroundColor: selected ? "#094553" : "#EEF9F5", color: selected ? "#FFFFFF" : "#404040", border: selected ? "none" : "1px solid #AFDCCA", padding: '5px 10px', borderRadius: "99999px", }}>{`YR-${topup.id}`}</span></div>
		<div style={{display: 'flex', justifyContent: 'center', zIndex: 0}}>{topup.client_name ? topup.client_name : topup.name}</div>
		<div style={{display: 'flex', flexDirection: 'flex-column'}}>{awareDatetimeString(topup.date, "Europe/Madrid")}</div>
		<div style={{backgroundColor: "#CCE8FB", padding: '4px 12px', borderRadius: "6px"}} >{centsToAmountString(topup.price ? topup.price : 0) + ' €'}</div>
		<div>{centsToAmountString((topup.price ? topup.price : 0) + (topup.extra_credits ? topup.extra_credits : 0)) + ' €'}</div>
	</div>
  );
};
export default WalletRow;