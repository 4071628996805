import createNavigatorContext from './createDataContext';

const INITIAL_STATE = {
 open: false
};

const ACTIONS = {
  SET_OPEN: 'SET_OPEN',
};

const actions = {
  setOpen: (dispatch) => {
    return (open) => {
      dispatch({ type: ACTIONS.SET_OPEN, payload: open });
    };
  }
};


const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_OPEN':
          return { ...state, open: action.payload };
        default:
            return state;
    }

};

export const { Context: NavigatorContext, Provider: NavigatorProvider } = createNavigatorContext(reducer, actions, INITIAL_STATE);