import React from "react";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { categories_select_styles } from "logic/defaults";
import { useMediaQuery } from '../../../../hooks/useMediaQuery';


const WaiterSelect = ({ waiterData, setWaiters}) => {
  const { t } = useTranslation();
  const selectorOptions = waiterData?.map((w_d) => {
    return {
      value: w_d.id,
      label: w_d.name,
      id: w_d.id,
      name: w_d.name
    }
  })
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const handleSelect = (value) => {
    const result = value.map((val) => {
      return {
        id: val.id,
        name: val.name
      }
    })
    setWaiters(result)
  }

  return (
    <div className={`flex flex-col items-start  ${!isRowBased ? "my-3 w-1/3 " : " w-full "}  ${isRowBased && 'justify-center'}`} style={{ fontFamily: 'Poppins, sans-serif', fontSize: isRowBased ? "14px" : "16px", fontWeight: 500}}>
      <div>{t('Waiter')}</div>
      <Select
        isMulti={true}
        id={'children'}
        options={selectorOptions}
        styles={categories_select_styles}
        className={'w-80'}
        placeholder={t('Waiter')}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onChange={(e) => {
          handleSelect(e);
        }}
      />
    </div>
  )
};

export default WaiterSelect