import React from "react";

export const CheckBox = ({table, handleCheckChieldElement, sectores}) => {
    

  React.useEffect(() => {
    //
  
  }, [sectores]); 
  return (
      <input
        key={table.id}
        onChange={handleCheckChieldElement}
        type="checkbox"
        checked={table.isChecked}
        value={table.id}
        className="rounded bg-gray-200 border-transparent focus:border-transparent text-primary focus:ring-1 focus:ring-offset-2 focus:ring-gray-500 mt-1 mr-6"

      />

  );
};

export default CheckBox;
