import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { KitchenSaturationModalPopup } from './KitchenSaturationContainers';
import { DelayCard } from './DelayCard';
import { PauseCard } from './PauseCard';
import { Context as AuthContext } from '../../../../data/authContext';
import { SelectInput } from 'common/Input';
import moment from 'moment';
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';

export const KitchenSaturationModal = ({mutate, ...popupProps}) => {
  const { state: { selectedRestaurant }, setSelectedRestaurant } = useContext(AuthContext);
  
  const { t } = useTranslation();

  const initialDraft = {
    restaurant: selectedRestaurant.length === 1 ? selectedRestaurant[0] : null,
    type: null,
    duration_minutes: 10,
    delay_minutes: 10,
    is_duration_undefined: false,
  };

  const [saturationDraft, setSaturationDraft] = useState(initialDraft);

  const [isLoading, setIsLoading] = useState(false);

  const isSaveDiabled = !saturationDraft.restaurant || !saturationDraft.type;

  const editSaturationDraft = partialSaturationDraft => {
    setSaturationDraft(prev => ({...prev, ...partialSaturationDraft}));
  };

  const saveSaturation = async () => {
    if (isSaveDiabled) return; 

    setIsLoading(true);
    
    const {restaurant, type, duration_minutes, is_duration_undefined, delay_minutes} = saturationDraft;

    const calcEndTime = () => {
      if (type === 'pause' && is_duration_undefined) return null;
      
      return moment().add(duration_minutes, 'm').toISOString();
    };

    const body = {
      restaurant_id: Number(restaurant.value),
      type,
      delay_minutes,
      end_time: calcEndTime(),
    };

    try {
      const res = await baseAuth.post('/restaurant/add_kitchen_saturation', body);
      setSaturationDraft(initialDraft);
      setSelectedRestaurant([restaurant]);
      toast.success(t('saturation_updated'));
      popupProps.close();
      mutate();
    } catch (error) {
      console.log(`🐉 > file: KitchenSaturationModal.jsx:59 > saveSaturation > error:`, error);
      toast.error(t('saturation_error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    editSaturationDraft({restaurant: selectedRestaurant.length === 1 ? selectedRestaurant[0] : null});
  }, [selectedRestaurant]);

  return (
    <KitchenSaturationModalPopup {...popupProps}>
      <header>
        <h3>{t('kitchen_saturated')}</h3>
      </header>
      {selectedRestaurant.length > 1 &&
        <SelectInput
          value={saturationDraft.restaurant}
          onChange={value => editSaturationDraft({restaurant: value})}
          options={selectedRestaurant}
          className='restaurant_select'
          placeholder={t('select_restaurant')}
        />
      }
      <h4>{t('select_and_save')}</h4>
      <div className={`saturation_options ${!saturationDraft.restaurant ? 'disabled' : ''}`}>
        <DelayCard
          editSaturationDraft={editSaturationDraft}
          saturationDraft={saturationDraft}
        />
        <PauseCard
          editSaturationDraft={editSaturationDraft}
          saturationDraft={saturationDraft}
        />
      </div>
      <p className='info'>
        <Trans i18nKey='clients_will_be_inform_in_webapp'>
          <b>Le informaremos al comensal</b> a través de la webapp
        </Trans>
      </p>
      <footer>
        <Button
          label={'cancel'}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={popupProps.close}
        />
        <Button
          label={'save'}
          loading={isLoading}
          disabled={isSaveDiabled}
          onClick={saveSaturation}
        />
      </footer>
    </KitchenSaturationModalPopup>
  );
};
