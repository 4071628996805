import { logToServer } from "logic/functions";
import { toast } from 'react-toastify';
import { orderAndPay } from "logic/api";
import { useTranslation } from "react-i18next";

export const useSynchronize = ({ selectedRestaurantId, isSynchronizing, setIsSynchronizing, mutate }) => {
	const { t } = useTranslation();

	return async () => {
		if (selectedRestaurantId && !isSynchronizing) {
			try {
				setIsSynchronizing(true);

				try {
					const msg = "Calling /synchronize with body={'restaurant': " + selectedRestaurantId.toString() + "}";
					logToServer(msg, selectedRestaurantId);
				} catch (e) { }

				await orderAndPay.post(`/synchronize`, { 'restaurant': selectedRestaurantId }).then((response) => {
					if (!response.data.generation_id) {
						mutate();
					} else {
						toast.info("Petición realizada. Vuelve en 10 minutos a comprobar si se ha realizado la sincronización");
					}
					setIsSynchronizing(false);
				}, (error) => {
					try {
						const msg = "/synchronize call error:" + error.message;
						logToServer(msg, selectedRestaurantId);
						console.log("🚀 ~ file: OrderAndPay.jsx:230 ~ awaitorderAndPay.post ~ msg:", msg);
					} catch (e) {
						console.log("/synchronize call error catch: " + e.toString());
					}
					setIsSynchronizing(false);
					console.log("🚀 ~ file: OrderAndPay.jsx:228 ~ awaitorderAndPay.post ~ error:", error);
					toast.error(`${t('Error_synchronize')}`);
				}).catch(err => {
					try {
						const msg = "/synchronize call err:" + err.message;
						console.log("🚀 ~ file: OrderAndPay.jsx:239 ~ awaitorderAndPay.post ~ msg:", msg);
						logToServer(msg, selectedRestaurantId);
					} catch (e) {
						console.log("/synchronize call err catch: " + e.toString());
					}
					setIsSynchronizing(false);
					toast.error(`${t('Error_synchronize')}`);
				});
				setIsSynchronizing(false);
			} catch (e) {
				console.log(e);
				setIsSynchronizing(false);
				toast.error(`${t('Error_synchronize')}`);
				const msg = "synchronize catch: " + e.toString();
				logToServer(msg, selectedRestaurantId);
			}
		}
	};
};
