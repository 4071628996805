import { toast } from "react-toastify";
import { LOCKER_COLORS } from "./constants";
import { baseAuth } from "logic/api";
import { addMinutes } from "date-fns";

export const getPickUpTime = order => {
  if (!order || (!order.ready_at && !order.modified_at)) return null;

  if (order.ready_at && order.modified_at) {
    const readyAt = new Date(order.ready_at);
    const modifiedAt = new Date(order.modified_at);

    const pickUptTime = readyAt > modifiedAt ? readyAt : modifiedAt;

    return addMinutes(pickUptTime, 15);
  }

  return addMinutes(new Date(order.ready_at || order.modified_at), 15);
};

export const isOrderExpired = (order, millisecLeft) => {
  if (!order) return false;

  if (typeof millisecLeft === 'number') return millisecLeft <= 0;

  const pickUpTime = getPickUpTime(order);

  return !!pickUpTime && pickUpTime < new Date;
};

export const lockerSorter = (a, b) => {
  if (a.counter && !b.counter) return -1;
  if (!a.counter && b.counter) return 1;
  if (a.active && !b.active) return -1;
  if (!a.active && b.active) return 1;
  return a.id - b.id;
};

export const getLockerColor = (locker, millisecLeft) => {
  if (!locker || !locker.active) return LOCKER_COLORS.DISABLED;
  if (locker.counter) return LOCKER_COLORS.COUNTER;
  if (!locker.order) return LOCKER_COLORS.EMPTY;
  if (isOrderExpired(locker.order, millisecLeft)) return LOCKER_COLORS.EXPIRED;
  return LOCKER_COLORS.FULL;
};

export const getEmptyLockersOptions = ({lockers, excludeCounter = false}) => {
  return lockers
    .filter(locker => locker.active && (!excludeCounter || !locker.counter) && (!locker.order || isOrderExpired(locker.order)))
    .sort(lockerSorter)
    .map(locker => ({
      label: locker.name,
      value: locker.id,
      color: getLockerColor(locker),
    }));
};

export const getOptionFromOrder = order => {
  if (!order) return null;

  return {
    code: order.code,
    ready_at: order.ready_at,
    value: order.id,
  };
};

// Endpoints

export const moveOrdertoLocker = async ({order, locker, moveOrder, t}) => {
  try {
    const res = await baseAuth.post(`/locker/move_order`, {order, locker});
    moveOrder({ order: res.data.order_request, locker: res.data.locker, modified_at: res.data.modified_at});
  } catch (error) {
    toast.error(t('order_locker_error'));
    console.error(error);
  }
};