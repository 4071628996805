import React,{useState} from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { TrashIcon, PlusIcon } from '@heroicons/react/outline';
import RoundButton from "./RoundButton";
import Select from 'react-select';
import { categories_select_styles } from "logic/defaults";

export const CustomGroupScheduleTimeRangeSettings = ({customGroupDraft, setEditSchedule, editSchedule}) => {
  const { t } = useTranslation();
  const weekArray = [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')];
  const HOURS = 24;
  const QUARTER_HOURS = HOURS * 4;
  const toHourSting = num => {
    const string = num.toString().padStart(4, '0');
    const hour = string.substring(0, 2);
    const min = string.substring(2);

    return `${hour}:${min}`;
  };

  const getQuarterHours = num => {
    const hour = Math.floor(num / 4) * 100;
    const min = (60 / 4) * (num % 4);
    return hour + min;
  }
  const options = new Array(QUARTER_HOURS + 1).fill(null).map((_, index) => ({
    value: getQuarterHours(index),
    label: toHourSting(getQuarterHours(index)),
  }));

  const handleRemove = (day) => {
    let newEditSchedule = JSON.parse(JSON.stringify(editSchedule));
    let index = newEditSchedule.schedule.days.indexOf(day);
    newEditSchedule.schedule.days.splice(index, 1);
    setEditSchedule(newEditSchedule)
  }

  const handleAdd = (day) => {
    let newEditSchedule = JSON.parse(JSON.stringify(editSchedule));
    newEditSchedule.schedule.days.push(day);
    newEditSchedule.schedule.days.sort()
    setEditSchedule(newEditSchedule)
  }

  const handleChangeHour = (attr, option, range_id) => {
    let newEditSchedule = JSON.parse(JSON.stringify(editSchedule));
    let editTimeRange = newEditSchedule.time_range.find(t_r => t_r.id == range_id);
    editTimeRange[attr] = option.label.split(':').map(str => Number(str))
    setEditSchedule(newEditSchedule)
  }

  const handleDeleteRange = (range_id) => {
    let newEditSchedule = JSON.parse(JSON.stringify(editSchedule));
    newEditSchedule.time_range = newEditSchedule.time_range.filter(e_s_tr => e_s_tr.id != range_id)
    setEditSchedule(newEditSchedule)
  }

  const handleAddRange = () => {
    let newEditSchedule = JSON.parse(JSON.stringify(editSchedule));
    newEditSchedule.time_range.push({"id": null, "from": [0, 0], "to": [24, 0]})
    setEditSchedule(newEditSchedule)
  }

  const HourSelector = ({name, range, filter}) => {
    const hour = range[name][0] || 0;
    const min = range[name][1] || 0;
    const value = options.find(option => option.value === hour * 100 + min)
    return (
      <div style={{userSelect: 'none'}}>
      <Select
        value={value}
        name={name}
        placeholder={t(`Select one option`)}
        onChange={option => handleChangeHour(name, option, range.id)}
        options={options.filter(filter)}
        styles={categories_select_styles}
        menuPortalTarget={document.body}
        closeMenuOnSelect
      />
      </div>
    );
  };

  const startFilter = (option, range) => option.value < ((range["to"][0] || 0) * 100 + (range["to"][1] || 0))
  const endFilter = (option, range) => option.value > ((range["from"][0] || 0) * 100 + (range["from"][1] || 0))

  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div>
        <div style={{marginTop: "15px"}}>Selecciona los días: </div>
        <div style={{display: 'flex', gap: '5px', marginTop: "15px"}}>
          {weekArray.map((day, index) => {
            if (editSchedule.schedule.days.indexOf(index) !== -1) {
              return (
                <RoundButton onClick={() => handleRemove(index)} bgColor={'#EA9F89'} textColor={'#FFFFFF'} text={day} />
              );
            } else {
              return (
                <RoundButton onClick={() => handleAdd(index)} bgColor={'#FFFFFF'} textColor={'#EA9F89'} text={day} border={'border-[#EA9F89] border-[2px] '} />
              );
            }
          })}
        </div>
        <div style={{marginTop: '35px', fontSize: '14px', gap: "15px", display: 'flex', flexDirection: 'column'}}>
        {editSchedule.time_range.map((range, index) => (
        <div style={{display: 'flex', alignItems: 'center', gap: '25px'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <div style={{textTransform: 'capitalize'}}>{t('from')}</div>
            <div style={{width: '200px'}}>
              <HourSelector name={'from'} range={range} filter={option => startFilter(option, range)}/>
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <div style={{textTransform: 'capitalize', fontSize: '14px'}}>{t('To')}</div>
            <div style={{width: '200px', fontSize: '14px'}}>
              <HourSelector name={'to'} range={range} filter={option => endFilter(option, range)}/>
            </div>
          </div>
          <TrashIcon onClick={() => handleDeleteRange(range.id)} height={24} color={'#999999'} className="cursor-pointer flex-shrink-0"/>
        </div>
      ))}
      <button
          style={{marginTop: '10px', width: '45%', fontSize: "13px"}}
          onClick={() => handleAddRange()}
          className='text-principal font-medium border-dashed border-2 border-gray-300 rounded-lg flex justify-center items-center p-1 gap-1 h-9'>
          <PlusIcon height={18}/>
          {t('add_time_range')}
        </button>
        {editSchedule.time_range.length < 1 &&
          <div style={{fontSize: '13px'}}>⚠️ Debes añadir una franja horaria</div>}
        </div>
      </div>
    </div>
  )
}
