import React from 'react';
import { useTranslation } from "react-i18next";
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/solid';

const WalletUsersTableHeader = ({opacity, sort, setSort, field, setField}) => {

  const { t } = useTranslation();

  const handleSort = (fieldName) => {
    if (field === fieldName) {
      setSort(sort === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setField(fieldName);
      setSort('ASC');
    }
  };

  const getArrowIcon = (fieldName) => {
    if (field === fieldName) {
      return sort === 'ASC' ? <ArrowDownIcon className=' w-6 h-5 text-[#5FB894]' /> : <ArrowUpIcon className='w-6 h-5'/>;
    }
    return <ArrowUpIcon className='w-6 h-5'/>;
  };

  return (
    <div className={`${opacity ? 'opacity-20' : ''}`} style={{display: 'grid', gridTemplateColumns: "1.25fr 1fr 1fr .5fr 1fr .75fr", textAlign: 'center', backgroundColor: "#FCFCFC", justifyContent: 'space-around', fontSize: "17px", fontWeight: 500, alignItems: 'center', borderBottom: "2px solid #D1D1D1"}}>
		<div style={{minWidth: "240px", padding: "0 10px"}}>{t('user')}</div>
		<div style={{padding: "0 10px", minHeight: "80px", alignContent: 'center', display: '-webkit-inline-box', WebkitBoxPack: 'justify'/*, maxInlineSize: 'min-content'*/, WebkitBoxAlign: 'center', cursor: 'pointer'}} onClick={() => handleSort('client_credit__balance')} className={`${sort !== null && field === 'client_credit__balance' ? 'font-bold bg-[#F0FDF8]' : ''}`}>
			{getArrowIcon('client_credit__balance')}{t('total_credits_available')}
		</div>
		<div style={{padding: "0 10px", minHeight: "80px", alignContent: 'center', display: '-webkit-inline-box', WebkitBoxPack: 'justify'/*, maxInlineSize: 'min-content'*/, WebkitBoxAlign: 'center', cursor: 'pointer'}} onClick={() => handleSort('total_incentive')} className={`${sort !== null && field === 'total_incentive' ? 'font-bold bg-[#F0FDF8]' : ''}`}>
			{getArrowIcon('total_incentive')}{t('incentive_obtained')}
		</div>
		<div style={{padding: "0 10px"}}>#{t('recharges')}</div>
		<div style={{padding: "0 10px"}}>{t('last_recharge')}</div>
		<div style={{padding: "0 10px"}}>#{t('orders_with_credit')}</div>
	</div>
  );
};
export default WalletUsersTableHeader;
