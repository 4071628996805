import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import '../../../../styles/customFilepond.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { registerPlugin } from 'react-filepond';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../../data/authContext';
import { apiAuth } from 'logic/api';
import { sayError } from 'logic/defaults';
import { CoverPreview, HomePreview, MobileMockup } from 'components/previews';
import Spinner from 'common/Spinner';
import { HEX_REGEX, getContrastRatio } from 'styles/colors';
import { ImagesSettings } from './ImagesSettings';
import { ColorSettings, MIN_CONTRAST } from './ColorSettings';
import { mockThemes } from 'components/previews/constants';

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

export const SettingsCustomization = () => {
  const { t } = useTranslation();
  const {state: { selectedRestaurantId }} = useContext(AuthContext);

  const [data, setData] = useState(null);
  const [logo, setLogo] = useState(null);
  const [homeImage, setHomeImage] = useState(null);
  const [theme, setTheme] = useState('light');
  const [colors, setColors] = useState({primary: '#5FB894', secondary: '#FFFFFF', tertiary: null});
  const [photoOverlay, setPhotoOverlay] = useState(true);
  const [logoWidth, setLogoWith] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const areColorsValid = useMemo(() => {
    const bgColor = mockThemes[theme].background_rised;

    const checkColor = (color) => HEX_REGEX.test(color) && getContrastRatio(color, bgColor) >= MIN_CONTRAST;

    const isPrimaryValid = checkColor(colors.primary);
    const isTertiaryValid = colors.tertiary ? checkColor(colors.tertiary) : true;

    return (isPrimaryValid && isTertiaryValid);
  }, [theme, colors]);

  const getData = async (id) => {
    setIsLoading(true);
    try {
      const { data: newData } = await apiAuth(`/restaurants/${id}/`, {method: 'GET'});

      setData(newData);
      setLogo({file: newData?.logo});
      setHomeImage({file: newData?.home_image});
      setPhotoOverlay(newData?.photo_overlay);
      setLogoWith(newData?.logo_width || null);
      setTheme(newData?.theme || 'light')
      setColors({
        primary: newData?.primary_color,
        secondary: newData?.secondary_color,
        tertiary: newData?.tertiary_color || null,
      });
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    } finally {
      setIsLoading(false);
    }
  };

  const changeImage = (type, file) => {
    const fd = file ? new FormData() : {[type]: null};
    const url = file ? URL.createObjectURL(file) : null;
    file && fd.append(type, file);

    type === 'logo' && setLogo({file, fd, url});
    type === 'home_image' && setHomeImage({file, fd, url});
  };

  const onLogoWidthChange = event => setLogoWith(validateLogoWidth(event.target.value));

  const togglePhotoOverlay = () => setPhotoOverlay(prev => !prev);

  const onThemeChange = themeOption => setTheme(themeOption.value);

  const onColorChange = color => setColors(prev => ({...prev, ...color}));

  const uploadImage = async (fd) => {
    try {
      await apiAuth(`/restaurants/${selectedRestaurantId}/`, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: fd,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const validateLogoWidth = (value) => {
    const width = !value ? null : Math.min(Math.abs(Number(value)), 100)
    
    if (data.logo_width === width) {
      return null;
    }

    if (typeof width !== 'number' && width !== null) {
      toast.error(t('invalid_value'))
      return null;
    }

    return width;
  };

  const save = async () => {
    if (isLoading || isSaving) return;
    
    setIsSaving(true);

    const newData = {};

    const validWidth = validateLogoWidth(logoWidth);
    validWidth && (newData.logo_width = logoWidth);

    theme !== data.theme && (newData.theme = theme);
    photoOverlay !== data.photo_overlay && (newData.photo_overlay = photoOverlay);

    Object.entries(colors).forEach(([color, value]) => {
      if (data[`${color}_color`] === value) return;
      newData[`${color}_color`] = value;
    });
    
    logo.file !== data?.logo && logo?.fd && uploadImage(logo.fd);
    homeImage.file !== data?.home_image && homeImage?.fd && uploadImage(homeImage.fd);
    
    if (!Object.keys(newData).length) {
      setIsSaving(false);
      return
    };

    try {
      await apiAuth(`/restaurants/${selectedRestaurantId}/`, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: newData,
      });
      toast.success(t('changes_applied'));
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    selectedRestaurantId && getData(selectedRestaurantId);
  }, [selectedRestaurantId]);

  return (
    <div className='flex gap-6 relative mb-8'>
      {isLoading &&
        <>
          <div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center bg-gray-100 bg-opacity-50">
            <Spinner>{t("Loading")}</Spinner>
          </div>
        </>
      }
      <div className='flex flex-col gap-6 flex-1'>
        <div className='bg-white rounded-2xl w-full py-4 px-6'>
          <h2 className='font-semibold text-xl mb-6'>
            {t('home_customization')}
          </h2>
          <ImagesSettings
            logo={logo}
            logoWidth={logoWidth}
            homeImage={homeImage}
            photoOverlay={photoOverlay}
            changeImage={changeImage}
            onLogoWidthChange={onLogoWidthChange}
            togglePhotoOverlay={togglePhotoOverlay}
          />
          <hr className='my-7'/>
          <ColorSettings
            data={data}
            theme={theme}
            colors={colors}
            onThemeChange={onThemeChange}
            onColorChange={onColorChange}
          />
        </div>
      </div>
      <div className='flex flex-col gap-6 items-start'>
        <h2 className='font-semibold text-xl'>
          {t('preview')}
        </h2>
        <div className='flex gap-6'>
          <div>
            <h4 className='text-center font-semibold text-sm mb-4'>
              {t('cover')}
            </h4>
            <MobileMockup>
              <CoverPreview color={colors.secondary} logo={logo?.url}/>
            </MobileMockup>
          </div>
          <div>
            <h4 className='text-center font-semibold text-sm mb-4'>
              {t('home')}
            </h4>
            <MobileMockup>
              <HomePreview themeName={theme} colors={colors} logo={logo?.url} homeImage={homeImage?.url} logoWidth={logoWidth} photoOverlay={photoOverlay}/>
            </MobileMockup>
          </div>
        </div>
        <button
          onClick={save}
          disabled={isLoading || isSaving || !areColorsValid}
          className='w-44 font-bold text-white text-sm bg-principal rounded-full p-3 disabled:opacity-50 disabled:cursor-not-allowed'
        >
          {t('save')}
        </button>
      </div>
    </div>
  );
};
