import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSVDownload } from "react-csv";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { thousandsSeparator, centsToAmountString } from "logic/functions";
import { Context as AuthContext } from "../../../data/authContext";
import { apiAuth } from "logic/api";
import Spinner from "common/Spinner";
import * as dayjs from "dayjs";
import { DatesFilter } from "components/yumminn/DatesFilter";
import useReviewFilter from "components/yumminn/useReviewFilter";
import useRestaurant from "components/yumminn/useRestaurant";
import { order } from "tailwindcss/defaultTheme";
import useModal from 'components/hooks/useModal';
import DataSelect from "components/yumminn/DataSelect";
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import CompanySelect from "components/yumminn/CompanySelect";
import useCompany from "components/yumminn/useCompany";
import AccountManagerSelect from "components/yumminn/AccountManagerSelect";
import DateRangeSelect from "components/yumminn/DateRangeSelect";

export function subtractDays(date) {
  date.setDate(date.getDate() - 7);
  return date;
}

export const Reports = () => {
  const { seleccionado, start, end } = useRestaurant();
  const { companySelected } = useCompany();
  const { activo } = useReviewFilter();
  const { t } = useTranslation();
  const infoModal = useModal();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [accountManagerSelected, setAccountManagerSelected] = React.useState({id: null, name: t("Todos")})
  const [orderByDate, setOrderByDate] = useState();
  const [column, setColumn] = useState("payments");
  const [sort, setSort] = useState("ASC");  
  const [reportsData, setReportsData] = useState();
  const [datesInRange, setDatesInRange] = useState();
  const [headers, setHeaders] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingTopRestaurants, setLoadingTopRestaurants] = useState(false);
  const [csvRestaurants, setCsvRestaurants] = useState([]);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const { state: selectedReviewFilter, setSelectedReviewFilter } = React.useContext(AuthContext);
  const selectedDate = JSON.parse(sessionStorage.getItem("yumminn-selectedDate"));
  const date = new Date(selectedDate);
  subtractDays(date);
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
  const selectedDateTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))
  const endDate = selectedDateTo ? new Date(selectedDateTo) : yesterday;
  const offset = date.getTimezoneOffset();
  const dateEnd = new Date(endDate.getTime() - (offset*60*1000)).toISOString().split('T')[0];
  const dateStart = new Date(date.getTime() - (offset*60*1000)).toISOString().split('T')[0];
  const csvInstance = useRef(null);
  const numRestaurants = 25;
  const selectedYear = JSON.parse(sessionStorage.getItem("yumminn-selectedYear"))
  const selectedYearTo = JSON.parse(sessionStorage.getItem("yumminn-selectedYearTo"))
  const selectedMonth = JSON.parse(sessionStorage.getItem("yumminn-selectedMonth"))
  const selectedMonthTo = JSON.parse(sessionStorage.getItem("yumminn-selectedMonthTo"))
  const selectedStartTime = sessionStorage.getItem('yumminn-selectedStartTime')
  const selectedEndTime = sessionStorage.getItem('yumminn-selectedEndTime')   

  let counter = numRestaurants * page - numRestaurants;

  useEffect(() => {
    let headersList = [];
    headersList.push({ label: "Restaurant", key: "restaurant" })
    datesInRange && datesInRange.map((date, index) => 
      headersList.push({key: date, label: date})
    )
    setHeaders(headersList)
  }, [datesInRange])

  const getReports = async ({allRestaurants,to_date,from_date}) => {
    let obj = {};

    //if downloading CSV
    if (allRestaurants) {
      obj = {
        from: from_date,
        to: to_date,
        period: activo,
        column: column,
        date: orderByDate,
        order: sort,
        page: page,
        restaurant: seleccionado,
        company: companySelected,
        account_manager: accountManagerSelected

      };
    } else {
      obj = {
        from: from_date,
        to: to_date,
        period: activo,
        date: orderByDate,
        column: column,
        order: sort,
        page: page,
        restaurants: numRestaurants,
        restaurant: seleccionado,
        company: companySelected,
        account_manager: accountManagerSelected
      };
    }

    try {
      const results = await apiAuth.post(`/payments/reports`, obj);
      setLoadingTopRestaurants(false);

      if (!allRestaurants) {
        setReportsData(results.data.result);
        setLoading(false)
        const newDates = []
        let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        let newDate = new Date();
        const dates = results.data.result.restaurants[0][1]["payments"]
        if (period === 'days'){
          Object.keys(dates).map((d) => {
            newDate = days[ new Date(d).getDay() ]
            return(
              newDates.push(t(newDate) + ", " + d.substring(8,10) + '/' + d.substring(5,7) + '/' + d.substring(2,4))
            )
          })
        } else if (period === 'month'){
          Object.keys(dates).map((d) => {
            newDate = months[ parseInt(d.substring(0,2)) - 1 ]
            return(
              newDates.push(t(newDate) + ' ' + d.substring(3))
            )
          })
        } else {
          Object.keys(dates).map((d) => {
            return(
              newDates.push(d)
            )
          })
        }
        setDatesInRange(newDates)
      } else {
        return new Promise((resolve) => {
          let data = [];

          let restaurants = results.data.result.restaurants;
          let allRestaurants = results.data.result.all_restaurants[column];
          let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
          let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
          let newDate = new Date();

          if (restaurants) {
          
            restaurants.forEach((item) => {
              let payments = [];
              if(column === 'transactions' || column === 'reviews' || column === 'avg_rating' ||  column === 'percentage_payments_with_reviews'){
                Object.keys(item[1][column]).map((date, index) => {
                  newDate = activo === 'days' ? days[ new Date(date).getDay() ] : activo === 'monts' ? months[ parseInt(date.substring(0,2)) - 1 ] : date
                  newDate = activo === 'days' ? t(newDate) + ", " + date.substring(8,10) + '/' + date.substring(5,7) : activo === 'monts' ? t(newDate) + ' ' + date.substring(3) : date
                  return(
                    payments[newDate] = column !== 'percentage_payments_with_reviews' ? handleToFixed(item[1][column][date]) : handleToFixed(item[1][column][date]) + '%'
                  )
                })
              } else if (column === 'payments'){
                Object.keys(item[1][column]).map((date, index) => {
                  newDate = activo === 'days' ? days[ new Date(date).getDay() ] : activo === 'monts' ? months[ parseInt(date.substring(0,2)) - 1 ] : date
                  newDate = activo === 'days' ? t(newDate) + ", " + date.substring(8,10) + '/' + date.substring(5,7) : activo === 'monts' ? t(newDate) + ' ' + date.substring(3) : date
                  return(
                    payments[newDate] = parseInt(centsToAmountString(item[1][column][date])) + "€"
                  )
                })
              } else {
                Object.keys(item[1][column]).map((date, index) => {
                  newDate = activo === 'days' ? days[ new Date(date).getDay() ] : activo === 'monts' ? months[ parseInt(date.substring(0,2)) - 1 ] : date
                  newDate = activo === 'days' ? t(newDate) + ", " + date.substring(8,10) + '/' + date.substring(5,7) : activo === 'monts' ? t(newDate) + ' ' + date.substring(3) : date
                  return(
                    payments[newDate] = handleToFixed(parseFloat(centsToAmountString(item[1][column][date]))) + "€"
                  )
                })
              }
              let restaurant = {
                restaurant: item[0],
                ...payments,
              };
              data.push(restaurant);
            });
          }
          if (allRestaurants){
            let payments = []; 
            if(column === 'transactions' || column === 'reviews' || column === 'avg_rating' ||  column === 'percentage_payments_with_reviews'){
              Object.keys(allRestaurants).forEach((item) => {
                newDate = activo === 'days' ? days[ new Date(item).getDay() ] : activo === 'monts' ? months[ parseInt(item.substring(0,2)) - 1] : item
                newDate = activo === 'days' ? t(newDate) + ", " + item.substring(8,10) + '/' + item.substring(5,7) : activo === 'monts' ? t(newDate) + ' ' + item.substring(3) : item
                payments[newDate] = column !== 'percentage_payments_with_reviews' ? handleToFixed(allRestaurants[item]) : handleToFixed(allRestaurants[item]) + '%'
              });
            } else if (column === 'payments'){
              Object.keys(allRestaurants).forEach((item) => {
                newDate = activo === 'days' ? days[ new Date(item).getDay() ] : activo === 'monts' ? months[ parseInt(item.substring(0,2)) - 1] : item
                newDate = activo === 'days' ? t(newDate) + ", " + item.substring(8,10) + '/' + item.substring(5,7) : activo === 'monts' ? t(newDate) + ' ' + item.substring(3) : item
                payments[newDate] = parseInt(centsToAmountString((allRestaurants[item]))) + "€"
              });
            } else {
              Object.keys(allRestaurants).forEach((item) => {
                newDate = activo === 'days' ? days[ new Date(item).getDay() ] : activo === 'monts' ? months[ parseInt(item.substring(0,2)) - 1] : item
                newDate = activo === 'days' ? t(newDate) + ", " + item.substring(8,10) + '/' + item.substring(5,7) : activo === 'monts' ? t(newDate) + ' ' + item.substring(3) : item
                payments[newDate] = handleToFixed(parseFloat(centsToAmountString((allRestaurants[item])))) + "€"
              });
            }
            let restaurant = {
              restaurant: "TOTAL",
              ...payments,
            };
            data.push(restaurant);
          }

          setLoadingCsv(false);
          setCsvRestaurants(data);
          resolve();
        });
      }
    } catch (error) {
      setLoadingTopRestaurants(false);
      setLoadingCsv(false);
      console.log(`error`, error);
    }
  };

  const getAllRestaurants = async (event, done) => {
    if (csvRestaurants.length > 0) setCsvRestaurants([]);
    setLoadingCsv(true);

    column && await getReports(
        {
            allRestaurants: true, 
            from_date:getDates().from_date, 
            to_date:getDates().to_date,
            column: column,
            restaurant: seleccionado,
            company: companySelected,
            account_manager: accountManagerSelected
        }
    );
  };

  const dateStyle = (date) => {
    if(period === 'days' && reportsData){
      let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      let newDate = new Date();
      let style = "";
      const dates = reportsData.restaurants[0][1]["payments"]
      Object.keys(dates).map((d) => {
        newDate = days[ new Date(d).getDay() ]
        if(newDate && date.includes(t(newDate))) {
          date = newDate
          style = "text-[#E18684]"
        }
      })
      if(date === 'Saturday' || date === 'Sunday') {
        return style
      }
    }
  }

  const headerStyle = (date) => {
    if(orderByDate){
      const arraySplit = activo === 'days' ? date.split(",") : ""
      const numberMonth = activo === 'month' ? date.split(" ") : ""
      const newDate = activo === 'days' && arraySplit ? ("20"+arraySplit[1].substring(7,10)+"-" + arraySplit[1].substring(4,6) + '-' + arraySplit[1].substring(1,3)) :  ((parseInt(getMonthNumberFromName(numberMonth[0])) + 1).toString().length === 1 ? "0" : "") + ((parseInt(getMonthNumberFromName(numberMonth[0])) + 1).toString() + "-" + numberMonth[1])
      if (orderByDate === newDate) {
        return `h-5 w-5 transition ease-in-out delay-150 z-1 transform ${
          sort === "DESC" && "rotate-180"
        } text-[#00A106]`;
      }
      return `h-5 w-5 transition ease-in-out delay-150 z-1 transform ${dateStyle(date)}`;
    } else {
      if (datesInRange[0] === date) {
        return `h-5 w-5 transition ease-in-out delay-150 z-1 transform ${
          sort === "DESC" && "rotate-180"
        } text-[#00A106]`;
      }
      return `h-5 w-5 transition ease-in-out delay-150 z-1 transform ${dateStyle(date)}`;
    }
  };

  function getMonthNumberFromName(monthName) {
    const months = {
      "Enero": 0,
      "Febrero": 1,
      "Marzo": 2,
      "Abril": 3,
      "Mayo": 4,
      "Junio": 5,
      "Julio": 6,
      "Agosto": 7,
      "Septiembre": 8,
      "Octubre": 9,
      "Noviembre": 10,
      "Diciembre": 11
    }
    let monthNumber = months[monthName];
    //let monthNumber = new Date(`${monthName} 1, 1999`).getMonth().toString();
    return monthNumber;
  }

  const sortTable = (date) => {
    const arraySplit = activo === 'days' ? date.split(",") : ""
    const numberMonth = activo === 'month' ? date.split(" ") : ""
    const newDate = activo === 'days' && arraySplit ? ("20"+arraySplit[1].substring(7,10)+"-" + arraySplit[1].substring(4,6) + '-' + arraySplit[1].substring(1,3)) :  ((parseInt(getMonthNumberFromName(numberMonth[0])) + 1).toString().length === 1 ? "0" : "") + ((parseInt(getMonthNumberFromName(numberMonth[0])) + 1).toString() + "-" + numberMonth[1])
    if (orderByDate === newDate) {
      if (sort === "ASC") {
        setSort("DESC");
      } else {
        setSort("ASC");
      }
    } else {
      if (sort === "ASC") {
        setSort("DESC");
      } else {
        setSort("ASC");
      }
    }

    
    setOrderByDate(newDate);
  };

  const handleToFixed = (number) => {
    if (!number) return "0";
    if (number >= 1000) {
      return thousandsSeparator(number);
    }
    if (number > 0) {
      return number.toFixed(2).replace(/\./g, ",");
    }
  };

  const getCell = ({ number, percentage, type }) => {
    if (number > 0) {
      if (type === "euros") {
        if (!percentage) {
          return (
            <div>
              {handleToFixed(parseFloat(centsToAmountString(number)))} €
            </div>
          );
        }

        let amountString = centsToAmountString(number);
        let amountInt = parseInt(amountString);
        let amountFixed = handleToFixed(amountInt);
        let amountWithoutDecimals = amountFixed.replace(/,00/g, "");
        let amountFinal = amountWithoutDecimals + " €";
        
        return (
          <div>
            {amountFinal} 
          </div>
        );
      } else if(type === 'percentage'){
        return (
          <div>
            {handleToFixed(number).replace(/,00/g, "") + '%'} 
          </div>
        );
      } else {
        return (
          <div>
            {handleToFixed(number).replace(/,00/g, "")} 
          </div>
        );
      }
    
    } else {
      if (type === "euros") {
        return <div>0€</div>;
      } else if (type === "percentage"){
        return <div>0%</div>;
      }
      return <div>0</div>;
    }
  };

  const getDates=() =>{
    let from_date;
    let to_date;
    
    const offsetMins = new Date().getTimezoneOffset();
    const date = new Date(new Date().getTime() - offsetMins * 60 * 1000);
    const stringDate = sessionStorage.getItem("yumminn-selectedDate")
      ? sessionStorage.getItem("yumminn-selectedDate").substring(1, 11)
      : date.toISOString().substring(0, 10);

      switch (activo) {
        case 'month':
            const startMonth = selectedMonth ? new Date(selectedMonth).getMonth() : new Date().getMonth()-12;
            const startYearMonth = selectedMonth ? new Date(selectedMonth).getFullYear() : new Date().getFullYear()-1;
            const fromDateMonth = dayjs().set('month', startMonth).set('year', startYearMonth).startOf('month').format('YYYY-MM-DD');
            const endMonth =  selectedMonthTo ? new Date(selectedMonthTo).getMonth() : new Date().getMonth();
            const endYearMonth = selectedMonthTo ? new Date(selectedMonthTo).getFullYear() : new Date().getFullYear();
            const toDateMonth = dayjs().set('month', endMonth).set('year', endYearMonth).endOf('month').format('YYYY-MM-DD')
            from_date = fromDateMonth;
            to_date =  toDateMonth;
            break;

        case 'year':
            const startYear = selectedYear ? new Date(selectedYear).getFullYear() : new Date().getFullYear();
            const fromDateYear= dayjs().startOf('year').set('year', startYear).format('YYYY-MM-DD');
            const endYear =  selectedYearTo ? new Date(selectedYearTo).getFullYear() : new Date().getFullYear();
            const toDateYear = dayjs().endOf('year').set('year', endYear).format('YYYY-MM-DD')
            from_date = fromDateYear;
            to_date = toDateYear;
            break;

        default:
            from_date = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().subtract(8, 'day').format('YYYY-MM-DD');
            to_date = dateEnd;
            break;
    }
    return {from_date,to_date};
  }

  useEffect(()=>{
    setLoadingTopRestaurants(true);
    activo && getReports({allRestaurants: false, from_date:getDates().from_date, to_date:getDates().to_date, restaurant: seleccionado, company: companySelected});

  }, [accountManagerSelected, companySelected, seleccionado, activo, end, start, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, dateStart, dateEnd, selectedDate, selectedDate, selectedStartTime, selectedEndTime, page, order, sort, column, orderByDate]);

  useEffect(()=>{
    setSort("ASC")
    setOrderByDate();
  }, [period, selectedMonth, selectedDate])


  if(reportsData && reportsData.restaurants){
    return (
      <>
        <div className={`flex flex-col items-center w-full ${!isRowBased ? " sticky top-0" : ""} bg-gray-100 `}>
          {!isRowBased && 
            <h1 className={`font-bold text-[#094553] text-[24px] px-[0px] right-0 py-5 w-full bg-[#E2FEF1] pl-8`} style={{zIndex: !isRowBased ? 9999 : ''}}>
              {t('Reports')}
            </h1>}
          <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-12 lg:px-6 rounded-lg gap-5 ${!isRowBased ? '' : ''} bg-gray-100 ${!isRowBased ? "z-9999" : ""} pt-3 pb-2`}>
            <CompanySelect />
            <RestaurantSelect accountManagerSelected={accountManagerSelected}/>
            <AccountManagerSelect accountManagerSelected={accountManagerSelected} setAccountManagerSelected={setAccountManagerSelected}/>
          </div>
          <div className={`flex flex-col lg:flex-row justify-evenly items-center w-full px-12 lg:px-6 rounded-lg  ${!isRowBased ? '' : ''} bg-gray-100 ${!isRowBased ? "z-9999" : ""} pb-8`}>
            <DataSelect setColumn={setColumn} column={column}/>
            <DateRangeSelect defaultRange="last_month"/>
            <DatesFilter doFilter={setSelectedReviewFilter}/>
            
          </div>
        </div>
        
        <div className="flex overflow-x-auto z-0 h-full w-full" >
          <div className={isRowBased ? "overflow-x-auto h-full" : "flex overflow-x-auto z-0 h-full w-full"}>
            <div
              style={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
              className="col-span-2 flex flex-col w-full"
            >
              {loadingTopRestaurants && (
                <div className="h-full w-full absolute z-9999 flex flex-col justify-center items-center">
                  <Spinner>{t("Loading")}</Spinner>
                </div>
              )}
              <table
                className={
                  loadingTopRestaurants
                    ? "bg-white rounded-md w-full border-collapse overflow-y-auto overflow-x-auto opacity-25"
                    : "bg-white rounded-md w-full border-collapse overflow-y-auto overflow-x-auto"
                }
              >
                <thead style={{zIndex: 99999}} className="w-full text-xs sticky top-0 m-0 bg-white">
                  <tr className="h-16 m-0 bg-white">
                    <th className="sticky px-5 left-0 bg-white z-999999 py-6 pb-3 w-14"></th>
                    <th className="sticky top-0 left-10 m-0 bg-white z-999999 px-4 w-1/7 text-center items-center max-w-min z-10">
                      <div className="w-full flex flex-row items-center z-99999">
                        {t("Restaurants")}
                      </div>
                    </th>
                    {/*Table headers*/}
                    {datesInRange && datesInRange.map((date, index) => 
                    <th
                      key={`th2${index}`}
                      className="px-3 w-1/7 text-center items-center"
                      onClick={() => sortTable(date)}
                    >
                      <div key={`div${index}`} 
                        className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap"
                      >
                        <svg
                          key={`svg${index}`}
                          xmlns="http://www.w3.org/2000/svg"
                          className={headerStyle(date)}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            key={`path${index}`}
                            fillRule="evenodd"
                            d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className={dateStyle(date)}>{date}</div>
                      </div>
                    </th>
                    )}
                {/*allRestaurants*/}
                  </tr>
                    <tr className=" w-full text-gray-600 bg-gray-100">
                      <th className="py-6 px-5 pb-3 w-14 sticky left-0 bg-gray-100 z-999999999"></th>
                      <th className="sticky top-0 left-10 m-0 bg-gray-100 z-999999 px-4 w-1/7 text-left">{t('Total')}</th>
                      {reportsData && column && datesInRange && Object.values(reportsData.all_restaurants[column]).map((item, index) => {
                        const type = column !== 'transactions' && column !== 'reviews' && column !== 'percentage_payments_with_reviews' && column !== 'percentage_payments_with_tips' && column !== 'avg_rating' && column !== 'payments_with_client' ? 'euros' : column === 'percentage_payments_with_reviews' || column === 'percentage_payments_with_tips' || column === 'payments_with_client' ? 'percentage' : ''
                        return (
                            <th key={`th${index}`} className="w-1/7 text-center items-center">
                              {getCell({
                                number: item,
                                type: type,
                              })}
                            </th>
                            )
                      })}
                  </tr> 
                </thead>
                {/*restaurants*/}
                <tbody className="text-sm">
                  {reportsData && datesInRange && column &&
                    reportsData.restaurants.map((item, index) => {
                      counter++;
                      return (                        
                        <tr className="bg-white rounded-md flex-grow w-full border-collapse overflow-y-auto overflow-x-auto" key={index}>
                          <td key={`td${index}`} className="sticky left-0 m-0 bg-white z-0 bg-white px-4 py-3 text-center w-14">{counter}</td>
                          <td key={`td2${index}`} className="sticky left-10 m-0 bg-white z-0 bg-white w-1/3 px-4 py-3 text-start break-words overflow-hidden">
                            {item[0]}
                          </td>
                          {Object.keys(item[1][column]).map((date, index) => {
                            const type = column !== 'transactions' && column !== 'reviews' && column !== 'percentage_payments_with_reviews' && column !== 'percentage_payments_with_tips' && column !== 'avg_rating' && column !== 'payments_with_client' ? 'euros' : column === 'percentage_payments_with_reviews' || column === 'percentage_payments_with_tips' || column === 'payments_with_client' ? 'percentage' : ''
                            return(
                            <td key={`td3${index}`} className="w-1/7 px-4 py-3 text-center whitespace-nowrap">
                                {getCell({
                                number: item[1][column][date],
                                type: type,
                                })}
                            </td>
                          )
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            
            <infoModal.Modal>
                {t('avg_ticket_explanation')}
            </infoModal.Modal> 
          </div>
        </div>
        {reportsData && (
              <div className="px-[32px] flex sm:flex-row flex-col lg:text-lg text-xs items-center justify-center w-full text-right">
                <div className="flex justify-center col-span-4 w-full space-x-4 mt-8 border-top border-grey-500 pb-7">
                  <button
                    className="disabled:text-gray-200"
                    disabled={page === 1}
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    {t("Previous")}
                  </button>
                  <div>
                    [Total registros: {reportsData.total_restaurants}
                  </div>
                  <div> /</div>
                  <div>
                    Página {page} de {reportsData.total_pages}]
                  </div>
  
                  <button
                    className="disabled:text-gray-200"
                    disabled={page === reportsData.total_pages}
                    onClick={() => {
                      if (page !== reportsData.total_pages) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    {t("Next")}
                  </button>
                </div>
                <div
                  onClick={getAllRestaurants}
                  className="rounded-full bg-[#5FB894] text-white text-base font-semibold sm:w-64 w-full p-5 h-10 flex flex-row items-center justify-center cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  {loadingCsv ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 animate-spin"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                  ) : (
                    t("Export_Csv")
                  )}
                </div>
                {csvRestaurants.length > 0 && !loadingCsv && (
                  <CSVDownload
                    data={csvRestaurants}
                    headers={headers}
                    separator={";"}
                    ref={csvInstance}
                  />
                )}
              </div>
            )}
      </>
    );
  } else {
    if(loading){
      return(
        <Spinner>{t("Loading")}</Spinner>
      )
    } else {
      return (
        <>
          <div className={`flex flex-col items-center w-full ${!isRowBased ? " sticky top-0" : ""} bg-gray-100 `}>
              {!isRowBased && 
                <h1 className={`font-bold text-[24px] px-[0px] right-0 py-5 w-full bg-[#EFF8F4] pl-8`} style={{zIndex: !isRowBased ? 9999 : ''}}>
                  {t('Reports')}
                </h1>}
              <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-12 lg:px-6 rounded-lg gap-5 ${!isRowBased ? '' : ''} bg-gray-100 ${!isRowBased ? "z-9999" : ""} pt-3`}>
                <CompanySelect />
                <RestaurantSelect accountManagerSelected={accountManagerSelected}/>
                <AccountManagerSelect accountManagerSelected={accountManagerSelected} setAccountManagerSelected={setAccountManagerSelected}/>
              </div>
              <div className={`flex flex-col lg:flex-row justify-evenly items-center w-full px-12 lg:px-6 rounded-lg  ${!isRowBased ? '' : ''} bg-gray-100 ${!isRowBased ? "z-9999" : ""}`}>
                <DataSelect setColumn={setColumn} column={column}/>
                <DateRangeSelect defaultRange="last_month"/>
                <DatesFilter doFilter={setSelectedReviewFilter}/>
                
              </div>
            </div>
          
            <div className="flex overflow-x-auto z-0 h-full w-full" >
              <div className={isRowBased ? "overflow-x-auto h-full" : "flex overflow-x-auto z-0 h-full w-full"}>
                <div
                  className="flex flex-row w-full justify-center align-center pt-40"
                >
                {t('This restaurant has no data to collect yet')}  
              </div>
            </div>
  
          </div>
        </>
      )
    }
    
  }
 
 
};
