import React, { useState, useEffect } from "react";
import { centsToAmountString, getImageUrl, toDecimalString } from "logic/functions";
import { StarIcon } from '@heroicons/react/outline'
import { Icon } from "common/Icon";

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '.1fr .5fr 1.3fr 1fr 1fr 1fr 1fr 1fr',
  gap: '0.5rem',
	width: '100%'
});

const TopProductRow = ({ product, indent, totalProducts, totalAmount, page}) => {
  const [imgError, setImgError] = useState(false);

  indent++;
  
  console.log(getImageUrl(product.image).toString())
  
  useEffect(() => {
    setImgError(false);
  }, [page]);

  return (
    <table className={'w-full'}>
      <tr
        className={`${bgShades[indent]} ${borderShades[indent]} border-b border-gray-200 px-1`}
        style={rowStyle()}
      >
          <td className={`h-full text-center text-xm font-medium py-2 items-center flex justify-center `}>
            {product.highlighted && 
              <div>
              <StarIcon fill={"#ffe349"} height={15}/>
            </div>}
          </td>
          <td className={`h-full text-center text-xm font-medium py-2 items-center flex justify-center `}>
          {product.image && !imgError ?
            <img style={{borderRadius: '8px', width: '52px', height: '52px', objectFit: 'cover'}} src={`${product.image}`} onError={() => setImgError(true)} alt='product_photo'/>
          :
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', width: '52px', height: '52px', border: '1px solid #E2E2E2', background: '#F9F9F9'}}>
              <Icon type='IMAGE' color='#9C9C9C'/>
            </div>
          } 
          </td>
          <td style={{padding: "0px 15px", textAlign: 'center'}} className={`h-full text-xm font-medium py-2 items-center flex justify-center `}>
            {product.tpv_name}
          </td>
          <td className={`h-full text-xm font-medium py-2 items-center flex justify-center `}>
          {product.tpv_price_cents > 0 ? 
          centsToAmountString(product.tpv_price_cents, true, true) + "€" : "-"}
          </td>
          <td className={`h-full text-xm font-medium py-2 items-center flex justify-center `}>
            {product.quantity}
          </td>
          <td className={`h-full text-xm font-medium py-2 items-center flex justify-center `}>
          {totalProducts > 0 ? 
            toDecimalString(product.quantity / totalProducts * 100) + "%" : "-"}
          </td>
          <td className={`h-full text-xm font-medium py-2 items-center flex justify-center `}>
          {product.tpv_price_cents > 0 ? 
          centsToAmountString(product.tpv_price_cents * product.quantity, true, true) + "€": "-"}
          </td>
          <td className={`h-full text-xm font-medium py-2 items-center flex justify-center `}>
          {totalAmount > 0 && product.tpv_price_cents > 0 ?
            toDecimalString((product.tpv_price_cents * product.quantity / totalAmount) * 100) + "%" : "-"}
          </td>
  
      </tr>
    </table>
  )
};

export default TopProductRow