import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CardWithHeader } from 'components/yumminn/CardWithHeader';
import styled from 'styled-components';
import { PeriodRow } from './PeriodRow';
import { abbreviateBigNumbers } from 'logic/functions';

export const UsersOverview = ({data, dates}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showPercent, setShowPercent] = useState(false);
  
  const togglePercent = () => setShowPercent(prev => !prev);

  return (
    <CardWithHeader
      title='active_users'
      info='info_active_users'
      infoOptions={dates}
      color='#B6EBD5'
      className='flex-auto min-w-[13rem]'
      handleClick={() => history.push('/admin/crm')}
    >
      <Container>
        <div style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Legend color='#094553'>
            <div/>
            <span>
              {t('new')}
            </span>
            <p>
              {t(`info_new`)}
            </p>
          </Legend>
          <Legend color='#5BB994'>
            <div/>
            <span>
              {t('recurrent')}
            </span>
            <p>
              {t(`info_recurrent`)}
            </p>
          </Legend>
          <PercentButton
            onClick={togglePercent}
            showPercent={showPercent}
          >
            {t(showPercent ? 'show_totals' : 'show_percentages')}
          </PercentButton>
        </div>
        <div>
          <PeriodRow period={data?.week} name={'week'} showPercent={showPercent}/>
          <PeriodRow period={data?.month} name={'month'} showPercent={showPercent}/>
          {/* <PeriodRow period={data?.year} name={'year'} showPercent={showPercent}/> */}
        </div>
        <hr/>
        <div>
          <span>
            {t('total_clients_registered')}
          </span>
          <b>
            {abbreviateBigNumbers(data?.historic)}
          </b>
        </div>
        {data?.restaurants_historic && (
          <div>
            <span>
              {t('Total registered on restaurant')}
            </span>
            <b>
              {abbreviateBigNumbers(data?.restaurants_historic)}
            </b>
        </div>
        )}
      </Container>
    </CardWithHeader>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);

  & > div {
    display: flex;
    gap: clamp(8px, 2vw, 16px);
    
    &:nth-of-type(1) {
      align-items: flex-end;
    }
    
    &:nth-of-type(2) {
      flex-direction: column;
      gap: clamp(14px, 2.5vw, 20px);
      padding: clamp(8px, 2vw, 16px) 0;
    }
    
    &:nth-of-type(3) {
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 0.875rem;
        font-weight: 600;
      }
      
      b {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.25;
        padding: 4px 56px;
        background-color: #F9F9F9;
        border-radius: 6px;
      }
    }

    &:nth-of-type(4) {
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 0.875rem;
        font-weight: 600;
      }
      
      b {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.25;
        padding: 4px 56px;
        background-color: #F9F9F9;
        border-radius: 6px;
      }
    }
  }

  hr {
    border: none;
    border-bottom: 2px solid #E4E4E7;
  }

  button {

  }
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  gap: clamp(4px, 1vw, 8px);
  position: relative;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${({color}) => color};
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 999px;
  }

  span {
    line-height: 1;
    font-size: 1rem;
    white-space: nowrap;
  }

  p {
    position: absolute;
    top: calc(100% + 0.25rem);
    right: 0;
    font-size: 0.75rem;
    line-height: 1.3;
    max-width: 10rem;
    width: max-content;
    background-color: #FFF;
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0 1px 3px 0 #0002, 0 1px 2px 0 #0001;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
  }

  &:hover p {
    opacity: 1;
    pointer-events: initial;
  }

  @media (max-width: 400px) {
    span {
      font-size: 0.875rem;
    }
  }
`;

const PercentButton = styled.button`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({showPercent}) => showPercent ? '#5BB994' : '#FFF'};
  background-color: ${({showPercent}) => showPercent ? '#FFF' : '#5BB994'};
  border: 2px solid #5BB994;
  border-radius: 999px;
  padding: 4px 12px;
  margin-left: auto;

  &:focus {
    outline: none;
  }
`;