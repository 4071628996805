import React from "react";
import { Trans, useTranslation } from "react-i18next";
import animatedTick from "../../../img/animated-tick.gif";
import QRCode from "react-qr-code";
import { getDiscountTitle } from "./discountFunctions";
import { VisualizeDiscountContainer } from "./DiscountContainers";
import { TextInput } from "common/Input";
import { TYPOLOGY_KEYS_SHORT } from "./constants";
import { Button } from "common/Button";

const LoadPromotionAndVisualize = ({ discount, testTableUrl, close }) => {
  const { t } = useTranslation();

  const type = t(TYPOLOGY_KEYS_SHORT[discount.typology]).toLowerCase();

  return (
    <>
      <VisualizeDiscountContainer>
        <QRCode value={testTableUrl} size={200}/>
        <div>
          <div>
            <img src={animatedTick} alt="" />
            <h3 className={discount.typology}>
              {getDiscountTitle(discount, t)}
            </h3>
          </div>
          <p>
            <Trans i18nKey='visualize_discount_message'>
              Tu {{type}} ya está en la carta. <b>Escanea o copia y pega para visualizarlo</b>.
            </Trans>
          </p>
          <TextInput
            value={testTableUrl}
            copy
          />
        </div>
      </VisualizeDiscountContainer>
      <footer>
        <Button
          label='done'
          onClick={close}
        />
      </footer>
    </>
  );
};

export default LoadPromotionAndVisualize;
