import React from "react";
import { useTranslation } from "react-i18next";
import { calculateEuros } from "services/payments";

const useRefundModal = (doRefund, amountToRefund, setRefundModalOpen) => {
  const { t } = useTranslation();

  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const amountToRefundRef = React.useRef(amountToRefund);
  const customerEmailRef = React.useRef(null)
  const customerNameRef = React.useRef(null)
  const itemValueRef = React.useRef(null);

  const confirmRefund = (inputValue, customerName, customerEmail) => {
    const refundAmount = Number(inputValue)
    doRefund(itemValueRef.current, refundAmount, customerName, customerEmail);
    setShowRefundModal(false);
    setRefundModalOpen(false)
  };

  const openModal = (item) => {
    const total = item.payment_method !== 'wallet' ? calculateEuros((item.base_amount_cents - item.general_discount_cents - item.product_discount_cents - item.loyalty_discount_cents - item.credit_cents - item.master_c2p_promotion_cents - item.code_coupon_cents - item.refunded_amount) + item.tip_cents) : calculateEuros(item.credit_cents - item.refunded_amount)
    const totalFloat = parseFloat(total.replace(/,/g, '.'));
    amountToRefundRef.current = totalFloat;
    itemValueRef.current = item;
    if ("client_email" in item){
      customerEmailRef.current = "client_email" in item ? item.client_email : "" ;
    }
    if ("client_name" in item){
      customerNameRef.current =  "client_name" in item ? item.client_name : "" ;
    }
    setShowRefundModal(true);
  };

  const closeModal = () => {
    setShowRefundModal(false);
    setRefundModalOpen(false)
  };



  const Modal = ({ mensaje }) => {
    const [inputValue, setInputValue] = React.useState(amountToRefundRef.current);
    const [customerName, setCustomerName] = React.useState(customerNameRef.current)
    const [customerEmail, setCustomerEmail] = React.useState(customerEmailRef.current)


    const handleAmountToRefund = (e) => {
      let newValue = e.target.value
      const maxDecimals = 2;
      const maxAmount = amountToRefundRef.current;
      // Remove any existing excess decimal places
      const decimalIndex = newValue.indexOf(".");
      if (decimalIndex !== -1) {
        newValue = newValue.slice(0, decimalIndex + maxDecimals + 1);
      }
      if (newValue <= maxAmount) {
        setInputValue(newValue);
      } else {
        setInputValue(maxAmount);
      }
    }

    const handleChangeName = (e) => {
      let newName = e.target.value
      setCustomerName(newName)
    }

    const handleChangeEmail = (e) => {
      let email = e.target.value
      setCustomerEmail(email)
    }

      return (
        <div>
          <input defaultChecked={showRefundModal} type="checkbox" id="delete-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box">
              <p>{t(mensaje)}</p>
              <div className='w-4/12 flex content-center pt-4'>
                <input
                  type="number"
                  step="0.01"
                  pattern="[0-9]*[.,]?[0-9]+"
                  max={amountToRefundRef.current}
                  min={0.01}
                  className="yumminn-input"
                  value={inputValue}
                  name="amountToRefund"
                  onChange={(e) => handleAmountToRefund(e)}
                />
              </div>
              <div className='w-8/12 flex-column content-center pt-4'>
                <p>{t('email_address')}</p>({t('optional')}):
                <input
                  type="text"
                  className="yumminn-input"
                  value={customerEmail}
                  name="customerEmail"
                  onChange={(e) => handleChangeEmail(e)}
                />
              </div>
              <div className='w-8/12 flex-column content-center pt-4'>
                <p>{t("customer_name")}</p>({t('optional')}):
                <input
                  type="text"
                  className="yumminn-input"
                  value={customerName}
                  name="customerName"
                  onChange={(e) => handleChangeName(e)}
                />
              </div>
              <div className="modal-action">
                <button onClick={() => confirmRefund(inputValue, customerName, customerEmail)} className=" btn bg-red-500 border-transparent hover:bg-red-700 hover:border-transparent">
                  {t("confirm")}
                </button>
                <button onClick={() => closeModal()} className="btn bg-secundario border-transparent">
                  {t("cerrar")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
  }

  return {
    Modal,
    openModal
  };
};

export default useRefundModal;