import React,{useState} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import Select, { components } from 'react-select';
import { getPosition } from './ProductHighlightRow';
import { categories_select_styles } from 'logic/defaults';
import { Context as AuthContext } from '../../../../data/authContext';

export const CustomGroupTitleDescriptionSetting = ({catalog, customGroupDraft, changeTitle, changeDescription, changeTag}) => {
  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '30%', height: '100%', marginLeft: "5%"}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>      
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '7%', paddingRight: '7%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{fontSize: '14px', fontWeight: 500}}>Etiqueta del destacado</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <div style={{fontSize: '14px', position: 'absolute', right: '15px', top: '25%', color: 'gray'}}>{customGroupDraft.tag.length}/15</div>
            <input
                type='text'
                placeholder='Ej: ⭐️ Novedad ⭐️'
                className='yumminn-input'
                value={customGroupDraft.tag}
                name='custom_group_title'
                style={{width: '10ch'}}
                maxLength={15}
                onChange={(e) => changeTag(e.target.value)}
              />
          </div>
          <div style={{marginTop: "25px", fontSize: '14px', fontWeight: 500}}>Título del destacado</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <div style={{fontSize: '14px', position: 'absolute', right: '15px', top: '25%', color: 'gray'}}>{customGroupDraft.name.length}/50</div>
            <input
                type='text'
                placeholder='Ej: ‍👩🏻‍🍳 ¡Sugerencias del chef!'
                className='yumminn-input'
                value={customGroupDraft.name}
                name='custom_group_title'
                style={{width: '10ch'}}
                maxLength={50}
                onChange={(e) => changeTitle(e.target.value)}
              />
          </div>
          {customGroupDraft.name.length < 1 &&
          <div style={{fontSize: '13px', marginTop: '5px'}}>⚠️ Debes añadir un título</div>}
          <div style={{marginTop: "25px", fontSize: '14px', fontWeight: 500}}>Descripción del destacado</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <div style={{fontSize: '14px', position: 'absolute', right: '15px', top: '25%', color: 'gray'}}>{customGroupDraft.description.length}/50</div>
            <input
                type='text'
                placeholder='Lorem Ipsum dolors lorem Ipsum dolors'
                className='yumminn-input'
                value={customGroupDraft.description}
                name='custom_group_title'
                style={{width: '10ch'}}
                maxLength={50}
                onChange={(e) => changeDescription(e.target.value)}
              />
          </div>
          

        
        </div>
      </div>  
    </div>
  )
}
