import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ExternalContainer = styled.div`
  gap: 16px;
  display: flex;
  padding: 16px 24px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: ${({theme}) => theme.text};
  }
  
  p {
    max-width: 28ch;
    margin: 0;
    color: ${({theme}) => theme.text};
  }

  b {
    font-weight: 600;
  }
`;
export const TimeButton = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 4px;
  opacity: ${({unavailable}) => unavailable ? '0.5': '1'};
  padding: 16px;
  background-color: ${({selected}) => selected ? '#1E1E1E' : '#5FB894'};
  border: 1px solid #E2E2E2;
  border-radius: 8px;
  color: white;
`;

export const BookingHourPicker = ({ hour, minute, shifts, bookedSlots, setResult }) => {
  const [times             , setTimes             ] = useState([]);
  const [selectedTimeIndex , setSelectedTimeIndex ] = useState(null);
  const { t } = useTranslation();

  const fillWithLeftZero = (value) => {
    const valueString = value.toString();
    return valueString.length > 1 ? valueString : "0" + valueString;
  };

  useEffect(() => {
    if (times?.length > 0 && hour !== null && hour !== undefined && minute != null && minute !== undefined) {
      times.some((time, index) => {
        if (time && time.hour === hour && time.minute === minute) {
          setSelectedTimeIndex(index);
          return true;
        }
        return false;
      });
    }
  }, [times, hour, minute]);

  useEffect(() => {
    setSelectedTimeIndex(null);
    const newTimes = [];
    for (let s = 0; s < shifts.length; s++) {
      const shift = shifts[s];
      let currentHour = shift.start_hour;
      let currentMinute = shift.start_minute;
      const dinersPerSlot = shift.diners_per_slot;
      for (let i = 0; i < shift.slots_quantity; i++) {
        let currentBookedSlots = 0;
        bookedSlots.forEach((bookedSlot) => {
          if (bookedSlot && bookedSlot.hour === currentHour && bookedSlot.minute === currentMinute) {
            currentBookedSlots += bookedSlot.booked_slots;
          }
        });
        newTimes.push({hour: currentHour, minute: currentMinute, full: currentBookedSlots >= dinersPerSlot});
        const nextMinute = (currentMinute + shift.slot_duration_minutes) % 60;
        if (nextMinute <= currentMinute) {
          currentHour += 1;
        }
        currentMinute = nextMinute;
      }
    }
    setTimes(newTimes);
  }, []);
  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
        <div style={{display: "flex", width: "30%", top: "5%", left: "35%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
            <ExternalContainer>
            <h3>{t('Selecciona una hora')}</h3>
            {times?.length > 0 && (
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', width: '100%'}}>
                {times.map((time, index) => {
                    return (
                    <TimeButton
                        key={'time_' + index}
                        selected={selectedTimeIndex === index}
                        unavailable={time.full}
                        style={{cursor: time.full ? "not-allowed" : "pointer"}}
                        onClick={() => !time.full ? setResult(times[index].hour, times[index].minute) : ""}
                    >
                        {fillWithLeftZero(time.hour) + ":" + fillWithLeftZero(time.minute)}
                    </TimeButton>
                    );
                })}
                </div>
            )}
            </ExternalContainer>
        </div>
        <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  );
};
