import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderItemContainer } from './LockerContainers';
import { isOrderExpired } from './functions';

const lang = sessionStorage.getItem('i18nextLng') || 'es-ES';

export const OrderItem = ({order, children, selected = false, crossExpired = true, onClick, onOrderClick, className = ''}) => {
  const { t } = useTranslation();

  const handleOrderClick = event => {
    if (!onOrderClick) return;

    event.stopPropagation();
    onOrderClick(order)
  };

  return (
    <OrderItemContainer key={order.id} onClick={onClick} className={`${className} ${selected ? 'selected' : ''}`}>
      <button className='code' onClick={handleOrderClick}>
        {t('order')}: <b>{order.code}</b>
      </button>
      <span className='time'>
        🕑
        <span className={crossExpired && isOrderExpired(order) ? 'expired' : ''}>
          {new Date(order.ready_at).toLocaleTimeString(lang, {timeStyle: 'short'})}
        </span>
      </span>
      {children}
    </OrderItemContainer>
  );
};