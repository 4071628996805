import React, { useState, useEffect, useContext } from "react";
import clock from "../../../img/clock.png";
import styled from "styled-components";
import Spinner from "common/Spinner";
import StockInput from "./StockConfiguration/StockInput";
import ShiftSelect from "./StockConfiguration/ShiftSelect";
import grey_arrow_up from "../../../img/grey_arrow_up.svg";
import black_arrow_up from "../../../img/black_arrow_up.svg";
import ItemNameSearch from "./StockConfiguration/ItemNameSearch";
import grey_arrow_down from "../../../img/grey_arrow_down.svg";
import RoundPagination from "common/RoundPagination";
import StockTypeSelect from "./StockConfiguration/StockTypeSelect";
import black_arrow_down from "../../../img/black_arrow_down.svg";
import { Trans } from "react-i18next";
import { baseAuth } from "logic/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';

const CustomButton = styled.div`
  color: white;
  height: 35px;
  display: flex;
  font-size: 12px;
  padding: 0 16px;
  font-weight: 400;
  margin-top: 10px;
  border-radius: 40px;
  background-color: #5FB894;
  &:hover {
    cursor: pointer;
  }
  span {
    align-self: center;
  }
`;
// display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginTop: '15px'
const ItemsListExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 16px;
  padding: 10px;
  border: 1px solid #D4D4D8;
`;
// display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'
const ItemsListHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
const ItemNavsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ItemNav = styled.div`
  border-bottom: ${(props) => props.selected ? '2px solid #094553' : '1px solid #D4D4D8'};
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  span {
    color: ${(props) => props.selected ? '#094553' : '#D4D4D8'};
    margin-left: 6px;
    margin-right: 6px;
    font-weight: 600;
    font-size: 14px;
  }
`;
const ItemsTable = styled.div`
  
`;
const ItemHeader = styled.div`
  border-bottom: 1px solid #D1D1D1;
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  padding: 16px 0 10px 0;
  font-weight: 600;
  background-color: #FCFCFC;
`;
const ItemHeaderCell = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
const ItemRow = styled.div`
  background-color: #FCFCFC;
  display: flex;
  flex-direction: row;
  margin: 4px 0;
`;
const ItemCell = styled.div`
  padding: 16px 30px;
  font-size: 13px;
`;
const OrderByContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const OrderByArrow = styled.img`
  width: 12px;
`;

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const StockConfiguration = () => {

  const {t} = useTranslation();
  const history = useHistory();
  const {state: {selectedRestaurant}} = useContext(AuthContext);
  const [page              , setPage              ] = useState(1);
  const [items             , setItems             ] = useState([]);
  const [shifts            , setShifts            ] = useState(null);
  const [loading           , setLoading           ] = useState(true);
  const [typeOfItem        , setTypeOfItem        ] = useState('products');
  const [itemsLoading      , setItemsLoading      ] = useState(true);
  const [numberOfPages     , setNumberOfPages     ] = useState(null);
  const [orderByColumn     , setOrderByColumn     ] = useState('tpv_name');
  const [selectedShift     , setSelectedShift     ] = useState(null);
  const [orderAscendant    , setOrderAscendant    ] = useState(true);
  const [searchItemName    , setSearchItemName    ] = useState('');
  const [selectedStockType , setSelectedStockType ] = useState('all');
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(null);

  //console.log('selectedRestaurant', selectedRestaurant);

  useEffect(() => {
    if (!selectedRestaurant || selectedRestaurant.length <= 0) return;
    const restaurantId = selectedRestaurant[0]["value"];
    //console.log('restaurantId', restaurantId);
    const endpoint = `restaurant/list/shifts/${restaurantId}/`;
    baseAuth.get(endpoint).then(response => {
      //console.log('response.data', response.data);
      setShifts(response.data);
      if (response.data?.length > 0) {
        setSelectedShift(response.data[0].id);
      }
    }).finally(_ => setLoading(false));
    //let endpoint = `stock/list/products//?&page=${pageRef.current}&restaurant=${id}`;
  }, [selectedRestaurant]);

  const parseItemsDict = itemsDict => {
    //console.log('itemsDict', itemsDict);
    const items = itemsDict['items'];
    /*const itemsStocks = itemsDict['items_stocks'];
    items = items.map(item => {
      const newItem = {...item};
      itemsStocks.some(itemStock => {
        if (itemStock.tpv_id === item.tpv_id) {
          newItem['stock'] = itemStock['stock'];
          newItem['stockId'] = itemStock['id'];
          return true;
        }
        return false;
      });
      return newItem;
    });*/
    //console.log('items', items);
    const totalNumberOfItems = itemsDict['total_number_of_items'];
    setTotalNumberOfItems(totalNumberOfItems);
    const modulusValue = totalNumberOfItems % 20;
    const numberOfPages = Math.floor(totalNumberOfItems / 20) + (modulusValue == 0 ? 0 : 1);
    //console.log('numberOfPages', numberOfPages);
    setNumberOfPages(numberOfPages);
    setItems(items);
  };

  const refreshItems = () => {
    if (!typeOfItem || !selectedShift) return;
    //console.log('refreshItems entered');
    setItemsLoading(true);
    const orderBy = (orderAscendant ? '' : '-') + orderByColumn;
    let endpoint = `stock/list/${typeOfItem}/${selectedShift}/${selectedStockType}/${page}/${orderBy}/`;
    if (searchItemName?.length > 0) {
      endpoint += `${searchItemName}/`;
    }
    //console.log('endpoint', endpoint);
    baseAuth.get(endpoint).then(response => {
      parseItemsDict(response.data);
    }).finally(_ => setItemsLoading(false));
  };

  useEffect(() => {
    refreshItems();
  }, [page, typeOfItem, selectedShift, selectedStockType, searchItemName, orderByColumn, orderAscendant]);

  useEffect(() => {
    setPage(1);
    setTotalNumberOfItems(null);
    setNumberOfPages(null);
  }, [typeOfItem, selectedShift, selectedStockType, searchItemName]);

  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`}>
        {loading ? <LoadingOverlay /> : (
          <>{(!shifts || shifts.length <= 0) ? (
            <>
              <img src={clock} style={{width: '41px', height: '40px', marginTop: '40px'}} />
              <div style={{display: 'flex', marginTop: '10px'}}>  
                <Trans i18nKey={"Create shifts to be able to configure the stocks"}>
                  <span style={{fontWeight: 600, marginRight: '4px'}}>Crea los turnos</span>en el restaurante para poder configurar el stock
                </Trans>
              </div>
              <CustomButton onClick={_ => history.push("/admin/shifts")}>
                <span>{t("Go to shift tab")}</span>
              </CustomButton>
            </>) : (
              <div style={{width: '100%'}}>
                <div style={{display: 'flex', gap: '20px', marginTop: '20px'}}>
                  <ShiftSelect shifts={shifts} selectedShift={selectedShift} setSelectedShift={setSelectedShift} />
                  <StockTypeSelect selectedStockType={selectedStockType} setSelectedStockType={setSelectedStockType} />
                  <ItemNameSearch setSearchItemName={setSearchItemName} />
                </div>
                {itemsLoading ? <LoadingOverlay /> : (
                  <>
                    <ItemsListExternalContainer>
                      <ItemsListHeader>
                        <ItemNavsContainer>
                          <ItemNav
                            selected={typeOfItem == 'products'}
                            onClick={_ => setTypeOfItem('products')}
                          ><span>{t('Products')}</span></ItemNav>
                          <ItemNav
                            selected={typeOfItem == 'modifiers'}
                            onClick={_ => setTypeOfItem('modifiers')}
                          ><span>{t('Modifiers')}</span></ItemNav>
                        </ItemNavsContainer>
                        {items?.length > 0 && (
                          <RoundPagination
                            page={page}
                            setPage={setPage}
                            total={totalNumberOfItems}
                            numberOfPages={numberOfPages}
                            wrapperStyle="margin-top: 4px;"
                            elementNamePlural={t(typeOfItem)}
                            elementNameSingular={t(typeOfItem.substring(0, typeOfItem.length - 1))}
                          />
                        )}
                      </ItemsListHeader>
                      {!items || items.length <= 0 ? (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '40px 0'}}>
                          <span style={{fontSize: '16px', fontWeight: '500', color: '#094553'}}>
                            {t('No results match your search criteria')}
                          </span>
                        </div>
                      ) : (
                        <ItemsTable>
                          <ItemHeader>
                            <ItemHeaderCell style={{flex: 2}} onClick={() => {
                              if (orderByColumn !== 'tpv_name') {
                                setOrderByColumn('tpv_name');
                                setOrderAscendant(true);
                              } else {
                                setOrderAscendant(!orderAscendant);
                              }
                            }}>
                              <span>{t('Items')}</span>
                              <OrderByContainer>
                                <OrderByArrow src={orderByColumn !== 'tpv_name' ? black_arrow_up : (orderAscendant ? grey_arrow_up : black_arrow_up)} />
                                <OrderByArrow src={orderByColumn !== 'tpv_name' ? black_arrow_down : (orderAscendant ? black_arrow_down : grey_arrow_down)} />
                              </OrderByContainer>
                            </ItemHeaderCell>
                            <ItemHeaderCell style={{flex: 1}} onClick={() => {
                              if (orderByColumn !== 'stock') {
                                setOrderByColumn('stock');
                                setOrderAscendant(true);
                              } else {
                                setOrderAscendant(!orderAscendant);
                              }
                            }}>
                              <span>{t('Stock')}</span>
                              <OrderByContainer>
                                <OrderByArrow src={orderByColumn !== 'stock' ? black_arrow_up : (orderAscendant ? grey_arrow_up : black_arrow_up)} />
                                <OrderByArrow src={orderByColumn !== 'stock' ? black_arrow_down : (orderAscendant ? black_arrow_down : grey_arrow_down)} />
                              </OrderByContainer>
                            </ItemHeaderCell>
                            
                          </ItemHeader>
                          {items.map((item, index) => {
                            const stockId = "stock_id" in item ? item["stock_id"] : null;
                            const value = "stock" in item ? item["stock"] : "";
                            return (
                              <ItemRow key={'stock_list_row_' + index}>
                                <ItemCell style={{flex: 2}}><span>{item.name ? item.name : item.tpv_name}</span></ItemCell>
                                <ItemCell style={{flex: 1}}>
                                  <StockInput
                                    initialValue={value}
                                    itemStockId={stockId}
                                    typeOfItem={typeOfItem}
                                    itemTpvId={item["tpv_id"]}
                                    stockUpdated={refreshItems}
                                    selectedShift={selectedShift}
                                    setItemsLoading={setItemsLoading}
                                  />
                                </ItemCell>
                              </ItemRow>
                            );
                          })}
                        </ItemsTable>
                      )}
                    </ItemsListExternalContainer>
                  </>
                )}
              </div>)}
          </>
        )}
      </div>
    </>
  );
};

export default StockConfiguration;
