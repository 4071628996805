import React, { useState } from "react";
import { FolderDownloadIcon, EyeIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import Switch from 'react-switch';
import {  baseAuth } from "logic/api";
import { centsToAmountString } from "logic/functions";

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1.5fr 1fr 1fr 1fr',
	width: '100%'

});

const IndexedDetailRow = ({ name, value, bold, position}) => {
  const { t } = useTranslation();

  return (
    <div style={{display: 'flex', borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: bold ? 600 : 500, fontSize: "13px"}}>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: position == "F" ? "1px 0px 0px 0px" : "0px", backgroundColor: '#FFFFFF', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
            </div>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: position == "F" ? "1px 0px 0px 0px" : "0px", backgroundColor: '#FFFFFF', justifyContent: 'center', alignItems: 'center'}}>
            </div>
            <div style={{display: 'flex', width: "50%", fontWeight: 600, height: "45px", borderWidth: position == "L" ? "1px" : "1px 1px 0px 1px", justifyContent: 'center', alignItems: 'center'}}>
              {name}
            </div>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: position == "L" ? "1px 1px 1px 0px" : "1px 1px 0px 0px" , justifyContent: 'center', alignItems: 'center'}}>
              {value}
            </div>
          </div>
  )
};

export default IndexedDetailRow