import React from 'react';

export const ButtonMockup = ({label, children, type = 'primary', theme = {}, className = '', style = {}}) => {
  const styles = {
    primary: () => ({
      backgroundColor: theme?.primary || '#5FB894',
      borderColor: theme?.primary || '#5FB894',
      color: theme?.background || '#FFF',
    }),
    secondary: () => ({
      backgroundColor: theme?.tertiary_lighter || '#EFF8F4',
      borderColor: theme?.primary || '#5FB894',
      color: theme?.primary || '#5FB894',
    }),
    neutral: () => ({
      backgroundColor: theme?.background || '#FFF',
      borderColor: '#8888',
      color: theme?.text || '#404040',
    }),
  };

  return (
    <div
      className={`w-full h-[20px] flex justify-center items-center rounded-full border-2 text-[8px] font-semibold leading-[1em] transition-colors ${className}`}
      style={{
        ...styles[type](),
        ...style
      }}
    >
      {label || children}
    </div>
  );
};