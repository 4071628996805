import React, { useState, useEffect } from "react";
import { TrashIcon, XIcon } from '@heroicons/react/outline';
import UpdateTablesInfo from "./UpdateTablesInfo";

const UpdateTablesInfoModal = ({new_tables, deactivated_tables, new_active_tables, setShowUpdateInfo}) => {

  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "50%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>{"Información de la sincronización"}</div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "220px", justifyContent: 'flex-end', display: 'flex'}}>
              <button style={{margin: "0 15px"}} onClick={() => {setShowUpdateInfo(false)}}><XIcon className={`w-[25px] text-gray-700 cursor-pointer`}/></button>
            </div>
        </div>     
        <div style={{marginTop: "15px", display: "flex", height: "400px", width: '100%'}}>
        {new_tables.length > 0 || deactivated_tables.length > 0 || new_active_tables.length > 0 ?
          <UpdateTablesInfo new_tables={new_tables} deactivated_tables={deactivated_tables} new_active_tables={new_active_tables} /> :
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '25px', fontWeight: 'bold'}}>No ha habido cambios en la sincronización</div>
        }
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default UpdateTablesInfoModal