import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import DatePicker from "react-datepicker";
import trash from '../../../img/trash.png';
import useDeleteModal from 'components/hooks/useDeleteModal';
import {apiAuth} from 'logic/api';
import {toast} from 'react-toastify';
import pencil from '../../../img/pencil.png';


const MenuSavedSchedules = ({
    allSchedules, 
    setAllSchedules, 
    menuId, 
    setGrupos,
    setTimeRange,
    setAllTimeRanges,
    setSelectedDays,
    setScroll,
    selectedDays
  }) => {
    const { t } = useTranslation();
    const isRowBased = useMediaQuery("(max-width: 760px)");
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const selected = JSON.parse(localStorage.getItem('yumminn-selectedRestaurant'))
    const [edit, setEdit] = React.useState(true)

    const handleDeleteSchedules = async(index) => {
        let schedulesList = [...allSchedules]
        schedulesList.splice(index, 1)
        setAllSchedules(schedulesList)
        //delete existing schedule
        if(menuId !== undefined){
          try {
            await apiAuth(`/restaurants/${selected[0]?.value}/modify_menu/?id=${menuId}`, {
              method: 'PATCH', 'Content-Type': 'multipart/form-data', data: 
              {
                visibility: schedulesList
              }
            });
            //setAllSchedules([])
            //setGrupos([])
          } catch (error) {
            console.error(error);
            toast.error(error.response.data);
          }
        } 
    };

    const handleEditSchedule = async(index) => {
      let schedulesList = [...allSchedules]
      let timeRangeList = []
      setSelectedDays(schedulesList[index].selectedDays)
      schedulesList[index].timeRanges.map((t, index) => {
        return(
          timeRangeList.push({from: new Date(t.from), to: new Date(t.to)})  
        )
      })
      //setTimeRange(true)
      setAllTimeRanges(timeRangeList)
      schedulesList.splice(index, 1)
      setAllSchedules(schedulesList)
      setScroll(true)
      setEdit(false)      
    }

    const scheduleModal = useDeleteModal(handleDeleteSchedules);
    

    return(
        <>
        <div className='flex flex-col w-full pt-8'>
        {allSchedules.map((schedule, indexS) => 
            <motion.div
              key={indexS}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='mb-5 mr-3 bg-white p-3 rounded-lg'
            >
              <div
                key={`sch1${indexS}`} 
                className='flex flex-col space-y-3 divide-y'
              >
                <div key={`sch2${indexS}`} className={`flex ${isRowBased && 'flex-col'} items-center w-full space-x-2`}>
                  <label
                    key={`sch3${indexS}`}
                    className={`w-36 text-[16px] text-texto ${isRowBased && 'pl-8 pb-4'}`}
                    htmlFor='name_link'
                  >
                    {t('Select days')}
                  </label>
                  {weekdays.map((day, index) => 
                  <div key={`sch4${index}`} className={`flex mb-1`}>
                    <button
                        key={`sch5${index}`} 
                        style={{color: schedule.selectedDays.some((d) => d === day) ? '#FFF' : '#EA9F89', background: schedule.selectedDays.some((d) => d === day) ? '#EA9F89' : '#FFF'}}
                        className='cursor-pointer focus:outline-none flex text-nuevo_secundario flex-row w-32 justify-center align-center capitalize rounded-full cursor-pointer shadow-md px-12 py-2 m-1 border-2 border-nuevo_secundario text-sm tracking-wide'
                        type='button'
                        >
                        {t(day)}
                    </button>
                  </div>
                  )}
                </div>
                <div key={`sch6${indexS}`} className='flex flex-col w-full space-x-2 pt-4 pb-4'>
                  <label
                    key={`sch7${indexS}`}
                    className='w-36 text-[16px] text-texto pb-4'
                    htmlFor='name_link'
                  >
                      {t('Select time range')}
                  </label> {/*saved timeranges*/}
                  {schedule.timeRanges.map((range, index) => {
                    return(
                      <div key={`sch8${index}`} className='flex pt-1 justify-between items-center pb-4'>
                      <div key={`sch9${index}`} className='flex'>
                        <div key={`sch10${index}`} className='mr-48'>
                            {t('From')}
                            <DatePicker
                                key={`fromsch11${index}`}
                                selected={new Date(range['from'])}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                disabled={edit}
                                style={{border: '2px solid hsl(0, 0%, 80%'}}
                             />
                        </div>
                        <div key={`sch12${index}`} className='ml-8'>
                            {t('To')}
                            <DatePicker
                                key={`sch13to${index}`}
                                selected={new Date(range['to'])}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                disabled={edit}
                                style={{border: '2px solid hsl(0, 0%, 80%'}}
                            />
                        </div>
                    </div>
                  </div>
                  )}   
                  )}
                </div>
               
                <div key={`sch14${indexS}`} className='flex justify-end w-full py-4'>
                  <img 
                      key={`sch16${indexS}`}
                      className='mr-2'
                      src={pencil} 
                      onClick={() => handleEditSchedule(indexS)}
                      style={{width: '24px', height: "24px", marginLeft: '15px', cursor: "pointer"}} 
                      alt='edit'
                  />  
                  <img 
                      key={`sch15${indexS}`}
                      className='mr-8'
                      src={trash} 
                      onClick={() => scheduleModal.openModal(indexS)}
                      style={{width: '24px', height: "24px", marginLeft: '15px', cursor: "pointer"}} 
                      alt='delete'
                  />  
                </div>

              </div>
              
            </motion.div>
          )}
        
        </div>

        {allSchedules.length > 0 && !edit && <div style={{borderBottom: "2px dashed gray", marginBottom: "20px", marginTop: "10px"}}></div>}

        <scheduleModal.Modal mensaje={t('Delete_schedule')}/>

        </>
    )
}

export default MenuSavedSchedules;

