import Spinner from 'common/Spinner';
import { DeltaIndicator } from 'components/yumminn/DeltaIndicator';
import { RadioButtons } from 'components/yumminn/RadioButtons';
import { thousandsSeparator } from 'logic/functions';
import moment from 'moment';
import React, {useState, useEffect} from 'react'
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { CustomLineChart } from './CustomLineChart';
import { Icon, IconType } from 'common/Icon';

const getTicksOptions = (period) => {
  const from = moment(period.from_date);
  const to = moment(period.to_date);
  const timeDiff = to.diff(from, 'days') + 1; // first day included

  if (timeDiff <= 2) return ['hours'];
  if (timeDiff <= 3) return ['hours', 'days'];
  if (timeDiff <= 14) return ['days'];
  if (timeDiff <= 59) return ['days', 'weeks'];
  if (timeDiff > 59) return ['days', 'weeks', 'months'];

  return [];
};

const toThousandsStr = (num) => thousandsSeparator(num || 0, {noDecimals: true});

const defaultFormatter = x => Number(x);

export const LineChartCard = ({
  title,
  subtitle = null,
  chartTitles,
  data,
  average = null,
  total = null,
  totalDefinition = '',
  iconType = null,
  period,
  initialInterval,
  unit = '',
  chartHeight = 220,
  updateData,
  formatter = defaultFormatter,
  className,
  style, 
  hourly
}) => {
  const { t } = useTranslation();

  const [interval, setInterval] = useState({selected: null, options: []});
  const [isLoading, setIsLoading] = useState(false);
  const isArray = Array.isArray(data);
  const path = window.location.pathname;

  const selectOption = async (selected) => {
    setIsLoading(true);
    try {
      await updateData(title, selected);
      setInterval(prev => ({...prev, selected}));
    } catch (error) {
      console.log("🚀 ~ file: LineChartCard.jsx:56 ~ selectOption ~ error:", error)
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    const options = getTicksOptions(period);
    
    let selected = null
    if (!interval.selected || (interval.selected && !interval.options.includes(interval.selected) )){
      selected = initialInterval
    }else{
      selected = interval.selected
    }
    
    setInterval({selected, options});
  }, [period.from_date, period.to_date]);


  return (
    <Card className={className} style={style} isLoading={isLoading}>
        {subtitle ? 
          <header>
            <div style={{flex: '1'}}>
              <h3 className='mb-2'>{t(title)}</h3>
                <Trans i18nKey={subtitle} average={average}>
                  subtitle <b>subtitle {{average: average}}</b> subtitle
                </Trans>
            </div>       
            <div style={{background: "#FAFAFA", padding: "15px", borderRadius: "15px"}} className='gap-1'>
              <div className='flex justify-between w-full items-center gap-6'>
                <Icon type={IconType[iconType]} />
                <h2 className='text-right'>{total}</h2>
              </div>
              <div className='flex items-center gap-8 mt-1'>
                <DeltaIndicator delta={data?.delta || 0}/>
                {totalDefinition && <p className='text-right'>{t(totalDefinition)}</p>}
              </div>
            </div>
          </header>
          :
          <header>
            <h3 className='capitalize'>{t(title)}</h3>
            <RadioButtons
              name={'ticks'}
              value={interval.selected}
              options={interval.options}
              onChange={selectOption}
              disabled={isLoading}
            />
            <DeltaIndicator delta={data?.delta || 0}/>
          </header>
        }
        {chartTitles &&
          <div>
            {chartTitles.map(chartTitle => (
              <h4>{t(chartTitle)}</h4>
            ))}
          </div>
        }
      <main>
        <Spinner/>
        {!isArray &&
          <CustomLineChart data={data?.chart} unit={unit} formatter={formatter} chartHeight={chartHeight} hourly={hourly} />
        }
        {isArray &&
          data.map((chartData, index) => (
            <CustomLineChart data={chartData?.chart} unit={unit} formatter={formatter} chartHeight={chartHeight} key={title + index} hourly={hourly} />
          ))
        }
      </main>
      <footer>
        {!isArray &&
          <ChartLegend current={data?.current} previous={data?.previous} unit={unit} formatter={formatter} t={t} path={path}/>
        }
        {isArray &&
          data.map((chartData, index) => (
            <ChartLegend current={chartData?.current} previous={chartData?.previous} unit={unit} formatter={formatter} key={title + index} t={t} path={path}/>
          ))
        }
      </footer>
    </Card>
  );
};

const ChartLegend = ({current = 0, previous = 0, unit = '', formatter, t, path}) => (
  <Legend>
    <div>
      <p>
        <ul>
          <i/>
        </ul>
        <span>{t('current')}</span>
      </p>
      {path === '/admin/dashboard' && <b>{toThousandsStr(formatter(current)) + unit}</b>}
    </div>
    <div>
      <p>
        <ul>
          <i/>
        </ul>
        <span>{t('previous')}</span>
      </p>
      {path === '/admin/dashboard' && <b>{toThousandsStr(formatter(previous)) + unit}</b>}
    </div>
  </Legend>
);

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 22px;
  color: #404040;
  background-color: #FFF;
  border: 2px solid #E4E4E7;
  border-radius: 20px;
  padding: 24px;
  overflow: hidden;
  font-weight: 600;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    h3 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1;
      flex: 1;
      color: #094553;
      
      @media (max-width: 400px) {
        font-size: 1.125rem;
      }
    }

    
    h2 {
      font-size: 38px;
      font-weight: 700;
      line-height: 1;
      flex: 1;
      color: #094553;
      
      @media (max-width: 400px) {
        font-size: 30px;
      }
    }

    & > div:last-of-type {
      margin-left: auto;
      opacity: ${({isLoading}) => isLoading ? 0.5 : 1};
    }
  }

  & > div {
    display: flex;
    gap: 24px;
    width: 100%;
    
    h4 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;
      width: 100%;
      margin: 0 0 -8px;
    }
  }

  main {
    display: flex;
    justify-content: space-evenly;
    gap: 24px;
    position: relative;

    & > div:first-of-type {
      position: absolute;
      height: 100%;
      background-color: #FFF8;
      opacity: ${({isLoading}) => isLoading ? 1 : 0};
      pointer-events: ${({isLoading}) => isLoading ? 'initial' : 'none'};
      z-index: 2;
      transition: opacity 0.2s ease-in-out;
    }
  }
  
  footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #F8F8F8;
    margin: 0 -24px -24px;
    padding: 8px;
    opacity: ${({isLoading}) => isLoading ? 0.5 : 1};
  }

  @media (max-width: 600px) {
    main, footer {
      flex-direction: column;
    }
  }
`;

const Legend = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 40px;
  flex: 1;
  margin-left: 24px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child i {
      background-color: #094553;
    }
    
    &:last-child i {
      background-color: #2E9EB7;
    }
  }

  p {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  ul{
    list-style-type: square;
  }

  i {
    display: inline-block;
    height: 12px;
    width: 12px;
  }

  span:first-letter {
    text-transform: capitalize;
  }

  b {
    font-size: 1.125rem;
    opacity: ${({isLoading}) => isLoading ? 0.5 : 1};
  }
`;