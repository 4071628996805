import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { SameSizeButtonGroup } from 'common/SameSizeButtonGroup';
import Spinner from 'common/Spinner';
import { TextInput } from 'common/Input';
import { MobileMockup } from 'components/previews';
import { WifiPreview } from 'components/previews/screens/WifiPreview';
import { Context } from 'data/authContext';
import { baseAuth } from 'logic/api';
import { ConfirmPopup } from 'pages/admin/pages/Lockers/ConfirmPopup';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import useSWR from 'swr';

const fetchWifiData = url => baseAuth.get(url).then(res => res.data);

export const SettingsWifi = () => {
	const { state: { selectedRestaurantId } } = useContext(Context);

  const { t } = useTranslation();
  
  const { data, isLoading, mutate } = useSWR(
    `/bo/get_wifi_data/${selectedRestaurantId}`,
    fetchWifiData,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  
  const [wifiDraft, setWifiDraft] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  
  const isSaveDisabled = !data || !wifiDraft || Object.keys(wifiDraft).every(key => wifiDraft[key] === data[key]);
  const isDeleteDisabled = !data || (!data.wifi_ssid && !data.wifi_pass);

  const changeWifiData = partialData => {
    setWifiDraft(prev => ({...prev, ...partialData}));
  };
  
  const saveWifiData = async (wifiData) => {
    try {
      setIsSaving(true);
      const res = await baseAuth.patch('/bo/set_wifi_data', {restaurant_id: selectedRestaurantId, ...wifiData});

      res.status === 200 && await mutate({...data, ...wifiData});
      toast.success(t('wifi_save_success'));
    } catch (error) {
      console.log(`🐉 > saveWifiData > error:`, error);
      toast.error(t('wifi_save_error'));
    } finally {
      setIsSaving(false);
    }
  };

  const deleteWifiData = async () => {
    await saveWifiData({wifi_ssid: '', wifi_pass: ''})
    setShowConfirmDelete(false);
  };

  useEffect(() => {
    setWifiDraft(data ? {wifi_ssid: data.wifi_ssid, wifi_pass: data.wifi_pass} : null);
  }, [data]);
  
  return (
    <Container>
      <header>
        <h2>Wifi</h2>
      </header>
      <div>
        <div className='form'>
          <TextInput
            label={'wifi_name'}
            value={wifiDraft?.wifi_ssid || ''}
            placeholder='wifi_name_placeholder'
            onChange={event => changeWifiData({wifi_ssid: event.target.value})}
          />
          <TextInput
            label={'wifi_pass'}
            value={wifiDraft?.wifi_pass || ''}
            placeholder='wifi_pass_placeholder'
            onChange={event => changeWifiData({wifi_pass: event.target.value})}
          />
          <SameSizeButtonGroup className='buttons'>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              rightIcon={<Icon type={IconType.TRASH}/>}
              onClick={() => setShowConfirmDelete(true)}
              disabled={isDeleteDisabled}
              loading={isSaving}
            />
            <Button
              label='save'
              onClick={() => saveWifiData(wifiDraft)}
              disabled={isSaveDisabled}
              loading={isSaving}
            />
          </SameSizeButtonGroup>
        </div>
        <div className='preview'>
          <div className='mock'>
            <MobileMockup>
              <WifiPreview {...data} {...wifiDraft}/>
            </MobileMockup>
            <Icon type={IconType.WIFI} color='#FFFFFF'/>
          </div>
          <p>
            <Trans i18nKey={'wifi_preview_message'}>
              Si el comensal tiene <b>una conexión débil</b>, verá automáticamente los datos del wifi, al escanear el QR.
              <br/><br/>
              Además, podrá acceder manualmente desde el icono superior izquierdo
            </Trans>
          </p>
        </div>
      </div>
      <ConfirmPopup
        isOpen={showConfirmDelete}
        title='delete_wifi_title'
        message='delete_wifi_message'
        confirm={deleteWifiData}
        cancel={() => setShowConfirmDelete(false)}
      />
      {(!wifiDraft || isLoading) &&
        <Spinner className='spinner'/>
      }
    </Container>
  );
};

const Container = styled.div`
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 35px;
  margin: 32px 24px;
  width: fit-content;
  position: relative;

  & > header {
    color: #404040;
    border-bottom: 1px solid #BFBFBF;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 1em;
    }
  }

  & > div {
    display: flex;
    gap: 48px;
    padding: 24px 24px 0px;
  }
  
  .preview {
    position: relative;

    & > p {
      position: absolute;
      top: 8%;
      left: 65%;
      font-size: 0.75rem;
      font-weight: 300;
      text-wrap: balance;
      width: 28ch;
      color: #FFFFFF;
      background-color: #094553;
      border-radius: 8px;
      padding: 12px;
      z-index: 2;
    }

    b {
      font-weight: 600;
    }
  }

  .mock {
    height: 366px;
    padding: 0 2px;
    margin-bottom: -16px;
    overflow: hidden;
    position: relative;

    @keyframes blink {
      100% {
        outline-offset: 0px;
      }
      100% {
        outline-offset: 2px;
      }
    }

    & > svg {
      position: absolute;
      top: 14.3%;
      left: 4.5%;
      height: 24px;
      width: 24px;
      padding: 4px;
      backdrop-filter: blur(50px);
      border-radius: 12px;
      outline: 2px solid #D05454;
      animation: blink 0.7s infinite alternate ease-in-out;
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    width: 20rem;
    padding: 24px 0 0;
  }

  .buttons {
    justify-content: space-between;
    margin-top: auto;
  }

  .spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #F4F4F540;
    bottom: 0;
    left: 0;
  }
`;

export default SettingsWifi