import React from 'react';
import { CardWithHeader } from 'components/yumminn/CardWithHeader';
import { useTranslation } from 'react-i18next';
import { abbreviateBigNumbers } from 'logic/functions';
import { DataCard } from '.';
import { DeltaIndicator } from 'components/yumminn/DeltaIndicator';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
// import createHistory from 'history/createBrowserHistory';

// const history = createHistory({forceRefresh:true});  


export const PaymentsLayout = ({title, infoOptions, period, metrics, current}) => {
  const { t } = useTranslation();
  const history = useHistory();
  
  const [periodLabel, periodData] = Object.entries(period || {a:{value:0,delta:0}})[0];

  const formatAmounts = (num = 0) => abbreviateBigNumbers(num / 100, {decimals: 1});

  return (
    <CardWithHeader
      title={title}
      info={`info_period_${title}`}
      infoOptions={infoOptions}
      className='flex-auto'
      handleClick={() => history.replace('/payments')}
    >
      <Container>
        <BigCard>
          <b>
            {formatAmounts(periodData?.value || 0) + '€'}
          </b>
          <span>
            {t(periodLabel)}
          </span>
          <DeltaIndicator delta={periodData?.delta || 0}/>
        </BigCard>
        <hr/>
        {Object.entries(metrics).map(([label, data]) => (
          <MetricContainer key={label}>
            <span>
              {t(label)}
            </span>
            <b>
              {data?.value || 0}
            </b>
            <DeltaIndicator delta={data?.delta || 0} small/>
          </MetricContainer>
        ))}
        <hr/>
        <PeriodCards>
          {Object.entries(current).map(([label, data]) => (
            <DataCard
              data={formatAmounts(data?.value || 0) + '€'}
              label={label}
              delta={data?.delta || 0}
              key={label}
              className='flex-1'
              size='small'
            />
          ))}
        </PeriodCards>
      </Container>
    </CardWithHeader>
  );
};

const Container = styled.div`
  //flex flex-col gap-3 h-full p-4
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);
  
  hr {
    width: 100%;
    border: none;
    border-bottom: 2px solid #E4E4E7;
  }
`;

const BigCard = styled.div`
  display: flex;
  gap: clamp(8px, 2vw, 16px);
  flex-direction: column;
  border-radius: 12px;
  background-color: #F4F4F5;
  padding: 20px;
  position: relative;

  b {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
  }

  span {
    font-size: 0.75rem;
    font-weight: 500;
    color: #999;
  }

  & > div {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

const MetricContainer = styled.div`
  display: flex;
  gap: clamp(8px, 2vw, 16px);
  align-items: center;
  
  span {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
  }
  
  b {
    line-height: 1;
    margin-left: auto;
  }
`;

const PeriodCards = styled.div`
  display: flex;
  gap: 8px;
`;