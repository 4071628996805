import React from 'react';
import { Provider as AuthProvider } from './data/authContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Helmet } from "react-helmet";
import { NavigatorProvider } from "data/navigatorContext";

import Routes from './Routes';

const App = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <AuthProvider>
        <NavigatorProvider>
          <DndProvider backend={HTML5Backend}>
            <Routes />
          </DndProvider>
        </NavigatorProvider>
      </AuthProvider>
    </>
  );
};

export default App;
