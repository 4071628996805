import { NumberInput } from 'common/Input';
import { DateInput } from 'common/Input/DateInput';
import React from 'react';
import styled from 'styled-components';

export const PromotionLimitsTab = ({ discountDraft, editDiscount }) => {
  const dateRange = discountDraft.valid_since && discountDraft.valid_until
    ? [new Date(discountDraft.valid_since), new Date(discountDraft.valid_until)]
    : null;

  const changePeriod = (dates) => {
    if (dates) {
      editDiscount({
        valid_since: dates[0].toISOString(),
        valid_until: dates[1].toISOString(),
      });
    } else {
      editDiscount({ valid_since: null, valid_until: null });
    }
  };

  const changeMaxUses = event => editDiscount({ max_total_uses: Math.floor(Number(event.target.value))});

  return (
    <PromotionLimitsContainer>
      <p>
        Combina los límites para mayor <b>control de tus descuentos.</b>
      </p>
      <div>
        <DateInput
          label={'Periodo de validez (opcional)'}
          placeholder='Seleccionar periodo'
          format={'dd/MM/yyyy HH:mm'}
          value={dateRange}
          onChange={changePeriod}
          disablePastDates
        />
        <i>
          Define un periodo con fecha y hora para la aplicación de tu descuento. <b>Fuera de este intervalo, dejará de ser válido.</b>
        </i>
      </div>
      <div>
        <NumberInput
          label={'Cantidad máxima de usos (opcional)'}
          placeholder='Ejemplo: “20 veces”'
          value={discountDraft.max_total_uses}
          onChange={changeMaxUses}
          step='1'
        />
        <i>
        Limita el número total de veces que se puede utilizar el descuento. <b>Una vez alcanzado el límite, el descuento no podrá ser aplicado por los comensales.</b>
        </i>
      </div>
      <div>
        <NumberInput
          label={'Importe límite (opcional)'}
          placeholder='Ejemplo: “1200”'
          value={discountDraft.maxEurosRedeemable}
          onChange={e => editDiscount({ maxEurosRedeemable: e.target.value })}
          unit='€'
        />
        <i>
          Configura un importe total a consumir para este descuento. <b>Una vez alcanzado, ya no podrá utilizarse.</b>
        </i>
      </div>
    </PromotionLimitsContainer>
  );
};

const PromotionLimitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  font-size: 1rem;
  line-height: 1.3;
  padding: 16px;
  
  & > div {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    width: 100%;

    & > div {
      width: 350px;
    }
  }

  b {
    font-weight: 600;
  }

  i {
    max-width: 400px;
    font-size: 0.875rem;
    padding: 8px;
    background-color: #E6E6E633;
    border-radius: 8px;
  }
`;