import "./Spinner.css"
import React from 'react'
import {useTranslation} from "react-i18next";

const Spinner = ({style = {}, className = '', showText=true}) => {
    const {t} = useTranslation()
    return (
        <div className={`container ${className}`} style={style}>
            <div className="loader">
                <div className="loader-wheel"></div>
                {showText && 
                    <div className="loader-text">{t("Loading")}</div>
                }
            </div>
        </div>
    )
}

export default Spinner;


