import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const HomeTabs = ({ filter }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col py-4'>
      <div
        className={'flex flex-row text-[14px] text-secundario font-bold py-3'}
      >
        <NavLink
          exact={true}
          activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
          to='/home'
          className='border-b-2 border-secundario pr-8'
        >
          {t('Value')}
        </NavLink>
        <NavLink
          activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
          to='/home/users'
          className='border-b-2 border-secundario pr-8'
        >
          {t('Users')}
        </NavLink>
        <NavLink
          activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
          to='/home/tips_total'
          className='border-b-2 border-secundario pr-8'
        >
          {t('Tips')}
        </NavLink>
      </div>
    </div>
  );
};

export default HomeTabs;
