import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import useRestaurant from 'components/yumminn/useRestaurant';
import { apiAuth } from 'logic/api';
import { useLazyAxios } from 'use-axios-client';
import { XIcon } from '@heroicons/react/outline';
import useDeleteModal from 'components/hooks/useDeleteModal';
import { sayError } from 'logic/defaults';
import CardProfilePhoto from 'components/yumminn/CardProfilePhoto';

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
  // name: '',
  // email: '',
};

const SettingsPhotosPage = ({ children }) => {
  const { t } = useTranslation();
  const selected =
  JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) 
  const { seleccionado } = useRestaurant();
  const [functionality, setFunctionality] = useState('')
  const [colors, setColors] = useState({primary: null, secondary: null, tertiary: null})
  const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants/${selected[0]?.value}/`,
  });

  React.useEffect(() => {
    //
      getData();
  }, [getData]);

  React.useEffect(() => {
    data && setFunctionality(data.functionality)
    data && data.sectors.forEach((sector) => {
      if(sector.functionality !== 'just_pay'){
        setFunctionality('multiple')
      }
    })
    data && setColors({primary: data.primary_color, secondary: data.secondary_color, tertiary: data.tertiary_color})
  }, [data])

  const uploadImage = async (type, file) => {
    let fd = new FormData();
    fd.append(type, file);

    try {
      await apiAuth(`/restaurants/${selected[0]?.value}/`, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: fd,
      });
      getData();
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const changeLogoWidth = async (value) => {
    const width = !value ? null : Math.min(Math.abs(Number(value)), 100)
    
    if (data.logo_width === width) {
      return;
    }

    if (typeof width !== 'number' && width !== null) {
      toast.error(t('invalid_value'))
      return;
    }

    try {
      await apiAuth(`/restaurants/${selected[0]?.value}/`, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: {
          logo_width: width
        },
      });
      toast.success(t('logo_size_changed'))
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const changeColor = async (color, value) => {
    if (data[color] === value) {
      return;
    }

    try {
      await apiAuth(`/restaurants/${selected[0]?.value}/`, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: {
          [color]: value
        },
      });
      toast.success(t('color_changed'))
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const uploadImages = async (type, file) => {
    let fd = new FormData();
    fd.append(type, file);
    fd.append('name', file.name);
    fd.append('restaurant', selected[0].value);

    try {
      await apiAuth(`/profile-photos/`, {
        method: 'POST',
        'Content-Type': 'multipart/form-data',
        data: fd,
      });
      getData();
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const handleDeleteLogo = async (restaurant_id) => {
    try {
      await apiAuth.patch(`/restaurants/${selected[0]?.value}/`, { logo: null });
      getData();
    } catch (error) {}
  };

  const handleDeleteChef = async (restaurant_id) => {
    try {
      await apiAuth.patch(`/restaurants/${selected[0]?.value}/`, {
        chef_photo: null,
      });
      getData();
    } catch (error) {}
  };

  const handleDeleteHomeImage = async (restaurant_id) => {
    try {
      await apiAuth.patch(`/restaurants/${selected[0]?.value}/`, {
        home_image: null,
      });
      getData();
    } catch (error) {}
  };

  const handleDeleteHomeImageOrderAndPayOrCollect = async (restaurant_id) => {
    try {
      await apiAuth.patch(`/restaurants/${selected[0]?.value}/`, {
        home_image_order_and_pay_or_collect: null,
      });
      getData();
    } catch (error) {}
  };

  const handleDeleteImage = async (photo_id) => {
    try {
      await apiAuth.delete(`/profile-photos/${photo_id}/`);
      getData();
    } catch (error) {}
  };

  const handleUpdateFavourite = async (e, photo) => {
    for (const image of data.profile_photos) {
      if (image.id === photo.id) {
        try {
          await apiAuth(`/profile-photos/${image.id}/`, {
            method: 'PATCH',
            data: { favourite: true },
          });
        } catch (error) {
          console.error(error);
          toast.error(sayError(error.response.data));
        }
      } else {
        try {
          await apiAuth(`/profile-photos/${image.id}/`, {
            method: 'PATCH',
            data: { favourite: false },
          });
        } catch (error) {
          console.error(error);
          toast.error(sayError(error.response.data));
        }
      }
    }
    getData();
  };

  const handleMove = async (photo, to) => {
    const photos = [...data.profile_photos];

    const first = photos.splice(photo.index, 1);
    photos.splice(to, 0, first[0]);

    for (const photo of photos) {
      try {
        await apiAuth(`/profile-photos/${photo.id}/`, {
          method: 'PATCH',
          data: { order: photos.indexOf(photo) },
        });
      } catch (error) {
        console.error(error);
        toast.error(sayError(error.response.data));
      }
    }
    getData();
  };

  const logoModal = useDeleteModal(handleDeleteLogo);
  const imageModal = useDeleteModal(handleDeleteImage);
  const homeImageModal = useDeleteModal(handleDeleteHomeImage);
  const homeImageModalOrderAndPayOrCollect = useDeleteModal(handleDeleteHomeImageOrderAndPayOrCollect);
  const chefModal = useDeleteModal(handleDeleteChef);
  
  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-col mb-6'}>
        {/* ***************************** */}
        {/* business logo */}
        
        <>
        <div className='font-bold text-[18px] mt-5'>{t('Business_logo')}</div>
        <div className='flex flex-col items-end w-[164px] h-[152px] bg-transparent border border-dashed border-gray-300 rounded-md p-2'>
          <div className=''>
            <button
              type='button'
              onClick={() => logoModal.openModal(seleccionado.value)}
            >
              <XIcon className='h-4 w-4 text-principal hover:text-medio' />
            </button>
          </div>
          <div className='w-[148px] h-[105px]'>
            <img
              className='object-cover w-[148px] h-[105px] '
              src={data.logo}
              alt={t('Business_logo')}
            />
          </div>
        </div>

        <div className='mt-4 border border-dashed border-gray-300 rounded-md'>
          <FilePond
            credits={false}
            labelIdle={`<div>${t(
              'Suelta tu imagen aquí o haz clic para '
            )}<span class="filepond--label-action"> ${t(
              'Upload Business logo'
            )}</span></div>`}
            name={'logo'}
            allowMultiple={false}
            allowDrop={true}
            allowReplace={true}
            allowRemove={true}
            onaddfile={(error, file) => {
              uploadImage('logo', file.file);
            }}
            acceptedFileTypes={['image/*']}
          />
        </div>
        </>

        <span className='font-bold text-[18px] mt-5'>
          {t('logo_size')}
          <span className='font-normal text-sm'>{' *' + t('optional')}</span>
        </span>
        <label className='flex items-center gap-2 p-2 w-[min-content] bg-white border border-gray-300 rounded-md cursor-pointer'>
          <input
            type='number'
            max={100}
            min={0}
            defaultValue={data.logo_width}
            onBlur={(event) => changeLogoWidth(event.target.value)}
            className='rounded border-2 border-gray-300 w-16 h-8 text-right'
          />
          %
        </label>
        
        {/* ***************************** */}

        {/* ***************************** */}
        {/* main image */}
        {(functionality === 'just_pay' || functionality === 'multiple') &&
        <>
        <div className='font-bold text-[18px] mt-5'>{t('Main_Image')}</div>
        <div className='flex flex-col items-end w-[164px] h-[152px] bg-transparent border border-dashed border-gray-300 rounded-md p-2'>
          <div className=''>
            <button
              type='button'
              onClick={() => homeImageModal.openModal(seleccionado.value)}
            >
              <XIcon className='h-4 w-4 text-principal hover:text-medio' />
            </button>
          </div>
          <div className='w-[148px] h-[105px]'>
            <img
              className='object-cover w-[148px] h-[105px] '
              src={data.home_image}
              alt={t('Main_Image')}
            />
          </div>
        </div>

        <div className='mt-4 border border-dashed border-gray-300 rounded-md'>
          <FilePond
            credits={false}
            labelIdle={`<div>${t(
              'Suelta tu imagen aquí o haz clic para '
            )}<span class="filepond--label-action"> ${t(
              'Upload_Main_Image'
            )}</span></div>`}
            name={'home_image'}
            allowMultiple={false}
            allowDrop={true}
            allowReplace={true}
            allowRemove={true}
            onaddfile={(error, file) => {
              uploadImage('home_image', file.file);
            }}
            acceptedFileTypes={['image/*']}
          />
        </div>
        </>} 
        {functionality !== 'just_pay' &&
        <>
        <div className='font-bold text-[18px] mt-5'>{t('Main_Image_OP')}</div>
        <div className='flex flex-col items-end w-[164px] h-[152px] bg-transparent border border-dashed border-gray-300 rounded-md p-2'>
          <div className=''>
            <button
              type='button'
              onClick={() => homeImageModalOrderAndPayOrCollect.openModal(seleccionado.value)}
            >
              <XIcon className='h-4 w-4 text-principal hover:text-medio' />
            </button>
          </div>
          <div className='w-[148px] h-[105px]'>
            <img
              className='object-cover w-[148px] h-[105px] '
              src={data.home_image_order_and_pay_or_collect}
              alt={t('Main_Image')}
            />
          </div>
        </div>

        <div className='mt-4 border border-dashed border-gray-300 rounded-md'>
          <FilePond
            credits={false}
            labelIdle={`<div>${t(
              'Suelta tu imagen aquí o haz clic para '
            )}<span class="filepond--label-action"> ${t(
              'Upload_Main_Image'
            )}</span></div>`}
            name={'home_image_order_and_pay_or_collect'}
            allowMultiple={false}
            allowDrop={true}
            allowReplace={true}
            allowRemove={true}
            onaddfile={(error, file) => {
              uploadImage('home_image_order_and_pay_or_collect', file.file);
            }}
            acceptedFileTypes={['image/*']}
          />
        </div>
        </>
        }
        <span className='font-bold text-[18px] mt-5'>
          {t('button_color')}
        </span>
        <label className='flex items-center gap-2 p-2 w-[min-content] bg-white border border-gray-300 rounded-md cursor-pointer'>
          {colors.primary?.toUpperCase()}
          <span className='w-8 h-8 rounded-full shadow-md' style={{backgroundColor: colors.primary}}/>
          <input
            type='color'
            defaultValue={data.primary_color}
            onInput={(event) => setColors(prev => ({...prev, primary: event.target.value}))}
            onBlur={() => changeColor('primary_color', colors.primary)}
            className='absolute opacity-0 w'
          />
        </label>
        <span className='font-bold text-[18px] mt-5'>
          {t('accent_color')}
          <span className='font-normal text-sm'>{' *' + t('optional')}</span>
        </span>
        <label className='flex items-center gap-2 p-2 w-[min-content] bg-white border border-gray-300 rounded-md cursor-pointer'>
          {colors.tertiary?.toUpperCase() || colors.primary?.toUpperCase()}
          <span className='w-8 h-8 rounded-full shadow-md' style={{backgroundColor: colors.tertiary || colors.primary}}/>
          <input
            type='color'
            defaultValue={data.teriary_color || data.primary_color}
            onInput={(event) => setColors(prev => ({...prev, tertiary: event.target.value}))}
            onBlur={() => changeColor('tertiary_color', colors.tertiary)}
            className='absolute opacity-0 w'
          />
          {!!colors.tertiary &&
            <button
              type='button'
              onClick={() => {
                setColors(prev => ({...prev, tertiary: null}));
                changeColor('tertiary_color', null);
            }}
            >
              <XIcon className='h-4 w-4 text-principal hover:text-medio' />
            </button>
          }
        </label>
        <span className='font-bold text-[18px] mt-5'>
          {t('background_color')}
        </span>
        <label className='flex items-center gap-2 p-2 w-[min-content] bg-white border border-gray-300 rounded-md cursor-pointer'>
          {colors.secondary?.toUpperCase()}
          <span className='w-8 h-8 rounded-full shadow-md' style={{backgroundColor: colors.secondary}}/>
          <input
            type='color'
            defaultValue={data.secondary_color}
            onInput={(event) => setColors(prev => ({...prev, secondary: event.target.value}))}
            onBlur={() => changeColor('secondary_color', colors.secondary)}
            className='absolute opacity-0 w'
          />
        </label>
        {/* ***************************** */}

        {/* ***************************** */}
        {/* Profile photos 
        <div className='mt-5'>
          <span className='font-bold text-[18px]'>{t('Profile_photos')}</span>
          <span className='ml-2 text-sm font-regular'>
            ({t('Drag_to_reorder')})
          </span>
        </div>
        <div className='flex flex-wrap'>
          {data.profile_photos.map((photo) => (
            <CardProfilePhoto
              key={photo.id}
              photo={photo}
              doMove={handleMove}
              doOpenModal={(id) => imageModal.openModal(id)}
              doHandleUpdateFavourite={(e, photo) =>
                handleUpdateFavourite(e, photo)
              }
            />
          ))}
        </div>

        <div className='mt-4 border border-dashed border-gray-300 rounded-md'>
          <FilePond
            credits={false}
            labelIdle={`<div>${t(
              'Suelta tu imagen aquí o haz clic para '
            )}<span class="filepond--label-action"> ${t(
              'Upload_Profile_photos'
            )}</span></div>`}
            name={'logo'}
            allowMultiple={true}
            allowDrop={true}
            allowReplace={true}
            allowRemove={true}
            onaddfile={(error, file) => {
              uploadImages('image', file.file);
            }}
            acceptedFileTypes={['image/*']}
          />
        </div>

         Chef photo 
        <div className='font-bold text-[18px] mt-5'>{t('Chef_photo')}</div>
        <div className='flex flex-col items-end w-[164px] h-[152px] bg-transparent border border-dashed border-gray-300 rounded-md p-2'>
          <div className=''>
            <button
              type='button'
              onClick={() => chefModal.openModal(seleccionado.value)}
            >
              <XIcon className='h-4 w-4 text-principal hover:text-medio' />
            </button>
          </div>
          <div className='w-[148px] h-[105px] '>
            <img
              className='object-cover w-[148px] h-[105px]'
              src={data.chef_photo}
              alt={t('Chef_photo')}
            />
          </div>
        </div>

        <div className='mt-4 border border-dashed border-gray-300 rounded-md'>
          <FilePond
            credits={false}
            labelIdle={`<div>${t(
              'Suelta tu imagen aquí o haz clic para '
            )}<span class="filepond--label-action"> ${t(
              'Upload Chef photo'
            )}</span></div>`}
            name={'chef_photo'}
            allowMultiple={false}
            allowDrop={true}
            allowReplace={true}
            allowRemove={true}
            onaddfile={(error, file) => {
              uploadImage('chef_photo', file.file);
            }}
            acceptedFileTypes={['image/*']}
          />
        </div>
        */}
        <imageModal.Modal mensaje={t('Delete_profile_photo')} />
        <chefModal.Modal mensaje={t('Delete_chef_photo')} />
        <logoModal.Modal mensaje={t('Delete_logo')} />
        <homeImageModal.Modal mensaje={t('Delete_main_image')} />
        <homeImageModalOrderAndPayOrCollect.Modal mensaje={t('Delete_main_image')} />
      </div>
    );
  }
};

export default SettingsPhotosPage;
