import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const RatingsTabs = ({ filter }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col py-4'>
      <div
        className={'flex flex-row text-[14px] text-secundario font-bold py-3'}
      >
        <NavLink
          exact={true}
          activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
          to={`/ratings`}
          className='border-b-2 border-secundario pr-8'
        >
          {t('Ratings_and_reviews')}
        </NavLink>
        <NavLink
          activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
          to={`/ratings/reviews`}
          className='border-b-2 border-secundario pr-8'
        >
          {t('Reviews')}
        </NavLink>
      </div>
    </div>
  );
};

export default RatingsTabs;
