import React, { useState, useEffect, useContext, useRef } from "react";
import Spinner from "common/Spinner";
import ShiftModal from "./OrderAndPay/Modals/ShiftModal";
import ShiftsTable from "./Shifts/ShiftsTable";
import DeleteShiftModal from "./OrderAndPay/Modals/DeleteShiftModal";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import { toast } from "react-toastify";
import { baseAuth } from "logic/api";
import { newShiftMock } from "./Shifts/newShiftMock";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const Shifts = () => {
  
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();
  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
	const hasActiveOrderAndPayTab = restaurants.some(item => item.show_order_and_pay_tab_on_backoffice)
  const [shifts       , setShifts]        = useState([]);
  const [l3DishCount  , setL3DishCount]   = useState([]);
  const [shiftToRemove, setShiftToRemove] = useState(null);
  const [shiftToEdit  , setShiftToEdit]   = useState(null);
  const [shiftToCreate, setShiftToCreate] = useState(null);
  const [loading      , setLoading]       = useState(false);

  useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setShifts([])
		if (selectedRestaurant && selectedRestaurant.length > 0) {
			getData();
		}
	}, [selectedRestaurant])


  const getData = async () => {
		setLoading(true)
		await baseAuth.get(`/restaurant/shifts/${selectedRestaurant[0].value}`)
			.then((response) => {
				const shifts = response.data.shifts
        const l3_dish_count = response.data.l3_dish_count
				setShifts(shifts)
        setL3DishCount(l3_dish_count)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

  const updateShift = async (shift, is_remove) => {
		setLoading(true)
		const newShift = JSON.parse(JSON.stringify(shift))
		newShift["type"] = is_remove ? "0" : "1"
		const body = {"shift": newShift}
		await baseAuth.post(`/restaurant/update_shift`, body)
		  .then((response) => {
        const shifts = response.data.shifts
        setShifts(shifts)
        setShiftToEdit(null)
        setShiftToCreate(null)
        setShiftToRemove(null)
        setLoading(false)
		  })
		  .catch((e) => {
        if (e.response.data.error == "E2") {
          toast.error("El horario seleccionado colapsa con otro turno existente.");
        } else if (e.response.data.error == "E1") {
          toast.error("Información del turno no enviada correctamente. Recargue la página y pruebe de nuevo.");
        }else {
          toast.error("Error al guardar el turno.");
        }
			setLoading(false)
		  })
	  }

    const handleCreate = () => {
      newShiftMock["restaurant"] = selectedRestaurant[0].value
      setShiftToCreate(newShiftMock)
    }

  if (!hasActiveOrderAndPayTab) return <UnavailableFunctionality title={'Orders'} />
  return (
    <> 
      <button
        style={{width: '225px'}}
        className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
        onClick={() => handleCreate()}>Crear turno</button>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {loading && <LoadingOverlay/>}
       
        <div className="flex-auto w-full">
            <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: '90%'}}>
              <ShiftsTable shifts={shifts} setShifts={setShifts} setLoading={setLoading} setShiftToRemove={setShiftToRemove} setShiftToEdit={setShiftToEdit}/>
            </div>
        </div>
      </div>
      {shiftToRemove &&
				<DeleteShiftModal t={t} removeShift={updateShift} shift={shiftToRemove} setShiftToRemove={setShiftToRemove}/>
			}
      {shiftToEdit &&
				<ShiftModal t={t} shift={shiftToEdit} setShiftToEdit={setShiftToEdit} saveShift={updateShift} setShiftToRemove={setShiftToRemove} type={0} l3DishCount={l3DishCount} setL3DishCount={setL3DishCount}/>
			}
      {shiftToCreate &&
				<ShiftModal t={t} shift={shiftToCreate} setShiftToEdit={setShiftToCreate} saveShift={updateShift} setShiftToRemove={setShiftToRemove} type={1} l3DishCount={l3DishCount} setL3DishCount={setL3DishCount}/>
			}
    </>
  );
};

export default Shifts;
