import React from 'react';
import { abbreviateBigNumbers } from 'logic/functions';
import { PaymentsLayout } from './PaymentsLayout';

export const PaymentsOverview = ({data, dates}) => {
  const formatAmounts = (num = 0) => abbreviateBigNumbers(num / 100);
  
  return (
    <PaymentsLayout
      title='total_payments'
      infoOptions={dates}
      period={{payments_period: data?.total_period}}
      metrics={{
        transactions: {...data?.transactions, value: abbreviateBigNumbers(data?.transactions?.value)},
        average_ticket: {...data?.average_ticket, value: formatAmounts(data?.average_ticket?.value) + '€'},
      }}
      current={{
        today: data?.today,
        last_week: data?.last_week,
        last_month: data?.last_month,
      }}
    />
  );
};
