import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { SelectInput } from '.';
import { useTranslation } from 'react-i18next';
import { components } from "react-select";
import { Icon, IconType } from 'common/Icon';

export const SearchInput = ({getSuggestions, onSelect, ...selectProps}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const deboncedGetSuggestions = useCallback(
    debounce(async value => {
      if (!value) return;

      try {
        setIsLoading(true);
        const newSuggestions = await getSuggestions?.(value);
        setSuggestions(newSuggestions);
      } catch (error) {
        console.log(`file: SearchInput.jsx:24 > SearchInput > error:`, error)
      }
      
      setIsLoading(false);
    }, 500)
  , [getSuggestions]);

  const onChange = newValue => {
    setInputValue(newValue);
    deboncedGetSuggestions(newValue);
  };

  return (
    <SelectInput
      {...selectProps}
      inputValue={inputValue}
      onInputChange={onChange}
      options={suggestions}
      loading={isLoading}
      loadingMessage={() => t('searching')}
      noOptionsMessage={() => inputValue ? t('no_results') : t('write_to_search')}
      components={{DropdownIndicator, ...selectProps.components}}
      onChange={onSelect}
      styles={styles}
      isMulti
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon type={IconType.SEARCH}/>
    </components.DropdownIndicator>
  );
};

const styles = {
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'inherit',
    fill: 'currentColor',
    rotate: 'none',
  }),
};