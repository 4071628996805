import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { useMediaQuery } from 'hooks/useMediaQuery';
import MenuContainer from 'layouts/MenuContainer';
import { NavigatorContext } from 'data/navigatorContext';
import DateRangeSelect from "components/yumminn/DateRangeSelect";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const WalletLayout = ({ children }) => {
  const { t } = useTranslation();
  const { state: { open } } = useContext(NavigatorContext);
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();
  const path = history.location.pathname;

  return (
    <div id="WalletLayout" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
      <MenuHeader title={t('wallet')} route_group='wallet'/>
 
      <div className=''>
        <div className={`${path !== "/wallet/settings" ? 'flex-row' : ''} flex justify-between items-center w-full bg-white`}>
          <div className={`${path !== "/wallet/settings" ? 'flex flex-col lg:flex-row items-center w-full gap-5 bg-white' : 'flex-1'} px-[32px] py-4 `}>
            <RestaurantSelect />
            {path !== "/wallet/settings" && <DateRangeSelect hourFormat={false} defaultRange='today' open={open}/>}
          </div>   
        </div>
        <div className='px-[32px]'>
          <div>{children}</div>
        </div>
      </div>
      </div>
    </div>

  );
};

export default WalletLayout;
