import { mockThemes } from 'components/previews/constants';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

export const ThemeSwatch = ({ theme }) => {
  if (!theme) return <></>;

  return (
    <ThemeProvider theme={mockThemes[theme]}>
      <Container>
        <div/>
        <div/>
        <div/>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  display: flex;
  
  div {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-left: -12px;
    box-shadow:
      0px 2px 4px #00000018,
      0px 3px 12px #00000012;
  }
  
  div:nth-of-type(1) {
    background-color: ${({theme}) => theme.background_sunken};
    margin: 0;
  }
  
  div:nth-of-type(2) {
    background-color: ${({theme}) => theme.background};
  }
  
  div:nth-of-type(3) {
    background-color: ${({theme}) => theme.background_rised};
    border: 1px solid ${({theme}) => theme.card_border_color};
  }
`;