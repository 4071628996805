import { TrashIcon } from '@heroicons/react/outline';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Popup } from 'common/Popup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const RemoveLanguagePopup = ({isOpen, languageToRemove, confirm, cancel}) => {
  const { t } = useTranslation();

  return (
    <Popup isOpen={isOpen} close={cancel}>
      <Container>
        <TrashIcon height={50}/>
        <h2>{t('remove_translations_in')} <span style={{textTransform: 'lowercase'}}>{t(languageToRemove)}</span></h2>
        <p>{t('warning_message_remove_translations_in')} <span style={{textTransform: 'lowercase'}}>{t(languageToRemove)}</span>?</p>  
        <div>
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            label={t('no_cancel')}
            onClick={cancel}
          />
          <Button
            label={t('yes_delete')}
            onClick={confirm}
          />
        </div>
      </Container>
    </Popup>
  );
};

const Container = styled.div`
    width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    max-width: 50ch;
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    max-width: 50ch;
  }

  & > div {
    width: max-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }
`;