import { select_styles } from 'logic/defaults';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import "./DateSelect.css";
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { components } from "react-select";
import './RestaurantSelect.css'
import {apiAuth} from 'logic/api';
import {toast} from 'react-toastify';

export default function AccountManagerSelect({accountManagerSelected, setAccountManagerSelected}) {
  const { t } = useTranslation();
  const [users, setUsers] = React.useState(null);
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();

  React.useEffect(() => {
    getDataUsers();
  }, [])

  const getDataUsers = async () => {
    try {
      const res_obj = await apiAuth.get(`/users/get_account_managers`);
      const res_data = res_obj.data;
      if (res_data){
        const usersList = res_data?.map(u => ({id: u.id, name: u.email})).filter((u => u.name !== undefined));
        usersList.unshift({id: null, name: t("Todos")})
        setUsers(usersList)
      }
    } catch (error) {
      toast.error(error);
    }
  }

  //Provoca 2 loading al cargar el dashboard, no he podido encontrar otra manera limpia de hacerlo
  // React.useEffect(() => {
  //   if(!accountManagerSelected && users){
  //       setAccountManagerSelected(users[0])
  //   }
  // }, [users, accountManagerSelected, setAccountManagerSelected])


  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null()}
          />{" "}
          <span></span>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleSelect = (opcion) => {
    setAccountManagerSelected(opcion);
  };

  const location = history.location.pathname
  
  return (
    <div className={`flex items-center space-x-2 ${!isRowBased && location !== '/admin/reports' ? " my-3 w-1/3" : location === '/admin/reports' ? "w-2/3" : "w-full"}  ${isRowBased && 'justify-center'}`} style={{fontFamily: 'Poppins, sans-serif', fontSize: isRowBased ? "14px" : "16px", fontWeight: 500, display: user.is_investor ? "none" : ""}}>
      <div style={{whiteSpace: location !== '/admin/reports' ? 'nowrap' : ''}}>{t('Account Manager')}</div>
      <Select
        isMulti={false}
        menuPortalTarget={document.body}
        multiple={true}
        isClearable={false}
        components={{
          Option
        }}
        closeMenuOnSelect={false}
        styles={select_styles}
        hideSelectedOptions={false}
        className={isRowBased ? '' : 'w-full max-w-md text-[14px]'}
        name={'accountManager'}
        options={users}
        value={accountManagerSelected}
        onChange={(a) => {
          handleSelect(a);
        }}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
      />
    </div>
  );
}
