import React from "react";
import { useTranslation } from "react-i18next";
import {useMediaQuery} from '../../../src/hooks/useMediaQuery'
import { ChevronLeftIcon } from '@heroicons/react/outline';

const useModal = () => {
  const { t } = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const openModal = (item) => {
    setShowConfirmModal(true);
  };

  const closeModal = () => {
    setShowConfirmModal(false);
    const title = document.querySelector('#title')
    const topBar = document.querySelector('#topBar')
    title && title.setAttribute('style', 'z-index: 999999')
    topBar && topBar.setAttribute('style', 'z-index: 99999')
  };

  const ModalButton = (props) => (
    <button
      onClick={() => setShowConfirmModal(true)}
      className="btn btn-primary modal-button"
    >
      {t("abre_modal")}
    </button>
  );

  const Modal = ({ children }) => {
      return (
        <div>
          <input defaultChecked={showConfirmModal} type="checkbox" id="popup" className="modal-toggle" /> 
          <div className="modal">
            <div className="modal-box">
            {isRowBased && 
            <div className='text-[16px] pb-6 cursor-pointer'>
              <ChevronLeftIcon onClick={() => closeModal()} className='h-6 w-6 hover:text-medio' />
            </div>}
              <div>{children}</div>
              {!isRowBased && <div className="modal-action">
                <button onClick={() => closeModal()} className="btn">
                  {t("cerrar")}
                </button>
              </div>}
            </div>
          </div>
        </div>
      )
  }

  return {
    Modal,
    ModalButton,
    openModal
  };
};

export default useModal;
