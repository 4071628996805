import React from "react";
import { Slide, ToastContainer } from "react-toastify";

const LoginContainer = ({ children }) => (
  <div>
    <div>{children}</div>
    <ToastContainer
      position="top-center"
      hideProgressBar
      autoClose={2000}
      transition={Slide}
      closeOnClick={true}
    />
  </div>
);

export default LoginContainer;
