import { awareDatetimeString, centsToAmountString } from 'logic/functions';
import React from 'react';
import { useTranslation } from "react-i18next";
import OrderProductRow from './OrderProductRow';

const OrderGeneralNotes = ({note, fontSize}) => {

  const { t } = useTranslation();

  return (
    <div style={{marginTop: "15px", fontSize: fontSize, fontWeight: 400, border: "1px solid #D4D4D4", padding: "15px", borderRadius: "10px", backgroundColor: "#FBFBFB"}}>
      <div>
      💬 {t("comments")}:
      </div>
       <div style={{fontStyle: "italic", fontWeight: 600}}>{note}</div>
	  </div>
  );
};
export default OrderGeneralNotes;
