import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput } from 'common/Input';
import styled from 'styled-components';
import { components }  from 'react-select';
import { Icon } from 'common/Icon';
import './Translations.css'


export const LanguageSelector = ({ allLanguages, availableLanguages, handleChange, disabled }) => {
  const { t } = useTranslation();
  const selectorOptions = allLanguages.filter(ln => availableLanguages.includes(ln.value))
  return (
    <SelectInput
      id={'lang_sel_inp'}
      placeholder={t('languages')}
      options={allLanguages}
      value={selectorOptions}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      isClearable={false}
      onChange={handleChange}
      components={{ MultiValue, Option, ValueContainer }}
      styles={language_selector_styles}
      disabled={disabled}
      isSearchable={false}
    />      
  );
};

const MultiValue = (props) => {
  const { t } = useTranslation();
  return (
    <components.MultiValue {...props}>
      <ValueContainerContainer>
        {t('languages')}
      </ValueContainerContainer>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const { t } = useTranslation();
  const length = children[0].length
  let tmpChildren = [...children];
  if(length >= 1){
    tmpChildren[0] = t(`languages`)
  }
  return (
    <components.ValueContainer {...props}>
      <ValueContainerContainer>
          {tmpChildren}
      </ValueContainerContainer>
    </components.ValueContainer>
  );
};

const Option = (props) => {
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <OptionContainer color={props.data.color}>
        <div className='w-full flex justify-between items-center'>
          <div className='flex gap-4'>
            <SelectorContainer color={props.data.color}>
              {<Icon type={props.data.value.toUpperCase()}/>}
            </SelectorContainer>
            {t(props.data.label)}
          </div>
          <input
            className="styledCheckbox"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null()}
          />{" "}
          <span></span>
        </div>
      </OptionContainer>
    </components.Option>
  );
};

export const SelectorContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  color: ${({color}) => color};
  background: #FFF;
  border-radius: 2rem;
  overflow: hidden;

  svg {
    height: 100%;
    width: 100%;
    background-color: ${({color}) => color + 40};
  }
`;

export const OptionContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  white-space: nowrap;
`;

export const ValueContainerContainer = styled.div`
  width: 100%;
  height: 100%;
  color: #094553;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
`;

export const language_selector_styles = {
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      margin: 0,
    };
  },
  control: styles => {
    return {
      ...styles,
      height: '40px',
      width: '200px',
      borderRadius: '50px',
      color: "#094553",
      borderColor: "#094553"
    };
  },
  option: (styles) => {
    return {
      ...styles,
      color: "#000000",
      backgroundColor: "#FFFFFF"
    };
  },
};
