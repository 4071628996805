import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import RentabilibarLeads from "common/RentabilibarLeads";


export const Sales = () => {
  const { t } = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");


  return (
    <>
        <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        {!isRowBased &&
          <h1 className={`font-bold text-[#094553] text-[24px] px-[0px] right-0 py-5 w-full bg-[#E2FEF1] pl-8`} >
            {t('Rentabilibar')}
          </h1>}
        <RentabilibarLeads/>
        </div>
        
    </>
  );
}