import { DISCOUNT_TYPOLOGIES } from 'pages/discounts/components/constants';
import React from 'react'
import { PromotionPreview } from './PromotionPreview';
import CouponPreview from './CouponPreview';
import { DiscountedProductPreview } from './DiscountedProductPreview';
import { MobileMockup } from '../MobileMockup';

export const DiscountPreview = ({discount, product}) => {
  if (discount?.typology === DISCOUNT_TYPOLOGIES.GENERAL) return <MobileMockup><PromotionPreview discount={discount}/></MobileMockup>;
  if (discount?.typology === DISCOUNT_TYPOLOGIES.COUPON) return <CouponPreview discount={discount}/>;
  if (discount?.typology === DISCOUNT_TYPOLOGIES.PRODUCT) return <DiscountedProductPreview discount={discount} product={product}/>;

  return <></>;
};