import React from 'react';
import { useTranslation } from "react-i18next";
import BookingShiftsHeader from './BookingShiftsHeader';
import BookingShiftRow from './BookingShiftRow';
import noSchedule from '../../../../img/NoSchedule.svg'

const ShiftsTable = ({shifts, setShifts, setLoading, setShiftToRemove, setShiftToEdit}) => {
	return (
	<div>
		<BookingShiftsHeader disabled={shifts.length < 1}/>
		<div> 
			{shifts?.map((shift, index) =>
				<div key={'shift' + index}>
					<BookingShiftRow shift={shift} setShifts={setShifts} setLoading={setLoading} setShiftToRemove={setShiftToRemove} setShiftToEdit={setShiftToEdit}/>
				</div>	
			)}
			{shifts.length < 1 && 
        	<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: "10px", padding: "50px 0px", fontSize: "16px"}}>
				<img src={noSchedule} style={{height: "60px"}} alt='no_schedule' />
				<div><span style={{fontWeight: 600}}>Crea los turnos</span> de tu restaurante</div>
			</div>
			}
		</div>
	</div>
  );
};
export default ShiftsTable;
