import React from 'react';
import { useTranslation } from "react-i18next";
import { awareDatetimeString } from 'logic/functions';
import BookingClientInfo from './BookingDetailComponents/BookingClientInfo';
import BookingGeneralInfo from './BookingDetailComponents/BookingGeneralInfo';
import BookingActionButton from './BookingDetailComponents/BookingActionButton';

const BookingDetail = ({booking, setBookingToCancel, setBookingToEdit}) => {
  const { t } = useTranslation();
  return (
	<div style={{padding: '20px'}}>
		<div style={{display: 'flex', justifyContent: "space-between", alignItems: 'center', width: '100%'}}>
			<p style={{fontWeight: 500, fontSize: '22px', color: '#222222'}}>#{t('Booking')}: <span style={{fontWeight: 700}}>{booking.code}</span></p>
			{booking.canceled && 
				<p style={{fontWeight: 700, fontSize: '22px', color: '#C44A4A', marginTop: 0}}>CANCELADA</p>
			}
		</div>
		<div style={{fontWeight: 500, marginTop: "5px"}}>{awareDatetimeString(booking.created_at, "Europe/Madrid")}</div>
		<div style={{fontWeight: 500, marginTop: "5px"}}>{booking.restaurant__name}</div>
		<BookingClientInfo booking={booking}/>		
		<BookingGeneralInfo booking={booking}/>
		{!booking.canceled ? 
		<div style={{display: 'flex', justifyContent: 'space-between', marginTop: "15px", gap: "15px"}}>
			<BookingActionButton onClick={() => setBookingToCancel(booking)} text={t(`Cancelar reserva`)} style={{backgroundColor: "#DFA395"}}/>
			<BookingActionButton onClick={() => setBookingToEdit(booking)} text={t(`Modificar reserva`)} style={{backgroundColor: "#094553"}}/>
		</div>
		:
		<div style={{marginTop: "15px", fontSize: "13px", fontWeight: 500, backgroundColor: "#F8F8F8DA", padding: "15px 20px", borderRadius: "10px"}}>
			<p style={{fontSize: "16px", fontWeight: 600}}>Motivo</p>
			<p style={{fontSize: "14px", fontWeight: 500, fontStyle: 'italic'}}>{booking.cancel_reason ? booking.cancel_reason : "No se ha específicado el motivo de la cancelación"}</p>
		</div>
		}
	</div>
  );
};
export default BookingDetail;
