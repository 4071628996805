import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import React, { useContext, useRef } from 'react';
import { AccessContext } from './AccesContext';
import { AccessManagementContainter } from './AccessManagementContainers';
import { AccessTable } from './AccessTable';
import { TextInput } from 'common/Input';
import Spinner from 'common/Spinner';
import { AccessTypeModal } from './AccessTypeModal';
import { InfoTooltip } from 'common/InfoTooltip';
import { ImportResultsModal } from './ImportResultsModal';
import { Trans, useTranslation } from 'react-i18next';

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const EmailManagement = () => {
  const { t } = useTranslation();

  const {
    pages,
    search,
    importResults,
    isLoading,
    isImporting,
    importFile,
    downloadEmptySheet,
    setSearch,
    closeImportResults,
  } = useContext(AccessContext);

  const csvInputRef = useRef(null);

  return (
    <AccessManagementContainter>
      <header>
        <Button
          label='download_sheet'
          rightIcon={<Icon type={IconType.DOWNLOAD}/>}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={downloadEmptySheet}
        />
        <Button
          label='upload_file'
          rightIcon={<Icon type={IconType.UPLOAD}/>}
          onClick={() => csvInputRef.current?.click()}
          loading={isImporting}
        />
        <InfoTooltip>
          <h5>{t('info_access_import_file')}</h5>
          <p>
            <Trans
              i18nKey='info_access_import_template'
              defaults='Recuerda que el archivo deberá <b>respetar los campos</b> de la plantilla.'
              components={{ b: <b/>}}
            />
          </p>
        </InfoTooltip>
        <input
          ref={csvInputRef}
          type='file'
          accept='.csv,.xlsx'
          onChange={importFile}
        />
        <TextInput
          value={search}
          rightIcon={<Icon type={IconType.SEARCH}/>}
          placeholder='search_by_email'
          onChange={e => setSearch(e.target.value)}
        />
      </header>
      <AccessTable type='email' regexValidator={EMAIL_REGEX}/>
      <AccessTypeModal/>
      <ImportResultsModal
        type='email'
        importResults={importResults}
        numEntries={pages.num_entries}
        close={closeImportResults}
      />
      {isLoading &&
        <div className='overlay'>
          <Spinner/>
        </div>
      }
    </AccessManagementContainter>
  );
};

export default EmailManagement;