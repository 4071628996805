import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Buttom = styled.div`
  border: 1px #094553 solid;
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  color: #094553;
  padding: 16px 24px;
  height: 35px;
  
  &:hover {
    background-color: #B8F0D9;
  }
`;


const NewFeaturesButton = ({title, click}) => {
  const history = useHistory();

  const handleNavigation = (click) => {
    history.push(click);
    window.scrollTo(0, 0);
  };

  return  (
    <>
      <Buttom
        onClick={() => handleNavigation(click)}
        className={`flex place-items-center hover:cursor-pointer whitespace-nowrap`}
      >
        {title}
      </Buttom>
     
    </>
  )
}

const NewFeaturesButtomCard = ({ title, click }) => {

  return (
    <div
      className={`text-[#094553] pt-5 w-full flex flex-row justify-center items-center gap-3`}
    >
        
      <NewFeaturesButton title={title} click={click}/>
      
    </div>
  );
};

export default NewFeaturesButtomCard;
