import React, { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const CoverPreview = ({color, logo}) => {
  const { t } = useTranslation();

  const logoRef = useRef(null);
  const [ratio, setRatio] = useState(0);

  const getRatio = () => {
    const logo = logoRef.current;
    const logoHeight = logo?.naturalHeight;
    const logoWidth = logo?.naturalWidth;
    setRatio(logoWidth/logoHeight);
  };

  const getImgStyles = (ratio) => ({
    height: ratio >= 1 ? '30%' : '50%',
    width: ratio >= 1 ? '70%' : '30%',
    minWidth: '30%',
    minHeight: '50%',
    objectFit: 'contain',
  })

  return (
    <div className='flex justify-center items-center w-full h-full transition-colors' style={{backgroundColor: color}}>
      {logo ?
        <img src={logo} ref={logoRef} onLoad={getRatio} className='transition-all' style={getImgStyles(ratio)}/>
        :
        <div className='text-gray-300 border-2 border-current border-dashed rounded-lg py-4 px-2 filter drop-shadow'>
          {t('your_logo')}
        </div>
      }
    </div>
  );
};
