import React, { useState } from 'react';
import { useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronLeftIcon, FilterIcon } from '@heroicons/react/outline';
import { ProductCardMockup } from '../components';
import styled, { keyframes } from "styled-components";
import {CustomGroupProductCardMockup} from '../components/CustomGroupProductCardMockup'
import { PhotographIcon, SelectorIcon } from '@heroicons/react/outline'
import { PlusCircleIcon } from '@heroicons/react/solid'
import { centsToAmountString } from "logic/functions";

const STYLES = {
  SUPERGROUP: 'text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] border border-[#404040] text-[#FFFFFF] bg-[#5E5E5E] box-border whitespace-nowrap ',
  SELECTED_SUPERGROUP: 'text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px]  bg-[#CEF5E5] !border-[#404040] text-[#404040] ',
  GROUP: 'bg-[#F0F0F0] text-[#909090] border border-[#A0A0A0]  text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] box-border whitespace-nowrap flex items-center gap-[2px] ',
  COLAPSED_GROUP: 'bg-white text-[#404040] border border-[#404040] text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] box-border whitespace-nowrap flex items-center gap-[2px] ',
  EXPANDED_GROUP: 'bg-[#CEF5E5] text-black text-[#404040]  border border-[#404040] text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] box-border whitespace-nowrap flex items-center gap-[2px] ',
  CATEGORY: 'text-[8px] bg-[#F0F0F0]  rounded-full leading-[1em] font-medium rounded-full p-[4px] whitespace-nowrap ',
  OVERLAY: 'w-full h-full bg-[#FFF8] absolute inset-0 fade-in ',
  SELECTED_CATEGORY: 'text-[8px] bg-[#CEF5E5] text-[#404040] rounded-full leading-[1em] font-medium rounded-full p-[4px] whitespace-nowrap ',
};

const CategoryTitle = styled.div`
  white-space: nowrap;
  font-size: 10px;
  font-weight: 650;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin: 2px 6px 0 6px;
`;

const OuterWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  marginTop: 0px;
  width: 100%;
`;

const ImgBannerWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-item: center;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const TitleOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 45px 0;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding: 0 0 0 10px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
`;

const ImgWrapper = styled.div`
  margin: 20px 0 10px 0;
  img {
    width: 45%;
    object-fit: contain;
  }
`;

const TagWrapper = styled.div`
  color: white;
  display: flex;
  p {
    text-align: center;
    border-radius: 9999px;
    padding: 2px 5px;
    margin: 0;
    background-color: #404040;
    font-weight: 600;
    font-size: 7px;
  }
`;
const SwimLineWrapper = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
justify-content: ${(props) => props.scrollable ? "auto" : "center"};
padding: 0px 15px 10px;
margin-top: -45px;
overflow: hidden;
scrollbar-width: none;
-ms-overflow-style: none;

&::-webkit-scrollbar {
  display: none;
}
`;

const getSrc = file => {
  if (!file) return null;
  if (typeof file === 'string') return file;
  
  try {
    return URL.createObjectURL(file);
  } catch (error) {
    console.log("🚀 ~ getSrc ~ file:", file)
    console.error(error);
    return null;
  };
};

export const CustomGroupPreview = ({catalog, customGroupDraft}) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);
  
  const defaultProductImage = getSrc(customGroupDraft.default_product_image);
  const is_restaurant = customGroupDraft.restaurant != null
  const is_super_group = customGroupDraft.l1 != null
  const is_category_group = customGroupDraft.l2 != null
  const is_product_category= customGroupDraft.l3 != null
  let supergroups = catalog?.supergroups ? catalog.supergroups : []
  let category_groups = catalog?.category_groups ? catalog.category_groups : []
  let product_categories = catalog?.product_categories ? catalog.product_categories : []
  let selected_supergroup = null
  let selected_category_group = null
  let selected_product_category = null
  if (is_super_group) {
    selected_supergroup = supergroups.find(s_g => s_g.id == customGroupDraft.l1)
    supergroups = selected_supergroup ? supergroups.filter(s_g => s_g.id != customGroupDraft.l1) : supergroups
    let supergroup_category_groups = category_groups.filter(c_g => c_g.category_supergroup == customGroupDraft.l1)
    selected_category_group = supergroup_category_groups ? supergroup_category_groups[0] : null
    category_groups = selected_category_group ? supergroup_category_groups.filter(s_c_g => s_c_g != selected_category_group) : category_groups
    let category_group_product_categories = selected_category_group ? product_categories.filter(p_c => p_c.category_group == selected_category_group.id) : product_categories
    selected_product_category = category_group_product_categories[0]
    product_categories = product_categories.filter(p_c => p_c != selected_product_category)
  } else if (is_category_group) {
    selected_category_group = category_groups.find(c_g => c_g.id == customGroupDraft.l2)
    category_groups = selected_category_group ? category_groups.filter(c_g => c_g.id != customGroupDraft.l2) : category_groups
    selected_supergroup = selected_category_group ? supergroups?.find(s_g => s_g.id == selected_category_group.category_supergroup) : null
    supergroups = selected_supergroup ? supergroups?.filter(s_g => s_g != selected_supergroup) : supergroups
    let category_group_product_categories = selected_category_group ? product_categories.filter(p_c => p_c.category_group == selected_category_group.id) : product_categories
    selected_product_category = category_group_product_categories[0]
    product_categories = product_categories.filter(p_c => p_c != selected_product_category)
  } else if (is_product_category) {
    selected_product_category = product_categories.find(p_c => p_c.id == customGroupDraft.l3)
    product_categories = product_categories.filter(p_c => p_c.id != customGroupDraft.l3)
    selected_category_group = category_groups?.find(c_g => c_g.id == selected_product_category.category_group)
    category_groups = selected_category_group ? category_groups?.filter(c_g => c_g != selected_category_group) : category_groups
    selected_supergroup = selected_category_group ? supergroups?.find(s_g => s_g.id == selected_category_group.category_supergroup) : supergroups
    supergroups = supergroups?.filter(s_g => s_g != selected_supergroup)
  } else {
    selected_supergroup = supergroups.length > 0 ? supergroups[0] : null
    supergroups = selected_supergroup ? supergroups.filter(s_g => s_g != selected_supergroup) : []
    let supergroup_category_groups = selected_supergroup ? category_groups?.filter(c_g => c_g.category_supergroup == selected_supergroup.id) : []
    selected_category_group = supergroup_category_groups.length > 0 ? supergroup_category_groups[0] : category_groups.length > 0 ? category_groups[0] : null
    category_groups = selected_category_group ? category_groups?.filter(c_g => c_g != selected_category_group) : []
    let category_group_product_categories = selected_category_group ? product_categories?.filter(p_c => p_c.category_group == selected_category_group.id) : []
    selected_product_category = category_group_product_categories.length > 0 ? category_group_product_categories[0] : product_categories.length > 0 ? product_categories[0] : null
    product_categories = selected_product_category ? product_categories?.filter(p_c => p_c != selected_product_category) : []
  }
  useEffect(() => {
    setImgError(false)
  }, [customGroupDraft.image]);
  return (
    <div className='w-full h-full flex flex-col'>
      {(supergroups.length > 0 || selected_supergroup) &&
      <div className='w-full p-1 pb-[2px] flex gap-1 relative bg-[#404040] '>
        <ChevronLeftIcon width={13} className='flex-shrink-0 text-white'/>
        {selected_supergroup &&
          <span className={STYLES.SELECTED_SUPERGROUP}>{selected_supergroup?.tpv_name ? selected_supergroup?.tpv_name : selected_supergroup?.name}</span>
        }
        {supergroups.map((supergroup) => (
            <span className={STYLES.SUPERGROUP}>{supergroup.tpv_name ? supergroup.tpv_name : supergroup.name}</span>
        ))}
        <FilterIcon width={13} className='flex-shrink-0 ml-auto opacity-0'/>
      </div>}
      <div className='w-full flex-1 flex flex-col relative'>
      {(category_groups.length > 0 || selected_category_group) &&
        <div className='w-full px-2 py-[2px] flex items-center gap-1 relative'>
        {selected_category_group &&
          <span className={STYLES.EXPANDED_GROUP}>{selected_category_group.tpv_name ? selected_category_group.tpv_name : selected_category_group.name}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
            </span>        }
        {category_groups.map((category_group) => (
            <span className={STYLES.GROUP}>{category_group.tpv_name ? category_group.tpv_name : category_group.name}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
            </span>
        ))}
        </div>}
        <div className='w-full px-2 py-[2px] text-[#A0A0A0] flex items-center gap-1'>
        {selected_product_category &&
          <span className={STYLES.SELECTED_CATEGORY}>
          {selected_product_category.tpv_name ? selected_product_category.tpv_name : selected_product_category.name}
        </span>       
        }
        {product_categories.map((product_category) => (
              <span className={STYLES.CATEGORY}>{product_category.tpv_name ? product_category.tpv_name : product_category.name}</span>
            ))}
          </div>
          {!is_restaurant &&
        <CategoryTitle>
          {selected_product_category?.tpv_name ? selected_product_category?.tpv_name : selected_product_category?.name}
          <hr style={{borderRadius:'10px', width: '90%', margin: '10px 0px', border: 'none', borderTop: '1px solid '}}/>
        </CategoryTitle>}
        {customGroupDraft.custom_group_type == "commercial" ? 
        <OuterWrapper style={{backgroundColor: "gray"}}>
          <ImgBannerWrapper>
            {customGroupDraft.image && !imgError ? 
            <img src={typeof customGroupDraft.image == "string" ? customGroupDraft.image : URL.createObjectURL(customGroupDraft.image)} onError={() => setImgError(true)} alt='' /> :     
            <PhotographIcon style={{paddingTop: "10px"}} height={60} className='text-white'/>
          }       
          </ImgBannerWrapper>
        </OuterWrapper> :
        customGroupDraft.custom_group_type == "individual" ? 
        <OuterWrapper style={{marginTop: "5px", flexDirection: "row", backgroundColor: customGroupDraft.secondary_color}}>
          <TitleOuterWrapper style={{alignItems: "flex-start", padding: "5px 0px 0px 5px", backgroundColor: customGroupDraft.primary_color}}>
            <TagWrapper style={{margin: '5px 0px 10px 2px'}}>
              <p>{customGroupDraft.tag ? customGroupDraft.tag : "⭐ Etiqueta ⭐"}</p>
            </TagWrapper>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "40px", gap: "5px"}}>
            <TitleWrapper style={{padding: "0 0 0 2px", textAlign: 'left', color: customGroupDraft.title_color, fontSize: "8px"}}>
              {customGroupDraft.name ? customGroupDraft.name : "Titulo del destacado"}
            </TitleWrapper>
            <TitleWrapper style={{padding: "0 0 0 2px", textAlign: 'left', color: customGroupDraft.title_color, fontSize: "7px", fontWeight: 300}}>
              {customGroupDraft.description ? customGroupDraft.description : ""}
            </TitleWrapper>
            </div>
            <ImgWrapper>
            {customGroupDraft.image && !imgError ? 
            <img src={typeof customGroupDraft.image == "string" ? customGroupDraft.image : URL.createObjectURL(customGroupDraft.image)} onError={() => setImgError(true)} alt='' /> :     
              <div style={{display: 'flex', alignItems: 'center', fontSize: "9px", gap: "2px", fontWeight: 600}}><PhotographIcon style={{color: "black"}} height={15} className='text-white'/> {"Logo"}</div>
            }    
            </ImgWrapper>
          </TitleOuterWrapper>
          <SwimLineWrapper style={{margin: "10px 10px 0 0"}} id="scroll" >
          {customGroupDraft.products_info.length > 0 ?
          customGroupDraft.products_info.map((item) => (
            <CustomGroupProductCardMockup
              card_primary_color={customGroupDraft.primary_color}
              product={item}
              default_image={defaultProductImage}
            />
          )) :
            <CustomGroupProductCardMockup
              card_primary_color={customGroupDraft.primary_color}
              product={null}
              default_image={defaultProductImage}
            />
        }
          </SwimLineWrapper>
        </OuterWrapper> 
        :
        <OuterWrapper style={{backgroundColor: customGroupDraft.secondary_color}}>
          <TitleOuterWrapper style={{backgroundColor: customGroupDraft.primary_color}}>
            <TitleWrapper style={{color: customGroupDraft.title_color}}>
              {customGroupDraft.name ? customGroupDraft.name : "Titulo del destacado"}
            </TitleWrapper>
          </TitleOuterWrapper>
          <SwimLineWrapper scrollable={customGroupDraft.products_info.length > 2 || customGroupDraft.products_info.length == 0 } id="scroll" >
          {customGroupDraft.products_info.length > 0 ? 
          customGroupDraft.products_info.map((item, index) => (
            <CustomGroupProductCardMockup
              card_primary_color={customGroupDraft.primary_color}
              product={item}
              default_image={defaultProductImage}
            />
          )) :
          <>
            <CustomGroupProductCardMockup
              card_primary_color={customGroupDraft.primary_color}
              product={null}
              default_image={defaultProductImage}
            />
            <CustomGroupProductCardMockup
              card_primary_color={customGroupDraft.primary_color}
              product={null}
              default_image={defaultProductImage}
            />
            <CustomGroupProductCardMockup
              card_primary_color={customGroupDraft.primary_color}
              product={null}
              default_image={defaultProductImage}
            />
          </>
        }
          </SwimLineWrapper>
        </OuterWrapper>}
        {is_restaurant &&
        <CategoryTitle>
          {selected_supergroup ? selected_supergroup.tpv_name ? selected_category_group.tpv_name : selected_category_group.name : selected_category_group ? selected_product_category ? selected_category_group.tpv_name ? selected_category_group.tpv_name : selected_category_group.name : selected_product_category.tpv_name ? selected_product_category.tpv_name : selected_product_category.name : "Categoría"}
          <hr style={{borderRadius:'10px', width: '90%', margin: '10px 0px', border: 'none', borderTop: '1px solid '}}/>
        </CategoryTitle>}
        <div className='flex-1 flex flex-col gap-2 p-2 relative'>
          {selected_product_category && selected_product_category.products && selected_product_category?.products?.map((product) => (
            <div className='w-full flex gap-2 rounded-md shadow p-1 text-[#404040]'>
              <div className='w-8 h-8 bg-gray-200 rounded flex relative'>
              {product && product.image ? 
                <img src={product.image} alt='product_photo'/> :
                <PhotographIcon height={14} className='m-auto text-gray-400'/>}
                <div className='w-2 h-2 rounded-sm bg-gray-400 absolute bottom-[3px] right-[3px]'>
                  <SelectorIcon height={8} className='text-gray-200 transform rotate-45'/>
                </div>
              </div>
              <div className='flex-1 flex flex-col gap-[3px]'>
                <h6 className='text-[7px] leading-[1em] font-semibold'>
                  {product.name ? product.name : product.tpv_name}
                </h6>
                <p className='text-[5px]'>
                  {product.description ? product.description : product.tpv_description ? product.tpv_description : ""}
                </p>      
              </div>
              <div className='flex flex-col justify-between items-end'>
                <b className='text-[7px]'>{centsToAmountString(product.tpv_price_cents)}€</b>
                <PlusCircleIcon height={14}/>
              </div>
            </div>
            ))}       
        </div>        
      </div>
    </div>
  );
};