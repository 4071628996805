import React, {useState} from 'react';
import { Brush, LineChart, Line, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { thousandsSeparator } from 'logic/functions';
import moment from 'moment';
import GraphCard from '../../components/yumminn/GraphCard';

const UsersGraph
  = ({info, data}) => {
  const {t} = useTranslation();
  const [left, setLeft] = useState("dataMin")
  const [right, setRigth] = useState("dataMax")
  const [top, setTop] = useState("dataMax")
  const [bottom, setBottom] = useState(0)
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');


  React.useEffect(() => {
    if(info && info.length > 0){
      const ACTUALS = info.map(({actual}) => actual)
      const PREVS = info.map(({prev}) => prev)
      if(Math.round(Math.max(...ACTUALS)) > Math.round(Math.max(...PREVS))){
        setTop(Math.round(Math.max(...ACTUALS)))
      } else {
        setTop(Math.round(Math.max(...PREVS)))
      }
      if(Math.round(Math.min(...ACTUALS)) < Math.round(Math.min(...PREVS))){
        setBottom(Math.round(Math.min(...ACTUALS)))
      } else {
        setBottom(Math.round(Math.min(...PREVS)))
      }
      document.getElementsByClassName('recharts-legend-item-text')
      let spanCollection = document.getElementsByClassName('recharts-legend-item-text')
      for (let i=0; i < spanCollection.length; i++){
        spanCollection[i].style.color = '#404040'
      }  
    }  
  }, [info])
  
  const DateFormatter = date => {
    return moment(date).format('DD-MM');
  };

  return (
    <GraphCard 
      title={'users'}
      indicators={[{label: '', value: data?.table_data?.diff || 0}]}
      totals={[
        {label: 'Actual', value: thousandsSeparator(data?.table_data?.actual?.total_clients || 0).replace(/,00/g, ''), color: '#5FB894'},
        {label: 'Prev', value: thousandsSeparator(data?.table_data?.previous?.total_clients || 0).replace(/,00/g, ''), color: '#BFE3D4'},
      ]}
    >
      <LineChart data={info} margin={{left: -10}}>
        <XAxis scale={"auto"} minTickGap={0} style={{fontSize: isRowBased ? "12px" : "14px"}} dataKey={"labels"} stroke="#404040" allowDataOverflow={true} domain={[left, right]} allowDecimals={false} tickCount={info?.length || 1} type={"category"} yAxisId="1"/>
        <YAxis padding={{top: 10}} style={{fontSize: isRowBased ? "12px" : "14px"}} scale={'linear'} tickCount={info?.length || 1} dataKey="actual" allowDataOverflow={false} domain={[bottom, top]} type="number" allowDecimals={false}/>
        <Legend wrapperStyle={{display: 'none', fontSize: "14px", width: '600px', right: 0, bottom: "-30px", boxShadow: "0px 12px 12px 15px rgb(149 157 165 / 10%)", borderRadius: '0.375rem', padding: "4px", justifyContent: 'flex-end'}}/>
        {period !== "days" && period !== "today" && period !== "month" && period !== "year" ?
          <Tooltip labelFormatter={value => { return `${moment(value).format('DD-MM')}`}} formatter={value => thousandsSeparator(Number(value)).replace(/,00/g, '')}/>
          :
          <Tooltip formatter={value => thousandsSeparator((value)).replace(/,00/g, '')}/>}            
        <Line strokeWidth={2} name={period === 'month' ? t('Months') : t(period)} type="monotone" dataKey="actual" stroke="#5FB894" />
        <Line strokeWidth={2} name={t('Previous period')} type="monotone" dataKey="prev" stroke="#BFE3D4" strokeDasharray="5 5"/>
        {period === 'month' && <Brush stroke="green" tickFormatter={DateFormatter} dataKey="labels" />}
      </LineChart> 
    </GraphCard>
  );
};

export default UsersGraph;