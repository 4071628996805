import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Context as AuthContext } from "../../../data/authContext";
import { DatesFilter } from "components/yumminn/DatesFilter";
import useReviewFilter from "components/yumminn/useReviewFilter";
import useRestaurant from "components/yumminn/useRestaurant";
import { order } from "tailwindcss/defaultTheme";
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import CrmTable from "common/CrmTable";
import DateRangeSelect from "components/yumminn/DateRangeSelect";

function subtractDays(date) {
  date.setDate(date.getDate() - 7);
  return date;
}

export const Crm = () => {
  const { seleccionado, start, end } = useRestaurant();
  const { activo } = useReviewFilter();
  const { t } = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [orderByDate, setOrderByDate] = useState();
  const [column, setColumn] = useState("payments");
  const [sort, setSort] = useState("ASC");
  const [page, setPage] = useState(1);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const { state: selectedReviewFilter, setSelectedReviewFilter } = React.useContext(AuthContext);
  const selectedDate = JSON.parse(sessionStorage.getItem("yumminn-selectedDate"));
  const date = new Date(selectedDate);
  subtractDays(date);
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
  const selectedDateTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))
  const endDate = selectedDateTo ? new Date(selectedDateTo) : yesterday;
  const offset = date.getTimezoneOffset();
  const dateEnd = new Date(endDate.getTime() - (offset*60*1000)).toISOString().split('T')[0];
  const dateStart = new Date(date.getTime() - (offset*60*1000)).toISOString().split('T')[0];
  const selectedYear = JSON.parse(sessionStorage.getItem("yumminn-selectedYear"))
  const selectedYearTo = JSON.parse(sessionStorage.getItem("yumminn-selectedYearTo"))
  const selectedMonth = JSON.parse(sessionStorage.getItem("yumminn-selectedMonth"))
  const selectedMonthTo = JSON.parse(sessionStorage.getItem("yumminn-selectedMonthTo"))
  const selectedStartTime = sessionStorage.getItem('yumminn-selectedStartTime')
  const selectedEndTime = sessionStorage.getItem('yumminn-selectedEndTime')   


  useEffect(()=>{
    setLoadingUsers(true);
   
  }, [seleccionado, activo, end, start, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, dateStart, dateEnd, selectedDate, selectedDate, selectedStartTime, selectedEndTime, page, order, sort, column, orderByDate]);

  useEffect(()=>{
    setSort("ASC")
    setOrderByDate();
  }, [period])

  return (
    <>
        <div className={`flex-auto flex flex-col items-center w-full bg-gray-100 `} style={{zIndex: 9999}}>
           {!isRowBased && 
            <h1 className={`font-bold text-[#094553] text-[24px] px-[0px] right-0 py-5 w-full bg-[#E2FEF1] pl-8`} style={{zIndex: !isRowBased ? 9999 : ''}}>
              {t('Crm')}
            </h1>}
          <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-12 lg:px-6 rounded-lg pb-3 gap-5 bg-gray-100 z-9999 pt-3`}>
            <RestaurantSelect/>
            <DateRangeSelect defaultRange="last_30_days"/>
            <DatesFilter doFilter={setSelectedReviewFilter}/>
          </div>
        </div>
        <CrmTable/>
        
    </>
  );
}