import React,{useRef, useState} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import Select from 'react-select';
import { categories_select_styles } from 'logic/defaults';
import { InfoLabel } from 'components/yumminn/InfoLabel';
import { getContrastRatio } from 'styles/colors';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import { InfoTooltip } from 'common/InfoTooltip';

const MIN_CONTRAST = 4;
const RECOMMENDED_CONTRAST = 4.5;

const getContrastInfo = (color, color2) => {
  if (!color) return;
  
  const contrast = getContrastRatio(color, color2);

  if (contrast >= RECOMMENDED_CONTRAST) {
    return {
      type: 'info',
      message: 'contrast_good',
    };
  }

  if (contrast >= MIN_CONTRAST) {
    return {
      type: 'warning',
      message: 'contrast_minimum',
    };
  }

  return {
    type: 'error',
    message: 'contrast_bad',
  };
};

export const CustomGroupColorSettings = ({catalog, customGroupDraft, changeTitle, changeColor, changeDefaultProductImage}) => {
  const selectorOptions = [{"label": "Un color", value: 0},
                          {"label": "Dos colores", value: 1}]  
  const sameColorBackgroundColor = customGroupDraft.primary_color == customGroupDraft.secondary_color
  const selected_option = sameColorBackgroundColor ? selectorOptions[0] :selectorOptions[1]

  const hiddenDefaultProductImageInput = useRef(null);

  const handleChangeOption = (value) => {
    const type = value.value
    if (type == 1) {
      const secondary_color = customGroupDraft.primary_color != "#FFF" ? "#FFF" : "#00000"
      changeColor(null, null, secondary_color)
    } else {
      changeColor(null, null, customGroupDraft.primary_color)
    }
  }
  
  const handleDefaultProductImageClick = () => {
    hiddenDefaultProductImageInput.current.click();
  };

  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '30%', height: '100%'}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '7%', paddingRight: '7%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{fontSize: '14px', fontWeight: 500}}>Título del destacado</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <div style={{fontSize: '14px', position: 'absolute', right: '15px', top: '25%', color: 'gray'}}>{customGroupDraft.name.length}/50</div>
            <input
                type='text'
                placeholder='Ej: ‍👩🏻‍🍳 ¡Sugerencias del chef!'
                className='yumminn-input'
                value={customGroupDraft.name}
                name='custom_group_title'
                style={{width: '10ch'}}
                maxLength={50}
                onChange={(e) => changeTitle(e.target.value)}
              />
          </div>
          {customGroupDraft.name.length < 1 &&
          <div style={{fontSize: '13px'}}>⚠️ Debes añadir una franja horaria</div>}
          <div style={{fontSize: '14px', fontWeight: 500, marginTop: "15px"}}>Color del título</div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '10px'}}>
            <label style={{fontSize: '14px', userSelect: 'none'}} className='flex justify-between items-center gap-2 p-2 w-32 bg-white border-2 border-gray-300 rounded-md cursor-pointer'>
              {customGroupDraft.title_color?.toUpperCase()}
              <span className='w-5 h-5 shadow-md' style={{backgroundColor: customGroupDraft.title_color}}/>
              <input
              type='color'
              default_value={customGroupDraft.title_color}
              onInput={(e) => changeColor(e.target.value, null, null)}
              className='absolute opacity-0 w-0 pointer-events-none'
              />
            </label>
            <div style={{marginLeft: '10px'}}>
              <InfoLabel {...getContrastInfo(customGroupDraft.title_color, customGroupDraft.primary_color)} isVisible={true}/>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
            <div style={{ width: '45%', fontSize: '14px'}}>
              <div style={{fontWeight: 500,  marginBottom: '5px'}}>Fondo</div>
              <Select
              value={selected_option}
              options={selectorOptions}
              styles={categories_select_styles}
              onChange={(e) => {
                handleChangeOption(e);
              }}
              />
            </div>
            <div style={{width: '10%'}}/>
            <div style={{ width: '45%'}}>
              <div style={{fontSize: '14px', fontWeight: 500, marginBottom: '5px'}}>{sameColorBackgroundColor ? "Color" : "Color superior"}</div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <label style={{fontSize: '14px', userSelect: 'none'}} className='flex justify-between items-center gap-2 p-2 w-32 bg-white border-2 border-gray-300 rounded-md cursor-pointer'>
                  {customGroupDraft.primary_color?.toUpperCase()}
                  <span className='w-5 h-5 shadow-md' style={{backgroundColor: customGroupDraft.primary_color}}/>
                  <input
                  type='color'
                  default_value={customGroupDraft.primary_color}
                  onInput={(e) => changeColor(null, e.target.value, null)}
                  className='absolute opacity-0 w-0 pointer-events-none'
                  />
                </label>        
              </div>
              {!sameColorBackgroundColor && 
              <>
              <div style={{fontSize: '14px', fontWeight: 500, marginTop: "15px", marginBottom: '5px'}}>Color inferior</div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <label style={{fontSize: '14px', userSelect: 'none'}} className='flex justify-between items-center gap-2 p-2 w-32 bg-white border-2 border-gray-300 rounded-md cursor-pointer'>
                  {customGroupDraft.secondary_color?.toUpperCase()}
                  <span className='w-5 h-5 shadow-md' style={{backgroundColor: customGroupDraft.secondary_color}}/>
                  <input
                  type='color'
                  default_value={customGroupDraft.secondary_color}
                  onInput={(e) => changeColor(null, null, e.target.value)}
                  className='absolute opacity-0 w-0 pointer-events-none'
                  />
                </label>
              </div></>}
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
            <h4 style={{fontWeight: 500,  marginBottom: '5px', lineHeight: 1.5, display: 'flex', alignItems: 'center', gap: '8px'}}>
              ¿Producto sin foto?
              <InfoTooltip info='La imagen por defecto se aplica solo en aquellos productos que no tienen cargada una foto, para darles mayor visibilidad. Formato png, jpg o gif. Calidad 2,5MB máx.'/>
            </h4>
            <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px'}}>
              <button
                onClick={handleDefaultProductImageClick}
                className={`rounded-full cursor-pointer py-2 px-4 text-xs text-white font-bold flex gap-1 items-center h-[32px] ${customGroupDraft.default_product_image ? 'bg-[#094553]' : 'bg-principal'}`}
              >
                {!customGroupDraft.default_product_image &&
                  <PlusCircleIcon color='#FFFFFF80' height={18}/>
                }
                {customGroupDraft.default_product_image ? 'Modificar imagen' : 'Añadir imagen por defecto'}
              </button>
              {customGroupDraft.default_product_image &&
                <div
                  style={{display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer'}}
                  onClick={() => changeDefaultProductImage(null)}
                >
                  <TrashIcon className={`w-[20px] text-gray-600`}/>
                  Eliminar
                </div>
              }
            </div>
            <input accept="image/png, image/jpg" ref={hiddenDefaultProductImageInput} type="file" style={{display:'none'}} name="default_product_image" onChange={(event) => {changeDefaultProductImage(event.target.files[0]); event.target.value=""}}/>
          </div>
        </div>
      </div>
    </div>
  )
}
