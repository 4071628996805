import React, { useContext } from 'react';
import useSWR from "swr";
import { orderAndPay } from "logic/api";
import { Context as AuthContext } from '../../../../../data/authContext';
import { Droppable } from 'react-beautiful-dnd';
import DraggableProductRow from './DraggableProductRow';
import SpinIcon from 'common/SpinIcon';
import { compareFn } from '../Logic/functions';
import { getTranslationLanguage } from '../../utils';

const fetcher = async endpoint => {
  const data = await orderAndPay.get(endpoint).then(res => res.data);

  const modifierCategories = [...data.modifier_categories].sort(compareFn);

  modifierCategories.forEach(c => {
    try {
      c.visible_name = c.modifier_category.visible_name;
      c.name = c.modifier_category__name;
      c.tpv_name = c.modifier_category__tpv_name;
      c.modifiers = [...c.modifiers].sort(compareFn);
    } catch {
      c.modifiers = [];
    }
  });

  return modifierCategories;
};

export const DroppableModifierCategory = ({
  parent,
  parentType,
  parentName,
  level,
  highlightedRow,
  isTpvYumminn,
  setSourceList,
  setModifierToEdit,
  setModifierCategoryToEdit,
  setModifierCategoryToDelete,
  setHighlightedRow,
}) => {
	const { state: { selectedRestaurantId } } = useContext(AuthContext);
  
  const lang = getTranslationLanguage();

  const { data: modifierCategories, isLoading } = useSWR(
    `/${parentType.toLowerCase()}-modifiers?${parentType.toLowerCase()}Id=${parent.id}&restaurant=${selectedRestaurantId}&language=${lang}`,
    fetcher,
  );

  if (!modifierCategories || isLoading) return (
    <tr>
      <td colSpan={100}>
        <SpinIcon/>
      </td>
    </tr>
  );

  return (
    <Droppable
      type={parent.id}
      droppableId={parentType + '_MODIFIER_CATEGORY-' + parent.id}
      direction="vertical"
    >
      {providedModCatList => (
        <>
          <tr
            key={`$tr-droppable ${parent.id}`}
            ref={providedModCatList.innerRef}
            {...providedModCatList.droppableProps}
          >
            <td key={parent.id} colSpan={100}>
              <table>
              {modifierCategories.map((category, index) =>
                <React.Fragment key={parentType + '_MODIFIER_CATEGORY-' + category.id}>
                  <DraggableProductRow
                    type={parentType + '_MODIFIER_CATEGORY'}
                    item={category}
                    parentList={modifierCategories}
                    parentId={parent.id}
                    parentName={parentName}
                    level={level}
                    index={index}
                    hasSiblings={parent.modifier_categories_count > 1}
                    highlightedRow={highlightedRow}
                    isTpvYumminn={isTpvYumminn}
                    setSourceList={setSourceList}
                    setModifierToEdit={setModifierToEdit}
                    setModifierCategoryToEdit={setModifierCategoryToEdit}
                    setModifierCategoryToDelete={setModifierCategoryToDelete}
                    setHighlightedRow={setHighlightedRow}
                  />
                </React.Fragment>
              )}
              </table>
            </td>
          </tr>
          {providedModCatList.placeholder}
        </>
      )}
    </Droppable>
  )
}
