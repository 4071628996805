import React from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { abbreviateBigNumbers } from 'logic/functions';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Context as AuthContext } from '../../../../data/authContext'; 

const toAbbrebviatedStr = (num) => abbreviateBigNumbers(num || 0, {rounded: true, thousands: true})

export const CustomLineChart = ({data, unit, formatter, chartHeight, hourly}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = history.location.pathname;
  const isWalletPage = path === '/wallet/metrics';
  const {
		state: { selectedDate, selectedDateTo },
	} = React.useContext(AuthContext);

  const CustomTooltip = ({ payload, unit, formatter }) => {
    if (payload.length === 0) return null;

    function formatDateToDDMM(date) {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0'); // Ensure day is two digits
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1

        return `${day}/${month}`;
    }
  
    const dataPoint = payload[0]?.payload;
    if (!dataPoint) return null;
  
    const formattedValueCurr = formatter(dataPoint.curr).toString().replace(".", ",")
    const formattedValuePrev = formatter(dataPoint.prev).toString().replace(".", ",")
    const date = hourly ? formatDateToDDMM(selectedDate) + '-' + dataPoint.date : dataPoint.date;
    const datePriorToCurrentDate = new Date(new Date(selectedDate).getTime() - 7*24*60*60*1000);
    const prevDate = hourly ? formatDateToDDMM(datePriorToCurrentDate) + '-' + dataPoint.prevDate : dataPoint.prevDate;
  
    return (
      <div 
        style={{
          background: "white",
          zIndex: 9999,
          color: '#404040',
          borderRadius: '6px',
          fontSize: '0.75rem',
          padding: '8px',
          border: 'none',
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
        }}
      >
        <div
          style={{
            color: '#094553'
          }}
        >
          {t('Current period')} {`(${date})`} {`: ${formattedValueCurr}${unit}`}
        </div>
        <div 
          style={{
            color: '#2E9EB7'
          }}
        >
          {t('Previous period')} {`(${prevDate})`} {`: ${formattedValuePrev}${unit}`}
        </div>
      </div>     
    );
  };

  
  return (
    <ResponsiveContainer width='100%' height={chartHeight}>
      <LineChart data={data} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
        <XAxis
          dataKey={'date'}
          tick={<XTick/>}
          tickSize={4}
          stroke='#A7A7A7'
          strokeWidth={1.5}
          height={25}
          padding={{left: 16, right: 16}}
        />
        <YAxis
          type='number'
          tick={<YTick unit={unit}/>}
          tickSize={4}
          stroke='#A7A7A7'
          strokeWidth={1.5}
          width={60}
          padding={{top: 16}}
        />
        <svg x={59} y={chartHeight - 41}> {/* round corner */}
          <rect width='16' height='18' fill='#FFF'/>
          <path d='M 17 16 A 16 16 0 0 1 1 0' stroke='#A7A7A7' fill='none' strokeWidth='1.5'/>
        </svg>
        <Tooltip
          cursor={{stroke: '#DDD', strokeWidth: 1.5}}
          content={<CustomTooltip unit={unit} formatter={formatter} />}
          //formatter={(value) => value.toString().replace(".", ",") + unit}
          contentStyle={{
            color: '#404040',
            borderRadius: '6px',
            fontSize: '0.75rem',
            padding: '8px',
            border: 'none',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
          }}
        />
        <Line dataKey={data => formatter(data.prev)} strokeWidth={2} dot={false} name={t('Previous period')} stroke='#2E9EB7' strokeDasharray='5 5'/>
        <Line dataKey={data => formatter(data.curr)} strokeWidth={2} dot={false} name={ t('Current period')} stroke='#094553' />
      </LineChart>
    </ResponsiveContainer>
  );
};

const XTick = ({payload, x, y}) => (
  <text x={x} y={y + 14} fontSize='14' fontWeight='500' fill='#404040' textAnchor="middle">
    {payload.value}
  </text>
);

const YTick = ({payload, x, y, unit}) => (
  <text x={x} y={y + 4} fontSize='14' fontWeight='500' fill='#404040' textAnchor="end">
    {toAbbrebviatedStr(payload.value) + unit}
  </text>
);