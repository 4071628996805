import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";


const PillContainer = styled.div`
    height: 27px;
    padding: 7px 18px;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    text-transform: uppercase;
    border-radius: 7px;
    background: ${({pillColor}) => pillColor};
    color: #FFF;
    text-align: center;
    display: flex;
    font-size: 10px;
    font-weight: 800;
    font-style: italic;
    line-height: 14.136px; 
    letter-spacing: 0.652px;
`;


const NewFeaturesPillCard = ({pillTitle, pillColor}) => {
  const { t } = useTranslation();

  return  (
    <div className='w-full p-3'>
      <PillContainer pillColor={pillColor}>
        {pillTitle}    
      </PillContainer>
     
    </div>
  )
}

export default NewFeaturesPillCard;
