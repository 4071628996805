import styled, { keyframes } from 'styled-components';

const modifiedRowAnimation = keyframes`
  to {
    background-color: #F0FFF8;
  }
`;

export const SubsidiesPageContainter = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;

  & > header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 24px;
    
    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.3;
      color: #222222;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    [type=file] {
      display: none;
    }

    & > *:last-child {
      margin-left: auto;
      min-width: 15rem;
    }

    div:has(h5) {
      max-width: 32ch;

      h5 {
        white-space: nowrap;
      }

      b {
        font-weight: 600;
      }
    }
  }

  .subsidies_table {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #FFF;
    border: 1px solid #40404026;
    border-radius: 16px 16px 0 0;
    padding: 8px 8px 0;
    margin: 0 24px;
    overflow: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 8px;
      margin: -8px 0 0;
    }

    thead {
      position: sticky;
      top: 0;
      box-shadow: 0 -8px 0 #FFF;
      z-index: 1;
    }

    th {
      font-size: 1.125rem;
      font-weight: 500;
      text-align: center;
      background-color: #FCFCFC;
      border-bottom: 1px solid #D1D1D1;
      height: 3.5rem;

      button {
        display: flex;
        align-items: center;
        gap: 0.5em;
        margin: auto;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: 2px solid #094553;
          outline-offset: 2px;
          border-radius: 99px;
        }

        span {
          max-width: 10ch;
          line-height: 1.15;
        
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }

      &:first-child {
        border-top-left-radius: 0.5rem;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
      }

      &.col_preselected > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    tr {
      background-color: #FCFCFC;
      
      &:last-child {
        td:first-child {
          border-bottom-left-radius: 0.5rem;
        }

        td:last-child {
          border-bottom-right-radius: 0.5rem;
        }
      }

      &.modified {
        animation: ${modifiedRowAnimation} 0.5s 2 ease alternate;
      }
    }

    td {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      padding: 16px;

      &:has(input) {
        padding: 0;
      }

      &.col_id span {
        display: inline-block;
        background-color: #EEF9F5;
        border: 1px solid #AFDCCA;
        border-radius: 99px;
        width: 100%;
      }

      &.col_created {
        color: #858585;
      }

      svg {
        display: inline-block;
      }
    }

    .col_id {
      width: 6rem;
    }

    .col_coupon {
      width: 14rem;
      padding: 0 24px;
    }
    
    .col_preselected {
      & > * {
        justify-content: center;
      }
    }

    .col_preselected, .col_created, .col_modified {
      width: 11rem;
    }

    .col_delete {
      width: 4rem;
    }

    & > div {
      flex: 1;
      padding: 0;
      margin: 0;
      
      p {
        font-weight: 600;
        max-width: 25ch;
        text-wrap: balance;
      }
    }

    footer {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      background-color: inherit;
      border-top: 1px solid #D1D1D1;
      padding: 16px 24px;
      margin-top: auto;
    }
  }

  .overlay {
    position: absolute;
    inset: 0;
    background-color: #F7F7F780;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;