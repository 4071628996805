import React from 'react';

const BookingSummary = ({imgSrc, imgHg, value, text}) => {

  return (
    <div style={{display: 'flex', backgroundColor: "#FFFFFF", minWidth: "200px", padding: "15px", borderRadius: "15px", border: "1px solid #E2E2E2"}}>
      <div>
        <div style={{width: "35px", height: "35px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#094553", borderRadius: "99999px"}}>
          <img style={{height: imgHg}} src={imgSrc}/>
        </div>
      </div>   
      <div style={{marginLeft: "10px"}}>
        <p style={{color: "#094553", fontSize: "35px", lineHeight: "35px", fontWeight: 600}}>{value}</p>
        <p style={{color: "#767676", fontSize: "15px", fontWeight: 600}}>{text}</p>
      </div>    
    </div>
  );
};
export default BookingSummary;