import { translation } from "logic/api";

export const translateAiCatalogService = (restaurantId, setAiProgressPercentage, setItemsDone, setTotalItems, translationId=null) => {
    return new Promise((resolve, reject) => {
      recursivelyRetryTranslateAiCatalog(restaurantId, resolve, reject, setAiProgressPercentage, setItemsDone, setTotalItems, translationId);
    });
  };

  const recursivelyRetryTranslateAiCatalog = (restaurantId, resolve, reject, setAiProgressPercentage, setItemsDone, setTotalItems, translationId=null) => {
    let endpoint = `/catalog-ai-translation?restaurant=${restaurantId}`
    if (translationId) {
        endpoint += `&translation=${translationId}`
    }
    const response = translation.get(endpoint)
    response.then((response) => {
      if (response.status == 200) {
        return response.data;
      } else {
        reject(response.statusText);
      }
    }).then((json) => {
      try {
        if ("progress_percentage" in json && json["progress_percentage"] !== undefined && "status" in json && "id" in json && setAiProgressPercentage) {    
          setAiProgressPercentage(json["progress_percentage"]) 
          setItemsDone(json["new_items_done"]) 
          setTotalItems(json["new_total_items"]) 
          if (json["status"] == "ongoing") {
              const translationId = json["id"]
              setTimeout(() => {
                  recursivelyRetryTranslateAiCatalog(restaurantId, resolve, reject, setAiProgressPercentage, setItemsDone, setTotalItems, translationId);
              }, 3000);
          } else if (json["status"] === "error") {
              reject("Error performing ai translation")
          } else if (json["status"] === "completed") {
            setAiProgressPercentage(100) 
            resolve(json)
          }
        }
      } catch (error) {
        console.log(error)
        throw Error(error.toString());
      }
    }).catch(err => {
      reject(err)
    });
  };