import React, { useEffect, useRef, useState, useContext } from "react";
import { dashboardData } from "logic/api";
import { useMediaQuery } from "hooks/useMediaQuery";
import BigSpinner from "common/BigSpinner";
import moment from "moment";
import { format } from 'date-fns';
import { Context as AuthContext } from "data/authContext";
import { useIsMounted } from "hooks/useIsMounted";
import { LineChartCard } from "pages/home/components";
import { formatDashboardData } from "pages/home/constants/dashboardFunctions";
import { centsToAmountString } from "logic/functions";

export const calculatePercentageDifference = (value1, value2) => {
  let percentageDifference = parseFloat(
    ((value1 - value2) / ((value1 + value2) / 2)) * 100
  ).toFixed(1);
  if (value1 === 0) percentageDifference = -100;
  if (value2 === 0) percentageDifference = 100;
  return percentageDifference;
};

const WalletDashboard = () => {
  const {state: { selectedRestaurant, selectedDate, selectedDateTo }} = useContext(AuthContext);
  const isMountedRef = useIsMounted();
  const timeoutRef = useRef(null);

  const [data, setData] = useState(null);
  const [chartsData, setChartsData] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [numberOfComputedElements, setNumberOfComputedElements] = useState(0);
  const [numberOfElementsToCompute, setNumberOfElementsToCompute] = useState(0);
  const [retryCount, setRetryCount] = useState(0);

  const isRowBased = useMediaQuery("(min-width: 1014px)");
  const dates = {from_date: selectedDate, to_date: selectedDateTo};

  const styles = {
    graphs: () => ({
      display: isRowBased ? "grid" : "flex",
      flexDirection: "column",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(auto-fit, auto)",
      alignItems: "start",
      gap: "1.25rem",
      width: "100%",
      maxWidth: "80rem",
      paddingBottom: "32px",
      margin: "20px auto",
    }),
  };

  const centsToEuros = (x) => (Number(x) / 100).toFixed(2); 

  useEffect(() => {
    if (selectedRestaurant && selectedRestaurant.length) {
      setNumberOfComputedElements(0);
      setNumberOfElementsToCompute(0);
      setRetryCount(0)
      getData();
    }

    return () => clearTimeout(timeoutRef.current);
  }, [selectedRestaurant, selectedDate, selectedDateTo]);

  const getDataThen = (response, recursive, resolve, reject) => {
    if (response.status === 200 && response.data) {
      if (recursive && (
            ("retry" in response.data && response.data["retry"]) ||
            ("period" in response.data && "retry" in response.data.period && response.data.period["retry"])
      )) {
        const responseData = response.data;
        if ("number_of_computed_elements" in responseData) {
          setNumberOfComputedElements(responseData["number_of_computed_elements"]);
        }
        if ("number_of_elements_to_compute" in responseData) {
          setNumberOfElementsToCompute(responseData["number_of_elements_to_compute"]);
        }

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          recursivelyRetryGetData(resolve, reject);
          setRetryCount(prevCount => prevCount + 1);
        }, 1000);
      } else {
        setSpinner(false);
        setData(response.data);
        setChartsData(formatDashboardData(response.data.period, getInterval()));
        if (recursive) {
          resolve(response);
        }
      }
    } else {
      setSpinner(false);
    }
  };

  const recursivelyRetryGetData = (resolve, reject) => {
    if (!isMountedRef.current) return;

    const promise = getDashBoardData('dashboard');
    if (promise) {
      promise
        .then(response => {
          getDataThen(response, true, resolve, reject)
        })
        .catch(err => console.log(err));
    }
  };

  const getData = () => {
    clearTimeout(timeoutRef.current);
    setSpinner(true);

    return new Promise((resolve, reject) => {
      recursivelyRetryGetData(resolve, reject);
    });
  };

  const getDashBoardData = (endpoint) => {
    try {
      const restaurants = selectedRestaurant?.length ? selectedRestaurant.map((item) => item.value) : [0];

      const from = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      const to = format(selectedDateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");   

      const adminType = 'wallet';
      const type = getInterval();
      const fullEndpoint = `/${endpoint}?date_from=${from}&date_to=${to}&restaurants=${restaurants}&type=${type}&admin_type=${adminType}&number_of_retries=${retryCount}`;      
      return dashboardData(fullEndpoint);
    } catch (error) {
      console.log(error);
    }
  };

  const isHourlyChart = () =>{
    const interval = getInterval()
    if (interval === 'hours'){
      return true
    }
    return false
  }

  const getInterval = () => {
    const fromDate = moment(selectedDate);
    const toDate = moment(selectedDateTo);
    const timeDiff = toDate.diff(fromDate, "days") + 1; // first day included
    return timeDiff <= 1 ? "hours" : "days";
  };

  const calculateData = (data) => {
    if(!data) return;
    const totalActiveCreditsLength = data.period.actual.length
    const totalActiveCreditsNumber = data.period.actual[totalActiveCreditsLength -1].total_active_credits
    const totalActiveCredits = centsToAmountString(totalActiveCreditsNumber)
    const avgTotalActiveCredits = centsToAmountString(totalActiveCreditsNumber > 0 ?  totalActiveCreditsNumber/ data.total_users_with_credit : 0)
    const totalCreditsUsedNumber = data.period.actual.reduce((sum, item) => sum + item.credit_cents, 0);
    const totalCreditsUsed = centsToAmountString(totalCreditsUsedNumber);
    const avgTotalCreditsUsed = centsToAmountString(totalCreditsUsedNumber > 0 ? totalCreditsUsedNumber / data.total_users_with_credit : 0);
    const totalInvestedInIncentivesNumber = data.period.actual.reduce((sum, item) => sum + item.total_incentive_credits, 0);
    const totalInvestedInIncentives = centsToAmountString(totalInvestedInIncentivesNumber)
    const avgTotalInvestedInIncentives = centsToAmountString(totalInvestedInIncentivesNumber > 0 ? totalInvestedInIncentivesNumber / data.total_users_with_credit : 0);
    const avg = {
        avgTotalActiveCredits, 
        totalActiveCredits,
        avgTotalCreditsUsed, 
        totalCreditsUsed,
        avgTotalInvestedInIncentives,
        totalInvestedInIncentives
    }
    return avg;
  }
 


  return (
    <>
      {spinner && (
        <div
          style={{
            position: 'absolute',
            top: 'calc(-3.5rem - 97px)',
            left: '0',
            width: '100%',
            height: 'calc(100% + 3.5rem + 97px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            background: '#FFF8',
            zIndex: 999
          }}
        >
          <BigSpinner
            text={numberOfElementsToCompute > 0 ? `${((numberOfComputedElements / numberOfElementsToCompute) * 100).toFixed(2)}%` : ''}
            style={{
              position: 'fixed',
              top: '50%',
            }}
          />
        </div>
      )}

      <div
        className={`${(!data || spinner) && "pointer-events-none"}`}
        style={styles.graphs()}
      >
        <LineChartCard
            title={"totalActiveCredits"}
            subtitle={"totalActiveCreditsSubtitle"}
            data={chartsData?.totalActiveCredits}
            average={calculateData(data)?.avgTotalActiveCredits}
            total={`${calculateData(data)?.totalActiveCredits}€`}
            totalDefinition={"total_credits"}
            iconType={'WALLET_FOR_CHART'}
            period={dates}
            unit="€"
            formatter={centsToEuros}
            style={{ height: "100%", width: "100%" }}
            hourly={isHourlyChart()}
        />
        <LineChartCard
            title={"totalCreditUsers"} //clients_with_credits
            subtitle={"totalCreditUsersSubtitle"}
            data={chartsData?.totalCreditUsers}
            total={data?.total_users_with_credit}
            totalDefinition={"total_users_active"}
            iconType={'PROFILE'}
            period={dates}
            style={{ height: "100%", width: "100%" }}
            hourly={isHourlyChart()}
        />
        <LineChartCard
            title={"totalCreditsUsed"} //credit_cents
            subtitle={"totalActiveCreditsSubtitle"}
            data={chartsData?.totalCreditsUsed}
            average={calculateData(data)?.avgTotalCreditsUsed}
            total={`${calculateData(data)?.totalCreditsUsed}€`}
            totalDefinition={"total_credits"}
            iconType={'CHART_PIE'}
            period={dates}
            unit="€"
            formatter={centsToEuros}
            style={{ height: "100%", width: "100%" }}
            hourly={isHourlyChart()}
        />
        <LineChartCard
            title={"totalInvestedInIncentives"} //total_incentive_credits
            subtitle={"totalActiveCreditsSubtitle"}
            data={chartsData?.totalInvestedInIncentives}
            average={calculateData(data)?.avgTotalInvestedInIncentives}
            total={`${calculateData(data)?.totalInvestedInIncentives}€`}
            totalDefinition={"total_incentive"}
            iconType={'HAND_MONEY'}
            period={dates}
            unit="€"
            formatter={centsToEuros}
            style={{ height: "100%", width: "100%" }}
            hourly={isHourlyChart()}
        />
      
      </div>
    </>
  );
};

export default WalletDashboard;
