import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Popup } from 'common/Popup';
import { TextInput, SelectInput } from 'common/Input';
import { Context } from 'data/authContext';
import { orderAndPay } from 'logic/api';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSWR from 'swr';
import { ObjectTypes, patchObject, postObject } from './api-OrderAndPay';
import { getTranslationLanguage } from '../utils';

const NEW_CATEGORY = {
  modifier_category: null,
  min_selectable_products: null,
  max_selectable_products: null,
  individual_max_selectable_products: null,
};

const fetcher = endpoint => orderAndPay.get(endpoint).then(res => res.data);

export const ModifierCategoryModal = ({ category, mutate, ...popupProps }) => {
  const { state: { selectedRestaurantId } } = useContext(Context);

  const { t } = useTranslation();

  const lang = getTranslationLanguage();

  const { data, isLoading } = useSWR(
    `/modifier_categories_list?restaurant=${selectedRestaurantId}&lang=${lang}`,
    fetcher,
    {revalidateOnFocus: false},
  );

  const categoriesOptions = data?.modifier_categories?.map(cat => ({ value: cat.id, label: cat.visible_name || cat.name || cat.tpv_name })) || [];
  
  const [categoryDraft, setCategoryDraft] = useState(NEW_CATEGORY);
  const [isSaving, setIsSaving] = useState(false);

  const editDraft = partialCategory => setCategoryDraft(prev => ({...prev, ...partialCategory}));

  const selectCategory = selected => {console.log(selected); editDraft({modifier_category: selected.value})};

  const save = async () => {
    setIsSaving(true)
    const parent = 'product' in categoryDraft ? 'product' : 'modifier';

    const method = 'id' in categoryDraft ? patchObject : postObject;

    try {
      await method({ objectType: ObjectTypes[parent.toUpperCase() + '_MODIFIER_CATEGORY'], object: categoryDraft, t });
      mutate();
      popupProps.close();
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setCategoryDraft(category ? {...NEW_CATEGORY, ...category} : NEW_CATEGORY);
  }, [category]);

  return (
    <ModifierCategoryContainer {...popupProps}>
      <header>
        <h2>{t('edit_modifier_category')}</h2>
      </header>
      <div>
        <div>
          <SelectInput
            label={'category'}
            placeholder='select_modifier_category'
            value={categoriesOptions?.find(cat => cat.value === categoryDraft.modifier_category)}
            loading={isLoading}
            options={categoriesOptions}
            onChange={selectCategory}
            disabled={isSaving}
          />
          <TextInput
            label={'min_selectable_products'}
            value={categoryDraft.min_selectable_products}
            onChange={e => editDraft({min_selectable_products: e.target.value})}
            placeholder='0_optional'
            disabled={isSaving}
          />
        </div>
        <div>
          <TextInput
            label={'individual_max_selectable_products'}
            value={categoryDraft.individual_max_selectable_products}
            onChange={e => editDraft({individual_max_selectable_products: e.target.value})}
            placeholder='no_limit'
            disabled={isSaving}
          />
          <TextInput
            label={'max_selectable_products'}
            value={categoryDraft.max_selectable_products}
            onChange={e => editDraft({max_selectable_products: e.target.value})}
            placeholder='no_limit'
            disabled={isSaving}
          />
        </div>
      </div>
      <footer>
        <Button
          label={'cancel'}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={popupProps.close}
          disabled={isSaving}
        />
        <Button
          label={'save'}
          onClick={save}
          loading={isSaving}
          disabled={!categoryDraft.modifier_category}
        />
      </footer>
    </ModifierCategoryContainer>
  );
};

const ModifierCategoryContainer = styled(Popup)`
  & > div {
    header {
      width: 100%;
      border-bottom: 1px solid #BFBFBF;
      padding-bottom: 24px;
      
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.3;
      }
    }
  
    & > div {
      display: flex;
      gap: 32px;
      padding: 32px;
      min-width: 50vw;
      
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 32px;
        flex: 1;
      }
    }
    
    footer {
      display: flex;
      justify-content: flex-end;
      gap: 32px;
      width: 100%;
      border-top: 1px solid #BFBFBF;
      padding-top: 24px;
    }
  }
`;