import { awareDatetimeString, centsToAmountString } from 'logic/functions';
import React from 'react';
import { useTranslation } from "react-i18next";
import OrderModifierRow from './OrderModifierRow';
import OrderPaymentMehodType from './OrderPaymentMethodType';

const OrderPaymentMethod = ({method, brand, last4}) => {
  const { t } = useTranslation();
  return (
    <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', height: "25px", marginTop: "25px"}}>
      <div style={{fontSize: "18px"}}> 
        {t("paid_with")}
      </div>
      <div style={{display: 'flex', gap: "10px", alignItems: 'center'}}>
        <OrderPaymentMehodType type={method} brand={brand}/>
        {!["bizum", "cash", "pos_terminal"].includes(method) &&
        <div style={{fontSize: "18px"}}>
        ****{last4}
        </div>
        }
      </div>
    </div>
  );
};
export default OrderPaymentMethod;
