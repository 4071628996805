import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { TrashIcon, XIcon } from '@heroicons/react/outline';
import { BookingShiftSettings } from "../../BookingShifts/BookingShiftSettings";

const BookingShiftModal  = ({ t, shift, setShiftToEdit, saveShift, setShiftToRemove, type}) => {
  const [shiftDraft, setShiftDraft] = useState(shift ? { ...shift } : null);
  const changeName = (name) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      name: name,
    }})
  }

  const changeStartTime = (start_minute) => {
    const start_real_hour = Math.floor(start_minute / 60)
    const start_real_minute = start_minute % 60
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      start_hour: start_real_hour,
      start_minute: start_real_minute,
    }})
  }

  const changeSlotDuration = (slot_duration_minutes) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      slot_duration_minutes: slot_duration_minutes
    }})
  }

  const changeSlotsQuantity = (slots_quantity) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      slots_quantity: slots_quantity
    }})
  }

  const changeDinersPerSlot = (diners_per_slot) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      diners_per_slot: diners_per_slot
    }})
  }
  const changeVisibilityDays = (day) => {
    let newVisibilityDays = shiftDraft.visibility_days_arr
    if (shiftDraft.visibility_days_arr.includes(day)) {
      newVisibilityDays = shiftDraft.visibility_days_arr.filter(item => item !== day)
    } else {
      newVisibilityDays[newVisibilityDays.length] = day
    }
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      visibility_days_arr: newVisibilityDays
    }})
  }

  const checkDisableNextStep = () => {
    if (!shiftDraft.slot_duration_minutes || !shiftDraft.slots_quantity || shiftDraft.visibility_days_arr.length < 1 || !shiftDraft.diners_per_slot || !shiftDraft.name || !shiftDraft.slots_quantity || shiftDraft.start_minute + (shiftDraft.slots_quantity * shiftDraft.slot_duration_minutes) > 1440) {
      return true
    }
    return false
  }
  
  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "50%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>{type == 0 ? "Editar Turno" : "Crear Turno"}</div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "220px", justifyContent: 'flex-end', display: 'flex'}}>
              <button style={{margin: "0 15px"}} onClick={() => {setShiftToEdit(null)}}><XIcon className={`w-[25px] text-gray-700 cursor-pointer`}/></button>
            </div>
        </div>
        <div style={{marginTop: "20px", width: "100%"}}>
          <div style={{display: "flex", flexDirection: "row", width: "100%", fontSize: "13px", alignItems: "center", fontWeight:  550}}>
            <div style={{color: '#5FB894', cursor: type == 0 ? 'pointer' : 'auto'}}>Configuración del turno</div>
          </div>
          <div style={{marginTop: "10px", width: "100%", height: "2px", backgroundColor: "#5FB894"}}></div>
        </div>
        <div style={{marginTop: "20px", display: "flex", height: "400px", width: '100%'}}>
          <BookingShiftSettings shiftDraft={shiftDraft} changeName={changeName} changeStartTime={changeStartTime} changeSlotDuration={changeSlotDuration} changeSlotsQuantity={changeSlotsQuantity} changeDinersPerSlot={changeDinersPerSlot} changeVisibilityDays={changeVisibilityDays}/>
        </div>
        <div style={{marginTop: "50px", width: "610px", height: "2px", background: '#CCC'}}></div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '25px', width: "100%", justifyContent: type == 0 ? 'space-between' : 'flex-end'}}>
        {type == 0 && 
        <button
          className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xs text-gray-500 font-bold"}
          onClick={() => setShiftToRemove(shift)}><TrashIcon className={`w-[20px] text-gray-700 cursor-pointer`}
          /></button>}
        <div>
          <button
            disabled={checkDisableNextStep()}
            style={{width: '175px', backgroundColor: checkDisableNextStep() ? 'gray' : '#5FB894'}}
            className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
            onClick={() => saveShift(shiftDraft)}>{type == 1 ? "Crear" : "Guardar"}</button>
          </div>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default BookingShiftModal 