import React from 'react';
import styled from 'styled-components';

const Tutorials = styled.iframe.attrs({
  id: 'tutorials',
  src: 'https://tutoriales.guidejar.xyz'
})`
  width: 100%;
  height: 100%;
`;

export default Tutorials