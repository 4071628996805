import React from 'react';
import { InputContainer } from './InputContainers';
import { useTranslation } from 'react-i18next';
import { parseDecimalString } from 'logic/functions';

export const NumberInput = ({
  label,
  unit,
  error,
  showErrorMessage = true,
  className = '',
  parseValue = parseDecimalString, //set this to null to use the default input value
  ...inputProps
}) => {
  const { t } = useTranslation();

  const handleChange = event => {
    if (!inputProps.onChange) return;

    if (!parseValue) {
      inputProps.onChange(event);
      return;
    };

    event.target.value = parseValue(event.target.value);
    
    inputProps.onChange(event);
  };

  return (
    <InputContainer className={className}>
      <label>
        {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
        <div className='input_wrapper'>
          <input
            {...inputProps}
            placeholder={t(inputProps.placeholder || '')}
            readOnly={!inputProps.onChange}
            type={inputProps.type || 'tel'}
            inputMode={inputProps.inputMode || 'decimal'}
            step={inputProps.step || '.01'}
            autoComplete={inputProps.autoComplete || 'off'}
            onChange={handleChange}
          />
          {unit}
        </div>
      </label>
      {error &&
        <i className={`error ${showErrorMessage ? '' : 'hidden'}`}>
          {t(error)}
        </i>
      }
    </InputContainer>
  );
};