function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const calculateEuros = (cents) => {
  if (cents === undefined) return "0";
  if (cents.toString().indexOf(".") !== -1){ //in case number is decimal
      cents = cents.substring(0, cents.indexOf(".")) //removing the dot
  }
  let centsStr = cents.toString();
  let length = centsStr.length;
  if(centsStr.startsWith("-")){
      centsStr = centsStr.substr(1, length)
      length = length - 1;
  }
  const numberOfZerosToAdd = length > 2 ? 0 : 3 - length;
  centsStr = "0".repeat(numberOfZerosToAdd) + centsStr;
  length = centsStr.length;
  return formatNumber(centsStr.substr(0, length - 2) + "," + centsStr.substr(length - 2, 2));
}

export const calculateCents = (price) => {
  if (price === "0" || price === ""){
      return price;
  }
  else if(price === undefined){
      return 0;
  }
  else {
      price = (price * 100).toFixed(2).toString()

      if (price.toString().indexOf(".") !== -1){ //in case number is decimal
          price = price.substring(0, price.indexOf(".")) //removing the dot
      }


      if (price[0] === "0"){  
          price = "0".concat(price.substring(1, price.length))
      }
      

      else if (price.length <= 2){  
          price = price.substring(0, price.length) 
          price = "0" + price
      }
      
      return price;
  }    
}