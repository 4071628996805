import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import { useMediaQuery } from 'hooks/useMediaQuery';
import React from 'react'
import { useTranslation } from 'react-i18next';
import RoundButton from './RoundButton';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import ToggleOrdersButton from './ToggleOrdersButton';
import { ToggleRestauratnGroupVisibility } from './ToggleRestauratnGroupVisibility';
import { Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';

const dateOptions = {
  dateStyle: 'medium',
  timeStyle: 'short',
};

export const OPHeader = ({customCatalog, lastSynchro, synchronize, loading}) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const { t } = useTranslation();

  const lang = sessionStorage.getItem('i18nextLng') || 'es-ES';

  return (
    <div className={`flex-auto flex flex-col items-ends w-full bg-white`} style={{ zIndex: 300 }}>
      {!isRowBased && <MenuHeader title={'products_and_categories'} route_group={'menu'}/>}
      <div className="flex sm:flex-row flex-col justify-between items-end gap-3 w-full px-6">
        <div className='flex items-end gap-4 py-3'>
          <RestaurantSelect filter={'show_order_and_pay_tab_on_backoffice'} />
          <ToggleOrdersButton isCatalogLoading={loading}/>
          <ToggleRestauratnGroupVisibility isCatalogLoading={loading}/>
        </div>
        {!customCatalog &&
          <div className="flex flex-row items-center gap-4 my-3">
            {lastSynchro &&
              <div className="text-xs whitespace-nowrap">
                {t("ultima sincronizacion")}<br/>
                {new Date(lastSynchro).toLocaleString(lang, dateOptions)}
              </div>
            }
            <Button
              label={t('synchronize')}
              rightIcon={<Icon type={IconType.REFRESH}/>}
              onClick={synchronize}
            />
          </div>
        }
      </div>
    </div>
  )
}
