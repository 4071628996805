import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const PaginationButton = ({
  type = 'left',
  className = '',
  disabled = false,
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer className={`${disabled ? 'disabled' : ''} ${className}`}>
      <button disabled={disabled} onClick={onClick}>
        {type == "right" ? 
          <ChevronRightIcon/>
        : 
          <ChevronLeftIcon/>
        }
      </button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  button {
    --color: #404040;
    --bg_color: #FFF;
    --border_color: #40404026;

    &:hover {
      --bg_color: #D4F9EE;
    }

    &.current {
      --color: #FFF;
      --bg_color: #094553;
      --border_color: #094553;
      
      pointer-events: none;
    }

    &:disabled {
      --color: #BFBFBF;
      --bg_color: #F6F6F6;
      --border_color: #D1D1D1;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.875rem;
    min-width: 1.875rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color);
    background-color: var(--bg_color);
    border: 1px solid var(--border_color);
    border-radius: 1rem;
    padding: 4px;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid #094553;
      outline-offset: 2px;
    }

    svg {
      height: 1.25em;

      path {
        stroke-width: 0.15em
      };
    }
  }

  span {
    font-size: 1rem;
    font-weight: 600;
  }
`;