import React from "react";
import styled from "styled-components";
import { Box, Flex } from "reflexbox";
import { motion } from "framer-motion";

const Loading = () => {
  const variants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const dotVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "100%",
    },
  };

  const circleTransition = {
    duration: 0.8,
    repeat: Infinity,
    ease: "easeInOut",
  };

  return (
    <Flex width="100%" p="5" justifyContent="center">
      <Box>
        <Container variants={variants} initial="start" animate="end">
          <Dot variants={dotVariants} transition={circleTransition} />
          <Dot variants={dotVariants} transition={circleTransition} />
          <Dot variants={dotVariants} transition={circleTransition} />
          <Dot variants={dotVariants} transition={circleTransition} />
          <Dot variants={dotVariants} transition={circleTransition} />
        </Container>
      </Box>
    </Flex>
  );
};

const Container = styled(motion.div)`
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: space-around;
`;

const Dot = styled(motion.span)`
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: black;
  border-radius: 0.5rem;
`;

export default Loading;
