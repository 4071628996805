import { select_styles, select_styles_month } from 'logic/defaults';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from '../../data/authContext';
import calendar from "../../img/calendar.png"; 
import DatePicker from "react-datepicker";
import "./DateSelect.css";
import "react-datepicker/dist/react-datepicker.css";
import {useMediaQuery} from '../../hooks/useMediaQuery';
import * as dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

export default function MonthSelect() {
  const { t } = useTranslation();
  const {
    state: { selectedMonth, selectedMonthTo },
    setSelectedMonth, setSelectedMonthTo
  } = React.useContext(AuthContext);
  const [seleccionado, setSeleccionado] = React.useState(null);
  const [seleccionadoTo, setSeleccionadoTo] = React.useState(null);
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();
  const location = history.location.pathname;

  React.useEffect(() => {
    let seleccionado = JSON.parse(sessionStorage.getItem('yumminn-selectedMonth'));
    seleccionado && setSeleccionado(seleccionado);
  }, [selectedMonth, seleccionado]);

  React.useEffect(() => {
    let seleccionadoTo = JSON.parse(sessionStorage.getItem('yumminn-selectedMonthTo'));
    setSeleccionadoTo(seleccionadoTo);
  }, [selectedMonthTo, seleccionadoTo]);

  React.useEffect(() => {
    setSeleccionado(dayjs().subtract(12, 'month').format('YYYY-MM-DD'))
  }, [period])

  const handleSelect = (opcion) => {
    const offset = opcion.getTimezoneOffset();
    const date = new Date(opcion.getTime() - (offset*60*1000))
    setSelectedMonth(date);
    setSeleccionado(date);
  };

  const handleSelectTo = (opcion) => {
    const offset = opcion.getTimezoneOffset();
    const dateTo = new Date(opcion.getTime() - (offset*60*1000))
    setSelectedMonthTo(dateTo);
    setSeleccionadoTo(dateTo)
  }

  return (
    <div className={`flex z-9999 items-center my-3 ${isRowBased && 'justify-center ml-14'}`} style={{position: 'relative', fontSize: isRowBased ? "14px" : "16px", fontFamily: 'Poppins, sans-serif', fontWeight: 700}}>
      <div className={location === '/loyalty' ? 'hidden' : ''}>{t('From')}</div>
      <DatePicker
        portalId='root-portal'
        menuPortalTarget={document.body}
        styles={select_styles_month}
        style={{border: '2px solid hsl(0, 0%, 80%', zIndex: 9999}}
        className='w-6/12 max-w-md text-[14px]'
        name={'month'}
        placeholder={t('from')}
        selectsStart
        startDate={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : new Date()}
        endDate={typeof seleccionadoTo === 'string' ? new Date(seleccionadoTo) : seleccionadoTo ? seleccionadoTo : new Date()}
        selected={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : new Date()}
        onChange={(a) => {
          handleSelect(a);
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        maxDate={new Date()}
        minDate={new Date('2022/01/01')}
      />
      {!isRowBased && <label style={{position: 'absolute', left: period === 'days' ? "145px" : "126px", pointerEvents: 'none'}}>
        <img src={calendar} alt='' style={{width: "24px", height: '24px'}}/>
      </label>}
      <>
      <div className={location === '/loyalty' ? 'hidden' : ''}>{t('to')}</div>
      <DatePicker
        portalId='root-portal'
        menuPortalTarget={document.body}
        style={{border: '2px solid hsl(0, 0%, 80%', zIndex: 9999}}
        name={'month'}
        placeholderText={t("to")}
        selectsEnd
        startDate={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : new Date()}
        endDate={typeof seleccionadoTo === 'string' ? new Date(seleccionadoTo) : seleccionadoTo ? seleccionadoTo : new Date()}
        selected={typeof seleccionadoTo === 'string' ? new Date(seleccionadoTo) : seleccionadoTo ? seleccionadoTo : new Date()}
        onChange={(a) => {
          handleSelectTo(a);
        }}
        dateFormat="MM/yyyy"
        minDate={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : new Date()}
        showMonthYearPicker
        maxDate={new Date()}
      />
       <label style={{position: 'absolute', left: location === '/loyalty' || location === '/loyalty/mails' || location === '/loyalty/programs' ? '310px' : "330px", pointerEvents: 'none'}}>
          <img src={calendar} alt='' style={{width: "24px", height: '24px'}}/>
        </label>
      </>
    </div>
  );
}


