import React from 'react';
import { components } from 'react-select';
import { LockerOptionLabel, OrderOptionLabel } from './LockerContainers';
import { useTranslation } from 'react-i18next';
import { isOrderExpired } from './functions';

const lang = sessionStorage.getItem('i18nextLng') || 'es-ES';

const SelectLockerItem = ({label, color}) => {
  return (
    <LockerOptionLabel style={{backgroundColor: color}}>
      {label}
    </LockerOptionLabel>
  );
};

export const LockerSingleValue = ({data, ...props}) => <components.SingleValue {...props}><SelectLockerItem {...data}/></components.SingleValue>;

export const LockerOption = ({data, ...props}) => <components.Option {...props}><SelectLockerItem {...data}/></components.Option>;

const SelectOrderItem = ({code, ready_at}) => {
  const { t } = useTranslation();

  return (
    <OrderOptionLabel>
      <span className='code'>
        {t('order')}: <b>{code}</b>
      </span>
      <span className='time'>
        🕑
        <span className={`${isOrderExpired({ready_at}) ? 'expired' : ''}`}>
          {new Date(ready_at).toLocaleTimeString(lang, {timeStyle: 'short'})}
        </span>
      </span>
    </OrderOptionLabel>
  );
};

export const OrderSingleValue = ({data, ...props}) => <components.SingleValue {...props}><SelectOrderItem {...data}/></components.SingleValue>;

export const OrderOption = ({data, ...props}) => <components.Option {...props}><SelectOrderItem {...data}/></components.Option>