import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { XIcon, FolderDownloadIcon } from '@heroicons/react/outline';
import { ClockIcon } from '@heroicons/react/outline';
import green_check from "../../../../img/check.svg";
import bizumPng from '../../../../img/bizum.svg';
import applePay from '../../../../img/applePay.svg';
import gPay from '../../../../img/gpay.svg';
import mastercardIcon from '../../../../img/mastercard.svg';
import visaIcon from '../../../../img/visa.svg';
import { centsToAmountString } from "logic/functions";

const rowStyle = {
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1.5fr 1fr 1fr 1fr',
	width: '100%',
  };


const PayoutDetailModal = ({ t, payout, setPayoutToSee, downloadPdf }) => {
	const start_date = new Date(payout.period_start).toLocaleString('es-ES',{day: 'numeric', month:'long', year:'numeric'}).replaceAll("de", "")
  const end_date = new Date(payout.period_end).toLocaleString('es-ES',{day: 'numeric', month:'long', year:'numeric'}).replaceAll("de", "")
  const final_date = new Date(payout.scheduled_at).toLocaleString('es-ES',{day: 'numeric', month:'long', year:'numeric'}).replaceAll("de", "")

  let indent = 0;

  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "50%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: 'center'}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>Transferencia</div>
          <div style={{ fontSize: "14px", fontWeight: 500, color: '#767676'}}>{start_date} - {end_date}</div>
          <div onClick={() => downloadPdf(payout, 0)} style={{display: "flex", flexDirection: "row", fontWeight: 600, gap: "5px", alignItems: "center", backgroundColor: "#5FB894", padding: "5px 10px", color: "#FFFFFF", cursor: 'pointer', borderRadius: "9999px"}}>
            <FolderDownloadIcon className={`w-[20px]`}/>
            Descargar pdf
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-end', display: 'flex'}}>
            <button style={{margin: "0 15px"}} onClick={() => {setPayoutToSee(null)}}><XIcon className={`w-[20px] text-gray-700 cursor-pointer`}/></button>
          </div>
        </div>
        <div style={{marginTop: "10px", width: "100%", height: "1px", backgroundColor: "#E6E6E6"}}></div>
        <div style={{width: '100%'}}>
        <div style={{marginTop: "15px", display: "flex", gap: "30px"}}>
          <div style={{display:'flex', flexDirection: 'column', gap: "17px"}}>
            <div>Estado</div>
            <div>Periodo</div>
            <div>Fecha</div>
            <div>IBAN</div>
            <div>Métodos de pago</div>
          </div>
          <div style={{display:'flex', flexDirection: 'column', gap: "17px"}}>
            <div style={{display: 'flex'}}>            
              <div style={{padding: "0px 5px", borderRadius: "5px", display: 'flex', gap: '5px', backgroundColor: payout.status == "completed" ? "#D4F8D3" : "#EBEBEB", fontWeight: 550}} >
                <div>{payout.status == "completed" ? "Completado" : "Pendiente"}</div>
                  {payout.status == "completed" ? 
                  <img src={green_check} style={{width: '15px'}} />: 
                  <ClockIcon className={`w-[15px]`}/>}
              </div>
            </div>
            <div style={{ fontSize: "14px", fontWeight: 500, color: '#767676'}}>{start_date} - {end_date}</div>
            <div style={{ fontSize: "14px", fontWeight: 500, color: '#767676'}}>{final_date}</div>
            <div style={{ fontSize: "14px", fontWeight: 500, color: '#767676'}}> ****{payout.iban.substr(payout.iban.length - 4)}</div>
            <div style={{fontSize: "14px", fontWeight: 500, color: '#767676', alignItems: 'center'}}>
              {payout.payment_method == "bizum" ?
              <img style={{ width: "50px"}} src={bizumPng} alt="bizum"/> : 
              <div style={{display: 'flex', gap: "5px"}}>
                <img style={{ width: "35px"}} src={applePay} alt="apple"/>
                <img style={{ width: "40px"}} src={gPay} alt="google"/>
                <img style={{ width: "30px"}} src={mastercardIcon} alt="master"/>
                <img style={{ width: "30px"}} src={visaIcon} alt="visa"/>
              </div>}
            </div> 
          </div>
          </div>
          <div style={{display: 'flex', marginTop: "30px", borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: 600}}>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 0px 1px", justifyContent: 'center', alignItems: 'center'}}>
              Nombre
            </div>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              Importe
            </div>
          </div>
          <div style={{display: 'flex', borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: 500}}>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 0px 1px", justifyContent: 'center', alignItems: 'center'}}>
              Volumen bruto
            </div>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              {centsToAmountString(payout.gross_volume, true, true)}€
            </div>
          </div>
          <div style={{display: 'flex', borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: 500}}>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 0px 1px", justifyContent: 'center', alignItems: 'center'}}>
              Honorarios del adquiriente
            </div>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              -{centsToAmountString(payout.acquirer_fees, true, true)}€
            </div>
          </div>
          <div style={{display: 'flex', borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: 600}}>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px", justifyContent: 'center', alignItems: 'center'}}>
              Volumen net
            </div>
            <div style={{display: 'flex', width: "50%", height: "55px", borderWidth: "1px 1px 1px 0px", justifyContent: 'center', alignItems: 'center'}}>
              {centsToAmountString(payout.net_volume, true, true)}€
            </div>
          </div>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default PayoutDetailModal