import React from 'react';
import styled from 'styled-components';
import { InfoTooltip } from 'common/InfoTooltip';
import { Trans, useTranslation } from 'react-i18next';
import { DiscountPreview } from "components/previews/screens/DiscountPreview";
import { Tooltip } from 'common/Tooltip';
import { DISCOUNT_TYPOLOGIES } from 'pages/discounts/components/constants';
import { toDecimalString } from 'logic/functions';

export const CouponPlaceholderPopover = () => {
  const { t } = useTranslation();

  return (
    <Tooltip>
      <h5>{t('select_coupon')}</h5>
      <p>{t('select_coupon_message')}</p>
    </Tooltip>
  );
};

export const CouponDataPopover = ({ coupon }) => {
  const { t } = useTranslation();

  const unit = coupon?.discount_type === 'percentage' ? '%' : '€';

  return (
    <Tooltip position='bottom'>
      <h5>{t('coupon_data') + ':'}</h5>
      <p>{t('type') + ':'} <b>{t(coupon?.discount_type)}</b></p>
      <p>{t('discount_value') + ':'} <b>{toDecimalString(coupon?.value) + unit}</b></p>
    </Tooltip>
  );
};

export const CouponPreviewPopover = () => {
  return (
    <CouponTooltip position='bottom'>
      <DiscountPreview
        discount={{typology: DISCOUNT_TYPOLOGIES.COUPON}}
      />
      <p>
        <Trans
          i18nKey='info_preselected_coupon'
          defaults='Si <b>no está seleccionada</b>, el usuario deberá ingresar manualmente el código del cupón'
          components={{b: <b/>}}
        />
      </p>
    </CouponTooltip>
  )
};

const CouponTooltip = styled(InfoTooltip)`
  & > div {
    max-width: min-content;
    background-color: #FFFFFF;
    color: #212121;

    & > div {
      margin: 0;
    }
  }
  
  p {
    font-size: 0.875rem;
    text-align: left;
    text-wrap: balance;
    width: 100%;
    max-width: 100%;
    padding: 8px 8px 0;
  }

  b {
    font-weight: 600;
  }
`;