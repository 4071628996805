import React, { useEffect, useContext, useState } from "react";
import { Slide, ToastContainer } from "react-toastify";
import NavigationComponent from "../components/NavigationComponent";
import { Context as AuthContext } from "../data/authContext";

const MenuContainer = (props) => {
  const {
    state: { selectedRestaurant },
    reloadRestaurants,
  } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const [loadingRestaurants, setLoadingRestaurants] = useState(false);

  useEffect(() => {
    const getRestaurants = async () => {
      setLoadingRestaurants(true);
      await reloadRestaurants(selectedRestaurant);
    };
    !loadingRestaurants && getRestaurants();
  }, []);

  return (
    <div
      style={{ backgroundColor: "rgba(244, 244, 245, 1)" }}
      className={"flex flex-row"}
    >
      <div className={"shadow-xl z-10 bg-white"}>
        <NavigationComponent open={open} setOpen={setOpen} />
      </div>
      <div style={{ width: "100%"}}>{props.children}</div>
      <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose={2000}
        transition={Slide}
        closeOnClick
      />
    </div>
  );
};

export default MenuContainer;
