import axios from "axios";
import jwt_decode from "jwt-decode";

export const URL = process.env.REACT_APP_API_URL;
export const ADYEN_URL = process.env.REACT_APP_ADYEN_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ORDER_AND_PAY = process.env.REACT_APP_ORDER_AND_PAY_URL;
export const TRANSLATION = process.env.REACT_APP_TRANSLATION_URL;
export const DASHBOARD_DATA = process.env.REACT_APP_DASHBOARD_DATA;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
export const EVENT_URL = process.env.REACT_APP_EVENT_URL;
export const CREDIT_URL = process.env.REACT_APP_CREDIT_URL;


export const api = axios.create({
  baseURL: URL,
});

export const apiAuth = axios.create({
  baseURL: URL,
});

export const baseAuth = axios.create({
  baseURL: BASE_URL
});

export const apiAuthMultipart = axios.create({
  baseURL: URL,
});

export const adyenAuth = axios.create({
  baseURL: ADYEN_URL,
});

export const orderAndPay = axios.create({
  baseURL: ORDER_AND_PAY,
});

export const translation = axios.create({
  baseURL: TRANSLATION,
});

export const dashboardData = axios.create({
  baseURL: DASHBOARD_DATA,
});

export const paymentAuth = axios.create({
  baseURL: PAYMENT_URL,
});

export const eventUrl = axios.create({
  baseURL: EVENT_URL,
});

export const creditUrl = axios.create({
  baseURL: CREDIT_URL,
});

const authorizationInterceptor = async (config) => {
  config.headers["authorization"] = localStorage.getItem("token");
  config.headers["authorization-type"] = "bo";
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    config.headers["yumminn-token"] = yumminnToken;
  }
  return config;
};

const authorizationTypeInterceptor = async (config) => {
  config.headers["authorization-type"] = "bo";
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    config.headers["yumminn-token"] = yumminnToken;
  }
  return config;
};

api.interceptors.request.use(authorizationTypeInterceptor, (err) => (Promise.reject(err)));
apiAuth.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
apiAuthMultipart.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
baseAuth.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
orderAndPay.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
adyenAuth.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
translation.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
dashboardData.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
paymentAuth.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
eventUrl.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));
creditUrl.interceptors.request.use(authorizationInterceptor, (err) => (Promise.reject(err)));

export const checkToken = async () => {
  let userToken = localStorage.getItem("yumminn-tokens");

  if (!userToken) {
    return false;
  }
  const {access, refresh} = JSON.parse(userToken);

  const decoded = jwt_decode(access);

  if (+Date.now() >= +decoded.exp * 1000) {

    try {
      const res = await api.post("/token/refresh", {refresh})
      localStorage.setItem("yumminn-tokens", JSON.stringify({refresh, access: res.data.access}))
      return true
    } catch (e) {
      // throw new Error("Session expired")
      window.location = "/"
      // return false
    }
  } else {
    return true
  }

};
