import "./SmallSpinner.css"
import React from 'react'

const SmallSpinner = ({style = {}}) => {
    return (
        <div className="loader" style={style}>
            <div className="small-loader-wheel"></div>
        </div>
    )
}

export default SmallSpinner;
