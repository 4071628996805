import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PercentBar } from 'components/custom-graphs/PercentBar';
import { DeltaIndicator } from 'components/yumminn/DeltaIndicator';
import styled from 'styled-components';
import { abbreviateBigNumbers } from 'logic/functions';

export const PeriodRow = ({period, name, showPercent}) => {
  const { t } = useTranslation();

  const barData = period ? [{value: period.new, color: '#094553'}, {value: period.recurrent, color: '#5BB994'}] : [];
  const total = period ? period.new + period.recurrent : 0;

  return (
    <Container>
      <div>
        <Trans i18nKey={'current_' + name}>
          Current <b>period</b>
        </Trans>
      </div>
      <div>
        <PercentBar data={barData} showPercent={showPercent} className='flex-1'/>
        <p>
          <b>
            {abbreviateBigNumbers(total)}
          </b>
          <span>
            {t('total_' + name)}
          </span>
        </p>
        <DeltaIndicator small delta={period?.delta || 0} className='min-w-[3.5rem]'/>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: clamp(8px, 2vw, 16px);

  & > div {
    &:nth-child(1) {
      font-size: 0.875rem;
      line-height: 1;
      width: 4rem;
      
      b {
        font-weight: 600;
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;
      gap: clamp(8px, 2vw, 16px);
      flex: 1;
      // background-color: #F4F4F5;
      border-radius: 6px;
      padding-right: 4px;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    width: 5rem;
    line-height: 1;

    span {
      font-size: 0.65rem;
    }
  }

  @media (max-width: 400px) {
    & > div:nth-child(1) {
      width: 100%;
    }
  }
`;