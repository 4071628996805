import React, {useState} from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CustomInput = styled.input`
	z-index: 5;
	color: #000;
	height: 41px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 8px;
	border-radius: 8px;
	position: relative;
	width: 200px;
	border: 2px solid #D4D4D8;
	font-family: Poppins, sans-serif;
    ${(props) => props.additionalStyle ? props.additionalStyle : ''}
`;
const CustomButton = styled.div`
    margin-left: 8px;
    align-self: center;
    border-radius: 40px;
    font-weight: 400;
    font-size: 12px;
    color: white;
    padding: 0 16px;
    height: 35px;
    background-color: #094553;
    display: flex;
    &:hover {
    cursor: pointer;
    }
    span {
        align-self: center;
    }
`;
const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    ${(props) => props.additionalStyle ? props.additionalStyle : ''}
`;

const ItemNameSearch = ({setSearchItemName, titleStyle="", inputStyle=""}) => {

	const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');

    const searchButtonClicked = _ => {
        setSearchItemName(inputValue);
    };

	return (
		<div>
            <Title additionalStyle={titleStyle}>{t('search_by')}</Title>
            <div style={{display: 'flex'}}>
                <CustomInput
                    placeholder={t('stock_item_search_input_placeholder')}
                    additionalStyle={inputStyle}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                />
                <CustomButton onClick={searchButtonClicked}>
                    <span>{t("Search")}</span>
                </CustomButton>
            </div>
        </div>
	);
};
export default ItemNameSearch;
