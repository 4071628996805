import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { baseAuth } from 'logic/api';
import React from 'react';
import { useTranslation } from "react-i18next";
import Switch from "react-switch";

const ShiftRow = ({shift, setShifts, setLoading, setShiftToRemove, setShiftToEdit}) => {
  const { t } = useTranslation();

  const getBeautyNumber = (number) => {
    return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  	})
  }

  const getHours = (shift) => {
	const start_minute = shift.start_minute
	const start_hour = `${getBeautyNumber(Math.trunc(start_minute / 60))}:${getBeautyNumber(start_minute - (Math.trunc(start_minute / 60) * 60))}`
	const end_minute = shift.start_minute + (shift.slots_quantity * shift.slot_duration_minutes)
	const end_hour = `${getBeautyNumber(Math.trunc(end_minute / 60))}:${getBeautyNumber(end_minute - (Math.trunc(end_minute / 60) * 60))}`

	return `${start_hour}h - ${end_hour}h`
	};

	const changeActiveState = async (new_state) => {
		setLoading(true)
		const newShift = JSON.parse(JSON.stringify(shift))
		newShift["active"] = new_state
		newShift["type"] = "1"
		const body = {"shift": newShift}
		await baseAuth.post(`/restaurant/update_shift`, body)
		  .then((response) => {
			const shifts = response.data.shifts
			console.log(shifts)
			setShifts(shifts)
			setLoading(false)
		  })
		  .catch((e) => {
			setLoading(false)
		  })
	  }

  return (
    <div style={{border: "2px solid #FCFCFC", display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr .7fr", textAlign: 'center', width: "100%", backgroundColor: "#FCFCFC", justifyContent: 'space-around', fontSize: "15px", fontWeight: 500, padding: "10px 0px", marginTop: "10px", alignItems: 'center', borderRadius: '15px'}}>
		<div><div style={{backgroundColor: "#EEF9F5", color: "#404040", border: "1px solid #AFDCCA", padding: '3px 5px', borderRadius: "99999px"}}>{shift.name}</div></div>
		<div>{getHours(shift)}</div>
		<div>{shift.slot_duration_minutes} min</div>
		<div><span style={{backgroundColor: "#EDE0F5", padding: "2px 15px", borderRadius: "5px"}}>{shift.slots_quantity}</span></div>
		<div><span style={{backgroundColor: "#E2FEF1", padding: "2px 15px", borderRadius: "5px"}}>{shift.dishes_in_parallel} {shift.dishes_in_parallel == 1  ? "plato" : "platos"}</span></div>
		<div>
			<Switch
				height={10}
				width={25}
				handleDiameter={15}
				offColor={'#DDD'}
				offHandleColor={'#CCC'}
				onColor={'#C7E6DA'}
				onHandleColor={'#5FB894'}
				activeBoxShadow={'0 0 0 3px #CFEADF'}
				onChange={() => changeActiveState(!shift.active)}
				checked={shift.active}
				uncheckedIcon={false}
				checkedIcon={false}
			/>
		  </div>
          <div className={`h-full text-start text-xs font-normal py-2 items-center justify-center flex`}>
            <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <TrashIcon
              onClick={() => setShiftToRemove(shift)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
              />
            <PencilIcon
              onClick={() => setShiftToEdit(shift)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />  
              </div>
          </div>     
	</div>
  );
};
export default ShiftRow;
