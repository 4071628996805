import FormError from 'common/FormError';
import XButton from 'common/XButton';
import useUpdateTableData from 'components/hooks/useUpdateTableData';
import useRestaurant from 'components/yumminn/useRestaurant';
import { Formik } from 'formik';
import { apiAuth } from 'logic/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyAxios } from 'use-axios-client';
import * as Yup from 'yup';


// construccion del objecto yup de validacion del cuestionario
let obligado = {
  // name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {};

const SettingsDescriptionPage = ({ children }) => {
  const { t } = useTranslation();
  const selected =
  JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) 
  const { seleccionado } = useRestaurant();

  const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants/${selected[0]?.value}`,
  });

  const { updateData, error: updatedError } = useUpdateTableData({
    entidad: 'restaurants',
    id: selected[0]?.value,
  });

  React.useEffect(() => {
    //
    getData();
  }, [getData, seleccionado]);

  const onSubmit = async (values, { setSubmitting }) => {
    //
    const enviaremos = beforeSubmit(values);

    console.group('Enviamos');
    console.log('values :>> ', values);
    console.log(enviaremos);
    console.groupEnd();

    await updateData(enviaremos);
    toast.success(`${t('update_ok')}`);
    getData();
  };

  const beforeSubmit = (values) => {
    const after = { ...values };

    delete after.groups;
    delete after.opening_hours;
    delete after.owner;
    delete after.profile_photos;
    delete after.sectors;
    delete after.chef_photo;
    delete after.logo;
    delete after.menu;
    delete after.home_image;
    delete after.adyen_config;
    delete after.manager;
    delete after.home_image_order_and_pay_or_collect;
    delete after.adyen_account_holder_id;
    delete after.adyen_account_id;
    delete after.adyen_account_tips_id;
    delete after.tpv;
    delete after.tpv_key;
    delete after.tpv_secret;
    delete after.tpv_wildcard_product_name;
    delete after.tpv_wildcard_product_code;
    delete after.tpv_local_id;

    // delete after.tags;
    after.tags = values.tags.map((t) => t.id);
    // delete after.types;
    after.types = values.types.map((t) => t.id);

    return after;
  };

  if (loading) {
    return <div>{t('Loading')}</div>;
  }

  if (error || updatedError) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {
    return (
      <div className={'flex flex-col'}>
        <Formik
          enableReinitialize={true}
          initialValues={data || initialValues}
          validationSchema={validacion}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} autoComplete='off'>
              {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
              {/* <pre>{JSON.stringify(updated, null, 4)}</pre> */}
              <div className='flex flex-row space-x-5 w-full'>
                {/* col1 */}
                <div className='w-full flex flex-col'>
                  {/* // description */}
                  <div className='flex items-center w-full my-4 space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='description'
                    >
                      {t('Description')} ({t('About')})*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <textarea
                        className='block w-full rounded-md bg-white border-transparent focus:ring-0 focus:border-primary focus:bg-primary focus:bg-opacity-30 text-[12px]'
                        rows='5'
                        spellCheck='false'
                        value={values.description || ''}
                        name='description'
                        onChange={handleChange}
                      ></textarea>
                      <FormError message={errors.name} />
                    </div>
                  </div>
                  {/* // our story */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='description'
                    >
                      {t('Our_story')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <textarea
                        className='mt-1 block w-full rounded-md bg-white border-transparent focus:ring-0 focus:border-primary focus:bg-primary focus:bg-opacity-30 text-[12px]'
                        rows='3'
                        spellCheck='false'
                        value={values.story || ''}
                        name='story'
                        onChange={handleChange}
                      ></textarea>
                      <FormError message={errors.name} />
                    </div>
                  </div>
                  {/* // chef name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='description'
                    >
                      {t('Chef_name')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Chef_name')}
                        className='yumminn-input'
                        value={values.chef_name || ''}
                        name='chef_name'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.name} />
                    </div>
                  </div>
                  {/* // chef name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='chef_instagram'
                    >
                      {t('Chef_instagram')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <input
                        type='text'
                        placeholder={t('Chef_instagram')}
                        className='yumminn-input'
                        value={values.chef_instagram || ''}
                        name='chef_instagram'
                        onChange={handleChange}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.name} />
                    </div>
                  </div>
                  {/* // name */}
                  <div className='flex items-center w-full space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='description'
                    >
                      {t('Chef_story')}*
                    </label>
                    <div className='w-full flex flex-col mb-1'>
                      <textarea
                        className='mt-1 block w-full rounded-md bg-white border-transparent  focus:ring-0 focus:border-primary focus:bg-primary focus:bg-opacity-30 text-[12px] '
                        rows='3'
                        spellCheck='false'
                        value={values.chef_story || ''}
                        name='chef_story'
                        onChange={handleChange}
                      ></textarea>
                      <FormError message={errors.name} />
                    </div>
                  </div>
                  {/* // about button */}
                  <div className='flex items-center space-x-2'>
                    <label
                      className='w-48 text-[14px] text-texto text-right'
                      htmlFor='about_button'
                    >
                      {t('About')}
                    </label>
                    <div className='w-full flex flex-col mb-1 mt-1 h-8'>
                      <input  
                        type='checkbox'
                        className='mt-2 rounded bg-gray-200 border-transparent focus:border-transparent text-primary focus:ring-1 focus:ring-offset-2 focus:ring-gray-500'
                        value={values.about_button || ''}
                        name='about_button'
                        onChange={handleChange}
                        checked={values.about_button}
                        autoComplete='one-time-code'
                      />
                      <FormError message={errors.about_button}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className='self-end my-5 w-full'>
                <XButton
                  className='btn btn-primary rounded-full px-12'
                  type='button'
                  doClick={handleSubmit}
                >
                  {t('save')}
                </XButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default SettingsDescriptionPage;
