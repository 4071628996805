import React, { useMemo, useState } from 'react';
import { PurchaseRow } from './PurchaseRow';
import { PurchaseTableContainer } from './TicketsContainers';
import { useTranslation, Trans } from 'react-i18next';
import Spinner from 'common/Spinner';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import EmptyState from 'common/EmptyState';
import { TicketsEmptyStateIcon } from 'common/EmptyStateIcons';

const getSortData = purchase => ({
  status: purchase.tickets.every(ticket => ticket.is_validated) ? 1 : 0,
  date: Date.parse(purchase.date),
  total: purchase.total_cents,
});

const sortByColumn = ([column, order]) => (a, b) => (getSortData(a)[column] - getSortData(b)[column]) * order;

export const PurchaseTable = ({ purchaseList, selectedPurchaseId, isLoading, setSelectedPurchaseId }) => {
  const { t } = useTranslation();

  const [columnSort, setColumnSort] = useState(null);

  const sortedList = useMemo(() =>
    columnSort ? [...purchaseList].sort(sortByColumn(columnSort)) : purchaseList
, [purchaseList, columnSort]);

  const toggleOrder = column => {
    setColumnSort(prev => (!prev || column !== prev[0]) ? [column, 1] : (prev[1] === 1) ? [column, -1] :  null);
  };

  return (
    <PurchaseTableContainer>
      <table>
        <thead>
          <tr>
            <th>
              #{t('Purchase')}
            </th>
            <th onClick={() => toggleOrder('status')}>
              <div>
                {t('Status')}
                <OrderIndicator column='status' columnSort={columnSort}/>
              </div>
            </th>
            <th onClick={() => toggleOrder('date')}>
              <div>
                {t('Date and hour')}
                <OrderIndicator column='date' columnSort={columnSort}/>
              </div>
            </th>
            <th onClick={() => toggleOrder('total')}>
              <div>
                {t('Total')}
                <OrderIndicator column='total' columnSort={columnSort}/>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {!isLoading && (!sortedList || sortedList?.length === 0) &&
              <td style={{border: 'none'}} colSpan='6'>
                  <EmptyState 
                    text={<Trans i18nKey='no_tickets_yet'>
                      <b>Aú no tienes entradas</b>para gestionar
                    </Trans>} 
                    icon={<TicketsEmptyStateIcon />}
                  />
                </td>
            }
          </tr>
          {!isLoading && sortedList?.map(purchase =>
            <PurchaseRow
              key={purchase.id}
              purchase={purchase}
              selected={purchase.id === selectedPurchaseId}
              onClick={setSelectedPurchaseId}
            />
          )}
          {isLoading &&
            <tr>
              <td colSpan={4}>
                <Spinner className='spinner'/>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </PurchaseTableContainer>
  );
};

const OrderIndicator = ({ column, columnSort }) => {
  const order = columnSort && columnSort[0] === column && columnSort[1];

  return (
    <span>
      {(!order || order === 1) &&
        <ChevronUpIcon/>
      }
      {(!order || order === -1) &&
        <ChevronDownIcon/>
      }
    </span>
  );
};