import React from "react";
import { Trans } from "react-i18next";
import VisibilitySchedulesList from "./VisibilitySchedulesList";
import illustration from "../../../../img/empty_schedules.svg";

const ProductSchedules = ({
  product,
  editProduct,
  newSchedule,
  editSchedule,
  setSchedulesToDelete,
}) => {
  const schedules = product?.visibility_schedules || [];
  const isInactive = product.visibility === 0;

  return (
    <div
      className={`flex gap-4 py-6 flex-1 overflow-auto min-h-[50vh] ${
        schedules.length ? "justify-between items-start" : "flex-col"
      }`}
    >
      <VisibilitySchedulesList
        schedules={schedules}
        isInactive={isInactive}
        handleAddSchedule={newSchedule}
        editSchedule={editSchedule}
        editObject={editProduct}
        setSchedulesToDelete={setSchedulesToDelete}
      />
      {!!schedules.length && (
        <i
          className={`text-xs text-[#404040] rounded-md max-w-[38ch] p-2 transition-colors ${
            isInactive ? "bg-[#FFF2D0]" : "bg-gray-100"
          }`}
        >
          <Trans
            i18nKey={
              isInactive
                ? "explanation_schedules_inactive"
                : "explanation_schedules_active"
            }
          >
            All time ranges (day/time) of all active schedules will be{" "}
            <b>complemented</b>. If you want to hide a time range, make sure
            that it doesn't appear in any active schedule.
          </Trans>
        </i>
      )}
      {!schedules.length && (
        <div className="flex flex-col justify-center items-center gap-4 text-center flex-1">
          <img src={illustration} alt="empty schedules" className="h-[158px]" />
          <h4 className="font-semibold text-lg max-w-[36ch] leading-tight">
            <Trans
              i18nKey={
                isInactive
                  ? "title_empty_schedules_inactive"
                  : "title_empty_schedules_active"
              }
            >
              The category will be <u>visible</u> during your entire opening
              hours
            </Trans>
          </h4>
          <p className="max-w-[43ch]">
            <Trans
              i18nKey={
                isInactive
                  ? "explanation_empty_schedules_inactive"
                  : "explanation_empty_schedules_active"
              }
            >
              If you want to customise the visibility, you can{" "}
              <b className="font-semibold">
                define a schedule or disable the category
              </b>{" "}
              at the top right.
            </Trans>
          </p>
        </div>
      )}
    </div>
  );
};

export default ProductSchedules;
