import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import styled from 'styled-components';

const PhoneInputContainer = styled.div`
  .react-tel-input {
    width: 100%;
    height: 40px;
    padding: 2px 4px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    box-sizing: border-box;
    
    input {
      font-size: 14px;
      color: #404040;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
    .flag-dropdown {
      background-color: transparent;
      border: none;
      margin: 8px 0;
    }

    .country-list {
      bottom: 28px;
      border-radius: 10px;
      border: 2px solid gray;
      box-shadow:
      inset 1px 1px 2px #00000005,
      inset 2px 2px 7px #00000010,
      inset -1px -1px 2px #FFFFFF05,
      inset -2px -2px 7px #FFFFFF10
    ;
    }

    .selected-flag {
      padding: 5px;

    
    }
  }


`;

export const BookingPhoneInput = ({
  value,
  placeholder = '',
  disabled = false,
  error,
  onChange,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation();

  return (
    <PhoneInputContainer>
        <ReactPhoneInput
          specialLabel=''
          country={'es'}
          placeholder={t(placeholder)}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          countryCodeEditable={true}
          enableLongNumbers={true}
        />
    </PhoneInputContainer>
  );
};