import React from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { useMediaQuery } from 'hooks/useMediaQuery';
import MenuContainer from 'layouts/MenuContainer';

const DeliveryAndTakeawayLayout = ({ children }) => {
  const { t } = useTranslation();
  const isRowBased = useMediaQuery("(max-width: 760px)");

  return (
    <div id="DeliveryAndTakeawayLayout" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
      {/*<MenuHeader title={t('Delivery and takeaway')} route_group='delivery_and_takeaway'/>*/}
      <MenuHeader title={t('delivery')} route_group='delivery_and_takeaway'/>
 
      <div className=''>
        <div className='flex justify-between items-center w-full bg-white'>
          <div className='flex-1 px-[32px] py-4'>
            <RestaurantSelect />
          </div>   
        </div>
        <div className='px-[32px]'>
          <div>{children}</div>
        </div>
      </div>
      </div>
    </div>

  );
};

export default DeliveryAndTakeawayLayout;
