import React from "react";
import { SelectInput } from "common/Input";
import { BUTTON_VARIANTS, Button } from "common/Button";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { Icon, IconType } from "common/Icon";

const HOURS = 24;
const QUARTER_HOURS = HOURS * 4;

export const TimeRangeSelector = ({ ranges, editRanges, setTimeRangesToDelete }) => {
  const toHourSting = num => {
    const string = num.toString().padStart(4, '0');
    const hour = string.substring(0, 2);
    const min = string.substring(2);

    return `${hour}:${min}`;
  };

  const getQuarterHours = num => {
    const hour = Math.floor(num / 4) * 100;
    const min = (60 / 4) * (num % 4);
    
    return hour + min;
  };

  const hourOptions = new Array(QUARTER_HOURS + 1).fill(null).map((_, index) => ({
    value: getQuarterHours(index),
    label: toHourSting(getQuarterHours(index)),
  }));

  const addRange = () => {
    const newRanges = [
      ...ranges,
      {
        start_hour: 0,
        end_hour: 24,
        start_minutes: 0,
        end_minutes: 0
      }
    ];
  
    editRanges(newRanges);
  };
  
  const editRange = (index, name, value) => {
    const hour = Math.floor(value / 100);
    const min = value - hour * 100;

    const object = {
      [name + '_hour']: hour,
      [name + '_minutes']: min,
    };
    
    const newRanges = [...ranges];
    const updatedRange = { ...newRanges[index], ...object };
    newRanges[index] = updatedRange;
    editRanges(newRanges);
  };
  
  const deleteRange = (index) => {
    const newRanges = [...ranges];
    const deleted = newRanges.splice(index, 1)[0];
    editRanges(newRanges);
    if (deleted.id && setTimeRangesToDelete) {
      setTimeRangesToDelete((prevRanges) => [...prevRanges, deleted.id]);
    }
  };

  const HourSelector = ({label, name, range, index, filter}) => {
    const hour = range[name + '_hour'] || 0;
    const min = range[name + '_minutes'] || 0;
    const value = hourOptions.find(option => option.value === hour * 100 + min)

    return (
      <SelectInput
        label={label}
        value={value}
        name={name}
        placeholder='Select one option'
        onChange={option => editRange(index, name, option.value)}
        options={hourOptions.filter(filter)}
        className={'w-[8rem]'}
        menuPortalTarget={document.body}
      />
    );
  };

  const startFilter = (option, range) => option.value < ((range.end_hour || 0) * 100 + (range.end_minutes || 0))
  const endFilter = (option, range) => option.value > ((range.start_hour || 0) * 100 + (range.start_minutes || 0))

  return (
    <div className={'flex flex-col gap-4'}>
      {ranges.map((range, index) => (
        <div key={'range-' + (range?.id || index)} className={'flex gap-4 items-end'}>
          <HourSelector
            label='From'
            name='start'
            range={range}
            index={index}
            filter={option => startFilter(option, range)}
          />
          <HourSelector
            label='To'
            name='end'
            range={range}
            index={index}
            filter={option => endFilter(option, range)}
          />
          <div className="my-[16px] h-6">
            <Icon
              type={IconType.TRASH}
              size={24}
              disabled={ranges.length < 2}
              onClick={() => deleteRange(index)}
            />
          </div>
          {index === ranges.length - 1 &&
            <Button
              label='add_time_range'
              className="ml-auto my-[6px]"
              leftIcon={<PlusCircleIcon/>}
              onClick={addRange}
              variant={BUTTON_VARIANTS.SECONDARY}
            />
          }
        </div>
      ))}
    </div>
  );
};