import React, { createContext, useContext, useState } from 'react';
import useSWR from 'swr';
import { Context } from 'data/authContext';
import { baseAuth } from 'logic/api';
import { useDebounce } from 'hooks/useDebounce';
import { logToServer } from 'logic/functions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Icon, IconType } from 'common/Icon';

export const SubsidiesContext = createContext();

export const SubsidiesContextProvider = ({ children }) => { const { state: { selectedRestaurantId } } = useContext(Context);

  const { t } = useTranslation();
  
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('-id');
  const [search, setSearch] = useState('');
  const [importResults, setImportResults] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const searchParams = new URLSearchParams ({ page, order });
  const debouncedSearch = useDebounce(search, 500);
  debouncedSearch && searchParams.append('search', debouncedSearch);

  const fetcher = async endpoint => {
    try {
      const res = await baseAuth.get(endpoint);
      return res.data;
    } catch (error) {
      logToServer(`${endpoint} error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`subsidies_error`));
      throw new Error(error);
    }
  };

  const couponFetcher = async endpoint => {
    try {
      const res = await baseAuth.get(endpoint);
      const coupons = res.data.coupons;

      return coupons.map(coupon => ({
        label: coupon.code,
        value: coupon.id,
        color: coupon.discount_type === 'percentage' ? '#CEECFF' : '#F8DCFF',
        coupon,
      }));
    } catch (error) {
      logToServer(`${endpoint} error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`subsidies_coupons_error`));
      return;
    }
  };

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    selectedRestaurantId && `/discount/list/email_coupon_mapping/${selectedRestaurantId}/?${searchParams.toString()}`,
    fetcher,
    {revalidateOnFocus: false},
  );

  const { data: couponOptions } = useSWR(
    selectedRestaurantId && `/discount/list/coupons/${selectedRestaurantId}/`,
    couponFetcher,
    {revalidateOnFocus: false},
  );
  
  const [entryDraft, setEntryDraft] = useState(null);
  const [isImporting, setIsImporting] = useState(null);
  const [isExporting, setIsExporting] = useState(null);

  const addEntry = () => setEntryDraft({
    restaurant: selectedRestaurantId,
    email: '',
    coupon: '',
    preselected: data?.all_preselected || false,
  });

  const changeEntry = partialEntry => setEntryDraft(
    prev => ({...prev, ...partialEntry})
  );

  const clearDraft = () => setEntryDraft(null);

  const saveEntry = async entry => {
    if (!entry || !entry.email || !entry.coupon || isValidating || isSaving) return;
    setIsSaving(true);
    entry.email = entry.email.toLowerCase();

    const body = {...entry};
    body.coupon = entry.coupon.id;

    try {
      await baseAuth.post(`/discount/update_email_coupon_mapping`, body);
      mutate();
      clearDraft();

      !entry.id && toast.success(
        <div style={{display: 'flex', alignItems: 'center', color: '#094553'}}>
          <Icon type={IconType.VERIFIED}/>
          <span style={{fontSize: '1.5rem', fontWeight: '600', textAlign: 'center', flex: '1'}}>
            {t(`email_added`)}
          </span>
        </div>,
        {position: 'bottom-center'}
      );
    } catch (error) {
      logToServer(`email_coupon_mapping error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`update_subsidy_error`));
    } finally {
      setIsSaving(false);
    }
  };

  const deleteEntry = async entryId => {
    if (!entryId) {
      clearDraft();
      return;
    };

    try {
      await baseAuth.delete(`/discount/delete_email_coupon_mapping/${entryId}`).then(res => res.data);

      mutate(prev => {
        const entries = [...prev.entries];
        const index = entries.findIndex(entry => entry.id === entryId);

        entries.splice(index, 1);

        return { ...prev, entries };
      });
    } catch (error) {
      logToServer(`delete_email_coupon_mapping error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`delete_subsidy_error`));
    }
  };

  const toggleAllPreselected = async (preselected) => {
    const body = {
      restaurant: selectedRestaurantId,
      preselected,
    };

    try {
      await baseAuth.post(`/discount/toggle_all_preselected`, body).then(res => res.data);
      mutate();
      entryDraft && changeEntry({preselected});
    } catch (error) {
      logToServer(`toggle_all_preselected error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`toggle_all_preselected_error`));
    }
  };

  const importFile = async event => {
    if (!event.target.files?.[0]) return;

    setIsImporting(true);

    const formData = new FormData();

    formData.append('restaurant', selectedRestaurantId);
    formData.append('file', event.target.files[0])

    try {
      const results = await baseAuth.post(
        `/discount/import_email_coupon_mapping`,
        formData,
        {"Content-Type": "multipart/form-data"}
      ).then(res => res.data);

      mutate();
      setImportResults(results);
    } catch (error) {
      logToServer(`import_email_coupon_mapping error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`import_subsidies_error`));
    } finally {
      event.target.value = ''; // reset input
      setIsImporting(false);
    }
  };

  const exportFile = async () => {
    setIsExporting(true);

    try {
      const file = await baseAuth.get(`/discount/export_email_coupon_mapping/${selectedRestaurantId}`).then(res => res.data);
      const blob = new Blob([file], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      
      a.href = url;
      a.download = `subsidies.csv`;
      
      a.click();
    } catch (error) {
      logToServer(`export_email_coupon_mapping error: ${error.toString()}`, selectedRestaurantId);
      toast.error(t(`export_subsidies_error`));
    } finally {
      setIsExporting(false);
    }
  };

  const downloadEmptySheet = () => {
    const blob = new Blob([`id,email,code_coupon,preselected,delete`], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    
    a.href = url;
    a.download = `new_subsidies.csv`;
    a.click();
  };

  const closeImportResults = () => setImportResults();

  const value = {
    entries: data?.entries || [],
    entryDraft,
    allPreselected : data?.all_preselected || false,
    couponOptions: couponOptions || [],
    pages: data?.pages || {num_pages: 0, num_entries: 0},
    page,
    order,
    search,
    error,
    importResults,
    isLoading,
    isValidating,
    isImporting,
    isExporting,
    addEntry,
    changeEntry,
    saveEntry,
    deleteEntry,
    clearDraft,
    toggleAllPreselected,
    importFile,
    exportFile,
    downloadEmptySheet,
    setPage,
    setOrder,
    setSearch,
    closeImportResults,
  };

  return (
    <SubsidiesContext.Provider value={value}>
      {children}
    </SubsidiesContext.Provider>
  );
};
