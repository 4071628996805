import React from 'react';
import OrdersHeader from './OrdersHeader';
import OrderRow from './OrderRow';
import styled from "styled-components";
import { OrdersEmptyStateIcon } from 'common/EmptyStateIcons';
import EmptyState from '../../../../common/EmptyState.jsx';
import { useTranslation } from 'react-i18next';

const RowContainer = styled.div`
	cursor: pointer;
	user-select: none;
`;

const AnimatedRowContainer = styled.div`
  cursor: pointer;
  user-select: none;
  animation: blink 1s linear 5;
  -webkit-animation: blink 1s linear 5;

  @keyframes blink {
		25% {
			opacity: 0.5;
		} 
		50% {
			opacity: 0;
		} 
		75% {
			opacity: 0.5;
	}
  }
`;

const TableWrapper = styled.div`
	max-height: 1040px;
	overflow-y: auto;
`;

const OrdersTable = ({orders, selectedOrder, setSelectedOrder, incomingOrdersIds, notOpenedIncomingOrdersIds, setNotOpenedIncomingOrdersIds}) => {
	const {t} = useTranslation();

	const handleSelect = (order) => {
		const updatedNotOpenedIds = notOpenedIncomingOrdersIds.filter(n_i => n_i !== order.id)
		setSelectedOrder(order)
		setNotOpenedIncomingOrdersIds(updatedNotOpenedIds)
	};

	return (
		<div>
			<OrdersHeader opacity={Boolean(orders?.length === 0)} />
			{orders?.length > 0 ?
				<TableWrapper>
					{orders?.map(order =>
					incomingOrdersIds.includes(order.id) ?
						<AnimatedRowContainer key={'order_' + order.id} onClick={() => handleSelect(order)}>
							<OrderRow order={order} selected={selectedOrder?.id === order.id} isNotOpened={notOpenedIncomingOrdersIds.includes(order.id)}/>
						</AnimatedRowContainer>
					:
						<RowContainer key={'order_' + order.id} onClick={() => handleSelect(order)}>
							<OrderRow order={order} selected={selectedOrder?.id === order.id} isNotOpened={notOpenedIncomingOrdersIds.includes(order.id)}/>
						</RowContainer>
					)}
				</TableWrapper>
			:
				<TableWrapper>
					<EmptyState text={t('no_orders_yet')} icon={<OrdersEmptyStateIcon />} />
				</TableWrapper>
			}
		</div>
  );
};
export default OrdersTable;
