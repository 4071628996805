import React, { useContext } from 'react';
import { TextInput } from 'common/Input';
import { useTranslation } from 'react-i18next';
import { AccessContext } from './AccesContext';
import { AccessManagementContainter } from './AccessManagementContainers';
import { AccessTable } from './AccessTable';
import { Icon, IconType } from 'common/Icon';
import { Spinner } from 'react-bootstrap';
import { AccessTypeModal } from './AccessTypeModal';

const DOMAIN_REGEX = /^([a-z0-9A-Z]\.)*[a-z0-9-]+\.([a-z0-9]{2,24})+(\.co\.([a-z0-9]{2,24})|\.([a-z0-9]{2,24}))*$/;

const DomainManagement = () => {
  const { t } = useTranslation();

  const {
    search,
    isLoading,
    setSearch,
  } = useContext(AccessContext);

  return (
    <AccessManagementContainter>
      <header>
        <h2>{t('allowed_domains')}</h2>
        <TextInput
          value={search}
          rightIcon={<Icon type={IconType.SEARCH}/>}
          placeholder='search_by_domain'
          onChange={e => setSearch(e.target.value)}
        />
      </header>
      <AccessTable type={'domain'} regexValidator={DOMAIN_REGEX}/>
      <AccessTypeModal/>
      {isLoading &&
        <div className='overlay'>
          <Spinner/>
        </div>
      }
    </AccessManagementContainter>
  );
};

export default DomainManagement;