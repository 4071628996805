import React,{useState, useEffect} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import Select, { components } from 'react-select';
import { categories_select_styles } from 'logic/defaults';
import { Context as AuthContext } from '../../../../data/authContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { XIcon } from '@heroicons/react/outline';
import { Icon, IconType } from 'common/Icon';

export const CustomGroupProductsSettings = ({catalog, customGroupDraft, setProducts}) => {
  const productList = customGroupDraft.products_info?.sort((a,b) => a.order - b.order);
  const is_multi_custom_group_type = customGroupDraft.custom_group_type === "multi"

  const handleDragEnd = ({ destination, source }) => {
    const result = [...productList];
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    let newProductList = result.map((product, index) => ({ ...product, order: index }))
    setProducts(newProductList)
  }

  const removeProduct = (product) => {
    const result = [...productList];
    const index = result.indexOf(product);
    result.splice(index, 1);
    let newProductList = result.map((product, index) => ({ ...product, order: index }))
    setProducts(newProductList)
  }

  const handleChangeOption = (value) => {
    const productId = is_multi_custom_group_type ? value[0].value : value.value
    const foundProduct = productList.find((p_l) => p_l.product_id === productId)
    let result = null
    if (foundProduct) {
      result = [...productList];
      const index = result.indexOf(foundProduct);
      result.splice(index, 1);
    } else {
      if (is_multi_custom_group_type) {
        result = [...productList];
        let newProduct = {...value[0]}
        delete newProduct["value"]
        delete newProduct["label"]
        result.push(newProduct)
      } else {
        result = [];
        let newProduct = {...value}
        delete newProduct["value"]
        delete newProduct["label"]
        result.push(newProduct)
      }

    }
    if (result) {
      let newProductList = result.map((foundProduct, index) => ({ ...foundProduct, order: index }))
      setProducts(newProductList)
    }
    
  }

  const selectorOptions = catalog.product_categories.filter(product_category => product_category.products !== undefined).map((product_category) =>
    ({"label": product_category.name ? product_category.name : product_category.tpv_name, 
    "options": product_category.products.map((product) => 
    ({"label": product.name ? product.name : product.tpv_name,
     "value": product.id,
     "product_image": product.image ? product.image : "",
     "product__tpv_name": product.name ? product.name : product.tpv_name,
     "product__tpv_price_cents": product.tpv_price_cents,
     "product_id": product.id,
      }))}))

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <label>{props.label}</label>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={productList.find((p_l) => p_l.product_id === props.value)}
            onChange={() => null()}
          />{" "}
          <span></span>
          </div>
        </components.Option>
      </div>
    );
  };
  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '30%', height: '100%', marginLeft: !is_multi_custom_group_type ? "5%" : "0px"}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>      
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '7%', paddingRight: '7%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{fontSize: '14px', fontWeight: 500}}>{is_multi_custom_group_type ? "Productos" : "Producto"}</div>
          {!is_multi_custom_group_type && 
          <div style={{fontSize: '14px', marginTop: "15px"}}>Elige un producto que quieras destacar</div>}

          <div style={{marginTop: "15px", fontSize: "13px"}}>
            <Select
                isMulti={is_multi_custom_group_type ? true : false}
                id={'children'}
                value={""}
                options={selectorOptions}
                styles={categories_select_styles}
                className={'w-80'}
                hideSelectedOptions={false}
                closeMenuOnSelect={is_multi_custom_group_type ? false : true}
                onChange={(e) => {
                  handleChangeOption(e);
                }}
                isClearable={false}
                components={{
                  Option
                }}
              />
          </div>
        </div>
        <div style={{fontSize: '13px', marginTop: '15px'}}>{is_multi_custom_group_type ? customGroupDraft.products_info.length < 1 ? "⚠️ Selecciona al menos 1 producto" : "✏️​ Ordena los productos arrastrando y soltando" : customGroupDraft.products_info.length < 1 ? "⚠️ Selecciona 1 producto" : ""}</div>
        <div style={{width: "100%", marginTop: '15px', height: "300px", overflow: 'scroll'}}>
        {is_multi_custom_group_type ? 
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical" style={{ width: '100%' }}>
          {(providedList) =>
            <div
              ref={providedList.innerRef}
              style={{position: 'relative'}}
              {...providedList.droppableProps}
            >
              {productList && productList.map((product, index) =>
              <Draggable key={product.product_id} index={index} draggableId={product.product_id.toString()}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px 0 5px 10px', fontSize: '14px', marginBottom: '10px', borderRadius: '5px', backgroundColor: '#F8F8F8', ...provided.draggableProps.style}}

                  >
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', userSelect: 'none'}}>
                    <div className="min-w-[17px] min-h-[13px]" >
                      <Icon type={IconType.DRAG}/>
                    </div>
                    {product.product__name ? product.product__name : product.product__tpv_name}
                    </div>
                    <button style={{margin: "0 15px"}} onClick={() => removeProduct(product)}><XIcon className={`w-[15px] text-gray-700 cursor-pointer`}/></button>
                  </div>
                  )}
              </Draggable>
              )}
              {providedList.placeholder}
            </div>
          }
        </Droppable>
        </DragDropContext> : 
        productList && productList.map((product, index) => 
        <div style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px 0 5px 10px', fontSize: '14px', marginBottom: '10px', borderRadius: '5px', backgroundColor: '#F8F8F8'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', userSelect: 'none', fontWeight: 600}}>
            {product.product__tpv_name}
            </div>
            <button style={{margin: "0 15px"}} onClick={() => removeProduct(product)}><XIcon className={`w-[15px] text-gray-700 cursor-pointer`}/></button>
          </div>
        )}
        </div>
      </div>  
    </div>
  )
}
