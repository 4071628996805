import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const HeaderButton = styled.div`
  border: 1px #094553 solid;
  border-radius: 40px;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.selected ? "white" : "#094553")};
  padding: 0 16px;
  height: 35px;
  background-color: ${(props) => (props.selected ? "#094553" : "")};
  &:hover {
    color: ${(props) => (props.selected ? "#094553" : "#094553")};
  }
  span {
    &:hover {
      color: ${(props) => (props.selected ? "#094553" : "#094553")};
    }
  }
`;

const MySolutionHeader = ({ title }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      className={`text-[#094553] bg-[#E2FEF1] py-5 w-full px-8 flex flex-row items-center  gap-3 `}
    >
        
      <h1 className="text-[24px] font-bold mr-5 whitespace-nowrap">{t(title)}</h1>

      <HeaderButton
        onClick={() => history.push("/settings/customization")}
        selected={window.location.pathname === "/settings/customization"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
      >
        {t("Customization")}
      </HeaderButton>
      <HeaderButton
        onClick={() => history.push("/qr")}
        selected={window.location.pathname === "/qr"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
      >
        {t("QR_Codes")}
      </HeaderButton>
      <HeaderButton
        onClick={() =>history.push("/admin/billing")}
        selected={window.location.pathname === "/admin/billing"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
      >
        {t("Facturación") }
      </HeaderButton>
      <HeaderButton
        onClick={() =>history.push("/admin/events")}
        selected={window.location.pathname === "/admin/events"}
        className={`flex place-items-center hover:cursor-pointer hover:bg-[#B8F0D9] whitespace-nowrap`}
      >
        {t("events") }
      </HeaderButton>
    </div>
  );
};

export default MySolutionHeader;
