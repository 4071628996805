import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { centsToAmountString } from 'logic/functions';

export const PaymentListOverview = ({ sum }) => {
  const { t } = useTranslation();

  return (
    <CardsContainer>
      <div>
        <Card>
          <b>{centsToAmountString(sum?.base_amount_cents || 0)}€</b>
          <span>{t('total_base_amount')}</span>
        </Card>
        <Card>
          <b>{centsToAmountString(sum?.loyalty_cents || 0)}€</b>
          <span>{t('loyalty')}</span>
        </Card>
        <Card>
          <b>{centsToAmountString(sum?.promo_cents || 0)}€</b>
          <span>{t('promos')}</span>
        </Card>
        <Card>
          <b>{centsToAmountString(sum?.subtotal_cents || 0)}€</b>
          <span>{t('Subtotal')}</span>
        </Card>
        <Card>
          <b>{centsToAmountString(sum?.tip_cents || 0)}€</b>
          <span>{t('Tip')}</span>
        </Card>
        <Card>
          <b>{centsToAmountString(sum?.refunded_cents || 0)}€</b>
          <span>{t('Refunded amount')}</span>
        </Card>
      </div>
      <div>
        <Card>
          <b>{centsToAmountString(sum?.total_cents || 0)}€</b>
          <span>{t('total_amount')}</span>
        </Card>
        <Card>
          <b>{sum?.payments_count || 0}</b>
          <span>{t('payment_amount')}</span>
        </Card>
      </div>
    </CardsContainer>
  );
};

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  max-width: 1400px;
  
  & > div {
    display: flex;
    gap: 16px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(${(100/6).toFixed(5)}% - ${((16 * 5) / 6).toFixed(5)}px);
  min-width: min-content;
  gap: 8px;
  padding: 16px;
  background-color: #FFF;
  border-radius: 16px;
  box-shadow: 0 4px 6px #0001;

  b {
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 1rem;
  }

  span {
    font-size: 0.875rem;
  }
`;