import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

export const RadioButtons = ({name, value, options, disabled, onChange}) => {
  const { t } = useTranslation();
  const tabsRef = useRef(new Array(options.length));

  const index = options.indexOf(value);
  const sliderWidth = tabsRef.current[index]?.clientWidth - 2 || 0;
  const sliderPosition = tabsRef.current[index]?.offsetLeft || 0;

  return (
    <TabContainer disabled={disabled}>
      {options.map((option, index) => (
        <Label
          key={'option-' + option}
          ref={tab => tabsRef.current[index] = tab}
          selected={value === option}
        >
          {t(option)}
          <input
            type="radio"
            name={name}
            value={option}
            checked={value === option}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
          />
        </Label>
      ))}
      <Slider style={{width: sliderWidth + 'px', left: sliderPosition + 'px'}}/>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #DDD;
  border-radius: 10px;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'initial'};
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  white-space: nowrap;
  color: ${({selected}) => selected ? '#094553' : '#404040'};
  cursor: ${({selected}) => selected ? 'initial' : 'pointer'};
  padding: 8px clamp(10px, 2vw, 16px);
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
  z-index: 2;

  &:first-letter {
    text-transform: capitalize;
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;
  }
`;

const Slider = styled.div`
  position: absolute;
  z-index: 0;
  inset: 0;
  margin: 1px;
  background-color: #D3F2E4;
  border: 2px solid #5FB894;
  border-radius: 8px;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;