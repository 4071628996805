import "./DateSelect.css";
import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Context as AuthContext } from '../../data/authContext';
import i18n from "../../i18n"
import {useMediaQuery} from '../../hooks/useMediaQuery';
import calendar from "../../img/calendar.png"; 
import * as dayjs from 'dayjs';
import { useHistory } from "react-router-dom";

export default function YearSelect() {
  const { t } = useTranslation();
  const {
    state: { selectedYear, selectedYearTo },
    setSelectedYear, setSelectedYearTo
  } = React.useContext(AuthContext);
  const [seleccionado, setSeleccionado] = React.useState(null);
  const [seleccionadoTo, setSeleccionadoTo] = React.useState(null);
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();
  const location = history.location.pathname;

  React.useEffect(() => {
    let seleccionado = JSON.parse(sessionStorage.getItem('yumminn-selectedYear'));
    seleccionado && setSeleccionado(seleccionado);
  }, [selectedYear, seleccionado]);

  React.useEffect(() => {
    let seleccionadoTo = JSON.parse(sessionStorage.getItem('yumminn-selectedYearTo'));
    seleccionadoTo && setSeleccionadoTo(seleccionadoTo);
  }, [selectedYearTo, seleccionadoTo]);

  const handleSelect = (opcion) => {
    const offset = opcion.getTimezoneOffset();
    const date = new Date(opcion.getTime() - (offset*60*1000))
    setSelectedYear(date);
    setSeleccionado(date);
  };

  const handleSelectTo = (opcion) => {
    const offset = opcion.getTimezoneOffset();
    const dateTo = new Date(opcion.getTime() - (offset*60*1000))
    setSelectedYearTo(dateTo);
    setSeleccionadoTo(dateTo)
  }

  const setLanguage = () => {
    const language = i18n.language; 

    registerLocale("es", es);
    registerLocale("en", en);
    registerLocale("de", de);
    registerLocale("fr", fr);
  
    switch (language) {
      case "es":
        setDefaultLocale("es");
        break;
  
      case "en":
        setDefaultLocale("en");
        break;
  
      case "de":
        setDefaultLocale("de");
  
        break;
  
      case "fr":
        setDefaultLocale("fr");
        break;
  
      default:
        return;
    }
    return language;
  };

  const language = setLanguage();


  return (
    <div className={`flex z-9999 items-center my-3 ${isRowBased && 'justify-center ml-14'}`} style={{position: 'relative', fontSize: isRowBased ? "14px" : "16px", fontFamily: 'Poppins, sans-serif', fontWeight: 700}}>
      <div className={location === '/loyalty' || location === '/loyalty/mails' || location === '/loyalty/programs' ? 'hidden' : ''}>{t('From')}</div>
      <DatePicker
        portalId='root-portal'
        locale={language}
        selected={typeof seleccionado === 'string' ? new Date(seleccionado) : seleccionado ? seleccionado : new Date()}
        onChange={(a) => {
          handleSelect(a);
        }}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={9}
        style={{border: '2px solid hsl(0, 0%, 80%', zIndex: 9999}}
        maxDate={new Date()}
        minDate={new Date('2022/01/01')}
      />
        {!isRowBased && <label style={{position: 'absolute', left: "145px", pointerEvents: 'none'}}>
          <img src={calendar} alt='' style={{width: "24px", height: '24px'}}/>
        </label>}
      <>
        <div className={location === '/loyalty' || location === '/loyalty/mails' || location === '/loyalty/programs' ? 'hidden' : ''}>{t('to')}</div>
        <DatePicker
          portalId='root-portal'
          locale={language}
          selected={typeof seleccionadoTo === 'string' ? new Date(seleccionadoTo) : seleccionadoTo ? seleccionadoTo : new Date()}
          onChange={(a) => {
            handleSelectTo(a);
          }}
          showYearPicker
          dateFormat="yyyy"
          style={{border: '2px solid hsl(0, 0%, 80%', zIndex: 9999}}
          minDate={new Date('2022/01/01')}
          maxDate={new Date()}
        />
        <label style={{position: 'absolute', left: "340px", pointerEvents: 'none'}}>
          <img src={calendar} alt='' style={{width: "24px", height: '24px'}}/>
        </label>
      </>
    </div>
  );
}
