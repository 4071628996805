import React from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import Switch from "react-switch";

const UnavailableFunctionality = ({title}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`flex flex-col items-center w-full bg-gray-100 h-min `}
        style={{ zIndex: 9999 }}
      >
        {/* <OPHeader title={title} /> */}
        <h1
          className={`font-bold text-[24px] px-[0px] right-0 py-5 w-full text-[#094553] bg-[#E2FEF1] pl-8`}
        >
          {t(title)}
          
        </h1>
      </div>
      <div className="m-auto w-96  ml-36 mt-36">
        <div className="font-medium text-2xl mb-7 flex flex-row items-center gap-5">
          <Switch
            height={25}
            width={45}
            handleDiameter={20}
            offColor={"#DDD"}
            offHandleColor={"#094553"}
            onColor={"#C7E6DA"}
            onHandleColor={"#5FB894"}
            activeBoxShadow={"0 0 0 3px #CFEADF"}
            onChange={null}
            name="loyalty_program"
            checked={false}
            disabled={true}
          />
          {t('activate_orders')}
        </div>
        <div className="whitespace-pre-wrap mb-5">
          <Trans i18nKey={"You dont have this functionality message"}>
            Improve <b>efficiency</b>, your average ticket and the experience of
            your diners with the <b>Order</b> functionality!
          </Trans>
        </div>
        <div className="underline">{t("Contact_our_team")}</div>
      </div>
    </>
  );
};

export default UnavailableFunctionality;
