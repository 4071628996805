import React from "react";
import { useLazyAxios } from "use-axios-client";
import * as dayjs from "dayjs";
import ReactStars from 'react-rating-stars-component';
import useReviewFilter from "components/yumminn/useReviewFilter";
import { apiAuth } from "logic/api";
import Loading from "common/Loading";
import { Context as AuthContext } from '../../../data/authContext';


const RatingsReviewsPage = () => {
  const seleccionado =
  JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) 
  const { activo } = useReviewFilter();
  const [query, setQuery] = React.useState("");
  const {
    state: { selectedWaiter },
  } = React.useContext(AuthContext);

  const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants/${seleccionado[0]?.value}/reviews/?waiters=${selectedWaiter}&${query}`,
  });

 /* React.useEffect(() => {
    getData();
  }, [selectedWaiter, getData]);*/

  React.useEffect(() => {
    //
    let from_date;
    let to_date;

    switch (activo) {
      case 'month':
        //see reviews from the last 30 days
        from_date = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        to_date = dayjs().add(1, 'day',).format('YYYY-MM-DD');
        break;
      case 'year':
        //for all reviews in history
        from_date = dayjs().startOf('year').format('2022-MM-DD');
        to_date = dayjs().format('YYYY-MM-DD');
        break;
      case 'days':
        //see reviews from the last 7 days
        from_date = dayjs().subtract(8, 'day').format('YYYY-MM-DD');
        to_date = dayjs().format('YYYY-MM-DD');
        break;
      default: //for all reviews in history
      from_date = dayjs().startOf('year').format('2022-MM-DD');
      to_date = dayjs().format('YYYY-MM-DD');
        break;
    }

    setQuery(`from=${from_date}&to=${to_date}`);
  }, [activo, getData]);

  React.useEffect(() => {
    //
    if (query !== "") {
      getData();
    }
  }, [query, getData, selectedWaiter]);

  if (error) return <div>{JSON.stringify(error)}</div>;
  if (loading) return <Loading />;
  if (!data) return null;

  if (data) {
    console.log("data", data)
    return (
      <div className={"flex flex-col space-y-2"}>
        {data.results.map(({ comment, experience, datetime, id, payment }) => (
          <div
            key={id}
            className="flex space-x-2 w-full rounded-md bg-white p-4"
          >
            <div className="w-1/6 flex flex-col items-start">
              <div className="text-[14px]">
                <ReactStars
                  count={5}
                  size={16}
                  value={experience}
                  color='#ddd'
                  classNames='rating-stars'
                  activeColor='#5eb894'
                  edit={false}
                />
              </div>
            </div>
            <div className="w-2/6 flex flex-col items-start">
              <div className="text-[14px]">
                {new Date(datetime).toLocaleString()}
              </div>
            </div>
            <div className="w-3/6 flex flex-col items-start text-[12px]">
              {comment}
            </div>
            <div className="w-3/6 flex flex-col items-start text-[12px]">
              {payment?.waiter?.name}
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default RatingsReviewsPage;
