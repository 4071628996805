import { Button, BUTTON_VARIANTS } from 'common/Button';
import { CustomProgressBar } from 'common/CustomProgressBar';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

export const AiTranslationProgressPopup = ({progressPercentage, itemsDone, totalItems}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PopupContainer>
      <Container>
        <h2>{t('auto_translation')}</h2>
        {totalItems > 0 &&
          <p>{itemsDone}/<b>{totalItems}</b> {t('translations_done')} </p> 
        }
        <CustomProgressBar progressPercentage={progressPercentage}/>
        {totalItems > 0 ?
        <em>
        <Trans i18nKey={'translation_progress_message'} minutes={{minutes: Math.ceil((totalItems-itemsDone) / 60)}}>
          The process will take around <b>{{minutes: `${Math.ceil((totalItems-itemsDone) / 60)} ${Math.ceil((totalItems-itemsDone) / 60) > 1 ? t('minutes') : t('minute')}`}}</b> approximately.
          In the meantime, you can continue working in the Back Office.
        </Trans></em> : 
        t("checking_translations_dimensions")}
        <div className='flex gap-4'>
          <Button
            label={t('go_home')}
            onClick={() => history.push(`/admin/home`)}
          />
        </div>
      </Container>
    </PopupContainer>
  );
};


const PopupContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  z-index: 9999;
  background-color: #76767666;
  padding: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: #FFFFFF;
  border-radius: 32px;
  padding: 32px 40px;
  margin: auto;
  width: fit-content;
  max-width: 35%;
  box-shadow: 0 4px 8px #76767640;
  text-align: center;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0.5em;
    max-width: 50ch;
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    max-width: 50ch;
  }
  em {
      font-size: 1rem;
  }
`;