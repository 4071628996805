import React,{useState} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import Select, { components } from 'react-select';
import { getPosition } from './ProductHighlightRow';
import { categories_select_styles } from 'logic/defaults';
import { Context as AuthContext } from '../../../../data/authContext';

export const CustomGroupTitlePositionSetting = ({catalog, customGroupDraft, changeTitle, changePosition}) => {
  const {state: { selectedRestaurant }} = React.useContext(AuthContext);
  const [showInfo, setShowInfo] = useState(true);
  const select_child = {"l1": catalog?.supergroups ? catalog.supergroups.map(supergroup => ({"value": supergroup.id, "label": supergroup.name ? supergroup.name : supergroup.tpv_name})) : [],
                        "l2": catalog?.category_groups ? catalog.category_groups.map(category_group => ({"value": category_group.id, "label": category_group.name ? category_group.name : category_group.tpv_name})) : [],
                        "l3": catalog?.product_categories ? catalog.product_categories.map(product_category => ({"value": product_category.id, "label": product_category.name ? product_category.name : product_category.tpv_name})) : []}
  const select_options = [{"value": "restaurant", "label": "Inicio de la carta", disabled: false},
                          {"value": "l1", "label": "Inicio de categoría principal", disabled: select_child["l1"]?.length === 0},
                          {"value": "l2", "label": "Inicio de categoría secundaria", disabled: select_child["l2"]?.length === 0},
                          {"value": "l3", "label": "Inicio de categoría de producto", disabled: select_child["l3"]?.length === 0}]
  const [childrenSelect, setChildrenSelect] = useState(select_child[getPosition(customGroupDraft)[0]] ? select_child[getPosition(customGroupDraft)[0]] : []);
  const selected_value = select_options.find(s_o => s_o.value === getPosition(customGroupDraft)[0])
  const selected_child = select_child[getPosition(customGroupDraft)[0]]?.find(s_c_c => s_c_c.value === customGroupDraft[getPosition(customGroupDraft)[0]])
  const is_multi_custom_group_type = customGroupDraft.custom_group_type === "multi"
  const innerChangePosition = (value) => {
    setShowInfo(false)
    if (value?.value == "restaurant") {
      changePosition(selectedRestaurant[0].value, null, null, null)
      setChildrenSelect([])
    } else {
      const children_select = select_child[value?.value] ? select_child[value?.value] : []
      setChildrenSelect(children_select)
      switch (value?.value) {
        case "l1":
          changePosition(null, children_select[0].value, null, null)
          break;
        case "l2":
          changePosition(null, null, children_select[0].value, null)
          break;
        case "l3":
          changePosition(null, null, null, children_select[0].value)
          break;    
        default:
          return;
      }
    }
  }
  const innerChangeChildPosition = (value) => {
    setShowInfo(false)
    switch (selected_value?.value) {
      case "l1":
        changePosition(null, value.value, null, null)
        break;
      case "l2":
        changePosition(null, null, value.value, null)
        break;
      case "l3":
        changePosition(null, null, null, value.value)
        break;    
      default:
        return;
    }
  }
  
  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '30%', height: '100%', marginLeft: !is_multi_custom_group_type ? "5%" : "0px"}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>      
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '7%', paddingRight: '7%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {is_multi_custom_group_type &&
          <>
          <div style={{fontSize: '14px', fontWeight: 500}}>Título del destacado</div>
          <div style={{position: 'relative', marginTop: '10px'}}>
            <div style={{fontSize: '14px', position: 'absolute', right: '15px', top: '25%', color: 'gray'}}>{customGroupDraft.name.length}/50</div>
            <input
                type='text'
                placeholder='Ej: ‍👩🏻‍🍳 ¡Sugerencias del chef!'
                className='yumminn-input'
                value={customGroupDraft.name}
                name='custom_group_title'
                style={{width: '10ch'}}
                maxLength={50}
                onChange={(e) => changeTitle(e.target.value)}
              />
          </div>
          {customGroupDraft.name.length < 1 &&
          <div style={{fontSize: '13px', marginTop: '5px'}}>⚠️ Debes añadir un título</div>}</>}

          <div style={{fontSize: '14px', fontWeight: 500, marginTop: '25px'}}>Posición del destacado</div>
          {showInfo || !is_multi_custom_group_type &&
          <>
          <div style={{fontSize: '12px', marginTop: '10px', fontStyle: 'italic'}}>
            <span style={{fontWeight: 600}}>Elige una posición estratégica dentro de tu carta.</span><br/>
            Ejemplos:<br/></div>
            <div style={{fontSize: '12px', fontStyle: 'italic', marginLeft: '15px'}}>
            <b>·</b> Destaca los productos <span style={{fontWeight: 600}}>más vendidos, nuevos o sugerencias del chef</span> al inicio de la carta.<br/>
            <b>·</b> Sugiere tapas que maridan perfectamente con tus vinos en la <span style={{fontWeight: 600}}>categoría de vinos.</span></div></>}
            <div style={{marginTop: "15px", fontSize: "13px"}}>
              <Select
                  id={'children'}
                  value={!selected_value["disabled"]  ? selected_value : null}
                  options={select_options}
                  styles={categories_select_styles}
                  className={'w-80'}
                  hideSelectedOptions={false}
                  onChange={(e) => innerChangePosition(e)}
                  isClearable={false}
                  isOptionDisabled={(option) => option.disabled}
                />
            </div>
            {childrenSelect.length > 0 && <div style={{marginTop: "15px", fontSize: "13px"}}>
              <Select
                  id={'child_children'}
                  value={selected_child}
                  options={childrenSelect}
                  styles={categories_select_styles}
                  className={'w-80'}
                  hideSelectedOptions={false}
                  onChange={(e) => innerChangeChildPosition(e)}
                  isClearable={false}
                  maxMenuHeight={showInfo ? "175px" : "275px"}
                />
            </div>}
        </div>
      </div>  
    </div>
  )
}
