import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from "react-i18next";

const StatusBadge = ({status, refunded, payment, hover=true}) => {
  const { t } = useTranslation();

  if (refunded) {
    return <div className='p-1 bg-red-100 rounded-md text-center z-9999'>{t('refunded')} </div>
  }
  if(!refunded && payment?.refunded_amount > 0){
    return <div className='p-1 bg-red-100 rounded-md text-center z-9999'>{t('partially_refunded')} </div>
  }
  if (status === 'received') {
    return <div style={{backgroundColor: '#ECECEC'}} className='p-1 font-medium text-center rounded-md z-9999'>{t('received')} </div>
  }
  if (status === 'pending_cash') {
    return <div style={{backgroundColor: '#FFF8E2'}} className='p-1 font-medium text-center rounded-md z-9999'>{t('pending_cash')} </div>
  }
  if (status === 'ready_to_pick_up') {
    return <div className='p-1 font-medium text-center  bg-orange-100 rounded-md z-9999'>{t('ready_to_pick_up')} </div>
  }
  if (status === 'success') {
    return <div className='p-1 font-medium text-center  bg-green-100 rounded-md z-9999'>{t('paid')} </div>
  }
  if (status === 'closed') {
    return <div className='p-1 font-medium text-center  bg-green-100 rounded-md z-9999'>{t('finalized')} </div>
  }
  if (status === 'waiting_send_info_to_pos') {
    return <div className='p-1 font-medium text-center  bg-purple-100 rounded-md z-9999'>{t('waiting_send_info_to_pos')} </div>
  }
  if (status === 'error_sending_info_to_pos') {
    return <div className=' z-99 p-1 group relative hover:font-bold cursor-pointer text-blue-500 bg-blue-100 flex flex-row items-center justify-center gap-2  rounded-md '>
      {hover && 
      <div className='group-hover:flex hidden bg-white p-5 text-[#404040] border-[1px] border-gray-400 shadow-md rounded-xl max-w-xs absolute sm:right-20 right-6 bottom-6'>
        <div className=''>
          <div className='relative text-base font-semibold whitespace-nowrap flex flex-row items-center gap-2 ml-0 pb-2'>
            <CheckCircleIcon height={24} color='#5eb894'/>
            {t('payment charged')}
          </div>
          <div>
            <p className='whitespace-nowrap font-medium'> {t('register payment on tpv')}</p>
            <p className='whitespace-nowrap font-medium ml-0'>({t('pos')})</p>
          </div>
        </div>
      </div>}
      <span className='text-center'>
        {hover ? t('see here') : t('error_pos')}
      </span>
      <ExclamationCircleIcon className='h-4 w-4' />
    </div>
  }
  return <div className=' z-9999 text-blue-300'>{t(status)} </div>
};

export default StatusBadge;