import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiAuth } from 'logic/api';
import * as dayjs from 'dayjs';
import useReviewFilter from 'components/yumminn/useReviewFilter';
import { Context } from 'data/authContext';

export const useGetDataInTimeRange = (path, setLoading, setData) => {
	
	const {
		state: { selectedRestaurant },
	} = useContext(Context);
	const { activo } = useReviewFilter();
	const { t } = useTranslation()
	const [accountManagerSelected, setAccountManagerSelected] = useState({id: null, name: t("Todos")})
	const selectedYear = JSON.parse(sessionStorage.getItem("yumminn-selectedYear"))
	const selectedYearTo = JSON.parse(sessionStorage.getItem("yumminn-selectedYearTo"))
	const selectedMonth = JSON.parse(sessionStorage.getItem("yumminn-selectedMonth"))
	const selectedMonthTo = JSON.parse(sessionStorage.getItem("yumminn-selectedMonthTo"))
	const selectedDate = JSON.parse(sessionStorage.getItem('yumminn-selectedDate'))
	const selectedDateTo = JSON.parse(sessionStorage.getItem('yumminn-selectedDateTo'))
	const date = new Date(selectedDate);
	const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
	const endDate = selectedDateTo ? new Date(selectedDateTo) : yesterday;
  const offset = date.getTimezoneOffset();
  const dateEnd = new Date(endDate.getTime() - (offset * 60 * 1000)).toISOString().split('T')[0];

  const getDates = () => {
  let from_date;
  let to_date;

  const offsetMins = new Date().getTimezoneOffset();
  const date = new Date(new Date().getTime() - offsetMins * 60 * 1000);
  const stringDate = sessionStorage.getItem("yumminn-selectedDate")
    ? sessionStorage.getItem("yumminn-selectedDate").substring(1, 11)
    : date.toISOString().substring(0, 10);

  switch (activo) {
    case 'month':
      const startMonth = selectedMonth ? new Date(selectedMonth).getMonth() : new Date().getMonth() - 12;
      const startYearMonth = selectedMonth ? new Date(selectedMonth).getFullYear() : new Date().getFullYear() - 1;
      const fromDateMonth = dayjs().set('month', startMonth).set('year', startYearMonth).startOf('month').format('YYYY-MM-DD');
      const endMonth = selectedMonthTo ? new Date(selectedMonthTo).getMonth() : new Date().getMonth();
      const endYearMonth = selectedMonthTo ? new Date(selectedMonthTo).getFullYear() : new Date().getFullYear();
      const toDateMonth = dayjs().set('month', endMonth).set('year', endYearMonth).endOf('month').format('YYYY-MM-DD')
      from_date = fromDateMonth;
      to_date = toDateMonth;
      break;

    case 'year':
      const startYear = selectedYear ? new Date(selectedYear).getFullYear() : new Date().getFullYear();
      const fromDateYear = dayjs().startOf('year').set('year', startYear).format('YYYY-MM-DD');
      const endYear = selectedYearTo ? new Date(selectedYearTo).getFullYear() : new Date().getFullYear();
      const toDateYear = dayjs().endOf('year').set('year', endYear).format('YYYY-MM-DD')
      from_date = fromDateYear;
      to_date = toDateYear;
      break;

    case 'hours':
      let selectedDate = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().subtract(8, 'day').format('YYYY-MM-DD');
  from_date = new Date(new Date(selectedDate).setHours(1,0,0))
  to_date = new Date(new Date(from_date).setHours(24,59,59))
      break;

    default:
      from_date = sessionStorage.getItem('yumminn-selectedDate') ? stringDate : dayjs().subtract(8, 'day').format('YYYY-MM-DD');
      to_date = dateEnd;
      break;
  }
  return { from_date, to_date };
}

  useEffect(() => {
		const getData = async () => {
			try {
				setLoading(true);
				const res_obj = await apiAuth.post(path, ({
          start: getDates().from_date,
          end: getDates().to_date,
          period: activo,
          restaurant: selectedRestaurant,
          // account_manager: accountManagerSelected
        }));
        setData(res_obj)
        setLoading(false)
      } catch (error) {
      console.log(error);
      }
    }

		if (selectedRestaurant && selectedRestaurant.length > 0 && activo) {
			getData();
		}

	}, [accountManagerSelected, selectedRestaurant, activo, selectedMonth, selectedYear, selectedYearTo, selectedMonthTo, selectedDate, selectedDateTo, setData, path]);
};