import React from 'react'
import { ColumnContainer, RowContainer } from '../ExampleContainers';
import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS, Button } from '../../../common/Button';
import { Icon, IconType } from 'common/Icon';
import { SameSizeButtonGroup } from 'common/SameSizeButtonGroup';

const variants = Object.values(BUTTON_VARIANTS);
const sizes = Object.values(BUTTON_SIZES);
const colors = Object.values(BUTTON_COLORS);

export const ButtonExample = () => {
  return (
    <ColumnContainer>
      <h2>Button</h2>
      <h3>Variants</h3>
      {variants.map(variant => <ButtonRow key={variant} variant={variant}/>)}
      <h3>Colors</h3>
      {colors.map(color => <ButtonRow key={color} color={color}/>)}
      <h3>Sizes</h3>
      {sizes.map(size => <ButtonRow key={size} size={size}/>)}
      <p>Variants, colors and sizes can be combined</p>
      <h2>SameSizeButtonGroup</h2>
      <SameSizeButtonGroup>
        <Button label='short'/>
        <Button label='short'/>
        <Button label='loooooooong'/>
        <Button label='short'/>
        <Button leftIcon={<Icon type={IconType.ADD}/>}/>
        <Button loading/>
      </SameSizeButtonGroup>
      <p>All buttons are the same size as the biggest one</p>
    </ColumnContainer>
  );
};

const ButtonRow = (props) => {
  const label = Object.values(props)[0];

  return (
    <RowContainer>
      <Button {...props} label={label}/>
      <Button {...props} label='left icon' leftIcon={<Icon type={IconType.ADD}/>}/>
      <Button {...props} label='right icon' rightIcon={<Icon type={IconType.PENCIL}/>}/>
      <Button {...props} label='loading' loading/>
      <Button {...props} label='disabled' disabled/>
    </RowContainer>
  );
};
