import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePalette } from 'styles/colors';
import { ButtonMockup, FooterMockup, HeaderMockup, RoadmapMockup } from '../components';
import defaultImage from './../../../img/homeImage.jpg'
import { mockThemes } from '../constants';

export const HomePreview = ({ themeName, colors, logo, homeImage, logoWidth, photoOverlay }) => {
  const { t } = useTranslation();

  const logoRef = useRef(null);
  const [ratio, setRatio] = useState(0);

  const theme = {
    ...mockThemes[themeName],
    ...generatePalette(colors.primary, 'primary'),
    ...generatePalette(colors.secondary, 'secondary'),
    ...generatePalette(colors.tertiary || colors.primary, 'tertiary'),
  };

  const getRatio = () => {
    const logo = logoRef.current;
    const logoHeight = logo?.naturalHeight;
    const logoWidth = logo?.naturalWidth;
    setRatio(logoWidth/logoHeight);
  };

  const getContainerStyles = (logoWidth, ratio) => ({
    height: logoWidth ? 'auto' : (ratio >= 1 ? '30%' : '50%'),
    width: logoWidth ? `${logoWidth}%` : (ratio >= 1 ? '70%' : '30%'),
    maxHeight: '100%',
    maxWidth: logoWidth ? `${logoWidth}%` : ratio > 1 ? '100%' : '50%',
    objectFit: 'contain',
    display: 'flex',
  })

  const getLogoStyles = (logoWidth, ratio) => ({
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: logoWidth || ratio > 1 ? '100%' : '50%',
    objectFit: 'contain',
    borderRadius: '4px',
    margin: 'auto',
  })

  return (
    <div className='flex flex-col h-full'>
      <HeaderMockup themeName={themeName}/>
      <div className='flex-1 bg-cover bg-center relative' style={{backgroundImage: `url(${homeImage || defaultImage})`}}>
        <div
          className={`absolute inset-0 h-full w-full transition-opacity ${photoOverlay ? 'opacity-100' : 'opacity-0'}`}
          style={{backgroundImage: 'linear-gradient(0deg, #0003, #000A)'}}
        />
        <div className='absolute inset-0 h-full w-full flex justify-center items-center pb-9'>
          {logo ?
            <div className='transition-all' style={getContainerStyles(logoWidth, ratio)}>
              <img src={logo} ref={logoRef} onLoad={getRatio} className='transition-all' style={getLogoStyles(logoWidth, ratio)}/>
            </div>
            :
            <div className='text-gray-300 border-2 border-current border-dashed rounded-lg py-4 px-2 filter drop-shadow'>
              {t('your_logo')}
            </div>
          }
        </div>
        <span className='absolute top-0 left-0 text-white text-[8px] p-1'>
          {t('table') + ' X'}
        </span>
      </div>
      <div
        className='relative flex flex-col gap-2 rounded-t-[16px] w-full p-2 mt-[-16px]'
        style={{backgroundColor: theme.background, boxShadow: '0px 0px 6px #00000020, 0px 0px 12px #00000010'}}
      >
        <div className='absolute bottom-full left-0 w-full p-2'>
          <ButtonMockup label={t('menu_button_label')} theme={theme} type='neutral'/>
        </div>
        <ButtonMockup label={t('pay_button_label')} theme={theme}/>
        <RoadmapMockup theme={theme}/>
      </div>
      <FooterMockup theme={theme}/>
    </div>
  );
};
