import { TextInput } from 'common/Input'
import React from 'react'
import { DaySelector } from 'pages/admin/pages/OrderAndPay/DaySelector';
import { TimeRangeSelector } from 'pages/admin/pages/OrderAndPay/TimeRangeSelector';
import { Button, BUTTON_VARIANTS } from 'common/Button';

export const EditVisibilitySchedule = ({schedule, editSchedule, defineSchedule, cancel}) => {
  const editDays = days => editSchedule({days});

  return (
    <>
      <div className='schedule'>
        <TextInput
          label='name_schedule'
          value={schedule.name}
          placeholder='name_schedule_placeholder'
          onChange={event => editSchedule({ name: event.target.value})}
        />
        <DaySelector
          days={schedule.days}
          editDays={editDays}
        />
        <TimeRangeSelector
          ranges={schedule?.times || []}
          editRanges={ranges => editSchedule({ times: ranges })}
        />
      </div>
      <footer>
        <Button
          label={'cancel'}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={cancel}
        />
        <Button
          label={'define'}
          disabled={!schedule.days.length || !schedule.times.length}
          onClick={defineSchedule}
        />
      </footer>
    </>
  )
}
