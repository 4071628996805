import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import {DatesFilter} from "../../components/yumminn/DatesFilter";
import {Context as AuthContext} from '../../data/authContext';
import HomeTabs from './HomeTabs';
import { useHistory } from "react-router-dom";
import XMenuButton from '../../common/XMenuButton';
import MenuContainer from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import DateRangeSelect from 'components/yumminn/DateRangeSelect';

const HomeContainer = ({children}) => {
  const {t} = useTranslation();
  const {selectedReviewFilter, setSelectedReviewFilter} = useContext(AuthContext);
  const history = useHistory();
  const [usuario, setUsuario] = React.useState(null);
  const user = localStorage.getItem("yumminn-user")
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const isRowBased = useMediaQuery("(max-width: 760px)");

  useEffect(() => {
    const user_obj = JSON.parse(localStorage.getItem('yumminn-user'));
    setUsuario(user_obj);
  }, [user]);
  return (
    <div className={`flex flex-row ${isRowBased ? 'bg-white' : 'bg-gray-100'} w-full h-full`}>

      {history.location.pathname === '/admin/dashboard' && <MenuContainer />}
      <div className={'flex flex-col bg-gray-100 w-full h-full'}>
        {!isRowBased && <div id="title" className='bg-white flex justify-between align-center w-full z-9999 sticky top-0 '>
          <h1 className='font-bold text-[24px] px-[24px] py-5 text-[#094553] bg-[#E2FEF1] '>
            {t('Dashboard')}
          </h1>
          <div style={{overflowWrap: 'everywhere', display: 'flex' }} className={'pt-4'}>
            {/*<img src={profile} alt="profile" style={{width: "35px", height: "35px"}} />*/}
            <XMenuButton doClick={() => null}>
              {usuario && usuario.email}
            </XMenuButton>
          </div>
        </div>}
        <div className="bg-gray-100 w-full h-full">
          {/* <div className={`${isRowBased ? 'bg-white' : 'bg-gray-100'}`} 
          style={{
            // width: isRowBased ? '100%' : 'max-content'
            width:'100%'
          }}> */}

            {usuario && !usuario.is_investor && <div id="topBar" style={{zIndex: 9999}} className={`flex flex-col lg:flex-row justify-between items-center lg:px-6 rounded-lg pb-3 pt-3 gap-5 ${isRowBased && "pt-16"} ${!isRowBased ? ' sticky top-20 z-99999' : ''} bg-gray-100`
              // ${period !== 'hours' && 'lg:pb-5'}
              }>
                <RestaurantSelect/>
                <DateRangeSelect/>
                <DatesFilter doFilter={setSelectedReviewFilter}/>
              </div>}
            {usuario && usuario.is_investor && <div className={`flex flex-col sm:flex-row justify-between items-center w-full ${period !== 'hours' ? 'px-12' : 'pr-12'} rounded-lg ${period !== 'hours' && 'pb-12'} my-4 gap-5`}>
              <RestaurantSelect/>
              <DateRangeSelect/>
            </div>}
            {history.location.pathname !== '/home' && history.location.pathname !== '/admin/dashboard' && <HomeTabs filter={{selectedReviewFilter}}/>}
            <div 
            className={
              // isRowBased ? '' : 'w-full px-[32px]'
              'w-full'
            }>{children}</div>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};

export default HomeContainer;
