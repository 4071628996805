import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { useMediaQuery } from "../hooks/useMediaQuery";
import useModal from 'components/hooks/useModal';
import { components } from "react-select";
import Select from 'react-select';
import { select_styles } from 'logic/defaults';
import {toast} from 'react-toastify';


const RentabilibarLeads = () => {
  
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const infoModal = useModal();
  const [leadsData, setLeadsData] = useState([]);
  const [rowChangedIdx, setRowChangedIdx] = React.useState([]);


  const options = [
    { value: 'new', label: t('Nuevo') },
    { value: 'hired', label: t('Contratado') },
    { value: 'refused', label: t('Rechazado') },
    { value: 'negotiation', label: t('Negotiation') },
    { value: 'other_service', label: t('Contrata otro Servicio') },
    { value: 'future', label: t('Interesado en el futuro') }
  ]

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await baseAuth.get(`/sales/rentabilibar-leads`);
      if (response?.status == 200 && response?.data) {
        //console.log(response.data, "DATA")
        setLeadsData(response.data)

      }
    } catch(e) {
      console.log(e.toString(), "<- payment/invoices exception (InvoicesTable.jsx)");
    } finally {
      setLoading(false);
    }
  }, []);


  const Option = (props) => {
    return (
      <div>
        {props.value !== "new" &&        
        <components.Option {...props}>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null()}
          />{" "}
          <span></span>
          <label>{props.label}</label>
        </components.Option>}
      </div>
    );
  };

  const handleSelect = (opcion, index) => {
    let newLeadsData = {...leadsData}
    let newrowChangedIdx = [...rowChangedIdx]
    newLeadsData.info[index].status = opcion.value
    setLeadsData(newLeadsData);
    if (!newrowChangedIdx.includes(index)){
      newrowChangedIdx.push(index)
    }
    setRowChangedIdx(newrowChangedIdx)
  };

  const handleChangeState = async (index_value) => {
    let leadData = leadsData.info[index_value]
    const response = await baseAuth.post(`/sales/rentabilibar-change-lead-state`, leadData);
      if (response?.status == 200) {
        toast.success(`${t('Estado actualizado con éxito')}`);
      } else {
        toast.error(`${t('Error al actualizar el estado')}`);
      }
      if (!response.data.status) {
        toast.error(`${t('Error al actualizar el estado')}`);
        window.location.reload();
      } else {      
        let newLeadsData = {...leadsData}
        let newrowChangedIdx = [...rowChangedIdx]
        newLeadsData.info[index_value].status = response.data.status
        setLeadsData(newLeadsData);
        var index = newrowChangedIdx.indexOf(index_value);
      if (index > -1) { 
        newrowChangedIdx.splice(index, 1);
      }
        setRowChangedIdx(newrowChangedIdx)
    }
  };

  const findStatusObjs = (status) => {
    return options.find((option) => {
      return option.value === status;
    })
  }

  return (
    <>
    <div className="flex overflow-x-auto z-0 h-full w-full" >
          <div className={isRowBased ? "overflow-x-auto h-full" : "flex overflow-x-auto z-0 h-full w-full"}>
            <div
              style={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              }}
              className="col-span-2 flex flex-col w-full"
            >
              {loading && (
                <div className="h-full w-full absolute z-9999 flex flex-col justify-center items-center">
                  <Spinner>{t("Loading")}</Spinner>
                </div>
              )}
              <table
                className={
                  loading
                    ? "bg-white rounded-md w-full border-collapse overflow-y-auto overflow-x-auto opacity-25"
                    : "bg-white rounded-md w-full border-collapse overflow-y-auto overflow-x-auto"
                }
              >
                <thead style={{zIndex: 99999}} className="w-full text-xs sticky top-0 m-0 bg-white">
                    {/*Table headers*/}
                    {leadsData && leadsData.headers && leadsData.headers.map((header, index) => 
                    <th
                      key={`th2${index}`}
                      className="px-3 w-1/6 text-center items-center"                     
                    >
                      <div key={`div${index}`} 
                        className="flex flex-row items-center cursor-pointer justify-center whitespace-nowrap"
                      >
                        <div>{header}</div>
                      </div>
                    </th>
                    )}
                </thead>
                <tbody className="text-sm">
                  {leadsData && leadsData.info &&
                    leadsData.info.map((item, index) => {
                      return (                        
                        <tr className="bg-white rounded-md flex-grow w-full">
                          <td key={`td1${index}`} className="w-1/6 px-4 py-3 text-center whitespace-nowrap">
                            <div>
                              {item.serviceName}
                            </div>
                          </td>
                          <td key={`td2${index}`} className="w-1/6 px-4 py-3 text-center whitespace-nowrap">
                            <div>
                              {item.accountName}
                            </div>
                          </td>
                          <td key={`td3${index}`} className="w-1/6 px-4 py-3 text-center whitespace-nowrap">
                            <div>
                              {item.applicationDate}
                            </div>
                          </td>
                          <td key={`td4${index}`} className="w-1/6 px-4 py-3 text-center whitespace-nowrap">
                            <div>
                              {item.contactPhone} 
                            </div>
                          </td>
                          <td key={`td5${index}`} className="w-1/6 px-4 py-3 text-center whitespace-nowrap">
                            <div>
                              {item.contactEmail} 
                            </div>
                          </td>
                          <td key={`td6${index}`} className="w-1/6 px-4 py-3 text-center whitespace-nowrap">
                            <div style={{display: "flex"}}>
                              {!["hired", "refused"].includes(item.status) || rowChangedIdx.includes(index) ?
                              <Select
                                menuPortalTarget={document.body}
                                multiple={false}
                                isClearable={false}
                                components={{
                                  Option
                                }}
                                closeMenuOnSelect={true}
                                styles={select_styles}
                                hideSelectedOptions={false}
                                className={isRowBased ? '' : 'w-full max-w-md text-[14px]'}
                                name={'Estado'}
                                placeholder={t(`Estado`)}
                                options={options}
                                value={findStatusObjs(item.status)}
                                onChange={(a) => {
                                  handleSelect(a ,index);
                                }}
                              /> : <div style={{width: "100%"}}>{findStatusObjs(item.status).label}</div>}
                              {rowChangedIdx.includes(index) && <button
                                className={`${isRowBased ?  'flex flex-row justify-center align-center rounded-full cursor-pointer shadow-md px-1 py-1 mx-2 my-1 border-4 border-principal text-sm text-gray-50 font-bold uppercase tracking-wide bg-principal hover:bg-gray-50 hover:text-principal disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200' : 'flex flex-row justify-center align-center rounded-full cursor-pointer shadow-md px-8 py-2 mx-2 border-4 border-principal text-sm text-gray-50 font-bold uppercase tracking-wide bg-principal hover:bg-gray-50 hover:text-principal disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200'}`}
                                onClick={() => handleChangeState(index)}
                              >
                                SAVE
                              </button>}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            
            <infoModal.Modal>
                {t('avg_ticket_explanation')}
            </infoModal.Modal> 
          </div>
        </div>
    </>
  )
}

export default RentabilibarLeads