import React, { useContext, useRef } from "react";
import { PencilIcon } from '@heroicons/react/outline';
import { EyeOffIcon, ClockIcon, EyeIcon } from '@heroicons/react/outline';
import { TabButtonContainer } from "./DraggableContainers";
import { CatalogDNDContext } from "data/catalogDnDContext";
import { useTranslation } from "react-i18next";
import { Tooltip } from "common/Tooltip";
import { Icon, IconType } from "common/Icon";

const VISIBILITY = {
  ICONS: [
    <EyeOffIcon height={18}/>,
    <EyeIcon height={18}/>,
    <ClockIcon height={18}/>,
  ],
  LABELS: [
    "not_visible",
    "visible",
    "visibility_schedule",
  ],
};

export const TabButton = ({
  listId,
  item,
  selected,
  provided,
  disabled = false,
  disableHover = false,
  isLocked = false,
  setSelected = () => null,
  openEditModal = () => null,
}) => {
  const { setLastHover } = useContext(CatalogDNDContext);
  const { t } = useTranslation();

  const listName = listId.split('-')[0];

  const timeout = useRef(null);
  
  const selectItem = () => !disabled && setSelected(item);

  const setSelectTimeout = () => {
    if (!isLocked || disableHover) return;

    setLastHover({[listName]: item.id});

    if (selected) return;
    
    timeout.current = setTimeout(() => {
      const lastHoverByName = {
        SUPER_GROUP: {SUPER_GROUP: item.id, CATEGORY_GROUP: null, CATEGORY: null},
        CATEGORY_GROUP: {CATEGORY_GROUP: item.id, CATEGORY: null},
        CATEGORY: {CATEGORY: item.id,},
      };

      setSelected(item);
      setLastHover(lastHoverByName[listName]);
    }, 500);
  };

  const clearSelectTimeout = () => timeout.current && clearTimeout(timeout.current);

  return (
    <TabButtonContainer
      level={listName}
      selected={selected}
      disabled={disabled}
      isLocked={isLocked && !disableHover}
      onClick={selectItem}
      onMouseEnter={setSelectTimeout}
      onMouseLeave={clearSelectTimeout}
    >
      <div {...provided.dragHandleProps}>
        <Icon type={IconType.DRAG}/>
        <div>
          {VISIBILITY.ICONS[item.visibility]}
          <Tooltip position="top">{t(VISIBILITY.LABELS[item.visibility])}</Tooltip>
        </div>
        <div className={`flex flex-row gap-2`}>
          {item?.visible_name || item?.name}
        </div>
        {selected &&
          <Icon
            type={IconType.PENCIL}
            color={'currentColor'}
            disabled={disabled}
            onClick={() => openEditModal(item)}
          />
        }
      </div>
    </TabButtonContainer>
  );
};
