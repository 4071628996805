import React from 'react';
import { Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import ProtectedRoute from 'common/ProtectedRoute';
import HomeContainer from './TablesContainer';
import TablesContainerNoLogin from './TablesContainerNoLogin';
import AppRoute from "../../common/AppRoute";
import TablesPage from './pages/Tables';

const HomeRoutes = () => (
  <>
    <Router>
      <Switch>
        {/* home */}
        {/*<ProtectedRoute
          exact
          path='/tables'
          component={TablesPage}
          layout={HomeContainer}
        />
        <AppRoute
          exact
          path='/tables/:id/:token'
          component={TablesPage}
          layout={TablesContainerNoLogin}
        />*/}
        {/* <ProtectedRoute
          exact
          path='/tables/sectors'
          component={TablesSectorPage}
          layout={HomeContainer}
        /> */}
        {/* comodin */}
      </Switch>
    </Router>
  </>
);

export default HomeRoutes;
