import React,{useState} from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import RoundButton from "./RoundButton";

export const CustomGroupScheduleSettings = ({customGroupDraft, changeVisibilitySchedules, setEditSchedule}) => {
  const { t } = useTranslation();
  const getVisibility = (visibility) => {
    const number_to_weekday = {0: "Lun", 1: "Mar", 2: "Mie", 3: "Jue", 4: "Vie", 5: "Sab", 6: "Dom"}
    let days = visibility.schedule?.days?.map((day) => number_to_weekday[day])
    const visibility_schedule = {"days": days.join(', '), "time_ranges": []}
    for (let e= 0; e < visibility.time_range.length; e++){
      let clean_from = `${visibility.time_range[e].from[0].toString().padStart(2,0)}:${visibility.time_range[e].from[1].toString().padStart(2,0)}`
      let clean_to = `${visibility.time_range[e]?.to[0].toString().padStart(2,0)}:${visibility.time_range[e].to[1].toString().padStart(2,0)}`;
      visibility_schedule["time_ranges"].push(clean_from + " - " + clean_to)
    }            
    return visibility_schedule           
  };

  const handleRemove = (visibility) => {
    let newVisibilitySchedules = [...customGroupDraft.visibility_schedules];
    
    const index = newVisibilitySchedules.findIndex(schedule =>
      schedule.temp_id === visibility.temp_id || schedule.schedule.id === visibility.schedule.id
    );

    newVisibilitySchedules.splice(index, 1);

    changeVisibilitySchedules(newVisibilitySchedules);
  };

  const handleAdd = () => {
    let newVisibilitySchedules = [...customGroupDraft.visibility_schedules]
    newVisibilitySchedules.push({"temp_id": newVisibilitySchedules.length,
                                 "schedule": {"id": null, "days": [0,1,2,3,4,5,6]},
                                 "time_range": [{"id": null, "from": [0, 0], "to": [24, 0]}],
                                 "index": newVisibilitySchedules.length})
    changeVisibilitySchedules(newVisibilitySchedules)
  };


  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '60%', height: '100%', display: 'flex', flexDirection: 'column'}}>
        {customGroupDraft.visibility_schedules.map((visibility) =>
            <div key={visibility.id} style={{display: 'flex', alignItems: 'center', gap: "5px"}}>
              <div style={{fontStyle: 'italic', fontSize: "11px", borderRadius: '5px', borderWidth: "2px", padding: '6px 10px', width: '65%', marginTop: '10px', display: 'flex'}}>
                {getVisibility(visibility).days}
                <div style={{marginLeft: '15px'}}>
                {getVisibility(visibility).time_ranges.map((time_range) =>
                  <div>{time_range}</div>
                )}
                </div>
              </div>
              <PencilIcon style={{marginLeft: '5px', marginTop: '10px'}} height={24} color={'#999999'} className="cursor-pointer" onClick={() => setEditSchedule(visibility)}/>
              <TrashIcon style={{marginTop: '10px'}} height={24} color={'#999999'} className="cursor-pointer" onClick={() => handleRemove(visibility)}/>
            </div>
        )}
        <button
          style={{marginTop: '10px', width: '65%', fontSize: "13px"}}
          onClick={() => handleAdd()}
          className='text-principal font-medium border-dashed border-2 border-gray-300 rounded-lg flex justify-center items-center p-1 gap-1 h-9'>
          <PlusIcon height={18}/>
          {t('define_schedule')}
        </button>
      </div>
      <div style={{width: '40%', height: '100%'}}>
        <div style={{fontSize: "11px", fontStyle: 'italic', backgroundColor: "#EFEFEF", borderRadius: "10px", padding: '10px'}}>
          <Trans i18nKey={'explanation_schedules_active'}>
            All time ranges (day/time) of all active schedules will be <b>complemented</b>. If you want to hide a time range, make sure that it doesn't appear in any active schedule.
          </Trans>
        </div>
      </div>
    </div>
  )
}
