import React, { useEffect, useRef, useState } from 'react';
import { getTransitionEndEventName } from 'logic/functions';
import { OrderData } from './OrderData';
import { XIcon } from '@heroicons/react/outline';
import { Panel, SideOrderPanel } from './LockerContainers';

export const SidePanel = ({ panel, sideOrder, closeSideOrder, children }) => {
  const isInitialRenderRef = useRef(true);

  const [visibleChildren, setVisibleChildren] = useState(children);
  
  const panelRef = useRef(null);
  const orderRef = useRef(null);
  
  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false; // Avoid closing on mount
    } else {
      const changeChildren = () => {
        panelRef.current.removeEventListener(getTransitionEndEventName(), changeChildren);
        panelRef.current.classList.remove('out');
        setVisibleChildren(children.every(child => !child) ? null : children);
      };
      
      closeSideOrder();
      panelRef.current?.classList.add('out');
      panelRef.current?.addEventListener(getTransitionEndEventName(), changeChildren);
    }
  }, [panel]);

  useEffect(() => {
    const clickOutsideSideOrder = event => {
      if (orderRef.current.contains(event.target)) return;
      closeSideOrder();
    }

    sideOrder && window.addEventListener('click', clickOutsideSideOrder);

    return () => window.removeEventListener('click', clickOutsideSideOrder);
  }, [sideOrder])

  return (
    <Panel ref={panelRef}>
      <main>
        {visibleChildren}
      </main>
      <SideOrderPanel ref={orderRef} className={sideOrder? '' : 'out'}>
        <button onClick={closeSideOrder}>
          <XIcon height={24}/>
        </button>
        {sideOrder &&
          <OrderData order={sideOrder}/>
        }
      </SideOrderPanel>
    </Panel>
  );
};