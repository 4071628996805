import React from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import MenuContainer from 'layouts/MenuContainer';


const PaymentsContainer = ({ children }) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");

  return (
    <div id="paymentsContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
        <MenuHeader title={"Payments and orders"} route_group={'payments_and_orders'}/>
        
        <div className={`${isRowBased && 'bg-[#EFF8F4]'}`} >
          <div className="bg-white pt-4 pb-4 pl-8">
            <RestaurantSelect />
          </div>
          <div className='px-[32px]'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsContainer;
