import "./BigSpinner.css"
import React from 'react'

const BigSpinner = ({text = "", style = {}}) => {
    return (
        <div className="loader" style={style}>
            <div className="big-loader-wheel"></div>
            <div className="loader-text">{text}</div>
        </div>
    )
}

export default BigSpinner;
