import React from 'react';
import { useTranslation } from "react-i18next";
import { awareDatetimeString } from 'logic/functions';
import OrderClientInfo from 'pages/admin/pages/Orders/OrderDetailComponents/OrderClientInfo';
import OrderTotals from 'pages/admin/pages/Orders/OrderDetailComponents/OrderTotals';
import OrderPaymentMethod from 'pages/admin/pages/Orders/OrderDetailComponents/OrderPaymentMethod';
import RechargeCard from './RechargeCard';

const TopupDetail = ({topup, statusLoading}) => {
  const { t } = useTranslation();
  return (
	<div style={{padding: '20px 20px 40px 20px'}}>
		<div style={{display: 'flex', justifyContent: 'space-between'}}>
			<div style={{fontWeight: 500, fontSize: '22px', color: '#222222'}}>#{t('ID topup')}: <span style={{fontWeight: 700}}>{topup.id}</span></div>
		</div>
		<div style={{fontWeight: 500, marginTop: "5px"}}>{awareDatetimeString(topup.date, "Europe/Madrid")}</div>
		<OrderClientInfo order={topup}/>
		<RechargeCard recharge={topup}/>
		<div style={{ marginTop: "25px"}}>
			<OrderTotals text={"total_bill"} amount={topup.base_amount + topup.tip - topup.discount}/>
			<div style={{marginTop: "15px", display: 'flex', flexDirection: 'column', gap: "10px"}}>
				<OrderTotals text={"subtotal"} amount={topup.base_amount} light={true}/>
				<OrderTotals text={"tip"} amount={topup.tip} light={true}/>
				{topup.discount > 0 &&
					<OrderTotals text={"discounts"} amount={topup.discount} light={true} negative={true}/>
				}
			</div>
		</div>
		{topup.payment_method && 
			<OrderPaymentMethod method={topup.payment_method} brand={topup.card_brand} last4={topup.card_last4}/>
		}
	</div>
  );
};
export default TopupDetail;
