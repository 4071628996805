import {  useLazyAxios } from "use-axios-client";
import { apiAuth } from "logic/api";

const useUpdateTableData = ({ entidad, id }) => {

	const [updateData, { data, error, loading, cancel }] = useLazyAxios({
		axiosInstance: apiAuth,
		method: "PATCH",
		url: `/${entidad}/${id}`,
	});

	return {
		data,
		error,
		loading,
		cancel,
		updateData
	};
};

export default useUpdateTableData;
