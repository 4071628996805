import React, { useState, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import NewFeaturesIcon from "common/NewFeaturesIcon";
import ArrowForwardCircle from "common/ArrowForwardCircle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const Updates = () => {
	const {
		state: { selectedRestaurantId }
	} = React.useContext(AuthContext);
	const { t } = useTranslation();
	const [PaymentCount, setPaymentCount] = useState(0);	
	const [UsersCount, setUsersCount] = useState(0);	
	const [ReviewsCount, setReviewsCount] = useState(0);	
	const [CustomerTimeSaved, setCustomerTimeSaved] = useState(0);
	const [WaiterTimeSaved, setWaiterTimeSaved] = useState(0);
	const [showTooltip, setShowTooltip] = useState(false);
	const isMounted = useRef(true);

	const [last30Days, setLast30Days] = useState({
		start: "",
		end: "",
	});
	const history = useHistory(); 
	
	
	function getLast30days() {
		const today = new Date();
		const thirtyDaysAgo = new Date(today);
		thirtyDaysAgo.setDate(today.getDate() - 30); 
	  
		setLast30Days({
		  start: thirtyDaysAgo.toLocaleDateString(),
		  end: today.toLocaleDateString(),
		});
	  }
	  
	useEffect(() => {
		isMounted.current = true;
		getLast30days();
		return () => {
			// Component is unmounting
			isMounted.current = false;
		};
	}, [])

	  
	useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setPaymentCount(0)
		setUsersCount(0)
		setReviewsCount(0)
		if (last30Days.start && last30Days.end && selectedRestaurantId && selectedRestaurantId) {
			getData();
		}
	}, [last30Days, selectedRestaurantId])

	const parseDate = (date, type) => {
		const parts = date.split('/');
		if (parts.length === 3) {
			const day = parseInt(parts[0], 10);
			const month = parseInt(parts[1], 10) - 1; 
			const year = parseInt(parts[2], 10);
			
			let dateTime = new Date(Date.UTC(year, month, day));
			if (type === 'end'){
				dateTime = new Date(Date.UTC(year, month, day, 23, 59, 59));
			}
			return dateTime
		} else {
			console.error('Invalid date format');
		}
	}

	const getData = async () => {
		if (!isMounted.current) {
			return;
		}
		const obj = {
			from: parseDate(last30Days.start, 'start'), 
			to: parseDate(last30Days.end, 'end'),
			restaurant: selectedRestaurantId,
		}
		await baseAuth.post(`/api/yumminn_updates/`, obj)
			.then((response) => {
				const payment_count = response.data.payment_count
				const users_count = response.data.users_count
				const reviews_count = response.data.reviews_count
				const customer_time_saved = response.data.customer_time_saved
				const waiter_time_saved = response.data.waiter_time_saved
				setPaymentCount(payment_count)
				setUsersCount(users_count)
				setReviewsCount(reviews_count)
				setCustomerTimeSaved(customer_time_saved)
				setWaiterTimeSaved(waiter_time_saved)
			})
			.catch((e) => {
				console.log(`Error in Updates: ${e}`)
			})
	}

	return (
		<>
        <div className={`flex-auto flex flex-col items-center w-full bg-white rounded-[27px] p-8 mb-6`} >
			<>
				<div className="text-black font-poppins text-2xl w-full flex items-center">
					<span role="img" aria-label="Calendar" className="mr-2">&#x1F4C5;</span>
					<div className='font-semibold'>
						{/*<Trans i18nKey={"your week in yumminn"}>
							Your <span className='font-semibold'> week</span> in Yumminn
						</Trans>*/}
						{t("last_30_days_with_yumminn")}
					</div>
					<div className="text-base text-[#000] font-semibold text-left pt-1 pl-1">({t('From')} {last30Days.start} {t('to')} {last30Days.end})</div>
				</div>
				<div style={{display: 'flex', alignSelf: 'flex-start', width: '100%'}}>
					<div className="flex flex-wrap gap-6 overflow-x-hidden w-full pt-5">
						<div className="pt-1 pl-2 pr-2 pb-2 col-span-1" style={{minWidth: "200px", maxWidth: "220px", display: 'flex', flexDirection: "column", alignSelf: 'flex-start', width: "16%", height: "110px", justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)", border: "1px solid #D7D7D7"}}>
							<div className="flex align-center">
								<NewFeaturesIcon icon="⏱" color="#E0F5ED"/> 
								<span className="pl-3 text-xl font-bold mt-1 text-[#2D3748]">{WaiterTimeSaved}</span>
							</div>
							<div className="pt-2 text-[#404040] text-xs text-left pl-11 pr-2">{t('time_saved_for_waiters')}</div>
						</div>
						<div className="pt-1 pl-2 pr-2 pb-2 col-span-1" style={{minWidth: "200px", maxWidth: "220px", display: 'flex', flexDirection: "column", alignSelf: 'flex-start', width: "16%", height: "110px", justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)", border: "1px solid #D7D7D7"}}>
							<div className="flex align-center">
								<NewFeaturesIcon icon="⏱" color="#E0F5ED"/> 
								<span className="pl-3 text-xl font-bold mt-1 text-[#2D3748]">{CustomerTimeSaved}</span>
							</div>
							<div className="pt-2 text-[#404040] text-xs text-left pl-11 pr-2">{t('time_saved_for_diners')}</div>
						</div>
						<div className="pt-1 pl-2 pr-2 pb-2 col-span-1" style={{minWidth: "200px", maxWidth: "220px", display: 'flex', flexDirection: "column", alignSelf: 'flex-start', width: "16%", height: "110px", justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)", border: "1px solid #D7D7D7"}}>
							<div className="flex align-center">
								<NewFeaturesIcon icon="🧾️" color="#E0F5ED"/> 
								<span className="pl-3 text-xl font-bold mt-1 text-[#2D3748]">{PaymentCount}</span>
							</div>
							<div className="pt-2 text-[#404040] text-xs text-left pl-11 pr-2">{t('total_payments_of_the_last_30_days')}</div>
						</div>
						<div className="pt-1 pl-2 pr-2 pb-2 col-span-1" style={{minWidth: "200px", maxWidth: "220px", display: 'flex', flexDirection: "column", alignSelf: 'flex-start', width: "16%", height: "110px", justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)", border: "1px solid #D7D7D7"}}>
							<div className="flex align-center">
								<NewFeaturesIcon icon="👋" color="#E0F5ED"/> 
								<span className="pl-3 text-xl font-bold mt-1 text-[#2D3748]">{UsersCount}</span>
							</div>
							<div className="pt-2 text-[#404040] text-xs text-left pl-11 pr-2">{t('new_users_of_the_last_30_days')}</div>
						</div>
						<div className="flex items-center gap-4 col-span-1 relative">
							<div className='pt-1 pl-2 pr-2 pb-2' style={{minWidth: "200px", maxWidth: "220px", display: 'flex', flexDirection: "column", alignSelf: 'flex-start', width: "16%", height: "110px", justifyContent: 'center', backgroundColor: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)", border: "1px solid #D7D7D7"}}>
								<div className="flex align-center">
									<NewFeaturesIcon icon="⭐" color="#E0F5ED"/> 
									<span className="pl-3 text-xl font-bold mt-1 text-[#2D3748]">{ReviewsCount}</span>
								</div>
								<div className="pt-2 text-[#404040] text-xs text-left pl-11 pr-2">{t('new_reviews_of_the_last_30_days')}</div>
							</div>
							<div 
								className="cursor-pointer" 
								onClick={() => history.push('/admin/dashboard')}
								onMouseEnter={() => setShowTooltip(true)}
              					onMouseLeave={() => setShowTooltip(false)}
							>
								<ArrowForwardCircle/>
							</div>
							<div className={`absolute top-3.5 left-36 max-w-[13rem] w-max text-xs shadow right-0 p-2 bg-[#E2FEF1] text-[#094553] rounded-md z-10 transition-opacity opacity-0 pointer-events-none ${showTooltip ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
								{t('see_dashboard')}
							</div>
						</div>

					</div>
				</div>
			</>
        </div>        
    </>
	)
}

export default Updates