import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'common/Popup';
import { TrashIcon } from '@heroicons/react/outline';
import { DeleteEventContainer } from './EventsContainers';
import { Button } from 'common/Button';

export const NotRemovablePopup = ({isOpen, confirm, type}) => {
    const { t } = useTranslation();
  
    return (
      <Popup isOpen={isOpen}>
       <DeleteEventContainer>
            <TrashIcon 
                className={`w-[40px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />
            <h3>{type === 'EVENT' ? t('you_cannot_remove_this_event') : t('you_cannot_remove_this_ticket')}</h3>
            <p>
                {type === 'EVENT' ? t('not_deletable_event_explanation') : t('not_deletable_ticket_explanation')}
            </p>
            <div className='flex justify-center mt-2'>
                <Button
                    label={t('ok_close')}
                    onClick={confirm}
                />
            </div>
        </DeleteEventContainer>
      </Popup>
    );
  };