import React from 'react';
import logo from '../../img/logo.png';
import { Link, useHistory } from 'react-router-dom';
import { XInput, XTextButton } from '../../styles/StyledComponents';
import { useTranslation } from 'react-i18next';
import { api, apiAuth } from '../../logic/api';
import { toast } from 'react-toastify';
import { sayError } from 'logic/defaults';
import background from '../../img/bg-login.svg'

const Forgot = () => {
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    //
  }, []);

  const [submitting, setSubmitting] = React.useState(false);
  const [state, setState] = React.useState({
    email: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgot = async () => {
    if (state.email !== '') {
      setSubmitting(true);
      try {
        const res = await api.post('/password_reset/', {
          email: state.email,
        });
        if (res.status === 200){
          toast.success(`${t('forgot_email_sent')}`);
        }else{
          toast.error(`${t('no_email')}`);
        }
        // setTimeout(() => history.push("/login"), 2000);
      } catch (err) {
        toast.error(`${t('no_email')}`);
      } finally {
        setSubmitting(false);
      }
    } else {
        toast.error(`${t('no_email')}`);
    }
  };

  return (
    <div className='flex h-screen w-full justify-center items-center bg-gray-100 bg-right-top bg-no-repeat'  style={{backgroundImage: `url(${background})`, backgroundSize: window.innerWidth > 768 ? '90%' : 'auto'}}>
      <div className='flex flex-col w-3/4 md:w-1/2 lg:max-w-md md:bg-white h-50 p-3 flex-column justify-around items-center space-y-4 bg-white shadow-sm rounded-xl'>
        <div className={'w-3/4 md:w-1/2'}>
          <img src={logo} alt='logo' />
        </div>
        <div className={'px-2 w-full'}>
          <h3 className={'font-bold mb-2 text-principal text-lg'}>
            {t('forgot_password_text')}
          </h3>

          <div className={''}>{t('forgot_password_explanation')}</div>

          <div className={'font-medium text-sm text-capitalize py-2'}>
            {t('Email')}*
          </div>
          <XInput
            icon={
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                />
              </svg>
            }
            type='text'
            name='email'
            placeholder={t('Email')}
            value={state.email || ''}
            doChange={handleChange}
          />
        </div>

        <XTextButton
          disabled={submitting || state.email === ''}
          submitting={submitting}
          doClick={handleForgot}
        >
          {t('send_reset_link')}
        </XTextButton>

        <div>
          <Link to='/'>
            <div style={{ textAlign: 'center' }}>{t('return_home')}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
