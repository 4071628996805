import React, { useEffect, useState } from 'react';
import { Line, XAxis, YAxis, Legend, Tooltip, LineChart } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import moment from 'moment';
import GraphCard from '../../components/yumminn/GraphCard';


export const LoyaltyGraph = ({ info, data }) => {
  const { t } = useTranslation();
  const [left, setLeft] = useState("dataMin")
  const [right, setRigth] = useState("dataMax")
  const [top, setTop] = useState("dataMax")
  const [bottom, setBottom] = useState(0)
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');

  // React.useEffect(() => {
  //   if (info && info.length > 0) {
  //     const ACTUALS = info.map(({ actual }) => actual)
  //     const PREVS = info.map(({ prev }) => prev)
  //     if (Math.round(Math.max(...ACTUALS)) > Math.round(Math.max(...PREVS))) {
  //       setTop(Math.round(Math.max(...ACTUALS)))
  //     } else {
  //       setTop(Math.round(Math.max(...PREVS)))
  //     }
  //     if (Math.round(Math.min(...ACTUALS)) < Math.round(Math.min(...PREVS))) {
  //       setBottom(Math.round(Math.min(...ACTUALS)))
  //     } else {
  //       setBottom(Math.round(Math.min(...PREVS)))
  //     }
  //     document.getElementsByClassName('recharts-legend-item-text')
  //     let spanCollection = document.getElementsByClassName('recharts-legend-item-text')
  //     for (let i = 0; i < spanCollection.length; i++) {
  //       spanCollection[i].style.color = '#404040'
  //     }
  //   }
  // }, [info])

  useEffect(() => {
    let max = 0
    if (info.length > 0) {
      info.forEach((data) => {
        console.log(data);
        const values = [parseFloat(data.actual_granted), parseFloat(data.actual_used), parseFloat(data.prev_granted), parseFloat(data.prev_used)]
        const maxValue = Math.max(...values)
        if (maxValue > max) {
          max = maxValue
        }
      })
      setTop(max)
    }
  }, [info])

  return (
    <GraphCard
      title={'Loyalty'}
      indicators={[
        { label: 'loyalty_granted', value: data?.loyalty_generated_diff },
        { label: 'loyalty_used', value: data?.loyalty_used_diff },
      ]}
      totals={[
        { label: 'actual_granted', value: data?.total_generated, color: '#5FB894' },
        { label: 'prev_granted', value: data?.total_generated_prev, color: '#cfe9de' },
        { label: 'actual_used', value: data?.total_used, color: '#EA9F89' },
        { label: 'prev_used', value: data?.total_used_prev, color: '#f2cabf' },
      ]}
    >
      <LineChart data={info} margin={{ left: -10 }}>
        <XAxis scale={"auto"} minTickGap={0} style={{ fontSize: isRowBased ? "12px" : "14px" }} dataKey="labels" stroke="#404040" allowDataOverflow={true} domain={[left, right]} allowDecimals={false} tickCount={info?.length || 1} type={"category"} yAxisId="1" />
        <YAxis padding={{ top: 10 }} tickFormatter={tick => { return tick.toLocaleString() }} domain={[0, top]} tick={{ width: 250 }} style={{ fontSize: isRowBased ? "12px" : "14px" }} scale='linear' unit=" €" tickCount={info?.length || 1} stroke="#404040" allowDataOverflow={false} type="number" allowDecimals={false} />
        <Legend wrapperStyle={{ display: 'none', fontSize: "14px", width: '600px', right: 0, bottom: "-30px", boxShadow: "0px 12px 12px 15px rgb(149 157 165 / 10%)", borderRadius: '0.375rem', padding: "4px" }} />
        {period !== "days" && period !== "today" && period !== "month" && period !== "year" ?
          <Tooltip labelFormatter={value => { return `${moment(value).format('DD-MM')}` }} formatter={value => Number(value) + '€'} />
          :
          <Tooltip formatter={value => value.replace(/,00/g, '') + '€'} />}
        <Line strokeWidth={2} name={t('actual_granted')} type="monotone" dataKey="actual_granted" stroke="#5FB894" />
        <Line strokeWidth={2} name={t('actual_used')} type="monotone" dataKey="actual_used" stroke="#EA9F89" strokeDasharray="5 5" />
        <Line strokeWidth={2} name={t('prev_granted')} type="monotone" dataKey="prev_granted" stroke="#cfe9de" strokeDasharray="5 5" />
        <Line strokeWidth={2} name={t('prev_used')} type="monotone" dataKey="prev_used" stroke="#f2cabf" strokeDasharray="5 5" />
      </LineChart >
    </GraphCard>
  );
};
