import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactStars from 'react-rating-stars-component';
import { useLazyAxios } from 'use-axios-client';
import * as dayjs from 'dayjs';
import { apiAuth } from 'logic/api';
import Loading from 'common/Loading';
import ProgressBar from '@ramonak/react-progress-bar';
import useReviewFilter from 'components/yumminn/useReviewFilter';
import { Context as AuthContext } from '../../../data/authContext';


const initialRatings = {
  totalReviews: 0,
  avg: 0,
  5: 0,
  4: 0,
  3: 0,
  2: 0,
  1: 0,
};

const RatingsOverviewPage = () => {
  const { t } = useTranslation();
  const { activo } = useReviewFilter();
  const [query, setQuery] = React.useState('');
  const [ratings, setRatings] = React.useState(initialRatings);
  
  const {
    state: { selectedWaiter, selectedRestaurant},
  } = React.useContext(AuthContext);

  const [getData, { data, error, loading }] = useLazyAxios({
    axiosInstance: apiAuth,
    url: `/restaurants/${selectedRestaurant[0]?.value}/reviews/?waiters=${selectedWaiter}&${query}`,
  });

  React.useEffect(() => {
    let from_date;
    let to_date;

    switch (activo) {
      case 'month':
        //see reviews from the last 30 days
        from_date = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        to_date = dayjs().add(1, 'day',).format('YYYY-MM-DD');
        break;
      case 'year':
        //for all reviews in history
        from_date = dayjs().startOf('year').format('2022-MM-DD');
        to_date = dayjs().format('YYYY-MM-DD');
        break;
      case 'days':
        //see reviews from the last 7 days
        from_date = dayjs().subtract(8, 'day').format('YYYY-MM-DD');
        to_date = dayjs().format('YYYY-MM-DD');
        break;
      default: //for all reviews in history
        from_date = dayjs().startOf('year').format('2022-MM-DD');
        to_date = dayjs().format('YYYY-MM-DD');
        break;
    }

    setQuery(`from=${from_date}&to=${to_date}`);
  }, [activo, getData]);

  React.useEffect(() => {
    if (query !== '' && selectedRestaurant && selectedRestaurant.length > 0) {
      getData();
    }
  }, [query, getData, selectedWaiter, selectedRestaurant]);

  /*React.useEffect(() => {
    getData();
  }, [selectedWaiter, getData]);*/

  React.useEffect(() => {
    if (data) {
      const { results, count } = data;
      const groupedReviews = results
        .reduce((prev, { experience }) => {
          prev[0] += experience;
          ++prev[experience];
          return prev;
        }, [ 0, 0, 0, 0, 0, 0])
        .reduce((prev, rating, index) => {
          if (index > 0) {
            prev[index] = rating / count;
          } else {
            prev.avg = rating / count;
          }
          return prev;
        }, {});
      const reviews = {
        totalReviews: count,
        ...groupedReviews,
      };
      setRatings(reviews);
    }
  }, [data]);




  if (error) return <div>{JSON.stringify(error)}</div>;
  if (loading) return <Loading />;
  if (!data) return null;

  return (
    <div className={'flex flex-col'}>
      <div>{t('ratings_general')}</div>
      <div className='grid gap-4 mt-8'>
        <div className='w-full h-auto p-4 bg-white rounded-md'>
          <div className='grid grid-cols-2 gap-4 pb-[10px]'>
          {Number.isNaN(ratings.avg) ? 0 : ratings.avg && (
              <>
              <div className='flex'>
                <strong className='text-3xl mt-[3px] mr-[17px]'>
                  {Math.round(ratings.avg * 100) / 100}
                </strong>
                <ReactStars
                  count={5}
                  size={28}
                  value={ratings.avg}
                  color='#ddd'
                  classNames='rating-stars'
                  activeColor='#5eb894'
                  edit={false}
                />
              </div>
              <div className='flex justify-end'>
                <span className='font-light text-[16px] mt-[10px]'>
                  {ratings.totalReviews} {t('revisiones_totales')}
                </span>
              </div>
              </>
            )}
          </div>
          <hr />
          <div className='flex flex-col w-full'>
            {[5,4,3,2,1].map(index =>
              <div className='flex w-full space-x-2 mt-[20px]' key={`rating${index}`}>
                <ReactStars
                  count={5}
                  size={16}
                  value={index}
                  color='#ddd'
                  classNames='rating-stars'
                  activeColor='#5eb894'
                  edit={false}
                />
                <div className='mt-[10px] w-[61%]'>
                  <ProgressBar
                    bgColor='#5eb894'
                    isLabelVisible={false}
                    height='8px'
                    completed={ratings[index] * 100}
                  />
                </div>
                <span className='font-light text-[16px] pl-[10px] flex-1 text-right'>
                  {Number.isNaN(ratings[`${index}`]) ? 0 : Math.round(ratings[`${index}`] * ratings.totalReviews)} {t('reviews')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingsOverviewPage;