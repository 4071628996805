import React from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import * as _ from 'lodash';
import FormError from 'common/FormError';
import {apiAuth} from 'logic/api';
import {PlusIcon, SaveIcon, XIcon} from '@heroicons/react/outline';
import useDeleteModal from 'components/hooks/useDeleteModal';
import {motion} from 'framer-motion';
import {sayError} from 'logic/defaults';
import XButton from "../../../common/XButton";
import { Context } from 'data/authContext';
import Loading from 'common/Loading';
import UpdateTablesInfoModal from '../tables_sectors/UpdateTablesInfoModal';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
  name: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
  // name: '',
  // email: '',
};

const TablesSectorPage = ({}) => {
  const {t} = useTranslation();
	const {
		state: { selectedRestaurantId },
	} = React.useContext(Context);

  const [sectores, setSectores] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showUpdateInfo, setShowUpdateInfo] = React.useState(false);
  const [newTables, setNewTables] = React.useState([]);
  const [deactivatedTables, setDeactivatedTables] = React.useState([]);
  const [newActiveTables, setNewActiveTables] = React.useState([]);


  const getData = async () => {
    try {
      const res_obj = await apiAuth.get(`/restaurants/${selectedRestaurantId}`);
      const res_data = res_obj.data;
      const ordenados = _.orderBy(res_data?.sectors, ['name']);
      const filtrados = _.filter(ordenados, sector => "active" in sector && sector["active"] == true);
      filtrados.forEach(sector => {
        sector["tables"] = _.filter(sector["tables"], table => "active" in table && table["active"] == true);
      });
      setSectores(filtrados);
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };
  
  React.useEffect(() => {
    if (!selectedRestaurantId) return;
      getData();
  }, [selectedRestaurantId]);

  const handleSubmitSector = async (e, sector) => {
    try {
      if (sector.id) {
        await apiAuth.patch(`/sectors/${sector.id}/`, {...sector});
      } else {
        await apiAuth.post(`/sectors/`, {...sector});
      }
      toast.success(`${t('update_ok')}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleSubmitTable = async (e, table, sector) => {
    try {
      if (table.id) {
        await apiAuth.patch(`/tables/${table.id}/`, {...table});
      } else {
        await apiAuth.post(`/tables/`, {...table});
      }
      toast.success(`${t('update_ok')}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleSync = async (e, table, sector) => {
    setLoading(true)
    try {
      const res_sync_obj = await apiAuth.post(`/tables/sync`, {restaurant: selectedRestaurantId});
      try {
        const res_sync_data = res_sync_obj.data;
        setNewTables(res_sync_data.new_tables)
        setDeactivatedTables(res_sync_data.deactivated_tables)
        setNewActiveTables(res_sync_data.inactive_tables_now_active)
        setShowUpdateInfo(true)
      } catch {
        console.log("Error getting update info")
      }
      //toast.success(`${t('¡Sincronizado!')}`);
      getData();
    } catch (error) {
      toast.error(error.response.data ? sayError(error.response.data) : "Error al sincronizar");
    }
    setLoading(false)
  };

  const handleAddSector = (e) => {
    setSectores((prev) => [
      ...prev,
      {
        name: 'Untitled',
        restaurant: selectedRestaurantId,
        tables: [],
      },
    ]);
  };

  const handleAddTable = (e, sector) => {
    const nuevos_sectores = sectores.map((s) => {
      if (s.id !== sector.id) {
        return s;
      } else {
        return {
          ...s,
          tables: [...s.tables, {name: 'Untitled', sector: s.id}],
        };
      }
    });

    setSectores(nuevos_sectores);
  };

  const handleDeleteSector = async (sector_id) => {
    if (!sector_id) {
      getData();
      return;
    }
    try {
      await apiAuth.post(`/sectors/${sector_id}/deactivate`);
      //await apiAuth.delete(`/sectors/${sector_id}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleDeleteTable = async (table_id) => {
    if (!table_id) {
      getData();
      return;
    }

    try {
      await apiAuth.post(`/tables/${table_id}/deactivate`);
      //await apiAuth.delete(`/tables/${table_id}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleChangeTable = (e, table, sector) => {
    const nombre = e.target.name.split('.')[1];

    //
    const nuevos_sectores = sectores.map((s) => {
      if (s.id !== sector.id) {
        return s;
      } else {
        const nuevas_tables = s.tables.map((t) => {
          if (t.id !== table.id) {
            return t;
          } else {
            return {...t, [nombre]: e.target.value};
          }
        });
        return {...s, tables: nuevas_tables};
      }
    });

    setSectores(nuevos_sectores);
  };

  const tableModal = useDeleteModal(handleDeleteTable);
  const sectorModal = useDeleteModal(handleDeleteSector);

  if (!sectores) return null;

  if (sectores) {
    return (
      loading ? <Loading /> : 
      showUpdateInfo ? 
        <UpdateTablesInfoModal new_tables={newTables} deactivated_tables={deactivatedTables} new_active_tables={newActiveTables} setShowUpdateInfo={setShowUpdateInfo}/> :
      <div className={'flex flex-wrap'}>
        {/* * */}
        {/* formularios de sectores */}
        {/* * */}

        <div className='self-end my-5 w-full'>
          <XButton
            className='btn btn-primary rounded-full px-12'
            type='button'
            doClick={handleSync}
          >
            {t('Sincronizar mesas')}
          </XButton>
        </div>

        {sectores.map((sector, index) => (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
            key={index}
            className='mb-5 mr-3 bg-white p-3 rounded-lg min-w-[420px]'
          >
            <Formik
              enableReinitialize={true}
              initialValues={sector || initialValues}
            >
              {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                <form onSubmit={handleSubmit} autoComplete='off'>
                  {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(updated, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}

                  <div
                    key={sector.id}
                    className='flex flex-col space-y-3 divide-y'
                  >
                    <div className='flex items-center w-full space-x-2'>
                      <div className='w-full flex flex-col mb-1'>
                        <input
                          type='text'
                          placeholder={t('Sector')}
                          className='yumminn-input'
                          value={values?.name || ''}
                          name='name'
                          onChange={handleChange}
                        />
                        <FormError message={errors.values?.name}/>
                      </div>
                      <button
                        type='button'
                        onClick={(e) => handleSubmitSector(e, values)}
                      >
                        <SaveIcon className='h-4 w-4 text-principal hover:text-medio'/>
                      </button>
                      <button
                        type='button'
                        onClick={() => sectorModal.openModal(sector.id)}
                      >
                        <XIcon className='h-4 w-4 text-principal hover:text-medio'/>
                      </button>
                    </div>
                    <div>
                      {sector.tables.map((table, index) => (
                        <motion.div
                          initial={{opacity: 0}}
                          animate={{opacity: 1}}
                          transition={{duration: 0.5}}
                          key={index}
                          className='flex items-center w-full space-x-2 pt-1 text-sm text-[12px]'
                        >
                          <input
                            type='text'
                            placeholder={t('Sector')}
                            className='yumminn-input'
                            value={table.name || ''}
                            name='table.name'
                            onChange={(e) => {
                              handleChangeTable(e, table, sector);
                            }}
                          />
                          <input
                            type='text'
                            placeholder={t('Number')}
                            className='yumminn-input'
                            value={table.number || ''}
                            name='table.number'
                            onChange={(e) => {
                              handleChangeTable(e, table, sector);
                            }}
                            disabled={true}
                          />
                          <button
                            type='button'
                            onClick={(e) => handleSubmitTable(e, table, sector)}
                          >
                            <SaveIcon className='h-4 w-4 text-principal hover:text-medio'/>
                          </button>
                          <button
                            type='button'
                            onClick={() => tableModal.openModal(table.id)}
                          >
                            <XIcon className='h-4 w-4 text-principal hover:text-medio'/>
                          </button>
                        </motion.div>
                      ))}
                      <div
                        className='flex items-center space-x-4 input input-bordered bg-transparent border-dashed my-3 rounded-md pt-0 py-0 h-[44px] text-[12px] cursor-pointer transition transform hover:bg-primary hover:bg-opacity-40'
                        onClick={(e) => handleAddTable(e, sector)}
                      >
                        <PlusIcon className='h-4 w-4 text-principal hover:text-medio'/>
                        {t('Addnewtable')}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </motion.div>
        ))}

        {/* * */}
        {/* nuevo sector */}
        {/* * */}
        <div
          className='flex items-center space-x-4 input input-bordered bg-transparent border-dashed my-0 rounded-md pt-0 py-0 h-[44px] text-[12px] cursor-pointer transition transform hover:bg-primary hover:bg-opacity-40'
          onClick={handleAddSector}
        >
          <PlusIcon className='h-4 w-4 text-principal hover:text-medio'/>
          {t('Addnewsector')}
        </div>

        <sectorModal.Modal mensaje={t('Deletesector')}/>
        <tableModal.Modal mensaje={t('Deletetable')}/>
      </div>
    );
  }
};

export default TablesSectorPage;
