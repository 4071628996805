import React from 'react'
import CrmTable from 'common/CrmTable'

const LoyaltyMails = () => {
  return (
    <div className='h-full'>
      <CrmTable/>
    </div>
  )
}

export default LoyaltyMails