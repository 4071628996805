import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { examples } from './examples';
import { ComponentsContainer } from './ExampleContainers';

const exampleList = Object.keys(examples);

const getDecodedHash = (location) => decodeURI(location.hash.replace('#', ''));

export const ComponentsPage = () => {
  const history = useHistory();
  const location = useLocation();

  const changeComponent = example => history.push(`/components#${example}`);

  return (
    <ComponentsContainer>
      <div>
        {examples[getDecodedHash(location) || exampleList[0]]}
      </div>
      <aside>
        <h3>Components</h3>
        <ul>
          {exampleList.map(example =>
            <li key={example} onClick={() => changeComponent(example)}>
              {example}
            </li>
          )}
        </ul>
      </aside>
    </ComponentsContainer>
  );
};