import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AccessTypePopup } from './AccessManagementContainers';
import { Icon, IconType } from 'common/Icon';
import { Context } from 'data/authContext';

export const AccessTypeModal = () => {
  const { state: { accessType }} = useContext(Context);
  
  const { t } = useTranslation();

  return (
    <AccessTypePopup isOpen={accessType !== null && accessType !== 2}>
      <div>
        <Icon type={IconType[accessType === 0 ? 'EYE' : 'LOCK']}/>
        <Icon type={IconType.VERIFIED}/>
      </div>
      <h3>
        {t(`${accessType === 0 ? 'open_access' : 'login_required'}_active`)}
      </h3>
      <p>
        <Trans
          i18nKey={`${accessType === 0 ? 'open_access' : 'login_required'}_message`}
          defaults={accessType === 0
            ? 'Si deseas <b>limitar el acceso</b> a la webapp, utiliza la opción de <b>Log in obligatorio o de Acceso restringido</b>.'
            : 'Para <b>restringir el acceso a ciertos comensales en particular</b>, utiliza la opción de <b>Acceso restringido</b>. Si deseas <b>liberar el acceso</b>, utiliza la opción de <b>Acceso abierto</b>.'
          }
          components={{ b: <b /> }}
        />
      </p>
    </AccessTypePopup>
  );
};
