import React from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import MenuContainer from 'layouts/MenuContainer';
import DateRangeSelect from 'components/yumminn/DateRangeSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';

const LoyaltyContainer = ({ children }) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();
  const location = history.location.pathname;

  return (
    <div id="loyaltyContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
        <MenuHeader title={'Loyalty'} route_group='loyalty' />
          
        <div className='h-full flex flex-col'>
          <div 
            id="topBar" 
            className={`flex flex-col bg-white lg:flex-row items-center py-4 px-[32px] gap-5 z-0 ${location === '/loyalty/programs' ? 'justify-start' : 'justify-between'} ${!isRowBased ? 'sticky top-0' : 'pt-16'}`}
          >
            <RestaurantSelect/>
            <DateRangeSelect hourFormat={false} defaultRange='last_30_days'/>
          </div>
          {/* <LoyaltyTabs /> */}
          <div className='px-8 mt-3 max-w-full flex-grow mt-14'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyContainer;
