import React from 'react'
import { BUTTON_SIZES, BUTTON_VARIANTS, Button } from './Button'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './Tooltip';

export const RADIO_VARIANT = {
  PILL: 'pill',
  ROUNDED_SQUARE: 'rounded_square',
};

export const RadioButtonGroup = ({
  value,
  multiValue,
  options,
  disabled = false,
  size = BUTTON_SIZES.SM,
  variant = RADIO_VARIANT.PILL,
  onChange
}) => {
  return (
    <Container>
      {options.map((option) =>
        <RadioButton
          key={option.label}
          option={option}
          selected={value === option.value || multiValue?.includes(option.value)}
          disabled={disabled}
          size={size}
          variant={variant}
          onChange={onChange}
        />
      )}
    </Container>
  );
};

const RadioButton = ({ option, selected, disabled, size, variant, onChange }) => {
  if (variant === RADIO_VARIANT.ROUNDED_SQUARE) return (
    <SquareButton
      {...option}
      selected={selected}
      disabled={disabled}
      size={size}
      onClick={() => onChange(option.value)}
    />
  );

  return (
    <Button
      {...option}
      disabled={disabled}
      variant={BUTTON_VARIANTS[selected ? 'PRIMARY' : 'SECONDARY']}
      size={size}
      onClick={() => onChange(option.value)}
    />
  );
};

const SquareButton = ({ selected, label, leftIcon, rightIcon, size, ...buttonProps }) => {
  const { t } = useTranslation();

  return (
    <StyledSquareButton {...buttonProps} className={`${size} ${selected ? 'selected' : ''}`}>
      {leftIcon}
      {typeof label === 'string' ? <span>{t(label)}</span> : label}
      {rightIcon}
    </StyledSquareButton>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledSquareButton = styled.button`
  --color: #212121;
  --bg_color: transparent;
  --border_color: #D9D9D9;
  --hover_border_color: #4ED9B7;
  --font_weight: 400;

  &.selected {
    --color: #094553;
    --bg_color: #D4F9EE;
    --border_color: #094553;
    --hover_border_color: #094553;
    --font_weight: 600;

    letter-spacing: -0.01em;
  }

  &.large {
    --font_size: 1.125rem;
    --line_height: 1.22em;
    --padding: 0 2.875rem;
    --height: 3rem;
    --radius: 12px;
  }
  
  &.medium {
    --font_size: 1rem;
    --line_height: 1.25em;
    --padding: 0 2rem;
    --height: 2.75rem;
    --radius: 10px;
  }
  
  &.small {
    --font_size: 0.875rem;
    --line_height: 1.125em;
    --padding: 0 1rem;
    --height: 2.125rem;
    --radius: 8px;
  }

  &.tiny {
    --font_size: 8px;
    --line_height: 1rem;
    --padding: 0 1rem;
    --height: 1.5rem;
    --radius: 6px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-family: Poppins, sans-serif;
  font-size: var(--font_size);
  font-weight: var(--font_weight);
  border: 1px solid var(--border_color);
  border-radius: var(--radius);
  color: var(--color);
  background-color: var(--bg_color);
  height: var(--height);
  width: fit-content;
  padding: var(--padding);
  transition: 0.15s ease-in-out;
  transition-property: color, background-color, border-color, translate, box-shadow;

  & > span {
    line-height: 1;
    
    &::first-letter {
      text-transform: capitalize;
    }
  }

  & > svg {
    height: 1.25em;
    width: 1.25em;
    margin: calc((-1.25em + var(--line_height)) / 2) 0;
    flex-shrink: 0;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--color);
    outline-offset: 2px;
  }

  &:hover:not(:disabled), &:focus-visible:not(:disabled) {
    background-color: #F3FFF9;
    border-color: var(--hover_border_color);
    translate: 0 -2px;
    box-shadow: 0 2px 0px #0001;
  }

  &:active:not(:disabled) {
    translate: 0 2px;
    box-shadow: 0 0 0 #0001, inset 0 2px 0px #0001;
  }

  &:disabled {
    opacity: 0.6;
  }
`;