import { abbreviateBigNumbers } from 'logic/functions';
import { DataCard } from 'pages/home/components/overview/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, LabelList, Rectangle, ResponsiveContainer } from 'recharts';

const data = [
  {label: 'cashback_redeemed', value: 840},
  {label: 'cashback_granted', value: 1680},
];

const StripesPattern = ({color}) => {
  return (
    <pattern id={`stripes-${color}`} patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(0.6) rotate(20)'>
      <rect x='0' y='0' width='100%' height='100%' fill={`#${color}60`}/>
      <path d='M0 10h20z' strokeWidth='3' stroke={`#${color}`} fill='none'/>
    </pattern>
  );
};

const Label = ({ x, y, width, value, index }) => {
  const fontSize = `${value}`.length > 4 ? 12 : 14;
  return (
    <g>
      <rect
        x={x - (80 - width) / 2}
        y={y - 40}
        height={28}
        width={80}
        rx={8}
        fill='#FFF'
        stroke='#F1F1F1'
        strokeWidth={1.5}
      />
      <polyline
        points={`${x + width / 2 - 8},${y - 13} ${x + width / 2},${y - 4} ${x + width / 2 + 8},${y - 13}`}
        fill='#FFF'
        stroke='#F1F1F1'
        strokeWidth={1.5}
        stopLinejoin="round"
      />
      <svg x={x + 5 - (80 - width) / 2} y={y - 35} width="18" height="18" viewBox="0 0 26 26" filter={index === 0 && "url(#grayscale)"}>
        <path d="M24.3105 13.1166C24.4433 6.82453 19.4415 1.61637 13.1389 1.48386L12.6582 24.2695C18.9609 24.402 24.1778 19.4087 24.3105 13.1166Z" fill="#FFEAAD"/>
        <path d="M1.48654 12.6368C1.61927 6.34468 6.8362 1.35135 13.1389 1.48386L12.6582 24.2695C6.35554 24.137 1.35381 18.9289 1.48654 12.6368Z" fill="#FFFAEB"/>
        <path d="M21.456 13.0572C21.5556 8.33813 17.8043 4.43201 13.0773 4.33263L12.7168 21.4219C17.4438 21.5213 21.3565 17.7763 21.456 13.0572Z" fill="#F7B500" fill-opacity="0.2"/>
        <path d="M4.33889 12.6967C4.23934 17.4158 7.99063 21.3219 12.7176 21.4213L13.0781 4.33203C8.35113 4.23265 4.43844 7.97764 4.33889 12.6967Z" fill="#FDDF8A" fill-opacity="0.3"/>
        <ellipse cx="11.7562" cy="11.754" rx="11.7562" ry="11.754" transform="matrix(0.999777 0.0211419 -0.0209678 0.99978 1.49316 1)" fill="transparent" stroke="#F7B500" strokeWidth="1.61538"/>
        <path d="M8.92676 11.4961L13.6554 11.5955" fill="transparent" stroke="#F7B500" strokeWidth="1.93871" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.87012 14.1328L13.5987 14.2322" fill="transparent" stroke="#F7B500" strokeWidth="1.93871" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.4479 9.95428C16.2765 9.57926 15.5923 8.35975 13.37 8.31303C11.1478 8.26631 9.78121 9.81412 9.71473 12.9658C9.64824 16.1175 10.9483 17.7214 13.1706 17.7681C15.3928 17.8148 16.0777 16.6757 16.315 16.2577" fill="transparent" stroke="#F7B500" strokeWidth="1.93871" strokeLinecap="round"/>
      </svg>
      <text x={x + 26 - (80 - width) / 2} y={y - 21} fontSize={fontSize} fontWeight={600}>
        {abbreviateBigNumbers(value) + '€'}
      </text>
      <filter id="grayscale">
        <feColorMatrix type="saturate" values="0.10"/>
      </filter>
    </g>
  )
}

export const CashbackOverview = () => {
  const { t } = useTranslation();

  data[0].fill = 'url(#stripes-F7A491)'
  data[1].fill = 'url(#stripes-FFE59D)'

  return (
    <div className='yumminn-card flex gap-3 h-72'>
      <div className='flex flex-col justify-between gap-3'>
        <h3 className='font-semibold text-xl text-[$404040$] w-min border-b-2 border-gray-200 pb-1'>
          {t('cashback')}
        </h3>
        <div className='flex flex-col gap-3'>
          <DataCard data={abbreviateBigNumbers(data[1].value)} label={data[1].label} bgColor='#FFF3D4'/>
          <DataCard data={abbreviateBigNumbers(data[0].value)} label={data[0].label} bgColor='#FFE4DE'/>
        </div>
      </div>
      <div>
        <ResponsiveContainer width={128} height='100%'>
          <BarChart data={data} barGap={14} margin={{right: 18, left: 18, top: 26}}>
            <Bar
              dataKey='value'
              barSize={35}
              isAnimationActive={false}
              shape={<Rectangle radius={8}/>}
            >
              <LabelList dataKey='value' content={<Label/>}/>
            </Bar>
            <defs>
              <StripesPattern color={'F7A491'}/>
              <StripesPattern color={'FFE59D'}/>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
