import React from "react";
import { Context as AuthContext } from '../../data/authContext';

const useReviewFilter = () => {
  const {
    state: { selectedReviewFilter }
  } = React.useContext(AuthContext);

  const [activo, setActivo] = React.useState(null);

  React.useEffect(() => {

    const seleccionado =
      selectedReviewFilter ||
      sessionStorage.getItem('yumminn-selectedReviewFilter');
    if (seleccionado) {
      setActivo(seleccionado);
    }
    return () => {
      setActivo({}); // This worked for me
    };
  }, [selectedReviewFilter]);

  return {
      activo
  };
};

export default useReviewFilter;
