import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer } from './InputContainers';
import DateRangePicker from "rsuite/DateRangePicker";
import { Icon, IconType } from 'common/Icon';
import { esES, enGB, frFR } from 'rsuite/esm/locales';

const locales = {
  es: esES.Calendar,
  en: enGB.Calendar,
  fr: frFR.Calendar,
};

export const DateInput = ({
  label,
  error,
  className = '',
  disablePastDates = false,
  ...datePickerProps
}) => {
  const { t } = useTranslation();

  const id = datePickerProps.id || `date_input_${Math.floor(Math.random() * 10000).toString().padStart(5, '0')}`;
  const lang = navigator.language || navigator.userLanguage || 'es';

  const shouldDisableDate = disablePastDates ? (date, selectDate, selectedDone) => {
    const dateToCompare = !selectedDone && selectDate.length === 1 ? selectDate[0] : new Date();
    return date < dateToCompare && date.setHours(0,0,0,0) !== dateToCompare.setHours(0,0,0,0);
  } : undefined;

  return (
    <InputContainer className={className}>
      <div>
        <label htmlFor={id}>
          {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
        </label>
        <DateRangePicker
          {...datePickerProps}
          id={id}
          appearance="subtle"
          className='input_wrapper date_input'
          placeholder={t(datePickerProps.placeholder || '')}
          showHeader={datePickerProps.showHeader || false}
          locale={locales[lang.split('-')[0].toLowerCase()] || locales.es}
          caretAs={datePickerProps.caretAs || (() => <Icon type={IconType.CALENDAR}/>)}
          ranges={datePickerProps.ranges || []}
          placement={datePickerProps.placement || 'autoVerticalStart'}
          shouldDisableDate={datePickerProps.shouldDisableDate || shouldDisableDate}
          isoWeek
          preventOverflow
        />
      </div>
      {error &&
        <i>{t(error)}</i>
      }
    </InputContainer>
  );
};
