import React, { useContext, useEffect, useState } from 'react'
import { Context as AuthContext } from '../../../../data/authContext';
import useSWR from 'swr';
import { orderAndPay } from 'logic/api';
import { compareFn } from '../OrderAndPay/Logic/functions';
import { getTranslationLanguage } from '../utils';

const fetcher = endpoint => orderAndPay.get(endpoint).then(res => res.data);

export const useModifiers = () => {
  const { state: { selectedRestaurantId } } = useContext(AuthContext);

  const lang = getTranslationLanguage();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const { data: catData, mutate: mutateCat } = useSWR(
    `/modifier_categories_list?restaurant=${selectedRestaurantId}&lang=${lang}`,
    fetcher,
    {revalidateOnFocus: false},
  );

  const { data: modData, mutate: mutateMod } = useSWR(
    selectedCategory ? `/modifier_list?category=${selectedCategory.id}&lang=${lang}` : null,
    fetcher,
    {revalidateOnFocus: false},
  );

  useEffect(() => {
    setSelectedCategory(prev => {
      if (!catData?.modifier_categories?.length) return null;

      if (!prev) return catData.modifier_categories[0];

      return catData.modifier_categories.find(cat => cat.id === prev.id) || catData.modifier_categories[0]
    })
  }, [catData]);

  return {
    modifierCategories: catData?.modifier_categories || [],
    modifiers: modData?.modifiers?.sort(compareFn) || [],
    tpv: catData?.tpv || '',
    selectedCategory,
    selectedRestaurantId,
    setSelectedCategory,
    mutateCat,
    mutateMod,
  };
};
