import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ToggleSwitch } from './ToggleSwitch';

export const ChipWithToggle = ({
  name,
  label,
  checked,
  loading = false,
  disabled = false,
  onOffLabel = ['enabled', 'disabled'],
  className = '',
  onChange,
}) => {
  const { t } = useTranslation();

  const statusLabel = checked ? onOffLabel[0] : onOffLabel[1];

  return (
    <Chip className={`${loading ? 'loading' : ''} ${disabled ? 'disabled' : ''} ${className}`}>
      {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
      <ToggleSwitch
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled || loading}
      />
        {typeof statusLabel === 'string' ? <b>{t(statusLabel)}</b> : statusLabel}
    </Chip>
  );
};

const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  color: #094553;
  background-color: #FFEAE5;
  border: 1px solid #C28A8A;
  border-radius: 99px;
  padding: 8px 16px;

  &:has(:checked) {
    background-color: #F1FFFA;
    border-color: #094553;
  }

  h6 {
    line-height: 1;
    margin: 0;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  b {
    display: inline-block;
    font-weight: 600;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  @keyframes loading {
    to { opacity: 0.5 }
  }

  &.loading {
    animation: loading 1s linear alternate infinite;
    cursor: wait;
    
    * {
      pointer-events: none;
    }
  }
`;