export const PARENTS = {
	SUPER_GROUP: '',
	CATEGORY_GROUP: 'SUPER_GROUP',
	CATEGORY: 'CATEGORY_GROUP',
	PRODUCT: 'CATEGORY',
};

export const CHILDREN = {
	SUPER_GROUP: 'CATEGORY_GROUP',
	CATEGORY_GROUP: 'CATEGORY',
	CATEGORY: 'PRODUCT',
	PRODUCT: '',
};

export const CHILDREN_FIELDS = {
	SUPER_GROUP: 'category_groups',
	CATEGORY_GROUP: 'product_categories',
	CATEGORY: 'products',
};

export const MAX_LEVELS = {
	SUPER_GROUP: 10,
	CATEGORY_GROUP: 2,
	CATEGORY: 1,
	PRODUCT: 0,
};

export const FIELDS = {
	SUPER_GROUP: 'supergroups',
	CATEGORY_GROUP: 'category_groups',
	CATEGORY: 'product_categories',
	PRODUCT: 'product',
};

export const PARENT_FIELDS = {
	SUPER_GROUP: '',
	CATEGORY_GROUP: 'category_supergroup',
	CATEGORY: 'category_group',
	PRODUCT: 'category',
};

export const CONTEXT_KEYS = {
	SUPER_GROUP: {
		SELECTED: 'selectedSuperGroup',
		SET_SELECTED: 'setSelectedSuperGroup',
	},
	CATEGORY_GROUP: {
		SELECTED: 'selectedGroup',
		SET_SELECTED: 'setSelectedGroup',
	},
	CATEGORY: {
		SELECTED: 'selectedCategory',
		SET_SELECTED: 'setSelectedCategory',
	}
};

export const TPVS = {
  AGORA: 'Agora',
  BDP: 'BDP',
  CENTRAL_CASH: 'Central Cash',
  DST: 'Dst',
  DELIVERECT: 'Deliverect',
  GLOP: 'Glop',
  HIOPOS: 'Hiopos',
  HOSTEL_TACTIL: 'Hostel Tactil',
  ICG: 'Icg',
  LASTAPP: 'Last App',
  ORACLE: 'Oracle',
  REVO: 'Revo',
  SIGHORE: 'Sighore',
  NEOGRUP: 'Neogrup',
  SINQRO: 'Sinqro',
  SOFYMAN: 'Sofyman',
  TECHNIWEB: 'Techni-Web',
  WETAKO: 'Wetako',
  YUMMINN: 'Yumminn',
  ZONESOFT: 'ZoneSoft',
};