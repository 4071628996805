import { ToggleSwitch } from 'common/ToggleSwitch';
import React from 'react';
import { baseAuth } from 'logic/api';
import { Icon, IconType } from 'common/Icon';
import SpinnerButton from 'common/SpinnerButton';
import { Tooltip } from 'common/Tooltip';
import { useTranslation } from 'react-i18next';
import { stringifySchedule } from 'logic/functions';
import { DISCOUNT_TYPOLOGIES, TYPOLOGY_KEYS } from './constants';
import { notify } from './notify';
import { formatDate, getDiscountStatus, getDiscountTitle } from './discountFunctions';

export const DiscountRow = ({
  discount,
  productNames,
  loading,
  activeGeneralDiscount,
  activeProductDiscount,
  setDiscountToEdit,
  updateDiscount,
  setDiscountToDelete,
}) => {
  const { t } = useTranslation();

  const status = getDiscountStatus(discount);

  const validPeriod = (discount.valid_since && discount.valid_until)
  ? `${formatDate(discount.valid_since)}  - ${formatDate(discount.valid_until)}`
  : null
  
  const hasLimits = !!(validPeriod || discount.max_total_uses || discount.max_cents_redeemable);

  const isSwitchDisabled = (
    (discount.typology === 'product_discount' && activeGeneralDiscount && !discount.active) ||
    (discount.typology === 'general_discount' && activeProductDiscount && !discount.active)
  );

  const redeemedEuros = Math.round((discount.redeemed_cents || 0) / 100);

  const toggleActive = async (discount) => {
    if (isSwitchDisabled || loading) return;

    discount.active = !discount.active;

    const endpoint = `/discount/toggle_active/${discount.typology}/${discount.id}/`;
    await baseAuth
      .post(endpoint)
      .then((response) => {
        if (response.status === 200) {
          updateDiscount(discount);
          notify({
            title: discount.active ? 'activated_discount' : 'disactivated_discount',
            message: discount.active ? 'visible_at_webapp' : 'not_visible_at_webapp',
          });
        }
      })
      .catch((e) => console.log(`🚀 ~ file: DiscountsTable.jsx:toggleActive ~ await baseAuth.put('${endpoint}') ~ e:`, e))
  };

  return (
    <tbody>
      <tr className={`${status.includes('limit') ? 'limit_reached' : status}_row`}>
        <td className='col_name'>
          <div>
            {discount.typology === DISCOUNT_TYPOLOGIES.COUPON &&
              <i>"{t('coupon')} -{parseFloat(discount.value)}{(discount.discount_type === 'percentage' ? '%' : '€')}"</i>
            }
            <span className={`badge ${discount.typology}`}>
              {getDiscountTitle(discount, t)}
            </span>
          </div>
        </td>
        <td className='col_typology'>
          {t(TYPOLOGY_KEYS[discount.typology])}
        </td>
        <td className='col_value'>
          <b>
            {parseFloat(discount.value) + (discount.discount_type === 'percentage' ? '%' : '€')}
          </b>
        </td>
        <td className='col_applied'>
          {discount.typology !== 'product_discount' &&
            t('whole_catalog_discount')
          }
          {discount.typology === 'product_discount' && !productNames[discount.products__id] &&
            <SpinnerButton />
          }
          {discount.typology === 'product_discount' && productNames[discount.products__id] &&
            productNames[discount.products__id]
          }
        </td>
        <td className='col_visibility'>
          {discount.schedules?.map(schedule =>
            <span key={discount.typology + 'schedule' + schedule.id}>
              {stringifySchedule(schedule, t, 'LONG')}
            </span>
          )}
          {!discount.schedules?.length &&
            <span>
              {t('all_day')}
            </span>
          }
        </td>
        <td className='col_limits'>
          {hasLimits &&
          <div>
            {validPeriod &&
              <span className='badge period'>
                {validPeriod}
              </span>
            }
            <div>
              {!!discount.max_total_uses &&
                <span className='badge maxUses'>
                  {`${discount.max_total_uses} ${t('use', {count: discount.max_total_uses})}`}
                </span>
              }
              {!!discount.max_cents_redeemable &&
                <span className='badge maxCents'>
                  {Math.round(discount.max_cents_redeemable / 100) + '€'}
                </span>
              }
            </div>
          </div>
          }
          {!hasLimits &&
            <span className='badge noLimit'>
              {t('no_limit')}
            </span>
          }
        </td>
        <td>
          <div>
            <ToggleSwitch
              name="discount_switch"
              checked={discount.active}
              disabled={isSwitchDisabled}
              onChange={() => toggleActive(discount)}
            />
            {isSwitchDisabled &&
              <Tooltip
                bgColor={'#FFFFFF'}
                position={'left'}
              >
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-row items-center gap-2'>
                    <Icon type={IconType.WARNING} size={30}/>
                    <b className='leading-none'>
                      {t('not_possible_to_change_status')}
                    </b>
                  </div>
                  <p className='whitespace-normal'>
                    {t(`should_desactivate_${discount.typology}`)}
                  </p>
                </div>
              </Tooltip>
            }
          </div>
        </td>
        <td>
          <Icon
            type={IconType.TRASH}
            size={20}
            disabled={loading}
            onClick={() => setDiscountToDelete(discount)}
            />
        </td>
        <td>
          <Icon
            type={IconType.PENCIL}
            size={20}
            disabled={loading}
            onClick={() => setDiscountToEdit(discount)}
            />
        </td>
      </tr>
      <tr>
        <td colSpan='9'>
          <div>
            <Icon type={IconType.CHART} size={20}/>
            <span>
              <b>{discount.uses || 0}</b>
              {' ' + t('discount_uses', {count: discount.uses})}
            </span>
            <hr/>
            <span>
              <b>{redeemedEuros + '€'}</b>
              {' ' + t('discount_cents_redeemed', {count: redeemedEuros})}
            </span>
            <span>
              {t('Status') + ': '}
              <span className={`badge ${status.includes('limit') ? 'limit' : status}`}>
                {t(status)}
              </span>
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
