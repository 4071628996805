import styled from "styled-components";

export const BaseCard = styled.div`
  font-size: 10px;
  color: ${({selected, theme}) => selected ? '#404040' : '#404040'};
  background-color: ${({selected, theme}) => selected ? '#F7FFFB' : 'theme.card_bg'};
  border: ${({selected, theme}) => `${theme.card_border} ${selected ? '#4FAEB5' : '#DDDDDD'}`};
  border-radius: 5px;
  box-shadow:  0px 1px 2px #00000010,
  0px 0px 4px #00000010;
  box-sizing: border-box;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
`;

export const TotalCredits = styled.div`
    background: #F2FFF2;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid #B4DEB4;
    box-shadow: 3px 4px 0px 0px #DFFFDF;
    border-radius: 38px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-weight: 700;
    font-size: ${({theme}) => theme.font_lg};
    line-height: 32px;
    padding: 2px 4px;

    span {
        margin: 0;
    }
`;

export const WalletLinkCard = styled(BaseCard)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 2px;
  transition: translate 0.15s ease-in-out;

  &:active {
    translate: 1px 2px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 9.25rem;
    object-fit: cover;
  }

`;

export const ExtraCredits = styled.div`
    font-size: ${({theme}) => theme.font_xs};
    display: flex; 
    align-items: center;
    margin: 4px;
    gap: 3px;

    i {
        white-space: nowrap; 
        font-size: 6px;
    }

    b {
        font-weight: 600;
        font-size: 6px;
    }

`;

export const BadgeBonus = styled.div`
    font-size: 6px;
    display: flex; 
    align-items: center;
    position: absolute; 
    top: 4px;
    right: 0;


    i {
        font-weight: 600;
        white-space: nowrap; 
    }

    span {
        font-weight: 600;
        background-color: #E1FFF2;
        border-radius: 18px;
        padding: 1.97px 5.91px 1.97px 5.91px;
        color: #404040;
    }
`;


export const CardDetailsWrapper = styled.div`
    font-size: ${({theme}) => theme.font_xs};
    display: flex; 
    align-items: center;
    position: relative;
`;

export const CardDetails = styled.div`
  padding: 4px;

  h4 {
    text-wrap: balance;
    line-height: 1.3;
    font-weight: 600;
    margin: 0;
    font-size: 10px;
    font-weight: 700;
  }

  p {
    font-size: 6px;
    margin: 0;
  }

  span {
        font-weight: 600;
        background-color: #E1FFF2;
        border-radius: 18px;
        padding: 1.97px 5.91px 1.97px 5.91px;
        color: #404040;
    }
`;