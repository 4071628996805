import { ChevronRightIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { DraggableTab } from './DraggableTab';
import { DroppableContainer } from './DraggableContainers';
import { TabListHeader } from './TabListHeader';
import { CatalogDNDContext } from 'data/catalogDnDContext';
import { CONTEXT_KEYS, MAX_LEVELS, PARENTS } from './constants';
import { useDraggableClone } from './useDraggableClone';

export const DroppableTabs = ({
	listId,
	draggableList,
	numberOfLevels,
	disabled,
	createNew,
	openEditModal,
}) => {
	const listName = listId.split('-')[0];
	const droppableId = 'droppable_scroll_otuer_' + listName;
	
	const { SELECTED, SET_SELECTED } = CONTEXT_KEYS[listName];
	
	const {
		state: {
			[SELECTED]: selected,
			draggingItem,
		},
		[SET_SELECTED]: setSelected,
	} = useContext(CatalogDNDContext);
	
	const disableHover = !!draggingItem && MAX_LEVELS[listName] < MAX_LEVELS[draggingItem?.listName];

	const [isOverflowing, setIsOverflowing] = useState(false)
	
	const DraggableClone = useDraggableClone(listName, listId, draggableList);

	const getDraggableIndex = (index) => {
		if (!draggingItem) return index;

		const isSameList = draggingItem.listName === listName;
		const isOriginList = draggingItem?.listId === listId;

		return (isSameList && !isOriginList && draggingItem?.index <= index) ? index + 1 : index;
	};

	useEffect(() => {
		const scrollOuter = document.getElementById(droppableId);
		setIsOverflowing(scrollOuter?.clientWidth < scrollOuter?.scrollWidth);
	});
	
	return (
		<>
			<TabListHeader listName={listName} numberOfLevels={numberOfLevels} listLength={draggableList.length} createNew={createNew}/>
			<Droppable
				mode="virtual"
				droppableId={listName}
				direction="horizontal"
				isDropDisabled={disabled}
				renderClone={DraggableClone}
			>
				{(providedDrop, snapshotDrop) => {
					return (
						<DroppableContainer id={droppableId} disabled={disableHover} ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
							<div className={`text-sm p-0 font-semibold gap-2 flex w-max`}>
								{draggingItem?.listName === listName && snapshotDrop.isUsingPlaceholder &&
									<div style={{width: `${draggingItem?.width || 0}px`, order: draggingItem?.index || 0}}/>
								}
								{draggableList.map((item, index) =>
									<DraggableTab
										key={listName + '-' + item.id}
										listId={listName}
										lockTabs={PARENTS[snapshotDrop?.draggingOverWith?.split('-')[0]] === listName || disabled}
										item={item}
										index={getDraggableIndex(index)}
										disabled={disabled}
										disableHover={disableHover}
										openEditModal={openEditModal}
										setSelected={setSelected}
										selected={draggingItem?.listName !== listName && item.id === selected?.id}
									/>
								)}
							</div>
							{isOverflowing &&
								<span>
									<ChevronRightIcon height={20}/>
								</span>
							}
						</DroppableContainer>
					)}
				}
			</Droppable>
		</>
  );
};
