import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { XIcon, FolderDownloadIcon } from '@heroicons/react/outline';
import { baseAuth } from "logic/api";
import { usePagination } from "react-table";
import IndexedDetailRow from "./IndexedDetailRow";
import { centsToAmountString } from "logic/functions";

const rowStyle = {
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1.5fr 1fr 1fr 1fr',
	width: '100%',
  };


const BillingDetailModal = ({ t, billing, billingItems, setBillingToSee, downloadPdf }) => {
	const start_date = new Date(billing.period_start).toLocaleString('es-ES',{day: 'numeric', month:'short', year:'numeric'}).replaceAll("de", "")
  const end_date = new Date(billing.period_end).toLocaleString('es-ES',{day: 'numeric', month:'short', year:'numeric'}).replaceAll("de", "")
	let indent = 0;

  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "60%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: 'center'}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>Informe</div>
          <div style={{ fontSize: "14px", fontWeight: 500, color: '#767676'}}>{start_date} - {end_date}</div>
          <div onClick={() => downloadPdf(billing, 1)} style={{display: "flex", flexDirection: "row", fontWeight: 600, gap: "5px", alignItems: "center", backgroundColor: "#5FB894", padding: "5px 10px", color: "#FFFFFF", cursor: 'pointer', borderRadius: "9999px"}}>
            <FolderDownloadIcon className={`w-[20px]`}/>
            Descargar pdf
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-end', display: 'flex'}}>
            <button style={{margin: "0 15px"}} onClick={() => {setBillingToSee(null)}}><XIcon className={`w-[20px] text-gray-700 cursor-pointer`}/></button>
          </div>
        </div>
        <div style={{marginTop: "10px", width: "100%", height: "1px", backgroundColor: "#E6E6E6"}}></div>
        <div style={{width: '100%'}}>
        <div style={{display: 'flex', marginTop: "30px", borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: 600}}>
            <div style={{display: 'flex', width: "25%", height: "55px", borderWidth: "1px 1px 0px 1px", justifyContent: 'center', alignItems: 'center'}}>
              Concepto
            </div>
            <div style={{display: 'flex', width: "25%", height: "55px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              Cantidad
            </div>
            <div style={{display: 'flex', width: "25%", height: "55px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              Tarifa
            </div>
            <div style={{display: 'flex', width: "25%", height: "55px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              Importe
            </div>
        </div>
        {billingItems.filter(billingItem => billingItem["restaurant_billing_id"] == billing["id"]).map((billingItem, index) =>			
					<div style={{display: 'flex', borderColor: "#D1D1D1", backgroundColor: '#F8F8F8', fontWeight: 500, fontSize: "13px"}}>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: "1px 1px 0px 1px", fontWeight: 600, justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
              <div style={{padding: "0px 15px"}}>{billingItem.name}</div>
            </div>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              {billingItem.unit_type == "money" ? billingItem.quantity : centsToAmountString(billingItem.quantity, true, true)}{billingItem.unit_type == "money" ?  "" : "€"}
            </div>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              {billingItem.unit_type == "money" ? centsToAmountString(billingItem.unit_amount).replace('.', ',').replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : billingItem.unit_amount}{billingItem.unit_type == "money" ? "€" : "%"}
            </div>
            <div style={{display: 'flex', width: "50%", height: "45px", borderWidth: "1px 1px 0px 0px", justifyContent: 'center', alignItems: 'center'}}>
              {centsToAmountString(billingItem.amount).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}€
            </div>
          </div>)}
          <IndexedDetailRow name={"Subtotal"} value={centsToAmountString(billing.total, true, true) + "€"} bold={false} position={"F"}/>
          <IndexedDetailRow name={"IVA"} value={centsToAmountString(billing.tax, true, true) + "€"} bold={false} position={""}/>
          <IndexedDetailRow name={"Total"} value={centsToAmountString(billing.final_total, true, true) + "€"} bold={true} position={"L"}/>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default BillingDetailModal