import { Icon, IconType } from 'common/Icon';
import { Popup } from 'common/Popup';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const CustomWarning = (popupProps) => {
  const { t } = useTranslation();

  return (
    <Popup {...popupProps}>
      <div className="flex items-center justify-center gap-4 w-[350px]">
        <Icon type={IconType.WARNING} size={120}/>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row items-center gap-2'>
            <b style={{lineHeight: "17px"}}>{t('not_possible_to_change_status')}</b>
          </div>
          <p style={{textAlign: "left"}}>
            <Trans i18nkey='should_desactivate_general_discount'>
              <b>Debes desactivar el descuento en toda la carta</b> para poder modificar el estado de esta promoción.
            </Trans>
          </p>
        </div>
      </div>
    </Popup>
  )
};