import React from "react";
import { PlusIcon } from '@heroicons/react/outline';
import styled from "styled-components";

export const AddButton = ({ text, plusIconStyle=null, ...buttonProps }) => {
  return (
    <AddButtonContainer {...buttonProps}>
      <PlusIcon style={plusIconStyle && plusIconStyle}/>
      {text}
    </AddButtonContainer>
  );
};

const AddButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  height: 36px;
  color: #767676;
  border: 2px solid #C8C8C8;
  border-radius: 99px;

  &:focus, &:hover {
    outline: none;
    border-color: #5FB894;
  }
  
  svg {
    color: #FFF;
    background-color: #5FB894;
    border-radius: 99px;
    height: 1.125rem;
    width: 1.125rem;
    border: 3px solid #5FB894;

    path {
      stroke-width: 4px
    };
  }
`;