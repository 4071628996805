import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'common/Popup';
import { TrashIcon } from '@heroicons/react/outline';
import { Button, BUTTON_COLORS, BUTTON_VARIANTS } from 'common/Button';
import { DeleteEventContainer } from 'pages/events/EventsContainers';

export const DeleteDiscountPopup = ({isOpen, confirm, cancel}) => {
    const { t } = useTranslation();
  
    return (
      <Popup isOpen={isOpen} close={cancel}>
       <DeleteEventContainer>
            <TrashIcon 
                className={`w-[40px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />
            <h3>{ t('delete_discount')}</h3>
            <p>
                {t('delete_discount_question')}
            </p>
            <footer>
                <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    label={t('no, cancel')}
                    onClick={cancel}
                />
                <Button
                    label={t('yes_delete')}
                    onClick={confirm}
                    color={BUTTON_COLORS.DANGER}
                />
            </footer>
        </DeleteEventContainer>
      </Popup>
    );
  };