import React from 'react';
import MenuContainer from 'layouts/MenuContainer';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import { AccessTypeSelector } from './components/AccessTypeSelector';
import { AccessContextProvider } from './AccesContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AccessContainer = ({ children }) => {
  const history = useHistory();
  const isRowBased = useMediaQuery("(max-width: 760px)");

  const type = history.location.pathname.replace('/access/', '').replace('_list', '');

  return (
    <div id="accessContainer" className={`flex flex-col bg-gray-100 h-full max-h-screen w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex: ' 0 1 auto', position: 'fixed', overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        <MenuHeader title={'access_management'} route_group={'access'}/>
        <div className='flex gap-4 bg-white p-4 items-end'>
          <RestaurantSelect/>
          <AccessTypeSelector/>
        </div>
        <AccessContextProvider type={type}>
          {children}
        </AccessContextProvider>
      </div>
    </div>
  );
};

export default AccessContainer;
