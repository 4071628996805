import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import NewFeaturesIcon from "common/NewFeaturesIcon";
import NewFeaturesButtomCard from "./NewFeaturesButtomCard";
import NewFeaturesImageCard from "./NewFeaturesImageCard";
import DOMPurify from "dompurify";

export const NewFeaturesCard = ({newFeature}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const sanitizedMessage = DOMPurify.sanitize(newFeature.message);

	return (
		<>
        <div className={`flex flex-col pr-8 pb-8 pt-5`} >
			{loading ?
				<>
					<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
						<Spinner>{t("Loading")}</Spinner>
					</div>
					<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
				</> :
				<>
				<div className="flex w-[365px] h-min min-h-[265px] flex-row flex-1">
					<div className="p-5 w-2/4" style={{display: 'flex', justifyContent: 'space-between', flexDirection: "column", backgroundColor: "#FFFFFF", borderBottomLeftRadius: "12px", borderTopLeftRadius: "12px", border: "1px solid #D7D7D7", boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"}}>
						<div className="flex flex-col align-center gap-2">
							<NewFeaturesIcon icon={newFeature.icon} color={newFeature.icon_bg_color}/> 
							<span className="text-base font-semibold mt-1 text-[#151D48]">{newFeature.title}</span>
						</div>

						<div className="pt-2 text-[#151D48] text-xs text-left leading-5" dangerouslySetInnerHTML={{ __html: sanitizedMessage }}></div>

						{newFeature.menu_completion &&
							<div className="relative mt-8">
								<span className="absolute bottom-2 left-0 text-primary font-bold">{newFeature.menu_completion + "%"}</span>
								<div className="w-full h-1 bg-[#E2E8F0]">
									<div className="h-full bg-primary" style={{ width: newFeature.menu_completion + "%" }}></div>
								</div>
							</div>
						}
						<NewFeaturesButtomCard title={newFeature.button_title} click={newFeature.button_click}/>
					</div>
					<NewFeaturesImageCard image={newFeature.image} pillTitle={newFeature.pill_title} pillColor={newFeature.pill_color}/>
				</div>

				</>

			
			}
        </div>        
    </>
	)
}

export default NewFeaturesCard