export const HEX_REGEX =/^#?[a-fA-F\d]{6}/;
const HEX_CHANNELS_REGEX =/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

const hexToRGB = (hex) => {
  if (!HEX_REGEX.test(hex)) throw new Error(`${hex} is not a valid hex color.`);

  const channels = HEX_CHANNELS_REGEX.exec(hex);

  const r = parseInt(channels[1], 16);
  const g = parseInt(channels[2], 16);
  const b = parseInt(channels[3], 16);

  return [r, g, b];
};

const hexToHSL = (hex) => {
  let [r, g, b] = hexToRGB(hex);

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  let l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch(max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
        break;
      case g:
        h = ((b - r) / d + 2) / 6;
        break;
      case b:
        h = ((r - g) / d + 4) / 6;
        break;
      default: 
        break;
    }
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
};

export const getContrastRatio = (color1, color2) => {
  const getLuminance = (rgb) => {
    const lum = rgb.map((v) => {
      v /= 255;
      
      return v <= 0.03928
        ? v / 12.92
        : Math.pow((v + 0.055) / 1.055, 2.4);
    })
    
    return lum[0] * 0.2126 + lum[1] * 0.7152 + lum[2] * 0.0722;
  };

  const luminance1 = getLuminance(hexToRGB(color1));
  const luminance2 = getLuminance(hexToRGB(color2));
  
  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

export const getTextColor = (color) => getContrastRatio(color, '#404040') > getContrastRatio(color, '#FFFFFF') ? '#404040' : '#FFFFFF';

export const generatePalette = (color, prefix) => {
  const {h, s, l} = hexToHSL(color)

  const valid = (number) => Math.min(Math.max(number, 0), 100);

  return {
    [prefix]: `hsl(${h} ${s}% ${l}%)`,
    [prefix + '_dark']: `hsl(${h} ${valid(s + 15)}% ${valid(l - 20)}%)`,
    [prefix + '_light']: `hsl(${h} ${valid(s - 5)}% ${Math.max(l, 80)}%)`,
    [prefix + '_lighter']: `hsl(${h} ${valid(s - 15)}% ${Math.max(l, 95)}%)`,
    [prefix + '_disabled']: `hsl(${h} ${Math.min(valid(s - 30), 35)}% ${Math.max(l, 70)}%)`,
    [prefix + '_a20']: `hsla(${h}, ${s}%, ${l}%, 0.2)`,
    [prefix + '_a50']: `hsla(${h}, ${s}%, ${l}%, 0.5)`,
  }
};

/*
hsl(hue(0-360)🌈, saturation(0-100)💥, lightness(0-100)💡)

Darker colors tend to be slightly more saturated than lighter ones.

If you need a very dark/light variation of a color, use a fixed
lightness value instead of subtraction/addition to avoid
pure black/white
*/