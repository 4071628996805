import React, { useState } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { deleteEventOrTicket, ObjectTypes } from './EventApi';
import { calculateEuros } from "services/payments";
import { Icon, IconType } from 'common/Icon';
import { ActiveSwitch } from "../../pages/admin/pages/OrderAndPay/ActiveSwitch";
import { cloneDeep } from 'lodash';
import { saveEventOrTicket } from './EventApi';
import { DeleteEventPopup } from './DeleteEventPopup';
import { NotRemovablePopup } from './NotRemovablePopup';
import { getImageUrl } from 'logic/functions';

export const TicketRow = ({ ticket, editTicket, setTicketDraft, mutate, provided, innerRef, restaurant, open }) => {
    const { t } = useTranslation();
    const imageUrl = ticket.image;
    const [isLoading, setIsLoading] = useState(false)
    const [active, setActive] = useState(ticket?.active)
    const [deletePopup, setDeletePopup] = useState(false)
    const [notRemovableTicketPopup, setNotRemovableTicketPopup] = useState(false)
    const notRemovable = ticket.not_removable;
    


    const handleEdit = () => {
      editTicket(ticket);
      setTicketDraft(ticket);
    };
  
    const openImage = () => {
      if (imageUrl) {
        window.open(getImageUrl(imageUrl), '_blank');
      }
    };

    const handleDelete = () => {
        if(notRemovable) {
            setNotRemovableTicketPopup(true)
        } else {
            setDeletePopup(true);
        }
    }
    
    const confirmDelete = () => {
        deleteEventOrTicket({ objectType: ObjectTypes['TICKET'], object: ticket, mutate, t: t });
        setDeletePopup(false)
    }


    const toggleActiveTicket = async state => {
        const newTicket = cloneDeep(ticket)
    
        if (ticket) {
          setActive(state)
        };
        newTicket.active = state
    
        setIsLoading(true);
    
        try {
          await saveEventOrTicket({
            restaurant: restaurant,
            eventOrTicket: 'TICKET',
            eventToEdit: null,
            ticketToEdit: newTicket,
            object: newTicket, 
            t: t
          })
    
          mutate();
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
    
        } catch (error) {
          setIsLoading(false);
          mutate();
          console.log("🚀 toggleActiveTicket error:", error);
        } 
      
    };    
  
    return (
      <tr
        className = {`${!ticket?.active ? 'disabled' : ''}`}
        ref={innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <td></td>
        <td>
          <span className='flex items-center gap-4'>
            <Icon type={IconType.DRAG} size={24}/>
            <span className='name bg-[#FFF1CC] rounded-[6px] px-2 w-max'>
              {ticket.name}
            </span>
          </span>
        </td>
        <td style={{minWidth: "280px"}}> 
          <span className='price whitespace-nowrap'>
            <span className='font-semibold'>{t('price')}:</span> {calculateEuros(ticket.price_cents)}€
          </span>
        </td>
        <td>
          <span className='flex justify-center'>
            {imageUrl ?
              <Button
                size={BUTTON_SIZES.SM}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={() => openImage()}
                label='see_photo'
                className='px-2'
              />
              :
              <Button
                size={BUTTON_SIZES.SM}
                variant={BUTTON_VARIANTS.PRIMARY}
                onClick={() => handleEdit()}
                label='add_photo'
                leftIcon={<Icon type={IconType.ADD} />}
              />
            }
          </span>
        </td>
        <td style={{ maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <span style={{ maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', color: ticket.description ? "#404040" : "#626262" }}>
            <span style={{fontWeight: 600}}>{t('Description')}: </span>{ticket.description ? ticket.description : t('no_description')}
          </span>
        </td>
        <td style={{width: "20px"}}>
            <ActiveSwitch
                noMessage={true}
                noTitle={true}
                disabled={isLoading}
                loading={isLoading}
                checked={active}
                onChange={toggleActiveTicket}
                />
        </td>
        <td style={{width: "20px"}}>
          <TrashIcon
            onClick={() => handleDelete()}
            className={`w-[20px] ${notRemovable ? 'text-gray-300' : 'text-gray-700'} cursor-pointer transition-all animate-fade-in-display delay-75 `}
          />
        </td>
        <td style={{width: "20px"}}>
          <PencilIcon
            onClick={() => handleEdit()}
            className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
          />  
        </td>
        {!notRemovable && <DeleteEventPopup isOpen={deletePopup} confirm={confirmDelete} cancel={() => setDeletePopup(false)} type='TICKET'/>}
        {notRemovable && <NotRemovablePopup isOpen={notRemovableTicketPopup} confirm={()=> setNotRemovableTicketPopup(false)} type='TICKET'/>}
      </tr>
  );
};
