import React from 'react';
import styled from 'styled-components';

export const CustomProgressBar = ({ estimatedSeconds = 10, progressPercentage=null}) => {
  return (
    <Bar estimatedSeconds={estimatedSeconds} progressPercentage={progressPercentage}>
      <div/>
    </Bar>
  );
};

const Bar = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 99px;
  background-color: #E2E8F0;
  
  div {
    width:  ${({progressPercentage}) => progressPercentage !== null ? `${progressPercentage}%` : 0};
    height: 100%;
    border-radius: 99px;
    background: linear-gradient(90deg, #094553, #5FB894);
    animation: ${({estimatedSeconds, progressPercentage}) => progressPercentage === null && estimatedSeconds ? `progress ${estimatedSeconds}s cubic-bezier(.08,.93,.43,.96) forwards` : ""};
    transition: ${({progressPercentage}) => progressPercentage === null ? "" : "width .6s ease"};
  }

  @keyframes progress {
    to { width: 100% }
  }
`;
