import React, { useState } from 'react';
import styled from "styled-components";
import { toast } from 'react-toastify';
import { baseAuth } from "logic/api";
import { useTranslation } from "react-i18next";

const CustomInput = styled.input`
	z-index: 5;
	color: #000;
	height: 41px;
    width: 200px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 8px;
	border-radius: 8px;
	position: relative;
	border: 2px solid #D4D4D8;
	font-family: Poppins, sans-serif;
`;

const StockTypeSelect = ({setItemsLoading, selectedShift, typeOfItem, itemStockId, itemTpvId, initialValue, stockUpdated}) => {

	const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(initialValue);

    const updateStock = (itemStockId, itemTpvId, stock) => {
        /*console.log('itemStockId', itemStockId);
        console.log('itemTpvId', itemTpvId);
        console.log('stock', stock);*/
        let endpoint = '';
        const typeOfItemSingular = typeOfItem.substring(0, typeOfItem.length - 1);
        if (stock?.length > 0) {
            endpoint = `stock/update/${typeOfItemSingular}_stock/${selectedShift}/${itemTpvId}/${stock}/`;
        } else {
            endpoint = `stock/delete/${typeOfItemSingular}_stock/${itemStockId}/`;
        }
        setItemsLoading(true);
        baseAuth.get(endpoint).then(stockUpdated).catch(err => {
            //console.log(`updateStock catch: ${err.toString()}`);
            toast.error(t('Error updating stock'));
            setItemsLoading(false);
        });
    };

	return (
		<CustomInput
            placeholder={t("Without limit")}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onBlur={event => updateStock(itemStockId, itemTpvId, event.target.value)}
        />
	);
};
export default StockTypeSelect;
