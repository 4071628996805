import styled from 'styled-components';

const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    gap: 16px;
    min-width: 20rem;
    padding: 10px;
  
    .shipping_input {
        width: 100%;
    }
`;

const HowTo = styled.a`
 display: flex;
 justify-content: ${({type}) => type === 'geojson' ? 'start' : 'center'};
 align-items: center;
 font-weight: 600;
 font-size: 14px;
 line-height: 18.2px;
 color: #094553;
 cursor: pointer;
 text-decoration: underline;
 text-align: center;
`;

const DeleteFile = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 font-weight: 600;
 font-size: 16px;
 line-height: 18.2px;
 color: #094553;
 cursor: pointer;
 text-decoration: underline;
 text-align: center;
`;

export {InputContainer, HowTo, DeleteFile}