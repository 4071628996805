import React,{useState} from 'react'
import { CustomGroupPreview, MobileMockup } from "components/previews";
import green_check from "../../../../img/green_check.svg";
import QRCode from 'react-qr-code';

export const CustomGroupFinishedSettings = ({catalog, customGroupDraft}) => {
  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
      <div style={{width: '45%', height: '100%', justifyContent: 'flex-end', display: 'flex'}}>
        <MobileMockup>
          <CustomGroupPreview catalog={catalog} customGroupDraft={customGroupDraft}/>      
        </MobileMockup>
      </div>
      <div style={{gap: "10px", fontSize: '18px', textAlign: 'center', width: '55%', height: '100%', paddingLeft: '15px', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <img src={green_check} style={{width: '35px'}} />
          <div style={{marginLeft: '5px', fontWeight: 600}}>¡Listo!</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}}>{customGroupDraft.name}</div>
        <div style={{fontSize: "13px", justifyContent: 'center', flexDirection: 'row'}}>Tu destacado ya está en la carta.<br/><span style={{fontWeight: 600}}>Escanea y prueba</span>, desde tu móvil.</div>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
        {customGroupDraft.test_table_url && 
        <QRCode
          size={130}
          value={customGroupDraft.test_table_url}
        />}
        </div>
      </div>  
    </div>
  )
}
