import { Popup } from "common/Popup";
import styled from "styled-components";

export const DiscountTableContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #40404026;
  border-radius: 1rem;
  padding: 8px;
  overflow: auto hidden;

  table {
    width: 100%;
    border-spacing: 0 16px;
    border-collapse: separate;
    margin: -16px 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th {
    background-color: #FCFCFC;
    border-bottom: 1px solid #40404026;
    height: 4.125rem;
    padding: 0 0.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3rem;
    text-wrap: balance;

    &:first-letter {
      text-transform: capitalize;
    }
    
    &:first-child {
      border-top-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
    }
  }

  tbody {
    box-shadow: inset 0 0 0 1px #DDD; //fake border
    border-radius: 8px;
  }

  tr {
    text-wrap: balance;
    
    &:first-child {
      td {
        border-bottom: 1px solid #DDD;
        padding: 16px 8px;

        &:first-child {
          padding-left: 16px;
          border-top-left-radius: 8px; 
        }

        &:last-child {
          padding-right: 16px;
          border-top-right-radius: 8px; 
        }

        &:nth-child(n + 7) {
          width: 4rem;
          min-width: 4rem;
        }
      }
    }

    &:last-child {
      td {
        padding: 0 16px 16px;
      }

      div {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        gap: 16px;
        
        & > span:last-child {
          margin-left: auto;
          font-size: 1rem;
        }

        hr {
          border: none;
          border-left: 2px solid currentColor;
          height: 16px;
          width: 0;
        }
      }
    }

    td {
      text-align: center;
      font-size: 1rem;

      &:first-letter {
        text-transform: capitalize;
      }

      & > * {
        margin:auto
      }
    }

    .col_name {
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
      }

      i {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1;
      }
    }

    .col_discount {
      width: auto;
    }

    .col_typology {
      max-width: 12ch;
    }

    .col_value {
      max-width: 10ch;
    }

    .col_applied {
      max-width: 16ch;
    }

    .col_visibility {
      max-width: 16ch;

      span + span {
        &::before {
          content: ', ';
        }
      }
    }

    .col_limits {
      max-width: 19ch;

      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }

    .badge {
      display: inline-block;
      font-weight: 500;
      line-height: 1.3;
      color: #222222;
      border: none;
      border-radius: 6px;
      padding: 4px 8px;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    .general_discount {
      background-color: #FFF0BB;
    }

    .code_coupon {
      background-color: #CCE8FB;
    }

    .product_discount {
      background-color: #EDE7FB;
    }

    .period {
      font-size: 0.875rem;
      background-color: #FFF9E2;
      width: min-content;
    }

    .maxUses {
      font-size: 0.875rem;
      white-space: nowrap;
      background-color: #E2F2FD;
    }

    .maxCents {
      font-size: 0.875rem;
      white-space: nowrap;
      background-color: #F7F0FF;
    }

    .noLimit, .disabled {
      font-size: 0.875rem;
      background-color: #ECECEC;
    }

    .limit {
      font-size: 0.875rem;
      background-color: #FBE7E9;
    }

    .operative {
      font-size: 0.875rem;
      background-color: #D4F8D3;
    }
  }

  .disabled_row td {
    background-color: #EEEEEE85;
  }

  .limit_reached_row td {
    background-color: #FFF1F285;
  }
`;

export const DiscountPopup = styled(Popup)`
  header {
    margin-bottom: 24px;
    
    div:has(h2) {
      display: flex;
      justify-content: space-between;
      gap: 24px;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
  }

  & > div {
    display: flex;
    flex-direction: column;
    min-height: 40rem;
    min-width: 55rem;

    & > h2 {
      text-align: center;
      margin-bottom: auto;
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
  }

  b {
    font-weight: 600;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 24px;
  }

  li {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 1rem;

    div {
      border: 1px solid #DDD;
      border-radius: 10px;
      padding: 16px;
      width: 20rem;
    }
  }

  .empty_state {
    margin: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    
    strong, p {
      max-width: 32ch;
      text-align: center;
    }
    
    strong {
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      margin: 0;
    }
  }

  .schedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin: 24px;

    & > div {
      min-width: 24rem;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    border-top: 1.5px solid #DDDDDD;
    padding-top: 24px;
    margin-top: auto;

    &:nth-of-type(n + 2) {
      display: none;
    }
  }
`;

export const VisualizeDiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 1;
  margin-bottom: 24px;
  
  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    padding: 4px;
    border-radius: 4px;

    &.general_discount {
      background-color: #FFF0BB;
    }

    &.code_coupon {
      background-color: #CCE8FB;
    }

    &.product_discount {
      background-color: #EDE7FB;
    }
  }
  
  img {
    height: 2.5rem;
    width: 2.5rem;
  }
  
  p {
    font-size: 1rem;
    max-width: 28ch;
  }
  
  b {
    font-weight: 600;
  }
`;