import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import styled from "styled-components";
import { mockThemes } from '../constants';


export const BottomSheetMockup = ({ children, themeName, className = '' }) => {
  const theme = mockThemes[themeName || 'light'];

  return (
    <Container theme={theme} className={className}>
      <div>
        <header>
          <XIcon height={12}/>
        </header>
        <div>
          {children}
        </div>
      </div>
    </Container>
  );
};


const Container = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${({theme}) => theme.backdrop_color};
  height: 100%;
  width: 100%;
  backdrop-filter: blur(1px);
  
  & > div {
    border-radius: 18px 18px 0 0;
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.background};

    & > header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 10px;
    }
  }
`;
