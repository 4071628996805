import React, { useState, useEffect } from "react";
import NewFeaturesCard from "../../home/components/overview/components/NewFeaturesCard";
import { Trans, useTranslation } from "react-i18next";
import menuPhoto from "../../../img/menu_photo.png";
import { baseAuth } from "logic/api";

export const NewFeatures = ({imagePercentage, isOC, functionalities, selectedRestaurantId}) => {
	const { t } = useTranslation();
	const nonImagePercentage = (100 - imagePercentage).toString()
	const defaultNewFeature = {
		id: 0,
		title: "Tu carta",
		message: `Este es el porcentaje de <strong>productos con foto</strong> en tu carta. ${nonImagePercentage === "0" ? '<strong>¡Lo estás haciendo <strong>genial!</strong>' : '<strong>¡Ve a completarla!</strong>'}`,
		menu_completion: imagePercentage.toString(),
		button_title: t('edit_menu'),
		button_click: "/admin/order-and-pay",
		icon: "🍕",
		icon_bg_color: "#E0F5ED",
		image: menuPhoto,
		pill_title: 'Fotos',
		pill_color: "#68A3CB",
		functionality_list: ['order_at_table', 'order_and_collect']
	}

	const [features, setFeatures] = useState([]);
	const [atLeastOneFeatureToShow, setAtLeastOneFeatureToShow] = useState(false)
	
	useEffect(() => {
	    let isMounted = true;

		const fetchData = async () => {
			try {
				const response = await baseAuth.get(`/new_feature/new_features`);

				if (isMounted) {
					const newFeatures = response.data.new_features;
					const isThereOneFeature = newFeatures.map((newFeature) => 
						newFeature.functionality_list?.some((func) => 
						functionalities.includes(func))
					)
					setAtLeastOneFeatureToShow(isThereOneFeature.some(value => value === true));
					setFeatures(isOC ?  [defaultNewFeature, ...newFeatures] : newFeatures); //if restaurantSelected is OC then we always show the defaultNewFeature
				}
			} catch (e) {
				if (isMounted) {
					console.log(`Error in NewFeatures: ${e}`)
				}
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, [selectedRestaurantId, isOC])

	return(
		<>
		{atLeastOneFeatureToShow || isOC ? 
			<div className='bg-white rounded-[27px] p-8 mb-2'>
				<div className="text-black font-poppins text-2xl leading-8 w-full font-normal">
					<div>
						<span role="img" aria-label="Fire" className="mr-2">🔥</span>
						<Trans i18nKey={"maximize your solution"}>
							<span className='font-semibold'>Maximize</span> your solution
						</Trans>
						<span role="img" aria-label="Fire" className="ml-2">🔥</span>
					</div>
				</div>

				<div className="flex flex-wrap">
				{features.map((newFeature) => 
					newFeature.functionality_list?.some((func) => 
						functionalities.includes(func)) &&
						<NewFeaturesCard key={newFeature.id} newFeature={newFeature}/>
				)}
				</div>
			</div>
		:
			<></>
		}
		</>
	)
}


