import { ChevronRightIcon, XIcon } from '@heroicons/react/outline';
import React, { useContext, useMemo } from 'react';
import { OrderItem } from './OrderItem';
import { useTranslation } from 'react-i18next';
import { ItemButton, OrderList, PanelTemplate } from './LockerContainers';
import { LockersContext } from 'data/LockersContext';

export const CounterPanel = ({counterId, manageOrder, setSideOrder, close}) => {
  const { state: { lockers } } = useContext(LockersContext);
  const { t } = useTranslation();

  const counter = useMemo(() => lockers.find(locker => locker.id === counterId), [lockers, counterId]);

  return (
    <PanelTemplate>
      <header>
        <h3>{counter.name}</h3>
        <button onClick={close}>
          <XIcon height={24}/>
        </button>
      </header>
      <hr/>
      <p>
        <b>{counter.order_list.length}</b>
        {t(counter.order_list.length === 1 ? 'order' : 'orders')}
      </p>
      <OrderList>
        {counter.order_list.map(order =>
          <OrderItem key={order.id} order={order} onClick={() => manageOrder(order)} onOrderClick={setSideOrder}>
            <ItemButton>
              <span>{t('see_and_manage')}</span>
              <ChevronRightIcon height={18}/>
            </ItemButton>
          </OrderItem>
        )}
      </OrderList>
    </PanelTemplate>
  );
};