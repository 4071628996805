import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import orderAndPayLogo from "../../../img/o&p.svg"
import ProductHighlightRow from "../pages/OrderAndPay/ProductHighlightRow"
import DeleteCustomGroupModal from "./OrderAndPay/Modals/DeleteCustomGroupModal";
import ChooseTypeCustomGroupModal from "./OrderAndPay/Modals/ChooseTypeCustomGroupModal";
import CustomGroupModal from "./OrderAndPay/Modals/CustomGroupModal";
import IndividualCustomGroupModal from "./OrderAndPay/Modals/IndividualCustomGroupModal";
import CommercialBannerModal from "./OrderAndPay/Modals/CommercialBannerModal";
import {newCustomGroupMock} from "../../../data/newCustomGroup"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getPosition } from "../pages/OrderAndPay/ProductHighlightRow";
import { HighlightsEmptyStateIcon } from "common/EmptyStateIcons";
import EmptyState from "common/EmptyState";
import { Button } from "common/Button";
import { PlusCircleIcon } from "@heroicons/react/solid";

const rowStyle = {
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '.2fr 1.5fr 1fr 1.5fr 1fr .5fr .2fr',
	width: '100%',
};

export const ProductHighlight = () => {
	const {
		state: { selectedRestaurantId }
	} = React.useContext(AuthContext);

	const { t } = useTranslation();
	let indent = 0;
	const [loading, setLoading] = useState(false);
	const [customGroups, setCustomGroups] = useState(null);	
	const [catalog, setCatalog] = useState(null);	
	const [lIds, setLIds] = useState(null);	
	const [currentStep, setCurrentStep] = useState(null);	
	const [customGroupToRemove, setCustomGroupToRemove] = useState(null);
	const [customGroupToEdit, setCustomGroupToEdit] = useState(null);
	const [newCustomGroup, setNewCustomGroup] = useState(null);
	const [draggingType, setDraggingType] = useState('');
	const [chooseType, setChooseType] = useState(false);

	useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setLIds(null)
		setCustomGroups(null);
		if (selectedRestaurantId) {
			getData();
		}
	}, [selectedRestaurantId])

	const getData = async () => {
		setLoading(true)
		await baseAuth.get(`/custom_group/custom_groups?restaurant=${selectedRestaurantId}`)
			.then((response) => {
				const custom_groups = response.data.product_highlights
				const catalog = response.data.catalog
				let l_ids = {"l1": [], "l2": [], "l3": []}
				custom_groups.filter(c_g => c_g.l1 != null).map((c_g) => !l_ids["l1"].includes(c_g.l1) && l_ids["l1"].push(c_g.l1))
				custom_groups.filter(c_g => c_g.l2 != null).map((c_g) => !l_ids["l2"].includes(c_g.l2) && l_ids["l2"].push(c_g.l2))
				custom_groups.filter(c_g => c_g.l3 != null).map((c_g) => !l_ids["l3"].includes(c_g.l3) && l_ids["l3"].push(c_g.l3))
				setLIds(l_ids)
				setCustomGroups(custom_groups)
				setCatalog(catalog)
				setLoading(false)
			})
			.catch((e) => {
				console.log(`Error ProductHighlight in custom_groups?restaurant=${selectedRestaurantId}: ${e}`)
				setLoading(false)
			})
	}

	const handleNewCustomGroup = (type) => {
		setChooseType(false)
		let mockCustomGorup = JSON.parse(JSON.stringify(newCustomGroupMock)); 
		mockCustomGorup["restaurant"] = selectedRestaurantId
		mockCustomGorup["custom_group_type"] = type
		setNewCustomGroup(mockCustomGorup)
	}

	const updateCustomGroup = async (custom_group, type, close_modal=true) => {
		if (close_modal) {
			setCustomGroupToRemove(null)
			setCustomGroupToEdit(null)
			setNewCustomGroup(null)
		}
		setLoading(true)
		custom_group.type = type
		custom_group.restaurant = selectedRestaurantId
		let custom_group_f_d = new FormData();
		for ( var key in custom_group ) {
			if (!["products_info", "visibility_schedules"].includes(key)) {
				custom_group_f_d.append(key, custom_group[key] != null ? custom_group[key] : "");
			}
		}
		custom_group_f_d.append("products_info", JSON.stringify(custom_group["products_info"]));
		custom_group_f_d.append("visibility_schedules", JSON.stringify(custom_group["visibility_schedules"]));
		await baseAuth(`/custom_group/update_custom_group`, {
			method: "POST",
			"Content-Type": "multipart/form-data",
			data: custom_group_f_d,
		  })
		  .then((response) => {
			const custom_groups = response.data.product_highlights
			let l_ids = {"l1": [], "l2": [], "l3": []}
			custom_groups.filter(c_g => c_g.l1 != null).map((c_g) => !l_ids["l1"].includes(c_g.l1) && l_ids["l1"].push(c_g.l1))
			custom_groups.filter(c_g => c_g.l2 != null).map((c_g) => !l_ids["l2"].includes(c_g.l2) && l_ids["l2"].push(c_g.l2))
			custom_groups.filter(c_g => c_g.l3 != null).map((c_g) => !l_ids["l3"].includes(c_g.l3) && l_ids["l3"].push(c_g.l3))
			//custom_groups.sort((a,b) => b.relation_id - a.relation_id);
			setLIds(l_ids)
			setCustomGroups(custom_groups)
			let newCustomGroupToEdit = null
			if (newCustomGroup) {
				if (newCustomGroup.custom_group_type === "multi") {
					setCurrentStep(5)
				} else if (newCustomGroup.custom_group_type === "individual") {
					setCurrentStep(6)
				} else {
					setCurrentStep(3)
				}
				newCustomGroupToEdit = custom_groups[0]
			} else {
				newCustomGroupToEdit = custom_groups.find(c_g => c_g.relation_id === customGroupToEdit.relation_id)
			}
			if (type !== 0) {
				setCustomGroupToEdit(newCustomGroupToEdit)
				setNewCustomGroup(null)
			} else {
				setCurrentStep(null)
			}
			setLoading(false)
		  })
		  .catch((e) => {
			console.log(`Error ProductHighlight in update_custom_group: ${e}`)
			setLoading(false)
		  })
	  }

	const handleDragEnd = async ({ destination, source }) => {
		if (destination && source && destination.index !== source.index) {
			setLoading(true)
			let filteredCustomGroup = customGroups.filter(custom_group => draggingType === "restaurant" ? custom_group["restaurant"] != null : custom_group[draggingType.split('_')[0]] === draggingType.split('_')[1])
			const result = [...filteredCustomGroup];
			const [removed] = result.splice(source.index, 1);
			result.splice(destination.index, 0, removed);
			let newRelationOrderList = result.map((c_g, index) => ({ ...c_g, order: index }))
			let data = {"new_order_list": newRelationOrderList, "restaurant_id": selectedRestaurantId}
			await baseAuth.post(`/custom_group/update_custom_group_order`, data)
				.then((response) => {
					const custom_groups = response.data.product_highlights
					setCustomGroups(custom_groups)
					let l_ids = {"l1": [], "l2": [], "l3": []}
					custom_groups.filter(c_g => c_g.l1 != null).map((c_g) => !l_ids["l1"].includes(c_g.l1) && l_ids["l1"].push(c_g.l1))
					custom_groups.filter(c_g => c_g.l2 != null).map((c_g) => !l_ids["l2"].includes(c_g.l2) && l_ids["l2"].push(c_g.l2))
					custom_groups.filter(c_g => c_g.l3 != null).map((c_g) => !l_ids["l3"].includes(c_g.l3) && l_ids["l3"].push(c_g.l3))
					setLIds(l_ids)	
				})
				.catch((e) => {
					console.log(`Error ProductHighlight in update_custom_group_order: ${e}`)
				})
		}
		setLoading(false)
		setDraggingType('');
	}

	const onDragStart = (result) => {
		setDraggingType(result.draggableId.split('-')[0]);
	}

	return (
		<>
			{(loading) &&
				<>
					<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
						<Spinner>{t("Loading")}</Spinner>
					</div>
					<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
				</>
			}
			<div style={{marginBottom: '25px'}}>
				{(!loading && catalog && catalog.product_categories.length > 0) &&
					<div>
						<div style={{width: '90%', display: 'flex', alignSelf: 'center', gap: '16px'}}>
							<Button
								label='create_new_featured_product'
								leftIcon={<PlusCircleIcon/>}
								onClick={() => setChooseType(true)}
							/>
							<Button
								label='create_advertising_banner'
								leftIcon={<PlusCircleIcon/>}
								onClick={() => handleNewCustomGroup("commercial")}
							/>
						</div>
						{customGroups && customGroups.filter(c_g => c_g.restaurant != null).length > 0 &&
						<div style={{width: '90%', alignSelf: 'center', marginTop: '10px', opacity: draggingType !== "" && draggingType !== "restaurant" ? 0.5 : 1}} className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200">
							<table className=" w-full">
								<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white">
									<div style={{width: '100%', textAlign: 'center', fontWeight: 600, marginTop: '10px', fontSize: '15px', textDecoration: 'underline'}}>Inicio de la carta</div>
									<tr style={rowStyle}>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('highlight_name')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('content')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('schedules')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('status')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
									</tr>
								</thead>
								<tbody style={{position: 'relative'}}>
								<DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
									<Droppable droppableId="droppable" direction="vertical" style={{ width: '100%' }}>
									{(providedList) =>
										<div ref={providedList.innerRef} style={{position: 'relative'}} {...providedList.droppableProps}>
											{customGroups.filter(c_g => c_g.restaurant != null).map((custom_group, index) =>
												<Draggable key={custom_group.relation_id} index={index} draggableId={`restaurant-${custom_group.relation_id.toString()}`}>
													{(provided) => (
														<div ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}>
															<ProductHighlightRow								
																custom_group={custom_group}
																indent={indent}
																setLoading={setLoading}
																setCustomGroups={setCustomGroups}
																setCustomGroupToRemove={setCustomGroupToRemove}
																setCustomGroupToEdit={setCustomGroupToEdit}
																catalog={catalog}
															/>
														</div>
														
													)}
												</Draggable>
											)}
											{providedList.placeholder}
										</div>
									}
									</Droppable>
								</DragDropContext>
								</tbody>
							</table>
							</div>}
							{lIds && lIds["l1"].map((l1_id) => 
							customGroups && customGroups.filter(c_g => c_g.l1 === l1_id).length > 0 &&
							<div style={{width: '90%', alignSelf: 'center', marginTop: '10px', opacity: draggingType !== "" && draggingType !== `l1_${l1_id}` ? 0.5 : 1}} className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200">
							<table className=" w-full">
								<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white">
									<div style={{width: '100%', textAlign: 'center', fontWeight: 600,  marginTop: '10px', fontSize: '15px', textDecoration: 'underline'}}>{getPosition(customGroups.filter(c_g => c_g.l1 === l1_id)[0], catalog)[1]}</div>
									<tr
									style={rowStyle}>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('highlight_name')}</th>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('content')}</th>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('schedules')}</th>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('status')}</th>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
									<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
									</tr>
								</thead>
								<tbody style={{position: 'relative'}}>
									<DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
										<Droppable droppableId="droppable" direction="vertical" style={{ width: '100%' }}>
										{(providedList) =>
											<div ref={providedList.innerRef} style={{position: 'relative'}} {...providedList.droppableProps}>
												{customGroups.filter(c_g => c_g.l1 === l1_id).map((custom_group, index) =>
													<Draggable key={custom_group.relation_id} index={index} draggableId={`l1_${l1_id}-${custom_group.relation_id.toString()}`}>
													{(provided) => (
														<div ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}>
															<ProductHighlightRow
																custom_group={custom_group}
																indent={indent}
																setLoading={setLoading}
																setCustomGroups={setCustomGroups}
																setCustomGroupToRemove={setCustomGroupToRemove}
																setCustomGroupToEdit={setCustomGroupToEdit}
																catalog={catalog}
															/>
														</div>
															
												)}
												</Draggable>
												)}
												{providedList.placeholder}
											</div>
										}
										</Droppable>
									</DragDropContext>
								</tbody>
							</table>
							</div>)}						
							
							{lIds && lIds["l2"].map((l2_id) => 
							customGroups && customGroups.filter(c_g => c_g.l2 === l2_id).length > 0 &&
							<div style={{width: '90%', alignSelf: 'center', marginTop: '10px', opacity: draggingType !== "" && draggingType !== `l2_${l2_id}` ? 0.5 : 1}} className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200">
							<table className=" w-full">
								<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white">
								<div style={{width: '100%', textAlign: 'center', fontWeight: 600,  marginTop: '10px', fontSize: '15px', textDecoration: 'underline'}}>{getPosition(customGroups.filter(c_g => c_g.l2 === l2_id)[0], catalog)[1]}</div>
									<tr style={rowStyle}>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('highlight_name')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('content')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('schedules')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('status')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
									</tr>
								</thead>
								<tbody style={{position: 'relative'}}>
									<DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
										<Droppable droppableId="droppable" direction="vertical" style={{ width: '100%' }}>
										{(providedList) =>
											<div ref={providedList.innerRef} style={{position: 'relative'}} {...providedList.droppableProps}>
												{customGroups.filter(c_g => c_g.l2 === l2_id).map((custom_group, index) =>
													<Draggable key={custom_group.relation_id} index={index} draggableId={`l2_${l2_id}-${custom_group.relation_id.toString()}`}>
													{(provided) => (
														<div ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}>
															<ProductHighlightRow
																custom_group={custom_group}
																indent={indent}
																setLoading={setLoading}
																setCustomGroups={setCustomGroups}
																setCustomGroupToRemove={setCustomGroupToRemove}
																setCustomGroupToEdit={setCustomGroupToEdit}
																catalog={catalog}
															/>
														</div>
															
												)}
												</Draggable>
												)}
												{providedList.placeholder}
											</div>
										}
										</Droppable>
									</DragDropContext>
								</tbody>
							</table>
							</div>)}	
							{lIds && lIds["l3"].map((l3_id) => 
							customGroups && customGroups.filter(c_g => c_g.l3 === l3_id).length > 0 &&
							<div style={{width: '90%', alignSelf: 'center', marginTop: '10px', opacity: draggingType !== "" && draggingType !== `l3_${l3_id}` ? 0.5 : 1}} className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200">
							<table className=" w-full">
								<thead className="sticky top-0 m-0 text-gray-600 z-50 bg-white">
								<div style={{width: '100%', textAlign: 'center', fontWeight: 600,  marginTop: '10px', fontSize: '15px', textDecoration: 'underline'}}>{getPosition(customGroups.filter(c_g => c_g.l3 === l3_id)[0], catalog)[1]}</div>
									<tr style={rowStyle}>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('highlight_name')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('content')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('schedules')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5">{t('status')}</th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
										<th className="text-xs font-semibold border-b-2 border-gray-200 py-5"></th>
									</tr>
								</thead>
								<tbody style={{position: 'relative'}}>
									<DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
										<Droppable droppableId="droppable" direction="vertical" style={{ width: '100%' }}>
										{(providedList) =>
											<div ref={providedList.innerRef} style={{position: 'relative'}} {...providedList.droppableProps}>
												{customGroups.filter(c_g => c_g.l3 === l3_id).map((custom_group, index) =>
													<Draggable key={custom_group.relation_id} index={index} draggableId={`l3_${l3_id}-${custom_group.relation_id.toString()}`}>
													{(provided) => (
														<div ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}>
															<ProductHighlightRow
																custom_group={custom_group}
																indent={indent}
																setLoading={setLoading}
																setCustomGroups={setCustomGroups}
																setCustomGroupToRemove={setCustomGroupToRemove}
																setCustomGroupToEdit={setCustomGroupToEdit}
																catalog={catalog}
															/>
														</div>	
													)}
												</Draggable>
												)}
												{providedList.placeholder}
											</div>
										}
										</Droppable>
									</DragDropContext>
								</tbody>
							</table>
						</div>)}
						{customGroups && customGroups.filter(c_g => c_g.restaurant != null).length === 0 &&
							<div 
								style={{width: '90%', alignSelf: 'center', marginTop: '10px', opacity: draggingType !== "" && draggingType !== "restaurant" ? 0.5 : 1}} 
								className="bg-white rounded-xl p-5 pt-0 w-full border-2 border-gray-200"
							>
								<EmptyState 
									text={
										<Trans i18nKey={"increase_your_average_ticket"}>
											<span style={{fontWeight: 600}}>¡Incrementa tu ticket medio <i>destacando</i></span><br></br> tus productos!
										</Trans>
									} 
									icon={<HighlightsEmptyStateIcon/>} 
								/>
							</div>
						}
					</div>
				}		
			</div>

			{/* Modals */}
			{chooseType &&
				<ChooseTypeCustomGroupModal t={t} handleNewCustomGroup={handleNewCustomGroup} setChooseType={setChooseType}/>
			}
			{customGroupToRemove &&
				<DeleteCustomGroupModal t={t} removeCustomGroup={updateCustomGroup} custom_group={customGroupToRemove} setCustomGroupToRemove={setCustomGroupToRemove}/>
			}
			{customGroupToEdit && customGroupToEdit["custom_group_type"] === "multi" &&
				<CustomGroupModal catalog={catalog} t={t} saveCustomGroup={updateCustomGroup} custom_group={customGroupToEdit} setCustomGroupToEdit={setCustomGroupToEdit} setCustomGroupToRemove={setCustomGroupToRemove} type={0} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
			}
			{newCustomGroup && newCustomGroup["custom_group_type"] === "multi" &&
				<CustomGroupModal catalog={catalog} t={t} saveCustomGroup={updateCustomGroup} custom_group={newCustomGroup} setCustomGroupToEdit={setNewCustomGroup} setCustomGroupToRemove={setCustomGroupToRemove} type={1} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
			}
			{customGroupToEdit && customGroupToEdit["custom_group_type"] === "individual" &&
				<IndividualCustomGroupModal catalog={catalog} t={t} saveCustomGroup={updateCustomGroup} custom_group={customGroupToEdit} setCustomGroupToEdit={setCustomGroupToEdit} setCustomGroupToRemove={setCustomGroupToRemove} type={0} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
			}
			{newCustomGroup && newCustomGroup["custom_group_type"] === "individual" &&
				<IndividualCustomGroupModal catalog={catalog} t={t} saveCustomGroup={updateCustomGroup} custom_group={newCustomGroup} setCustomGroupToEdit={setNewCustomGroup} setCustomGroupToRemove={setCustomGroupToRemove} type={1} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
			}
			{customGroupToEdit && customGroupToEdit["custom_group_type"] === "commercial" &&
				<CommercialBannerModal catalog={catalog} t={t} saveCustomGroup={updateCustomGroup} custom_group={customGroupToEdit} setCustomGroupToEdit={setCustomGroupToEdit} setCustomGroupToRemove={setCustomGroupToRemove} type={0} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
			}
			{newCustomGroup && newCustomGroup["custom_group_type"] === "commercial" &&
				<CommercialBannerModal catalog={catalog} t={t} saveCustomGroup={updateCustomGroup} custom_group={newCustomGroup} setCustomGroupToEdit={setNewCustomGroup} setCustomGroupToRemove={setCustomGroupToRemove} type={1} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
			}
			{(!catalog || (!catalog?.supergroups?.length && !catalog?.product_categories?.length)) &&
				<div className=" bg-white rounded-xl h-36 flex gap-4 sm:flex-row font-semibold flex-col-reverse items-center justify-center">
					<img src={orderAndPayLogo} alt="Order and Pay"></img>
					<div style={{textAlign: 'center'}}>No se ha encontrado ningún producto para poder destacar.<br/>Debe sincronizar la carta.</div>
				</div>
			}
		</>
	)
}

export default ProductHighlight