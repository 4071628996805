import React from "react";
import { useTranslation } from "react-i18next";

const useDeleteModal = (doDelete, title = "borrar") => {
  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [itemValue, setItemValue] = React.useState(null);
  const toggleRef = React.useRef(null);

  const action = () => {
    doDelete(itemValue);
    setShowDeleteModal(false);
  };

  const openModal = (item) => {
    setItemValue(item);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const ModalButton = (props) => (
    <button
      onClick={() => setShowDeleteModal(true)}
      className="btn btn-primary modal-button"
    >
      {t("abre_modal")}
    </button>
  );

  const Modal = ({ mensaje, title }) => {
      return (
        <div>
          <input defaultChecked={showDeleteModal} type="checkbox" id="delete-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box">
              <p>{t(mensaje)}</p>
              <div className="modal-action">
                <button onClick={() => action()} className=" btn bg-red-500 border-transparent hover:bg-red-700 hover:border-transparent">
                  {t("ok")}
                </button>
                <button onClick={() => closeModal()} className="btn bg-secundario border-transparent">
                  {t("cerrar")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
  }

  return {
    Modal,
    ModalButton,
    openModal
  };
};

export default useDeleteModal;