import React from 'react';
import {useTranslation} from 'react-i18next';
import AgentsFilters from './AgentsFilters';
import MenuContainer from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';

const AgentsLayout = ({children}) => {
  const {t} = useTranslation();
  const [usuario, setUsuario] = React.useState(null);
  const user = localStorage.getItem("yumminn-user")
  const isRowBased = useMediaQuery("(max-width: 760px)");

  React.useEffect(() => {
    const user_obj = JSON.parse(localStorage.getItem('yumminn-user'));
    setUsuario(user_obj);
  }, [user]);
  
  return (
    <div className={`flex flex-row ${isRowBased ? 'bg-white' : 'bg-gray-100'} w-full h-full`}>
      <MenuContainer />
      <div className={'flex flex-col bg-gray-100 w-full h-full'}>
        {!isRowBased && <div className='bg-[#E2FEF1] flex justify-between align-center w-full'>
          <h1 className='font-bold text-[24px] px-[32px] py-5 text-[#094553] bg-[#E2FEF1] '>
            {t('Agents')}
          </h1>
        </div>}
        <div className={`${isRowBased ? 'bg-white' : 'bg-gray-100'} w-full h-full`}>
            { usuario && !usuario.is_investor && 
                <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] bg-white mb-5  ${!isRowBased && 'gap-5'}`}>
                    <>
                        <AgentsFilters />
                    </>
                </div>
            }
            <div className={'w-full'}>
              {children}
            </div>
          </div>
        </div>
    </div>
  );
};

export default AgentsLayout;
