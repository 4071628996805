import React, { useState } from 'react';
import { ColumnContainer } from '../ExampleContainers';
import { Pagination } from 'common/Pagination';

export const PaginationExample = () => {
  const [page, setPage] = useState(1);

  return (
    <ColumnContainer>
      <h2>Pagination</h2>
      <Pagination
        page={page}
        numOfPages={20}
        setPage={setPage}
        numOfEntries={100}
      />
      <Pagination
        page={2}
        numOfPages={3}
        numOfEntries={100}
        entryName='purchase'
        disabled
      />
      <Pagination
        page={1}
        numOfPages={1}
        numOfEntries={1}
      />
    </ColumnContainer>
  );
};
