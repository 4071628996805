import React from 'react';
import styled from "styled-components";
import { BookingsEmptyStateIcon, OrdersEmptyStateIcon } from 'common/EmptyStateIcons';
import EmptyState from '../../../../common/EmptyState.jsx';
import { useTranslation } from 'react-i18next';
import BookingRow from './BookingRow';
import BookingsHeader from './BookingsHeader';

const RowContainer = styled.div`
	cursor: pointer;
	user-select: none;
`;

const TableWrapper = styled.div`
    max-height: 450px;
    overflow-y: scroll;
`;

const BookingsTable = ({bookings, selectedBooking, setSelectedBooking}) => {
	const {t} = useTranslation();
	const handleSelect = (booking) => {
		setSelectedBooking(booking)
	}
	return (
	<div>
		<BookingsHeader opacity={Boolean(bookings?.length === 0)} />
		{bookings?.length > 0 ? 
			<TableWrapper> 
				{bookings?.map((booking, index) =>
					<RowContainer key={'booking_' + index} onClick={() => handleSelect(booking)}>
						<BookingRow booking={booking} selected={selectedBooking?.id === booking.id}/>
					</RowContainer>
				)} 
			</TableWrapper>
		:
			<TableWrapper> 
				<EmptyState text={t('No se han realizado reservas')} icon={<BookingsEmptyStateIcon />} />
			</TableWrapper>
		}
	</div>
  );
};
export default BookingsTable;
