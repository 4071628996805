import React, { useState } from "react";
import { FolderDownloadIcon, EyeIcon, ClockIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import Switch from 'react-switch';
import {  baseAuth } from "logic/api";
import { centsToAmountString } from "logic/functions";
import green_check from "../../../../img/check.svg";

const bgShades = ['bg-white','bg-white', 'bg-gray-100', 'bg-gray-100', 'bg-gray-200', 'bg-gray-200', 'bg-gray-300', 'bg-gray-300']
const borderShades = ['border-gray-200','border-gray-200', 'border-gray-200', 'border-gray-200', 'border-gray-100', 'border-gray-100', 'border-gray-1200', 'border-gray-1200']

const rowStyle = () => ({
	userSelect: "none",
	display: 'grid',
	alignContent: 'center',
	gridTemplateColumns: '1fr 1fr 2fr 1fr 1fr 1fr .5fr',
	width: '100%'

});

const PayoutRow = ({ payout, setPayoutToSee, indent, downloadPdf}) => {
  const { t } = useTranslation();
  const start_date = new Date(payout.period_start).toLocaleString('es-ES',{day: 'numeric', month:'short', year:'numeric'}).replaceAll("de", "")
  const end_date = new Date(payout.period_end).toLocaleString('es-ES',{day: 'numeric', month:'short', year:'numeric'}).replaceAll("de", "")
  const final_date = new Date(payout.scheduled_at).toLocaleString('es-ES',{day: 'numeric', month:'short', year:'numeric'}).replaceAll("de", "")

  indent++;

  return (
    <table className={'w-full'}>
      <tr
        className={`${bgShades[indent]} ${borderShades[indent]} border-b-2 border-gray-200 px-1`}
        style={rowStyle()}
      >
          <td style={{fontWeight: 600}} className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {centsToAmountString(payout.net_volume, true, true)}€
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            <div style={{padding: "0px 5px", borderRadius: "5px", display: 'flex', gap: '5px', backgroundColor: payout.status == "completed" ? "#D4F8D3" : "#EBEBEB", fontWeight: 550}} >
              <div>{payout.status == "completed" ? "Completado" : "Pendiente"}</div>
              {payout.status == "completed" ? 
              <img src={green_check} style={{width: '15px'}} />: 
              <ClockIcon className={`w-[15px]`}/>}
            </div>
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {start_date} - {end_date}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {final_date}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            ****{payout.iban.substr(payout.iban.length - 4)}
          </td>
          <td className={`h-full text-center text-xm font-normal py-2 items-center flex justify-center `}>
            {payout.payment_method == "bizum" ? "Bizum" : "Todos"}
          </td>
          <td className={`h-full text-start text-xm font-normal py-2 items-center justify-center flex`}>
            <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <EyeIcon 
              onClick={() => setPayoutToSee(payout)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
              />
            <FolderDownloadIcon
              onClick={() => downloadPdf(payout, 0)}
              className={`w-[20px] text-gray-700 cursor-pointer transition-all animate-fade-in-display delay-75 `}
            />  
              </div>
          </td>        
      </tr>
    </table>
  )
};

export default PayoutRow