
// WIP NOT FINISHED

import { difference } from 'lodash';
import { orderAndPay } from 'logic/api';
import { toast } from 'react-toastify';

export const ObjectTypes = {
  SYNCHRONIZE: {
    TYPE: 'synchronize',
    ENDPOINT: '/synchronize',
    SUCCESS: 'Updated_group',
    ERROR: 'Error_synchronize',
  },
  SUPER_GROUP: {
    TYPE: 'super_group',
    ENDPOINT: '/category_supergroup',
    SUCCESS: 'Updated_supergroup',
    DELETE: 'Deleted_supergroup',
    ERROR: 'Error_saving_supergroup',
  },
  CATEGORY_GROUP: {
    TYPE: 'category_group',
    ENDPOINT: '/category_group',
    SUCCESS: 'Updated_group',
    DELETE: 'Deleted_group',
    ERROR: 'Error_saving_group',
  },
  CATEGORY: {
    TYPE: 'category',
    ENDPOINT: '/product_category',
    SUCCESS: 'Updated_category',
    DELETE: 'Deleted_category',
    ERROR: 'Error_saving_category',
  },
  PRODUCT: {
    TYPE: 'product',
    ENDPOINT: '/product',
    SUCCESS: 'Saved_product',
    DELETE: 'Deleted_product',
    ERROR: 'Error_saving_product',
  },
  MODIFIER: {
    TYPE: 'modifier',
    ENDPOINT: '/modifier',
    SUCCESS: 'Updated_modifier',
    DELETE: 'Deleted_modifier',
    ERROR: 'Error_saving_modifier',
  },
  PRODUCT_MODIFIER_CATEGORY: {
    TYPE: 'modifier_category',
    ENDPOINT: '/product_modifier_category',
    SUCCESS: 'Updated_modifier',
    DELETE: 'Deleted_modifier',
    ERROR: 'Error_saving_modifier',
  },
  MODIFIER_MODIFIER_CATEGORY: {
    TYPE: 'modifier_category',
    ENDPOINT: '/modifier_modifier_category',
    SUCCESS: 'Updated_modifier',
    DELETE: 'Deleted_modifier',
    ERROR: 'Error_saving_modifier',
  },
};

const PARENT_FIELDS = {
  SUPER_GROUP: null,
  CATEGORY_GROUP: 'category_supergroup',
  CATEGORY: 'category_group',
  PRODUCT: 'category',
};

const CHILD_TYPES = {
  SUPER_GROUP: 'CATEGORY_GROUP',
  CATEGORY_GROUP: 'CATEGORY',
  CATEGORY: 'PRODUCT',
};

export const postObject = async ({ objectType, object, t }) => {
  if (objectType) {
    return await orderAndPay.post(objectType.ENDPOINT, object)
      .then((response) => {
        toast.success(`${t(objectType.SUCCESS)}`);
        return response;
      })
      .catch(error => {
        toast.error(`${t(objectType.ERROR)}`);
        console.log("🚀 ~ file: api-OrderAndPay.js:62 ~ postObject ~ error:", error)
        throw error;
      });
  }
}

export const patchObject = async ({ objectType, object, t }) => {

  if (objectType) {
    return await orderAndPay.patch(objectType.ENDPOINT, object)
      .then((response) => {
        toast.success(`${t(objectType.SUCCESS)}`);
        return response;
      })
      .catch(error => {
        toast.error(`${t(objectType.ERROR)}`);
        console.log("🚀 ~ file: api-OrderAndPay.js:75 ~ patchObject ~ error:", error)
      });
  }
}

export const deleteObject = async ({ objectType, object, t }) => {
  if (objectType) {
    const body = { id: object.id };

    return await orderAndPay.delete(objectType.ENDPOINT, { data: body })
      .then((response) => {
        toast.success(`${t(objectType.SUCCESS)}`);
        return response;
      })
      .catch(error => {
        toast.error(`${t(objectType.ERROR)}`);
        console.log("🚀 ~ file: api-OrderAndPay.js:92 ~ deleteObject ~ error:", error)
      });
  }
}

export const patchOrderAndParent = async ({ objectType, object, t }) => {
  try {
    const parentField = ['category_supergroup', 'category_group', 'category'].find(key => key in object);
  
    if (parentField) {
      const parentData = { id: object.id, [parentField]: object[parentField] };
      await orderAndPay.patch(objectType.ENDPOINT, parentData);
    }
    
    if ('order' in object /*&& object.order !== 0*/) {
      const orderData = { id: object.id, order: object.order };
      await orderAndPay.patch(objectType.ENDPOINT, orderData);
    }
  
    toast.success(`${t(objectType.SUCCESS)}`);
  } catch (error) {
    console.log("🚀 ~ file: api-OrderAndPay.js:125 ~ patchOrderAndParent ~ error:", error)
    toast.error(`${t(objectType.ERROR)}`);
  }
};

const moveManyItems = async ({ objectType, body, t }) => {
  if (!objectType) return;

  await orderAndPay.patch(`/move_many_items/${objectType.TYPE}`, body)
    .catch(error => {
      toast.error(`${t(objectType.ERROR)}`);
      console.log("🚀 ~ file: api-OrderAndPay.js:151 ~ moveManyItems ~ error:", error);
    });
};

export const saveProductImage = async ({id, file, type, t}) => {
  let fd = new FormData();
  
  fd.append("image", file);
  fd.append("item_id", id);
  fd.append("type", type);

  return await orderAndPay(`/product_image`, {
    method: "PATCH",
    "Content-Type": "multipart/form-data",
    data: fd,
  }).then(res => {
    return res;
  }).catch(error => {
    console.log(error);
    toast.error(`${t("Error_saving_image")}`);
  });
};

export const saveGroup = async ({
  endpoint,
  group,
  restaurant,
  groupType,
  parentId,
  groupToEdit,
  groupChildren,
  schedulesToDelete,
  timeRangesToDelete,
  t
}) => {
  let newGroupId;
  
  const object = {
    ...group,
    restaurant: restaurant,
    [PARENT_FIELDS?.[groupType]]: parentId,
  };
  
  try {
    if (!groupToEdit) {
      const response = await postObject({ objectType: ObjectTypes[groupType], object, t });
      newGroupId = await response?.data.id;
      object.id = newGroupId;

      if (groupType !== 'PRODUCT') {
        const type = CHILD_TYPES[groupType];
        const objectType = ObjectTypes[type];

        const body = {
          ids: groupChildren.map(child => child.value),
          new_parent: { [PARENT_FIELDS[type]]: newGroupId },
        };

        await moveManyItems({ objectType, body, t });
      }
    } else {
      await patchObject({ objectType: ObjectTypes[groupType], object, t });
    }

    if (groupType === 'PRODUCT' && object.imageFile) {
      await saveProductImage({id:object.id, file: object.imageFile, type: 'product', t});
    }

    const schedulesToUpdate = difference(group?.visibility_schedules, groupToEdit?.visibility_schedules)
      .filter((schedule => !schedulesToDelete.includes(schedule.id)));

    if (group?.visibility_schedules && schedulesToUpdate.length) {
      const updateSchedules = schedulesToUpdate.map((async (schedule) => {
        const scheduleBody = {
          ...schedule,
          [endpoint]: group.id || newGroupId
        };

        try {
          const { data } = await orderAndPay[scheduleBody.id ? 'patch' : 'post'](`/${endpoint}_visibility_schedule`, scheduleBody);

          if (!schedule?.time_ranges.length) return;

          const timeRangesToUpdate = schedule.time_ranges.filter((timeRange => !timeRangesToDelete.includes(timeRange.id)));
          const updateTimeRanges = timeRangesToUpdate.map(range => {
            const rangeBody = {
              ...range,
              [endpoint + "_schedule"]: schedule.id || data.id
            };

            return orderAndPay[rangeBody.id ? 'patch' : 'post'](`${endpoint}_visibility_time_range`, rangeBody);
          });

          await Promise.all(updateTimeRanges);
        }

        catch (error) {
          toast.error(`${t('Error_creating_schedule')}`);
          console.log("🚀 ~ file: CreateEditGroupModal.jsx:141 ~ returnorderAndPay.post ~ error:", error);
        }
      }));

      await Promise.all(updateSchedules);
    }

    if (schedulesToDelete.length) {
      const deleteSchedules = schedulesToDelete.map(schedule => {
        return orderAndPay.delete(`/${endpoint}_visibility_schedule`, { data: { id: schedule } })
          .catch((error) => {
            console.log(error);
            toast.error(`${t('Error_deleting_schedule')}`);
          });
      });

      await Promise.all(deleteSchedules);
    }

    if (timeRangesToDelete) {
      const deleteRanges = timeRangesToDelete.map(range => {
        return orderAndPay.delete(`${endpoint}_visibility_time_range`, { data: { id: range } })
          .catch((error) => {
            console.log(error);
            toast.error(`${t('Error_deleting_time_range')}`);
          });
      });

      await Promise.all(deleteRanges);
    }

    return object.id;
  } catch (error) {
    console.log("🚀 ~ file: CreateEditGroupModal.jsx:237 ~ save ~ error:", error);
    throw error;
  }
};

export const generateNewCatalog = async ({ restaurant, setProgress, t }) => {
  setProgress && setProgress(0);
  try {
    const baseGroup = { restaurant, name: 'Todos', visibility: 0 };
    const l1Response = await postObject({ objectType: ObjectTypes.SUPER_GROUP, object: baseGroup, t });
    const l1Id = l1Response?.data?.id;

    if (!l1Id) throw new Error(ObjectTypes.SUPER_GROUP.ERROR);
    
    setProgress && setProgress(33);
    
    const l2Object = { ...baseGroup, [PARENT_FIELDS.CATEGORY_GROUP]: l1Id }
    const l2Response = await postObject({ objectType: ObjectTypes.CATEGORY_GROUP, object: l2Object, t });
    const l2Id = l2Response?.data?.id;

    if (!l2Id) throw new Error(ObjectTypes.CATEGORY_GROUP.ERROR);
    
    setProgress && setProgress(66);
    
    const l3Object = { ...baseGroup, [PARENT_FIELDS.CATEGORY]: l2Id }
    const l3Response = await postObject({ objectType: ObjectTypes.CATEGORY, object: l3Object, t });
    const l3Id = l3Response?.data?.id;

    if (!l3Id) throw new Error(ObjectTypes.CATEGORY.ERROR);

    setProgress && setProgress(100);
  } catch (error) {
    console.error(error);
    toast.error(t(error));
    setProgress(null);
  }
};