import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { baseAuth } from 'logic/api';
import { Context as AuthContext } from '../../../../data/authContext'
import SmallSpinner from 'common/SmallSpinner';

const inputWidth = 348;

const OrderSearchInputWrapper = styled.div`
	input:focus {
		outline: none;
		border: 3px solid rgb(95, 184, 148);
	}
`;
const CustomInput = styled.input`
	z-index: 5;
	color: #000;
	height: 41px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 8px;
	border-radius: 8px;
	position: relative;
	width: ${inputWidth}px;
	border: 2px solid #D4D4D8;
	font-family: Poppins, sans-serif;
`;
const SmallSpinnerWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;
const ErrorPerformingSearchText = styled.p`
	color: red;
	font-size: 12px;
	margin-top: 12px;
	margin-bottom: 8px;
	text-align: center;
`;
const NoMatchesFoundText = styled.p`
	font-size: 12px;
	margin-top: 12px;
	margin-bottom: 8px;
	text-align: center;
`;
const RecommendationsWrapper = styled.div`
	z-index: 4;
	padding: 5px;
	position: absolute;
	width: ${inputWidth}px;
	background-color: white;
	border: 2px solid #D4D4D8;
	transform: translateY(-6px);
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;
const Recommendation = styled.div`
	padding: 7px;	
	margin-top: 4px;
	font-size: 12.5px;
	border-radius: 4px;

	&:hover {
		cursor: pointer;
	}
	&.recommendationHovered {
		background-color: rgb(95, 184, 148);
	}
	&.recommendationHovered > span {
		color: white;
	}
	&.recommendationHovered > span:first-child {
		background-color: rgb(115, 204, 168);
	}
`;
const RecommendationType = styled.span`
	padding-top: 2px;
	font-size: 11.5px;
	margin-right: 5px;
	padding-left: 4px;
	border-radius: 4px;
	padding-right: 4px;
	padding-bottom: 2px;
	color: hsl(0, 0%, 20%);
	background-color: rgb(218, 249, 245);
`;
const RecommendationValue = styled.span`
	color: hsl(0, 0%, 20%);
`;

const OrderSearchInput = ({addFilter}) => {

	const { state: { selectedRestaurant } } = useContext(AuthContext);
	const { t } = useTranslation();
	const inputRef = useRef(null);

	const [getRecommendationsAgain	, setGetRecommendationsAgain  ] = useState(false);
	const [inputFocused				, setInputFocused			  ] = useState(false);
	const [loading					, setLoading				  ] = useState(false);
	const [noMatchingRecommendations, setNoMatchingRecommendations] = useState(false);
	const [errorPerformingSearch	, setErrorPerformingSearch	  ] = useState(false);
	const [selectedRecommendation	, setSelectedRecommentation	  ] = useState(null);
	const [recommendations			, setRecommendations		  ] = useState([]);
	const [inputValue				, setInputValue				  ] = useState('');

	const getRecommendationsAgainRef = useRef(getRecommendationsAgain);
	const inputValueRef 			 = useRef(inputValue);
	const loadingRef				 = useRef(loading);
	const selectedRestaurantRef 	 = useRef(selectedRestaurant);
	getRecommendationsAgainRef.current = getRecommendationsAgain;
	inputValueRef		 	  .current = inputValue;
	loadingRef			 	  .current = loading;
	selectedRestaurantRef	  .current = selectedRestaurant;

	const recommendationTypeToString = {
		'address':      t('Address'),
		'client_name':  t('Client_name'),
		'client_phone': t('Phone'),
		'client_email': t('Email_short'),
		'code':         '#' + t('Order'),
	};

	const inputKeyDown = event => {
		const recommendationsLength = recommendations ? recommendations.length : 0;
		if (recommendationsLength > 0) {
			if (event.key === "ArrowDown") {
				const setToFirst = selectedRecommendation == null || selectedRecommendation >= recommendationsLength - 1;
				setSelectedRecommentation(setToFirst ? 0 : selectedRecommendation + 1);
			} else if (event.key === "ArrowUp") {
				const setToLast = selectedRecommendation == null || selectedRecommendation <= 0;
				setSelectedRecommentation(setToLast ? recommendationsLength - 1 : selectedRecommendation - 1);
			} else if (event.key === "Escape") {
				inputRef.current.blur();
			} else if (event.key === "Enter" && selectedRecommendation !== null && selectedRecommendation > -1 && selectedRecommendation < recommendations?.length) {
				const recommendationToAdd = recommendations[selectedRecommendation];
				//console.log(recommendationToAdd, 'recommendationToAdd');
				if (recommendationToAdd) {
					addRecommendation(recommendationToAdd);
				}
			}
		}
	};
	const handleChange = event => {
		//console.log(event.target.value);
		setInputValue(event.target.value);
	};
	const inputOnBlur = () => {
		/*setTimeout(() => {
		}, 200);*/
		setSelectedRecommentation(null);
		setInputFocused(false);
		setNoMatchingRecommendations(false);
		setErrorPerformingSearch(false);
	};
	const addRecommendation = recommendation => {
		addFilter(recommendation);
		setInputValue('');
		setSelectedRecommentation(null);
	};

	const getRecommendations = () => {
		if (!inputValueRef.current || !selectedRestaurantRef.current) {
			return;
		} else if (loadingRef.current) {
			if (!getRecommendationsAgainRef.current) {
				setGetRecommendationsAgain(true);
			}
			return;
		} else if (getRecommendationsAgainRef.current) {
			setGetRecommendationsAgain(false);
		}
		//console.log(`inputValueRef.current: ${inputValueRef.current}`);
		setLoading(true);
		setErrorPerformingSearch(false);
		setNoMatchingRecommendations(false);
		const ids = selectedRestaurantRef.current.map(res => res.value);
		const idsString = JSON.stringify(ids);
		const requestInputValue = inputValueRef.current;
		baseAuth.get(`/product/orders_recommendations?restaurants=${idsString}&search_input=${requestInputValue}`).then((res)=>{
			//console.log(res.data);
			if (res?.data?.length > 0) {
				setRecommendations(res.data);
			} else {
				setRecommendations([]);
				setNoMatchingRecommendations(true);
			}
        }).catch(err => {
			setErrorPerformingSearch(true);
			//console.error(err);
		})
		.finally(() => {
			setLoading(false);
			if (getRecommendationsAgainRef.current) {
				if (inputValueRef.current != requestInputValue) {
					//console.log("getting recommendations again");
					getRecommendations();
				} else {
					//console.log("inputValue is the same, not getting recommendations again");
					setGetRecommendationsAgain(false);
				}
			}
		});
	};

	useEffect(() => {
		//console.log(`inputValue: ${inputValue}`);
		getRecommendations();
	}, [inputValue]);
	
	return (
		<OrderSearchInputWrapper>
			<div style={{fontSize: "16px", fontWeight: 500}}>{t('search_by')}</div>
			<CustomInput
				ref={inputRef}
				placeholder={t('order_search_input_placeholder')}
				value={inputValue}
				onChange={handleChange}
				onFocus={() => setInputFocused(true)}
				onBlur={inputOnBlur}
				onKeyDown={inputKeyDown}
				disabled={selectedRestaurant?.length <= 0}
			/>
			{inputValue?.length > 0 && inputFocused && (
				<RecommendationsWrapper>
					{loading ? (
						<SmallSpinnerWrapper>
							<SmallSpinner style={{alignSelf: 'center', width: '20px', height: '20px', margin: '8px'}} />
						</SmallSpinnerWrapper>
					) : (
						<>
							{errorPerformingSearch ? (
								<ErrorPerformingSearchText>{t('error_performing_search')}</ErrorPerformingSearchText>
							) : (
								<>
									{noMatchingRecommendations ? (
										<NoMatchesFoundText>{t('no_matches_found')}</NoMatchesFoundText>
									): (
										<>
											{recommendations.map((recommendation, index) => (
												<Recommendation
													key={'recommendation_' + index}
													onMouseOver={() => setSelectedRecommentation(index)}
													className={selectedRecommendation == index ? 'recommendationHovered' : ''}
													onMouseDown={() => addRecommendation(recommendation)}
												>
													<RecommendationType>{recommendationTypeToString[recommendation.type] + ":"}</RecommendationType>
													<RecommendationValue>{recommendation.value}</RecommendationValue>
												</Recommendation>
											))}
										</>
									)}
								</>
							)}
						</>
					)}
				</RecommendationsWrapper>
			)}
		</OrderSearchInputWrapper>
	);
};
export default OrderSearchInput;
