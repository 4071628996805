
export const STYLES = {
    SUPERGROUP: 'text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] border border-[#404040] text-[#FFFFFF] bg-[#5E5E5E] box-border whitespace-nowrap ',
    SELECTED_SUPERGROUP: 'text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px]  bg-[#CEF5E5] !border-[#404040] text-[#404040] ',
    GROUP: 'bg-[#F0F0F0] text-[#909090] border border-[#A0A0A0]  text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] box-border whitespace-nowrap flex items-center gap-[2px] ',
    COLAPSED_GROUP: 'bg-white text-[#404040] border border-[#404040] text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] box-border whitespace-nowrap flex items-center gap-[2px] ',
    EXPANDED_GROUP: 'bg-[#CEF5E5] text-black text-[#404040]  border border-[#404040] text-[8px] leading-[1em] font-medium rounded-full py-[3px] px-[4px] box-border whitespace-nowrap flex items-center gap-[2px] ',
    CATEGORY: 'text-[8px] bg-[#F0F0F0]  rounded-full leading-[1em] font-medium rounded-full p-[4px] whitespace-nowrap ',
    OVERLAY: 'w-full h-full bg-[#FFF8] absolute inset-0 fade-in ',
    SELECTED_CATEGORY: 'text-[8px] bg-[#CEF5E5] text-[#404040] rounded-full leading-[1em] font-medium rounded-full p-[4px] whitespace-nowrap ',
    EXTRA_OVERLAY: 'w-full h-full bg-[#020203] bg-opacity-70 absolute inset-0 fade-in ',
};