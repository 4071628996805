import { motion } from 'framer-motion';
import { apiAuth } from 'logic/api';
import { sayError } from 'logic/defaults';
import React, {useEffect} from 'react';
import { toast } from 'react-toastify';
import { Context } from 'data/authContext';

const TableStatusesPage = () => {
	const {
		state: { selectedRestaurantId },
	} = React.useContext(Context);
  
  const [sectores, setSectores] = React.useState([]);

  const getData = async () => {
    try {
      const res_obj = await apiAuth.get(
        `/sectors/?restaurant=${selectedRestaurantId}`
      );
      const res_data = res_obj.data.results;
      setSectores(res_data);
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  useEffect(() => {
    //
    if (selectedRestaurantId) {
      getData();
    }
  }, [selectedRestaurantId]);

  return (
    <div className={'flex flex-row flex-wrap'}>
      {/* * */}
      {/* formularios de sectores */}
      {/* * */}
      {sectores.map((sector, index) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          key={index}
          className='mb-5 mr-3 bg-white p-3 rounded-lg min-w-[420px] mt-14'
        >
          <div key={index} className='font-semibold text-xl py-4'>
            {sector.name}
          </div>
          <div className='flex flex-row flex-wrap p-3'>
            {sector.tables.map((table, index) => {
              return (
                <div
                  key={index}                  
                  className='w-[125px] items-center mb-5 mr-3 bg-green-100 p-3 rounded-lg '
                  style={{background: '#DCFCE7', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
                >
                  <div className="text-sm">{table.name}</div>
                  <div className="text-sm">{table.number}</div>
                </div>
              );
            })}
          </div>
        </motion.div>
      ))}{' '}
    </div>
  );
};

export default TableStatusesPage;
