import { select_styles } from 'logic/defaults';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Context as AuthContext } from '../../data/authContext';
import "./DateSelect.css";
import {useMediaQuery} from '../../hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { components } from "react-select";
import './RestaurantSelect.css'

export default function CompanySelect() {
  const { t } = useTranslation();
  const {
    state: { companies, selectedCompany },
    setSelectedCompany,
  } = React.useContext(AuthContext);
  const [companiesList, setCompaniesList] = React.useState(null);
  const [seleccionado, setSeleccionado] = React.useState('');
  const user = JSON.parse(localStorage.getItem("yumminn-user"));
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const history = useHistory();

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null()}
          />{" "}
          <span></span>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  React.useEffect(() => {
    const seleccionado =
      JSON.parse(localStorage.getItem('yumminn-selectedCompany')) || selectedCompany 
    if (seleccionado) {
      setSeleccionado(seleccionado);
    }
    let empresas = companies || JSON.parse(localStorage.getItem('yumminn-companies'));
    if (empresas) {
      empresas = empresas.filter((el) => !el.label.includes("DEMO"))
      setCompaniesList(empresas);
    }

  }, [selectedCompany, companies]);


  const handleSelect = (opcion) => {
      location === '/home' || location === '/admin/dashboard' || location === '/admin/reports' ? setSelectedCompany(opcion) : setSelectedCompany([opcion])
      setSeleccionado(opcion);
  };

  const location = history.location.pathname
  
  return (
    <div className={`flex items-center space-x-2 ${!isRowBased ? " my-3 w-2/3 ": " w-full "}  ${isRowBased && 'justify-center'}`} style={{fontFamily: 'Poppins, sans-serif', fontSize: isRowBased ? "14px" : "16px", fontWeight: 700, display: user.is_investor ? "none" : ""}}>
      <div>{t('Companies')}</div>
      <Select
        isMulti={location === '/home' || location === '/admin/dashboard' || location === '/admin/reports' ? true : false}
        menuPortalTarget={document.body}
        multiple={true}
        isClearable={false}
        components={{
          Option
        }}
        closeMenuOnSelect={false}
        styles={select_styles}
        hideSelectedOptions={false}
        className={isRowBased ? '' : 'w-full max-w-md text-[14px]'}
        name={'restaurante'}
        placeholder={t(`Empezar a escribir restaurante...`)}
        options={companiesList}
        value={seleccionado === null ? "" : seleccionado}
        onChange={(a) => {
          handleSelect(a);
        }}
      />
    </div>
  );
}
