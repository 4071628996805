import React from 'react';
import { useTranslation } from "react-i18next";

const UpdateTablesInfo = ({new_tables, deactivated_tables, new_active_tables, setShowUpdateInfo}) => {

  const { t } = useTranslation();
  console.log(new_tables)
  return (
	<div style={{width: '100%', overflowY: 'scroll', marginTop: "25px", fontSize: "13px", fontWeight: 500, backgroundColor: "#F8F8F8DA", padding: "15px 20px", borderRadius: "10px"}}>
		<div style={{display: 'flex', gap: "20px", justifyContent: 'space-around'}}>
			{new_tables.length > 0 &&
			<div>
				<div style={{fontSize: '15px', fontWeight: 600, marginBottom: "10px"}}>{t("Mesas nuevas")}</div>
				<ul>
					{new_tables.map((new_table, index) => (
						<li style={{listStyle: 'circle'}}>{new_table}</li>
					))}
				</ul>
			</div>}
			{new_active_tables.length > 0 &&
			<div>
				<div style={{fontSize: '15px', fontWeight: 600, marginBottom: "10px"}}>{t("Mesas reactivadas")}</div>
				<ul>
					{new_active_tables.map((new_active_table, index) => (
					<li style={{listStyle: 'circle'}}>{new_active_table}</li>
					))}
				</ul>
			</div>}
			{deactivated_tables.length > 0 &&
			<div>
				<div style={{fontSize: '15px', fontWeight: 600, marginBottom: "10px"}}>{t("Mesas desactivadas")}</div>
				<ul>
				{deactivated_tables.map((deactivated_table, index) => (
					<li style={{listStyle: 'circle'}}>{deactivated_table}</li>
				))}
				</ul>
			</div>}
		</div>
	</div>
  );
};
export default UpdateTablesInfo;
