import React from 'react';
import { thousandsSeparator } from 'logic/functions';
import {centsToAmountString} from "../../logic/functions";
import TableCard from 'components/yumminn/TableCard';

const LoyaltyTable = ({revenue, revenueCount, loyalty}) => {

    //revenue
    const revenueSum = revenue?.table_data;

    const totalActual = thousandsSeparator(parseInt(revenueSum?.total_actual || 0));
    const totalPrev = thousandsSeparator(parseInt(revenueSum?.total_prev || 0));
    const diffPaymentVolumeAvg = revenueSum?.diff || 0;

    const totalWithLoyaltyActual = thousandsSeparator(parseInt(revenueSum?.total_with_loyalty_actual || 0));
    const totalWithLoyaltyPrev = thousandsSeparator(parseInt(revenueSum?.total_with_loyalty_prev || 0));
    const diffPaymentsWithLoyalty = revenueSum?.loyalty_diff || 0;

    const percentPaymentsWithLoyaltyActual = revenueSum?.percent_with_loyalty_actual || 0;
    const percentPaymentsWithLoyaltyPrev = revenueSum?.percent_with_loyalty_prev || 0;
    const diffPercentPaymentsWithLoyalty = revenueSum?.percent_with_loyalty_diff || 0;

    //revenueCount
    const revenueTotalCount =  revenueCount?.table_data;

    const totalActualRevenueCount = revenueTotalCount?.total_actual || 0;
    const totalPrevRevenueCount = revenueTotalCount?.total_prev || 0;
    const diffRevenueCount = revenueTotalCount?.diff || 0;

    const totalActualRevenueCountWithLoyalty = revenueTotalCount?.total_with_loyalty_actual || 0;
    const totalPrevRevenueCountWithLoyalty = revenueTotalCount?.total_with_loyalty_prev || 0;
    const diffRevenueCountWithLoyalty = revenueTotalCount?.loyalty_diff || 0;

    const actualPercentTransactionsWithLoyalty = revenueTotalCount?.percent_with_loyalty_actual || 0;
    const PrevPercentTransactionsWithLoyalty = revenueTotalCount?.percent_with_loyalty_prev || 0;
    const diffPercentTransactionsWithLoyalty = revenueTotalCount?.percent_with_loyalty_diff || 0;

    //ticket
    const avgTicketActual = thousandsSeparator(parseFloat(centsToAmountString(revenueSum?.avg_ticket || 0)));
    const avgTicketPrev = thousandsSeparator(parseFloat(centsToAmountString(revenueSum?.avg_ticket_prev || 0)));
    const diffTicketAvg = parseInt(avgTicketActual.toString().replace(/\./g, '')) > 0 && parseInt(avgTicketPrev.toString().replace(/\./g, '')) > 0 ? (((parseInt(avgTicketActual.toString().replace(/\./g, '')) - parseInt(avgTicketPrev.replace(/\./g, ''))) / parseInt(avgTicketPrev.replace(/\./g, ''))) * 100).toFixed(2): 0

    // Loyalty
    const actualUsersWithLoyalty = loyalty?.users_with_loyalty || 0;
    const prevUsersWithLoyalty = loyalty?.users_with_loyalty_prev || 0;
    const diffUsersWithLoyalty = loyalty?.users_with_loyalty_diff || 0;

    const actualTransactionsPerUser = loyalty?.transactions_with_loyalty_per_user || 0;
    const prevTransactionsPerUser = loyalty?.transactions_with_loyalty_per_user_prev || 0;
    const diffTransactionsPerUser = loyalty?.transactions_with_loyalty_per_user_diff || 0;

    const actualClientsWhoRepeated = loyalty?.clients_who_repeated_actual || 0;
    const prevClientsWhoRepeated = loyalty?.clients_who_repeated_prev || 0;
    const diffClientsWhoRepeated = loyalty?.clients_who_repeated_diff || 0;
    
    const actualLoyaltyGranted = thousandsSeparator(Number(loyalty?.total_generated || 0));
    const prevLoyaltyGranted = thousandsSeparator(Number(loyalty?.total_generated_prev || 0));
    const diffLoyaltyGranted = loyalty?.loyalty_generated_diff  || 0;

    const actualLoyaltyUsed = thousandsSeparator(Number(loyalty?.total_used || 0));
    const prevLoyaltyUsed = thousandsSeparator(Number(loyalty?.total_used_prev || 0));
    const diffLoyaltyUsed = loyalty?.loyalty_used_diff || 0;

    const actualLoyaltyUnused = thousandsSeparator(Number(loyalty?.total_unused || 0));
    const prevLoyaltyUnused = thousandsSeparator(Number(loyalty?.total_unused_prev || 0));
    const diffLoyaltyUnused = loyalty?.loyalty_unused_diff || 0;

    const rowsData = [
        {
            label: 'payments',
            actualValue: totalActual.toString().replace(/,00/g, '') + ' €',
            previousValue: totalPrev.toString().replace(/,00/g, '') + ' €',
            variation: diffPaymentVolumeAvg,
            isGray: true,
        },
        {
            label: 'loyalty_payments',
            actualValue: totalWithLoyaltyActual.toString().replace(/,00/g, '') + ' €',
            previousValue: totalWithLoyaltyPrev.toString().replace(/,00/g, '') + ' €',
            variation: diffPaymentsWithLoyalty,
            isGray: true,
        },
        {
            label: 'percent_loyalty_payments',
            actualValue: percentPaymentsWithLoyaltyActual + ' %',
            previousValue: percentPaymentsWithLoyaltyPrev + ' %',
            variation: diffPercentPaymentsWithLoyalty,
            isGray: true,
        },
        {
            label: 'transactions',
            actualValue: thousandsSeparator(parseInt(totalActualRevenueCount)).split(',00'),
            previousValue: thousandsSeparator(parseInt(totalPrevRevenueCount)).split(',00'),
            variation: diffRevenueCount,
        },
        {
            label: 'loyalty_transactions',
            actualValue: thousandsSeparator(parseInt(totalActualRevenueCountWithLoyalty)).split(',00'),
            previousValue: thousandsSeparator(parseInt(totalPrevRevenueCountWithLoyalty)).split(',00'),
            variation: diffRevenueCountWithLoyalty,
        },
        {
            label: 'percent_loyalty_transactions',
            actualValue: actualPercentTransactionsWithLoyalty + ' %',
            previousValue: PrevPercentTransactionsWithLoyalty + ' %',
            variation: diffPercentTransactionsWithLoyalty,
        },
        {
            label: 'users_with_loyalty',
            actualValue: actualUsersWithLoyalty,
            previousValue: prevUsersWithLoyalty,
            variation: diffUsersWithLoyalty,
            isGray: true,
        },
        {
            label: 'transactions_with_loyalty_per_user',
            actualValue: actualTransactionsPerUser,
            previousValue: prevTransactionsPerUser,
            variation: diffTransactionsPerUser,
            isGray: true,
        },
        {
            label: 'users_who_repeated',
            actualValue: actualClientsWhoRepeated,
            previousValue: prevClientsWhoRepeated,
            variation: diffClientsWhoRepeated,
            isGray: true,
        },
        {
            label: 'avg_ticket',
            actualValue: avgTicketActual.replace(/,00/g, '') + ' €',
            previousValue: avgTicketPrev.replace(/,00/g, '') + ' €',
            variation: diffTicketAvg,
        },
        {
            label: 'granted_credits',
            actualValue: actualLoyaltyGranted.replace(/,00/g, '') + ' €',
            previousValue: prevLoyaltyGranted.replace(/,00/g, '') + ' €',
            variation: diffLoyaltyGranted,
            isGray: true,
        },
        {
            label: 'used_credits',
            actualValue: actualLoyaltyUsed.replace(/,00/g, '') + ' €',
            previousValue: prevLoyaltyUsed.replace(/,00/g, '') + ' €',
            variation: diffLoyaltyUsed,
            isGray: true,
        },
        {
            label: 'unused_credits',
            actualValue: actualLoyaltyUnused.replace(/,00/g, '') + ' €',
            previousValue: prevLoyaltyUnused.replace(/,00/g, '') + ' €',
            variation: diffLoyaltyUnused,
            isGray: true,
        },
    ]

    return(
        <TableCard
        rows={rowsData}
        />
    );
};

export default LoyaltyTable;



