import React from 'react';
import MenuContainer from 'layouts/MenuContainer';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { SubsidiesContextProvider } from './SubsidiesContext';

const SubsidiesContainer = ({ children }) => {
  const isRowBased = useMediaQuery("(max-width: 760px)");

  const history = useHistory();

  return (
    <div id="subsidiesContainer" className={`flex flex-col bg-gray-100 h-full max-h-screen w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex: ' 0 1 auto', position: 'fixed', overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        <MenuHeader title={'subsidies'}/>
        <div className='flex gap-4 bg-white p-4 items-end justify-between'>
          <RestaurantSelect/>
          <Button
            label='manage_coupons'
            onClick={() => history.push('/discounts')}
            variant={BUTTON_VARIANTS.SECONDARY}
          />
        </div>
        <SubsidiesContextProvider>
          {children}
        </SubsidiesContextProvider>
      </div>
    </div>
  );
};

export default SubsidiesContainer;