import { routes } from "./AdminRoutes";
import { Icon, IconType } from "common/Icon";


export const admin_routes = (t, tabs, allowedRoutes) => {
    const routeList = [
        ...routes(t, tabs, allowedRoutes), 
        {
            icon: <Icon type={IconType.EYE} />,
            title: t('Agents'),
            path: '/agents'
        },
        {
            icon: <Icon type={IconType.BUILDING} />,
            title: t('Companies'),
            path: '/admin/companies'
        },
        {
            icon: <Icon type={IconType.HOUSE} />,
            title: t('Restaurants'),
            path: '/admin/restaurants'
        },
        {
            icon: <Icon type={IconType.INBOX} />,
            title: t('Sales'),
            path: '/admin/sales'
        },
    ];

    const filterRoutes = (routes) => {
        if(allowedRoutes.length === 0) return routeList;

        return routes.reduce((acc, route) => {
            // Check if the current route's path is in allowedRoutes
            const isParentAllowed = allowedRoutes.includes(route.path);

            // Filter the children based on allowedRoutes
            const filteredChildren = route.children 
                ? filterRoutes(route.children) // Recursively filter children
                : [];

            // If the parent route is allowed or has valid children, include it in the results
            if (isParentAllowed || filteredChildren.length > 0) {
                acc.push({
                    ...route,
                    // Include only filtered children if they exist
                    ...(filteredChildren.length > 0 ? { children: filteredChildren } : {}),
                });
            }

            return acc;
        }, []);
    };

    return filterRoutes(routeList);
};




export const bottomRoutes = ({ handleLogout, t, allowedRoutes }) => {
    const routeList = [
        {
            icon: <Icon type={IconType.CIRCLE_LIST}/>,
            title: t('access'),
            children: [
                {
                    title: t('email_list'),
                    path: '/access/email_list',
                    route_group: 'access',
                },
                {
                    title: t('domain_list'),
                    path: '/access/domain_list',
                    route_group: 'access',
                },
            ],
        },
        {
            icon: <Icon type={IconType.FORK_AND_SPOON}/>,
            title: t('Restaurant'),
            path: '/settings',
        },
        {
            icon: <Icon type={IconType.VIDEO}/>,
            title: t('tutorials'),
            path: '/tutorials',
        },
        {
            icon: <Icon type={IconType.OUT}/>,
            title: t('Logout'),
            path: '/logout',
            onClick: () => { handleLogout() }
        },
    ]

    const filterRoutes = (routeList) => {
        if(allowedRoutes.length === 0) return routeList;

        return routeList.reduce((acc, route) => {
            // Check if the current route's path is in allowedRoutes
            const isParentAllowed = allowedRoutes.includes(route.path) || route.path === '/logout';

            // Filter the children based on allowedRoutes
            const filteredChildren = route.children 
                ? route.children.filter(child => allowedRoutes.includes(child.path))
                : [];

            // If the parent route is allowed or has valid children, include it in the results
            if (isParentAllowed || filteredChildren.length > 0) {
                acc.push({
                    ...route,
                    // Include only filtered children if they exist
                    children: filteredChildren.length > 0 ? filteredChildren : undefined,
                });
            }

            return acc;
        }, []);
    };

    return filterRoutes(routeList);
}