import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

export const SameSizeButtonGroup = ({ children, className = ''}) => {
  const groupRef = useRef(null);

  useLayoutEffect(() => {
    const buttons = Array.from(groupRef.current.querySelectorAll(':scope > button'));
    const maxWidth = Math.max(...buttons.map(btn => btn.clientWidth));

    groupRef.current?.style.setProperty("--btn_width", `${maxWidth}px`);
  }, []);

  return (
    <Container ref={groupRef} className={className}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  --btn_width: auto;

  display: flex;
  gap: 16px;

  & > button {
    width: var(--btn_width) !important;
  }
`;