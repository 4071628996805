import React, {useState} from 'react';
import { Brush, ResponsiveContainer, Line, XAxis, YAxis, ReferenceArea, Legend, Tooltip, LineChart } from 'recharts';
import {useTranslation} from 'react-i18next';
import {centsToAmountString, toDecimalString} from "../../../logic/functions";
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import { thousandsSeparator } from 'logic/functions';
import moment from 'moment';
import { calculatePercentageDifference  } from './Dashboard';
import { calcDelta } from '../constants/dashboardFunctions';


export const HomeTipsTotal = ({info, data}) => {
  const {t} = useTranslation();
  const [left, setLeft] = useState("dataMin")
  const [right, setRigth] = useState("dataMax")
  let [refAreaLeft, setRefAreaLeft]  = useState("");
  let [refAreaRight, setRefAreaRight] = useState("");
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const path = localStorage.getItem('yumminn-path') || null
  const period = sessionStorage.getItem('yumminn-selectedReviewFilter');
  const actualValueArray = info.map((payment)=> payment.actual)
  const prevValueArray = info.map((payment)=> payment.prev)

  const total_actual = actualValueArray.reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue)
  },0);
  const total_prev = prevValueArray.reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue)
  },0);

  const bottom = 0

  const diff = toDecimalString(calcDelta(total_actual, total_prev), 1)
  const topActual = Math.max(...actualValueArray)
  const topPrev = Math.max(...prevValueArray)

  const top = Math.ceil(Math.max(...[topActual, topPrev]))
  

  const DateFormatter = date => {
    return moment(date).format('DD-MM');
  };

  return (
    <div className='yumminn-card'>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', fontWeight: 700, color: "#101038", fontFamily: "Poppins, sans-serif", marginBottom: "20px", alignItems: "center"}}>
          <span style={{fontSize: "24px", paddingLeft: isRowBased ? '15px' : '', marginTop: isRowBased ? '10px' : ''}}>{t('Tips')}</span>
        </div>
        <div style={{ marginRight: isRowBased ? "10px" : "", padding: '10px', display: 'flex', alignItems: 'center', background: diff >= 0 ? "rgba(95, 184, 148, 0.25)" : "rgba(225, 134, 132, 0.25)", fontWeight: 700, borderRadius: "9px", color: diff >= 0 ? '#5FB894' : '#E18684', fontSize: '14px'}}>
          {diff}%
          {diff !== 0 ? 
            <div id="arrow" style={{marginLeft: "4px", width: 0, height: 0, borderLeft: "10px solid transparent", borderRight: "10px solid transparent", borderBottom: diff >= 0 ? "10px solid #5FB894" : "", borderTop: diff < 0 ? "10px solid #E18684" : ""}}></div>
          :
            <div style={{width: "14px", marginLeft: "6px", fontSize: '15px', fontWeight: 900}}>=</div>
          }
        </div>
      </div>
      <ResponsiveContainer
        // width={isRowBased ? "100%" : period === 'hours' ? 750 : 630} 
        width={"100%"}
        height={period !== 'month' ? 400 : 430}
      >
      <LineChart
          data={info}
          margin={{
            top: 5,
            right: 30,
            left: 25,
            bottom: 5,
          }}
        >
        <XAxis scale={"auto"} /*tickFormatter={period === 'month' ? DateFormatter :  ""}*/ minTickGap={0} style={{fontSize: isRowBased ? "12px" : "14px"}} dataKey="label" padding={{ left: 10 }} stroke="#404040" allowDataOverflow={true} domain={[left, right]} allowDecimals={false} tickCount={info?.length} type={"category"} yAxisId="1" />
        <YAxis tickFormatter={tick => {return tick.toLocaleString()}} tick={{width: 250}} style={{fontSize: isRowBased ? "12px" : "14px"}} scale='linear' unit=" €" tickCount={info?.length} dataKey="actual" stroke="#404040" allowDataOverflow={false} domain={[bottom, top]} type="number" allowDecimals={false}/>
        <Legend wrapperStyle={{display: 'none', fontSize: "14px", width: '600px', right: 0, bottom: "-30px", boxShadow: "0px 12px 12px 15px rgb(149 157 165 / 10%)", borderRadius: '0.375rem', padding: "4px"}}/>
        {period !== "days" && period !== "today" && period !== "month" && period !== "year" ?
          <Tooltip labelFormatter={function(value) { return `${moment(value).format('DD-MM')}`}} formatter={(value, name) => value + '€'}/>
          :
          <Tooltip formatter={(value, name) => value + '€'}/>}            
        <Line strokeWidth={2} name={data?.period === 'month' ? t('Months') : t(data?.period)} type="monotone" dataKey="actual" stroke="#5FB894" />
        <Line strokeWidth={2} name={t('Previous period')} type="monotone" dataKey="prev" stroke="#BFE3D4" strokeDasharray="5 5"/>
        {refAreaLeft && refAreaRight ? (
            <ReferenceArea yAxisId="1" x1={1} x2={2} strokeOpacity={0.3} />
          ) : null}
          {period === 'month' && <Brush stroke="green" tickFormatter={DateFormatter} dataKey="label" />}
      </LineChart> 
      </ResponsiveContainer>
      <div style={{display: "flex", justifyContent: path === 'admin' ? 'space-around' : 'center', boxShadow: "0px 12px 12px 15px rgb(149 157 165 / 10%)", borderRadius: '0.375rem', paddingBottom: "15px"}}>
        <div style={{paddingTop: '20px', paddingLeft: '20px', fontWeight: 600, fontSize: '14px',}}>
          <span style={{marginRight: '10px'}}>{t("Actual")}</span>
          <svg width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px", marginBottom: "2px"}}><path stroke="none" fill="#5FB894" d="M0,4h32v24h-32z"></path></svg>                
            {thousandsSeparator(total_actual)}€
        </div>
        <div style={{paddingTop: '20px', paddingLeft: "20px", fontWeight: 600, fontSize: '14px'}}>
          <span style={{marginRight: '10px'}}>{t("Prev")}</span>
          <svg  width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px", marginBottom: "2px"}}><path stroke="none" fill="#BFE3D4" d="M0,4h32v24h-32z"></path></svg>
            {thousandsSeparator(total_prev)}€
        </div>
      </div>
    </div>
  );
};

