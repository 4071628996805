import XButton from 'common/XButton';
import { adyenAuth } from 'logic/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyAxios } from 'use-axios-client';

export default function OBUrlButton({ account }) {
  const { t } = useTranslation();

  const [createData, { data, error, loading }] = useLazyAxios({
    axiosInstance: adyenAuth,
    method: 'POST',
    url: `/onboarding_url`,
  });

  React.useEffect(() => {
    if (account && account !== '') {
      createData({ adyen_account_holder_code: account });
    }
  }, [account, createData]);

  const handleSubmit = () => {
      window.open(data.response.redirectUrl, '_blank');
  }

  return (
    <div>
      <XButton
        className='btn btn-primary rounded-full px-12'
        type='button'
        doClick={handleSubmit}
      >
        {t('Onboarding_Form')}
      </XButton>
    </div>
  );
}
