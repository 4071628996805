import React from 'react';
import { useTranslation } from 'react-i18next';


const Takeaway = () => {
  const { t } = useTranslation();
  return (
    <div className={'flex flex-col'}>
      <div>{t('takeaway')}</div>
    </div>
  );
};

export default Takeaway;