import React from 'react';

import FormError from 'common/FormError';
import Select from 'react-select';
import XButton from 'common/XButton';
import { adyenAuth, apiAuth } from 'logic/api';
import { select_styles } from 'logic/defaults';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sayError } from "../../../logic/defaults";

export default function RestaurantForm({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) {
  const { t } = useTranslation();
  const [users, setUsers] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [adyen_config, setAdyenConfigs] = React.useState([])
  const [tpv, setTpv] = React.useState([]);

  React.useEffect(() => {
    getDataUsers();
    getDataCompanies();
    getAdyenConfigs();
    getTPVData();
  }, [])


  const getDataUsers = async () => {
    try {
      const res_obj = await apiAuth.get(`/users/admin_users`);
      const res_data = res_obj.data;
      setUsers(res_data?.map(u => ({ id: u.id, name: u.email })));
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }

  const getDataCompanies = async () => {
    try {
      const res_obj = await apiAuth.get(`/companies/`);
      const res_data = res_obj.data.results;
      setCompanies(res_data?.map(u => ({ id: u.id, name: u.name })));
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }


  const getAdyenConfigs = async () => {
    try {
      const res_obj = await adyenAuth.post(`/adyen-config`);
      const res_data = res_obj.data;
      setAdyenConfigs(res_data.map(u => ({ id: u.id, name: u.name })));
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }

  const getTPVData = async () => {
    try {
      const res_obj = await apiAuth.get('/tpvs/');
      const res_data = res_obj.data.results;
      setTpv(res_data.map(u => ({ id: u.id, name: u.name })));
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }


  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <div className='flex flex-row space-x-5 w-full'>
        {/* col1 */}
        <div className='w-full flex flex-col'>
          {/* // company */}
          {!values.id &&
            <div className='flex items-center w-full space-x-2'>
              <label
                className='w-48 text-[14px] text-texto text-right'
                htmlFor='tpv_key'
              >
                {t('Company')}*
              </label>
              <div className='w-full flex flex-col mb-1'>
                <Select
                  menuPortalTarget={document.body}
                  styles={select_styles}
                  className='w-full max-w-md text-[14px]'
                  name={'company'}
                  placeholder={t('Company')}
                  options={companies}
                  value={
                    typeof values.company === 'string'
                      ? {
                        id: values.company,
                        name: values.company,
                      }
                      : values.company
                  }
                  onChange={(a) => {
                    setFieldValue('company', a);
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </div>
              <FormError message={errors.company} />
            </div>
          }

          {/* // name */}
          <div className='flex items-center w-full space-x-2 mt-5'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='name'
            >
              {t('Restaurant_Name')}*
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Restaurant_Name')}
                className='yumminn-input'
                value={values.name || ''}
                name='name'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.name} />
            </div>
          </div>

          {/* // manager */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='manager'
            >
              {t('Manager')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <Select
                menuPortalTarget={document.body}
                styles={select_styles}
                className='w-full max-w-md text-[12px]'
                name={'manager'}
                placeholder={t('Manager')}
                options={users}
                value={
                  typeof values.manager === 'string'
                    ? {
                      id: values.manager,
                      name: values.manager,
                    }
                    : values.manager
                }
                onChange={(a) => {
                  setFieldValue('manager', a);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
              <FormError message={errors.manager} />
            </div>
          </div>

          {/* // adyen_config */}
          {/*<div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='adyen_config'
            >
              {t('Adyen_config')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <Select
                  menuPortalTarget={document.body}
                  styles={select_styles}
                  className='w-full max-w-md text-[12px]'
                  name={'adyen_config'}
                  placeholder={t('Adyen_config')}
                  options={adyen_config}
                  value={
                    typeof values.adyen_config === 'string'
                      ? {
                        id: values.adyen_config,
                        name: values.adyen_config,
                      }
                      : values.adyen_config
                  }
                  onChange={(a) => {
                    setFieldValue('adyen_config', a);
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              <FormError message={errors.adyen_config}/>
            </div>
                </div>*/}
          {/* // monei account id */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='adyen_config'
            >
              {t('Monei Account Id')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Monei Account Id')}
                className='yumminn-input'
                value={values.monei_account_id || ''}
                name='monei_account_id'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.name} />
            </div>
          </div>
          {/* // tpv */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='tpv'
            >
              {t('tpv')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <Select
                menuPortalTarget={document.body}
                styles={select_styles}
                className='w-full max-w-md text-[12px]'
                name={'tpv'}
                placeholder={t('tpv')}
                options={tpv}
                value={
                  typeof values.tpv === 'string'
                    ? {
                      id: values.tpv,
                      name: values.tpv,
                    }
                    : values.tpv
                }
                onChange={(a) => {
                  setFieldValue('tpv', a);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
              <FormError message={errors.tpv} />
            </div>
          </div>

          {/* // ue payment commission */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='ue_payment_commission'
            >
              {t('UE Commission')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type="tel"
                inputMode="decimal"
                step=".01"
                placeholder={t('UE Commission')}
                className='yumminn-input'
                value={values.ue_payment_commission || ''}
                name='ue_payment_commission'
                onChange={handleChange}
                autoComplete='one-time-code'
                required
              />
              <FormError message={errors.ue_payment_commission} />
            </div>
          </div>

          {/* // not ue payment commission */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='not_ue_payment_commission'
            >
              {t('Not UE Commission')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type="tel"
                inputMode="decimal"
                step=".01"
                placeholder={t('Not UE Commission')}
                className='yumminn-input'
                value={values.not_ue_payment_commission || ''}
                name='not_ue_payment_commission'
                onChange={handleChange}
                autoComplete='one-time-code'
                required
              />
              <FormError message={errors.not_ue_payment_commission} />
            </div>
          </div>

          {/* // email */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='email'
            >
              {t('Email')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='email'
                placeholder={t('Email')}
                className='yumminn-input'
                value={values.email || ''}
                name='email'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.email} />
            </div>
          </div>
          {/* // phone */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='phone'
            >
              {t('Phone')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Phone')}
                className='yumminn-input'
                value={values.phone || ''}
                name='phone'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.phone} />
            </div>
          </div>
          {/* // address */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='address'
            >
              {t('Address')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Address')}
                className='yumminn-input'
                value={values.address || ''}
                name='address'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.address} />
            </div>
          </div>
          {/* // street number */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='street_number'
            >
              {t('Street_number')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Street_number')}
                className='yumminn-input'
                value={values.street_number || ''}
                name='street_number'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.street_number} />
            </div>
          </div>
          {/* // zip_code */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='zip_code'
            >
              {t('Zip_code')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Zip_code')}
                className='yumminn-input'
                value={values.zip_code || ''}
                name='zip_code'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.zip_code} />
            </div>
          </div>
          {/* // city */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='city'
            >
              {t('City')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('City')}
                className='yumminn-input'
                value={values.city || ''}
                name='city'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.city} />
            </div>
          </div>

          {/* // country code */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='country'
            >
              {t('Country_code')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Country_code')}
                className='yumminn-input'
                value={values.country || ''}
                name='country'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.country} />
            </div>
          </div>

          {/* // coords */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='latitude'
            >
              {t('latitude')}*
            </label>
            <div className='w-full flex flex-row mb-1 gap-2 items-center justify-center'>
              <input
                type='text'
                placeholder={t('latitude')}
                className='yumminn-input'
                value={values.latitude || ''}
                name='latitude'
                onChange={handleChange}
                autoComplete='one-time-code'
                required
              />
              <FormError message={errors.latitude} />
            </div>
          </div>

          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='longitude'
            >
              {t('longitude')}*
            </label>
            <div className='w-full flex flex-row mb-1 gap-2 items-center justify-center'>
              <input
                type='text'
                placeholder={t('longitude')}
                className='yumminn-input'
                value={values.longitude || ''}
                name='longitude'
                onChange={handleChange}
                autoComplete='one-time-code'
                required
              />
              <FormError message={errors.longitude} />
            </div>
          </div>

          {/* // web */}
          <div className='flex items-center w-full space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='web_address'
            >
              {t('Web')}
            </label>
            <div className='w-full flex flex-col mb-1'>
              <input
                type='text'
                placeholder={t('Web en formato http(s)')}
                className='yumminn-input'
                value={values.web_address || ''}
                name='web_address'
                onChange={handleChange}
                autoComplete='one-time-code'
              />
              <FormError message={errors.web_address} />
            </div>
          </div>

          {/* // about button */}
          <div className='flex items-center space-x-2'>
            <label
              className='w-48 text-[14px] text-texto text-right'
              htmlFor='about_button'
            >
              {t('About enable or disable')}
            </label>
            <div className='w-full flex flex-col mb-1 mt-1 h-8'>
              <input
                type='checkbox'
                className='mt-2 rounded bg-gray-200 border-transparent focus:border-transparent text-primary focus:ring-1 focus:ring-offset-2 focus:ring-gray-500'
                value={values.about_button || ''}
                name='about_button'
                onChange={handleChange}
                checked={values.about_button}
                autoComplete='one-time-code'
              />
              <FormError message={errors.about_button} />
            </div>
          </div>

        </div>
      </div>
      <div className='self-end my-5 w-full'>
        <XButton
          className='btn btn-primary rounded-full px-12'
          type='button'
          doClick={handleSubmit}
        >
          {t('save')}
        </XButton>
      </div>
    </form>
  );
}
