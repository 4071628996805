import { ChevronDownIcon, ChevronLeftIcon, FilterIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardMockup } from '../components';
import '../../../styles/animations.css';
import { STYLES } from './CategoryStyle';


export const CategoriesPreview = ({groupType, name}) => {
  const { t } = useTranslation();

  const isSupergroup = groupType === 'SUPER_GROUP';
  const isGroup = groupType === 'CATEGORY_GROUP';
  const isCategory = groupType === 'CATEGORY';
  
  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full p-1 pb-[2px] flex items-center gap-1 relative bg-[#404040] '>
        <ChevronLeftIcon width={13} className='flex-shrink-0 mr-auto text-white'/>
        <span className={STYLES.SELECTED_SUPERGROUP + (isSupergroup ? 'intermitent-outline' : '')}>
          {isSupergroup && name ? name : t('group')}
        </span>
        <span className={STYLES.SUPERGROUP}>{t('group')}</span>
        <span className={STYLES.SUPERGROUP}>{t(t('group'))}</span>
        <FilterIcon width={13} className='flex-shrink-0 ml-auto opacity-0'/>
        {!isSupergroup &&
          <div className={STYLES.OVERLAY}/>
        }
      </div>
      <div className='w-full flex-1 flex flex-col relative'>
        <div className='w-full px-2 py-[2px] flex items-center gap-1 relative'>
          <span className={(isGroup || isCategory ? STYLES.EXPANDED_GROUP : STYLES.COLAPSED_GROUP) + (isGroup ? 'intermitent-outline' : '')}>
            {isGroup && name ? name : t('category')}
            <ChevronDownIcon width={10} className={`flex-shrink-0 ${isCategory ? 'transform rotate-180' : ''}`}/>
          </span>
          <span className={STYLES.GROUP}>
            {t('category')}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          <span className={STYLES.GROUP}>
            {t('category')}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          <span className={STYLES.GROUP}>
            {t('category')}
            <ChevronDownIcon width={10} className='flex-shrink-0'/>
          </span>
          {isCategory &&
            <div className={STYLES.OVERLAY}/>
          }
        </div>
        {isCategory &&
          <div className='w-full px-2 py-[2px] text-[#A0A0A0] flex items-center gap-1'>
            <span className={isCategory ? STYLES.SELECTED_CATEGORY : STYLES.CATEGORY + 'text-white intermitent-outline'}>
              {isCategory && name ? name : t('category')}
            </span>
            <span className={STYLES.CATEGORY}>{t('category')}</span>
            <span className={STYLES.CATEGORY}>{t('category')}</span>
            <span className={STYLES.CATEGORY}>{t('category')}</span>
          </div>
        }
        <div className='flex-1 flex flex-col gap-2 p-2 relative'>
          <ProductCardMockup allergens description={'long'}/>
          <ProductCardMockup allergens description={'short'}/>
          <ProductCardMockup allergens description={'long'}/>
          <ProductCardMockup allergens description={'short'}/>
          <ProductCardMockup allergens description={'short'}/>
          {!isSupergroup &&
            <div className={STYLES.OVERLAY}/>
          }
        </div>
        {isSupergroup &&
          <div className={STYLES.OVERLAY}/>
        }
      </div>
    </div>
  );
};