import React from "react";
import ProtectedRoute from "common/ProtectedRoute";

import SettingsContainer from './SettingsContainer';
import SettingsGeneralPage from './pages/SettingsGeneral';
import SettingsPhotosPage from './pages/SettingsPhotos';
import SettingsDescriptionPage from "./pages/SettingsDescription";
import SettingsBankPage from "./pages/SettingsBank";
import TableStatusesPage from "./pages/TableStatuses";
import TablesSectorPage from "./pages/TablesSector";
import { SettingsCustomization } from "./pages/customization/SettingsCustomization";

const SettingsRoutes = () => (
  <>
    {/* home */}
    <ProtectedRoute
      exact
      path='/settings'
      component={SettingsGeneralPage}
      layout={SettingsContainer}
    />
    <ProtectedRoute
      exact
      path='/settings/photos'
      component={SettingsPhotosPage}
      layout={SettingsContainer}
    />
    <ProtectedRoute
      exact
      path='/settings/customization'
      component={SettingsCustomization}
      layout={SettingsContainer}
    />
    <ProtectedRoute
      exact
      path='/settings/description'
      component={SettingsDescriptionPage}
      layout={SettingsContainer}
    />
    <ProtectedRoute
      exact
      path='/settings/bank'
      component={SettingsBankPage}
      layout={SettingsContainer}
    />
    <ProtectedRoute
      exact
      path='/settings/tables'
      component={TableStatusesPage}
      layout={SettingsContainer}
    />
    <ProtectedRoute
      exact
      path='/settings/tables/sectors'
      component={TablesSectorPage}
      layout={SettingsContainer}
    />
  </>
);

export default SettingsRoutes;
