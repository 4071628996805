import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "common/Input";
import { DISCOUNT_TYPOLOGIES } from "./constants";
import { DiscountPreview } from "components/previews/screens/DiscountPreview";

export const PromotionTexts = ({ discountDraft, editDiscount }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4 justify-around flex-1 my-10">
      <DiscountPreview discount={discountDraft}/>
      <div className="flex flex-col gap-8 w-96">
        {discountDraft?.typology === DISCOUNT_TYPOLOGIES.GENERAL && (
          <TextInput
            label={'marquee_text'}
            placeholder={t(`example_${discountDraft?.typology}_marquee_text`)}
            value={discountDraft?.marquee_text || ''}
            onChange={event => editDiscount({ marquee_text: event.target.value })}
          />
        )}
        {discountDraft?.typology === DISCOUNT_TYPOLOGIES.COUPON && (
          <TextInput
          label={'coupon_code'}
          placeholder={t(`example_coupon_code`)}
          value={discountDraft?.code || ''}
          onChange={event => editDiscount({ code: event.target.value })}
          />
        )}
        <TextInput
          label={'payment_details_text'}
          placeholder={t(`example_${discountDraft?.typology}_payment_details_text`)}
          value={discountDraft?.payment_details_text || ''}
          onChange={event => editDiscount({ payment_details_text: event.target.value })}
        />
      </div>
    </div>
  );
};
