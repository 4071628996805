import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Counter } from 'common/Counter';

export const DelayCard = ({ saturationDraft, editSaturationDraft }) => {
  const { t } = useTranslation();

  const addMinutes = key => editSaturationDraft({[key]: saturationDraft[key] + 5});
  const subtractMinutes = key => editSaturationDraft({[key]: Math.max(saturationDraft[key] - 5, 5)});
  const changeMinutes = (key, value) => editSaturationDraft({[key]: value});

  return (
    <div
      className={saturationDraft.type === 'delay' ? 'selected' : ''}
      onClick={() => editSaturationDraft({type: 'delay'})}
    >
      <i>🕙</i>
      <h5>
        <Trans i18nKey='report_delay'>
          <b>Informar</b> espera
        </Trans>
      </h5>
      <p>
        <Trans i18nKey='how_long_delay'>
          ¿<b>Durante cuánto tiempo</b> quieres pausar los pedidos?
        </Trans>
      </p>
      <Counter
        count={saturationDraft.delay_minutes}
        onAdd={() => addMinutes('delay_minutes')}
        onSubtract={() => subtractMinutes('delay_minutes')}
        onNumberChange={value => changeMinutes('delay_minutes', value)}
        min={5}
      />
      <span>{t('minutes')}</span>
      <p>
        <Trans i18nKey='how_long_report'>
          ¿<b>Durante cuánto tiempo</b> quieres pausar los pedidos?
        </Trans>
      </p>
      <Counter
        count={saturationDraft.duration_minutes}
        onAdd={() => addMinutes('duration_minutes')}
        onSubtract={() => subtractMinutes('duration_minutes')}
        onNumberChange={value => changeMinutes('duration_minutes', value)}
        min={5}
      />
      <span>{t('minutes')}</span>
    </div>
  );
};
