import { DownloadIcon, TrashIcon } from '@heroicons/react/outline';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { BUTTON_VARIANTS, Button } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { TextInput, NumberInput, TextArea } from 'common/Input';
import { ProductPopupMockup } from 'components/previews/components';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { calculateCents } from 'services/payments';

export const ModifierSpecs = ({
  modifierDraft,
  disabledPriceInput,
  loading,
  editModifier,
}) => {
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  const [price, setPrice] = useState(modifierDraft.tpv_price_cents ? (modifierDraft.tpv_price_cents / 100).toFixed(2) : '');

  const handleNameChange = event => {
    editModifier({ name: event.target.value });
  };
  
  const handleDescriptionChange = event => {
    editModifier({ description: event.target.value });
  };

  const handlePriceChange = event => {
    if (disabledPriceInput) return;

    const value = event.target.value;

    let priceString = value.replace(/[^\d.,]/g, '').replace(',', '.');
    priceString = priceString === '' ? '' : Number(priceString) < 0 ? '' : priceString;
    
    if (priceString.includes('.')) {
      const index = priceString.indexOf('.');
      const decimal = priceString.slice(index + 1).replace('.', '').slice(0, 2);
      priceString = priceString.slice(0, index + 1) + decimal;
    }
    
    const priceCents = Math.floor(calculateCents(priceString));
    
    setPrice(priceString);
    editModifier({ tpv_price_cents: priceCents });
  };

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  const handleFileSelect = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Create a data URL for the selected image
      const imageUrl = URL.createObjectURL(selectedFile);
      editModifier({ image: imageUrl, imageFile: selectedFile });
    }
  };

  const saveImage = () => {
    const imageUrl = modifierDraft.image;
    window.open(imageUrl, '_blank');
  };

  const deleteImage = () => {
    editModifier({ image: '', imageFile: null });
  };

  return (
    <div className="flex gap-4 w-full overflow-auto py-6 flex-1 h-0">
      <ProductPopupMockup product={modifierDraft}/>
      <div className='inputs'>
        {disabledPriceInput &&
          <div className="row">
            <TextInput
              name='id'
              label={t('id')}
              value={modifierDraft.id}
              className='modifier_config_item'
              disabled
            />
            <TextInput
              name='tpv_name'
              label={t('tpv_name')}
              value={modifierDraft.tpv_name}
              className='modifier_config_item'
              disabled
              />
          </div>
        }
        <div className='row'>
          <TextInput
            name='name'
            label={t('name')}
            value={modifierDraft.name || ''}
            className='modifier_config_item'
            placeholder={modifierDraft.tpv_name}
            onChange={handleNameChange}
          />
          <NumberInput
            name='price'
            label={t('Price')}
            value={price}
            className='modifier_config_item'
            disabled={disabledPriceInput}
            placeholder='0,00'
            unit={'€'}
            onChange={handlePriceChange}
          />
        </div>
        <TextArea
          name='description'
          label={t('Description')}
          value={modifierDraft.description || modifierDraft.translated_description || modifierDraft.tpv_description || ""}
          placeholder={t('Description')}
          onChange={handleDescriptionChange}
        />
        <div className="row">
          <Button
            label={modifierDraft.image ? "swap_photo" : "add_photo"}
            leftIcon={!modifierDraft.image && <PlusCircleIcon/>}
            disabled={loading}
            onClick={handleButtonClick}
            variant={BUTTON_VARIANTS[modifierDraft.image ? "SECONDARY" : "PRIMARY"]}
          />
          {modifierDraft.image &&
            <>
              <Icon type={IconType.DOWNLOAD} loading={loading} onClick={saveImage} size={20}/>
              <Icon type={IconType.TRASH} loading={loading} onClick={deleteImage} size={20}/>
            </>
          }
          {!modifierDraft.image &&
            <i className="text-base">({t('optional')})</i>
          }
        </div>
        <p className="text-base">
          <Trans i18nKey={'img_suggestions'}>
            <b className="font-semibold">Sugerencias:</b> png/jpg ou gif 400x400 pixels, entre 1MB et 2MB.
          </Trans>
        </p>
      </div>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
    </div>
  );
};
