import React from 'react';
import {useTranslation} from 'react-i18next';
import useRestaurant from 'components/yumminn/useRestaurant';
import {apiAuth} from 'logic/api';
import {PaymentCard} from "../../../common/PaymentCard";
import DateTimePicker from 'react-datetime-picker';
import {centsToAmountString} from "../../../logic/functions";
import "./customDateTimePicker.css";


const YumminnPaymentsScreen = () => {
  const {t} = useTranslation();
  const {seleccionado} = useRestaurant();  
  const [data, setData] = React.useState([]);
  const timeToday = new Date().getHours()
  const today = new Date(new Date().getTime());
  const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
  const tomorrow = new Date(new Date().getTime() + 24*60*60*1000);
  const startDate = timeToday >= 3 ? today.setHours(3, 0, 0, 0) : yesterday.setHours(3, 0, 0, 0);
  const endDate = timeToday >= 3 ? tomorrow.setHours(3, 0, 0, 0) : today.setHours(3, 0, 0, 0);
  const [from, setFrom] = React.useState(new Date(startDate));
  const [to, setTo] = React.useState(new Date(endDate));
  
  const getData = React.useCallback(async () => {
    const obj = {
      restaurant: seleccionado[0]?.value,
      from: from,
      to: to,
    }
    try {
      const res_obj = await apiAuth.post(`/payments/tips`, obj);
      setData(res_obj.data)
    } catch (error) {
      console.log(`error`, error);
    }
  }, [seleccionado, from, to]);

  React.useEffect(() => {
    if (seleccionado) {
      getData();
    }
  }, [seleccionado, getData]);

  if (!data) return null;

  if (data) {
    return (
      <div className=''>
        <div className={'mb-16 flex flex-row space-x-4 items-end'}>
          <div style={{width: "100%"}}>
            <div>{t('Start_Date')}</div>
            <div className={'bg-white p-4 rounded-lg'}>
            <div className={"customDatePickerWidth"}>
              <DateTimePicker  
                onChange={value => setFrom(value)}
                value={from}
              />
            </div>
            </div>
          </div>
          <div style={{width: "100%"}}>
            <div>{t('End_Date')}</div>
            <div className={'bg-white p-4 rounded-lg'}>
              <DateTimePicker
                onChange={value => setTo(value)}
                value={to}
              />
            </div>
          </div>
        </div>

        <div className={'w-full flex flex-row grid grid-cols-3 gap-4'}>
          <PaymentCard title={t('Revenue')} subtitle={t('(excluding tips)')} quantity={data && data.total_bill ? centsToAmountString(data.total_bill) : 0 }/>
          <PaymentCard title={t('Tips')} quantity={data && data.tips ? centsToAmountString(data.tips) : 0}/>
          <PaymentCard title={t('Total')} quantity={data ? centsToAmountString(data.total_bill + data.tips) : 0}/>
        </div>

      </div>
    );
  }
};

export default YumminnPaymentsScreen;
