import React, { useContext } from 'react';
import { LockersContext } from 'data/LockersContext';
import { useTranslation } from 'react-i18next';
import { MetricsContainer } from './LockerContainers';

import { useCountDown } from 'hooks/useCountDown';
const getMetrics = ({ lockers, orders }) => [
  ['orders_in_lockers', lockers?.filter(locker => !!locker?.order).length || 0],
  ['orders_in_counter', lockers?.find(locker => locker.counter)?.order_list.length || 0],
  ['new_orders', orders?.length || 0],
  ['empty_lockers', lockers?.filter(locker => !locker.counter && !locker.order).length || 0],
  ['active_lockers', lockers?.filter(locker => locker.active).length || 0],
];

export const MetricsPanel = ({ openOrdersPanel }) => {
  const { state : { shift, ...state } } = useContext(LockersContext);

  const { t } = useTranslation();

  return (
    <MetricsContainer>
      <button onClick={openOrdersPanel}>
        {t('see_order_management')}
      </button>
      <ul>
        {getMetrics(state).map(([label, value]) =>
          <li key={label}>
            <b>{value}</b>
            <span>{t(label)}</span>
          </li>
        )}
      </ul>
      {!shift &&
        <p>{t('no_active_shift')}</p>
      }
      {shift &&
        <ShfitTimer shift={shift}/>
      }
    </MetricsContainer>
  )
};

const ShfitTimer = ({shift}) => {
  const { t } = useTranslation();
  const { timer } = useCountDown({ endTime: shift.end_time, fixedHours: true });
  
  return (
    <div className='timer'>
      <b>{timer}</b>
      <p>
        {t('shift_time_left')}
        <span>{shift.name}</span>
      </p>
    </div>
  );
};
