import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import AppRoute from "./common/AppRoute";
import ProtectedRoute from "./common/ProtectedRoute";
import LoginContainer from "./layouts/LoginContainer";
import MenuContainerLayout from './layouts/MenuContainer';
import Dashboard from "pages/home/pages/Dashboard";
import { Reports } from "pages/home/pages/Reports";
import Agents from "pages/agents/Agents";
import AgentsLayout from "pages/agents/AgentsLayout";
import HomePage from "./pages/Home";
import Login from "./pages/users/Login";
import Forgot from "./pages/users/Forgot";
import Update from "./pages/users/Update";
import SettingsPage from "./pages/Settings";
import TablesPage from "pages/Tables";
import YumminnPaymentsPage from "./pages/YumminnPayments";
import AdminContainer from "./pages/admin/AdminContainer";
import CompaniesList from './pages/admin/pages/CompaniesList';
import RestaurantsList from "./pages/admin/pages/RestaurantsList";
import RestaurantsNew from "./pages/admin/pages/RestaurantsNew";
import RestaurantsEdit from "./pages/admin/pages/RestaurantsEdit";
import RestaurantsEditExternal from "./pages/admin/pages/RestaurantsEditExternal";
import RestaurantsEditTPV from "./pages/admin/pages/RestaurantsEditTPV";
import CompaniesNew from "./pages/admin/pages/CompaniesNew";
import CompaniesEdit from "./pages/admin/pages/CompaniesEdit";
import { TopRestaurants } from "pages/admin/pages/TopRestaurants";
import { Crm } from "pages/admin/pages/Crm";
import OrderAndPay from "pages/admin/pages/OrderAndPay";
import ProductHighlight from "pages/admin/pages/ProductHighlight";
import Suggestions from "pages/admin/pages/Suggestions";
import Orders from "pages/admin/pages/Orders";
import OrdersScan from "pages/admin/pages/OrdersScan";
import Events from "pages/admin/pages/Events";
import Translations from "pages/translations/Translations";
import Billing from "pages/admin/pages/Billing";
import { Sales } from "pages/home/pages/Sales";
import PerformanceGraphs from "pages/admin/pages/PerformanceGraphs";
import SettingsGeneralPage from "pages/settings/pages/SettingsGeneral";
import { SettingsCustomization } from "pages/settings/pages/customization/SettingsCustomization";
import SettingsDescriptionPage from "pages/settings/pages/SettingsDescription";
import TableStatusesPage from "pages/settings/pages/TableStatuses";
import TablesSectorPage from "pages/settings/pages/TablesSector";
import PaymentsList from "pages/payments/pages/PaymentsList";
import LoyaltyDashboard from "pages/loyalty/pages/LoyaltyDashboard";
import LoyaltyPrograms from "pages/loyalty/pages/LoyaltyPrograms";
import LoyaltyMails from "pages/loyalty/pages/LoyaltyMails";
import RatingsOverviewPage from "pages/ratings/pages/RatingsOverview";
import RatingsReviewsPage from "pages/ratings/pages/RatingsReviews";
import RatingsContainer from "pages/ratings/RatingsContainer";
import LoyaltyContainer from "pages/loyalty/LoyaltyContainer";
import SettingsContainer from "pages/settings/SettingsContainer";
import PaymentsContainer from "pages/payments/PaymentsContainer";
import MenuCreationPage from "pages/menu/components/MenuCreation";
import MenuContainer from "pages/menu/MenuContainer";
import DiscountsContainer from "pages/discounts/DiscountsContainer";
import DiscountsPage from "pages/discounts/pages/DiscountsPage";
import TopProducts from "pages/admin/pages/TopProducts";
import { NewsHome } from "pages/admin/pages/News/NewsHome";
import Waiters from "pages/admin/pages/Waiters";
import MySolutionContainer from "pages/my_solution/MySolutionContainer";
import QRCodesPage from "pages/qr/pages/QRCodes";
import ServiceContainer from "pages/service/ServiceContainer";
import StockControlContainer from "pages/admin/pages/StockConfiguration/StockControlContainer";
import Shifts from "pages/admin/pages/Shifts";
import BookingShifts from "pages/admin/pages/BookingShifts";
import Bookings from "pages/admin/pages/Bookings";
import Lockers from "pages/admin/pages/Lockers";
import StockConfiguration from "pages/admin/pages/StockConfiguration";
import StockStatus from "pages/admin/pages/StockStatus";
import Tutorials from 'pages/tutorials/Tutorials';
import { TutorialLayout } from "pages/tutorials/TutorialLayout";
import { ComponentsPage } from "pages/components/ComponentsPage";
import { ComponentsLayot } from "pages/components/ComponentsLayout";
import SettingsWifi from "pages/settings/pages/SettingsWifi";
import Delivery from "pages/delivery_and_takeaway/Delivery";
import DeliveryAndTakeawayLayout from "pages/delivery_and_takeaway/DeliveryAndTakeawayLayout";
import Takeaway from "pages/delivery_and_takeaway/Takeaway";
import { Tickets } from "pages/events/Tickets";
import { CreateEvents } from "pages/events/CreateEvents";
import WalletLayout from "pages/wallet/WalletLayout";
import WalletSettings from "pages/wallet/WalletSettings";
import WalletTopups from "pages/wallet/WalletTopups";
import Modifiers from "pages/admin/pages/Modifiers/Modifiers";
import WalletDashboard from "pages/wallet/WalletDashboard";
import WalletUsers from "pages/wallet/WalletUsers";
import EmailManagement from "pages/access/EmailManagement";
import AccessContainer from "pages/access/AccessContainer";
import DomainManagement from "pages/access/DomainManagement";
import Subsidies from "pages/admin/pages/Subsidies/Subsidies";
import SubsidiesContainer from "pages/admin/pages/Subsidies/SubsidiesContainer";

const service_routes = [
  <ProtectedRoute
    key='settings/tables'
    type='tables'
    title='Service'
    exact
    path='/settings/tables'
    component={TableStatusesPage}
    layout={ServiceContainer}
  />,
  <ProtectedRoute
    key='settings/tables/sectors'
    type='tables'
    title='Service'
    exact
    path='/settings/tables/sectors'
    component={TablesSectorPage}
    layout={ServiceContainer}
  />,
  <ProtectedRoute
    key='/admin/waiters'
    exact
    path='/admin/waiters'
    title='Service'
    component={Waiters}
    layout={ServiceContainer}
  />,
  <ProtectedRoute
    key={'/admin/shifts'}
    exact
    title='Service'
    path='/admin/shifts'
    component={Shifts}
    layout={ServiceContainer}
  />,
  <ProtectedRoute
    key={'/admin/booking-shifts'}
    exact
    title='Service'
    path='/admin/booking-shifts'
    component={BookingShifts}
    layout={ServiceContainer}
  />,
  <ProtectedRoute
    key={'/admin/bookings'}
    exact
    title='Service'
    path='/admin/bookings'
    component={Bookings}
    layout={ServiceContainer}
  />,
  <ProtectedRoute
    key={'/admin/lockers'}
    exact
    title='Service'
    path='/admin/lockers'
    component={Lockers}
    layout={ServiceContainer}
  />
];

const stock_routes = [
  <ProtectedRoute
    key={'/admin/stock-configuration'}
    exact
    title='Stock control'
    path='/admin/stock-configuration'
    component={StockConfiguration}
    layout={StockControlContainer}
  />,
  <ProtectedRoute
    key={'/admin/stock-status'}
    exact
    title='Stock control'
    path='/admin/stock-status'
    component={StockStatus}
    layout={StockControlContainer}
  />
];

const menu_routes = [
  <ProtectedRoute
    key={'/admin/order-and-pay'}
    title={'order-and-pay'}
    path='/admin/order-and-pay'
    component={OrderAndPay}
    layout={AdminContainer}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key={'/admin/modifiers'}
    title={'modifiers'}
    path='/admin/modifiers'
    component={Modifiers}
    layout={AdminContainer}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key={'/admin/product-highlight'}
    exact
    title={'highlights_banners'}
    path='/admin/product-highlight'
    component={ProductHighlight}
    layout={MenuContainer}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key={'/admin/cross-sell'}
    exact
    title={'menu'}
    path='/admin/cross-sell'
    component={Suggestions}
    layout={MenuContainer}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/menu'
    title={'link_and_pdf'}
    path='/menu'
    component={MenuCreationPage}
    layout={MenuContainer}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/discounts'
    title={'discounts'}
    path='/discounts'
    component={DiscountsPage}
    layout={DiscountsContainer}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/modifier_name'
    exact
    path='/translations/modifier_name'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/modifier_description'
    exact
    path='/translations/modifier_description'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/modifier_category_name'
    exact
    path='/translations/modifier_category_name'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/product_name'
    exact
    path='/translations/product_name'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/product_description'
    exact
    path='/translations/product_description'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/product_category_name'
    exact
    path='/translations/product_category_name'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/category_group_name'
    exact
    path='/translations/category_group_name'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
  <ProtectedRoute
    key='/translations/supergroup_name'
    exact
    path='/translations/supergroup_name'
    component={Translations}
    layout={MenuContainerLayout}
    route_group={'menu'}
  />,
];

const ratings_routes = [
  <ProtectedRoute
    key='/ratings'
    exact
    title='Ratings_and_reviews'
    path='/ratings'
    hasDateFilter={true}
    hasWaiterSelect={true}
    component={RatingsOverviewPage}
    layout={RatingsContainer}
  />,
  <ProtectedRoute
    key='/ratings/reviews'
    exact
    title='Ratings_and_reviews'
    path='/ratings/reviews'
    hasDateFilter={true}
    hasWaiterSelect={true}
    component={RatingsReviewsPage}
    layout={RatingsContainer}
  />
];

const delivery_and_takeaway_routes = [
  <ProtectedRoute
    key='/delivery'
    exact
    title='delivery'
    path='/delivery'
    hasDateFilter={true}
    hasWaiterSelect={true}
    component={Delivery}
    layout={DeliveryAndTakeawayLayout}
  />,
  <ProtectedRoute
    key='takeaway'
    exact
    title='takeaway'
    path='/takeaway'
    hasDateFilter={true}
    hasWaiterSelect={true}
    component={Takeaway}
    layout={DeliveryAndTakeawayLayout}
  />
];

const wallet_routes = [
  <ProtectedRoute
    key='/wallet'
    exact
    title='wallet'
    path='/wallet/settings'
    hasDateFilter={true}
    hasWaiterSelect={true}
    component={WalletSettings}
    layout={WalletLayout}
  />,
  <ProtectedRoute
    key='/wallet'
    exact
    title='wallet'
    path='/wallet/topups'
    hasDateFilter={true}
    hasWaiterSelect={true}
    component={WalletTopups}
    layout={WalletLayout}
  />,
   <ProtectedRoute
   key='/wallet'
   exact
   title='wallet'
   path='/wallet/metrics'
   hasDateFilter={true}
   hasWaiterSelect={true}
   component={WalletDashboard}
   layout={WalletLayout}
 />,
  <ProtectedRoute
  key='/wallet'
  exact
  title='wallet'
  path='/wallet/users'
  hasDateFilter={true}
  hasWaiterSelect={true}
  component={WalletUsers}
  layout={WalletLayout}
/>,
];

const loyalty_routes = [
  <ProtectedRoute
    key='/loyalty'
    exact
    path='/loyalty'
    hasDateFilter={true}
    title='Service'
    component={LoyaltyDashboard}
    layout={LoyaltyContainer}
    route_group={'loyalty'}
  />,
  <ProtectedRoute
    key='/loyalty/programs'
    exact
    path='/loyalty/programs'
    hasDateFilter={true}
    title='Service'
    component={LoyaltyPrograms}
    layout={LoyaltyContainer}
    route_group={'loyalty'}
  />,
  <ProtectedRoute
    key='/loyalty/mails'
    exact
    path='/loyalty/mails'
    hasDateFilter={true}
    title='Service'
    component={LoyaltyMails}
    layout={LoyaltyContainer}
    route_group={'loyalty'}
  />
];

const mysolution_routes = [
  <ProtectedRoute
    key='/settings/customization'
    type='customization'
    title='my_solution'
    exact
    path='/settings/customization'
    component={SettingsCustomization}
    layout={MySolutionContainer}
  />,
  <ProtectedRoute
    key='/qr'
    path='/qr'
    title='my_solution'
    component={QRCodesPage}
    layout={MySolutionContainer}
  />,
  <ProtectedRoute
    key='/admin/billing'
    path='/admin/billing'
    title='my_solution'
    component={Billing}
    layout={MySolutionContainer}
  />,
  <ProtectedRoute
    key='/admin/events'
    exact
    title='my_solution'
    path='/admin/events'
    component={Events}
    layout={MySolutionContainer}
  />
];

const subsidies_routes = [
  <ProtectedRoute
    key='/admin/subsidies'
    type='subsidies'
    title='subsidies'
    exact
    path='/admin/subsidies'
    component={Subsidies}
    layout={SubsidiesContainer}
  />,
];

const restaurant_routes = [
  <ProtectedRoute
    key='/settings'
    type='settings'
    title='Restaurant'
    exact
    path='/settings'
    component={SettingsGeneralPage}
    layout={SettingsContainer}
  />,
  <ProtectedRoute
    key='/settings/wifi'
    type='settings'
    title='Restaurant'
    exact
    path='/settings/wifi'
    component={SettingsWifi}
    layout={SettingsContainer}
  />,
  <ProtectedRoute
    key='/settings/description'
    type='settings'
    title='Restaurant'
    exact
    path='/settings/description'
    component={SettingsDescriptionPage}
    layout={SettingsContainer}
  />
];

const dashboard_routes = [
  <ProtectedRoute
    key={'/admin/general_metrics'}
    title='general_metrics'
    path='/admin/dashboard'
    component={Dashboard}
    layout={MenuContainer}
    route_group={'dashboard'}
  />,
  <ProtectedRoute
      key={'/admin/home'}
      exact
      title='Home'
      path='/admin/home'
      component={NewsHome}
      layout={MenuContainer}
      route_group={'dashboard'}
    />,
    <ProtectedRoute
      key='/admin/catalog-dashboard'
      path='/admin/catalog-dashboard'
      title='product_metrics'
      component={TopProducts}
      layout={MenuContainer}
      route_group={'dashboard'}
    />,
];

const payments_and_orders_routes = [
  <ProtectedRoute
    key={'/payments'}
    exact
    path='/payments'
    component={PaymentsList}
    layout={PaymentsContainer}
    route_group={'payments_and_orders'}
  />,
  <ProtectedRoute
    key="/admin/orders"
    exact
    path="/admin/orders"
    component={Orders}
    layout={AdminContainer}
    route_group={'payments_and_orders'}
  />,
  <ProtectedRoute
    key="/admin/orders/scan"
    exact
    path="/admin/orders/scan"
    component={OrdersScan}
    layout={AdminContainer}
    route_group={'payments_and_orders'}
  />
];

const superuser_routes = [
  <ProtectedRoute
    key={'/components'}
    exact
    path='/components'
    component={ComponentsPage}
    layout={ComponentsLayot}
    route_group={'super_user'}
  />,
];

const event_routes = [
  <ProtectedRoute
    key={'/admin/event_tickets'}
    exact
    path='/admin/event-tickets'
    component={Tickets}
    layout={AdminContainer}
    route_group={'event_tickets'}
  />,
   <ProtectedRoute
   key={'/admin/create_events'}
   exact
   path='/admin/create-events'
   component={CreateEvents}
   layout={AdminContainer}
   route_group={'create_events'}
 />,
];

const Routes = () => {
  const user = JSON.parse(localStorage.getItem("yumminn-user"));

  return (
    <>
      <Router>
        <Switch>
  
        {dashboard_routes}
  
          <ProtectedRoute
              key='/admin/catalog-dashboard'
              path='/admin/catalog-dashboard'
              component={TopProducts}
              layout={AdminContainer}
            />
  
     
        {service_routes}
        {stock_routes}
        {event_routes}
        {menu_routes}
        {ratings_routes}
        {delivery_and_takeaway_routes}
        {wallet_routes}
        {loyalty_routes}
        {mysolution_routes}
        {subsidies_routes}
        {restaurant_routes}
        {payments_and_orders_routes}
        {user?.is_superuser && superuser_routes}
          {/* <ProtectedRoute
            title='Service'
            exact
            path='/settings/bank'
            component={SettingsBankPage}
            layout={SettingsContainer}
          /> */}
  
          
          {/* home */}
          <AppRoute exact path='/' component={Login} layout={LoginContainer} />
          <AppRoute
            key='/forgot'
            exact
            path='/forgot'
            component={Forgot}
            layout={LoginContainer}
          />
          <AppRoute
            key='/recupera/:token'
            exact
            path='/recupera/:token'
            component={Update}
            layout={LoginContainer}
          />
          {/*<AppRoute
            exact
            path='/tables/:id/:token'
            component={TablesPage}
            layout={YumminnLayout}
          />*/}
  
          {/* superadmin screens */}
          {/*<ProtectedRoute
            exact
            path='/admin'
            component={AdminPage}
            layout={YumminnLayout}
          />*/}
  
          <ProtectedRoute
            key='/admin/reports'
            path='/admin/reports'
            component={Reports}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/agents'
            path='/agents'
            component={Agents}
            layout={AgentsLayout}
          />
          <ProtectedRoute
            key='/admin/restaurants'
            exact
            path='/admin/restaurants'
            component={RestaurantsList}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/restaurants/new'
            exact
            path='/admin/restaurants/new'
            component={RestaurantsNew}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/restaurants/tpv/:id'
            exact
            path='/admin/restaurants/tpv/:id'
            component={RestaurantsEditTPV}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/restaurants/external/:id'
            exact
            path='/admin/restaurants/external/:id'
            component={RestaurantsEditExternal}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/restaurants/:id'
            exact
            path='/admin/restaurants/:id'
            component={RestaurantsEdit}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/companies'
            exact
            path='/admin/companies'
            component={CompaniesList}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/companies/new'
            exact
            path='/admin/companies/new'
            component={CompaniesNew}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/companies/:id'
            exact
            path='/admin/companies/:id'
            component={CompaniesEdit}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/top-restaurants'
            exact
            path='/admin/top-restaurants'
            component={TopRestaurants}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/crm'
            exact
            path='/admin/crm'
            component={Crm}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/admin/sales'
            path='/admin/sales'
            component={Sales}
            layout={AdminContainer}
          />
          {/* admin screens */}
          <ProtectedRoute
            key='/home'
            path='/home'
            component={HomePage}
            layout={MenuContainerLayout}
          />
          <ProtectedRoute
            key='/settings'
            path='/settings'
            component={SettingsPage}
            layout={MenuContainerLayout}
          />
          <ProtectedRoute
            key='/tables'
            path='/tables'
            component={TablesPage}
            layout={MenuContainerLayout}
          />
          <ProtectedRoute
            key='/yumminn-payments'
            exact
            path='/yumminn-payments'
            component={YumminnPaymentsPage}
            layout={MenuContainerLayout}
          />
          <ProtectedRoute
            key='/admin/performance'
            exact
            path='/admin/performance'
            component={PerformanceGraphs}
            layout={AdminContainer}
          />
          <ProtectedRoute
            key='/tutorials'
            path='/tutorials'
            component={Tutorials}
            layout={TutorialLayout}
          />
          <ProtectedRoute
            key='/access/email_list'
            path='/access/email_list'
            component={EmailManagement}
            layout={AccessContainer}
          />
          <ProtectedRoute
            key='/access/domain_list'
            path='/access/domain_list'
            component={DomainManagement}
            layout={AccessContainer}
          />
  
          {/* comodin */}
        </Switch>
      </Router>
    </>
  )
};

export default Routes;
