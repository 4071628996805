import React, { useState, useEffect } from "react";
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import Spinner from "common/Spinner";
import { NumberInput, SelectInput } from "common/Input";
import { DISCOUNT_TYPOLOGIES } from "./constants";
import { DiscountPreview } from "components/previews/screens/DiscountPreview";

export const PromotionSpecs = ({ editPromo, discount, catalog, discountToEdit }) => {
  const { t } = useTranslation();
  const [productList, setProductList] = useState([])
  
  const typeOptions = [
    { value: "percentage", label: t("percentage") },
    { value: "amount", label: t("amount") + ' ' + '(EUR)' }
  ];

  const handleSelectTypology = (type) => {
    if (type) {
      editPromo({ discount_type: type.value });
    }
  };

  const selectorOptions = catalog?.product_categories
    .filter(product_category => product_category.products !== undefined)
    .map(product_category => ({
    "label": product_category.name ? product_category.name : product_category.tpv_name,
    "options": product_category.products.map(product => ({
    "label": product.name ? product.name : product.tpv_name,
    "value": product.id,
    "product_image": product.image ? product.image : "",
    "product_description": product.description ? product.description : "",
    "product__tpv_name": product.name ? product.name : product.tpv_name,
    "product__tpv_price_cents": product.tpv_price_cents,
    "product_id": product.id,
    "isDisabled": product.product_discount !== null && !discountToEdit
  }))
  }));

  useEffect(() => {
    if(catalog && selectorOptions && discount.products__id){
      // Flatten the selectorOptions to get a list of all products
      const allProducts = selectorOptions.flatMap(category => category.options);

      // Filter the products based on the IDs in discount.products__id
      const matchingProducts = allProducts.filter(product => discount.products__id.includes(product.value)); 
      
      const newProductList = matchingProducts.map(product => ({
        label: product.label,
        value: product.value,
        product_image: product.product_image,
        product_description: product.product_description,
        product__tpv_name: product.product__tpv_name,
        product__tpv_price_cents: product.product__tpv_price_cents,
        product_id: product.product_id,
        "isDisabled": product.product_discount !== null && !discountToEdit
      }))
      
      setProductList(newProductList);
      editPromo({ products: newProductList })
    }
  }, [catalog])

  const Option = (props) => {
    return (
      <div>
      <components.Option {...props} isDisabled={props.isDisabled}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <label>{props.label}</label>
          <input
            className="checkboxRestaurantSelect"
            type="checkbox"
            checked={!!productList.find((p_l) => p_l.product_id === props.value)}
            onChange={() => null()}
            disabled={props.isDisabled}
          />{" "}
          <span></span>
          </div>
        </components.Option>
      </div>
    );
  };

  const handleChangeOption = (selectedOptions) => {
    if (!selectedOptions) {
      setProductList([]);
      editPromo({ products: [] });
      return;
    }
  
    const newProductList = selectedOptions.map((option, index) => ({
      ...option,
      order: index,
    }));

    editPromo({ products: newProductList });
    setProductList(newProductList);
  };
  
  if(!catalog){
    return(
      <Spinner></Spinner>
    )
  }

  if(catalog){
    return (
      <div className="flex gap-4 justify-around my-10">
        <DiscountPreview discount={discount} product={productList?.[0]}/>
        <div className="flex flex-col gap-8 w-96">
          {discount.typology === DISCOUNT_TYPOLOGIES.PRODUCT &&
            <SelectInput
              label={'select one or several products'}
              value={productList}
              options={selectorOptions}
              placeholder={'select'}
              onChange={handleChangeOption}
              isMulti={true}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isClearable={false}
              components={{Option}}
            />
          }
          <SelectInput
            label={'discount_type'}
            value={typeOptions.find(type => type.value === discount?.discount_type)}
            options={typeOptions}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={handleSelectTypology}
            isClearable={false}
            components={{ OptionDiscountType, MultiValue }}
            isOptionDisabled={(option) => option.disabled}
          />
          <NumberInput
            label={'discount_value'}
            placeholder={'0'}
            value={discount?.value || ''}
            onChange={e => editPromo({ value: e.target.value })}
            unit={discount?.discount_type === 'percentage' ? '%' : '€'}
          />
          {discount.typology === DISCOUNT_TYPOLOGIES.COUPON &&
            <NumberInput
              label='minimum_redeem_amount'
              placeholder='0'
              value={discount?.minimumRedeemEuros || ''}
              onChange={e => editPromo({ minimumRedeemEuros: e.target.value })}
              unit='€'
            />
          }
        </div>
      </div>
    )};
  }


const OptionDiscountType = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          className="checkboxRestaurantSelect"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null()}
        />{" "}
        <span></span>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxVisible = 2;
  const hiddenValues = getValue().slice(maxVisible).length;

  if (index > maxVisible) return <></>;

  if (index === maxVisible)
    return (
      <div className="bg-[#E6E6E6] rounded-sm p-[3px] min-w-[26.4px] text-[85%] text-center ml-auto">
        {"+" + hiddenValues}
      </div>
    );

  return <components.MultiValue isClearable={false} {...props} />;
};
