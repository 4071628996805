import { InformationCircleIcon } from '@heroicons/react/outline';
import { DeltaIndicator } from 'components/yumminn/DeltaIndicator';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DataCard = ({data, label, delta, color = 'black', bgColor = '#F9F9F9', row = false, size = 'medium', className}) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={`flex gap-2 rounded-lg relative ${size === 'small' ? 'px-2 py-3' : 'p-3'} ${className ? className : 'w-28'} `}
      style={{flexDirection: row ? 'row' : 'column', alignItems: row ? 'center' : 'start', backgroundColor: bgColor}}
    >
      {delta !== undefined &&
        <DeltaIndicator delta={delta} small className={row ? 'absolute top-1 right-1' : `ml-auto -mt-2 ${size === 'medium' ? '-mb-6 -mr-2' : size === 'large' ? 'mb-auto -mr-2' : '-mr-1'}`}/>
      }
      <b className={`leading-[1em] text-[${color}] ${size === 'small' ? 'text-base' : 'text-2xl'}`}
        style={{color: color}}
      >{data}</b>
      <div className='flex items-center gap-1 w-full'>
        <span className={`text-[0.65rem] font-medium text-[${color}] leading-[1em] tracking-tight flex-1 w-min max-w-max`}
        style={{color: color}}>
          {t(label)}
        </span>
        <InformationCircleIcon
          height={14}
          width={14}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className='text-gray-400'
        />
      </div>
      <div className={`absolute top-[calc(100%+0.25rem)] max-w-[10rem] w-max text-xs shadow right-0 p-2 bg-white rounded-md z-10 transition-opacity ${showTooltip ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        {t(`info_${label}`)}
      </div>
    </div>
  );
};
