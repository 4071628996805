import React from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import SettingsTab from './SettingsTab';
import MenuContainer from 'layouts/MenuContainer';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { RadioButtonGroup } from 'common/RadioButtonGroup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const tabs = [
  { label: 'general', value: '/settings' },
  { label: 'description', value: '/settings/description' },
  { label: 'wifi', value: '/settings/wifi' }
];

const SettingsContainer = ({ children, title, type}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isRowBased = useMediaQuery("(max-width: 760px)");

  const changeTab = (path) => {
    if (path === history.location.pathname) return;
    history.push(path);
  };

  return (

    <div id="settingsContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <MenuContainer style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col w-[calc(100%-85px)]'}`}>
        
        <div className='flex gap-8 items-center px-[32px] py-5 bg-[#E2FEF1]'>
          <h1 className='font-bold text-[24px] text-[#094553]'>
            {t(title)}
          </h1>
          <RadioButtonGroup value={history.location.pathname} options={tabs} onChange={changeTab}/>
        </div>
          
        <div className={`${isRowBased && 'bg-[#EFF8F4]'}`} >
          <div className='px-[32px] bg-white py-4'>
            <RestaurantSelect />
          </div>
          <div className='px-[32px]'>{children}</div>
        </div>
      </div>
    </div>
  );};

export default SettingsContainer;
