import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Popup } from 'common/Popup';
import { SeeAtWebAppContainer, BodyQrContainer, QRExplanation } from './EventsContainers';
import { Button } from 'common/Button';
import QRCode from "react-qr-code";
import { Icon, IconType } from 'common/Icon';
import { TextInput } from 'common/Input';

export const SeeAtWebAppPopup = ({isOpen, confirm, qr}) => {
    const { t } = useTranslation();
    const restaurantHomeUrl = `${process.env.REACT_APP_URL}/${qr}`;

  
    return (
      <Popup isOpen={isOpen}>
        <SeeAtWebAppContainer>
            <h3>{t('see_event_at_webapp')}</h3>
            <hr></hr>
            <BodyQrContainer>
                <div className='flex flex-col gap-4 justify-center items-center mt-4'>
                    <QRCode
                    size={180}
                    value={restaurantHomeUrl}
                    />
                    <Button
                        label={t('download_qr')}
                        onClick={confirm}
                        rightIcon={<Icon type={IconType.DOWNLOAD}/>}
                    />
                </div>
                <QRExplanation>
                    <h2>{t('scan_or_copy_link')}</h2>
                    <p>
                        <Trans i18nKey="explanation_event_qr">
                            <b>Visualiza todos los eventos creados</b> en la webapp copiando el enlace o escaneando el QR.
                        </Trans>
                    </p>
                    <TextInput name='example-6' value={restaurantHomeUrl} copy/>
                </QRExplanation>
            </BodyQrContainer>
            <hr></hr>
            <div className='flex justify-end mt-2'>
                <Button
                    label={t('ready')}
                    onClick={confirm}
                />
            </div>
        </SeeAtWebAppContainer>
      </Popup>
    );
  };