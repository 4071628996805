import {
  ChevronDownIcon,
  ChevronLeftIcon,
  FilterIcon,
} from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProductCardMockup } from "../components";
import "../../../styles/animations.css";
import { STYLES } from "./CategoryStyle";
import styled from "styled-components";

const MarqueeContainer = styled.div`
  background-color: #fef282;
  position: relative;
  width: 100%;
  height: 20px;
  font-weight: 700;
  font-size: 8px;
  display: flex;
  color: black;
  align-items: center;

  .marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 8s linear infinite;
  }

  .marquee2 span {
    animation-delay: 4s;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
`;

export const PromotionPreview = ({ discount }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full p-1 pb-[2px] flex items-center gap-1 relative bg-[#404040] ">
        <ChevronLeftIcon
          width={13}
          className="flex-shrink-0 mr-auto text-white"
        />
        <span className={STYLES.SELECTED_SUPERGROUP}>{t("group")}</span>
        <span className={STYLES.SUPERGROUP}>{t("group")}</span>
        <span className={STYLES.SUPERGROUP}>{t("group")}</span>
        <FilterIcon width={13} className="flex-shrink-0 ml-auto opacity-0" />
      </div>
      <div className="w-full flex-1 flex flex-col relative">
        <div className="w-full px-2 py-[2px] flex items-center gap-1 relative">
          <span className={STYLES.COLAPSED_GROUP}>
            {t("category")}
            <ChevronDownIcon width={10} className={`flex-shrink-0`} />
          </span>
          <span className={STYLES.GROUP}>
            {t("category")}
            <ChevronDownIcon width={10} className="flex-shrink-0" />
          </span>
          <span className={STYLES.GROUP}>
            {t("category")}
            <ChevronDownIcon width={10} className="flex-shrink-0" />
          </span>
          <span className={STYLES.GROUP}>
            {t("category")}
            <ChevronDownIcon width={10} className="flex-shrink-0" />
          </span>
        </div>
        <MarqueeContainer>
          <p className="marquee">
            <span>{discount && discount.marquee_text ? discount.marquee_text : t(`example_general_discount_marquee_text`)}</span>
          </p>
          <p className="marquee marquee2">
            <span>{discount && discount.marquee_text ? discount.marquee_text : t(`example_general_discount_marquee_text`)}</span>
          </p>
        </MarqueeContainer>
        <div className="flex-1 flex flex-col gap-2 p-2 relative">
          <ProductCardMockup allergens description={"long"} discount={discount}/>
          <ProductCardMockup allergens description={"short"} discount={discount}/>
          <ProductCardMockup allergens description={"long"} discount={discount}/>
          <ProductCardMockup allergens description={"short"} discount={discount}/>
          <ProductCardMockup allergens description={"short"} discount={discount}/>
        </div>
      </div>
    </div>
  );
};
