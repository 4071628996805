import React from 'react';

const ArrowIndicator = ({value, small = false}) => {
  return (
    value ?
      <div className='inline-block w-0 h-0'
        style={{
          borderLeft: `${small ? '6px' : '10px'} solid transparent`,
          borderRight: `${small ? '6px' : '10px'} solid transparent`,
          borderBottom: value > 0 ? `${small ? '6px' : '10px'} solid currentColor` : "",
          borderTop: value < 0 ? `${small ? '6px' : '10px'} solid currentColor` : ""
        }}
      />
      :
      <div className={`inline-block leading-4 font-black text-current text-center ${small ? 'w-3 text-base' : 'w-5 text-lg'}`}>
        {'='}
      </div>
  );
};

export default ArrowIndicator;