import React, { useContext, useMemo, useState } from 'react';
import { PanelTemplate } from './LockerContainers';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { OrderData } from './OrderData';
import { LockersContext } from 'data/LockersContext';
import Select from 'react-select';
import { OrderOption, OrderSingleValue } from './SelectComponents';
import { locker_select_styles } from 'logic/defaults';
import { getOptionFromOrder, moveOrdertoLocker } from './functions';

export const LockerPanel = ({lockerId, manageOrder, close}) => {
  const { state: { lockers, orders }, moveOrder } = useContext(LockersContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false)

  const locker = useMemo(() => lockers.find(locker => locker.id === lockerId), [lockers, lockerId]);
  
  const orderOptions = useMemo(() => orders.map(order => getOptionFromOrder(order)), [orders]);

  const replaceOrder = async ({value}) => {
    const promise = async () => {
      await moveOrdertoLocker({order: value, locker: locker.id, moveOrder, t});
      setIsLoading(false);
    };
    
    setIsLoading(true);

    try {
      locker.order ? manageOrder(locker.order, promise) : promise();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const clearLocker = () => locker.order && manageOrder(locker.order, close);

  return (
    <PanelTemplate>
      <header>
        <h3>{locker.name}</h3>
        <button onClick={close}>
          <XIcon height={24}/>
        </button>
      </header>
      {locker.order &&
        <div className='locker_actions'>
          <Select
            name={'replace_order'}
            placeholder={t(`replace_order`)}
            options={orderOptions}
            styles={locker_select_styles}
            onChange={replaceOrder}
            components={{SingleValue: OrderSingleValue, Option: OrderOption}}
            value={getOptionFromOrder(locker.order)}
            isLoading={isLoading}
            hideSelectedOptions
            controlShouldRenderValue={false}
          />
          <button onClick={clearLocker} className='clear'>
            {t('clear_locker')}
          </button>
        </div>
      }
      <hr/>
      {locker.order &&
        <OrderData order={locker.order}/>
      }
    </PanelTemplate>
  );
};
