import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/outline';

const chevronSize = 27;
const buttonSize = 44;

const ExternalContainer = styled.div`
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    
    h3 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        color: #404040;
    }
    
    p {
        max-width: 28ch;
        margin: 0;
        color: #404040;
    }

    b {
        font-weight: 600;
    }
`;
const MonthPicker = styled.div`
    width: -webkit-fill-available;
    padding: 11px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #404040;
    margin-top: 5px;
    margin-bottom: 2px;

    img {
        min-width: ${chevronSize}px;
        max-width: ${chevronSize}px;
        min-height: ${chevronSize}px;
        max-height: ${chevronSize}px;
    }
`;
const MonthText = styled.p`
    align-self: center;
    width: fit-content;
    font-size: 18px;
    font-weight: 500;
    user-select: none;
`;
const WeekdayInitialsContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: -webkit-fill-available;
    margin-top: 8px;
    user-select: none;
`;
const WeekdayInitial = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;

    p {
        font-weight: 300;
        font-size: 14px;
    }
`;
const Calendar = styled.div`
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    user-select: none;
`;
const DayButtonContainer = styled.div`
    height: 54px;
    display: flex;
    justify-content: center;
`;
const DayButton = styled.div`
    width: ${buttonSize}px;
    height: ${buttonSize}px;
    align-self: center;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &.selectable {
        background-color: #5FB894;
        border-radius: ${buttonSize}px;
        border: 1px solid #404040;
        p {
          color: white;
          font-weight: 600;
      }
    }

    &.unavailable {
        border-radius: ${buttonSize}px;
        border: 1px solid #404040;
        opacity: 40%;
        p {
          color: #404040;
          font-weight: 600;
      }
    }

    &.selected {
        background-color: #1E1E1E;
        border-radius: ${buttonSize}px;
        p {
          color: white;
          font-weight: 600;
        }
    }

    p {
        //font-family: Roboto;
        font-weight: 100;
        font-size: 13px;
        align-self: center;
    }
`;

const generateDaysForMonth = (year, month) => {
    const numberOfDaysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonthWeekPosition = new Date(year, month, 1).getDay();
    const days = [];
    for (let i = 0; i < firstDayOfMonthWeekPosition - 1; i++) {
        days.push(null);
    }
    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      days.push(i);   
    }
    return days;
}

function capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const currentDay = currentDate.getDate();
const daysForCurrentMonth = generateDaysForMonth(currentYear, currentMonth);

export const BookingDatePicker = ({ setShowDatePicker, diners, weekdaysMaxDiners, daysDiners, selectedDate, setResult }) => {
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth);
    const [days, setDays] = useState(daysForCurrentMonth);
    const weAreInCurrentMonth = year === currentYear && month === currentMonth;
    const weAreInNextYearMonth = year === currentYear + 1 && month === currentMonth - 1;
    const { t } = useTranslation();

    useEffect(() => {
        if (month > 11) {
            setMonth(0);
            setYear(year + 1);
        } else if (month < 0) {
            setMonth(11);
            setYear(year - 1);
        }
        
    }, [month]);

    useEffect(() => {
        setDays(generateDaysForMonth(year, month));
    }, [month, year]);

    return (
      <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
       
          <div style={{display: "flex", width: "30%", minWidth: "420px", top: "5%", left: "35%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
            <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: 'flex-end'}}>
                <button style={{margin: "0 15px", outline: 'none'}} onClick={() => {setShowDatePicker(false)}}><XIcon className={`w-[25px] text-gray-700 cursor-pointer`}/></button>
            </div>
            <ExternalContainer>
            <h3>{t('Selecciona una fecha')}</h3>
            <MonthPicker>
                <ChevronLeftIcon
                    style={{height: "20px", cursor: weAreInCurrentMonth ? 'not-allowed' : 'pointer', opacity: weAreInCurrentMonth ? '20%': '100%'}}
                    onClick={() => {
                        if (weAreInCurrentMonth) return;
                        setMonth(month - 1);
                    }}
                />
                <MonthText>
                    {capitalizeFirstLetter(new Date(year, month, 1).toLocaleString('es', { month: 'long' })) + " " + year.toString()}
                </MonthText>
                <ChevronRightIcon
                    style={{height: "20px", cursor: weAreInNextYearMonth ? 'not-allowed' : 'pointer', opacity: weAreInNextYearMonth ? '20%': '100%'}}
                    onClick={() => {
                        if (weAreInNextYearMonth) return;
                        setMonth(month + 1);
                    }}
                />
            </MonthPicker>
            <WeekdayInitialsContainer>
                <WeekdayInitial><p>{t('Lun')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Mar')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Mie')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Jue')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Vie')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Sab')}</p></WeekdayInitial>
                <WeekdayInitial><p>{t('Dom')}</p></WeekdayInitial>
            </WeekdayInitialsContainer>
            <Calendar>
                {days.map((day, index) => {
                    const date = new Date(year, month, day);
                    const weekday = date.getDay();
                    let maxDiners = weekdaysMaxDiners[weekday === 0 ? 5 : weekday - 1];          
                    daysDiners.forEach((element) => {
                        //console.log(`date__year: ${element.date__year}, date__month: ${element.date__month}, date__day=${element.date__day}, sum_diners: ${element.sum_diners}`);
                        if (element.date__year === year && element.date__month === month + 1 && element.date__day === day) {
                            maxDiners -= element.sum_diners;                        
                        }
                    });
                    maxDiners = maxDiners >= 0 ? maxDiners : 0;
                    const isVoidButton = !day;
                    const isDayPrevToToday = month === currentMonth && day < currentDay;
                    const isUnavailable = diners > maxDiners;
                    const isSelected = selectedDate ? date.getTime() === selectedDate.getTime() : false;
                    return (
                        <DayButtonContainer key={'calendar_day_' + index}>
                            <DayButton
                                className={isVoidButton || isDayPrevToToday ? '' : isUnavailable ? 'unavailable' : isSelected ? 'selected' : 'selectable'}
                                onClick={() => {
                                    if (isDayPrevToToday || isUnavailable) return;
                                    setResult(date);
                                }}
                            >
                                <p>{day ? day : ''}</p>
                            </DayButton>
                        </DayButtonContainer>
                    );
                })}
              </Calendar>
            </ExternalContainer>
          </div>
          <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
        </div>
    );
};
