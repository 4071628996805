import { baseAuth, orderAndPay } from 'logic/api';
import useSWR from 'swr';

const fetcher = endpoint => baseAuth
  .get(endpoint)
  .then(res => res.data)
  .catch(e => console.log(`file: useDiscounts.jsx:7 > fetcher > error:`, e));

const catalogFetcher = endpoint => orderAndPay
  .get(endpoint)
  .then(res => res?.data?.products_and_categories)
  .catch(e => console.log(`file: useDiscounts.jsx:12 > catalogFetcher > error:`, e));

const hasActiveGeneralDiscount = (data) => {
  if (!data) return false;
  return data.some(discount => discount.typology === 'general_discount' && discount.active);
};

const hasActiveProductDiscount = (data) => {
  if (!data) return false;
  return data.some(discount => discount.typology === 'product_discount' && discount.active);
};

export const useDiscounts = ({ selectedRestaurantId }) => {
  const { data: discountsData, isValidating, mutate } = useSWR(
    `/discount/list/${selectedRestaurantId}/`,
    fetcher,
  );

  const { data: catalog, isLoading: loadingCatalog } = useSWR(
    `/catalog?restaurant=${selectedRestaurantId}`,
    catalogFetcher,
  );

  return {
    discounts: discountsData?.discounts || [],
    testTableUrl: discountsData?.test_table_url || '',
    catalog,
    activeGeneralDiscount: hasActiveGeneralDiscount(discountsData?.discounts),
    activeProductDiscount: hasActiveProductDiscount(discountsData?.discounts),
    isValidating,
    loadingCatalog,
    mutate,
  };
};
