import React from 'react';
import { svgs } from './svgs';
import styled from 'styled-components';

export const IconType = Object.keys(svgs).reduce((keys, key) => {
  keys[key] = key;
  return keys;
}, {});

const Btn = styled.button`
  --click_margin: ${({size}) => Math.max((32 - size) / 2, 0) + 'px'}; // add margin to click if svg is too small

  padding: var(--click_margin);
  margin: calc(-1 * var(--click_margin));
  border-radius: ${({size}) => Math.max(size, 32) + 'px'};
  background-color: transparent;
  flex: 0 0 auto;
  transition: background-color 0.15s ease-in-out;
  overflow: visible;
  color: ${({color}) => color || '#094553'};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid currentColor;
    outline-offset: 3px;
  }

  &:hover, &:focus-visible {
    background-color: #26DFA833;
  }

  &:disabled, &.loading {
    background-color: transparent;
    opacity: 0.5;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.loading {
    cursor: wait;
  }
`;

export const Icon = ({ type, size, color, onClick, ...btnProps}) => {
  const svg = svgs[type]?.(size, color || 'currentColor');

  if (svg && onClick) return (
    <Btn
      onClick={onClick}
      size={size || 20}
      color={color}
      className={`${btnProps?.className} ${btnProps?.loading ? 'loading' : ''}`}
      {...btnProps}
    >
      {svg}
    </Btn>
  );

  return svg || <></>;
};

export * from './SquareIcon';