import React from 'react'
import { CardDetails, WalletLinkCard, ExtraCredits, BadgeBonus, CardDetailsWrapper } from "./WalletContainers";
import { useTranslation } from 'react-i18next';


export const calculateExtraCredits = (bonus, credit_amount, type) => {
    let extraCredits = 0;    
    if(type === 'percentage'){
        extraCredits = bonus * credit_amount / 100
    } else {
        extraCredits = bonus
    }
    return beautifyDecimalsAmount(extraCredits); 
}
   
export const beautifyDecimalsAmount = (amount) => {
    const beautifulAmount = amount.toString().replace('.', ',').endsWith(',00') ? amount.toString().replace('.', ',').slice(0, -3) : amount.toString().replace('.', ',')

    if (beautifulAmount.includes(',')) {
        const parts = beautifulAmount.split(',');

        if (parts[1] && parts[1].length === 1) {
            return `${parts[0]},${parts[1]}0`;
        }
    }

    return beautifulAmount
}

const WalletCardPreview = ({ selected, item, theme }) => {
  const { t } = useTranslation();

  return (
    <WalletLinkCard theme={theme} key={'topup_value' + item.id} selected={selected}>
        <CardDetailsWrapper>
            <CardDetails>
                <h4>{beautifyDecimalsAmount(item.credit__amount)}€</h4>
                <p>{t('of topup')}</p>
            </CardDetails>
            <BadgeBonus>
                {item.credit__bonus_type === 'percentage' && <span>+{Number(item.credit__bonus)}%</span>}
            </BadgeBonus>
        </CardDetailsWrapper>
        {item.credit__bonus > 0 && 
            <ExtraCredits>
                <b>+{calculateExtraCredits(item.credit__bonus, item.credit__amount, item.credit__bonus_type)}€</b> 
                <i>{t('extra')}</i>
            </ExtraCredits>
        }
  </WalletLinkCard>
  )
}


export default WalletCardPreview


