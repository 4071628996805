import React,{useState, useEffect, useContext} from 'react'
import StepperInput from 'common/StepperInput';
import { BookingDatePicker } from './BookingDatePicker';
import moment from 'moment';
import { baseAuth } from 'logic/api';
import { Context as AuthContext } from 'data/authContext';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { BookingHourPicker } from './BookingHourPicker';
import { BookingPhoneInput } from './BookingPhoneInput';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-phone-input-2/lib/style.css'

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  )
};

const fillWithLeftZero = (value) => {
  const valueString = value.toString();
  return valueString.length > 1 ? valueString : "0" + valueString;
};

export const BookingSettings = ({
  bookingDraft, 
  changeName, 
  changePhone, 
  changeEmail, 
  changeDiners, 
  changeDate, 
  changeHour, 
  weekdaysMaxDiners, 
  daysDiners,
  errorName, 
  errorEmail, 
  errorDiners,
  errorHour, 
  errorDate
}) => {  
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showHourPicker, setShowHourPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingShifts, setBookingShifts] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [validEmail, setValidEmail] = useState(true)
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const {t} = useTranslation();

  useEffect(() => {
    getAvailableHours(new Date(bookingDraft.date))
  }, [bookingDraft.date]);

  const getAvailableHours = (date) => {
    setLoading(true);
    const id = selectedRestaurant[0].value
    let endpoint = `booking/list/booking_shift/0/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/?restaurant=${id}`;
    baseAuth
      .get(endpoint)
      .then(res => {
        const booking_shifts = res.data.booking_shifts
        const booked_slots = res.data.booked_slots
        setBookingShifts(booking_shifts);
        setBookedSlots(booked_slots)
      })
      .catch(err => console.log(`Bookings.jsx:getData ~ endpoint=${endpoint} catch: ${err.toString()}`))
      .finally(_ => {
        setLoading(false);
      });
  };
  const handleDatePick = (date) => {
    changeDate(moment(date).format('YYYY-MM-DD'))
    setShowDatePicker(false)
  }

  const handleHourPick = (hour, minute) => {
    changeHour(hour, minute)
    setShowHourPicker(false)
  }
  const phoneUtil = PhoneNumberUtil.getInstance();

  const validatePhoneNumber = (phoneNumber) => {
    try {
      const parsedNumber = phoneUtil.parse(phoneNumber, undefined);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
      return false;
    }
  };

  const handleBlurPhone = (phoneNumber) => {
    const number = phoneNumber?.target?.value
    const isValid = validatePhoneNumber(number)
    if(isValid){
      setIsPhoneValid(true)
    } else {
      setIsPhoneValid(false)
    }
    return
  };

  const checkEmail = (e) => {
    if(e.length === 0){
      setValidEmail(true);
    }
    else if (e.length > 0 && !(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(e))) {
      setValidEmail(false);
    }
    else {
      setValidEmail(true);
    }
  };
  
  return (
      <>
        <div style={{display: 'flex', userSelect: 'none', flexDirection: 'column', width: "100%", alignItems: 'center', justifyContent: 'center', marginBottom: `${errorName||errorEmail||errorDate||errorHour||errorDiners ? '20px' : ''}`}}>          
        {loading && <LoadingOverlay />}
          <div style={{display: 'grid', width: '90%', gridTemplateColumns: "1fr 1fr", marginTop: `${errorName||errorEmail||errorDate||errorHour||errorDiners ? '40px' : '10px'}`, gap: "50px"}}>
            <div>
              <div style={{fontSize: '14px', fontWeight: 500}}>{t("customer_name")}<span className='text-red-600'>*</span></div>
                <div style={{marginTop: "5px"}}>
                  <input
                  type='text'
                  placeholder='Nombre del cliente'
                  className='yumminn-input'
                  value={bookingDraft.name}
                  name='shift_name'
                  style={{width: '10ch'}}
                  maxLength={50}
                  onChange={(e) => changeName(e.target.value)}
                  /> 
                  {errorName && <div className='text-[10px] pt-[5px] pl-1 text-[#E57070] italic'>{t(errorName)}</div>}              
                </div>
              </div>
              <div>
                <div style={{fontSize: '14px', fontWeight: 500}}>{t("Email")}<span className='text-red-600'>*</span></div>
                <div style={{marginTop: "5px"}}>
                  <input
                    type='text'
                    placeholder='Email del cliente'
                    className='yumminn-input'
                    value={bookingDraft.email}
                    name='shift_name'
                    style={{width: '10ch'}}
                    maxLength={50}
                    onChange={(e) => changeEmail(e.target.value)}
                    onBlur={(e) => checkEmail(e.target.value)}
                  />         
                  {(errorEmail || !validEmail) && <div className='text-[10px] text-[#E57070] pt-[5px] pl-1 italic'>{errorEmail ? t(errorEmail) : t("compulsory_email_error")}</div>}        
                </div>
              </div>
            </div>
            <div style={{display: 'grid', width: '90%', gridTemplateColumns: "1fr 1fr", marginTop: '20px', gap: "50px"}}>
              <div>
                <div style={{fontSize: '14px', fontWeight: 500}}>{t("phone")}</div>
                  <div style={{marginTop: "5px"}}>
                    <BookingPhoneInput
                      placeholder={'phone'}
                      value={bookingDraft.phone}
                      onBlur={handleBlurPhone}
                      onChange={changePhone}
                      error={!isPhoneValid && 'valid_phone'}
                    />
                  </div>
                </div>
              <div>
                <div style={{fontSize: '14px', fontWeight: 500}}>{t("diners_amount")}<span className='text-red-600'>*</span></div>
                <div style={{marginTop: "5px"}}>
                  <StepperInput value={bookingDraft.diners} changeValueFunc={changeDiners} disabledDown={bookingDraft.diners < 2}/>
                  {errorDiners && <div className='text-[10px] pt-[5px] pl-1 text-[#E57070] italic'>{t(errorDiners)}</div>}
                </div>
              </div>
            </div>
            <div style={{display: 'grid', width: '90%', gridTemplateColumns: "1fr 1fr", marginTop: '20px', gap: "50px"}}>
              <div>
                <div style={{fontSize: '14px', fontWeight: 500, textTransform: 'capitalize'}}>{t("date")}<span className='text-red-600'>*</span></div>
                <div style={{cursor: 'pointer', marginTop: "5px", borderRadius: '8px', height: '42px', padding: "2px 16px", border: "1px solid #DDDDDD", display: 'flex', alignItems: 'center'}} onClick={() => setShowDatePicker(true)}>{bookingDraft.date ? bookingDraft.date : "-"}</div>
                {errorDate && <div className='text-[10px] pt-[5px] pl-1 text-[#E57070] italic'>{t(errorDate)}</div>}
              </div>
              <div>
                <div style={{fontSize: '14px', fontWeight: 500, textTransform: 'capitalize'}}>{t("hour")}<span className='text-red-600'>*</span></div>
                <div style={{cursor: bookingDraft.date ? 'pointer' : "not-allowed", marginTop: "5px", borderRadius: '8px', height: '42px', padding: "2px 16px", border: "1px solid #DDDDDD", display: 'flex', alignItems: 'center'}} onClick={() => setShowHourPicker(true)}>{bookingDraft.hour ? `${fillWithLeftZero(bookingDraft.hour)}:${fillWithLeftZero(bookingDraft.minute)}` : "-"}</div>
                {errorHour && <div className='text-[10px] pt-[5px] pl-1 text-[#E57070] italic'>{t(errorHour)}</div>}
              </div>

            </div>
        </div>
        {showDatePicker && <BookingDatePicker setShowDatePicker={setShowDatePicker} loading={loading} setResult={handleDatePick} diners={bookingDraft.diners} selectedDate={new Date(bookingDraft.date)} weekdaysMaxDiners={weekdaysMaxDiners} daysDiners={daysDiners}/>}
        {showHourPicker && <BookingHourPicker loading={loading} setResult={handleHourPick} shifts={bookingShifts} bookedSlots={bookedSlots} hour={bookingDraft.hour} minute={bookingDraft.minute} weekdaysMaxDiners={weekdaysMaxDiners} daysDiners={daysDiners}/>}

      </>
  )
}
