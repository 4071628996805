import React from "react";
import { Context as AuthContext } from '../../data/authContext';

const useCompany = () => {
  const {
    state: { selectedCompany },
  } = React.useContext(AuthContext);

  const [companySelected, setCompanySelected] = React.useState(null);

  React.useEffect(() => {
    const companySelected =
      JSON.parse(localStorage.getItem('yumminn-selectedCompany')) || selectedCompany 

    if (companySelected) {
        setCompanySelected(companySelected);
    }
    return () => {
        setCompanySelected({}); // This worked for me
    };
  }, [selectedCompany]);

  return {
      companySelected
  };
};

export default useCompany;
