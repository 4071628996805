import React from "react";
import {useLazyAxios} from 'use-axios-client';
import {apiAuth} from 'logic/api';
import Loading from "../../../common/Loading";
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import pencil from '../../../img/pencil.png';
import trash from '../../../img/trash.png';
import Switch from "react-switch";
import {toast} from 'react-toastify';
import {sayError} from 'logic/defaults';
import useDeleteModal from 'components/hooks/useDeleteModal';
import dragAnddrop from '../../../img/drag&drop.png';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import useUpdateTableData from 'components/hooks/useUpdateTableData';
import { Context } from "data/authContext";

const MenuList = ({setGrupos, data, setNewtab, setMenuId}) => {
    

	const {
		state: { selectedRestaurant },
	} = React.useContext(Context);
    
    const {t} = useTranslation();
    const id = selectedRestaurant[0]?.value
    const [getMenusData, {data: menus, error: errorMenus, loading: loadingMenus}] = useLazyAxios({
         axiosInstance: apiAuth, url: `/restaurants/${id}/menus`,
    }); 
    const { updateData, error: updatedError } = useUpdateTableData({
      entidad: 'restaurants-admin-new',
      id,
    });
    const [items, setItems] = React.useState([])
    const isRowBased = useMediaQuery("(max-width: 760px)");

    React.useEffect(() => {
      //
      if(selectedRestaurant.length > 0){
        getMenusData(); 
      }
    }, [getMenusData, selectedRestaurant]);

    React.useEffect(() => {
      setItems(menus)
    }, [menus])

    const handleChange = async(id, status) => {
        const newStatus = !status
        try {
            await apiAuth(`/restaurants/${id}/modify_menu/?id=${id}`, {
              method: 'PATCH', 'Content-Type': 'multipart/form-data', data: {is_active: newStatus},
            });
            getMenusData()
          } catch (error) {
            console.error(error);
            toast.error(sayError(error.response.data));
          }
    }

    const handleDeleteMenu = async (id) => {
        try {
          await apiAuth(`/restaurants/${id}/delete_menu/?id=${id}`, {method: 'PATCH'});
          getMenusData();
        } catch (error) {
            console.error(error);
            toast.error(sayError(error.response.data));
        }
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
          return;
      }
      const newItems = [...items];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      for(let i=0; i<newItems.length; i++){
        newItems[i].order = i
      }
    try {
        apiAuth(`/restaurants/${id}/order_menu/`, {
          method: 'PATCH', data: newItems,
        });
        getMenusData()
        setItems(newItems)
      } catch (error) {
        console.error(error);
        toast.error(error.response.data);
      }
    };

    const handleEditMenu = (id) => {
      const thisMenu = menus.map((m) => ({
        id: m.id,
        name: m.name,
        link: m.link,
        file: m.file,
        is_active: m.is_active,
        order: m.order,
        visibility: m.visibility
      })).filter((m => m.id === id));
      setMenuId(id)
      setGrupos([
        ...thisMenu
      ]);
    }

    const handleEditLink = () => {
      setGrupos((prev) => [
        ...prev,
        {
          name: data.menu_link_name,
          restaurant: id,
          link: data.menu_link,
          items: [],
        },
      ]);
    }

    const handleDeleteLink = () => {
      delete data.adyen_config
      delete data.manager
      delete data.tpv
      data.menu_link = ""
      data.menu_link_name = ""
      data.new_tab_menu = false
      setNewtab(false)
      updateData(data)
    }

    const menuModal = useDeleteModal(handleDeleteMenu);

    if (!items || !data) return <Loading />;

    if (data && data.new_tab_menu) {
        return (
          <>
            {!isRowBased && 
            <div className='bg-white grid grid-cols-9 p-6 border-b-2 mt-8'>
              <div style={{color: '#767674'}} className={'font-bold col-span-2'}>{t('Name')}</div>
              <div style={{color: '#767674'}} className={'font-bold col-span-2'}>{t('Visibility schedule')}</div>
              <div style={{color: '#767674'}} className={'font-bold col-span-2'}>{t('Link')}</div>
              <div style={{color: '#767674'}} className={'font-bold col-span-2'}>{t('Options')}</div>
            </div>}
            <div className={`${!isRowBased ? 'bg-white grid grid-cols-9' : 'bg-white'} p-6 border-b-2`}>
            <div className={`${!isRowBased ? 'col-span-2' : 'justify-start'} flex items-center`}>
                <div>{data.menu_link_name}</div>
            </div>
            {!isRowBased && <div className={`${!isRowBased ? 'col-span-2': 'justify-start '} flex items-center`}>{t('Always visible')}</div>}
            <div className={`${!isRowBased ? 'col-span-2' : 'justify-start'} flex items-center`}>
                <div className={`${!isRowBased ? 'w-36 truncate' : 'w-44 truncate'} `}>{data.menu_link}</div>
            </div>
            <div className={`${!isRowBased ? 'col-span-1' : 'justify-end'} flex items-center`}>
              <div onClick={() => handleEditLink()} className='w-50'>
                <img 
                    src={pencil} 
                    style={{width: '24px', height: "24px", cursor: "pointer"}} 
                    alt='edit'
                />
              </div>
              <div onClick={() => handleDeleteLink()}>
                <img 
                    src={trash} 
                    style={{width: '24px', height: "24px", marginLeft: '15px', cursor: "pointer"}} 
                    alt='delete'
                /> 
              </div>
            </div>
          </div> 
          </>
        )
    }
    
    if (items) {
      return (
        <>
        {!isRowBased &&
        <div className='bg-white grid grid-cols-10 p-6 border-b-2 mt-8'>
          <div style={{color: '#767674'}} className={'font-bold col-span-2'}>{t('Name')}</div>
          <div style={{color: '#767674'}} className={'font-bold col-span-4'}>{t('Visibility schedule')}</div>
          <div style={{color: '#767674'}} className={'font-bold col-span-2'}>{t('Modified')}</div>
          <div style={{color: '#767674'}} className={'font-bold col-span-1'}>{t('Active')}</div>
        </div>}
        {items.length < 1 ?
        
        <div className={'flex-col'}>
            <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
            className={`mb-5 bg-white ${!isRowBased && 'min-w-[420px]'}`}
            >
            <div className='flex justify-center p-28'>{t('No menus added yet')}</div>
            </motion.div>
        </div> 
        :
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
        {(provided, snapshot) => (  
        <div 
          className={'flex-col'}
          {...provided.droppableProps}  
          ref={provided.innerRef}  
        >
            <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
            className={`mb-5 bg-white ${!isRowBased && 'min-w-[420px]'}`}
            >
           

            {items.map((menu, idx) => {
                return(
                  <div key={`${menu.id}"firstDiv"}`}  className={``}>
                    <Draggable 
                        draggableId={menu.id.toString()}
                        key={menu.id} 
                        index={idx}
                    >
                     {(provided, snapshot) => (  
                      <div className={`${!isRowBased ? 'bg-white grid grid-cols-10' : 'grid grid-cols-2'} p-6 border-b-2`}
                            ref={provided.innerRef}  
                            key={`${menu.id}"divProvided"}`} 
                            {...provided.draggableProps}  
                            {...provided.dragHandleProps}>
                        <div className={`${!isRowBased ? 'col-span-2' : 'justify-start'} flex items-center`} key={`divDetail${menu.id}`} >
                            <img style={{width: "8px", height: "15px", marginRight: '10px', cursor: 'pointer'}} src={dragAnddrop} alt='drag&drop' />
                            <div>{menu.name}</div>
                        </div>
                        <div className={`${!isRowBased ? 'col-span-4': 'justify-start'} flex items-start`} key={`divDetail2${menu.id}`}>
                          {menu.visibility?.length > 0 ?
                            menu.visibility.map((vis, index) => {
                              return (
                                <div key={`visDiv${index}`} className="flex flex-col pr-3">
                                  <div key={`visDiv2${index}`} className="flex flex-col">
                                  {vis.selectedDays.length === 7 ? t('Everyday') :
                                    vis.selectedDays.map((value, index) => {
                                      return (
                                        <div className='flex' key={`selDays${index}`}> 
                                          <div className='capitalize' key={`selDays1${index}`}>{t(value)}</div>
                                          {vis.selectedDays.length > index+1 && <div key={`selDays2${index}`}>,</div>}
                                        </div>
                                      )
                                    })}
                                  </div>
                                  {vis.timeRanges.length > 0 ?
                                  <div key={`visDiv3${index}`} className="flex">
                                    <div key={`visDiv4${index}`}>
                                      {vis.timeRanges.map((value, index) => {
                                        let hoursFrom = (new Date(value.from)).getHours()
                                        if (hoursFrom < 10){
                                          hoursFrom = '0' + (new Date(value.from)).getHours()
                                        }
                                        let minutesFrom = (new Date(value.from)).getMinutes()
                                        if (minutesFrom < 10){
                                          minutesFrom = '0' + (new Date(value.from)).getMinutes()
                                        }
                                        return (
                                          <div className='flex' key={`from${index}`}>
                                            <div className='pr-1' key={`from1${index}`}>{t('From')}</div>
                                            <div key={`from2${index}`}>{hoursFrom + ":" + minutesFrom}</div>
                                          </div>
                                        )
                                      }
                                      )}
                                    </div>
                                    {/*<div key={`visDiv5${index}`} className='px-1'>{t('to')}</div>*/}
                                    <div key={`visDiv6${index}`}>
                                      {vis.timeRanges.map((value, index) => {
                                        let hoursTo = (new Date(value.to)).getHours()
                                        if (hoursTo < 10){
                                          hoursTo = '0' + (new Date(value.to)).getHours()
                                        }
                                        let minutesTo = (new Date(value.to)).getMinutes()
                                        if (minutesTo < 10){
                                          minutesTo = '0' + (new Date(value.to)).getMinutes()
                                        }
                                        return (
                                          <div className='flex' key={`from${index}`}>
                                            <div className='pr-1 pl-1' key={`from1${index}`}>{t('to')}</div>
                                            <div key={`from2${index}`}>{hoursTo + ":" + minutesTo}</div>
                                          </div>
                                        )
                                      }
                                      )}
                                    </div>
                                  </div> 
                                  : 
                                  <div>{t('All day')}</div>
                                  }
                                </div>
                                )})  
                          : t('Always visible')}
                        </div>
                        <div className={`${!isRowBased ? 'col-span-2' : 'justify-start'} flex items-center`} key={`divDetail3${menu.id}`}>{new Date(menu.modified_at).toLocaleString().split(",")[0]}</div>
                        <div className={`${!isRowBased ? 'col-span-1' : 'justify-start'} flex items-center`} key={`divDetail4${menu.id}`}>
                            {isRowBased && <span style={{marginRight: "10px"}}>{isRowBased && t('Active')}: </span>}
                            <Switch onChange={() => handleChange(menu.id, menu.is_active)} checked={menu.is_active} />
                        </div>
                        <div className={`${!isRowBased ? 'col-span-1' : 'justify-end'} flex items-center`} key={`divDetail5${menu.id}`}>
                          <div onClick={() => handleEditMenu(menu.id)}>
                              <img 
                                  src={pencil} 
                                  style={{width: '24px', height: "24px", cursor: "pointer"}} 
                                  alt='edit'
                                  
                              />
                          </div>
                          <div onClick={() => menuModal.openModal(menu.id)}>
                              <img 
                                  src={trash} 
                                  style={{width: '24px', height: "24px", marginLeft: '15px', cursor: "pointer"}} 
                                  alt='delete'
                              /> 
                          </div>
                        </div>
                      </div>
                      )}  
                    </Draggable>
                    </div>
            )}
            )}
            {provided.placeholder}
            </motion.div>
           
            <menuModal.Modal mensaje={t('Delete_menu')}/>

        </div> 
        )}
        </Droppable>
        </DragDropContext>
        }
        </>
      )
    }
}

export default MenuList;


