import React, { useState } from 'react';
import { ColumnContainer } from '../ExampleContainers';
import { RADIO_VARIANT, RadioButtonGroup } from 'common/RadioButtonGroup';
import { getGenericMultiValueHandler } from 'logic/functions';
import { Icon, IconType } from 'common/Icon';
import { BUTTON_SIZES } from 'common/Button';

const radioOptions = [
  {label: 'one', value: 1},
  {label: 'two', value: 2},
  {label: 'three', value: 3},
  {label: 'left icon', value: 4, leftIcon: <Icon type={IconType.CALENDAR}/>},
  {label: 'right icon', value: 5, rightIcon: <Icon type={IconType.PENCIL}/>},
];

export const RadioButtonExample = () => {
  const [value, setValue] = useState(1)
  const [multiValue, setMultiValue] = useState([1, 2, 4])

  const changeMultiValue = getGenericMultiValueHandler(setMultiValue);

  return (
    <ColumnContainer>
      <h2>RadioButtonGroup</h2>
      <h3>Single Value (radio)</h3>
      <RadioVariants
        value={value}
        options={radioOptions}
        onChange={setValue}
      />
      <h3>Multi Value (checkbox)</h3>
      <RadioVariants
        multiValue={multiValue}
        options={radioOptions}
        onChange={changeMultiValue}
      />
      <h3>Sizes</h3>
      <RadioVariants
        value={value}
        options={radioOptions}
        onChange={setValue}
        size={BUTTON_SIZES.LG}
      />
      <RadioVariants
        value={value}
        options={radioOptions}
        onChange={setValue}
        size={BUTTON_SIZES.MD}
      />
      <RadioVariants
        value={value}
        options={radioOptions}
        onChange={setValue}
        size={BUTTON_SIZES.SM}
      />
      <RadioVariants
        value={value}
        options={radioOptions}
        onChange={setValue}
        size={BUTTON_SIZES.XS}
      />
    </ColumnContainer>
  );
};

const RadioVariants = props => (
  <>
    <RadioButtonGroup {...props}/>
    <RadioButtonGroup {...props} variant={RADIO_VARIANT.ROUNDED_SQUARE}/>
  </>
);