import * as React from 'react';

function ArrowForwardCircle({width, height}) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <circle cx="14.5" cy="14.5" r="14.5" fill="#094553"/>
        <path d="M8.92296 13.7801C8.46535 13.7801 8.09439 14.151 8.09439 14.6087C8.09439 15.0663 8.46535 15.4372 8.92296 15.4372L8.92296 13.7801ZM21.0988 15.1945C21.4224 14.871 21.4224 14.3463 21.0988 14.0228L15.8258 8.74977C15.5022 8.42619 14.9776 8.42619 14.654 8.74977C14.3304 9.07334 14.3304 9.59797 14.654 9.92154L19.3411 14.6087L14.654 19.2958C14.3304 19.6193 14.3304 20.144 14.654 20.4675C14.9776 20.7911 15.5022 20.7911 15.8258 20.4675L21.0988 15.1945ZM8.92296 15.4372L20.5129 15.4372L20.5129 13.7801L8.92296 13.7801L8.92296 15.4372Z" fill="white"/>
      </svg>
      );
}

export default ArrowForwardCircle;
