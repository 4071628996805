import React from 'react'
import { Draggable } from 'react-beautiful-dnd';
import { TabButton } from './TabButton';


const getCategoryStyle = (draggableStyle, index) => ({
  userSelect: "none",
  whiteSpace: "nowrap",
  order: index,
  ...draggableStyle.style
});

export const DraggableTab = ({
  listId,
  item,
  index,
  disabled,
  disableHover,
  openEditModal,
  setSelected,
  selected,
  lockTabs
}) => {
  return (
    <Draggable
      index={index}
      draggableId={listId + '-' + item.id}
    >
      {(providedDrag, snapshotDrag) => {
        return (
          <div
            {...providedDrag.draggableProps}
            ref={providedDrag.innerRef}
            style={getCategoryStyle(providedDrag.draggableProps, index)}
            className={lockTabs ? '!transform-none' : ''}
          >
            <TabButton
              listId={listId}
              disabled={disabled}
              disableHover={disableHover}
              openEditModal={openEditModal}
              isDroppable={lockTabs && snapshotDrag.isDragging}
              provided={providedDrag}
              item={item}
              setSelected={setSelected}
              selected={selected}
              isLocked={lockTabs}
            />
          </div>
        )
      }}
    </Draggable>
  )
}
