import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@heroicons/react/outline";

const Wrapper = styled.div`
  flex-shrink: 0;
  border-radius: 21.074px 21.074px 21.077px 21.077px;
  background-color: white;
  position: relative;
  margin-top:10px;
  padding: 5px;
`;

const DiscountCouponPreview = ({discount}) => {
  const { t } = useTranslation();

  const type = (type) => {
    if(type === 'percentage') return '%'
    if(type === 'amount') return '€'
  }

  const calculateTotal = (type, value) => {
    if(type === 'percentage'){
      return (25 - (25 * value / 100)) + 1.25 + '€'
    }
    if(type === 'amount'){
      return (25 - value) + 1.25  + '€'
    }
    return '26,25€'
  }

  const defaultValue = 5;

  return (
    <Wrapper style={{height: '100vw'}}>
      <div className="flex flex-row w-full justify-center items-center p-2 text-[8px] font-semibold">
        <span className="absolute left-2 text-[8px]">
          <ChevronLeftIcon height={15} />
        </span>
        {t("payment_details")}
      </div>
      <hr className="border-dotted mx-4"></hr>
      <div>
        <div className="text-[6px] px-5 p-1 flex flex-row w-full justify-between items-center">{t('subtotal')}<span>25,00€</span></div>
        <div className="text-[6px] px-5 p-1 flex flex-row w-full justify-between">{t('tip')}(5%)<span>1,25€</span></div>
        <div className="text-[7px] px-5 p-1 flex flex-row w-full justify-between">{discount?.payment_details_text ? discount.payment_details_text : t(`example_${discount?.typology}_payment_details_text`)}<span>{discount?.value ? discount.value : defaultValue}{type(discount?.discount_type)}</span></div>
        <div className="text-[8px] font-semibold px-4 p-1 flex flex-row w-full justify-between items-center">{t('total')}<span className="rounded-full bg-[#EFF8F4] p-1">{calculateTotal(discount?.discount_type, discount?.value ? discount.value : defaultValue)}</span></div>
      </div>
      <div className="mt-[32px] bg-[#404040] flex items-center h-6 justify-center rounded-full text-white text-center text-[11px] w-full font-semibold px-4">{t('continue')}</div>
    </Wrapper>
  );
};

export default DiscountCouponPreview;
