export const newCustomGroupMock = {"id": null,
                              "relation_id": null,
                              "name": "",
                              "description": "",
                              "image": null,
                              "tag": "",
                              "custom_group_type": "multi",
                              "primary_color": "#D0EEE2",
                              "secondary_color": "#D0EEE2",
                              "border_color": "#404040",
                              "title_color": "#404040",
                              "addition_background_color": "#404040",
                              "addition_text_color": "#FFFFFF",
                              "card_primary_color": "#FFFFFF",
                              "card_selected_color": "#FFFFFF",
                              "products_info": [],
                              "visibility": 1,
                              "active": true,
                              "visibility_schedules": [],
                              "restaurant": 0,
                              "l1": null,
                              "l2": null,
                              "l3": null,
                              "test_table_url": ""}