import React from 'react';
import { SelectInput } from 'common/Input';
import { components } from 'react-select';
import { CouponDataPopover, CouponPlaceholderPopover } from './SubsidiesPopovers';

export const CouponSelector = ({ coupon, options, ...selectProps }) => {
  return (
    <SelectInput
      {...selectProps}
      value={options?.find(o => o.value === coupon?.id)}
      options={options}
      placeholder={'select'}
      styles={styles}
      components={{SingleValue, Placeholder}}
    />
  );
};

const Placeholder = props => (
  <components.Placeholder {...props}>
    {props.children}
    <CouponPlaceholderPopover/>
  </components.Placeholder>
);

const SingleValue = props => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      {data.label}
      <CouponDataPopover coupon={data.coupon}/>
    </components.SingleValue>
  );
};

const styles = {
  control: (baseStyles, { isDisabled }) => ({
    ...baseStyles,
    fontSize: '1rem',
    height: '56px',
    border: 'none',
    backgroundColor: 'translate',
    opacity: isDisabled ? '0.7' : '1',
    boxShadow: 'none',
    outlineOffset: '2px',
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    padding: '0 0 0 8px',
    overflow: 'visible',

    input: {
      opacity: 0,
    }
  }),
  singleValue: (baseStyles, { data }) => ({
    ...baseStyles,
    backgroundColor: data.color,
    borderRadius: '4px',
    padding: '2px 8px',
    width: 'fit-content',
    margin: 'auto',
    overflow: 'visible',

    h5: {
      fontWeight: 600,
    },

    p: {
      margin: '0'
    },

    'h5:first-letter, p:first-letter': {
      textTransform: 'capitalize',
    },
  }),
  indicatorSeparator: baseStyles => ({
    ...baseStyles,
    display: 'none',
  }),
  placeholder: baseStyles => ({
    ...baseStyles,
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    color: '#40404080',
    
    '&:hover': {
      color: '#094553',
    },

    '.yumminn_tooltip': {
      maxWidth: '13rem',
    },

    h5: {
      fontWeight: 600,
    },

    p: {
      margin: '0'
    },
  })
};