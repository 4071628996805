import React from "react";
import styled from "styled-components";

//style={{display: 'flex', width: '100%', gap: "6px", justifyContent: 'flex-end', margin: "-25px 15px 0 0", userSelect: 'none'}}
const RoundPaginationWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  width: 100%;
  gap: 6px;
  width: fit-content;
  margin: 14px 15px 0 0;
  user-select: none;
  ${(props) => props.additionalStyle ? props.additionalStyle : ''}
`;

const RoundPagination = ({
  page = 1,
  numberOfPages = 1,
  total = null,
  elementNameSingular = 'element',
  elementNamePlural = 'elements',
  wrapperStyle="",
  setPage = () => {}
}) => {

  const bigJumpNumberOfPages = 10;

  const UnselectedButton = ({pageToGo}) => {
    return (
      <div
        style={{width: "30px", height: "30px", lineHeight: "30px", borderRadius: "9999px", textAlign: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", color: "#404040", borderWidth: "1px", borderColor: "#DDDDDD", cursor: "pointer"}}
        onClick={() => setPage(pageToGo)}
      >{pageToGo}</div>
    );
  };
  const ButtonAdjacentToSelected = ({offset}) => <UnselectedButton pageToGo={page + offset} />;
  const Ellipsis = _ => {
    return (
      <div style={{width: "30px", height: "30px", fontSize: "18px", lineHeight: "30px", textAlign: 'center', alignItems: 'center', justifyContent: 'center', color: "#404040"}}>
        {". . ."}
      </div>
    );
  };

  return (
    <RoundPaginationWrapper additionalStyle={wrapperStyle}>
      {numberOfPages > bigJumpNumberOfPages + 1 && (
        <div
          style={{width: "40px", height: "30px", paddingRight: "2px", lineHeight: "30px", borderRadius: "9999px", textAlign: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", color: page > 1 ? "#404040" : "#DDDDDD" , borderWidth: "1px", borderColor: "#DDDDDD", cursor: "pointer"}}
          onClick={() => page - bigJumpNumberOfPages > 1 ? setPage(page - bigJumpNumberOfPages) : setPage(1)}
        >{"◀◀"}</div>
      )}
      {page - 3 > 0 && <UnselectedButton pageToGo={1} />}
      {page - 4 > 0 && <Ellipsis />}
      {page - 2 > 0 && <ButtonAdjacentToSelected offset={-2} />}
      {page - 1 > 0 && <ButtonAdjacentToSelected offset={-1} />}
      <div
          style={{width: "30px", height: "30px", lineHeight: "30px", borderRadius: "9999px", textAlign: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: "#094553", color: "#FFFFFF", borderWidth: "1px", borderColor: "#094553", cursor: "pointer"}}
          onClick={() => setPage(page)}
        >{page}</div>
      {page + 1 <= numberOfPages && <ButtonAdjacentToSelected offset={1} />}
      {page + 2 <= numberOfPages && <ButtonAdjacentToSelected offset={2} />}
      {page + 3 < numberOfPages && <Ellipsis />}
      {page + 3 <= numberOfPages && <UnselectedButton pageToGo={numberOfPages} />}
      {numberOfPages > bigJumpNumberOfPages + 1 && (
        <div
          style={{width: "40px", height: "30px", paddingLeft: "2px", lineHeight: "30px", borderRadius: "9999px", textAlign: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", color: page < numberOfPages ? "#404040" : "#DDDDDD" , borderWidth: "1px", borderColor: "#DDDDDD", cursor: "pointer"}}
          onClick={() => page + bigJumpNumberOfPages < numberOfPages ? setPage(page + bigJumpNumberOfPages) : setPage(numberOfPages)}
        >{"▶▶"}</div>
      )}
      {total && (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '5px', marginRight: '10px'}}>
          <span style={{marginRight: '4px'}}><b>{total}</b></span>
          <span>{total == 1 ? elementNameSingular : elementNamePlural}</span>
        </div>
      )}
    </RoundPaginationWrapper>
  );
};

export default RoundPagination;
