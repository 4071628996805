import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { STATUS_COLORS } from './constants';
import { ClientCardContainter } from 'pages/admin/pages/Lockers/LockerContainers';
import { getPurchaseData, groupTicketsByEvent } from './functions';
import { BUTTON_SIZES, Button } from 'common/Button';
import { PurchasePanelContainer } from './TicketsContainers';
import { Icon, IconType } from 'common/Icon';
import Spinner from 'common/Spinner';
import { baseAuth } from 'logic/api';
import { toast } from 'react-toastify';

export const PurchasePanel = ({purchase, mutate, isLoading}) => {
  const { t } = useTranslation();

  const [validatingUuid, setValidatingUuid] = useState(null);

  if(!purchase) return <></>

  if (isLoading) return (
    <PurchasePanelContainer>
      <Spinner className='spinner'/>
    </PurchasePanelContainer>
  );
  
  const { status, date, total } = getPurchaseData(purchase);

  const ticketsToValidate = purchase.tickets.filter(ticket => !ticket.is_validated);
  const ticketsToValidateByEvent = groupTicketsByEvent(ticketsToValidate);

  const validatedTickets = purchase.tickets.filter(ticket => ticket.is_validated);
  const validatedTicketsByEvent = groupTicketsByEvent(validatedTickets);

  const toggleValidation = async uuid => {
    if (validatingUuid) return;

    setValidatingUuid(uuid);
    try {
      const res = await baseAuth.patch(`event/toggle_purchase_ticket_validation/${uuid}`);
  
      if (res.status === 200) {
        mutate(prevData => {
          const purchase_list = [...prevData.purchase_list];
          const index = purchase_list.findIndex(p => p.id === purchase.id);
    
          purchase_list[index].is_validated = res.data.is_validated;
  
          return {...prevData, purchase_list };
        });
      }
    } catch (error) {
      toast.error('error_updating_validation');
    } finally {
      setValidatingUuid(null);
    }
  };

  return (
    <PurchasePanelContainer>
      <header>
        <h3>
          #{t('Purchase')}: <b>{purchase.code}</b>
          <Icon type={IconType.TICKET}/>
          <span className='status' style={{backgroundColor: STATUS_COLORS[status]}}>
            {t(status)}
          </span>
        </h3>
        <span className='date'>{date}</span>
      </header>
      <ClientCardContainter>
        <p>
          <span>{t('client')}</span>
          {purchase.client_name}
        </p>
        <p>
          <span>{t('phone')}</span>
          {purchase.client_phone}
        </p>
        <p>
          <span>{t('email')}</span>
          {purchase.client_email}
        </p>
      </ClientCardContainter>
      <h4>
        <Trans i18nKey={'tickets_purchased'} count={purchase.tickets.length}>
          <b>{{count: purchase.tickets.length}} tickets</b> purchased
        </Trans>
      </h4>
      {!!ticketsToValidate.length &&
        <div className='list_wrapper'>
          <h5>
            <span>{ticketsToValidate.length}</span>
            {t('tickets_to_validate', {count: ticketsToValidate.length})}
          </h5>
          <ul>
            {Object.entries(ticketsToValidateByEvent).map(([event, tickets]) =>
              <li key={event}>
                <h6>{event}</h6>
                <ul>
                  {tickets.map(ticket =>
                    <li key={ticket.uuid}>
                      <span>
                        {t('ticket')}
                        <b>{ticket.code}</b>
                      </span>
                      <Button
                        label='validate'
                        onClick={() => toggleValidation(ticket.uuid)}
                        size={BUTTON_SIZES.SM}
                        disabled={validatingUuid}
                        loading={ticket.uuid === validatingUuid}
                      />
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      }
      {!!validatedTickets.length &&
        <div className='list_wrapper'>
          <h5>
            <span>{validatedTickets.length}</span>
            {t('validated_tickets', {count: validatedTickets.length})}
          </h5>
          <ul>
            {Object.entries(validatedTicketsByEvent).map(([event, tickets]) =>
              <li key={event}>
                <h6>{event}</h6>
                <ul>
                  {tickets.map(ticket =>
                    <li key={ticket.uuid}>
                      <span>
                        {t('ticket')}
                        <b>{ticket.code}</b>
                      </span>
                      <a onClick={() => toggleValidation(ticket.uuid)} disabled={validatingUuid}>
                        {t('cancel_validation')}
                      </a>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      }
      <div className='payment'>
        <span>{t('total_account')}</span>
        <b>{total}€</b>
      </div>
      {purchase.payment_method &&
        <div className='payment'>
          <span>{t('paid_with')}</span>
          {purchase.payment_method === 'card' &&
            <i>
              <Icon type={purchase.card_brand.toUpperCase()}/>
              ****{purchase.card_last_4}
            </i>
          }
          {purchase.payment_method !== 'card' &&
            <Icon type={purchase.payment_method.toUpperCase()}/>
          }
        </div>
      }
    </PurchasePanelContainer>
  );
};
