const { default: styled } = require("styled-components");

export const ModifiersContainer = styled.div`
  max-height: 100svh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > header {
    background-color: #FFF;
    padding: 16px 24px;
  }
  
  & > div:has(section) {
    display: flex;
    gap: 24px;
    margin: 24px;
    flex: 1;
    overflow: hidden;
  }

  .modifiers_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;

    h2 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1;
      border-bottom: 1px solid #DDD;
      padding-bottom: 1em;
      margin-bottom: 1em;

      b {
        font-weight: 600;
      }
    }
  }

  section {
    background-color: #FFF;
    border: 1px solid #40404026;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }

    h3 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 1.125rem;
      line-height: 1.3em;

      span::first-letter {
        text-transform: capitalize;
      }
    }

    &.categories {
      width: min-content;
      height: fit-content;
      max-height: 100%;
      min-width: 350px;
      max-width: 24rem;

      header {
        border-bottom: 1px solid #DDD;
        padding-bottom: 16px;
      }

      h3 span {
        width: min-content;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
        max-width: 100%;
        padding: 16px 4px 4px;
        margin: 0 -4px -4px;
        flex: 1;
        overflow: hidden auto;
      }
      
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25em;
        white-space: nowrap;
        color: #094553;
        border-radius: 2rem;
        height: 2.75rem;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.15s ease-in-out;
        transition-property: color, background-color;
        max-width: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        
        &:not(:has(input)) {
          border: 1px solid #DDD;
          padding: 0 16px;
          overflow: hidden;

          span {
            flex-shrink: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          &:hover {
            color: #094553;
            background-color: #D4F9EE;
          }
        }

        &:has(input) {
          align-self: stretch;
        }
        
        &.selected {
          color: #FFF;
          background-color: #094553;
          border-color: #094553;
          cursor: default;

          &:hover {
            color: #094553;
            background-color: #FFF;
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      div:has(.input_wrapper) {
        flex: 1;
      }

      .input_wrapper {
        height: 100%;
      }
    }

    &.modifiers {
      overflow: hidden;

      .table_wrapper {
        border: 1px solid #40404026;
        border-radius: 12px;
        padding: 0 8px;
        overflow: auto;
        margin-top: 16px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        
        &::-webkit-scrollbar {
          display: none;
        }
        
        table {
          width: 100%;
          border-spacing: 0 8px;
          border-collapse: separate;
        }
        
        tr{
          width: 100%;
          user-select: none;
          
          &:last-child td {
            
            &:first-child {
              border-bottom-left-radius: 8px;
            }
  
            &:last-child {
              border-bottom-right-radius: 8px;
            }
          }
  
          &:has(td:nth-child(6)) td:nth-child(5) {
            width: 10%;
          }
        }
        
        td, th {
          background-color: #FCFCFC;
          padding: 16px;
          text-align: center;
  
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
  
            p {
              font-size: 1.125rem;
              font-weight: 400;
              max-width: 30ch;
              text-wrap: balance;
            }
  
            b {
              font-weight: 600;
            }
          }
        }
        
        th {
          font-size: 1.125rem;
          font-weight: 500;
          border-bottom: 1px solid #40404026;
          position: sticky;
          top: 8px;
          z-index: 1;
          box-shadow: 0 -8px 0 #FFF;
  
          &:first-child {
            border-top-left-radius: 8px;
          }
  
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
  
        td {
          font-size: 1rem;
          text-align: center;
          width: 20%;
  
          &:first-child {
            width: 1rem;
          }
  
          &:nth-child(2) {
            text-align: start;
            width: 40%;
          }
  
          &:nth-child(4) {
            text-align: end;
            padding-right: 32px;
          }
  
          &:nth-child(6) {
            width: 10%;
          }
  
          & > svg {
            margin: auto;
          }
        }
      }
    }

    &.empty_state {
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 96px;

      p {
        font-size: 1.125rem;
        text-align: center;
        text-wrap: balance;
        max-width: 25ch;
      }
    }
  }
`;