import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { TrashIcon } from '@heroicons/react/outline';
import { baseAuth } from "logic/api";
import x_IMG from '../../../../../img/x_IMG.svg'
import { Trans } from "react-i18next";

const CancelBookingModal = ({ t, booking, cancelBooking, setBookingToCancel }) => {
  const [reason, setReason] = useState("");

  return (
    <div style={{ zIndex: 1000, fontSize: "15px" }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div className='w-full max-w-min top-14 m-auto z-50 relative flex flex-col gap-4 items-center p-7 overflow-hidden bg-white rounded-xl'>
      <img
        src={x_IMG}
        className={`w-[50px] text-gray-700`}
      />
      <p className="font-bold max-w-min whitespace-nowrap text-center">
        {t('cancel_booking')} <br/>
        #{booking.code}
      </p>
      <p className="text-xm text-center max-w-min whitespace-nowrap">
        {t('cancel_booking_confirmation')}
      </p>
      <div>
        <p className="text-xm text-center w-[28rem]">
          <Trans i18nKey={'cancel_booking_message'}>
            Si necesitas informar al cliente, <b>escribe aquí un<br/> mensaje</b> y lo enviaremos a su correo
          </Trans>
          <span style={{color: "#3BA278"}}>
            ({t('Optional')})
          </span>
          </p>
        <div style={{marginTop: "15px"}}>
          <textarea
            placeholder={t('cancel_reason')}
            value={reason}
            name='cancel_reason'
            style={{width: '100%', height: "120px", borderRadius: "10px", fontSize: "14px"}}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <button
          className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xm text-gray-500 font-bold"}
          onClick={() => setBookingToCancel(null)}>{t('no_keep')}</button>
          <button
          className={"rounded-full cursor-pointer py-2 px-5 m-2 bg-principal text-xm text-white font-bold"}
          onClick={() => cancelBooking(booking, reason)}>{t('yes_cancel')}</button>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'/>
    </div>
  );
};

export default CancelBookingModal