import React from "react";
import { Context as AuthContext } from '../../data/authContext';

const useRestaurant = () => {
  const {
    state: { selectedRestaurant },
  } = React.useContext(AuthContext);

  const [seleccionado, setSeleccionado] = React.useState(null);
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);


  React.useEffect(() => {
    const seleccionado =
      JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) || selectedRestaurant 
    const start = sessionStorage.getItem('yumminn-selectedStartTime')
    const end = sessionStorage.getItem('yumminn-selectedEndTime')
    if (seleccionado) {
      setSeleccionado(seleccionado);
    }
    if (start){
      setStart(start);
    }
    if (end){
      setEnd(end)
    }
    return () => {
      setSeleccionado({}); // This worked for me
      setStart({}); // This worked for me
      setEnd({}); // This worked for me
    };
  }, [selectedRestaurant]);

  return {
      seleccionado,
      start,
      end
  };
};

export default useRestaurant;
