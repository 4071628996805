export const WEEKDAYS = {
  SHORT: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  LONG: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
};

export const DISCOUNT_TYPOLOGIES = {
  GENERAL: 'general_discount',
  COUPON: 'code_coupon',
  PRODUCT: 'product_discount',
};

export const TYPOLOGY_KEYS = {
  general_discount: 'promotion',
  code_coupon: 'coupon',
  product_discount: 'discount_in_products',
};

export const TYPOLOGY_KEYS_SHORT = {
  ...TYPOLOGY_KEYS,
  product_discount: 'discount',
};

export const DISCOUNT_TABS = ['information', 'specs', 'validity_limits', 'visibility_and_schedules'];
export const PRODUCT_DISCOUNT_TABS = DISCOUNT_TABS.slice(1);

export const MANDATORY_DISCOUNT_FIELDS = {
  [DISCOUNT_TYPOLOGIES.GENERAL]: {
    [DISCOUNT_TABS[0]]: ['marquee_text', 'payment_details_text'],
    [DISCOUNT_TABS[1]]: ['discount_type', 'value'],
  },
  [DISCOUNT_TYPOLOGIES.COUPON]: {
    [DISCOUNT_TABS[0]]: ['code', 'payment_details_text'],
    [DISCOUNT_TABS[1]]: ['discount_type', 'value'],
  },
  [DISCOUNT_TYPOLOGIES.PRODUCT]: {
    [DISCOUNT_TABS[1]]: ['products', 'discount_type', 'value'],
  },
};

export const NEW_DISCOUNT = {
  typology: null,
  discount_type: 'percentage',
  value: 0,
  active: true,
  minimum_redeem_amount: 0,
  max_cents_redeemable: null,
  minimumRedeemEuros: '',
  maxEurosRedeemable: '',
  max_daily_uses: null,
  max_total_uses: null,
  valid_since: null,
  valid_until: null,
  schedules: [],
};