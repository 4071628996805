import React from 'react';
import { Icon } from '.';
import styled from 'styled-components';

export const SquareIcon = ({ children, type, src, alt = '', size = 44, color = 'currentColor', emoji, fill, extraIcon, className = '' }) => {
  return (
    <Square size={size} color={color} data-emoji={emoji} className={className}>
      {children &&
        children
      }
      {type &&
        <Icon type={type} fill={fill}/>
      }
      {extraIcon &&
        <span>
          <Icon type={extraIcon} fill={fill}/>
        </span>
      }
      {src &&
        <img src={src} alt={alt}/>
      }
    </Square>
  );
};

const Square = styled.div`
  --shadow: clamp(4px, ${({size}) => `${size / 20}px, 8px`});
  --padding: ${({size}) => size / 10}px;
  --shadow_color: #404040;
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  color: ${({color}) => color};
  background-color: #FFF;
  border: 2px solid var(--shadow_color);
  outline: 2px solid var(--shadow_color); // this covers some white pixels in conrers
  outline-offset: -1.6px;
  border-radius: clamp(4px, ${({size}) => size / 5}px, 20px);
  font-size: ${({size}) => size / 1.6}px;
  padding: var(--padding);
  position: relative;
  box-shadow:
    calc(var(--shadow) * 0.3) calc(var(--shadow) * 0.3) var(--shadow_color),
    calc(var(--shadow) * 0.6) calc(var(--shadow) * 0.6) var(--shadow_color),
    var(--shadow) var(--shadow) var(--shadow_color);

  svg {
    height: 100%;
    width: 100%;
  }
  
  img {
    height: calc(100% + var(--padding) * 2);
    width: calc(100% + var(--padding) * 2);
    margin: calc(var(--padding) * -1);
    object-fit: cover;
    border-radius: clamp(4px, ${({size}) => size / 4.4}px, 15px);
    max-height: unset;
    max-width: unset;
  }

  span{
    position: absolute;
    height: 86px;
    width: 72px;
    bottom: 36px;
    left: 44px;
    overflow: visible;
  }

  &::after {
    content: attr(data-emoji);
    position: absolute;
    top: calc(var(--padding) / 4);
    right: calc(var(--padding) / 4);
    font-size: clamp(4px, ${({size}) => size / 3.3}px, 44px);
  }
`;