import React from 'react';
import { useTranslation } from "react-i18next";

const DeliveryAddressInfo = ({order}) => {

  const { t } = useTranslation();

  return (
    <div style={{marginTop: "15px", fontWeight: 500, backgroundColor: "#EEF9F5", padding: "15px", borderRadius: "10px"}}>
		<div>📍 {t("delivery_address")}</div>
		<div style={{fontWeight: 600}}>{order.delivery_address}</div>	
    <div style={{marginTop: "10px"}}>💬 {t("Comment")}</div>
		<div style={{fontWeight: 600}}>{order.delivery_notes}</div>	
	</div>
  );
};
export default DeliveryAddressInfo;
