import React from 'react';
import { CardWithHeader } from 'components/yumminn/CardWithHeader';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DataCard } from './DataCard';
import { abbreviateBigNumbers, toDecimalString } from 'logic/functions';
import styled from 'styled-components';

const AverageStars = ({value = 0,}) => (
  <div className='flex items-center'>
    <span className='text-base'>⭐</span>
    <span>{toDecimalString(value, 1)}</span>
  </div>
);

const XTick = ({payload, x, y}) => (
  <text x={x} y={y + 16} fontSize='12' fontWeight='600' fill='#404040' textAnchor="middle">
    ⭐{payload.value}
  </text>
);

const YTick = ({payload, x, y}) => (
  <text x={x - 2} y={y + 4} fontSize='12' fontWeight='500' fill='#A7A7A7' textAnchor="end">
    {payload.value}
  </text>
);

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;
  let height = y
  if (y < 10) height = 40

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} /> */}
      <text
      
        x={x + width / 2}
        y={height - 15}
        textAnchor="middle"
        fontSize='14' 
        fontWeight='600' 
        fill='#404040' 
        
      >
        {value}
      </text>
    </g>
  );
};

export const ReviewsOverview = ({data, dates, type}) => {
  const { t } = useTranslation();
  
  const topReviews = data => Math.max(...data.reviews.map(review => review.quantity));
  const toPercent = (decimal, fixed) => `${(decimal * 100).toFixed(fixed)}%`;

  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;
    return toPercent(ratio, 0);
  };

  const valueAccessor = attribute => ({ payload }) => {
    const total = data.total_reviews.value;
    return getPercent(payload[attribute], total);
  };

  return (
    <CardWithHeader
      title='ratings'
      info='info_period_ratings'
      infoOptions={dates}
      color='#E4F4FF'
      className='flex flex-col flex-auto'
      delta={data?.total_reviews.delta}
    >
      <Container type={type}>
        <div className={`flex flex-col`}>
          <DataCard
            data={<AverageStars value={data?.average_stars.value || 0}/>}
            delta={data?.average_stars.delta || 0}
            label={'average_stars'}
            className={'w-48'}
          />
          {type === 'superadmin' && 
            <DataCard
              data={`${data?.restaurants.with_reviews || 0}/${data?.restaurants.total || 0}`}
              delta={data?.restaurants.delta}
              label={'restaurants_with_reviews'}
              className={'w-48'}
            />
          }
          <DataCard
            data={abbreviateBigNumbers(data?.total_reviews.value, {rounded: true})}
            delta={data?.total_reviews.delta}
            label={'total_reviews'}
            className={'w-48'}
          />
          {type === 'admin' && 
            <DataCard
              data={`${data?.payments_with_review || 0} %`}
              label={'payments_with_review'}
              className={'w-48'}
            />
          }
        </div>
        <div>
          <ResponsiveContainer width={'100%'} height={type === 'superadmin' ? 286 : 246}>
            <BarChart
              data={data?.reviews}
              margin={{left: 0, right: 0, top: 0, bottom: 0}}
            >
              <CartesianGrid className='loyalty-overview-grid' strokeDasharray="3 3" vertical={false}/>
              <XAxis
                dataKey='stars'
                domain={[1, 5]}
                ticks={[1, 2 , 3, 4, 5]}
                tickSize={4}
                tick={<XTick/>}
                height={25}
                stroke='#A7A7A7'
                strokeWidth='1.5'
                strokeOpacity='1'
              />
              <YAxis
                dataKey='quantity'
                type='number'
                
                tickSize={4}
                tick={<YTick/>}
                padding={{top : 8, bottom: 8}}
                stroke='#A7A7A7'
                strokeWidth='1.5'
                strokeOpacity='1'
                width={50}
              />
              <svg x={49} y={245}>
                <rect width='16' height='18' fill='#FFF'/>
                <path d="M 17 16 A 16 16 0 0 1 1 0" stroke='#A7A7A7' fill='none' strokeWidth='1.5'/>
              </svg>
              <Bar
                stackId={'a'}
                dataKey='quantity'
                maxBarSize={40}
                minPointSize={1}
                isAnimationActive={false}
                shape={<Rectangle radius={8}/>}
                label={{ position: 'insideBottom', fill: '#404040', fontSize: '0.75rem', fontWeight: '600' }}
              >
                {data?.reviews.map(review => (
                  <React.Fragment key={review.name}>
                    <Cell fill={review.quantity === topReviews(data) ? 'url(#stripes)' : '#E4F4FF'}/>
                  </React.Fragment>
                ))}
              </Bar>

              <Bar
                stackId={'a'}
                dataKey='percentage'
                maxBarSize={40}
                minPointSize={1}
                isAnimationActive={false}
                fill="#00000000"
              >
                 <LabelList valueAccessor={valueAccessor('quantity')} position="top" content={renderCustomizedLabel} />
              </Bar>
              <Tooltip
                cursor={false}
                formatter={value => [value, t('num_reviews')]}
                labelFormatter={label => `⭐${label}`}
                labelStyle={{fontWeight: '600'}}
                contentStyle={{
                  color: '#404040',
                  borderRadius: '6px',
                  fontSize: '0.75rem',
                  padding: '8px',
                  border: 'none',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
                }}
              />
              <defs>
                <pattern id='stripes' patternUnits='userSpaceOnUse' width='14' height='14' patternTransform='scale(1) rotate(20)'>
                  <rect x='0' y='0' width='100%' height='100%' fill='#E4F4FF'/>
                  <path d='M0 10h20z' strokeWidth='1.5' stroke='#9FC8E3' fill='none'/>
                </pattern>
              </defs>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Container>
    </CardWithHeader>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);

  & > div {
    &:nth-child(1) {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      flex: 1;
      max-width: 10rem;
      gap: clamp(8px, 2vw, 16px);
    }
    
    &:nth-child(2) {
      flex: 1;
    }
  }
    
  @media (max-width: 500px) {
    flex-direction: column;
    
    & > div:nth-child(1) {
      max-width: 100%;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: initial;
    }
  }
`;


const ContainerAdmin = styled.div`
  display: flex;
  flex: 1;
  gap: clamp(8px, 2vw, 16px);
  padding: clamp(8px, 2vw, 16px);
  
  
  
  @media (max-width: 500px) {
    flex-direction: column;
    
    & > div:nth-child(1) {
      max-width: 100%;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: initial;
    }
  }
`;