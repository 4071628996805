import React from 'react';
import { useTranslation } from "react-i18next";
import StatusBadge from './StatusBadge';
import { awareDatetimeString, centsToAmountString } from 'logic/functions';
import OrderType from './OrderType';

const OrderRow = ({order, selected, isNotOpened}) => {
  const { t } = useTranslation();
  const getTypeLabel = (type) => {
	switch (type) {
		case "take_away":
			return t("take_away");	
		case "delivery":
			return t("delivery");	
		default:
			return t("at_table");
		}
	};

  return (
    <div style={{border: selected ? "2px solid #5FB894" : "2px solid #FCFCFC", display: 'grid', gridTemplateColumns: "1fr 1.2fr .8fr .7fr 1fr .7fr", textAlign: 'center', width: "100%", backgroundColor: selected ? "#EEF9F5" : isNotOpened ? "#FFF7E2" : "#FCFCFC", justifyContent: 'space-around', fontSize: "16px", fontWeight: 500, padding: "10px 0px", marginTop: "10px", alignItems: 'center', borderRadius: '15px'}}>
		<div><span style={{backgroundColor: selected ? "#094553" : "#EEF9F5", color: selected ? "#FFFFFF" : "#404040", border: selected ? "none" : "1px solid #AFDCCA", padding: '5px 10px', borderRadius: "99999px", }}>{order.code}</span></div>
		<div style={{display: 'flex', justifyContent: 'center', zIndex: 0}}><StatusBadge status={order.status} refunded={order.refunded} payment={order} hover={false}/></div>
		<div>{awareDatetimeString(order.created_at, "Europe/Madrid")}</div>
		<div>{order.type === "table" ? order.tableName : "-"}</div>
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "2px", fontWeight: 600, fontSize: "14px"}}>
			<OrderType type={order.type}/>
			<div>{getTypeLabel(order.type)}</div>
		</div>
		<div>{centsToAmountString(order.price ? order.price : 0) + ' €'}</div>
	</div>
  );
};
export default OrderRow;
