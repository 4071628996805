import React from 'react';
import { useTranslation } from "react-i18next";

const OrdersHeader = ({opacity}) => {

  const { t } = useTranslation();
  return (
    <div className={`${opacity ? 'opacity-20' : ''}`} style={{display: 'grid', gridTemplateColumns: "1fr 1.2fr .8fr .7fr 1fr .8fr", textAlign: 'center', width: "100%", backgroundColor: "#FCFCFC", justifyContent: 'space-around', fontSize: "17px", fontWeight: 500, padding: "15px 0px", borderBottom: "2px solid #D1D1D1"}}>
		<div>#{t('Order')}</div>
		<div>{t('Status')}</div>
		<div>{t('Date and hour')}</div>
		<div>{t('Table')}</div>
		<div>{t('Type')}</div>
		<div>{t('Total')}</div>
	</div>
  );
};
export default OrdersHeader;
